import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import { UserSettingState } from '../state.d';
// import UserTable from './TableBodyData';
import UserFilters from './FilterBtn';
import { fetchUserData } from '../../../../api/settings/fetchSettingUsersListing';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData
} from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import PaginationSettings from '../Pagination';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, READ, SETTINGS, UPDATE } from '../../../../constants/constants';
import { format } from 'date-fns';
import edit from '../../../../assets/images/edit.svg';
import Popup from '../../common/popup/Popup';

export default function UserIndex() {
  const title = 'Users';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [users, setUsers] = useState<UserSettingState[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const [dense] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (property: keyof UserSettingState) => {
    console.log(property);
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 2, READ)) {
      navigate('/dashboard');
    }
    setIsAccessable(true);
  }, []);

  useEffect(() => {
    fetchData(orderBy, order);
  }, [currentPage, search, selectedStatus]);

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      const response = await fetchUserData(
        currentPage,
        search,
        selectedStatus,
        authToken,
        selectedAccount,
        undefined,
        orderBy,
        order
      );

      if (response.users && response.users.data && Array.isArray(response.users.data)) {
        setUsers(response.users.data);
        const totalPages = response.users.last_page || 1;
        setTotalPages(totalPages);
        setTotalUsers(response.totalUsers);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }finally{
      setLoading(false);
    }
  };

  const EditUserData = (user: UserSettingState) => {
    navigate(`/settings/edit-user/${encryptData(String(user.id), true)}`);
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof UserSettingState;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'first_name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email address'
    },
    {
      id: 'access_level_name',
      numeric: false,
      disablePadding: true,
      label: 'Role'
    },
    {
      id: 'login_at',
      numeric: false,
      disablePadding: true,
      label: 'Last Login'
    }
  ];

  const togglePopup = (type?: string) => {
    if (type == 'alert') {
      setOpenAlertPopup(false);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 2, CREATE) && (
        <>
          {selectedAccount.plan_max_users > totalUsers ? (
            <Button className="btn primary-btn" component={RouterLink} to="/settings/add-user">
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Add User
            </Button>
          ) : (
            <Button className="btn primary-btn" onClick={() => setOpenAlertPopup(true)}>
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Add User
            </Button>
          )}
        </>
      )}
    </>
  );

  const isEditPermission = checkUserPermission(user, SETTINGS, 2, UPDATE);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              button={addButton}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <form>
                    <UserFilters
                      search={search}
                      setSearch={setSearch}
                      selectedStatus={selectedStatus}
                      setSelectedStatus={setSelectedStatus}
                    />
                    <div className="table-outer">
                      <TableContainer>
                        {loading ? (
                          <div className="no-record">
                            <CircularProgress />
                          </div>
                        ) : users.length === 0 ? (
                          <div className="no-record">No users found.</div>
                        ) : (
                          <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            className="table hover-table">
                            <TableHead>
                              <TableRow>
                                {headCells.map((headCell) => (
                                  <TableCell
                                    key={`key-${headCell.id}`}
                                    align={headCell.numeric ? 'left' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    <TableSortLabel
                                      active={orderBy === headCell.id}
                                      direction={orderBy === headCell.id ? order : 'asc'}
                                      onClick={() => handleSort(headCell.id)}>
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={{ display: 'none' }}>
                                          {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(users) && users.length > 0
                                ? users.map((user) => (
                                    <TableRow key={user.id} hover>
                                      <TableCell align="left">
                                        <Grid container alignItems="center" flexWrap="nowrap">
                                          <Grid item sm={3}>
                                            {user.profile_image ? (
                                              <Avatar
                                                src={`${JSON.parse(user.profile_image).path}`}
                                                alt={user.first_name ? user.first_name : 'user'}
                                                onError={(e) => {
                                                  const target = e.target as HTMLImageElement;
                                                  target.style.display = 'none'; // Hide the image
                                                  const nextElement =
                                                    target.nextElementSibling as HTMLElement | null;
                                                  if (nextElement) {
                                                    nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                                  }
                                                }}
                                              />
                                            ) : (
                                              <Avatar alt={user.first_name} src="." />
                                            )}
                                          </Grid>
                                          <Grid item sm={9} className="content">
                                            <p className="name">
                                              {user.first_name + ' ' + user.last_name}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell align="left">{user.email}</TableCell>
                                      <TableCell align="left">{user.access_level_name}</TableCell>
                                      {user.login_at ? (
                                        <TableCell align="left">
                                          {format(new Date(user.login_at), 'd/MMM/yyyy')}
                                        </TableCell>
                                      ) : (
                                        <TableCell align="left">N/A</TableCell>
                                      )}
                                      {isEditPermission && (
                                        <TableCell align="left">
                                          <div className="table-btn">
                                            <Button
                                              className="edit"
                                              onClick={() => EditUserData(user)}>
                                              <img src={edit} alt="edit" />
                                            </Button>
                                          </div>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))
                                : null}
                            </TableBody>
                          </Table>
                        )}
                        {!loading && users.length > 0 && (
                          <PaginationSettings
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                          />
                        )}
                      </TableContainer>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Popup
            type="alert"
            openPopup={openAlertPopup}
            text={`You have reached the maximum number of users for your Plan. Contact support@3lines.com.au to upgrade your Plan`}
            togglePopup={togglePopup}
          />
        </CommonLayout>
      )}
    </>
  );
}
