import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PaginationResponse, UpdateState } from '../user/state';
import { format } from 'date-fns';
import { fetchUpdates } from '../../../api/updates/fetchUpdates';
import { encryptData, getSelectedAccountInfo, useAuthToken } from '../../../utils/common/user';

const UpdateColumn = () => {
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(true);
  const [topUpdates, setTopUpdates] = useState<UpdateState[]>([]);
  const [updates, setUpdates] = useState<UpdateState[]>([]);

  const fetchAllUpdates = async () => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchUpdates(
          authToken,
          selectedAccount,
          0,
          '',
          1,
          '',
          '1',
          10,
          false,
          '',
          'date'
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setUpdates(response.data);
            setTopUpdates(response.data.slice(0, 5));
          }
        }
      }
    } catch (error: any) {
      console.log('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUpdates();
  }, []);

  const fetchBackground = (type: number) => {
    if (type == 1) {
      return '#1da2ef';
    } else if (type == 2) {
      return '#13c4a3';
    } else if (type == 3) {
      return '#835fa6';
    }
  };

  return (
    <div className="dashboard-right-col">
      <div className="update-outer">
        <h5>Updates</h5>
        {loading ? (
          <div className="no-record">
            <CircularProgress />
          </div>
        ) : updates.length === 0 ? (
          <div className="no-record">No updates found.</div>
        ) : (
          <div className="update-inner">
            {topUpdates.map((update: UpdateState) => (
              <div
                className="update-list"
                key={update.id}
                style={{ border: `1px solid ${fetchBackground(update.type)}` }}>
                <div
                  className="update-title dashboard-update-content"
                  style={{ background: fetchBackground(update.type) }}>
                  <h6>
                    {update.type == 1
                      ? 'Regulatory Update'
                      : update.type == 2
                        ? 'System Update'
                        : update.type == 3
                          ? 'Internal Update'
                          : ''}
                  </h6>
                  <p>{update.date ? format(new Date(update.date), 'd/MMM/yyyy') : ''}</p>
                </div>
                <div className="dashboard-update-content update-content ">
                  <h5>{update.title}</h5>
                  <p className="collapse-summary dashboard-update-summary">{update.summary}</p>
                  <Link
                    to={`/resources/update/${encryptData(String(update.id), true)}`}
                    style={{ color: fetchBackground(update.type) }}
                    className="text-btn home-read-more"
                    title="Read more">
                    Read more
                    <span className="icon">
                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <g clipPath="url(#clip0_6545_879)">
                          <path
                            d="M5.16507 15L15.1651 5M15.1651 5H8.4984M15.1651 5V11.6667"
                            stroke="CurrentColor"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6545_879">
                            <rect width="21" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {updates.length > 0 && (
        <Button
          className="btn btn-sm blue-fill-btn"
          onClick={() => {
            navigate('/resources/updates');
          }}>
          <i className="left">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
              <path
                d="M9.66683 1.8916V5.33372C9.66683 5.80043 9.66683 6.03378 9.75766 6.21204C9.83755 6.36885 9.96504 6.49633 10.1218 6.57622C10.3001 6.66705 10.5335 6.66705 11.0002 6.66705H14.4423M9.66683 14.167H4.66683M11.3335 10.8337H4.66683M14.6668 8.32385V14.3337C14.6668 15.7338 14.6668 16.4339 14.3943 16.9686C14.1547 17.439 13.7722 17.8215 13.3018 18.0612C12.767 18.3337 12.067 18.3337 10.6668 18.3337H5.3335C3.93336 18.3337 3.2333 18.3337 2.69852 18.0612C2.22811 17.8215 1.84566 17.439 1.60598 16.9686C1.3335 16.4339 1.3335 15.7338 1.3335 14.3337V5.66699C1.3335 4.26686 1.3335 3.5668 1.60598 3.03202C1.84566 2.56161 2.22811 2.17916 2.69852 1.93948C3.2333 1.66699 3.93336 1.66699 5.3335 1.66699H8.00998C8.62145 1.66699 8.92719 1.66699 9.21491 1.73607C9.47 1.79731 9.71386 1.89832 9.93754 2.03539C10.1898 2.19 10.406 2.40619 10.8384 2.83857L13.4953 5.49542C13.9276 5.9278 14.1438 6.14399 14.2984 6.39628C14.4355 6.61996 14.5365 6.86382 14.5978 7.11891C14.6668 7.40663 14.6668 7.71237 14.6668 8.32385Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          View All Updates
        </Button>
      )}
    </div>
  );
};

export default UpdateColumn;
