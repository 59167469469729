import axios from 'axios';
import { ApiResponse } from '../apiResponse';
import { localStorageKeys } from '../../utils/local-storage';
import { encryptData } from '../../utils/common/user';
import { Dispatch } from 'redux';
import { loginFailure, loginSuccess } from '../../store/user/authSlice';

/**
 * Verifies the token by sending it to the backend.
 * @param token - The JWT access token.
 * @returns A string containing an error message if verification fails, or undefined if successful.
 */
export const verifyToken = async (
  dispatch: Dispatch,
  token: string
): Promise<string | undefined> => {
  try {
    // Send the token to the backend for verification
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/outseta/verify-token?token=${token}`
    );

    // Check if the backend response indicates success
    if (response.data.success) {
      dispatch(loginSuccess(response.data.data));
      if (response.data.data) {
        // Store user data in localStorage if available
        localStorage.setItem(
          localStorageKeys.USER_DATA,
          encryptData(JSON.stringify(response.data.data))
        );
      }
      return;
    } else {
      // Throw an instance of ApiResponse for custom error handling
      throw new ApiResponse(response.data.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse
        ? error.message
        : error?.response?.data?.message || 'Something went wrong';

    dispatch(loginFailure(errorMessage));
    // Return the error message so it can be handled
    return errorMessage;
  }
};
