import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Attestations from './Attestations';
import AttestationCreateEdit from './AttestationCreateEdit';
import AttestationNotes from './AttestationNotes';
import AttestationNotesAdd from './AttestationNotesAdd';
import AttestationTasks from './AttestationTasks';
import AttestationTasksAddEdit from './AttestationTasksAddEdit';
import AttestationTemplatePreview from './AttestationTemplatePreview';

const AttestationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Attestations />} />
      <Route path="/:id" element={<AttestationCreateEdit />} />
      <Route path="/notes" element={<AttestationNotes />} />
      <Route path="/add-note" element={<AttestationNotesAdd />} />
      <Route path="/edit-note/:id/" element={<AttestationNotesAdd />} />
      <Route path="/tasks" element={<AttestationTasks />} />
      <Route path="/add-task" element={<AttestationTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<AttestationTasksAddEdit />} />

      <Route path="/preview" element={<AttestationTemplatePreview />} />
    </Routes>
  );
};

export default AttestationRoutes;
