import React, { useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getSelectedAccountInfo, useAuthToken } from '../../../utils/common/user';
import { requestCall } from '../../../api/user/requestCall';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const MoreThanTwenty = () => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const title = 'More Than 20';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const [preferredPhone, setPreferredPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPreferredPhone(value);
  };

  const handleMoreThan20 = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    console.log(preferredPhone);
    
    if (!preferredPhone) {
      setPhoneError(t('user.preferredPhoneRequired'));
      setLoading(false);
      return;
    }

    try {
      const response = await requestCall(authToken, selectedAccount, preferredPhone);

      if (!response) {
        console.log('Request call', response);
        navigate('/more-than-20-sent')
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper selected-plan-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="content-wrapper">
                <h2>{t('user.enterPrisePlan')}</h2>
                <p>
                  {t('user.moreThanTwenty')}
                </p>
              </div>

              {loading ? (
                <div className="no-record">
                  <CircularProgress />
                </div>
              ) : (
                <div className="btn-wrap">
                <form onSubmit={handleMoreThan20}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      {t('user.preferredPhone')}
                    </label>
                    <TextField
                      error={!!phoneError}
                      id="preferred-phone"
                      variant="outlined"
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Enter your phone number"
                      helperText={phoneError}
                      name="preferred_phone"
                      value={preferredPhone}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="message-ic" position="end">
                            <InfoOutlinedIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn primary-btn">
                      {t('user.requestCall')}
                  </Button>
                </form>
                </div>
              )}
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-success-1.png`}
                  alt={'Login'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default MoreThanTwenty;
