import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  decryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, NOTE_CREATE } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import AdviserProfileLeftMenu from './AdviserProfileLeftMenu';
import AddNotes from '../../notes/AddNotes';

const AdviserProfileNotesAdd = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true) || ''; // Default to an empty string if undefined
  const source_Id = parseInt(sourceId!, 10);
  let adviserProfileId = queryParams.get('adviserProfileId');
  const encryptedAdviserProfileId = adviserProfileId; // Decrypt the ID
  adviserProfileId = decryptData(adviserProfileId, true) || ''; // Default to an empty string
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const title = id ? 'Edit Adviser Profile Note' : 'Add Adviser Profile Note';
  const mainPath = `/adviser-profiles/${sourceId}`;
  const thirdTitle = 'Notes';
  const thirdPath = `/adviser-profile/notes?source=${encryptedSourceId}&adviserProfileId=${encryptedAdviserProfileId}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const adviserName = localStorage.getItem('adviser_name');
  if (adviserName) {
    secondTitle = adviserName;
  }
  const secondpath = `/adviser-profile/${encryptedAdviserProfileId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, NOTE_CREATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <AdviserProfileLeftMenu
                  user={user}
                  adviserProfileId={adviserProfileId ? adviserProfileId : ''}
                  sourceId={sourceId || ''}
                />
                <AddNotes
                  moduleType="adviser-profile"
                  sourceId={sourceId}
                  recordId={adviserProfileId}
                  localKey="adviserProfileNotesCount"
                  listUrl={`/adviser-profile/notes?source=${encryptedSourceId}&adviserProfileId=${encryptedAdviserProfileId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AdviserProfileNotesAdd;
