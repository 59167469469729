import React, { useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Switch
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import PaginationSettings from '../../settings/Pagination';
import { FileReviewState, AccountState } from '../../user/state';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import trashGrey from '../../../../assets/images/trash-grey.svg';
import { FileReviewResponseError, ReviewsDataFormState } from './state';
import { createFileReview } from '../../../../api/adviser/createFileReview';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  useCurrentUserInfo,
  encryptData
} from '../../../../utils/common/user';
import { CREATE, DELETE, MODULES, UPDATE } from '../../../../constants/constants';

interface SetFileReviewSwitch {
  (value: React.SetStateAction<boolean>): void;
}
interface clientFileProps {
  setFileReviewSwitch: SetFileReviewSwitch;
  setOpenConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setClientFileReviewId: React.Dispatch<React.SetStateAction<number>>;
  setFilereviews: React.Dispatch<React.SetStateAction<FileReviewState[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  fetchFilereviewes: (orderBy: string, order: 'asc' | 'desc') => void; // Corrected definition
  loading: boolean;
  filereviews: FileReviewState[];
  reviewId?: string;
  authToken?: string;
  formData?: ReviewsDataFormState;
  sourceId?: string | null;
  accountInfo?: AccountState;
  currentPage?: number;
  totalPages?: number;
  orderBy: keyof FileReviewState; // Use correct type
  setOrderBy: React.Dispatch<React.SetStateAction<keyof FileReviewState>>; // Match type
  order: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  reviewIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const ClientFileReviews: React.FC<clientFileProps> = ({
  loading,
  filereviews,
  authToken,
  formData,
  sourceId,
  accountInfo,
  currentPage,
  totalPages,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFilereviews,
  setCurrentPage,
  setFileReviewSwitch,
  setOpenConfirmationPopup,
  setClientFileReviewId,
  fetchFilereviewes,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  reviewIdEncrypted,
  sourceIdEncrypted
}) => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const { setNotificationSuccess } = useNotificationContext();
  const [dense] = useState(false);
  const { t } = useTranslation();

  interface HeadCell {
    disablePadding: boolean;
    id: keyof FileReviewState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'client',
      numeric: false,
      disablePadding: true,
      label: 'Client'
    },
    {
      id: 'is_complete',
      numeric: false,
      disablePadding: true,
      label: 'Completed'
    }
  ];

  const handleSort = (property: keyof FileReviewState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchFilereviewes(property, isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const redirectEditPage = (rowid: number, is_complete: number) => {
    if (is_complete == 0 && formData && formData.is_open === 1) {
      navigate(
        `/review/edit-file-review/${encryptData(String(rowid), true)}?source=${sourceIdEncrypted}&reviewId=${reviewIdEncrypted}`
      );
    } else if (formData && is_complete == 1) {
      navigate(
        `/review/view-file-review/${encryptData(String(rowid), true)}?source=${sourceIdEncrypted}&reviewId=${reviewIdEncrypted}`
      );
    }
  };

  const handleSwitchChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    handleSubmit(id, checked);
  };

  const handleSubmit = async (id: number, checked: boolean) => {
    const requestData = {
      is_complete: checked ? checked : false
    };

    try {
      const response: FileReviewResponseError = (await createFileReview(
        requestData,
        authToken,
        accountInfo,
        id
      )) as FileReviewResponseError;

      if (response) {
        const msg = t('success.field.reviewUpdated');
        setNotificationSuccess(msg);
        setFileReviewSwitch((prevState) => !prevState);
        fetchFilereviewes(orderBy, order);
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  let addButton = null;
  if (formData && formData.is_open === 1 && !formData.finalised) {
    addButton = (
      <>
        {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), CREATE) && (
          <Button
            className="btn primary-btn"
            component={RouterLink}
            to={`/review/add-file-review?source=${sourceIdEncrypted}&reviewId=${reviewIdEncrypted}`}>
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Add File Review
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`card-title-wrap secondary-title-wrap ${
          filereviews.length > 0 ? 'no-border-title' : ''
        }`}>
        <h3>Client File Reviews</h3>
        <div className="inner-btn-wrap">{addButton}</div>
      </div>
      <div className="row-wrap client-review-table">
        <TableContainer>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : filereviews.length === 0 ? (
            <div className="no-record no-record-finalise">No file reviews have been created…</div>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="table hover-table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'left' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSort(headCell.id)}>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={{ display: 'none' }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), UPDATE) && (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filereviews.map((row: FileReviewState) => (
                  <React.Fragment key={row.id}>
                    <TableRow key={row.id} id={`main-col-${row.id}`}>
                      {row.created_at ? (
                        <TableCell align="left">
                          {format(new Date(row.created_at), 'd/MMM/yyyy')}
                        </TableCell>
                      ) : (
                        <TableCell align="left">N/A</TableCell>
                      )}
                      <TableCell align="left">{row.client}</TableCell>
                      <TableCell align="left">
                        <div className="switch-wrap">
                          {formData?.finalised ? (
                            <span>{row.is_complete ? 'Yes' : 'No'}</span>
                          ) : (
                            <>
                              <Switch
                                className={`custom-switch custom-switch-inside ${
                                  row.is_complete ? 'custom-switch-complete' : 'custom-switch-open'
                                } `}
                                checked={row.is_complete ? true : false}
                                onChange={handleSwitchChange(row.id)}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                      {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), UPDATE) && (
                        <TableCell align="left" className="right-arrow-width">
                          <div className="table-btn">
                            {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), DELETE) &&
                              !formData?.finalised && (
                                <Button
                                  className="edit"
                                  onClick={() => {
                                    setClientFileReviewId(row.id);
                                    setOpenConfirmationPopup(true);
                                  }}>
                                  <img src={trashGrey} alt="trashGrey" />
                                </Button>
                              )}
                            {row.is_complete == 0 && formData && formData.is_open === 1 && (
                              <Button
                                className="edit"
                                onClick={() => redirectEditPage(row.id, row.is_complete)}>
                                <img src={rightarrow} alt="Right Arrow" />
                              </Button>
                            )}
                            {row.is_complete == 1 && formData && (
                              <Button
                                className="edit"
                                onClick={() => redirectEditPage(row.id, row.is_complete)}>
                                <img src={rightarrow} alt="Right Arrow" />
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
          {!loading && filereviews.length > 0 && (
            <PaginationSettings
              currentPage={currentPage || 0}
              totalPages={totalPages || 0}
              handlePageChange={handlePageChange}
            />
          )}
        </TableContainer>
      </div>
    </>
  );
};

export default ClientFileReviews;
