import { PaginationResponse, NotesState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchNotes = async (
  module_id: number,
  source_id: number | null,
  module_type: string,
  authToken?: string,
  id?: number,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  orderBy: string = 'id',
  order: string = 'desc',
  storedTypeData?: string | null
): Promise<PaginationResponse | NotesState | string | undefined> => {
  let routeUrl;
  if (!id) {
    routeUrl = `${process.env.REACT_APP_API_URL}/notes?module_id=${module_id}&module_type=${module_type}`; // Include pagination parameters
    if (source_id) {
      routeUrl += `&source_id=${source_id}`;
    }
    if (currentPage) {
      routeUrl += `&page=${currentPage}&perPage=${itemsPerPage}&orderBy=${orderBy}&order=${order}`;
    }
    if(storedTypeData){
      routeUrl += `&storedTypeData=${dataEncrypt(storedTypeData)}`;
    }
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/notes/${id}?module_id=${module_id}&module_type=${module_type}`;

    if (source_id) {
      routeUrl += `&source_id=${source_id}`;
    }
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      if ('total' in responseData) {
        responseData.data.total = responseData.total;
      }
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching registers';
  }
};
