import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import LeftNav from '../LeftNav';
import { Button } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData,
  encryptData
} from '../../../../utils/common/user';
import Popup from '../../common/popup/Popup';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import ModulesUpdate from '../modules/ModuleUpdate';
import { fetchAttestationTemplate } from '../../../../api/settings/fetchAttestationTemplate';
import { AttestationTemplateState } from '../../modules/attestations/state';
import { convertToEnterpriseAttestation } from '../../../../api/settings/convertToEnterpriseAttestation';
import AttestationRelevantPeriods from './AttestationRelevantPeriods';

const AttestationsDefault = () => {
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const accountId = selectedAccount?.id;
  const authToken = useAuthToken() || '';
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Attestations';
  const secondTitle = 'Modules';
  const secondpath = '/settings/modules';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [attestationTemplate, setAttestationTemplate] = useState<AttestationTemplateState>({
    account_id: 0,
    module_id: 0,
    attestation_template_id: 1
  });

  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  const getTemplateIdsForModule = async () => {
    try {
      const source_id = parseInt(id!, 10);
      const account_id = parseInt(accountId, 10);
      const response: AttestationTemplateState = (await fetchAttestationTemplate(
        source_id,
        account_id,
        authToken
      )) as AttestationTemplateState;

      if (response.id == 0 || response.id) {
        setAttestationTemplate(response);
      } else {
        console.log(response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);

    getTemplateIdsForModule();
  }, []);

  const handleConvertToEnterprise = () => {
    if (attestationTemplate.id == 0) {
      setOpenAlertPopup(true);
      return;
    }

    setOpenConfirmationPopup(true);
  };

  const togglePopup = (type?: string) => {
    if (type == 'alert') {
      setOpenAlertPopup(false);
    } else if (type == 'confirmation') {
      setOpenConfirmationPopup(false);
    }
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      const source_id = parseInt(id!, 10);
      const account_id = parseInt(accountId, 10);
      const response: AttestationTemplateState = (await convertToEnterpriseAttestation(
        source_id,
        account_id,
        authToken
      )) as AttestationTemplateState;

      if (response.id) {
        getTemplateIdsForModule();
      } else {
        console.log(response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    <form>
                      <div className="card-outline">
                        <div className="card-title-wrap">
                          <h3>Templates</h3>
                        </div>
                        <div className="row-wrap">
                          <div className="inner-btn-wrap inner-attestation">
                            <div
                              className={`form-group ${attestationTemplate.type && attestationTemplate.type == 'master' ? ' second-button-attestation' : 'second-button-attestation-width'}`}>
                              <Button
                                fullWidth
                                className="btn primary-btn btn-sm"
                                component={RouterLink}
                                to={`/settings/attestation-template-edit/${encryptData(String(attestationTemplate.attestation_template_id), true)}?sourceId=${encryptedId}&accountId=${encryptData(String(accountId), true)}`}>
                                <i className="left">
                                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path
                                      d="M17.9999 17.5029H11.3333M2.58325 17.9195L7.20764 16.1409C7.50343 16.0271 7.65132 15.9703 7.78968 15.896C7.91259 15.83 8.02975 15.7539 8.13995 15.6684C8.26402 15.5721 8.37606 15.46 8.60015 15.236L17.9999 5.83619C18.9204 4.91572 18.9204 3.42333 17.9999 2.50286C17.0795 1.58238 15.5871 1.58238 14.6666 2.50285L5.26682 11.9026C5.04273 12.1267 4.93069 12.2388 4.83441 12.3628C4.7489 12.473 4.67275 12.5902 4.60678 12.7131C4.53251 12.8515 4.47563 12.9994 4.36186 13.2951L2.58325 17.9195ZM2.58325 17.9195L4.29835 13.4603C4.42108 13.1412 4.48245 12.9817 4.5877 12.9086C4.67969 12.8447 4.7935 12.8206 4.9035 12.8416C5.02936 12.8656 5.15024 12.9865 5.39199 13.2282L7.27456 15.1108C7.51631 15.3526 7.63719 15.4734 7.66122 15.5993C7.68223 15.7093 7.65807 15.8231 7.59421 15.9151C7.52112 16.0204 7.36158 16.0817 7.04248 16.2044L2.58325 17.9195Z"
                                      stroke="CurrentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </i>
                                {attestationTemplate.type && attestationTemplate.type == 'master'
                                  ? 'Update Master Template'
                                  : 'Update Custom Template'}
                              </Button>
                            </div>
                            {attestationTemplate.type && attestationTemplate.type == 'master' && (
                              <div className="form-group second-button-attestation">
                                <Button
                                  fullWidth
                                  className="btn blue-fill-btn btn-sm"
                                  onClick={handleConvertToEnterprise}>
                                  <i className="left">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                      <path
                                        d="M13.3334 9.99739L10.0001 6.66406M10.0001 6.66406L6.66675 9.9974M10.0001 6.66406V14.3307C10.0001 15.4897 10.0001 16.0691 10.4588 16.7179C10.7637 17.149 11.6413 17.681 12.1644 17.7519C12.9518 17.8586 13.2509 17.7026 13.8489 17.3906C16.514 16.0004 18.3334 13.2114 18.3334 9.99739C18.3334 5.39502 14.6025 1.66406 10.0001 1.66406C5.39771 1.66406 1.66675 5.39502 1.66675 9.9974C1.66675 13.0819 3.34258 15.775 5.83342 17.2159"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </i>
                                  Convert to custom template
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>

                    <AttestationRelevantPeriods accountId={accountId} authToken={authToken} />

                    <ModulesUpdate />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Popup
            type="alert"
            openPopup={openAlertPopup}
            text={`Mark Attestation Module as visible for ${selectedAccount.name} account.`}
            togglePopup={togglePopup}
          />

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to convert ${selectedAccount.name} Account to use a custom template?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default AttestationsDefault;
