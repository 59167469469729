import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import {
  RequestPrevetsDataFormState,
  RequestPrevetsResponseError
} from '../../views/components/modules/pre-vet/state';

export const requestPrevet = async (
  formData: RequestPrevetsDataFormState,
  authToken?: string,
  accountInfo?: AccountState
): Promise<RequestPrevetsResponseError | string | number | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const { client_names, reviewer_id, completion_date, comment, document } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('client_names', client_names);
    formData.append('reviewer_id', reviewer_id.toString());
    if(completion_date){
      formData.append('completion_date', completion_date);
    }
    formData.append('comment', comment);
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    if (document) {
      formData.append('document', document);
    }

    const routeUrl = `${process.env.REACT_APP_API_URL}/request-prevet`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
