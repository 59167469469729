import React from 'react';
import TextField from '@mui/material/TextField';
import colorsIc from '../../../../assets/images/colors.svg';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, FormControl, MenuItem, /*OutlinedInput,*/ Select } from '@mui/material';
import { AccountDetailsFormsProps } from '../state';

const AccountDetailsForm: React.FC<AccountDetailsFormsProps> = ({
  formData,
  handleInputChange,
  handleSubmit,
  handleFileChange,
  handleCancelClick,
  previewURL,
  fetchLogo,
  errors,
  selectedFile,
  prefixDisabled,
  users,
  // adviserUsers,
  handleSelectChange,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="card-outline">
        <div className="row-wrap">
          <div className="form-group">
            <label className="form-label" htmlFor="Account Name">
              Account name
            </label>
            <TextField
              error={!!errors.accountName}
              id="accountName"
              variant="outlined"
              name="accountName"
              value={formData.accountName}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Account Name"
              type="text"
              helperText={errors.accountName}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="Account Prefix">
              Account prefix
            </label>
            <TextField
              error={!!errors.accountPrefix}
              id="accountPrefix"
              value={formData.accountPrefix !== null ? formData.accountPrefix : ''}
              variant="outlined"
              name="accountPrefix"
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Account Prefix"
              type="text"
              disabled={prefixDisabled}
              helperText={errors.accountPrefix}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="Primary Color">
              Primary colour
            </label>
            <TextField
              id="primaryColor"
              variant="outlined"
              name="primaryColor"
              onChange={handleInputChange}
              className="form-control"
              type="color"
              value={formData.primaryColor !== null ? formData.primaryColor : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={colorsIc} alt="" />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="Compliance query email">
              Compliance query email
            </label>
            <TextField
              id="compliance_query_email"
              value={
                formData.compliance_query_email !== null ? formData.compliance_query_email : ''
              }
              variant="outlined"
              name="compliance_query_email"
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Compliance query email"
              type="text"
            />
          </div>

          <div className="form-group w-full file-upload">
            <label className="form-label large" htmlFor="Logo">
              Logo upload
            </label>
            <div className="relative">
              <TextField
                id="logo"
                variant="outlined"
                name="logo"
                onChange={handleFileChange}
                className="form-control"
                InputProps={{
                  startAdornment: (
                    <input
                      type="file"
                      accept="image/*"
                      className="file-upload-input"
                      onChange={handleFileChange}
                      title="file"
                    />
                  )
                }}
                helperText={errors.logo}
              />
              {selectedFile ? (
                <div className="preview-uploaded">
                  {previewURL && <img src={previewURL} alt="Preview" />}
                  <p className="file-name">
                    <span className="blue-text">Selected file:</span> {selectedFile.name}
                  </p>
                </div>
              ) : fetchLogo != null ? (
                <div className="preview-uploaded">
                  <img src={`${fetchLogo.path}`} alt="Logo" />
                  <div className="mb-2">
                    <p className="file-name">
                      <span>
                        <span className="blue-text">Click to upload</span> or drag and drop
                      </span>
                    </p>
                    <p className="file-name small-text">
                      <span className="small-text">Logo must be 300px by 60px PNG file.</span>
                    </p>
                  </div>
                </div>
              ) : (
                <p className="placeholder-text">
                  <img src={fileUploadIc} alt="File upload" />
                  <span>
                    <span className="blue-text">Click to upload</span> or drag and drop
                  </span>
                  <span className="small-text">Logo must be 300px by 60px PNG file.</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-outline">
        <div className="row-wrap">
          <div className="form-group w-full">
            <label className="form-label">Users with access to this Account</label>
            <FormControl variant="outlined">
              <Select
                labelId="users-associated-label"
                id="users-associated"
                name="users_associated"
                className="custom-select"
                multiple
                displayEmpty
                value={formData.users_associated}
                onChange={handleSelectChange}
                
                renderValue={(selected) => {
                  if (!selected.length) {
                    return <p className="dropdown-placeholder">Select the assosiated users</p>; // Render placeholder if no attendees selected
                  }
                  const selectedOptions = selected.map(
                    (selectedId) =>
                      users && users.find((option) => option.id === selectedId)
                  );
                  return (
                    selectedOptions &&
                    selectedOptions.map((selectedOption, index) => (
                      <span key={selectedOption && selectedOption.id}>
                        {selectedOption && selectedOption.name}
                        {index < selectedOptions.length - 1 ? ', ' : ''}{' '}
                        {/* Add comma separator if not last item */}
                      </span>
                    ))
                  );
                }}
                MenuProps={{ className: 'custom-dropdown-menu' }}>
                {users &&
                  users.map((user) => (
                    <MenuItem key={`associated-${user.id}`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {/* <div className="form-group w-full">
            <label className="form-label">Users Adviser</label>
            <FormControl variant="outlined">
              <Select
                labelId="users-associated-label"
                id="users-adviser"
                name="users_adviser"
                className="custom-select"
                multiple
                displayEmpty
                value={formData.users_adviser}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Users Adviser" />}
                renderValue={(selected) => {
                  if (!selected.length) {
                    return <p className="dropdown-placeholder">Select the adviser users</p>; // Render placeholder if no attendees selected
                  }
                  const selectedOptions = selected.map(
                    (selectedId) =>
                      adviserUsers && adviserUsers.find((option) => option.id === selectedId)
                  );
                  return (
                    selectedOptions &&
                    selectedOptions.map((selectedOption, index) => (
                      <span key={selectedOption && selectedOption.id}>
                        {selectedOption && selectedOption.name}
                        {index < selectedOptions.length - 1 ? ', ' : ''}{' '}
                      </span>
                    ))
                  );
                }}
                MenuProps={{ className: 'custom-dropdown-menu' }}>
                {adviserUsers &&
                  adviserUsers.map((user) => (
                    <MenuItem key={`adviser-${user.id}`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}
        </div>
      </div>
      <div className="btn-wrap">
        <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" className="btn primary-btn btn-sm">
          Save changes
        </Button>
      </div>
    </form>
  );
};

export default AccountDetailsForm;
