import axios from 'axios';
import { UserAccessLevelPermissionState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const assignRemovePermissions = async (
  formData: UserAccessLevelPermissionState,
  authToken?: string
): Promise<UserAccessLevelPermissionState | string | undefined | null> => {
  const { id, lu_user_access_level_id, page, sub_page, permissions } = formData;
  let routeUrl;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('lu_user_access_level_id', lu_user_access_level_id.toString());
    formData.append('page', page);
    formData.append('sub_page', sub_page.toString());
    formData.append('permissions', JSON.stringify(permissions));

    if (id) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/assign-remove-permission/${dataEncrypt(id?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/assign-remove-permission`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
