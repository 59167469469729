import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { AccountState } from './state';
import {
  useCurrentUserInfo,
  setSelectedAccountInfo,
  useAuthToken,
  checkUserIsNewEntrant,
  decryptData,
  dataEncrypt
} from '../../../utils/common/user';
import { setAccounts } from '../../../store/account/accountSlice';
import { RootState } from '../../../store/store';
import { updateloginInfoAccount } from '../../../api/user/updateloginInfoAccount';
import CommonLayout from '../layout/CommonLayout';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const AccountSelection: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();

  // Use the useSelector hook to get the accounts from the Redux store
  const accounts = useSelector((state: RootState) => state?.account?.accounts) || [];
  const title = 'Account selection';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (user) {
      const userId = user.id;
      fetch(`${process.env.REACT_APP_API_URL}/user/${dataEncrypt(userId.toString())}/accounts`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const userAccountsData = data.data;

          dispatch(setAccounts(userAccountsData));
          const storedRedirect = localStorage.getItem('redirectAfterLogin');
          if (storedRedirect) {
            try {
              const url = new URL(storedRedirect);
              localStorage.removeItem('redirectAfterLogin'); // Clear the stored URL
              if (url.searchParams.has('account_id') && userAccountsData) {
                let accountId = url.searchParams.get('account_id');
                accountId = decryptData(accountId, true);
                const account_Id = parseInt(accountId!, 10);
                const isMatchingAccountId = userAccountsData.some(
                  (account: { id: number | null }) => account.id === account_Id
                );
                if (isMatchingAccountId) {
                  setSelectedAccountInfo(account_Id, userAccountsData);
                  updateloginInfoAccount(account_Id, authToken);

                  window.location.href = storedRedirect; // Redirect the user
                }
              }
            } catch (error) {
              console.error('Error handling URL:', error);
            }
          } else if (userAccountsData?.length === 1) {
            setSelectedAccountInfo(userAccountsData[0].id, userAccountsData);
            updateloginInfoAccount(userAccountsData[0].id, authToken);

            if (!checkUserIsNewEntrant(user)) {
              navigate('/dashboard');
            } else {
              navigate('/new-entrant');
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching accounts:', error);
        });
    } else {
      navigate('/login');
    }
  }, [dispatch]);

  const handleAccountClick = (accountId: number) => {
    setSelectedAccountInfo(accountId, accounts);
    updateloginInfoAccount(accountId, authToken);

    if (!checkUserIsNewEntrant(user)) {
      navigate('/dashboard');
    } else {
      navigate('/new-entrant');
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner large">
              <div className="logo-wrap">
                <Link to="/">
                  <LazyImage src={`${S3PublicUrl}3lines-logo.svg`} alt="logo" />
                </Link>
              </div>
              <div className="title-wrap">
                <h1>Please select an account</h1>
                <p>Welcome back! Please select an account.</p>
              </div>
              <form>
                {accounts.length > 0 && (
                  <div className="selection-btn-wrap">
                    {accounts.map((account: AccountState) => (
                      <Button
                        key={account.id}
                        className="btn selection-btn"
                        onClick={() => handleAccountClick(account.id)}
                        disabled={user?.lu_user_access_level_id !== 1 && !account.is_open}
                        style={{
                          cursor:
                            user?.lu_user_access_level_id !== 1 && !account.is_open
                              ? 'not-allowed'
                              : 'pointer'
                        }}>
                        {user?.lu_user_access_level_id !== 1 && !account.is_open ? (
                          <LockOutlinedIcon style={{ color: '#98a2b3' }} />
                        ) : (
                          ''
                        )}
                        <span className="img-wrap">
                          <div className="image-container">
                            {account.image ? (
                              <img
                                src={`${JSON.parse(account.image).path}`}
                                alt={account.name || ''}
                                style={{ display: imageLoaded ? 'block' : 'none' }}
                                onError={handleImageError}
                                onLoad={handleImageLoad}
                              />
                            ) : null}
                            {(!imageLoaded || !account.image) && (
                              <p
                                className="name"
                                style={{
                                  color:
                                    user?.lu_user_access_level_id !== 1 && !account.is_open
                                      ? '#98a2b3'
                                      : 'inherit'
                                }}>
                                {account.name}
                              </p>
                            )}
                          </div>
                        </span>

                        <i className="arrow">
                        {user?.lu_user_access_level_id !== 1 && !account.is_open ? '' : <ArrowForwardIcon />}
                        </i>
                      </Button>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-select-account.png`}
                  alt=""
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default AccountSelection;
