import React, { useEffect, useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import { Alert, Button } from '@mui/material';
import {
  encryptData,
  // checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../utils/common/user';
import { fetchProfessionalYearForNewEntrant } from '../../../api/professional-year/fetchProfessionalYearForNewEntrant';
import { ProfessionalYearState } from '../modules/professional-years/state';
import { t } from 'i18next';
import { Link as RouterLink } from 'react-router-dom';
import ProfessionalYearTemplateDialog from '../modules/professional-years/new-entrant/ProfessionalYearTemplateDialog';
// import { CREATE, MODULES, READ, REGISTERS } from '../../../constants/constants';
import ProfessionalYearLogbookWorkactivityTemplateTableMode from '../modules/professional-years/ProfessionalYearLogbookWorkactivityTemplateTableMode';
import ProfessionalYearLogTrainingTemplateTableMode from '../modules/professional-years/ProfessionalYearLogTrainingTemplateTableMode';
import TaskColumn from './TaskColumn';
import UpdateColumn from './UpdateColumn';
import { format } from 'date-fns';
import { useNotificationContext } from '../layout/NotificationContext';

const NewEntrantDashboard: React.FC = () => {
  const metapageTitle = `${process.env.REACT_APP_NAME}: Dashboard`;

  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const authToken = useAuthToken();
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [templateType, setTemplateType] = useState<string>('');
  const [dataId, setDataId] = useState<number>(0);
  const [workActivityLogAdded, setWorkActivityLogAdded] = useState<boolean>(false);
  const [trainingLogAdded, setTrainingLogAdded] = useState<boolean>(false);
  const [formData, setFormData] = useState<ProfessionalYearState>({
    id: 0,
    plan_complete: 0,
    q1_complete: 0,
    q2_complete: 0,
    q3_complete: 0,
    q4_complete: 0,
    exam_passed: 0,
    exam_passed_confirmation: 0
  });

  let successAlertTimer: ReturnType<typeof setTimeout>;
  const { notificationSuccess, setNotificationSuccess } = useNotificationContext();
  useEffect(() => {
    if (notificationSuccess) {
      successAlertTimer = setTimeout(() => {
        setNotificationSuccess('');
      }, 3000);
    }
    return () => {
      clearTimeout(successAlertTimer);
    };
  }, [notificationSuccess, setNotificationSuccess]);

  const fetchProfessionalYearData = async () => {
    if (authToken) {
      try {
        const response: ProfessionalYearState = (await fetchProfessionalYearForNewEntrant(
          authToken,
          selectedAccount,
          user?.id
        )) as ProfessionalYearState;

        if (response) {
          setFormData(response);
          localStorage.setItem('professional-year-data', JSON.stringify(response));
          localStorage.setItem('professional_year_unique_id', response.unique_id || '');
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    fetchProfessionalYearData();
  }, [workActivityLogAdded, trainingLogAdded]);

  const toggleTemplateDialog = (type: string = '', id: number = 0) => {
    setTemplateType(type);
    setDataId(id);
    setOpenTemplateDialog(!openTemplateDialog);
  };

  const fetchReportPath = (report: string | undefined) => {
    const reportObject = report ? JSON.parse(report) : null;
    const typedReportObject = reportObject as { path: string; original_name: string };

    if (report) {
      return typedReportObject.path;
    } else {
      return null;
    }
  };

  const handleViewCertificate = (certificate: string | undefined) => {
    const report = fetchReportPath(certificate);
    const componentBUrl = report ? `${report}` : '';
    window.open(componentBUrl, '_blank');
  };

  return (
    <>
      <CommonLayout title={metapageTitle}>
        <div className="container-full">
          <div className="dashboard-outer-wrapper">
            <TaskColumn />

            <div className="dashboard-middles-col">
              <div className="professional-year-new-entrant">
                <div>
                  <h2>Professional Year</h2>
                </div>
                {/* Success Message */}
                {notificationSuccess && (
                  <Alert variant="outlined" severity="success">
                    {notificationSuccess}
                  </Alert>
                )}
              </div>
              {formData && formData.id ? (
                <>
                  <div className="outer-btn-wrapper">
                    <Button
                      className="btn btn-sm blue-fill-btn"
                      onClick={() => toggleTemplateDialog(t('templateType.logWorkactivity'))}>
                      <i className="left">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <g clipPath="url(#clip0_6545_879)">
                            <path
                              d="M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z"
                              stroke="CurrentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6545_879">
                              <rect width="18" height="18" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      Log work activity
                    </Button>
                    <Button
                      className="btn btn-sm blue-fill-btn"
                      onClick={() => toggleTemplateDialog(t('templateType.logTraining'))}>
                      <i className="left">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <g clipPath="url(#clip0_6545_879)">
                            <path
                              d="M9 5.66667V12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z"
                              stroke="CurrentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6545_879">
                              <rect width="18" height="18" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      Log training
                    </Button>
                  </div>

                  {/* Progress Card */}
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <div>
                        <h3>Progress</h3>
                      </div>
                    </div>
                    <div className="progress-outer">
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.plan_complete == 0 ? 'red' : formData.plan_complete == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Annual Plan</h3>
                            {/* <p>Start date: 01/Jan/2024</p> */}
                          </div>
                          <div className="progress-btn-wrap">
                            {formData.plan_complete == 1 && (
                              <Button
                                className="btn btn-sm blue-fill-btn"
                                component={RouterLink}
                                to={`/new-entrant/plan`}>
                                <i className="left">
                                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <g clipPath="url(#clip0_6545_879)">
                                      <path
                                        d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_6545_879">
                                        <rect width="20" height="20" fill="white"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </i>
                                View
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.plan_complete == 1 && formData.q1_complete == 0 ? 'orange' : formData.plan_complete == 1 && formData.q1_complete == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Q1 Plan</h3>
                            <p>
                              {formData.q1_start_date
                                ? `Start date: ${format(new Date(formData.q1_start_date || ''), 'd/MMM/yyyy')}`
                                : 'Not yet started'}
                            </p>
                          </div>
                          {formData.plan_complete == 1 && (
                            <div className="progress-btn-wrap">
                              {formData.q1_complete == 0 ? (
                                <Button
                                  className="btn btn-sm blue-fill-btn"
                                  component={RouterLink}
                                  to={`/new-entrant/q1plan-entrant-edit`}>
                                  <i className="left">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                      <g clipPath="url(#clip0_6545_879)">
                                        <path
                                          d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_6545_879">
                                          <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Update
                                </Button>
                              ) : formData.q1_complete == 1 ? (
                                <>
                                  <Button
                                    className="btn btn-sm white-btn"
                                    onClick={() =>
                                      handleViewCertificate(formData.q1_completion_certificate)
                                    }>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            strokeWidth="1.2"
                                            stroke="#667085"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    Completion Certificate
                                  </Button>
                                  <Button
                                    className="btn btn-sm blue-fill-btn"
                                    component={RouterLink}
                                    to={`/new-entrant/q1plan-entrant-view`}>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            stroke="CurrentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    View
                                  </Button>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.q1_complete == 1 && formData.q2_complete == 0 ? 'orange' : formData.q1_complete == 1 && formData.q2_complete == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Q2 Plan</h3>
                            <p>
                              {formData.q2_start_date
                                ? `Start date: ${format(new Date(formData.q2_start_date || ''), 'd/MMM/yyyy')}`
                                : 'Not yet started'}
                            </p>
                          </div>
                          {formData.q1_complete == 1 && (
                            <div className="progress-btn-wrap">
                              {formData.q2_complete == 0 ? (
                                <Button
                                  className="btn btn-sm blue-fill-btn"
                                  component={RouterLink}
                                  to={`/new-entrant/q2plan-entrant-edit`}>
                                  <i className="left">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                      <g clipPath="url(#clip0_6545_879)">
                                        <path
                                          d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_6545_879">
                                          <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Update
                                </Button>
                              ) : formData.q2_complete == 1 ? (
                                <>
                                  <Button
                                    className="btn btn-sm white-btn"
                                    onClick={() =>
                                      handleViewCertificate(formData.q2_completion_certificate)
                                    }>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            strokeWidth="1.2"
                                            stroke="#667085"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    Completion Certificate
                                  </Button>
                                  <Button
                                    className="btn btn-sm blue-fill-btn"
                                    component={RouterLink}
                                    to={`/new-entrant/q2plan-entrant-view`}>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            stroke="CurrentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    View
                                  </Button>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.exam_passed == 1 && formData.exam_passed_confirmation == 0 ? 'orange' : formData.exam_passed == 1 && formData.exam_passed_confirmation == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Financial Adviser Exam</h3>
                            {/* <p>Required</p> */}
                          </div>
                          <div className="progress-btn-wrap">
                            {formData.exam_passed_confirmation == 0 ? (
                              <Button
                                className="btn btn-sm blue-fill-btn"
                                component={RouterLink}
                                to={`/new-entrant/exam`}>
                                <i className="left">
                                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <g clipPath="url(#clip0_6545_879)">
                                      <path
                                        d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_6545_879">
                                        <rect width="20" height="20" fill="white"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </i>
                                Update
                              </Button>
                            ) : formData.exam_passed_confirmation == 1 ? (
                              <Button
                                className="btn btn-sm blue-fill-btn"
                                component={RouterLink}
                                to={`/new-entrant/exam-view`}>
                                <i className="left">
                                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <g clipPath="url(#clip0_6545_879)">
                                      <path
                                        d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                        stroke="CurrentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_6545_879">
                                        <rect width="20" height="20" fill="white"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </i>
                                View
                              </Button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.q2_complete == 1 && formData.q3_complete == 0 ? 'orange' : formData.q2_complete == 1 && formData.q3_complete == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Q3 Plan</h3>
                            <p>
                              {formData.q3_start_date
                                ? `Start date: ${format(new Date(formData.q3_start_date || ''), 'd/MMM/yyyy')}`
                                : 'Not yet started'}
                            </p>
                          </div>
                          {formData.q2_complete == 1 && (
                            <div className="progress-btn-wrap">
                              {formData.q3_complete == 0 ? (
                                <Button
                                  className="btn btn-sm blue-fill-btn"
                                  component={RouterLink}
                                  to={`/new-entrant/q3plan-entrant-edit`}>
                                  <i className="left">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                      <g clipPath="url(#clip0_6545_879)">
                                        <path
                                          d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_6545_879">
                                          <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Update
                                </Button>
                              ) : formData.q3_complete == 1 ? (
                                <>
                                  <Button
                                    className="btn btn-sm white-btn"
                                    onClick={() =>
                                      handleViewCertificate(formData.q3_completion_certificate)
                                    }>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            strokeWidth="1.2"
                                            stroke="#667085"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    Completion Certificate
                                  </Button>
                                  <Button
                                    className="btn btn-sm blue-fill-btn"
                                    component={RouterLink}
                                    to={`/new-entrant/q3plan-entrant-view`}>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            stroke="CurrentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    View
                                  </Button>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.q3_complete == 1 && formData.q4_complete == 0 ? 'orange' : formData.q3_complete == 1 && formData.q4_complete == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Q4 Plan</h3>
                            <p>
                              {formData.q4_start_date
                                ? `Start date: ${format(new Date(formData.q4_start_date || ''), 'd/MMM/yyyy')}`
                                : 'Not yet started'}
                            </p>
                          </div>
                          {formData.q3_complete == 1 && (
                            <div className="progress-btn-wrap">
                              {formData.q4_complete == 0 ? (
                                <Button
                                  className="btn btn-sm blue-fill-btn"
                                  component={RouterLink}
                                  to={`/new-entrant/q4plan-entrant-edit`}>
                                  <i className="left">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                      <g clipPath="url(#clip0_6545_879)">
                                        <path
                                          d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                          stroke="CurrentColor"
                                          strokeWidth="1.66667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_6545_879">
                                          <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Update
                                </Button>
                              ) : formData.q4_complete == 1 ? (
                                <>
                                  <Button
                                    className="btn btn-sm white-btn"
                                    onClick={() =>
                                      handleViewCertificate(formData.q4_completion_certificate)
                                    }>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            strokeWidth="1.2"
                                            stroke="#667085"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    Completion Certificate
                                  </Button>
                                  <Button
                                    className="btn btn-sm blue-fill-btn"
                                    component={RouterLink}
                                    to={`/new-entrant/q4plan-entrant-view`}>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            stroke="CurrentColor"
                                            strokeWidth="1.66667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    View
                                  </Button>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="progress-list">
                        <div
                          className={`progress-inner ${formData.py_complete_supervisor == 1 && formData.py_complete_licensee == 0 ? 'orange' : formData.py_complete_supervisor == 1 && formData.py_complete_licensee == 1 ? 'green' : ''}`}>
                          <div className="content-wrapper">
                            <h3>Completion</h3>
                          </div>
                          {formData.q4_complete == 1 && (
                            <div className="progress-btn-wrap">
                              {formData.py_complete_supervisor == 1 &&
                              formData.py_complete_licensee == 1 ? (
                                <>
                                  <Button
                                    className="btn btn-sm white-btn"
                                    onClick={() =>
                                      handleViewCertificate(formData.completion_certificate)
                                    }>
                                    <i className="left">
                                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <g clipPath="url(#clip0_6545_879)">
                                          <path
                                            d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                            strokeWidth="1.2"
                                            stroke="#667085"></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_6545_879">
                                            <rect width="20" height="20" fill="white"></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </i>
                                    Completion Certificate
                                  </Button>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Workactiviry Log Card */}
                  <div className="card-outline">
                    <ProfessionalYearLogbookWorkactivityTemplateTableMode
                      user={user}
                      sourceId={8}
                      formData={formData}
                      professionalYearId={formData.id}
                      authToken={authToken}
                      workActivityLogAdded={workActivityLogAdded}
                      setWorkActivityLogAdded={setWorkActivityLogAdded}
                      toggleTemplateDialog={toggleTemplateDialog}
                      professionalYearIdEncrypted={encryptData(String(formData.id), true)}
                      sourceIdEncrypted={encryptData(String(8), true)}
                    />
                  </div>

                  {/* Training Log Card */}
                  <div className="card-outline">
                    <ProfessionalYearLogTrainingTemplateTableMode
                      user={user}
                      sourceId={8}
                      formData={formData}
                      professionalYearId={formData.id}
                      authToken={authToken}
                      trainingLogAdded={trainingLogAdded}
                      setTrainingLogAdded={setTrainingLogAdded}
                      toggleTemplateDialog={toggleTemplateDialog}
                      professionalYearIdEncrypted={encryptData(String(formData.id), true)}
                      sourceIdEncrypted={encryptData(String(8), true)}
                    />
                  </div>
                </>
              ) : (
                <div>Professional Year record has not been set up yet</div>
              )}
            </div>

            <UpdateColumn />
          </div>
        </div>
      </CommonLayout>

      <ProfessionalYearTemplateDialog
        templateType={templateType}
        formData={formData}
        professionalYearId={formData.id || 0}
        dataId={dataId}
        openTemplateDialog={openTemplateDialog}
        toggleTemplateDialog={toggleTemplateDialog}
        setWorkActivityLogAdded={setWorkActivityLogAdded}
        setTrainingLogAdded={setTrainingLogAdded}
      />
    </>
  );
};

export default NewEntrantDashboard;
