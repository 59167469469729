import axios from 'axios';
import { RegisterDataFormState } from '../../views/components/settings/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { RegistersState } from '../../views/components/user/state';

export const updateRegisters = async (
  formData: RegisterDataFormState,
  authToken?: string,
  accountInfo?: AccountState
): Promise<RegisterDataFormState | RegistersState | string | number | undefined> => {
  const {
    id,
    name,
    add_record_label,
    prefix,
    description,
    instructions,
    logo,
    default_for_starter,
    default_for_premium
  } = formData;
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('name', name);
    formData.append('add_record_label', add_record_label || '');
    formData.append('prefix', prefix || '');
    formData.append('description', description || '');
    formData.append('instructions', instructions || '');
    formData.append('default_for_starter', default_for_starter?.toString() || '0');
    formData.append('default_for_premium', default_for_premium?.toString() || '0');
    if (logo) {
      formData.append('logo', logo); // Attach the logo as a Blob
    }
    if (id) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/registers/${dataEncrypt(id?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/registers`;
    }
    if (selectedAccountId) {
      formData.append('accountId', selectedAccountId.toString());
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const duplicateRegisters = async (
  id?: number,
  authToken?: string,
  accountInfo?: AccountState
): Promise<RegisterDataFormState | RegistersState | string | number | undefined> => {
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  try {
    const formData = new FormData(); // Create a FormData object
    if (selectedAccountId) {
      formData.append('accountId', selectedAccountId.toString());
    }
    formData.append('_method', 'PUT');
    routeUrl = `${process.env.REACT_APP_API_URL}/duplicate-register/${dataEncrypt(id?.toString() ?? '0')}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
