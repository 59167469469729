import React, { useState, useEffect } from 'react';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import CommonLayout from '../../layout/CommonLayout';
import { useCurrentUserInfo } from './../../../../utils/common/user';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import ModulesUpdate from '../modules/ModuleUpdate';

const CalenderEdit: React.FC = () => {
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Compliance Calender';
  const secondTitle = 'Modules';
  const secondpath = '/settings/modules';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  
  
  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true)
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <ModulesUpdate />
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default CalenderEdit;
