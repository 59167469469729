import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { CalendarFormData, CalendarResponseError } from '../../views/components/modules/compliance-calendar/state';

export const createCalendarData = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  moduleId?: string | number,
): Promise<CalendarFormData | CalendarResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { subject, required_action, owner_id, color, event_date, recurrence_rule, recurrence_type, create_task, task_days, regulatory_reference, is_recurring, module_id, recurring_event_dates, is_open } =
    formData;

  try {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('owner_id ', owner_id);
    formData.append('required_action', required_action);
    formData.append('module_id', module_id);
    formData.append('regulatory_reference', regulatory_reference);
    formData.append('color', color);
    formData.append('event_date', event_date);
    formData.append('create_task', create_task.toString());
    formData.append('task_days', task_days.toString());
    formData.append('is_recurring', is_recurring);
    formData.append('recurrence_type', recurrence_type);
    formData.append('recurrence_rule', recurrence_rule);
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('recurring_event_dates[]', recurring_event_dates);
    formData.append('is_open', is_open);

    if (moduleId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/calender-event/${dataEncrypt(moduleId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/calender-event`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

