import React, { useState, useEffect } from 'react';
import {
  FormControl,
  MenuItem,
  Typography,
  Button,
  Select,
  TextField,
  CircularProgress
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData,
  encryptData
} from '../../../../utils/common/user';
import { FileReviewResponseError, FileReviewFormState } from './state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useTranslation } from 'react-i18next';
import CommonLayout from '../../layout/CommonLayout';
import { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { createFileReview } from '../../../../api/adviser/createFileReview';
import { FileReviewTemplatesState } from '../../user/state';
// import { fetchFileReviewTemplates } from '../../../../api/adviser/fetchFileReviewTemplates';
import { fetchFileReviewTemplatesFiltered } from '../../../../api/adviser/fetchFileReviewTemplatesFiltered';

const FileReviewAdd: React.FC = () => {
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let reviewId = queryParams.get('reviewId');
  const encryptedReviewId = reviewId; // Decrypt the ID
  reviewId = decryptData(reviewId, true);
  const review_id = parseInt(reviewId!, 10);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const mainTitle = 'Adviser Reviews';
  const mainPath = `/reviews/${encryptedSourceId}`;
  const title = 'Add Client File Review';
  const pagetitle = 'Add Client File Review';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { setNotificationSuccess } = useNotificationContext();
  const selectedAccount = getSelectedAccountInfo();
  const [templateselect, settemplateselect] = useState<string>('');
  const [fileReviewTemplates, setFileReviewTemplates] = useState<FileReviewTemplatesState[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  let secondTitle;
  const unique_id = localStorage.getItem('review_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/review/${encryptedReviewId}?source=${encryptedSourceId}`;

  const [formData, setFormData] = useState<FileReviewFormState>({
    review_id: review_id,
    file_review_template_id: 0,
    client: '',
    error: ''
  });

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.client) {
      newErrors.client = t('error.field.client');
    }
    if (!templateselect) {
      newErrors.file_review_template_id = t('error.field.filereviewtemp');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    fetchFileReviewTemplatesdata();
  }, [reviewId]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    const requestData = {
      file_review_template_id: templateselect,
      client: formData.client,
      review_id: review_id
    };
    const response: FileReviewResponseError = (await createFileReview(
      requestData,
      authToken,
      selectedAccount
    )) as FileReviewResponseError;
    if (response && !response.id) {
      const newErrors: Record<string, string> = {};
      if (response.client) {
        newErrors.client = response.client[0];
      }
      if (response.file_review_template_id) {
        newErrors.file_review_template_id = response.file_review_template_id[0];
      } else {
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
      setErrors(newErrors);
    } else {
      const msg = t('success.field.filereviewAdded');

      setNotificationSuccess(msg);
      if (response.id) {
        navigate(
          `/review/edit-file-review/${encryptData(String(response.id), true)}?source=${encryptedSourceId}&reviewId=${encryptedReviewId}`
        );
      } else {
        navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
      }
    }
  };

  const fetchFileReviewTemplatesdata = async () => {
    try {
      if (authToken) {
        const response: FileReviewTemplatesState[] = (await fetchFileReviewTemplatesFiltered(
          authToken,
          selectedAccount,
          0,
          0,
          0,
          0,
          true
        )) as FileReviewTemplatesState[];

        if (response) {
          // Update the state with the fetched templates
          if (Array.isArray(response) && response.length > 0) {
            setFileReviewTemplates(response);
          } else {
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.noFileReviewTemplates')
            }));
          }
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  // const fetchFileReviewTemplatesdata = async () => {
  //   try {
  //     if (authToken) {
  //       const response: FileReviewTemplatesState[] = (await fetchFileReviewTemplates(
  //         authToken,
  //         selectedAccount,
  //         0,
  //         0,
  //         0,
  //         0,
  //         true
  //       )) as FileReviewTemplatesState[];

  //       if (response) {
  //         // Update the state with the fetched templates
  //         if (Array.isArray(response) && response.length > 0) {
  //           setFileReviewTemplates(response);
  //         } else {
  //           setFormData((prevData) => ({
  //             ...prevData,
  //             error: t('error.field.noFileReviewTemplates')
  //           }));
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const handleCancelClick = () => {
    navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangetemplate = (event: SelectChangeEvent<string>) => {
    settemplateselect(event.target.value);
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={pagetitle}
            secondTitle={secondTitle}
            secondpath={secondpath}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <ReviewLeftMenu
              user={user}
              reviewId={reviewId ? reviewId : ''}
              sourceId={sourceId ? sourceId : ''}
              reviewIdEncrypted={encryptedReviewId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="form-wrap">
                  <form onSubmit={handleSubmit}>
                    <div className="card-outline">
                      <div className="card-title-wrap">
                        <h3>Client Details</h3>
                      </div>
                      {loading ? (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="row-wrap">
                          <div className="form-group">
                            <label className="form-label">Client</label>
                            <TextField
                              error={!!errors.client}
                              id="Client"
                              variant="outlined"
                              name="client"
                              value={formData.client ? formData.client : ''}
                              className="form-control"
                              onChange={handleInputChange}
                              placeholder="Enter client name..."
                              type="text"
                              helperText={errors.client}
                              inputProps={{
                                maxLength: 50 // Set the maximum character length
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label className="form-label">Question set</label>
                            <FormControl variant="outlined" error={!!errors.client_id}>
                              <Select
                                error={!!errors.file_review_template_id}
                                onChange={handleChangetemplate}
                                labelId="Complaint-owner-label"
                                id="Complaint-owner"
                                value={templateselect}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                className="custom-select"
                                MenuProps={{ className: 'custom-dropdown-menu' }}
                                displayEmpty
                                renderValue={(selected) => {
                                  if (selected === '') {
                                    return (
                                      <p className="dropdown-placeholder">Select Question set</p>
                                    ); // Render "Select Question set" as placeholder
                                  }
                                  const selectedOption =
                                    fileReviewTemplates &&
                                    fileReviewTemplates.find(
                                      (option) => option.id === parseInt(selected, 10)
                                    );
                                  return selectedOption ? `${selectedOption.name}` : '';
                                }}>
                                {fileReviewTemplates.map((row: FileReviewTemplatesState) => (
                                  <MenuItem key={row?.id} value={row?.id || ''}>
                                    {row?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.file_review_template_id && (
                              <Typography variant="caption" color="error">
                                {errors.file_review_template_id}
                              </Typography>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="btn-wrap">
                      <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                      {Array.isArray(fileReviewTemplates) && fileReviewTemplates.length > 0 && (
                        <Button type="submit" className="btn primary-btn btn-sm">
                          Save
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default FileReviewAdd;
