import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { fetchRegistersHeaderListing } from '../../../api/dashboard/fetchRegistersHeaderListing';
import { getSelectedAccountInfo, useAuthToken } from '../../../utils/common/user';
import { RegistersState } from '../user/state';

interface RegistersProviderProps {
  children: ReactNode;
}

interface RegistersContextType {
  registersRecords: RegistersState[];
  fetchRegisterRecords: () => void;
}

const RegistersContext = createContext<RegistersContextType | undefined>(undefined);

export const useRegistersContext = () => {
  const context = useContext(RegistersContext);
  if (!context) {
    throw new Error('useRegistersContext must be used within a RegistersProvider');
  }
  return context;
};

export const RegistersProvider: React.FC<RegistersProviderProps> = ({ children }) => {
  const selectedAccount = getSelectedAccountInfo();
  const authToken = useAuthToken();
  const [registersRecords, setRegistersRecords] = useState<RegistersState[]>([]);

  const fetchRegisterRecords = async () => {
    try {
      // Fetch the records and update the state
      const response: RegistersState[] = await fetchRegistersHeaderListing(
        authToken,
        selectedAccount
      );
      if (response) {
        setRegistersRecords(response);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (selectedAccount?.id) fetchRegisterRecords();
  }, [useAuthToken]);

  return (
    <RegistersContext.Provider value={{ registersRecords, fetchRegisterRecords }}>
      {children}
    </RegistersContext.Provider>
  );
};
