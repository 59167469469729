import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import {
  FileReviewState,
  DropdownResponse,
  ReviewState,
  PaginationResponse,
  FinalizationReviewState,
  AccountTemplateState
} from '../../user/state';
import { fetchAdviserReviews } from '../../../../api/adviser/fetchAdviserReviews';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import { ReviewsDataFormState } from './state';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import * as SurveyCore from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { fetchReviewTemplate } from '../../../../api/adviser/fetchReviewTemplate';
import { useNotificationContext } from '../../layout/NotificationContext';
import { createAdviserData } from '../../../../api/adviser/createAdviserData';
import { ReviewsResponseError } from './state';
import { SelectChangeEvent } from '@mui/material/Select';
import ReviewCreateEditFormData from './ReviewCreateEditFormData';
import CommonLayout from '../../layout/CommonLayout';
import ReviewSurveyTextMode from './ReviewSurveyTextMode';
import ClientFileReviews from './ClientFileReviews';
import FinaliseReviews from './FinaliseReview';
// import PdfGenerator from './PdfGenerator';
import {
  CREATE,
  DELETE,
  FINALISE,
  MODULES,
  NOTE_CREATE,
  // PRINT,
  READ,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
// import { fetchReportPath } from '../../../../utils/commonUtils';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import { fetchRepresentativeUsers } from '../../../../api/common/fetchRepresentativeUsers';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';
import DeleteButton from '../../common/DeleteButton';
import Popup from '../../common/popup/Popup';
import { deleteAdviserData } from '../../../../api/adviser/deleteAdviserData';
import { deleteFileReview } from '../../../../api/adviser/deleteFileReview';
import { fetchFileReviews } from '../../../../api/adviser/fetchFileReviews';
import Report from './Report';
import { format } from 'date-fns';

const ReviewCreateEdit: React.FC = () => {
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [closed_at, setClosed_at] = useState<string | null>(null);
  const { setNotificationSuccess } = useNotificationContext();
  const [isDisabled, setIsDisabled] = useState(Boolean); // Initially disabled if id is not available
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [adviser, setAdviser] = useState<string>('');
  const [reviewer, setReviewer] = useState<string>('');
  const [adviserArray, setAdviserArray] = useState<DropdownResponse[]>([]);
  const [reviewerArray, setReviewerArray] = useState<DropdownResponse[]>([]);
  const mainPath = `/reviews/${encryptedSourceId}`;
  const [reviewAddMode, setReviewAddMode] = useState(false); // Initially disabled if id is not available
  const [fileReviewSwitch, setFileReviewSwitch] = useState(false); // Initially disabled if id is not available

  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filereviews, setFilereviews] = useState<FileReviewState[]>([]);
  const [finaliseFilereviews, setFinaliseFilereviews] = useState<FinalizationReviewState[]>([]);
  const [orderBy, setOrderBy] = useState<keyof FileReviewState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const secondTitle = 'Adviser Reviews';
  const pageTitle = id ? `Adviser Review Details` : `Add ${secondTitle}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const today = new Date();
  const formattedDate = format(today, 'd/MMM/yyyy');

  const [date_received, setDate_received] = useState<string | null>(formattedDate);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [clientFileReviewId, setClientFileReviewId] = useState<number>(0);
  const [survey, setSurvey] = useState<any>('');
  const [formData, setFormData] = useState<ReviewsDataFormState>({
    id: 0,
    module_id: parseInt(sourceId!, 10),
    subject: '',
    adviser_id: 0,
    reviewer_id: 0,
    review_template_id: selectedAccount?.review_template_id,
    unique_id: '',
    finalised: false,
    finalised_by: {
      first_name: '',
      last_name: ''
    },
    is_open: 1,
    finalised_at: '',
    created_at: formattedDate,
    created_by: 0,
    error: '',
    adviser_review_report: '',
    adviser: {
      first_name: '',
      last_name: ''
    },
    reviewer: {
      first_name: '',
      last_name: ''
    },
    user: {
      first_name: '',
      last_name: ''
    },
    file_reviews_with_status: {
      file_review_status: '0'
    }
  });

  useEffect(() => {
    const permission =
      current_page.pathname === `/review/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const HandlesnapshotIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      navigate(`/review-snapshot-edit/${encryptedId}?source=${encryptedSourceId}`);
    }
  };

  const HandleformIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      navigate(`/review-form-edit/${encryptedId}?source=${encryptedSourceId}`);
    }
  };

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  const [templateData, setTemplateData] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchAdviserUsers = async () => {
    if (
      current_route !== `/review/${encryptedId}` &&
      current_route !== `/review-form-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchRepresentativeUsers(
          authToken,
          selectedAccount
        );
        if (response) {
          setAdviserArray(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  const fetchReviewerUsers = async () => {
    if (
      current_route !== `/review/${encryptedId}` &&
      current_route !== `/review-form-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
        if (response) {
          setReviewerArray(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  useEffect(() => {
    if (
      current_route == `/review-snapshot-edit/${encryptedId}` ||
      current_route == `/review-form-edit/${encryptedId}` ||
      current_route == `/review-add`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      formData?.reviews_data &&
      Object.keys(formData?.reviews_data?.form_data).length !== 0
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.reviews_data.form_data)
      );
      localStorage.removeItem('survey-json-adv-review-template');
      localStorage.setItem('survey-json-adv-review-template', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.removeItem('survey-json-adv-review-template');
      localStorage.setItem('survey-json-adv-review-template', templateData);
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();

      setHasEffectRun(true);
      fetchTemplate();
      fetchData(); // Call the async function
      fetchAdviserUsers();
      fetchReviewerUsers();
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun, current_route]);

  const setSurveyData = () => {
    const surveyJson = window.localStorage.getItem('survey-json-adv-review-template') || ''; // Provide an empty string as the default
    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.focusFirstQuestionAutomatic = false;

      surveyModel.completeText = 'Save';
      surveyModel.addNavigationItem({
        id: 'survey_clear_current_page',
        title: 'Cancel',
        visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
        action: () => {
          handleCancelClick();
        }
      });

      setSurvey(surveyModel);
    }
  };

  useEffect(() => {
    if (fileReviewSwitch != null && hasEffectRun) {
      fetchData();
    }
  }, [fileReviewSwitch]);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const reviewId = parseInt(id!, 10);

        const response: ReviewState = (await fetchAdviserReviews(
          source_Id,
          authToken,
          selectedAccount,
          reviewId
        )) as ReviewState;

        if (response) {
          const {
            id,
            subject,
            module_id,
            review_template_id,
            unique_id,
            reviewer_id,
            adviser_id,
            created_at,
            closed_at,
            finalised,
            finalised_by,
            finalised_at,
            is_open,
            adviser,
            reviewer,
            user,
            reviews_data,
            file_reviews_with_status,
            adviser_review_report
          } = response;

          if (reviewer_id) {
            setReviewer(reviewer_id.toString());
          }
          if (adviser_id) {
            setAdviser(adviser_id.toString());
          }

          if (created_at) {
            setDate_received(created_at);
          }

          if (closed_at) {
            setClosed_at(closed_at);
          }
          const newFormData = {
            id,
            subject,
            module_id,
            adviser_id,
            reviewer_id,
            review_template_id,
            unique_id,
            is_open,
            created_at,
            closed_at,
            finalised: finalised ? true : false,
            finalised_at,
            finalised_by: {
              id: finalised_by ? finalised_by.id : 0,
              email: finalised_by ? finalised_by.email : '',
              first_name: finalised_by ? finalised_by.first_name : '',
              last_name: finalised_by ? finalised_by.last_name : ''
            },
            error: '',
            adviser: {
              id: adviser ? adviser.id : 0,
              email: adviser ? adviser.email : '',
              first_name: adviser ? adviser.first_name : '',
              last_name: adviser ? adviser.last_name : ''
            },
            reviewer: {
              id: reviewer ? reviewer.id : 0,
              email: reviewer ? reviewer.email : '',
              first_name: reviewer ? reviewer.first_name : '',
              last_name: reviewer ? reviewer.last_name : ''
            },
            user: {
              id: user ? user.id : 0,
              email: user ? user.email : '',
              first_name: user ? user.first_name : '',
              last_name: user ? user.last_name : ''
            },
            reviews_data: {
              id: reviews_data ? reviews_data.id : 0,
              review_id: reviews_data ? reviews_data.review_id : 0,
              form_data: reviews_data ? reviews_data.form_data : ''
            },
            file_reviews_with_status: {
              file_review_status:
                file_reviews_with_status && file_reviews_with_status[0]
                  ? file_reviews_with_status[0]?.file_review_status
                  : '0'
            },
            adviser_review_report
          };
          setFormData(newFormData);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };
  const fetchTemplate = async () => {
    try {
      const reviewId = parseInt(id!, 10);
      if (authToken) {
        const response: AccountTemplateState[] = (await fetchReviewTemplate(
          authToken,
          selectedAccount,
          reviewId || 0,
          source_Id
        )) as AccountTemplateState[];

        if (response.length > 0) {
          response.map((template: AccountTemplateState) => {
            if (template.template !== '""') {
              if (template.type === t('templateType.adviserReviewSummary')) {
                setTemplateData(template.template);
                setFormData((prevData) => ({ ...prevData, review_template_id: template.id }));
              }
            } else {
              localStorage.removeItem('survey-json-adv-review-template');
              setFormData((prevData) => ({
                ...prevData,
                error: t('error.field.notemplateFound')
              }));
            }
          });
        } else {
          setFormData((prevData) => ({
            ...prevData,
            error: t('error.field.notemplateFound')
          }));
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchFilereviewes(orderBy, order);
  }, [currentPage]);

  const fetchFilereviewes = async (orderBy: string, order: 'asc' | 'desc') => {
    if (current_route === `/review/${encryptedId}`) {
      localStorage.removeItem('survey-json-file-review-temp-render');
      try {
        if (authToken && id) {
          const review_Id = parseInt(id, 10);
          const response: PaginationResponse = (await fetchFileReviews(
            authToken,
            review_Id,
            0,
            currentPage,
            10,
            false,
            orderBy,
            order
          )) as PaginationResponse;
          if (response && response.data) {
            if (Array.isArray(response.data)) {
              setFilereviews(response.data);
              setFinaliseFilereviews(response.data);
            }
            setTotalPages(response.last_page);
            setLoading(false); // Set loading to false when data is fetched
          }
        }
      } catch (error) {
        // Handle any errors here
        setLoading(false); // Set loading to false on error too
        console.error('Error fetching data:', error);
      }
    }
  };

  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined) {
          // Add the value to the element
          element.defaultValue = fieldValues[fieldName];
        }
      });
    });
    return mainResponse;
  };

  const handleChangeAdviser = (event: SelectChangeEvent<string>) => {
    setAdviser(event.target.value);
  };

  const handleChangeReviewer = (event: SelectChangeEvent<string>) => {
    setReviewer(event.target.value);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.subject) {
      newErrors.subject = t('error.field.subjectRequired');
    }
    if (!adviser) {
      newErrors.adviser_id = t('error.field.adviser');
    }
    if (!reviewer) {
      newErrors.reviewer_id = t('error.field.reviewer');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });

      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const handleSurveySubmit = async (type = '') => {
    let isFormValid = true;
    let validationResult = true;
    if (current_route === `/review-snapshot-edit/${encryptedId}?source=${encryptedSourceId}`) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    } else if (
      templateData &&
      current_route === `/review-form-edit/${encryptedId}?source=${encryptedSourceId}`
    ) {
      if (!type) {
        validationResult = survey.validate();
      }
      if (!validationResult) {
        return;
      }
    } else if (!formData.id) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    } else {
      if (!type) {
        validationResult = survey.validate();
      }
      if (!validationResult) {
        return;
      }
    }

    if (isFormValid && validationResult) {
      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;
      const requestData = {
        module_id: formData.module_id,
        subject: formData.subject,
        reviewer_id: reviewer,
        adviser_id: adviser,
        review_template_id: formData.review_template_id,
        is_open: formData.is_open,
        form_data: survey ? surveyData : ''
      };

      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createAdviserData(
          requestData,
          authToken,
          selectedAccount,
          edit_id
        );

        if (response && response?.id) {
          // Success: Handle successful response
          localStorage.removeItem('survey-json-adv-review-template');
          const msg = id ? t('success.field.reviewUpdated') : t('success.field.reviewSaved');

          if (response.id && current_route && current_route === `/review-add` && !reviewAddMode) {
            setErrors({});
            setNewResponse(response);
            navigate(
              `/review/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
            );
          } else if (id || reviewAddMode) {
            if (!type) {
              setHasEffectRun(false);
              setNotificationSuccess(msg);
              setReviewAddMode(false);
              navigate(
                `/review/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
              );
            }
          } else {
            navigate(`/reviews/${encryptedSourceId}`);
            setNotificationSuccess(msg);
          }
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          // Error handling for validation errors
          const newErrors: Record<string, string> = {};

          if (response.reviewer_id) {
            newErrors.reviewer_id = response.reviewer_id[0];
          } else if (response.adviser_id) {
            newErrors.adviser_id = response.adviser_id[0];
          } else if (response.subject) {
            newErrors.subject = response.subject[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const setNewResponse = (response: ReviewState) => {
    if (response) {
      const { id, subject, review_template_id, unique_id, reviewer_id, adviser_id, created_at } =
        response;

      if (closed_at) {
        setClosed_at(closed_at);
      }
      const newFormData = {
        id,
        subject,
        adviser_id,
        reviewer_id,
        review_template_id,
        unique_id,
        created_at
      };
      setFormData(newFormData);
      setTimeout(() => {
        setReviewAddMode(true);
      }, 200);
    }
  };

  const handleFinalizebutton = async (newStatus: number) => {
    const requestData = {
      module_id: formData.module_id,
      subject: formData.subject,
      reviewer_id: reviewer,
      adviser_id: adviser,
      is_open: newStatus
    };
    const response: ReviewsResponseError = (await createAdviserData(
      requestData,
      authToken,
      selectedAccount,
      id,
      true
    )) as ReviewsResponseError;
    if (response && response.id) {
      localStorage.removeItem('survey-json-adv-review-template');
      const msg = newStatus ? t('success.field.reviewOpen') : t('success.field.reviewClosed');

      setNotificationSuccess(msg);
      if (response.id) {
        navigate(`/review/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`);
        setHasEffectRun(false);
      } else {
        navigate(`/reviews/${encryptedSourceId}`);
      }
    } else {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const handleCancelClick = () => {
    if (id) {
      navigate(`/review/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/reviews/${encryptedSourceId}`);
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
    setClientFileReviewId(0);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (clientFileReviewId && authToken) {
        const response: any = (await deleteFileReview(clientFileReviewId, authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.fileReviewDeleted'));
          setFilereviews((prevReviews) =>
            prevReviews.filter((review) => review.id !== clientFileReviewId)
          );
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      } else if (id && authToken) {
        const response: any = (await deleteAdviserData(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.reviewDeleted'));
          navigate(`/reviews/${encryptedSourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
    setClientFileReviewId(0);
  };

  let edit_btn_form = null;
  let edit_btn_snapshot = null;
  if (formData.is_open === 1 && !formData.finalised) {
    edit_btn_form = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandleformIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );

    edit_btn_snapshot = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  let addButton;
  if (formData && formData.is_open === 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {isDisabled && current_route === `/review/${encryptedId}` && (
          <React.Fragment>
            {/* {checkUserPermission(user, MODULES, source_Id, PRINT) &&
              formData.finalised === true && (
                <PdfGenerator report={fetchReportPath(formData.adviser_review_report)} />
              )} */}
            {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && !formData.finalised && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/review/add-note?source=${encryptedSourceId}&reviewId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}
            {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && !formData.finalised && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/review/add-task?source=${encryptedSourceId}&reviewId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id ? formData.unique_id : pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                errorMsg={formData.error}
                button={id ? addButton : undefined}
              />

              <div className="record-outer-row settings-outer-row">
                <ReviewLeftMenu
                  user={user}
                  reviewId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formData_record={formData}
                  reviewIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {current_route &&
                      (current_route === `/review-snapshot-edit/${encryptedId}` ||
                        current_route === `/review/${encryptedId}` ||
                        current_route === `/review-add`) &&
                      !reviewAddMode && (
                        <ReviewCreateEditFormData
                          formData={formData}
                          errors={errors}
                          date_received={date_received}
                          adviser={adviser}
                          reviewer={reviewer}
                          adviserArray={adviserArray}
                          reviewerArray={reviewerArray}
                          closed_at={closed_at}
                          isDisabled={isDisabled}
                          current_route={current_route}
                          reviewAddMode={reviewAddMode}
                          edit_btn_snapshot={edit_btn_snapshot}
                          handleChangeAdviser={handleChangeAdviser}
                          handleChangeReviewer={handleChangeReviewer}
                          handleInputChange={handleInputChange}
                          handleFinalizebutton={handleFinalizebutton}
                          encryptedId={encryptedId ? encryptedId : null}
                        />
                      )}

                    {!isDisabled &&
                    survey &&
                    formData.id &&
                    (current_route === `/review-form-edit/${encryptedId}` || reviewAddMode) ? (
                      <div className="card-outline">
                        <Survey model={survey} />
                      </div>
                    ) : null}

                    {isDisabled && current_route === `/review/${encryptedId}` && (
                      <div className="card-outline">
                        <ClientFileReviews
                          loading={loading}
                          filereviews={filereviews}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          setCurrentPage={setCurrentPage}
                          setFilereviews={setFilereviews}
                          setFileReviewSwitch={setFileReviewSwitch}
                          reviewId={id}
                          authToken={authToken}
                          formData={formData}
                          accountInfo={selectedAccount}
                          sourceId={sourceId}
                          setOpenConfirmationPopup={setOpenConfirmationPopup}
                          setClientFileReviewId={setClientFileReviewId}
                          fetchFilereviewes={fetchFilereviewes}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          reviewIdEncrypted={encryptedId}
                          sourceIdEncrypted={encryptedSourceId}
                        />
                      </div>
                    )}

                    {templateData &&
                      isDisabled &&
                      formData.reviews_data?.form_data &&
                      current_route === `/review/${encryptedId}` && (
                        <div className="card-outline">
                          <ReviewSurveyTextMode
                            surveyData={JSON.parse(templateData)}
                            formData={JSON.parse(formData?.reviews_data?.form_data)}
                            edit_btn={edit_btn_form}
                          />
                        </div>
                      )}

                    {!isDisabled &&
                      !reviewAddMode &&
                      (current_route == `/review-add` ||
                        current_route == `/review-snapshot-edit/${encryptedId}`) && (
                        <div className="btn-wrap">
                          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </Button>
                          {templateData && (
                            <Button
                              className="btn primary-btn btn-sm"
                              type="submit"
                              onClick={() => handleSurveySubmit()}>
                              {current_route == `/review-snapshot-edit/${encryptedId}`
                                ? 'Save'
                                : 'Save and continue'}
                            </Button>
                          )}
                        </div>
                      )}
                    {isDisabled &&
                      checkUserPermission(user, MODULES, source_Id, FINALISE) &&
                      current_route === `/review/${encryptedId}` && (
                        <div className="card-outline">
                          <FinaliseReviews
                            loading={loading}
                            filereviews={finaliseFilereviews}
                            reviewId={id}
                            formData={formData}
                            sourceId={sourceId}
                            reviewIdEncrypted={encryptedId}
                            sourceIdEncrypted={encryptedSourceId}
                            filereviewData={filereviews}
                          />
                        </div>
                      )}

                    {isDisabled && current_route === `/review/${encryptedId}` && (
                      <div className="card-outline">
                        <Report
                          loading={loading}
                          filereviews={finaliseFilereviews}
                          formData={formData}
                          filereviewData={filereviews}
                        />
                      </div>
                    )}

                    {checkUserPermission(user, MODULES, source_Id, DELETE) &&
                      current_route === `/review/${encryptedId}` && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default ReviewCreateEdit;
