import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useCurrentUserInfo, checkUserPermission } from '../../../../utils/common/user';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';
import RegisterCreate from './RegisterCreateEdit';

const RegisterCreateEditIndex: React.FC = () => {
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const { id } = useParams();
  const title = 'Registers';
  const pageTitle = id ? 'Register Edit' : 'Register Add';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';

  useEffect(() => {
    const permission = id ? UPDATE : CREATE
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 5, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true)
  }, []);

  return (
    <>
    {isAccessable && (
      <CommonLayout title={metapageTitle}>
        <div className="settings-outer-wrap">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
          />
          <div className="settings-outer-row">
            <div className="right-outer">
              <div className="right-inner">
                <RegisterCreate />
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    )}
    </>
  );
};

export default RegisterCreateEditIndex;
