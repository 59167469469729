import React, { useEffect, useState } from 'react';
import {
  checkUserPermission,
  encryptData,
  decryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useNavigate, useParams } from 'react-router-dom';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import PaginationSettings from '../../settings/Pagination';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { PaginationResponse } from '../../user/state';
import AdviserProfilesFilters from './FilterBtn';
import { MODULES, READ, EXPORT } from '../../../../constants/constants';
import { fetchAdviserProfiles } from '../../../../api/adviser-profiles/fetchAdviserProfiles';
import { AdviserProfileState } from './state';
import { downloadExcelReport } from '../../../../api/adviser-profiles/downloadExcelReport';

const AdviserProfiles = () => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const { moduleRecords } = useModuleContext();
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [dense] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [adviserProfiles, setAdviserProfiles] = useState<AdviserProfileState[]>([]);
  const [exportingFileType, setExportingFileType] = useState<string>('');

  let title = 'Adviser Profiles';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const moduleId = parseInt(id!, 10);
  const foundModule =
    Array.isArray(moduleRecords) && moduleRecords.length > 0
      ? moduleRecords.find((module) => module.id === moduleId)
      : '';
  title = foundModule ? foundModule?.name : '';

  interface HeadCell {
    disablePadding: boolean;
    id: keyof AdviserProfileState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email address'
    },
    {
      id: 'is_open',
      numeric: false,
      disablePadding: false,
      label: 'Status'
    }
  ];

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, parseInt(id!, 10), READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken && id) {
        const response: PaginationResponse = (await fetchAdviserProfiles(
          authToken,
          selectedAccount,
          moduleId,
          0,
          currentPage,
          search,
          selectedStatus,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setAdviserProfiles(response.data);
          }
          setTotalPages(response.last_page);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('adviserProfileTasksCount');
    localStorage.removeItem('adviserProfileNotesCount');
    fetchData(orderBy, order);
  }, [id, currentPage, search, selectedStatus]); // Dependency array

  const handleSort = (property: keyof AdviserProfileState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  const redirectEditPage = (rowid: number) => {
    navigate(`/adviser-profile/${encryptData(String(rowid), true)}?source=${encryptedId}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleExportExcel = async (type: string = 'file') => {
    setExportingFileType(type);

    try {
      if (authToken && id) {
        const source_id = parseInt(id, 10);

        const response: any = await downloadExcelReport(
          source_id,
          selectedAccount,
          authToken,
          title
        );

        if (response.status === 200) {
          // Handle success
          setExportingFileType('');
        } else {
          console.log('Error:', response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setExportingFileType('');
    }
  };

  const addButton = (
    <div className="inner-btn-wrap">
      {checkUserPermission(user, MODULES, parseInt(id!, 10), EXPORT) &&
        adviserProfiles.length > 0 && (
          <>
            {exportingFileType === 'file' ? (
              <CircularProgress />
            ) : (
              <Button
                className="btn blue-fill-btn"
                onClick={() => handleExportExcel()}
                disabled={exportingFileType ? true : false}>
                <i className="left">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M16.6666 10.416V5.66602C16.6666 4.26588 16.6666 3.56582 16.3941 3.03104C16.1544 2.56063 15.772 2.17818 15.3016 1.9385C14.7668 1.66602 14.0667 1.66602 12.6666 1.66602H7.33325C5.93312 1.66602 5.23306 1.66602 4.69828 1.9385C4.22787 2.17818 3.84542 2.56063 3.60574 3.03104C3.33325 3.56582 3.33325 4.26588 3.33325 5.66602V14.3327C3.33325 15.7328 3.33325 16.4329 3.60574 16.9677C3.84542 17.4381 4.22787 17.8205 4.69828 18.0602C5.23306 18.3327 5.93308 18.3327 7.33313 18.3327H10.4166M11.6666 9.16602H6.66659M8.33325 12.4993H6.66659M13.3333 5.83268H6.66659M12.4999 15.8327L14.9999 18.3327M14.9999 18.3327L17.4999 15.8327M14.9999 18.3327V13.3327"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Export Data
              </Button>
            )}
          </>
        )}
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs title={title} pageTitle={title} button={addButton} />
              <AdviserProfilesFilters
                search={search}
                setSearch={setSearch}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
              <div className="table-outer">
                <TableContainer>
                  {loading ? (
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : adviserProfiles.length === 0 ? (
                    <div className="no-record">No Adviser Profiles found.</div>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="table hover-table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={`key-${headCell.id}`}
                              align={headCell.numeric ? 'left' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}>
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => handleSort(headCell.id)}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                  <Box component="span" sx={{ display: 'none' }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adviserProfiles.map((data: AdviserProfileState) => (
                          <TableRow
                            key={data.id}
                            onClick={() => {
                              if (data.is_open) {
                                redirectEditPage(data.id);
                              }
                            }}
                            style={{ cursor: data.is_open ? 'pointer' : 'default' }}>
                            <TableCell align="left">
                              <Grid container alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                  {data.profile_image ? (
                                    <Avatar
                                      src={`${process.env.REACT_APP_AWS_URL}${
                                        JSON.parse(data.profile_image).path
                                      }`}
                                      alt={data.first_name}
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.style.display = 'none'; // Hide the image
                                        const nextElement =
                                          target.nextElementSibling as HTMLElement | null;
                                        if (nextElement) {
                                          nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                        }
                                      }}
                                    />
                                  ) : (
                                    <Avatar alt={data.first_name} src="." />
                                  )}
                                </Grid>
                                <Grid item className="content">
                                  <p>{data.first_name + ' ' + data.last_name}</p>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="left">{data.email}</TableCell>
                            <TableCell align="left" className={data.is_open ? 'open' : 'closed'}>
                              <span>{data.is_open ? 'Active' : 'Inactive'}</span>
                            </TableCell>
                            <TableCell align="left" className="right-arrow-width">
                              {data.is_open ? (
                                <div className="table-btn">
                                  <img src={rightarrow} alt="Right Arrow" />
                                </div>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {!loading && adviserProfiles.length > 0 && (
                    <PaginationSettings
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </TableContainer>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AdviserProfiles;
