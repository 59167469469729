import axios from 'axios';
import { PlanResponseError, PlanState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const createPlanData = async (
  formData: PlanState,
  selectedFile: File | null,
  authToken?: string,
  planId?: string | number
): Promise<PlanState | PlanResponseError | string | number | undefined> => {
  let routeUrl;

  const { plan_name, max_users, price_id, product_id, is_open } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('plan_name', plan_name);
    formData.append('max_users', max_users.toString());
    formData.append('price_id', price_id);
    formData.append('product_id', product_id);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }
    formData.append('is_open', is_open.toString());

    if (planId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/plans/${dataEncrypt(planId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/plans`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
