import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import PlansLeftMenu from './PlansLeftMenu';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import {
  checkUserPermission,
  decryptData,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useNotificationContext } from '../../layout/NotificationContext';
import { PlanState } from '../../user/state';
import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';
import { fetchPlans } from '../../../../api/plans/fetchPlans';
import { createPlanData } from '../../../../api/plans/createPlanData';
import PlansCreateEditFormData from './PlansCreateEditFormData';

const PlansCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id ? id : '';
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = id ? 'Edit Plan' : 'Add Plan';
  const secondpath = '/settings/plans';
  const secondTitle = 'Plans';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const navigate = useNavigate();
  const authToken = useAuthToken();
  const { setNotificationSuccess } = useNotificationContext();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [isDisabled, setIsDisabled] = useState<boolean>(false); // Initially disabled if id is not available
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<PlanState>({
    id: 0,
    plan_name: '',
    max_users: 0,
    price_id: '',
    product_id: '',
    image: '',
    is_open: 1,
    error: ''
  });

  useEffect(() => {
    const permission = id ? UPDATE : CREATE;

    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 10, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const response: PlanState = (await fetchPlans(authToken, parseInt(id!, 10))) as PlanState;

        if (response) {
          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    if (
      current_route == `/settings/plans/add` ||
      current_route == `/settings/plans/update/${encryptedId}`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    fetchData(); // Call the async function
  }, [current_route]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleCancelClick = () => {
    navigate(`/settings/plans`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!formData.plan_name) {
      newErrors.plan_name = t('error.field.planNameRequired');
    }
    if (!formData.max_users) {
      newErrors.max_users = t('error.field.maxUsersRequired');
    }
    if (!formData.price_id) {
      newErrors.price_id = t('error.field.priceIdRequired');
    }
    if (!formData.product_id) {
      newErrors.product_id = t('error.field.productIdRequired');
    }
    if (current_route !== `/settings/plans/update/${encryptedId}` && !selectedFile) {
      newErrors.image = t('error.field.fileRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      setLoading(true);
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createPlanData(formData, selectedFile, authToken, edit_id);

        if (response && response.id) {
          const msg = id ? t('success.field.planUpdated') : t('success.field.planCreated');

          setNotificationSuccess(msg);

          navigate(`/settings/plans`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          const newErrors: Record<string, string> = {};

          if (response.price_id) {
            newErrors.owner = response.price_id[0];
          } else if (response.product_id) {
            newErrors.owner = response.product_id[0];
          } else if (response.image) {
            newErrors.image = response.image[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />

            <div className="record-outer-row settings-outer-row">
              <PlansLeftMenu />

              <div className="right-outer">
                <div className="right-inner">
                  <PlansCreateEditFormData
                    formData={formData}
                    selectedFile={selectedFile}
                    isDisabled={isDisabled}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleFileChange={handleFileChange}
                  />

                  {!isDisabled && (
                    <div className="btn-wrap">
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </Button>
                          <Button
                            className="btn primary-btn btn-sm"
                            type="submit"
                            onClick={handleFormSubmit}>
                            Save
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default PlansCreateEdit;
