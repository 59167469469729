import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import PrevetLeftMenu from './PrevetLeftMenu';
import CommonLayout from '../../layout/CommonLayout';
import { fetchFileReviewTemplates } from '../../../../api/pre-vet/fetchFileReviewTemplates';
import { PrevetFileReviewTemplatesState, PrevetFileReviewState } from '../../user/state';
import { fetchFileReviews } from '../../../../api/pre-vet/fetchFileReviews';
import { FileReviewFormState } from './state';
import { renderFormElement } from '../../../../utils/common/surveyjs/survey-js';
const FileReviewCompleteView: React.FC = () => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let prevetId = queryParams.get('prevetId');
  const encryptedPrevetId = prevetId; // Decrypt the ID
  prevetId = decryptData(prevetId, true);
  const prevet_id = parseInt(prevetId!, 10);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const title = 'File Review Details';
  const edit_id = parseInt(id!, 10);
  const { t } = useTranslation();
  const mainPath = `/prevets/${encryptedSourceId}`;
  const pageTitle = 'Client File Review';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<SurveyData>();
  const [formData, setFormData] = useState<FileReviewFormState>({
    id: 0,
    prevet_id: prevet_id,
    prevet_file_review_template_id: 0,
    client: '',
    is_complete: false,
    error: ''
  });

  let thirdTitle;
  if (formData && formData.prevet_data?.unique_id) {
    thirdTitle = formData.prevet_data?.unique_id;
  }
  const thirdPath = `/prevet/${encryptedPrevetId}?source=${encryptedSourceId}`;
  const secondTitle = 'Pre-Vets';

  useEffect(() => {
    if (!hasEffectRun) {
      setHasEffectRun(true);
      fetchTemplate();
      fetchData();
    }
  }, [templateData, formData, hasEffectRun, current_route]);

  const fetchTemplate = async () => {
    try {
      if (id && authToken) {
        const response: PrevetFileReviewTemplatesState = (await fetchFileReviewTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          edit_id
        )) as PrevetFileReviewTemplatesState;

        if (response) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(JSON.parse(form_data));
          } else {
            localStorage.removeItem('survey-json-prevet-file-review-temp-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFoundReview')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      if (authToken && id) {
        const prevetId = parseInt(id, 10);
        const response: PrevetFileReviewState = (await fetchFileReviews(
          authToken,
          prevetId,
          edit_id
        )) as PrevetFileReviewState;
        if (response) {
          const {
            id,
            prevet_id,
            client,
            prevet_file_review_template_id,
            is_complete,
            filereview_data,
            prevet_data
          } = response;

          const newFormData = {
            id,
            prevet_id,
            client,
            prevet_file_review_template_id,
            is_complete: is_complete ? true : false,
            filereview_data: {
              id: filereview_data ? filereview_data.id : 0,
              prevet_file_review_id: filereview_data ? filereview_data.prevet_file_review_id : 0,
              form_data: filereview_data ? JSON.parse(filereview_data.form_data) : ''
            },
            prevet_data: {
              id: prevet_data ? prevet_data.id : 0,
              unique_id: prevet_data ? prevet_data.unique_id : '',
              adviser: {
                id: prevet_data?.adviser ? prevet_data?.adviser.id : 0,
                email: prevet_data?.adviser ? prevet_data?.adviser.email : '',
                first_name: prevet_data?.adviser ? prevet_data?.adviser.first_name : '',
                last_name: prevet_data?.adviser ? prevet_data?.adviser.last_name : ''
              }
            }
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const handleExit = () => {
    if (prevetId) {
      navigate(`/prevet/${encryptedPrevetId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/prevets/${encryptedSourceId}`);
    }
  };

  type SurveyData = {
    title: string;
    description: string;
    pages: {
      name: string;
      elements: Element[];
      title: string;
      description: string;
    }[];
  };

  type Element = {
    type: any;
    name: string;
    title: string;
    inputType?: string;
    choices?: Choices[];
    startWithNewLine?: boolean;
    items?: Items[];
  };

  type Items = {
    name: string;
    title?: string;
  };
  type Choices = {
    value: string;
    name?: string;
    type?: string;
    text?: string;
    imageLink?: string;
  };
  //   let content; // Declare variable outside the switch block

  //   switch (element.type) {
  //     case 'dropdown':
  //       {
  //         const choices = element.choices || [];
  //         const selectedChoice = choices.find((choice) => choice.value === value);
  //         content = (
  //           <div className="dropdown-container">
  //             <p className="selected-choice-text additional-view-space">
  //               {selectedChoice ? selectedChoice.text : value}
  //             </p>
  //           </div>
  //         );
  //       }
  //       break;
  //     case 'tagbox':
  //     case 'checkbox':
  //       {
  //         const choices = element.choices || [];
  //         const selectedValues = Array.isArray(value) ? value : [value];

  //         const selectedTexts = choices
  //           .filter((choice) => selectedValues.includes(choice.value))
  //           .map((choice) => choice.text);

  //         content = (
  //           <div className="tagbox-container">
  //             {selectedTexts.map((text, index) => (
  //               <p key={index} className="selected-tagbox-item additional-view-space">
  //                 {text}
  //               </p>
  //             ))}
  //           </div>
  //         );
  //       }
  //       break;
  //     case 'ranking':
  //       {
  //         const choices = element.choices || [];
  //         const selectedValues = Array.isArray(value) ? value : [value];

  //         content = (
  //           <div className="ranking-container">
  //             {selectedValues.map((selectedValue, index) => {
  //               const selectedChoice = choices.find((choice) => choice.value === selectedValue);
  //               const text = selectedChoice ? selectedChoice.text : '';

  //               return (
  //                 <div key={index} className="ranking-item">
  //                   <p className="selected-ranking-text additional-view-space">{text}</p>
  //                   {/* You can add additional styling or elements for each ranking item */}
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         );
  //       }
  //       break;
  //     case 'boolean':
  //       {
  //         content = <p className="form-control additional-view-space">{value ? 'Yes' : 'No'}</p>;
  //       }
  //       break;
  //     case 'file':
  //       {
  //         content =
  //           Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' ? (
  //             <>
  //               {value.map((file: any, index: number) => (
  //                 <p
  //                   onClick={() => PresignedUrl(file.content, authToken)}
  //                   className="form-control edit-task-arrow-home"
  //                   key={index}>
  //                   {file.name}
  //                 </p>
  //               ))}
  //             </>
  //           ) : (
  //             <p className="form-control additional-view-space">{value}</p>
  //           );
  //       }
  //       break;
  //     case 'signaturepad':
  //       {
  //         content = <img src={value as string} alt="Signature" />;
  //       }
  //       break;
  //     case 'imagepicker':
  //       {
  //         const choices = element.choices || [];
  //         const selectedImages = Array.isArray(value) ? value : [value];
  //         content = (
  //           <div className="image-picker-container">
  //             {choices.map((choice, index) => (
  //               <div
  //                 key={index}
  //                 className={`image-picker-item ${
  //                   selectedImages.includes(choice.value) ? 'selected' : ''
  //                 }`}>
  //                 {selectedImages.includes(choice.value) && (
  //                   <>
  //                     <img src={choice?.imageLink} alt={choice.value} />
  //                     <p>{choice.value}</p>
  //                   </>
  //                 )}
  //               </div>
  //             ))}
  //           </div>
  //         );
  //       }

  //       break;
  //     case 'multipletext':
  //       {
  //         const items = element.items || [];

  //         content = (
  //           <div className="multiple-text-container">
  //             {items.map((item, index) => {
  //               const itemName: string = item.name;
  //               const itemTitle = item.title;

  //               const itemValue =
  //                 itemName && typeof value === 'object' && value !== null
  //                   ? String(value[itemName as keyof typeof value])
  //                   : String(value);

  //               return (
  //                 <div key={index} className="">
  //                   <p className="item-title">{itemTitle}</p>
  //                   <p className="item-value">{itemValue}</p>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         );
  //       }
  //       break;

  //     case 'matrix':
  //       {
  //         content = <div className="matrix"></div>;
  //       }
  //       break;
  //     default:
  //       {
  //         if (element.inputType && element.inputType === 'date') {
  //           let dateValue: Date;

  //           // Convert 'string' or 'number' to 'Date' if possible
  //           if (typeof value === 'string' || typeof value === 'number') {
  //             dateValue = new Date(value);
  //           } else if (value instanceof Date) {
  //             dateValue = value;
  //           } else {
  //             // Handle other cases or throw an error based on your requirements
  //             throw new Error('Invalid date format');
  //           }

  //           content = <p className="form-control">{format(dateValue, 'd/MMM/yyyy')}</p>;
  //         } else {
  //           // If it's not a Date, string, or number, render 'value' as is
  //           content = <p className="form-control">{value}</p>;
  //         }
  //       }
  //       break;
  //   }

  //   return content;
  // };

  const editButton = (
    <div className="inner-btn-wrap">
      <React.Fragment>
        <Button className="btn white-btn" onClick={handleExit}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          Exit
        </Button>
      </React.Fragment>
    </div>
  );

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            thirdTitle={thirdTitle}
            thirdPath={thirdPath}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <PrevetLeftMenu
              user={user}
              fileformData_record={formData}
              filereviewId={id}
              prevetIdEncrypted={encryptedPrevetId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="card-outline">
                  <div className="card-title-wrap secondary-title-wrap">
                    <div>
                      <h3> {t('snapshot')}</h3>
                    </div>
                    <div className="inner-btn-wrap">{editButton}</div>
                  </div>
                  <div className="row-wrap">
                    {templateData?.title && (
                      <div className="inner-wrap-title ">
                        <h4 className="title-padding">{templateData?.title}:</h4>
                        <p className={` ${templateData?.title ? 'template-description' : ''}`}>
                          {templateData?.description}
                        </p>
                      </div>
                    )}
                    <div className="form-group w-full">
                      {templateData &&
                        templateData?.pages?.map((page) => (
                          <div key={page.name} className="row-wrap">
                            {page.elements
                              .filter(
                                (element: any) =>
                                  formData.filereview_data?.form_data[element.name] !== undefined
                              )
                              .map((element: any) => (
                                <div className={`form-group w-full`} key={element.name}>
                                  <label className="form-label">
                                    {element.title ? element.title : element.name}
                                  </label>
                                  {renderFormElement(
                                    element,
                                    formData.filereview_data?.form_data[element.name] !== undefined
                                      ? formData.filereview_data?.form_data[element.name]
                                      : false,
                                    authToken
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default FileReviewCompleteView;
