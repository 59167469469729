import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ProfessionalYearTemplateDataState } from '../../views/components/modules/professional-years/state';
import { t } from 'i18next';

export const createProfessionalYearTemplateData = async (
  type: string,
  form_data: any,
  professionalYearId: number,
  authToken: string,
  id: number
): Promise<ProfessionalYearTemplateDataState | string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object
    
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('professional_year_id', professionalYearId.toString());
    formData.append('type', type);

    if (type === t('templateType.planSupervisor')) {
      let planComplete = 0;
      if (form_data && form_data.PlanComplete) {
        planComplete = 1;
      }
      formData.append('plan_complete', planComplete.toString());
    } else if (type === t('templateType.q1Supervisor')) {
      let q1Complete = 0;
      if (form_data && form_data.Q1Complete) {
        q1Complete = 1;
      }
      formData.append('q1_complete', q1Complete.toString());
    } else if (type === t('templateType.q2Supervisor')) {
      let q2Complete = 0;
      if (form_data && form_data.Q2Complete) {
        q2Complete = 1;
      }
      formData.append('q2_complete', q2Complete.toString());
    } else if (type === t('templateType.q3Supervisor')) {
      let q3Complete = 0;
      if (form_data && form_data.Q3Complete) {
        q3Complete = 1;
      }
      formData.append('q3_complete', q3Complete.toString());
    } else if (type === t('templateType.q4Supervisor')) {
      let q4Complete = 0;
      if (form_data && form_data.Q4Complete) {
        q4Complete = 1;
      }
      formData.append('q4_complete', q4Complete.toString());
    } else if (type === t('templateType.examSupervisor')) {
      let examPassedConfirmation = 0;
      if (form_data && form_data.ExamPassedConfirmation) {
        examPassedConfirmation = 1;
      }
      formData.append('exam_passed_confirmation', examPassedConfirmation.toString());
    } else if (type === t('templateType.examEntrant')) {
      let examPassed = 0;
      if (form_data && form_data.ExamPassed) {
        examPassed = 1;
      }
      formData.append('exam_passed', examPassed.toString());
    } else if (type === t('templateType.completionSupervisor')) {
      let pyCompleteSupervisor = 0;
      console.log(form_data)
      if (form_data && form_data.PYCompleteSupervisor) {
        pyCompleteSupervisor = 1;
      }
      formData.append('py_complete_supervisor', pyCompleteSupervisor.toString());
    } else if (type === t('templateType.completionLicensee')) {
      let pyCompleteLicensee = 0;
      if (form_data && form_data.PYCompleteLicensee) {
        pyCompleteLicensee = 1;
      }
      formData.append('py_complete_licensee', pyCompleteLicensee.toString());
    }

    let routeUrl = `${process.env.REACT_APP_API_URL}/professional-year-template-data`;

    if (id) {
      routeUrl = `${process.env.REACT_APP_API_URL}/professional-year-template-data/${dataEncrypt(id?.toString())}`;

      formData.append('_method', 'PUT');
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
