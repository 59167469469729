import axios from 'axios';

interface LoginFormState {
  email: string;
}

export const sendResetPasswordEmail = async (
  formData: LoginFormState,
  type?: string
): Promise<string | undefined> => {
  const { email } = formData;

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/password/email-outseta?type=${type}`, {
      email
    });

    return;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message;

    return errorMessage;
  }
};
