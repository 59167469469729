import React from 'react';
import { Route, Routes } from 'react-router';
import AdviserProfilesDetails from './AdviserProfilesDetails';
import AdviserProfileNotes from './AdviserProfileNotes';
import AdviserProfileNotesAdd from './AdviserProfileNotesAdd';
import AdviserProfileTasks from './AdviserProfileTasks';
import AdviserProfileTasksAddEdit from './AdviserProfileTasksAddEdit';
import AdviserProfilePersonalDetailsTemplateEdit from './AdviserProfilePersonalDetailsTemplateEdit';
import AdviserProfileAuthorisationsTemplateEdit from './AdviserProfileAuthorisationsTemplateEdit';

const AdviserProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<AdviserProfilesDetails />} />
      <Route path="/notes" element={<AdviserProfileNotes />} />
      <Route path="/add-note" element={<AdviserProfileNotesAdd />} />
      <Route path="/edit-note/:id/" element={<AdviserProfileNotesAdd />} />
      <Route path="/tasks" element={<AdviserProfileTasks />} />
      <Route path="/add-task" element={<AdviserProfileTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<AdviserProfileTasksAddEdit />} />

      {/* Temnplats routes */}
      <Route path="/personal-details" element={<AdviserProfilePersonalDetailsTemplateEdit />} />
      <Route path="/authorisations" element={<AdviserProfileAuthorisationsTemplateEdit />} />
    </Routes>
  );
};

export default AdviserProfileRoutes;
