import { PaginationResponse } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { QuickVetTemplateFormState } from '../../views/components/settings/state';

export const fetchQuickVetTemplates = async (
  authToken?: string,
  accountInfo?: AccountState,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  templateId?: number,
  isDefault?: boolean,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<
  PaginationResponse | QuickVetTemplateFormState | QuickVetTemplateFormState[] | string | undefined
> => {
  let routeUrl;

  const selectedAccountId = accountInfo?.id;
  routeUrl = `${process.env.REACT_APP_API_URL}/quickvet-templates?account_id=${dataEncrypt(
    selectedAccountId?.toString() ?? '0'
  )}`;

  if (templateId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/quickvet-templates/${dataEncrypt(
      templateId?.toString()
    )}`;
  } else if (!isDefault) {
    routeUrl = `${
      process.env.REACT_APP_API_URL
    }/quickvet-templates?page=${currentPage}&perPage=${itemsPerPage}&account_id=${dataEncrypt(
      selectedAccountId?.toString() ?? '0'
    )}&orderBy=${orderBy}&order=${order}`;
  }

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData?.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};
