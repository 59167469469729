import React, { useState, useEffect } from 'react';
import TasksIndex from '../../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { ProviderState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import { REGISTERS, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ProviderLeftMenu from './ProviderLeftMenu';
import AddButton from '../../tasks/AddButton';
import { fetchProviders } from '../../../../api/outsourced-provider/fetchProviders';
import { useRegistersContext } from '../../registers/RegisterContext';

const ProviderTasksIndex = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let providerId = queryParams.get('providerId');
  const encryptedProviderId = providerId; // Decrypt the ID
  providerId = decryptData(providerId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/providers/${encryptedSourceId}`;
  const title = 'Provider Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { registersRecords } = useRegistersContext();

  if (sourceId && registersRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(registersRecords) && registersRecords.length > 0
        ? registersRecords.find((register) => register.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('provider_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/providers/details/${encryptedProviderId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, REGISTERS, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchProviderData = async () => {
    try {
      if (providerId && authToken) {
        const editId = parseInt(providerId!, 10);

        const response: ProviderState = (await fetchProviders(
          0,
          authToken,
          selectedAccount,
          editId
        )) as ProviderState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('provider_unique_id');
          localStorage.setItem('provider_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, REGISTERS, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/providers/add-task?source=${encryptedSourceId}&providerId=${encryptedProviderId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <ProviderLeftMenu
                  user={user}
                  providerId={providerId ? providerId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  providerIdEncrypted={encryptedProviderId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <TasksIndex
                  moduleType="providers"
                  sourceId={sourceId}
                  recordId={providerId}
                  localKey="provider_unique_id"
                  fetchModuleData={fetchProviderData}
                  editUrl={`/providers/edit-task/rowId?source=${encryptedSourceId}&providerId=${encryptedProviderId}`}
                  currentStatus={currentStatus}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default ProviderTasksIndex;
