import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import detail from '../../../assets/images/detail.svg';
import detaillActive from '../../../assets/images/detaill-black.svg';
import note from '../../../assets/images/note.svg';
import noteActive from '../../../assets/images/note-black.svg';
import templatesIc from '../../../assets/images/templates-ic.svg';
import templatesActive from '../../../assets/images/templates-black-ic.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { PaginationResponse, RecordsState, UserState } from '../user/state';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission
} from '../../../utils/common/user';
import { fetchRecords } from '../../../api/records/fetchRecords';
import { RecordsDataFormState } from './state';
import { useRegistersContext } from './RegisterContext';
import { fetchNotes } from '../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../api/notes-tasks/fetchTasks';
import { NOTE_READ, REGISTERS, TASK_READ } from '../../../constants/constants';
import { parseISO, format } from 'date-fns';
import { isFormatted } from '../../../utils/commonUtils';
import TasksNotesShowAddEditDialog from '../task-notes-dialog/TasksNotesShowAddEditDialog';

interface LeftMenuProps {
  user: UserState | undefined;
  recordId?: string;
  sourceId?: string;
  formData_record?: RecordsDataFormState;
  registerIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const RecordLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  recordId,
  sourceId,
  formData_record,
  registerIdEncrypted,
  sourceIdEncrypted
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const module_type = 'register_records';
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { registersRecords } = useRegistersContext();
  const { id } = useParams();
  const taskUrl = `/register/tasks?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`;
  const notesUrl = `/register/notes?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`;
  const detailsUrl = `/register-record/${registerIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/register-form-edit/${registerIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEditSecond = `/register-snapshot-edit/${registerIdEncrypted}?source=${sourceIdEncrypted}`;
  const registerId = sourceId ? parseInt(sourceId, 10) : 0;
  const foundRegister =
    Array.isArray(registersRecords) && registersRecords.length > 0
      ? registersRecords.find((register) => register.id === registerId)
      : '';
  const instructions =
    foundRegister && foundRegister.instructions != null && foundRegister.instructions != ''
      ? foundRegister?.instructions
      : t('record.addMenuMsg');
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('recordNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('recordTasksCount') || ''
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string>('');
  const [currentAction, setCurrentAction] = useState<string>('');
  const [stateUpdates, setStateUpdates] = useState(false);

  useEffect(() => {
    localStorage.setItem('typeData', JSON.stringify({ tasks: [], notes: [] }));
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [formData, setFormData] = useState<RecordsDataFormState>({
    register_id: 0,
    unique_id: '',
    is_open: 1,
    register_template_id: 0,
    created_at: ''
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const label = e.currentTarget.getAttribute('data-label') ?? '';
    const action = e.currentTarget.getAttribute('data-action') ?? '';
    if (
      path &&
      location.pathname !== '/register-record-add' &&
      location.pathname !== `/register-form-edit/${id}`
    ) {
      navigate(path);
    } else {
      setCurrentLabel(label);
      setCurrentAction(action);
      handleClickOpen();
    }
  };

  const fetchData = async () => {
    try {
      if (
        Array.isArray(registersRecords) &&
        registersRecords.length > 0 &&
        ((sourceId && !foundRegister) || !sourceId)
      ) {
        navigate('/404');
      }
      if (recordId && authToken) {
        const editId = parseInt(recordId!, 10);

        const response: RecordsState = (await fetchRecords(
          0,
          authToken,
          selectedAccount,
          editId
        )) as RecordsState;

        if (response) {
          const { register_id, unique_id, is_open, register_template_id } = response;

          localStorage.removeItem('record_unique_id');
          localStorage.setItem('record_unique_id', unique_id);
          let created_at;
          if (response.created_at) {
            const date = new Date(response.created_at);
            created_at = date.toISOString().split('T')[0];
          }
          let closed_at;
          if (response.closed_at) {
            const date = new Date(response.closed_at);
            closed_at = date.toISOString().split('T')[0];
          }

          const newFormData = {
            register_id,
            unique_id,
            register_template_id,
            is_open,
            created_at,
            closed_at
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('recordNotesCount');
    try {
      if (authToken) {
        const storedTypeData = localStorage.getItem('typeData');
        const recordEditId = sessionStorage.getItem('record-id');
        const record_Id = recordEditId ? parseInt(recordEditId, 10) : parseInt(recordId!, 10);
        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          registerId,
          module_type,
          authToken,
          0,
          0,
          0,
          'id',
          'desc',
          storedTypeData
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('recordNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('recordTasksCount');
    try {
      if (authToken) {
        const storedTypeData = localStorage.getItem('typeData');
        const recordEditId = sessionStorage.getItem('record-id');
        const record_Id = recordEditId ? parseInt(recordEditId, 10) : parseInt(recordId!, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          registerId,
          module_type,
          authToken,
          0,
          0,
          0,
          'id',
          'desc',
          storedTypeData
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('recordTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    } else {
      setFormData(formData_record);
      const keyCheck = localStorage.getItem('record_unique_id');
      if (formData_record && formData_record.unique_id && !keyCheck && keyCheck === null) {
        localStorage.setItem('record_unique_id', formData_record.unique_id);
      }
    }
    if (!localStorage.getItem('recordNotesCount')) {
      fetchNotesCount();
    }

    if (!localStorage.getItem('recordTasksCount')) {
      fetchTasksCount();
    }
  }, [sourceId, formData_record, stateUpdates]);

  // URLs exclude from the menu
  const excludedUrls = [
    '/register/add-note',
    `/register/edit-note/${id}`,
    '/register/add-task',
    `/register/edit-task/${id}`,
    `/register-chart/${sourceIdEncrypted}`,
    `/register-snapshot-edit/${id}`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit, detailsUrlEditSecond] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (
      (location.pathname === '/register-record-add' ||
        location.pathname === `/register-form-edit/${id}`) &&
      item.label === 'Details'
    ) {
      return false; // Exclude Details item when URL is /register-record-add
    }
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, REGISTERS, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, REGISTERS, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/register/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {(location.pathname == '/register-record-add' ||
              location.pathname == `/register-chart/${sourceIdEncrypted}`) && (
              <span className="inner-text-wrap">{instructions}</span>
            )}
            <div className="sidebar-inner">
              {location.pathname !== '/register-record-add' &&
                location.pathname !== `/register-chart/${sourceIdEncrypted}` && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      <p>{formData && formData?.unique_id ? formData?.unique_id : ''}</p>
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Received</h6>
                      <p>
                        {formData && formData.created_at
                          ? isFormatted(formData.created_at)
                            ? formData.created_at
                            : format(parseISO(formData.created_at), 'd/MMM/yyyy')
                          : 'N/A'}
                      </p>
                    </div>

                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      <p>{formData && formData?.is_open ? 'Open' : 'Archived'}</p>
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul
                  className="menu-item-wrap"
                  style={location.pathname === '/register-record-add' ? { marginTop: '1rem' } : {}}>
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      data-label={item.label}
                      data-action={item.label === 'Tasks' ? 'list-task' : 'list-note'}
                      onClick={(e) => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0], e);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label === 'Notes' ? notesCount || 0 : tasksCount || 0}
                            </text>
                          </svg>
                        </i>
                      )}
                      {(location.pathname === '/register-record-add' ||
                        location.pathname === `/register-form-edit/${id}`) && (
                        <div
                          style={{ marginLeft: '1rem' }}
                          data-label={item.label}
                          data-action={item.label === 'Tasks' ? 'add-task' : 'add-note'}
                          onClick={(e) => {
                            if (Array.isArray(item.path)) {
                              handleNavigation(item.path[0], e);
                            }
                          }}>
                          <i className="left" style={{ color: '#98A2B3' }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <g clipPath="url(#clip0_6545_879)">
                                <path
                                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_6545_879">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </i>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
          <TasksNotesShowAddEditDialog
            handleClose={handleClose}
            dialogOpen={dialogOpen}
            currentLabel={currentLabel}
            currentAction={currentAction}
            recordId={recordId}
            sourceId={sourceId}
            setStateUpdates={setStateUpdates}
            setCurrentAction={setCurrentAction}
            addTaskUrl={`/register/add-task?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`}
            editTaskUrl={`/register/edit-task/rowId?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`}
            addNoteUrl={`/register/add-note?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`}
            editNoteUrl={`/register/edit-note/rowId?source=${sourceIdEncrypted}&recordId=${registerIdEncrypted}`}
          />
        </div>
      )}
    </>
  );
};
export default RecordLeftMenu;
