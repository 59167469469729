import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Button, CircularProgress } from '@mui/material';
import { checkUserIsNewEntrant, getSelectedAccountInfo, isSubscriptionExpired, useAuthToken, useCurrentUserInfo } from '../../../../../utils/common/user';
import {
  ProfessionalYearState,
  ProfessionalYearTemplateDataState,
  ProfessionalYearTemplateState
} from '../state';
import { fetchProfessionalYearPlanTemplate } from '../../../../../api/professional-year/fetchProfessionalYearPlanTemplate';
import CommonLayout from '../../../layout/CommonLayout';
import CommonBreadcrumbs from '../../../layout/Breadcrumb/CommonBreadcrumbs';
import ProfessionalYearTemplateTextMode from '../ProfessionalYearTemplateTextMode';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const PlanSupervisorTemplateView = () => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [professionalYearData, setProfessionalYearData] = useState<ProfessionalYearState>({
    id: 0
  });
  const [formData, setFormData] = useState<ProfessionalYearTemplateDataState>({
    id: 0,
    professional_year_id: 0,
    form_data: '',
    error: ''
  });

  const dashboardPath = `/new-entrant`;
  const pageTitle = 'Professional Year Plan';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  useEffect(() => {
    if (subscriptonExpired || !checkUserIsNewEntrant(user)) {
      navigate('/dashboard');
    }

    const data = localStorage.getItem('professional-year-data');
    if (data) {
      setProfessionalYearData(JSON.parse(data));
    }
  }, []);

  const fetchTemplate = async () => {
    try {
      if (professionalYearData.id && authToken) {
        setLoading(true);

        const response: ProfessionalYearTemplateState = (await fetchProfessionalYearPlanTemplate(
          professionalYearData.id,
          authToken
        )) as ProfessionalYearTemplateState;

        if (response.id) {
          const { form_data, plan_supervisor_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
          } else {
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFound')
            }));
          }

          if (plan_supervisor_data) {
            setFormData(plan_supervisor_data);
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, [professionalYearData]);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            dashboardPath={dashboardPath}
            title={pageTitle}
            pageTitle={pageTitle}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : templateData && formData.form_data ? (
                  <>
                    <div className="card-outline">
                      <ProfessionalYearTemplateTextMode
                        surveyData={JSON.parse(templateData)}
                        formData={JSON.parse(formData.form_data)}
                        type={t('templateType.planSupervisor')}
                      />
                    </div>
                    
                    <div className="support-request-button">
                      <Button
                        fullWidth
                        className="btn primary-btn btn-sm"
                        component={RouterLink}
                        to={`/new-entrant`}>
                        Return to home
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="no-record">Plan supervisor data not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default PlanSupervisorTemplateView;
