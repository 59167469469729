import React from 'react';
import { Route, Routes } from 'react-router';
import Onboardings from './Onboardings';
import OnboardingCreateEdit from './OnboardingCreateEdit';
import OnboardingNotes from './OnboardingNotes';
import OnboardingNotesAdd from './OnboardingNotesAdd';
import OnboardingTasks from './OnboardingTasks';
import OnboardingTasksAddEdit from './OnboardingTasksAddEdit';
import OnboardingApplicationReview from './OnboardingApplicationReview';
import OnboardingAssessment from './OnboardingAssessment';
import OnboardingAssessmentReview from './OnboardingAssessmentReview';
import OnboardingChecklist from './OnboardingChecklist';
import OnboardingChecklistReview from './OnboardingChecklistReview';
import OnboardingAuthorisations from './OnboardingAuthorisations';
import OnboardingAuthorisationsReview from './OnboardingAuthorisationsReview';
import OnboardingUser from './OnboardingUser';

const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<Onboardings />} />
      <Route path="/details/:id" element={<OnboardingCreateEdit />} />
      <Route path="/notes" element={<OnboardingNotes />} />
      <Route path="/add-note" element={<OnboardingNotesAdd />} />
      <Route path="/edit-note/:id/" element={<OnboardingNotesAdd />} />
      <Route path="/tasks" element={<OnboardingTasks />} />
      <Route path="/add-task" element={<OnboardingTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<OnboardingTasksAddEdit />} />

      {/* Temnplats routes */}
      <Route path="/review-application/:id" element={<OnboardingApplicationReview />} />
      <Route path="/assessment" element={<OnboardingAssessment />} />
      <Route path="/assessment-review/:id" element={<OnboardingAssessmentReview />} />
      <Route path="/checklist" element={<OnboardingChecklist />} />
      <Route path="/checklist-review/:id" element={<OnboardingChecklistReview />} />
      <Route path="/authorisations" element={<OnboardingAuthorisations />} />
      <Route path="/authorisations-review/:id" element={<OnboardingAuthorisationsReview />} />
      <Route path="/create-user" element={<OnboardingUser />} />
    </Routes>
  );
};

export default OnboardingRoutes;
