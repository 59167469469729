import axios from 'axios';
import { AccountState } from '../../views/components/user/state';
import {
  MeetingsDataFormState,
  MeetingsResponseError
} from './../../views/components/modules/meetings/state.d';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';

export const createMeetingData = async (
  formData: MeetingsDataFormState,
  template: number,
  authToken?: string,
  accountInfo?: AccountState,
  meetingId?: string | number,
): Promise<MeetingsDataFormState | MeetingsResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    module_id,
    host_id,
    scribe_id,
    agenda_id,
    is_open,
    meeting_date_time,
    meeting_location,
    webinar_link,
    description,
    attendee_ids,
    meeting_started
  } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('template', template.toString());
    formData.append('host_id', host_id?.toString() || '');
    formData.append('scribe_id', scribe_id?.toString() || '');
    formData.append('agenda_id', agenda_id?.toString() || '');
    formData.append('meeting_date_time', meeting_date_time?.toString() || '');
    formData.append('meeting_location', meeting_location?.toString() || '');
    formData.append('webinar_link', webinar_link?.toString() || '');
    formData.append('description', description?.toString() || '');
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('module_id', module_id?.toString() || '');
    formData.append('is_open', is_open.toString());
    formData.append('meeting_started', meeting_started.toString());

    attendee_ids?.forEach((id) => {
      formData.append('attendees[]', id);
    })

    if (meetingId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/meetings/${dataEncrypt(meetingId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/meetings`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const sendEmailAttendes = async (
  meetingId: number,
  authToken?: string,
  accountInfo?: AccountState,
  encryptedSourceId?: string | null,
): Promise<MeetingsDataFormState | string | undefined | null> => {
  let routeUrl;
  try {
    const selectedAccountId = accountInfo?.id;
    const redirectUrl = `meeting/${encryptData(String(meetingId), true)}?account_id=${encryptData(String(selectedAccountId), true)}&redirect=true&source=${encryptedSourceId}`;
    const formData = new FormData(); // Create a FormData object
    formData.append('accountId', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('redirectUrl', redirectUrl);
    formData.append('meetingId', String(meetingId));

    routeUrl = `${process.env.REACT_APP_API_URL}/send-attendes-email`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
