import { ApiResponse, ApiResponseType } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountProviderTemplateState } from '../../views/components/modules/outsource-provider/state';

export const fetchProviderRegisterData = async (
  accountInfo?: AccountState,
  authToken?: string,
  source_Id?: number
): Promise<AccountProviderTemplateState[]> => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/provider/fetch-provider-register-data?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&register_id=${dataEncrypt(source_Id?.toString() ?? '0')}`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching modules';
  }
};
