import axios from 'axios';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const convertToEnterpriseProvider = async (
  accountInfo: AccountState,
  authToken: string,
  registerId: number,
  type: string,
  templateId: number,
): Promise<any> => {
  const selectedAccountId = accountInfo?.id;
  try {
    const formData = new FormData();
    formData.append('_method', 'PUT');

    const routeUrl = `${process.env.REACT_APP_API_URL}/provider/convert-to-enterprise?register_id=${dataEncrypt(registerId?.toString())}&accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&type=${type}&templateId=${dataEncrypt(templateId?.toString())}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response.data.data; // Return the 'active' status as a number
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
