import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import CalenderLeftMenu from './CalenderLeftMenu';
import AddTasks from '../../tasks/AddTasks';
import { useParams } from 'react-router-dom';

const CalendarTasksAddEdit = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true) || ''; // Default to an empty string if undefined
  const source_Id = parseInt(sourceId!, 10);
  let moduleId = queryParams.get('moduleId');
  const encryptedModuleId = moduleId; // Decrypt the ID
  moduleId = decryptData(moduleId, true) || ''; // Default to an empty string
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const mainPath = `/compliance-calendar/${encryptedSourceId}`;
  const title = 'Calendar Event Task';
  const pageTitle = id ? 'Edit Task' : 'Add Task';

  const thirdTitle = 'Tasks';
  const thirdPath = `/calendar/tasks?source=${encryptedSourceId}&moduleId=${encryptedModuleId}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id = localStorage.getItem('calendar_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/calendar/${encryptedModuleId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={pageTitle}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <CalenderLeftMenu
                  user={user}
                  moduleId={moduleId ? moduleId : ''}
                  sourceId={sourceId || ''}
                  calendarIdEncrypted={encryptedModuleId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <AddTasks
                  moduleType="calendar_events"
                  sourceId={sourceId}
                  recordId={moduleId}
                  localKey="calendarTasksCount"
                  listUrl={`/calendar/tasks?source=${encryptedSourceId}&moduleId=${encryptedModuleId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default CalendarTasksAddEdit;
