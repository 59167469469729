import React, { useState, useEffect } from 'react';
import LeftNav from '../LeftNav';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  decryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import AccountDetailsForm from './AccountDetailForm';
import { AccountDetailsResponseError, AccountDataFormState, AccountDetailsState } from '../state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useNotificationContext } from '../../layout/NotificationContext';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { createAddAccount } from '../../../../api/settings/createAddAccount';
import { fetchAddAccountsData } from '../../../../api/settings/fetchAddAccountsData';
import { fetchUsersAssociatedListAPI } from '../../../../api/dashboard/fetchUsersAssociatedList';
import { SelectChangeEvent } from '@mui/material';
import { DropdownResponse } from '../../user/state';

const AccountCreateEdit: React.FC = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  id = id ? decryptData(id, true) : '';
  const isEditing = !!id;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const secondTitle = 'Account Management';
  const secondpath = '/settings/add-accounts';
  const title = isEditing ? 'Edit Account' : 'Add Account';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [updateLogo, setUpdateLogo] = useState<File | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const authToken = useAuthToken() || '';
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const [prefixDisabled, setPrefixDisabled] = useState<boolean>(false);
  const [users, setUsers] = useState<DropdownResponse[]>([]);
  const [adviserUsers, setAdviserUsers] = useState<DropdownResponse[]>([]);

  const [formData, setFormData] = useState<AccountDataFormState>({
    accountName: '',
    accountPrefix: '',
    primaryColor: '',
    logo: null,
    is_bespoke: false,
    compliance_query_email: '',
    users_associated: [],
    users_adviser: [],
    error: ''
  });

  const [fetchLogo, setFetchLogo] = useState<{
    path: string;
    original_name: string;
  } | null>(null);

  useEffect(() => {
    const permission = id ? UPDATE : CREATE;

    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 9, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchUsersAssociatedList = async () => {
    try {
      const response: DropdownResponse[] = (await fetchUsersAssociatedListAPI(
        id
      )) as DropdownResponse[];

      if (Array.isArray(response)) {
        setUsers(response);
      } else {
        console.log('Error :', response);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchSettingAccountDetails = async () => {
    try {
      if (isEditing && id) {
        const userId = parseInt(id, 10);
        const accountData = await fetchAddAccountsData(1, '', authToken, selectedAccount, userId);

        const imageObject = accountData.image ? JSON.parse(accountData.image) : null; // Add a null check
        const typedImageObject = imageObject
          ? (imageObject as { path: string; original_name: string })
          : null;
        if (typedImageObject && typedImageObject.path) {
          setFetchLogo({
            path: typedImageObject ? typedImageObject.path : '',
            original_name: typedImageObject ? typedImageObject.original_name : ''
          });
        } else {
          setFetchLogo(null);
        }
        if (accountData.prefix) {
          setPrefixDisabled(true);
        }
        setFormData({
          accountName: accountData.name,
          accountPrefix: accountData.prefix,
          primaryColor: accountData.color,
          logo: accountData.image,
          compliance_query_email: accountData.compliance_query_email,
          users_associated:
            accountData.users_associated && accountData.users_associated.length > 0
              ? accountData.users_associated
              : [],
          users_adviser:
            accountData.users_adviser && accountData.users_adviser.length > 0
              ? accountData.users_adviser
              : [],
          error: ''
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsersAssociatedList();
    fetchSettingAccountDetails();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const advisers = users.filter((user) => formData.users_associated?.includes(user.id));
    setAdviserUsers(advisers);

    const userAdviser = formData.users_adviser?.filter((user) =>
      formData.users_associated?.includes(user)
    );
    setFormData((prevData) => ({ ...prevData, users_adviser: userAdviser }));
  }, [formData.users_associated]);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.accountName) {
      newErrors.accountName = t('error.field.accountDetailsName');
    }
    if (!formData.accountPrefix) {
      newErrors.accountPrefix = t('error.field.accountDetailsPrefix');
    } else if (formData.accountPrefix.length < 2 || formData.accountPrefix.length > 4) {
      newErrors.accountPrefix = t('error.field.accountDetailsPrefixLength');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    // Prepare the data to send to the API
    const requestData: AccountDetailsState = {
      name: formData.accountName,
      prefix: formData.accountPrefix,
      color: formData.primaryColor,
      image: updateLogo, // Use the updateLogo variable here
      compliance_query_email: formData.compliance_query_email,
      users_associated: formData.users_associated,
      users_adviser: formData.users_adviser
    };

    if (formData.is_bespoke !== selectedAccount.is_bespoke) {
      requestData.is_bespoke = formData.is_bespoke;
    }

    const response: AccountDetailsResponseError = (await createAddAccount(
      requestData,
      id,
      authToken
    )) as AccountDetailsResponseError;
    if (!response) {
      setNotificationSuccess(t('success.field.accountDetailsUpdates'));
      navigate('/settings/add-accounts');
      return;
    } else if (response == t('permission.doesNotHaveAccessPermission')) {
      setFormData((prevData) => ({
        ...prevData,
        error: t('permission.doesNotHaveAccessPermission')
      }));
      return;
    }

    const newErrors: Record<string, string> = {};
    if (response.name) {
      newErrors.accountName = response.name[0];
    } else if (response.prefix) {
      newErrors.accountPrefix = response.prefix[0];
    } else if (response.logo) {
      setSelectedFile(null);
      newErrors.logo = response.logo[0];
    } else {
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
    setErrors(newErrors);
  };

  const handleCancelClick = () => {
    navigate('/settings/add-accounts');
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);
      setUpdateLogo(file);
    } else {
      setPreviewURL(null);
      setUpdateLogo(null);
    }
  };

  const handleCloseClick = () => {
    setFormData((prevData) => ({ ...prevData, error: '' }));
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
              errorMsg={formData.error}
              handleCloseClick={handleCloseClick}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    <AccountDetailsForm
                      formData={formData}
                      handleInputChange={handleInputChange}
                      handleSubmit={handleSubmit}
                      handleFileChange={handleFileChange}
                      handleCancelClick={handleCancelClick}
                      previewURL={previewURL}
                      fetchLogo={fetchLogo}
                      errors={errors}
                      selectedFile={selectedFile}
                      prefixDisabled={prefixDisabled}
                      users={users}
                      adviserUsers={adviserUsers}
                      handleSelectChange={handleSelectChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default AccountCreateEdit;
