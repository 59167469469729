import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { ProvidersDataFormState, ProvidersResponseError } from '../../views/components/modules/outsource-provider/state';

export const createProviderData = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  providerId?: string | number,
  isStatusUpdate?: boolean
): Promise<ProvidersDataFormState | ProvidersResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { is_open, provider, service, owner_id, date_initial_engagement, contact_name, contact_email, register_id, form_data, provider_contract_template_id } =
    formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('provider', provider?.toString() || '');
    formData.append('service', service?.toString() || '');
    formData.append('owner_id', owner_id?.toString() || '');
    formData.append('date_initial_engagement', date_initial_engagement?.toString() || '');
    formData.append('contact_name', contact_name?.toString() || '');
    formData.append('contact_email', contact_email?.toString() || '');
    formData.append('register_id', register_id?.toString() || '');

    if (isStatusUpdate) {
      formData.append('is_open', is_open?.toString() || '');
    } else {
      formData.append('provider_contract_template_id', provider_contract_template_id);
      formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
      formData.append('form_data', JSON.stringify(form_data));
    }

    if (providerId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/providers/${dataEncrypt(providerId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/providers`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
