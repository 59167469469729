import React from 'react';
import { EditFormsPropsRegister } from '../state';
import TextField from '@mui/material/TextField';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { Button, Switch } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { encryptData } from '../../../../utils/common/user';

const RegisterCreateForm: React.FC<EditFormsPropsRegister> = ({
  registerTemplate,
  formData,
  handleInputChange,
  handleFileChange,
  handleSubmit,
  handleCancelClick,
  handleSwitchChange,
  previewURL,
  fetchLogo,
  errors,
  selectedFile,
  showEditTemplateButton,
  handleConvertToEnterprise
}) => {
  return (
    <>
      {showEditTemplateButton && (
        <div className="card-outline">
          <div className="card-title-wrap">
            <h3>Templates</h3>
          </div>
          <div className="row-wrap">
            <div className="inner-btn-wrap inner-attestation">
              <div
                className={`form-group ${
                  registerTemplate.type && registerTemplate.type == 'master'
                    ? ' second-button-attestation'
                    : 'second-button-attestation-width'
                }`}>
                <Button
                  fullWidth
                  className="btn primary-btn btn-sm"
                  component={RouterLink}
                  to={`/settings/register-template-edit/${encryptData(
                    String(registerTemplate.register_template_id),
                    true
                  )}?registerid=${encryptData(String(registerTemplate.register_id), true)}`}>
                  <i className="left">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <path
                        d="M17.9999 17.5029H11.3333M2.58325 17.9195L7.20764 16.1409C7.50343 16.0271 7.65132 15.9703 7.78968 15.896C7.91259 15.83 8.02975 15.7539 8.13995 15.6684C8.26402 15.5721 8.37606 15.46 8.60015 15.236L17.9999 5.83619C18.9204 4.91572 18.9204 3.42333 17.9999 2.50286C17.0795 1.58238 15.5871 1.58238 14.6666 2.50285L5.26682 11.9026C5.04273 12.1267 4.93069 12.2388 4.83441 12.3628C4.7489 12.473 4.67275 12.5902 4.60678 12.7131C4.53251 12.8515 4.47563 12.9994 4.36186 13.2951L2.58325 17.9195ZM2.58325 17.9195L4.29835 13.4603C4.42108 13.1412 4.48245 12.9817 4.5877 12.9086C4.67969 12.8447 4.7935 12.8206 4.9035 12.8416C5.02936 12.8656 5.15024 12.9865 5.39199 13.2282L7.27456 15.1108C7.51631 15.3526 7.63719 15.4734 7.66122 15.5993C7.68223 15.7093 7.65807 15.8231 7.59421 15.9151C7.52112 16.0204 7.36158 16.0817 7.04248 16.2044L2.58325 17.9195Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </i>
                  {registerTemplate.type && registerTemplate.type == 'master'
                    ? 'Update Master Template'
                    : 'Update Custom Template'}
                </Button>
              </div>
              {registerTemplate.type && registerTemplate.type == 'master' && (
                <div className="form-group second-button-attestation">
                  <Button
                    fullWidth
                    className="btn blue-fill-btn btn-sm"
                    onClick={handleConvertToEnterprise}>
                    <i className="left">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M13.3334 9.99739L10.0001 6.66406M10.0001 6.66406L6.66675 9.9974M10.0001 6.66406V14.3307C10.0001 15.4897 10.0001 16.0691 10.4588 16.7179C10.7637 17.149 11.6413 17.681 12.1644 17.7519C12.9518 17.8586 13.2509 17.7026 13.8489 17.3906C16.514 16.0004 18.3334 13.2114 18.3334 9.99739C18.3334 5.39502 14.6025 1.66406 10.0001 1.66406C5.39771 1.66406 1.66675 5.39502 1.66675 9.9974C1.66675 13.0819 3.34258 15.775 5.83342 17.2159"
                          stroke="CurrentColor"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    Convert to custom template
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="card-outline">
          <div className="row-wrap w-70">
            <div className="form-group">
              <label className="form-label" htmlFor="Name">
                Name
              </label>
              <TextField
                error={!!errors.name}
                id="Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Register Name"
                className="form-control"
                type="text"
                helperText={errors.name}
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="add_record_label">
                Add Record Label
              </label>
              <TextField
                error={!!errors.add_record_label}
                id="add_record_label"
                variant="outlined"
                name="add_record_label"
                value={formData.add_record_label}
                onChange={handleInputChange}
                placeholder="Add record label"
                className="form-control"
                type="text"
                helperText={errors.add_record_label}
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="Prefix">
                Prefix
              </label>
              <TextField
                error={!!errors.prefix}
                id="Prefix"
                variant="outlined"
                name="prefix"
                value={formData.prefix}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Register Prefix"
                type="text"
                helperText={errors.prefix}
              />
            </div>
            <div className="form-group"></div>
            <div className="form-group">
              <label className="form-label">Default Register for Starter Plan</label>
              <div className="switch-wrap">
                {formData.default_for_starter ? (
                  <>
                    <p className="mute-text">No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.default_for_starter === 1 ? true : false}
                      onChange={handleSwitchChange('default_for_starter')}
                    />
                    <p>Yes</p>
                  </>
                ) : (
                  <>
                    <p>No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.default_for_starter === 1 ? true : false}
                      onChange={handleSwitchChange('default_for_starter')}
                    />
                    <p className="mute-text">Yes</p>
                  </>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Default Register for Premium Plan</label>
              <div className="switch-wrap">
                {formData.default_for_premium ? (
                  <>
                    <p className="mute-text">No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.default_for_premium === 1 ? true : false}
                      onChange={handleSwitchChange('default_for_premium')}
                    />
                    <p>Yes</p>
                  </>
                ) : (
                  <>
                    <p>No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.default_for_premium === 1 ? true : false}
                      onChange={handleSwitchChange('default_for_premium')}
                    />
                    <p className="mute-text">Yes</p>
                  </>
                )}
              </div>
            </div>
            <div className="form-group w-full textarea">
              <label className="form-label">Description</label>
              <TextField
                error={!!errors.description}
                id="description"
                variant="outlined"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="form-control textarea-two-rows"
                type="text"
                multiline
                rows={2}
                placeholder="Enter a description..."
                helperText={errors.description}
              />
            </div>

            <div className="form-group w-full textarea">
              <label className="form-label">Instructions</label>
              <TextField
                error={!!errors.instructions}
                id="instructions"
                variant="outlined"
                name="instructions"
                value={formData.instructions ? formData.instructions : ''}
                onChange={handleInputChange}
                className="form-control"
                type="text"
                multiline
                rows={5}
                placeholder="Enter a instructions..."
                helperText={errors.instructions}
              />
            </div>
          </div>
        </div>
        <div className="form-group w-full file-upload">
          <label className="form-label large" htmlFor="Logo">
            Logo upload
          </label>
          <div className="relative">
            <TextField
              id="logo"
              variant="outlined"
              name="logo"
              onChange={handleFileChange}
              className="form-control"
              InputProps={{
                startAdornment: (
                  <input
                    type="file"
                    accept="image/*"
                    className="file-upload-input"
                    onChange={handleFileChange}
                    title="file"
                  />
                )
              }}
              helperText={errors.logo}
            />
            {selectedFile ? (
              <div className="preview-uploaded">
                {previewURL && <img src={previewURL} alt="Preview" />}
                <p className="file-name">
                  <span className="blue-text">Selected file:</span> {selectedFile.name}
                </p>
              </div>
            ) : fetchLogo != null ? (
              <div className="preview-uploaded">
                <img src={`${fetchLogo.path}`} alt="Logo" />
                <p className="file-name">
                  <span className="blue-text">Logo :</span> {fetchLogo.original_name}
                </p>
              </div>
            ) : (
              <p className="placeholder-text">
                <img src={fileUploadIc} alt="File upload" />
                <span>
                  <span className="blue-text">Click to upload</span> or drag and drop
                </span>
                <span className="small-text">Logo has to be 40px by 40px PNG file.</span>
              </p>
            )}
          </div>
        </div>
        <div className="btn-wrap">
          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit" className="btn primary-btn btn-sm">
            {formData.id ? 'Save changes' : 'Create'}
          </Button>
        </div>
      </form>
    </>
  );
};
export default RegisterCreateForm;
