import React, { useState } from 'react';
import LeftNav from '../LeftNav';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import HomeIc from '../../../../assets/images/home-outline.svg';
import rightArrowIc from '../../../../assets/images/right-arrow.svg';
import fileUploadIc from '../../../../assets/images/file-upload.svg';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconButton } from '@mui/material';

const RegisterUpdateCreate: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewURL(null);
    }
  };
  return (
    <div className="settings-outer-wrap">
      <div className="breadcrumbs-title-wrap">
        <div className="breadcrumbs-wrap">
          <Breadcrumbs aria-label="breadcrumb" separator={<img src={rightArrowIc} alt="arrow" />}>
            <Link href="/dashboard">
              <img src={HomeIc} alt="Home" />
            </Link>
            <Link href="/settings">Settings</Link>
            <span>Regulatory Updates</span>
          </Breadcrumbs>
        </div>
        <div className="title-wrap">
          <h2>Add Regulatory Update </h2>
          <IconButton aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
        </div>
      </div>
      <div className="settings-outer-row">
        <LeftNav />
        <div className="right-outer">
          <div className="right-inner">
            <div className="form-wrap">
              <form>
                <div className="card-outline">
                  <div className="card-title-wrap">
                    <h3>Regulatory Update</h3>
                  </div>
                  <div className="row-wrap">
                    <div className="form-group w-full">
                      <label className="form-label" htmlFor="category">
                        Category
                      </label>
                      <TextField
                        id="category"
                        variant="outlined"
                        name="category"
                        className="form-control"
                        type="text"
                        placeholder="Enter Category"
                      />
                    </div>
                    <div className="form-group w-full file-upload">
                      <label className="form-label large" htmlFor="imgaeUpdate">
                        Update Image
                      </label>
                      <div className="relative">
                        <TextField
                          id="imgaeUpdate"
                          variant="outlined"
                          name="imgaeUpdate"
                          className="form-control"
                          InputProps={{
                            startAdornment: (
                              <input
                                type="file"
                                accept="image/*"
                                className="file-upload-input"
                                onChange={handleFileChange}
                                title="file"
                              />
                            )
                          }}
                        />
                        {selectedFile ? (
                          <div className="preview-uploaded">
                            {previewURL && <img src={previewURL} alt="Preview" />}
                            <p className="file-name">
                              <span className="blue-text">Selected file:</span> {selectedFile.name}
                            </p>
                          </div>
                        ) : (
                          <p className="placeholder-text">
                            <img src={fileUploadIc} alt="File upload" />
                            <span>
                              <span className="blue-text">Click to upload</span>or drag and drop
                            </span>
                            <span className="small-text">
                              Logo has to be 40px by 40px PNG file.
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group w-full">
                      <label className="form-label" htmlFor="headline">
                        Headline
                      </label>
                      <TextField
                        id="headline"
                        variant="outlined"
                        name="headline"
                        className="form-control"
                        type="text"
                        placeholder="Enter article headline"
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-wrap">
                  <Button className="btn border-btn btn-sm">Cancel</Button>
                  <Button type="submit" className="btn primary-btn btn-sm">
                    Save changes
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterUpdateCreate;
