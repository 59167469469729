import React from 'react';
import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReorderIcon from '@mui/icons-material/Reorder';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { QuickVetTemplateFormsProps } from '../state';

const QuickVetTemplateCreateEditFormData: React.FC<QuickVetTemplateFormsProps> = ({
  formData,
  errors,
  handleInputChange,
  checks,
  setChecks
}) => {
  // Handle input change for checks
  const handleCheckChange = (index: number, field: 'displayText' | 'aiPrompt', value: string) => {
    const updatedChecks = [...checks];
    updatedChecks[index][field] = value;
    setChecks(updatedChecks);
  };

  // Add a new check
  const addCheck = () => {
    setChecks([...checks, { id: `${Date.now()}-${checks.length + 1}`, displayText: '', aiPrompt: '' }]);
  };

  // Remove a check
  const removeCheck = (index: number) => {
    setChecks(checks.filter((_, i) => i !== index));
  };

  // Handle drag end event to reorder the checks
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) return;

    const reorderedChecks = Array.from(checks);
    const [movedCheck] = reorderedChecks.splice(source.index, 1);
    reorderedChecks.splice(destination.index, 0, movedCheck);

    setChecks(reorderedChecks);
  };

  return (
    <>
      <div className="form-group w-full">
        <label className="form-label">Template name</label>
        <TextField
          error={!!errors.template_name}
          id="template_name"
          variant="outlined"
          name="template_name"
          value={formData.template_name}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Enter template name"
          type="text"
          inputProps={{
            maxLength: 120
          }}
        />
        {errors.template_name && (
          <Typography variant="caption" color="error">
            {errors.template_name}
          </Typography>
        )}
      </div>
      <div className="form-group w-full textarea">
        <label className="form-label">Description</label>
        <TextField
          error={!!errors.description}
          id="description"
          variant="outlined"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Enter description"
          type="text"
          multiline
          rows={3}
        />
        {errors.description && (
          <Typography variant="caption" color="error">
            {errors.description}
          </Typography>
        )}
      </div>

      {/* Checks Section */}
      <div className="form-group w-full checks textarea">
        <label className="form-label">Checks</label>

        {/* Display the labels once */}
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '16px' }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <label className="form-label">Display text</label>
          </Grid>
          <Grid item xs={5}>
            <label className="form-label">AI Prompt</label>
          </Grid>
        </Grid>

        {/* Draggable Section */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-checks">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {checks.map((check, index) => (
                  <Draggable key={check.id} draggableId={check.id} index={index}>
                    {(provided) => (
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          marginBottom: '16px', // Apply margin directly to the draggable item
                          ...provided.draggableProps.style // Ensure dragging style is maintained
                        }}
                      >
                        {/* Reorder button */}
                        <Grid item>
                          <IconButton {...provided.dragHandleProps}>
                            <ReorderIcon />
                          </IconButton>
                        </Grid>

                        {/* Description field */}
                        <Grid item xs={5}>
                          <TextField
                            className="form-control"
                            placeholder="Display text"
                            value={check.displayText}
                            onChange={(e) =>
                              handleCheckChange(index, 'displayText', e.target.value)
                            }
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                        </Grid>

                        {/* AI prompt field */}
                        <Grid item xs={5}>
                          <TextField
                            className="form-control"
                            placeholder="AI prompt"
                            value={check.aiPrompt}
                            onChange={(e) => handleCheckChange(index, 'aiPrompt', e.target.value)}
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                        </Grid>

                        {/* Delete button */}
                        <Grid item>
                          <IconButton onClick={() => removeCheck(index)} aria-label="delete">
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* Add Check Button */}
        <Button
          className='btn primary-btn btn-sm'
          onClick={addCheck}
          style={{ marginTop: '16px' }}
        >
          Add Check
        </Button>
      </div>
    </>
  );
};

export default QuickVetTemplateCreateEditFormData;
