import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Calendar from './Calendar';
import CalendarCreateEdit from './CalendarCreateEdit';
import CalendarNotesAdd from './CalendarNotesAdd';
import CalendarTasksAddEdit from './CalendarTasksAddEdit';
import CalendarTasksIndex from './CalendarTasksIndex';
import CalendarNotesIndex from './CalendarNotesIndex';

const AdviserRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Calendar />} />
      <Route path="/:id" element={<CalendarCreateEdit />} />
      <Route path="/notes" element={<CalendarNotesIndex />} />
      <Route path="/add-note" element={<CalendarNotesAdd />} />
      <Route path="/edit-note/:id/" element={<CalendarNotesAdd />} />
      <Route path="/tasks" element={<CalendarTasksIndex />} />
      <Route path="/add-task" element={<CalendarTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<CalendarTasksAddEdit />} />
    </Routes>
  );
};

export default AdviserRoutes;
