import React, { useEffect } from 'react';

const SignupEmbed = () => {
  useEffect(() => {
    const domain =
      process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'local'
        ? '3lines.outseta.com'
        : '3lines---staging.outseta.com';

    console.log(process.env.REACT_APP_ENV, domain);

    // Configuration options for the Outseta signup embed
    const o_signup_options = {
      id: 'Outseta',
      domain: domain,
      load: 'auth',
      auth: {
        widgetMode: 'register',
        skipPlanOptions: true,
        id: 'signup_embed',
        mode: 'embed',
        selector: '#signup-embed'
      }
    };

    // Convert the options into a string and attach them to the window
    (window as any).o_signup_options = o_signup_options;

    // Create the script tag dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.outseta.com/outseta.min.js';
    script.setAttribute('data-options', 'o_signup_options');
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* This div is where the Outseta signup form will be embedded */}
      <div id="signup-embed"></div>
    </div>
  );
};

export default SignupEmbed;
