import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Checkbox
} from '@mui/material';
import edit from '../../../../assets/images/edit.svg';
import copy from '../../../../assets/images/copy.svg';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import LeftNav from '../LeftNav';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { fetchRegisters } from '../../../../api/settings/fetchRegisters';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData
} from '../../../../utils/common/user';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PaginationSettings from '../Pagination';
import { RegistersState, PaginationResponse } from '../../user/state';
import CommonLayout from '../../layout/CommonLayout';
import { updateRegisterActiveStatus } from '../../../../api/settings/updateRegisterActiveStatus';
import { useRegistersContext } from '../../registers/RegisterContext';
import { duplicateRegisters } from '../../../../api/settings/updateRegisters';
import { useNotificationContext } from '../../layout/NotificationContext';
import { CREATE, READ, SETTINGS, UPDATE } from '../../../../constants/constants';

const RegistersIndex: React.FC = () => {
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const title = 'Registers';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const [orderBy, setOrderBy] = useState<keyof RegistersState>('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [error, setError] = useState<string>('');
  const { setNotificationSuccess } = useNotificationContext();
  const [registers, setRegisters] = useState<RegistersState[]>([]);
  const current_page = useParams();
  const { fetchRegisterRecords } = useRegistersContext();
  const { t } = useTranslation();
  const current_route = current_page['*'];
  interface HeadCell {
    disablePadding: boolean;
    id: keyof RegistersState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description'
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated'
    },
    {
      id: 'account_register_access',
      numeric: false,
      disablePadding: false,
      label: 'Active'
    },
    {
      id: 'account_register_access',
      numeric: false,
      disablePadding: false,
      label: 'Locked'
    }
  ];

  // headCells = headCells.filter((cell) => {
  //   if (cell.label === 'Visible') {
  //     return checkUserPermission(user, SETTINGS, 5, UPDATE);
  //   }
  //   return true;
  // });

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 5, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleSwitchToggle = async (registerId: number, name: string, newValue: number) => {
    const requestData = {
      id: registerId,
      is_open: 0,
      locked: 0
    };

    if (name === 'is_open') {
      requestData.is_open = newValue;
    } else if (name === 'locked') {
      requestData.locked = newValue;
    }
    try {
      const response = await updateRegisterActiveStatus(requestData, selectedAccount, authToken);

      if (response == t('permission.doesNotHaveAccessPermission')) {
        setError(t('permission.doesNotHaveAccessPermission'));
        return;
      }

      fetchData(1, orderBy, order);
      fetchRegisterRecords();
    } catch (error) {
      console.error('Error updating switch state:', error);
    }
  };

  const emptyRows = 6 - registers.length;
  const fetchData = async (type: number, orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (type !== 1) {
        setLoading(true);
      }
      if (authToken) {
        const response: PaginationResponse = (await fetchRegisters(
          authToken,
          selectedAccount,
          currentPage,
          undefined,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            // If data is an array (e.g., RegistersState[])
            setRegisters(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(0, orderBy, order);
  }, [currentPage, current_route]);

  const handleSort = (property: keyof RegistersState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(0, property, isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const duplicateSubmit = async (id: number) => {
    try {
      setLoading(true);
      const response: RegistersState = (await duplicateRegisters(
        id,
        authToken,
        selectedAccount
      )) as RegistersState;
      if (!response) {
        setLoading(false);
        const msg = t('success.field.registerduplicated');
        setNotificationSuccess(msg);
        fetchData(0, orderBy, order);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const redirectEditPage = (prefix: string, id: number) => {
    if (prefix == 'PRV') {
      navigate(`/settings/registers/provider/${encryptData(String(id), true)}`);
    } else {
      navigate(`/settings/registers-edit/${encryptData(String(id), true)}`);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 5, CREATE) && (
        <Button className="btn primary-btn" component={RouterLink} to={`/settings/registers-add`}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_6545_879)">
                <path
                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6545_879">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Add Register
        </Button>
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              button={addButton}
              errorMsg={error}
            />

            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="table-outer">
                    <TableContainer>
                      {loading ? ( // Check loading state
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      ) : registers.length === 0 ? (
                        <div className="no-record">No registers found.</div>
                      ) : (
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          className="table">
                          <TableHead>
                            <TableRow>
                              {headCells.map((headCell, index) => (
                                <TableCell
                                  key={`key-${headCell.id}-${index}`}
                                  align={headCell.numeric ? 'left' : 'left'}
                                  padding={headCell.disablePadding ? 'none' : 'normal'}
                                  sortDirection={orderBy === headCell.id ? order : false}>
                                  {headCell.id == 'account_register_access' ? (
                                    headCell.label // Render the label without sorting for the "id" column
                                  ) : (
                                    <TableSortLabel
                                      active={orderBy === headCell.id}
                                      direction={orderBy === headCell.id ? order : 'asc'}
                                      onClick={() => handleSort(headCell.id)}>
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={{ display: 'none' }}>
                                          {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                    </TableSortLabel>
                                  )}
                                </TableCell>
                              ))}
                              {checkUserPermission(user, SETTINGS, 5, UPDATE) && (
                                <TableCell></TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {registers.map(
                              (row: RegistersState) =>
                                row && (
                                  <TableRow key={row.id}>
                                    <TableCell scope="row">{row.name}</TableCell>
                                    <TableCell align="left" className="row-description">
                                      {row.description}
                                    </TableCell>
                                    {row.updated_at ? (
                                      <TableCell align="left">
                                        {format(new Date(row.updated_at), 'd/MMM/yyyy')}
                                      </TableCell>
                                    ) : (
                                      <TableCell align="left">N/A</TableCell>
                                    )}

                                    {checkUserPermission(user, SETTINGS, 5, UPDATE) && (
                                      <>
                                        <TableCell align="left" className="">
                                          <Checkbox
                                            onChange={(e) =>
                                              handleSwitchToggle(
                                                row.id,
                                                'is_open',
                                                e.target.checked ? 1 : 0
                                              )
                                            }
                                            checked={
                                              Array.isArray(row.account_register_access) &&
                                              row.account_register_access.length > 0 &&
                                              Number(
                                                row.account_register_access[0]?.locked ?? 0
                                              ) !== 1
                                            }
                                            className="custom-switch"
                                          />
                                        </TableCell>
                                        <TableCell align="left" className="">
                                          <Checkbox
                                            onChange={(e) =>
                                              handleSwitchToggle(
                                                row.id,
                                                'locked',
                                                e.target.checked ? 1 : 0
                                              )
                                            }
                                            checked={
                                              Array.isArray(row.account_register_access) &&
                                              row.account_register_access.length > 0 &&
                                              Number(
                                                row.account_register_access[0]?.locked ?? 0
                                              ) === 1
                                            }
                                            className="custom-switch"
                                          />
                                        </TableCell>
                                        <TableCell align="left">
                                          <div className="table-btn">
                                            {row.prefix !== 'PRV' ? (
                                              <Button
                                                className="copy"
                                                onClick={() => duplicateSubmit(row.id)}>
                                                <img src={copy} />
                                              </Button>
                                            ) : (
                                              <Button style={{ pointerEvents: 'none' }}></Button>
                                            )}
                                            <Button
                                              className="edit"
                                              style={
                                                row?.account_register_access?.length == 0
                                                  ? { pointerEvents: 'none' }
                                                  : undefined
                                              }
                                              onClick={() =>
                                                row?.account_register_access?.length !== 0
                                                  ? redirectEditPage(row.prefix || '', row.id)
                                                  : ''
                                              }>
                                              <img src={edit} alt="Right Arrow" />
                                            </Button>
                                          </div>
                                        </TableCell>
                                      </>
                                    )}
                                  </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (dense ? 33 : 53) * emptyRows
                                }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      )}
                      {!loading && registers.length > 0 && (
                        <PaginationSettings
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default RegistersIndex;
