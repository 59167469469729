import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import LeftNav from '../LeftNav';
import { PaginationResponse, PolicyState, UserState } from '../../user/state';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken
} from '../../../../utils/common/user';
import { fetchPolicies } from '../../../../api/policies/fetchPolicies';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import { NOTE_READ, SETTINGS, TASK_READ } from '../../../../constants/constants';

interface LeftMenuProps {
  user: UserState | undefined;
  policyId: string | undefined;
  formData_record?: PolicyState;
  encryptedPolicyId?: string | null;
}

const PoliciesLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  policyId,
  formData_record,
  encryptedPolicyId
}) => {
  const module_type = 'policies';
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();

  const taskUrl = `/settings/policies/tasks?policyId=${encryptedPolicyId}`;
  const notesUrl = `/settings/policies/notes?policyId=${encryptedPolicyId}`;
  const detailsUrl = `/settings/policies/details/${encryptedPolicyId}`;
  const detailsUrlEdit = `/settings/policies/update/${encryptedPolicyId}`;
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('policyNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('policyTasksCount') || ''
  );

  const [formData, setFormData] = useState<PolicyState>({
    id: 0,
    owner: 0,
    policy_name: '',
    versions: {
      version: '',
      file: ''
    },
    date_next_review: '',
    is_open: 1,
    closed_at: '',
    error: ''
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    if (policyId && authToken) {
      try {
        const editId = parseInt(policyId!, 10);

        const response: PolicyState = (await fetchPolicies(
          authToken,
          selectedAccount,
          editId
        )) as PolicyState;

        if (response) {
          localStorage.removeItem('policy_unique_id');
          localStorage.setItem('policy_unique_id', response?.unique_id || '');

          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('policyNotesCount');
    try {
      if (authToken && policyId) {
        const record_Id = parseInt(policyId, 10);

        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          null,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('policyNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('policyTasksCount');
    try {
      if (authToken && policyId) {
        const record_Id = parseInt(policyId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          null,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('policyTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    } else {
      localStorage.removeItem('policy_unique_id');
      localStorage.setItem('policy_unique_id', formData_record?.unique_id || '');
      setFormData(formData_record);
    }

    if (!localStorage.getItem('policyNotesCount')) {
      fetchNotesCount();
    }
    if (!localStorage.getItem('policyTasksCount')) {
      fetchTasksCount();
    }
  }, [formData_record]);

  // URLs exclude from the menu
  const excludedUrls = [
    '/settings/policies',
    '/settings/policies/add',
    `/settings/policies/update/${encryptedPolicyId}`,
    '/settings/policies/add-note',
    `/settings/policies/edit-note/${encryptedPolicyId}`,
    '/settings/policies/add-task',
    `/settings/policies/edit-task/${encryptedPolicyId}`,
    `/settings/policies/${encryptedPolicyId}/new-version`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (item.label === 'Notes' && checkUserPermission(user, SETTINGS, 7, NOTE_READ)) {
      return item;
    } else if (item.label === 'Tasks' && checkUserPermission(user, SETTINGS, 7, TASK_READ)) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/settings/policies` ? (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>

            {(location.pathname == `/settings/policies/add` ||
              location.pathname == `/settings/policies/update/${encryptedPolicyId}`) && (
              <span className="inner-text-wrap">{t('policy.addMenuMsg')}</span>
            )}

            {location.pathname == `/settings/policies/${encryptedPolicyId}/new-version` && (
              <span className="inner-text-wrap">{t('policy.addNewVersionMenuMsg')}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== `/settings/policies/add` &&
                location.pathname !== `/settings/policies/update/${encryptedPolicyId}` &&
                location.pathname !== `/settings/policies/${encryptedPolicyId}/new-version` && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      {formData && formData.unique_id ? (
                        <p>{formData.unique_id ? formData.unique_id : ''}</p>
                      ) : null}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Policy</h6>
                      {formData && formData.policy_name && <p>{formData.policy_name}</p>}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Owner</h6>
                      {formData && formData.user ? (
                        <p>
                          {formData.user.first_name} {formData.user.last_name}
                        </p>
                      ) : null}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      {formData && formData.is_open ? (
                        <p>{formData.is_open == 1 ? 'Active' : 'Inactive'}</p>
                      ) : null}
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      ) : (
        <LeftNav />
      )}
    </>
  );
};

export default PoliciesLeftMenu;
