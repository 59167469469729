import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateRegisters } from '../../../../api/settings/updateRegisters';
import { useAuthToken, getSelectedAccountInfo, decryptData } from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { AccountRegisterTemplateState, RegisterDataFormState } from '../state';
import { RegistersState } from '../../user/state';
import { fetchRegisters } from '../../../../api/settings/fetchRegisters';
import RegisterCreateForm from './RegisterCreateForm';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useRegistersContext } from '../../registers/RegisterContext';
import { fetchRegisterTemplate } from '../../../../api/settings/fetchRegisterTemplate';
import Popup from '../../common/popup/Popup';
import { convertToEnterpriseRegister } from '../../../../api/settings/convertToEnterpriseRegister';

const RegisterCreate: React.FC = () => {
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const account_id = parseInt(selectedAccount.id, 10);
  const { t } = useTranslation();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const register_id = parseInt(id!, 10);
  const { pathname, search } = useLocation(); // Access the URL query
  const queryParams = new URLSearchParams(search);
  let copyId = queryParams.get('copyId');
  copyId = copyId ? decryptData(copyId, true) : 0;
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [updateLogo, setUpdateLogo] = useState<Blob | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [showEditTemplateButton, setShowEditTemplateButton] = useState<boolean>(true);
  const { setNotificationSuccess } = useNotificationContext();
  const { fetchRegisterRecords } = useRegistersContext();
  const [openAlertPopup, setOpenAlertPopup] = useState<boolean>(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [registerTemplate, setregisterTemplate] = useState<AccountRegisterTemplateState>({
    account_id: 0,
    register_id: 0,
    register_template_id: 1
  });
  const [formData, setFormData] = useState<RegisterDataFormState>({
    id: 0,
    name: '',
    add_record_label: '',
    prefix: '',
    description: '',
    instructions: '',
    logo: null,
    default_for_starter: 0,
    default_for_premium: 0,
    error: ''
  });

  const [fetchLogo, setFetchLogo] = useState<{
    path: string;
    original_name: string;
  } | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);
      setUpdateLogo(file);
    } else {
      setPreviewURL(null);
      setUpdateLogo(null);
    }
  };
  const handleCancelClick = () => {
    navigate(`/settings/registers`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.name) {
      newErrors.name = t('error.field.registerDetailsName');
    }
    if (!formData.add_record_label) {
      newErrors.add_record_label = t('error.field.registerDetailsAddRecordLabel');
    }
    if (!formData.description) {
      newErrors.description = t('error.field.registerDetailsDescription');
    }
    if (!formData.instructions) {
      newErrors.instructions = t('error.field.detailsInstructions');
    }
    if (!formData.prefix) {
      newErrors.prefix = t('error.field.accountDetailsPrefix');
    } else if (formData.prefix.length < 2 || formData.prefix.length > 4) {
      newErrors.prefix = t('error.field.accountDetailsPrefixLength');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getTemplateIdsForRegister = async () => {
    try {
      const response: AccountRegisterTemplateState = (await fetchRegisterTemplate(
        register_id,
        account_id,
        authToken
      )) as AccountRegisterTemplateState;

      if (response.id == 0 || response.id) {
        setregisterTemplate(response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    if (
      pathname === `/settings/registers/provider/${encryptedId}` ||
      pathname === `/settings/registers-add`
    ) {
      setShowEditTemplateButton(false);
    }

    if (
      pathname !== `/settings/registers/provider/${encryptedId}` &&
      pathname !== `/settings/registers-add`
    ) {
      getTemplateIdsForRegister();
    }

    const fetchData = async () => {
      try {
        if ((id || copyId) && authToken) {
          const registerId = copyId ? parseInt(copyId, 10) : parseInt(id!, 10);
          const response: RegistersState = (await fetchRegisters(
            authToken,
            selectedAccount,
            0,
            0,
            registerId
          )) as RegistersState;

          if (response) {
            const {
              id,
              name,
              add_record_label,
              prefix,
              description,
              instructions,
              logo,
              default_for_starter,
              default_for_premium
            } = response;
            const imageObject = logo ? JSON.parse(logo) : null;
            const typedImageObject = imageObject as { path: string; original_name: string };

            if (typedImageObject && typedImageObject.path) {
              setFetchLogo({
                path: typedImageObject.path,
                original_name: typedImageObject.original_name
              });
            } else {
              setFetchLogo(null);
            }

            const newFormData = {
              id: copyId ? 0 : id,
              name,
              add_record_label,
              prefix,
              description,
              instructions,
              logo: null,
              default_for_starter: parseInt(default_for_starter?.toString() || '', 10),
              default_for_premium: parseInt(default_for_premium?.toString() || '', 10),
              error: ''
            };

            setFormData(newFormData);
          }
        }
      } catch (error) {
        // Handle any errors here
        setFetchLogo(null);
        console.error('Error fetching data:', error);
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    };
    fetchData(); // Call the async function
  }, [id, copyId, authToken, t]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    // Prepare the data to send to the API
    const requestData = {
      id: formData.id,
      name: formData.name,
      add_record_label: formData.add_record_label,
      prefix: formData.prefix,
      description: formData.description,
      instructions: formData.instructions,
      logo: updateLogo,
      default_for_starter: formData.default_for_starter,
      default_for_premium: formData.default_for_premium
    };

    const response: any = (await updateRegisters(requestData, authToken, selectedAccount)) as any;
    if (response && !response.id) {
      if (response == t('permission.doesNotHaveAccessPermission')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('permission.doesNotHaveAccessPermission')
        }));
      } else {
        const newErrors: Record<string, string> = {};
        if (response.name) {
          newErrors.name = response.name[0];
        } else if (response.add_record_label) {
          newErrors.add_record_label = response.add_record_label[0]; // Take the first error message for 'prefix'
        } else if (response.prefix) {
          newErrors.prefix = response.prefix[0]; // Take the first error message for 'prefix'
        } else if (response.description) {
          newErrors.description = response.description[0]; // Take the first error message for 'prefix'
        } else if (response.instructions) {
          newErrors.instructions = response.instructions[0]; // Take the first error message for 'prefix'
        } else if (response.logo) {
          setSelectedFile(null);
          newErrors.logo = response.logo[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      }
    } else {
      const msg = formData.id
        ? t('success.field.registerUpdated')
        : t('success.field.registerCreated');
      setNotificationSuccess(msg);
      fetchRegisterRecords();
      navigate(`/settings/registers`);
    }
  };

  const handleConvertToEnterprise = () => {
    if (registerTemplate.id == 0) {
      setOpenAlertPopup(true);
      return;
    }

    setOpenConfirmationPopup(true);
  };

  const togglePopup = (type?: string) => {
    if (type == 'alert') {
      setOpenAlertPopup(false);
    } else if (type == 'confirmation') {
      setOpenConfirmationPopup(false);
    }
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      const response: AccountRegisterTemplateState = (await convertToEnterpriseRegister(
        register_id,
        account_id,
        authToken
      )) as AccountRegisterTemplateState;

      if (response.id) {
        getTemplateIdsForRegister();
      } else {
        console.log(response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const handleSwitchChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFormData((prevData) => ({ ...prevData, [fieldName]: checked ? 1 : 0 }));
    };

  return (
    <>
      <div className="form-wrap">
        <RegisterCreateForm
          registerTemplate={registerTemplate}
          formData={formData}
          handleInputChange={handleInputChange}
          handleFileChange={handleFileChange}
          handleSubmit={handleSubmit}
          handleCancelClick={handleCancelClick}
          handleSwitchChange={handleSwitchChange}
          previewURL={previewURL}
          fetchLogo={fetchLogo}
          errors={errors}
          selectedFile={selectedFile}
          showEditTemplateButton={showEditTemplateButton}
          handleConvertToEnterprise={handleConvertToEnterprise}
        />
      </div>

      <Popup
        type="alert"
        openPopup={openAlertPopup}
        text={`Mark this register as visible for ${selectedAccount.name} account.`}
        togglePopup={togglePopup}
      />

      <Popup
        type="confirmation"
        openPopup={openConfirmationPopup}
        text={`Are you sure you want to convert ${selectedAccount.name} account to Enterprise for this register?`}
        togglePopup={togglePopup}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default RegisterCreate;
