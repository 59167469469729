import axios from 'axios';
import { QuestionsFormState } from '../../views/components/settings/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const uploadMeetingAgendaQuestions = async (
  questionFormData: QuestionsFormState,
  authToken?: string
): Promise<string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object

    formData.append('agenda_id', questionFormData.agenda_id.toString());
    if (questionFormData.questionFile) {
      formData.append('question_file', questionFormData.questionFile);
    }

    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/meetings/upload-questions`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
