import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import OnboardingLeftMenu from './OnboardingLeftMenu';
import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { Button, CircularProgress } from '@mui/material';
import { OnboardingTempplateReviewDataState } from '../../user/state';
import OnboardingTemplateTextMode from './OnboardingTemplateTextMode';
import { OnboardingTemplateState } from './state';
import {
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useNotificationContext } from '../../layout/NotificationContext';
import { t } from 'i18next';
import { fetchOnboardingAuthorisationsTemplate } from '../../../../api/onboarding/fetchOnboardingAuthorisationsTemplate';
import { onboardingAuthorisationsDataSubmit } from '../../../../api/onboarding/onboardingAuthorisationsDataSubmit';

const OnboardingAuthorisationsReview = () => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();
  const authToken = useAuthToken();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let onboardingId = queryParams.get('onboardingId');
  const encryptedonboardingId = onboardingId; // Decrypt the ID
  onboardingId = decryptData(encryptedonboardingId, true);
  const onboarding_id = parseInt(onboardingId!, 10);
  let sourceId = queryParams.get('sourceId');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_id = parseInt(sourceId!, 10);
  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [formData, setFormData] = useState<OnboardingTempplateReviewDataState>({
    id: 0,
    onboarding_id: 0,
    form_data: '{}',
    submitted: 0,
    error: ''
  });

  const { moduleRecords } = useModuleContext();
  let mainTitle;
  const mainPath = `/onboarding/${encryptedSourceId}`;
  if (sourceId) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }
  let secondTitle;
  const unique_id = localStorage.getItem('onboarding_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/onboarding/details/${encryptedonboardingId}?source=${encryptedSourceId}`;
  const pageTitle = 'Review Authorisations';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: OnboardingTemplateState = (await fetchOnboardingAuthorisationsTemplate(
          onboarding_id,
          source_id,
          selectedAccount,
          authToken
        )) as OnboardingTemplateState;

        if (response.id) {
          const { form_data, authorisations_data } = response;

          if (form_data && form_data !== '""') {
            setTemplateData(form_data);

            if (authorisations_data) {
              setFormData(authorisations_data);
            }
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const handleSubmit = async () => {
    setFormLoading(true);

    try {
      if (authToken && id) {
        const onboardingAuthorisationId = parseInt(id, 10);
        const response: any = await onboardingAuthorisationsDataSubmit(
          onboardingAuthorisationId,
          authToken
        );
        if (response.id) {
          setFormData(response);
          setNotificationSuccess(t('success.field.onboardingAuthorisationsDataSubmited'));
          navigate(secondpath);
        } else {
          console.log('Error: ', response);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setFormLoading(false);
    }
  };

  let edit_btn_form = null;
  if (formData.submitted == 0) {
    edit_btn_form = (
      <Button
        className="btn blue-fill-btn"
        component={RouterLink}
        to={`/onboarding/authorisations?dataId=${encryptedId}&onboardingId=${encryptedonboardingId}&source=${encryptedSourceId}`}>
        <i className="left">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        Edit
      </Button>
    );
  }
  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            secondTitle={secondTitle}
            secondpath={secondpath}
            title={pageTitle}
            pageTitle={pageTitle}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <OnboardingLeftMenu
              user={user}
              onboardingId={onboardingId ? onboardingId : ''}
              sourceId={sourceId ? sourceId : ''}
              onboardingIdEncrypted={encryptedonboardingId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : templateData && formData.id ? (
                  <>
                    <div className="card-outline">
                      <OnboardingTemplateTextMode
                        surveyData={JSON.parse(templateData)}
                        formData={formData.form_data ? JSON.parse(formData.form_data) : {}}
                        edit_btn={edit_btn_form}
                        title="Authorisations"
                      />
                    </div>

                    {formData.submitted == 0 && (
                      <div className="row-wrap center">
                        {formLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button className="btn primary-btn btn-sm" onClick={handleSubmit}>
                            <i className="left">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                  d="M16.6668 10.4141V5.66406C16.6668 4.26393 16.6668 3.56387 16.3943 3.02909C16.1547 2.55868 15.7722 2.17623 15.3018 1.93655C14.767 1.66406 14.067 1.66406 12.6668 1.66406H7.3335C5.93336 1.66406 5.2333 1.66406 4.69852 1.93655C4.22811 2.17623 3.84566 2.55868 3.60598 3.02909C3.3335 3.56387 3.3335 4.26393 3.3335 5.66406V14.3307C3.3335 15.7309 3.3335 16.4309 3.60598 16.9657C3.84566 17.4361 4.22811 17.8186 4.69852 18.0582C5.2333 18.3307 5.93336 18.3307 7.3335 18.3307H10.0002M11.6668 9.16406H6.66683M8.3335 12.4974H6.66683M13.3335 5.83073H6.66683M12.0835 15.8307L13.7502 17.4974L17.5002 13.7474"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                            Submit authorisations
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="no-record">Review not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default OnboardingAuthorisationsReview;
