import React from 'react';
import { Route, Routes } from 'react-router-dom';
import QuickVetIndex from './QuickVetIndex';
import QuickVetCreateEdit from './QuickVetCreateEdit';

const QuickVetRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<QuickVetIndex />} />
      <Route path="/:id" element={<QuickVetCreateEdit />} />
    </Routes>
  );
};

export default QuickVetRoutes;
