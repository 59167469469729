export interface ApiResponseType {
  message: string;
  data?: any;
  success?: boolean;
  status?: number;
  url?: string;
}

export class ApiResponse extends Error implements ApiResponseType {
  message: string;
  data?: any;
  success?: boolean;
  status?: number;

  constructor({ message, data, success, status }: ApiResponseType) {
    super(message);
    this.message = message;
    this.success = success;
    this.status = status;
    this.data = data;
  }
}
