import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck, encryptData } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { PrevetState } from '../../views/components/user/state';

export const sendEmail = async (
    recordId: number,
    sourceId?: number,
    authToken?: string,
    accountInfo?: AccountState,
): Promise<PrevetState | string | undefined | null> => {
    let routeUrl;
    try {
        const selectedAccountId = accountInfo?.id;
        const encryptedAccountId = encryptData(String(selectedAccountId), true);
        const encryptedSourceId = encryptData(String(sourceId), true);
        const encryptedPrevetId = encryptData(String(recordId), true);
        const returnUrl = `prevet/${encryptedPrevetId}?source=${encryptedSourceId}&account_id=${encryptedAccountId}&redirect=true`;

        const formData = new FormData(); // Create a FormData object
        formData.append('returnUrl', returnUrl);
        formData.append('prevetId', recordId.toString());
        formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');

        routeUrl = `${process.env.REACT_APP_API_URL}/send-prevet-email`;
        const response = await axios.post<ApiResponseType>(routeUrl, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }
        });

        if (response.data.success) {
            return response?.data.data;
        } else {
            tokenCheck(response.status);
            throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
        }
    } catch (error: any) {
        const errorMessage =
            error instanceof ApiResponse ? error.message : error?.response?.data?.message;

        return errorMessage;
    }
};
