import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet from react-helmet

interface CommonLayoutProps {
  title: string;
  children: ReactNode; // Use ReactNode to type the children prop
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ title, children }) => {
  return (
    <div>
      <Helmet>
        <title>{title ? title : process.env.REACT_APP_NAME}</title>
      </Helmet>
      {/* The rest of your component */}
      {children}
    </div>
  );
};

export default CommonLayout;
