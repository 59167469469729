import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { fetchModuleHeaderListing } from '../../../../api/settings/fetchModuleHeaderListing';
import { ModulesState } from '../../user/state';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';

interface ModuleProviderProps {
  children: ReactNode;
}

interface ModuleContextType {
  moduleRecords: ModulesState[];
  fetchModuleRecords: () => void;
}

const ModuleContext = createContext<ModuleContextType | undefined>(undefined);

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  if (!context) {
    throw new Error('useModuleContext must be used within a ModulesProvider');
  }
  return context;
};

export const ModulesProvider: React.FC<ModuleProviderProps> = ({ children }) => {
  const selectedAccount = getSelectedAccountInfo();
  const authToken = useAuthToken();
  const [moduleRecords, setModulesRecords] = useState<ModulesState[]>([]);

  const fetchModuleRecords = async () => {
    try {
      // Fetch the records and update the state
      const response: ModulesState[] = await fetchModuleHeaderListing(authToken, selectedAccount);
      if (response) {
        setModulesRecords(response);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (selectedAccount?.id) fetchModuleRecords();
  }, [useAuthToken]);

  return (
    <ModuleContext.Provider value={{ moduleRecords, fetchModuleRecords }}>
      {children}
    </ModuleContext.Provider>
  );
};
