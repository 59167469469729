export const removeFile = (
  index: number,
  selectedFiles: FileList | null,
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>,
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
) => {
  if (selectedFiles) {
    // Convert FileList to an array and ensure all items are of type File
    const updatedFiles = Array.from(selectedFiles).map((file: any) => {
      if (!(file instanceof File)) {
        const blob = new Blob([file], { type: file.file_type });
        return new File([blob], file.name, { type: file.file_type });
      }
      return file;
    });

    // Remove the file at the specified index
    updatedFiles.splice(index, 1);

    // Handle the case when there are no files left
    if (updatedFiles.length === 0) {
      setSelectedFiles(null);
      setFiles([]);
    } else {
      // Create a new FileList from the updatedFiles array
      const newSelectedFiles = new DataTransfer();
      updatedFiles.forEach((file) => newSelectedFiles.items.add(file));

      setSelectedFiles(newSelectedFiles.files);
      setFiles(updatedFiles);
    }
  }
};
