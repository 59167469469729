import React, { useEffect, useState } from 'react';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo,
  useAuthToken
} from '../../../../utils/common/user';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { MeetingState, MeetingsDataFormState, MeetingsTasksDataLeftMenuState } from './state';
import { fetchMeetings } from '../../../../api/meeting/fetchMeetings';
import { PaginationResponse, UserState } from '../../user/state';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import {
  RunMeetingLetMenuModuleButtons,
  RunMeetingLetMenuRegisterButtons
} from '../../../../utils/common/variable';
import { useRegistersContext } from '../../registers/RegisterContext';
import {
  MODULES,
  NOTE_READ,
  READ,
  REGISTERS,
  RESOURCES,
  TASK_READ
} from '../../../../constants/constants';

interface LeftMenuProps {
  user: UserState | undefined;
  meetingId?: string;
  sourceId?: string;
  formData_record?: MeetingsDataFormState;
  previousMeetingReportUrl?: string;
  meetingIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const MeetingLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  meetingId,
  sourceId,
  formData_record,
  previousMeetingReportUrl,
  meetingIdEncrypted,
  sourceIdEncrypted
}) => {
  const module_type = 'meetings';
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();

  const taskUrl = `/meeting/tasks?source=${sourceIdEncrypted}&meetingId=${meetingIdEncrypted}`;
  const notesUrl = `/meeting/notes?source=${sourceIdEncrypted}&meetingId=${meetingIdEncrypted}`;
  const detailsUrl = `/meeting/${meetingIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/meeting-snapshot-edit/${meetingIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEditSecond = `/meeting-form-edit/${meetingIdEncrypted}?source=${sourceIdEncrypted}`;
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('meetingNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('meetingTasksCount') || ''
  );

  const [formData, setFormData] = useState<MeetingsTasksDataLeftMenuState | MeetingsDataFormState>({
    unique_id: '',
    agenda_id: 0,
    meeting_date_time: '',
    is_open: 0
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (meetingId && authToken) {
        const editId = parseInt(meetingId!, 10);

        const response: MeetingState = (await fetchMeetings(
          0,
          authToken,
          selectedAccount,
          editId
        )) as MeetingState;

        if (response) {
          const { unique_id, agenda_id, meeting_date_time, is_open, agenda } = response;
          const uniqueId = unique_id || '';

          localStorage.removeItem('meeting_unique_id');
          localStorage.setItem('meeting_unique_id', uniqueId);

          const newFormData = {
            unique_id: uniqueId,
            meeting_date_time,
            agenda_id,
            is_open,
            agenda
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('meetingNotesCount');
    try {
      if (authToken && meetingId) {
        const record_Id = parseInt(meetingId, 10);

        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('meetingNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('meetingTasksCount');
    try {
      if (authToken && meetingId) {
        const record_Id = parseInt(meetingId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('meetingTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    } else {
      formData_record ? setFormData(formData_record) : '';
    }

    if (
      !localStorage.getItem('meetingNotesCount') &&
      location.pathname !== '/meeting/review-agenda' &&
      location.pathname !== '/meeting/run-meeting'
    ) {
      fetchNotesCount();
    }
    if (
      !localStorage.getItem('meetingTasksCount') &&
      location.pathname !== '/meeting/review-agenda' &&
      location.pathname !== '/meeting/run-meeting'
    ) {
      fetchTasksCount();
    }
  }, [sourceId, formData_record]);

  const { moduleRecords } = useModuleContext();
  let filteredModuleRecords;
  if (moduleRecords) {
    filteredModuleRecords = moduleRecords.filter((item) => {
      if (checkUserPermission(user, MODULES, item.id, READ)) {
        return RunMeetingLetMenuModuleButtons.includes(item.id.toString());
      }
    });
  }

  const { registersRecords } = useRegistersContext();
  let filteredRegisterRecords;
  if (registersRecords) {
    filteredRegisterRecords = registersRecords.filter((item) => {
      if (checkUserPermission(user, REGISTERS, item.id, READ)) {
        return RunMeetingLetMenuRegisterButtons.includes(item.id.toString());
      }
    });
  }

  // URLs exclude from the menu
  const excludedUrls = [
    '/meeting-add',
    '/meeting/add-note',
    `/meeting/edit-note/${id}`,
    '/meeting/add-task',
    `/meeting/edit-task/${id}`,
    `/meeting-edit/${id}`,
    `/meeting/review-agenda`,
    `/meeting/run-meeting`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit, detailsUrlEditSecond] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/meetings/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/meeting-add' && (
              <span className="inner-text-wrap">{t('meeting.addMenuMsg')}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/meeting-add' && (
                <div className="comp-uder-detail">
                  <div className="comp-uder-detail-list">
                    <h6>ID</h6>
                    {formData && formData.unique_id ? (
                      <p>{formData.unique_id ? formData.unique_id : ''}</p>
                    ) : null}
                  </div>
                  <div className="comp-uder-detail-list">
                    <h6>Meeting date/time</h6>
                    {formData && formData.meeting_date_time && (
                      <p>
                        {formData.meeting_date_time &&
                        formData.meeting_date_time !== 'Invalid Date' &&
                        formData.meeting_date_time !== 'Invalid date format' ? (
                          <>
                            {format(
                              new Date(formData.meeting_date_time.split(' ')[0]),
                              'd/MMM/yyyy'
                            )}{' '}
                            {formData.meeting_date_time.split(' ')[1]}{' '}
                            {formData.meeting_date_time.split(' ')[2]}
                          </>
                        ) : (
                          ''
                        )}
                      </p>
                    )}
                  </div>
                  <div className="comp-uder-detail-list">
                    <h6>Agenda</h6>
                    {formData && formData?.agenda_id ? <p>{formData.agenda?.name}</p> : null}
                  </div>
                  <div className="comp-uder-detail-list">
                    <h6>Status</h6>
                    {formData && formData?.is_open ? (
                      <p>{formData?.is_open == 1 ? 'Open' : 'Archived'}</p>
                    ) : null}
                  </div>
                </div>
              )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}

              {location.pathname === '/meeting/run-meeting' && (
                <div className="comp-uder-detail">
                  <div className="comp-uder-detail-list">
                    <h6 className="mb-2">Quick links</h6>

                    {checkUserPermission(user, MODULES, 3, READ) &&
                      previousMeetingReportUrl !== '' && (
                        <Button
                          fullWidth
                          className="btn primary-btn btn-sm mb-2"
                          component={RouterLink}
                          to={`${previousMeetingReportUrl}`}
                          target="_blank">
                          Previous minutes
                        </Button>
                      )}

                    {filteredRegisterRecords &&
                      filteredRegisterRecords.map((register) => (
                        <Button
                          key={register.name}
                          fullWidth
                          className="btn primary-btn btn-sm mb-2"
                          component={RouterLink}
                          to={
                            register.prefix === 'PRV'
                              ? `/providers/${encryptData(String(register.id), true)}`
                              : `/register/${encryptData(String(register.id), true)}`
                          }
                          target="_blank">
                          {register.name}
                        </Button>
                      ))}

                    {filteredModuleRecords &&
                      filteredModuleRecords.map((module) => (
                        <Button
                          key={module.name}
                          fullWidth
                          className="btn primary-btn btn-sm mb-2"
                          component={RouterLink}
                          to={`/${module.route}/${encryptData(String(module.id), true)}`}
                          target="_blank">
                          {module.name}
                        </Button>
                      ))}
                    
                    {/* for policies */}
                    {checkUserPermission(user, RESOURCES, 2, READ) && (
                      <Button
                        fullWidth
                        className="btn primary-btn btn-sm mb-2"
                        component={RouterLink}
                        to={`/resources/policies`}
                        target="_blank">
                        Policies
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
            {location.pathname == '/meeting/review-agenda' && (
              <span className="inner-text-wrap">{t('meeting.reviewAgendaMenuMsg')}</span>
            )}
          </nav>
        </div>
      )}
    </>
  );
};
export default MeetingLeftMenu;
