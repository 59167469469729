import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { ProviderReviewDataFormState, ProviderReviewResponseError } from './state';
import { DropdownResponse, ProviderReviewState } from '../../user/state';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import * as SurveyCore from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { ReviewTemplatesState } from '../../user/state';
import { useNotificationContext } from '../../layout/NotificationContext';
import { SelectChangeEvent } from '@mui/material/Select';
import CommonLayout from '../../layout/CommonLayout';
import ProviderReviewCreateEditFormData from './ProviderReviewCreateEditFormData';
import ProviderLeftMenu from './ProviderLeftMenu';
import { fetchProviderTemplate } from '../../../../api/outsourced-provider/fetchProviderTemplate';
import { fetchProviderReviews } from '../../../../api/outsourced-provider/fetchProviderReviews';
import { createProviderReview } from '../../../../api/outsourced-provider/createProviderReview';
import ProviderReviewCompleteView from './ProviderReviewCompleteView';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import { CREATE, REGISTERS, UPDATE } from '../../../../constants/constants';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';

const ProviderReviewCreateEdit: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let providerId = queryParams.get('provider');
  const encryptedProviderId = providerId;
  providerId = providerId ? decryptData(providerId, true) : 0;
  const provider_Id = parseInt(providerId!, 10);

  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [closed_at, setClosed_at] = useState<string | null>(null);
  const { setNotificationSuccess } = useNotificationContext();
  const [isDisabled, setIsDisabled] = useState(Boolean); // Initially disabled if id is not available
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [reviewerArray, setReviewerArray] = useState<DropdownResponse[]>([]);
  const mainPath = `/providers/${encryptedSourceId}`;
  const [providerReviewAddMode, setProviderReviewAddMode] = useState(false); // Initially disabled if id is not available
  const [loading, setLoading] = useState<boolean>(true);
  const secondTitle = 'Outsourced Providers';
  const pageTitle = id ? `Contract Review` : `Contract Review`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.toLocaleString('default', {
    month: 'short'
  })}/${today.getFullYear()}`;

  const [date_received, setDate_received] = useState<string | null>(formattedDate);

  const [formData, setFormData] = useState<ProviderReviewDataFormState>({
    id: 0,
    reviewer_id: 0,
    provider_id: 0,
    provider_review_template_id: 1,
    review_date: '',
    is_open: 1,
    created_at: formattedDate,
    created_by: 0,
    error: '',
    reviewer: {
      first_name: '',
      last_name: ''
    },
    user: {
      first_name: '',
      last_name: ''
    }
  });

  let thirdTitle;
  if (formData && formData.provider?.unique_id) {
    thirdTitle = formData.provider?.unique_id;
  } else {
    thirdTitle = localStorage.getItem('provider_unique_id');
  }
  const thirdPath = `/providers/details/${encryptedProviderId}?source=${encryptedSourceId}`;

  let survey: any;
  const surveyJson = window.localStorage.getItem('survey-json-prv-review-template') || ''; // Provide an empty string as the default
  if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
    survey = new Model(surveyJson);
    survey.showCompletedPage = false; // prevent the thank-you page
    survey.showNavigationButtons = true; // Hide the default navigation buttons
    survey.isSinglePage = false; // show all pages in single
    survey.focusFirstQuestionAutomatic = false;
    survey.pageNextText = 'Save and next';
    survey.completeText = 'Save';
    survey.addNavigationItem({
      id: 'survey_clear_current_page',
      title: 'Cancel',
      visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
      action: () => {
        handleCancelClick();
      }
    });
  }

  SurveyCore.ChoicesRestfull.clearCache();
  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  const [templateData, setTemplateData] = useState<string>('');

  useEffect(() => {
    const permission = id ? UPDATE : CREATE;
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, REGISTERS, source_Id, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
      if (response) {
        setReviewerArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (
      current_route == `/providers/review-edit/${encryptedId}` ||
      current_route == `/providers/review-add` ||
      current_route == `/providers/review` ||
      current_route == `/providers/review-data`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      templateData != '' &&
      formData?.reviews_data &&
      Object.keys(formData?.reviews_data?.form_data).length !== 0
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.reviews_data.form_data)
      );
      localStorage.removeItem('survey-json-prv-review-template');
      localStorage.setItem('survey-json-prv-review-template', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.removeItem('survey-json-prv-review-template');
      localStorage.setItem('survey-json-prv-review-template', templateData);
    }

    if (!hasEffectRun) {
      fetchTemplate();
      fetchData(); // Call the async function
      fetchUsers();
      setTimeout(() => {
        setHasEffectRun(true);
        setLoading(false);
      }, 1000);
    } else {
      return;
    }
  }, [templateData, formData, hasEffectRun, current_route]);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const reviewId = parseInt(id!, 10);

        const response: ProviderReviewState = (await fetchProviderReviews(
          authToken,
          selectedAccount,
          provider_Id,
          reviewId
        )) as ProviderReviewState;

        if (response) {
          const {
            id,
            provider_id,
            provider_review_template_id,
            created_at,
            closed_at,
            review_date,
            reviewer_id,
            is_open,
            reviewer,
            user,
            reviews_data,
            provider
          } = response;

          if (created_at) {
            setDate_received(created_at);
          }

          if (closed_at) {
            setClosed_at(closed_at);
          }
          const newFormData = {
            id,
            provider_id,
            reviewer_id,
            provider_review_template_id,
            review_date,
            is_open,
            created_at,
            closed_at,
            error: '',
            reviewer: {
              id: reviewer ? reviewer.id : 0,
              email: reviewer ? reviewer.email : '',
              first_name: reviewer ? reviewer.first_name : '',
              last_name: reviewer ? reviewer.last_name : ''
            },
            user: {
              id: user ? user.id : 0,
              email: user ? user.email : '',
              first_name: user ? user.first_name : '',
              last_name: user ? user.last_name : ''
            },
            reviews_data: {
              id: reviews_data ? reviews_data.id : 0,
              review_id: reviews_data ? reviews_data.review_id : 0,
              form_data: reviews_data ? reviews_data.form_data : ''
            },
            provider
          };
          setFormData(newFormData);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };
  const fetchTemplate = async () => {
    try {
      const reviewId = parseInt(id!, 10);
      if (authToken) {
        const response: ReviewTemplatesState = (await fetchProviderTemplate(
          authToken,
          t('templateType.contractReview'),
          selectedAccount,
          source_Id,
          provider_Id,
          reviewId
        )) as ReviewTemplatesState;

        if (response) {
          const { id, form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            setFormData((prevData) => ({ ...prevData, provider_review_template_id: id }));
          } else {
            localStorage.removeItem('survey-json-prv-template-template');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFoundReview')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined) {
          // Add the value to the element
          element.defaultValue = fieldValues[fieldName];
        }
      });
    });
    return mainResponse;
  };

  const handleChangeReviewer = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleReviewDateInputChange = (date: any): void => {
    setFormData((prevData) => ({
      ...prevData,
      review_date: date.format('YYYY-MM-DD')
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.review_date) {
      newErrors.review_date = t('error.field.reviewDateRequired');
    }
    if (!formData.reviewer_id) {
      newErrors.reviewer_id = t('error.field.reviewer');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  if (survey && survey.data) {
    survey.onUploadFiles.add((_: any, options: any) => {
      try {
        uploadSurveyFile(options.files, authToken)
          .then((response) => {
            const data: any = response;
            setTimeout(() => {
              options.callback(
                options.files.map((file: any, index: number) => {
                  return {
                    file: file,
                    content: data[index]
                  };
                })
              );
            }, 2000);
          })
          .catch((error) => {
            // Handle other error cases
            console.error(error); // Log the error
            options.callback([], ['An error occurred during file upload.']);
          });
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        options.callback([], ['An error occurred during file upload.']);
      }
    });

    // Save survey results when click on next
    survey.onCurrentPageChanged.add(() => {
      handleSurveySubmit('nextPage');
    });

    survey.onComplete.add(() => {
      handleSurveySubmit();
    });
  }

  const handleSurveySubmit = async (type = '') => {
    let isFormValid = true;
    let validationResult = true;

    if (current_route === `/providers/review-edit/${encryptedId}?source=${encryptedSourceId}`) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    } else if (
      templateData &&
      current_route === `/providers/review-data?source=${encryptedSourceId}`
    ) {
      if (!type) {
        validationResult = survey.validate();
      }
      if (!validationResult) {
        return;
      }
    } else if (!formData.id) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    } else {
      if (!type) {
        validationResult = survey.validate();
      }
      if (!validationResult) {
        return;
      }
    }

    const provider_Id = formData.provider_id ? formData.provider_id : providerId;
    if (isFormValid && validationResult) {
      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;
      const requestData = {
        reviewer_id: formData.reviewer_id,
        provider_id: provider_Id,
        provider_review_template_id: formData.provider_review_template_id,
        review_date: formData.review_date,
        is_open: formData.is_open,
        form_data: survey ? surveyData : ''
      };

      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createProviderReview(
          requestData,
          authToken,
          selectedAccount,
          edit_id
        );

        if (response && response?.id) {
          // Success: Handle successful response
          localStorage.removeItem('survey-json-prv-review-template');
          const msg = id ? t('success.field.reviewUpdated') : t('success.field.reviewSaved');

          if (
            response.id &&
            current_route &&
            current_route === `/providers/review-add` &&
            !providerReviewAddMode
          ) {
            setErrors({});
            setNewResponse(response);
            navigate(
              `/providers/review-data?review=${encryptData(String(response.id), true)}&source=${encryptedSourceId}&provider=${encryptedProviderId}`
            );
          } else if (id || providerReviewAddMode) {
            if (!type) {
              setHasEffectRun(false);
              setNotificationSuccess(msg);
              setProviderReviewAddMode(false);
              navigate(`/providers/details/${encryptedProviderId}?source=${encryptedSourceId}`);
            }
          } else {
            navigate(`/providers/${encryptedSourceId}`);
            setNotificationSuccess(msg);
          }
        } else {
          // Error handling for validation errors
          const newErrors: Record<string, string> = {};

          if (response.reviewer_id) {
            newErrors.reviewer_id = response.reviewer_id[0];
          } else if (response.review_date) {
            newErrors.review_date = response.review_date[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const setNewResponse = (response: ProviderReviewState) => {
    if (response) {
      const { id, provider_id, reviewer_id, provider_review_template_id, review_date, created_at } =
        response;
      if (closed_at) {
        setClosed_at(closed_at);
      }
      const newFormData = {
        id,
        provider_id,
        review_date,
        reviewer_id,
        provider_review_template_id,
        created_at
      };
      setFormData(newFormData);
      setTimeout(() => {
        setProviderReviewAddMode(true);
      }, 200);
    }
  };

  const handleFinalizebutton = async (newStatus: number) => {
    const requestData = {
      review_date: formData.review_date,
      reviewer_id: formData.reviewer_id,
      provider_review_template_id: formData.provider_review_template_id,
      provider_id: providerId,
      is_open: newStatus
    };
    const response: ProviderReviewResponseError = (await createProviderReview(
      requestData,
      authToken,
      selectedAccount,
      id,
      true
    )) as ProviderReviewResponseError;
    if (response && response.id) {
      localStorage.removeItem('survey-json-prv-contract-template');
      const msg = newStatus
        ? t('success.field.providerReviewOpen')
        : t('success.field.providerReviewClosed');

      setNotificationSuccess(msg);
      if (response.id) {
        navigate(`/providers/details/${encryptedProviderId}?source=${encryptedSourceId}`);
        setHasEffectRun(false);
      } else {
        navigate(`/providers/${encryptedSourceId}`);
      }
    } else {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const handleCancelClick = () => {
    if (providerId) {
      navigate(`/providers/details/${encryptedProviderId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/providers/${encryptedSourceId}`);
    }
  };

  const back_btn = (
    <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
      Back
    </Button>
  );

  const editButton = (
    <div className={`inner-btn-wrap`}>
      {formData.is_open == 1 &&
      templateData &&
      current_route === `/providers/review-edit/${encryptedId}` ? (
        <React.Fragment>
          <Button className="btn white-btn" onClick={() => handleFinalizebutton(0)}>
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_10218_12413)">
                  <path
                    d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                    fill="#667085"
                  />
                  <path
                    d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                    fill="#667085"
                  />
                  <path
                    d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                    fill="#667085"
                  />
                  <path
                    d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                    fill="#667085"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10218_12413">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Archive
          </Button>
        </React.Fragment>
      ) : (
        back_btn
      )}
    </div>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
                errorMsg={formData.error}
                button={current_route === `/providers/review-add` ? null : editButton}
              />

              <div className="record-outer-row settings-outer-row">
                <ProviderLeftMenu
                  user={user}
                  providerId={providerId}
                  sourceId={sourceId ? sourceId : ''}
                  providerIdEncrypted={encryptedProviderId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {current_route &&
                      (current_route === `/providers/review/${encryptedId}` ||
                        current_route === `/providers/review-add`) &&
                      !providerReviewAddMode && (
                        <ProviderReviewCreateEditFormData
                          formData={formData}
                          errors={errors}
                          date_received={date_received}
                          reviewerArray={reviewerArray}
                          closed_at={closed_at}
                          isDisabled={isDisabled}
                          current_route={current_route}
                          providerReviewAddMode={providerReviewAddMode}
                          handleChangeReviewer={handleChangeReviewer}
                          handleInputChange={handleInputChange}
                          handleReviewDateInputChange={handleReviewDateInputChange}
                          providerReviewIdEncrypted={encryptedId}
                        />
                      )}

                    {loading ? (
                      <div className="no-record">
                        <CircularProgress />
                      </div>
                    ) : surveyJson &&
                      !isDisabled &&
                      survey &&
                      formData.id &&
                      (current_route === `/providers/review-data` ||
                        current_route === `/providers/review-edit/${encryptedId}` ||
                        providerReviewAddMode) ? (
                      <div className="card-outline">
                        <Survey model={survey} />
                      </div>
                    ) : null}

                    {formData.is_open == 0 &&
                      templateData &&
                      formData.reviews_data?.form_data &&
                      current_route === `/providers/review/${encryptedId}` && (
                        <div className="card-outline">
                          <ProviderReviewCompleteView
                            surveyData={JSON.parse(templateData)}
                            formData={JSON.parse(formData?.reviews_data?.form_data)}
                          />
                        </div>
                      )}

                    {!isDisabled &&
                      !providerReviewAddMode &&
                      current_route == `/providers/review-add` && (
                        <div className="btn-wrap">
                          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </Button>
                          {templateData && (
                            <Button
                              className="btn primary-btn btn-sm"
                              type="submit"
                              onClick={() => handleSurveySubmit()}>
                              {current_route == `/providers/review-edit/${formData.id}`
                                ? 'Save'
                                : 'Save and continue'}
                            </Button>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default ProviderReviewCreateEdit;
