import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import {
  QuickVetDataFormState,
  QuickVetsResponseError
} from '../../views/components/modules/quickvets/state';

export const createQuickVetData = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  quickVetId?: string | number,
  isStatusUpdate?: boolean
): Promise<QuickVetDataFormState | QuickVetsResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { subject, adviser_id, template_id, is_open, module_id, document } = formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('subject', subject);
    formData.append('adviser_id', adviser_id);
    formData.append('module_id', module_id);
    formData.append('template_id', template_id);
    if (document) {
      formData.append('document', document);
    }

    if (isStatusUpdate) {
      formData.append('is_open', is_open);
    } else {
      formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    }

    if (quickVetId) {
      const encryptedAccountId = encryptData(String(selectedAccountId), true);
      const encryptedSourceId = encryptData(String(module_id), true);
      const encryptedQuickVetId = encryptData(String(quickVetId), true);
      const returnUrl = `quickvet/${encryptedQuickVetId}?source=${encryptedSourceId}&account_id=${encryptedAccountId}&redirect=true`;
      formData.append('returnUrl', returnUrl);
    }

    if (quickVetId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/quickvets/${dataEncrypt(
        quickVetId?.toString()
      )}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/quickvets`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
