import { ApiResponse, ApiResponseType } from '../apiResponse';
import { TemplatesDropdownResponse } from '../../views/components/settings/state';
import { tokenCheck } from '../../utils/common/user';

export const fetchTemplatesDropdown = async (
  authToken?: string
): Promise<TemplatesDropdownResponse[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/active-templates`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    const responseData: ApiResponseType = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
