import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { StripeSessionUrlState } from '../../views/components/settings/state';
import axios from 'axios';

export const fetchSubscriptionSessionURL = async (
  authToken: string,
  accountInfo: AccountState,
  planId: number
): Promise<StripeSessionUrlState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/subscription?account_id=${selectedAccountId}&planId=${planId}`;

  try {
    const response = await axios.post<ApiResponseType>(routeUrl, [], {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.data.status);
      throw new ApiResponse(response.data);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching updates';
  }
};

