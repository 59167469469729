import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Button, SelectChangeEvent } from '@mui/material';
import PoliciesCreateEditFormData from './PoliciesCreateEditFormData';
import { DropdownResponse, PolicyState } from '../../user/state';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { t } from 'i18next';
import { useNotificationContext } from '../../layout/NotificationContext';
import PoliciesLeftMenu from './PoliciesLeftMenu';
import { fetchPolicies } from '../../../../api/policies/fetchPolicies';
import { createPolicyData } from '../../../../api/policies/createPolicyData';
import {
  CREATE,
  DELETE,
  NOTE_CREATE,
  READ,
  SETTINGS,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
import { isAfter } from 'date-fns';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';
import DeleteButton from '../../common/DeleteButton';
import Popup from '../../common/popup/Popup';
import { deletePolicy } from '../../../../api/policies/deletePolicy';

const PoliciesCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id ? id : '';
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title =
    current_route == `/settings/policies/details/${encryptedId}`
      ? 'Policy'
      : current_route == `/settings/policies/${encryptedId}/new-version`
        ? 'New Version'
        : id
          ? 'Edit Policy'
          : 'Add Policy';
  const secondpath = '/settings/policies';
  const secondTitle = 'Policies';
  const thirdPath = `/settings/policies/details/${encryptedId}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [users, setUsers] = useState<DropdownResponse[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false); // Initially disabled if id is not available
  const [formData, setFormData] = useState<PolicyState>({
    id: 0,
    owner: 0,
    policy_name: '',
    versions: {
      version: '',
      file: ''
    },
    date_next_review: '',
    is_open: 1,
    closed_at: '',
    error: ''
  });
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  useEffect(() => {
    const permission =
      current_route === `/settings/policies/details/${encryptedId}` ? READ : id ? UPDATE : CREATE;

    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 7, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const policyId = parseInt(id!, 10);

        const response: PolicyState = (await fetchPolicies(
          authToken,
          selectedAccount,
          policyId
        )) as PolicyState;

        if (response) {
          if (current_route == `/settings/policies/${encryptedId}/new-version`) {
            response.versions = {
              version: ''
            };
          }
          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
      if (response) {
        setUsers(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if (
      current_route == `/settings/policies/add` ||
      current_route == `/settings/policies/update/${encryptedId}` ||
      current_route == `/settings/policies/${encryptedId}/new-version`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    fetchData(); // Call the async function
    fetchUsers();
  }, [current_route]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'version') {
      setFormData((prevData) => ({
        ...prevData,
        versions: { ...prevData.versions, [name]: value }
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateInputChange = (date: any): void => {
    setFormData((prevData) => ({
      ...prevData,
      date_next_review: date.format('YYYY-MM-DD')
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleCancelClick = () => {
    navigate(`/settings/policies`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (current_route !== `/settings/policies/${encryptedId}/new-version`) {
      if (!formData.owner) {
        newErrors.owner = t('error.field.ownerRequired');
      }
      if (!formData.policy_name) {
        newErrors.policy_name = t('error.field.policyNameRequired');
      }
      if (!formData.date_next_review) {
        newErrors.date_next_review = t('error.field.nextReviewDateRequired');
      }
      if (isAfter(new Date(), new Date(formData.date_next_review))) {
        newErrors.date_next_review = t('error.field.nextReviewDateFutureDate');
      }
    }

    if (current_route !== `/settings/policies/update/${encryptedId}`) {
      if (formData.versions && !Array.isArray(formData?.versions) && !formData.versions.version) {
        newErrors.version = t('error.field.versionRequired');
      }
      if (!selectedFile) {
        newErrors.file = t('error.field.fileRequired');
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      const edit_id = id ? id : formData.id;
      let type = '';
      if (current_route === `/settings/policies/${encryptedId}/new-version`) {
        type = 'new-version';
      }

      try {
        const response: any = await createPolicyData(
          formData,
          selectedFile,
          authToken,
          selectedAccount,
          edit_id,
          type
        );

        if (response && response.id) {
          const msg =
            current_route === `/settings/policies/${encryptedId}/new-version`
              ? t('success.field.policyNewVersionAdded')
              : id
                ? t('success.field.policyUpdated')
                : t('success.field.policyCreated');

          setNotificationSuccess(msg);

          if (id) {
            navigate(`/settings/policies/details/${encryptedId}`);
          } else {
            navigate(`/settings/policies`);
          }
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          const newErrors: Record<string, string> = {};

          if (response.owner) {
            newErrors.owner = response.owner[0];
          } else if (response.policy_name) {
            newErrors.policy_name = response.policy_name[0];
          } else if (response.version) {
            newErrors.version = response.version[0];
          } else if (response.date_next_review) {
            newErrors.date_next_review = response.date_next_review[0];
          } else if (response.file) {
            newErrors.file = response.file[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const HandlesnapshotIsDisabled = (type: string = '') => {
    if (type === 'new-version') {
      setIsDisabled(false);
      navigate(`/settings/policies/${encryptedId}/new-version`);
    } else if (isDisabled) {
      setIsDisabled(false);
      navigate(`/settings/policies/update/${encryptedId}`);
    }
  };

  const handleComplete = async (status: number) => {
    const requestData = {
      ...formData,
      is_open: status
    };

    const edit_id = id ? id : formData.id;
    try {
      const response: any = await createPolicyData(
        requestData,
        selectedFile,
        authToken,
        selectedAccount,
        edit_id
      );

      if (response && response.id) {
        const msg = t('success.field.policyUpdated');

        setNotificationSuccess(msg);
        navigate(`/settings/policies`);
      }
    } catch (error) {
      // Handle other error cases
      console.error(error); // Log the error
      // You can handle other error cases as needed
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deletePolicy(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.policyDeleted'));
          navigate(`/settings/policies`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  let edit_btn_snapshot;
  if (formData && !formData.closed_at) {
    edit_btn_snapshot = (
      <>
        {checkUserPermission(user, SETTINGS, 7, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  let new_version_btn_snapshot;
  if (formData && !formData.closed_at) {
    new_version_btn_snapshot = (
      <>
        {checkUserPermission(user, SETTINGS, 7, UPDATE) && (
          <Button
            onClick={() => HandlesnapshotIsDisabled('new-version')}
            className="btn blue-fill-btn">
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
            New Version
          </Button>
        )}
      </>
    );
  }

  let addButton;
  if (formData && formData.is_open == 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {isDisabled && current_route === `/settings/policies/details/${encryptedId}` && (
          <>
            {checkUserPermission(user, SETTINGS, 7, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/settings/policies/add-note?policyId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}

            {checkUserPermission(user, SETTINGS, 7, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/settings/policies/add-task?policyId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={
                current_route === `/settings/policies/details/${encryptedId}` && formData.unique_id
                  ? formData.unique_id
                  : title
              }
              pageTitle={title}
              secondTitle={secondTitle}
              secondpath={secondpath}
              thirdTitle={
                current_route !== `/settings/policies/details/${encryptedId}` && formData.unique_id
                  ? formData.unique_id
                  : ''
              }
              thirdPath={
                current_route !== `/settings/policies/details/${encryptedId}` ? thirdPath : ''
              }
              button={id ? addButton : undefined}
            />

            <div className="record-outer-row settings-outer-row">
              <PoliciesLeftMenu
                user={user}
                policyId={id}
                formData_record={formData}
                encryptedPolicyId={encryptedId}
              />

              <div className="right-outer">
                <div className="right-inner">
                  <PoliciesCreateEditFormData
                    users={users}
                    formData={formData}
                    selectedFile={selectedFile}
                    isDisabled={isDisabled}
                    current_route={current_route}
                    edit_btn_snapshot={edit_btn_snapshot}
                    new_version_btn_snapshot={new_version_btn_snapshot}
                    errors={errors}
                    handleComplete={handleComplete}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    handleDateInputChange={handleDateInputChange}
                    handleFileChange={handleFileChange}
                    encryptedId={encryptedId}
                  />

                  {!isDisabled && current_route !== `/settings/policies/details/${encryptedId}` && (
                    <div className="btn-wrap">
                      <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn btn-sm"
                        type="submit"
                        onClick={handleFormSubmit}>
                        Save
                      </Button>
                    </div>
                  )}

                  {checkUserPermission(user, SETTINGS, 7, DELETE) &&
                    current_route === `/settings/policies/details/${encryptedId}` && (
                      <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                    )}
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default PoliciesCreateEdit;
