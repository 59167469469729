import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import search from '../../../../assets/images/search.svg';

interface AccountFiltersProps {
  search: string;
  setSearch: (value: string) => void;
}

const SearchIcon = () => (
  <InputAdornment position="start">
    <img src={search} alt="search" />
  </InputAdornment>
);

const AccountFilters: React.FC<AccountFiltersProps> = ({
  search,
  setSearch,
}) => {
  return (
    <div className="filter-outer">
      <div className="filter-list search-box">
        <div className="form-group">
          <TextField
            id="first-name-input"
            type="search"
            className="form-control"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: <SearchIcon />
            }}
            variant="outlined"
            aria-label="Search"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountFilters;
