import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, SelectChangeEvent } from '@mui/material';
import { useCurrentUserInfo, useAuthToken } from '../../../utils/common/user';
import { fetchTimezones } from '../../../api/dashboard/fetchTimezones';
import { TimezoneResponse } from '../settings/state';
import { useTranslation } from 'react-i18next';
import { PersonalInfoResponseError } from './state';
import { updateCurrentUserInfo } from '../../../api/dashboard/updateCurrentUserInfo';
import { PersonalInfo } from './state';
import UserAccountDetailsForm from './UserAccountDetailsForm';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import CommonLayout from '../layout/CommonLayout';

const UserAccountDetails: React.FC = () => {
  const mainTitle = '';
  const mainPath = '/user-profile';
  const title = 'User Profile';
  const pageTitle = 'Account Settings';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const currentUserInfo = useCurrentUserInfo();
  const authToken = useAuthToken() || '';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [updateLogo, setUpdateLogo] = useState<Blob | null>(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [timezone, setTimezone] = useState<string>('');

  const [timezoneOptionsWithValueLabel, setTimezoneOptionsWithValueLabel] = useState<
    TimezoneResponse[]
  >([]);

  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_image: null,
    timezone: '',
    error: ''
  });

  useEffect(() => {
    // Fetch user details and timezones when the component mounts
    fetchUserDetails();
    fetchTimezonesApi();
  }, []);

  const fetchTimezonesApi = async () => {
    try {
      const response: TimezoneResponse[] = await fetchTimezones();
      if (response) {
        setTimezoneOptionsWithValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching timezones:', error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      if (currentUserInfo) {
        const { id, first_name, last_name, email, phone, timezone, profile_image } =
          currentUserInfo;

        setPersonalInfo({
          id,
          first_name,
          last_name,
          email,
          phone,
          profile_image,
          timezone: timezone || '',
          error: ''
        });

        if (timezone) {
          setTimezone(timezone);
        }
      }
    } catch (error) {
      console.error('Error fetching user account details:', error);
    }
  };

  const handlePersonalInfoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setPersonalInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleCancelClick = () => {
    // Reset form fields and navigate back
    setErrors({});
    setPersonalInfo((prevData) => ({ ...prevData, error: '' }));
    navigate('/dashboard');
  };

  const handleClose = () => {
    setErrors({});
    setPersonalInfo((prevData) => ({ ...prevData, error: '' }));
    setSuccessMsg(null);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!personalInfo.first_name) {
      newErrors.first_name = t('error.field.firstname');
    }
    if (!personalInfo.last_name) {
      newErrors.last_name = t('error.field.lastname');
    }

    if (!personalInfo.email) {
      newErrors.email = t('error.field.email');
    }

    if (!personalInfo.phone) {
      newErrors.phone = t('error.field.phoneRequired');
    }
    // Regex for Australian phone number format (+61456789012)

    const phoneRegex = /^[0-9]{10}$/;
    if (personalInfo.phone && !phoneRegex.test(personalInfo.phone)) {
      newErrors.phone = t('error.field.phone');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = async (event: React.FormEvent) => {
    event.preventDefault();
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    // Prepare the data to send to the API
    const requestData = {
      id: personalInfo.id,
      first_name: personalInfo.first_name,
      last_name: personalInfo.last_name,
      email: personalInfo.email,
      phone: personalInfo.phone || '',
      profile_image: updateLogo,
      timezone,
      error: ''
    };

    try {
      const response: PersonalInfoResponseError = (await updateCurrentUserInfo(
        dispatch,
        requestData,
        imageChanged,
        authToken
      )) as PersonalInfoResponseError;

      if (response) {
        const newErrors: Record<string, string> = {};
        if (response.first_name) {
          newErrors.name = response.first_name[0];
        } else if (response.last_name) {
          newErrors.prefix = response.last_name[0]; // Take the first error message for 'prefix'
        } else if (response.profile_image) {
          newErrors.logo = response.profile_image[0];
        } else if (response.email) {
          newErrors.email = response.email[0];
        } else if (response.phone) {
          newErrors.phone = response.phone[0];
        } else {
          setPersonalInfo((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      } else {
        setSuccessMsg(t('success.field.accountDetailsUpdates'));
      }
    } catch (error) {
      console.error('Error updating user info:', error);
    }
  };

  const handleChangeTimezone = (event: SelectChangeEvent<string>) => {
    setTimezone(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
        setImageChanged(true);
      };
      reader.readAsDataURL(file);
      setUpdateLogo(file);
    } else {
      setPreviewURL(null);
      setUpdateLogo(null);
    }
  };

  const removeHandle = () => {
    setImageChanged(true);
    setPreviewURL(null);
    setUpdateLogo(null);
    setPersonalInfo((prevData) => ({ ...prevData, profile_image: null }));
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
          />
          {/* Success Message */}
          {successMsg && (
            <Alert variant="outlined" severity="success" onClose={handleClose}>
              {successMsg}
            </Alert>
          )}

          {/* Error Message */}
          {personalInfo.error && (
            <Alert variant="outlined" severity="error" onClose={handleClose}>
              <div>{personalInfo.error}</div>
            </Alert>
          )}

          <UserAccountDetailsForm
            personalInfo={personalInfo}
            handleChangeTimezone={handleChangeTimezone}
            handlePersonalInfoChange={handlePersonalInfoChange}
            handleCancelClick={handleCancelClick}
            handleSaveChanges={handleSaveChanges}
            handleFileChange={handleFileChange}
            removeHandle={removeHandle}
            previewURL={previewURL}
            timezoneOptionsWithValueLabel={timezoneOptionsWithValueLabel}
            timezone={timezone}
            imageChanged={imageChanged}
            updateLogo={updateLogo}
            errors={errors}
          />
        </div>
      </section>
    </CommonLayout>
  );
};

export default UserAccountDetails;
