import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

const SupportRequestLeftMenu = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {location.pathname == `/resources/support-request` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            <span className="inner-text-wrap">{t('support-request.addMenuMsg')}</span>
          </nav>
        </div>
      )}
    </>
  );
};

export default SupportRequestLeftMenu;
