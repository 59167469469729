import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProviderIndex from './ProviderIndex';
import ProviderCreateEdit from './ProviderCreateEdit';
import ProviderReviewCreateEdit from './ProviderReviewCreateEdit';
import ProviderNotesIndex from './ProviderNotesIndex';
import ProviderNotesAdd from './ProviderNotesAdd';
import ProviderTasksIndex from './ProviderTasksIndex';
import ProviderTasksAddEdit from './ProviderTasksAddEdit';

const ProviderRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/:id" element={<ProviderIndex />} />
      <Route path="/add" element={<ProviderCreateEdit />} />
      <Route path="/add/initial-engagement" element={<ProviderCreateEdit />} />
      <Route path="/edit/initial-engagement/:id" element={<ProviderCreateEdit />} />
      <Route path="/details/:id" element={<ProviderCreateEdit />} />
      <Route path="/review" element={<ProviderReviewCreateEdit />} />
      <Route path="/review/:id" element={<ProviderReviewCreateEdit />} />
      <Route path="/review-add" element={<ProviderReviewCreateEdit />} />
      <Route path="/review-edit/:id" element={<ProviderReviewCreateEdit />} />
      <Route path="/review-data" element={<ProviderReviewCreateEdit />} />
      <Route path="/notes" element={<ProviderNotesIndex />} />
      <Route path="/add-note" element={<ProviderNotesAdd />} />
      <Route path="/edit-note/:id/" element={<ProviderNotesAdd />} />
      <Route path="/tasks" element={<ProviderTasksIndex />} />
      <Route path="/add-task" element={<ProviderTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<ProviderTasksAddEdit />} />
    </Routes>
  );
};

export default ProviderRoutes;
