import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { checkUserPermission, useCurrentUserInfo, decryptData, getSelectedAccountInfo, isSubscriptionExpired } from '../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { REGISTERS, NOTE_CREATE } from '../../../constants/constants';
import CommonLayout from '../layout/CommonLayout';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import { useRegistersContext } from './RegisterContext';
import RecordLeftMenu from './RecordLeftMenu';
import AddNotes from '../notes/AddNotes';

const NotesAdd = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let recordId = queryParams.get('recordId');
  const encryptedRecordId = recordId; // Decrypt the ID
  recordId = decryptData(recordId, true);
  const mainPath = `/register/${sourceId}`;
  const thirdTitle = 'Notes';
  const thirdPath = `/register/notes?source=${encryptedSourceId}&recordId=${encryptedRecordId}`;
  const title = 'Add Note';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const [isAccessable, setIsAccessable] = useState<boolean>(false);

  let mainTitle;
  const { registersRecords } = useRegistersContext();

  if (sourceId && registersRecords) {
    const recordId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(registersRecords) && registersRecords.length > 0
        ? registersRecords.find((register) => register.id === recordId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id = localStorage.getItem('record_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/register-record/${encryptedRecordId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, REGISTERS, source_Id, NOTE_CREATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <RecordLeftMenu
                  user={user}
                  recordId={recordId ? recordId : ''}
                  sourceId={sourceId || ''}
                  registerIdEncrypted={encryptedRecordId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <AddNotes
                  moduleType="register_records"
                  sourceId={sourceId}
                  recordId={recordId}
                  localKey="recordNotesCount"
                  listUrl={`/register/notes?source=${encryptedSourceId}&recordId=${encryptedRecordId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default NotesAdd;
