export const USER_NOT_FOUND = 'User not found';

export const MODULES = 'Modules';
export const REGISTERS = 'Registers';
export const REPORTING = 'Reporting';
export const RESOURCES = 'Resources';
export const SETTINGS = 'Settings';
export const TASKS = 'Tasks';
export const ALL_TASKS = 'All Tasks';
export const MY_TASKS = 'My Tasks';
export const USER_ACCESS_PERMISSION = 'User Access Permission';
export const USERS = 'Users';
export const ACCOUNT_DETAILS = 'Account Details';
export const ACCOUNTS = 'Accounts';
export const UPDATES = 'Updates';
export const POLICIES = 'Policies';
export const SUPPORT_REQUEST = 'Technical support request';
export const COMPLIANCE_QUERY = 'Compliance Query';
export const BILLING = 'Billing';
export const PLANS = 'Plans';
export const REPORTS = 'Reports';
export const ADVISER_REVIEW_REPORT = 'Adviser Reviews Report';
export const PREVET_REPORT = 'Prevet Report';
export const INCIDENT_BREACH_REPORT = 'Incident & Breach Report';
export const COMPLAINT_REPORT = 'Complaint Report';
export const EXPORT_DATA = 'Export Data';
export const CLIENT_FILE_REVIEW = 'Client File Review';
export const COMPLAINT_IDR_REPORT = 'Complaints (IDR) Register';
export const ASIC_IDR_REPORT = 'ASIC-IDR-Report';

export const CREATE = 'Create';
export const READ = 'Read';
export const UPDATE = 'Update';
export const DELETE = 'Delete';
export const NOTE_CREATE = 'Note Create';
export const NOTE_READ = 'Note Read';
export const NOTE_UPDATE = 'Note Update';
export const NOTE_DELETE = 'Note Delete';
export const TASK_CREATE = 'Task Create';
export const TASK_READ = 'Task Read';
export const TASK_UPDATE = 'Task Update';
export const TASK_DELETE = 'Task Delete';
export const PRINT = 'Print';
export const EXPORT = 'Export';
export const FINALISE = 'Finalise';
export const PREVET_ID = 5;
export const ONBOARDING_ID = 4;
export const ADVISER_PROFILE_ID = 6;
export const S3PublicUrl = 'https://3lines-public.s3.ap-southeast-2.amazonaws.com/public/';
export const termsAndConditionUrl = 'https://www.3lines.com.au/terms';
export const privacyPolicyUrl = 'https://www.3lines.com.au/privacy';
export const defaultEventColor = '#3788d8';
