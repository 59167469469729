import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfessionalYears from './ProfessionalYears';
import ProfessionalYearCreateEdit from './ProfessionalYearCreateEdit';
import ProfessionalYearNotes from './ProfessionalYearNotes';
import ProfessionalYearNotesAdd from './ProfessionalYearNotesAdd';
import ProfessionalYearTasks from './ProfessionalYearTasks';
import ProfessionalYearTasksAddEdit from './ProfessionalYearTasksAddEdit';
import ProfessionalYearPlanTemplateAddEdit from './ProfessionalYearPlanTemplateAddEdit';
import ProfessionalYearLogbookTemplateAddEdit from './ProfessionalYearLogbookTemplateAddEdit';
import ProfessionalYearLogTrainingTemplateAddEdit from './ProfessionalYearLogTrainingTemplateAddEdit';
import ProfessionalYearQ1PlanSupervisorTemplateAddEdit from './ProfessionalYearQ1PlanSupervisorTemplateAddEdit';
import ProfessionalYearQ2PlanSupervisorTemplateAddEdit from './ProfessionalYearQ2PlanSupervisorTemplateAddEdit';
import ProfessionalYearQ3PlanSupervisorTemplateAddEdit from './ProfessionalYearQ3PlanSupervisorTemplateAddEdit';
import ProfessionalYearQ4PlanSupervisorTemplateAddEdit from './ProfessionalYearQ4PlanSupervisorTemplateAddEdit';
import ProfessionalYearExamSupervisorTemplateAddEdit from './ProfessionalYearExamSupervisorTemplateAddEdit';
import ProfessionalYearCompletionTemplateAddEdit from './ProfessionalYearCompletionTemplateAddEdit';

const ProfessionalYearRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<ProfessionalYears />} />
      <Route path="/details/:id" element={<ProfessionalYearCreateEdit />} />
      <Route path="/notes" element={<ProfessionalYearNotes />} />
      <Route path="/add-note" element={<ProfessionalYearNotesAdd />} />
      <Route path="/edit-note/:id" element={<ProfessionalYearNotesAdd />} />
      <Route path="/tasks" element={<ProfessionalYearTasks />} />
      <Route path="/add-task" element={<ProfessionalYearTasksAddEdit />} />
      <Route path="/edit-task/:id" element={<ProfessionalYearTasksAddEdit />} />

      {/* Template routes */}
      <Route path="/plan-supervisor-edit" element={<ProfessionalYearPlanTemplateAddEdit />} />
      <Route path="/plan-supervisor-view" element={<ProfessionalYearPlanTemplateAddEdit />} />
      <Route
        path="/logbook-workactivity-add"
        element={<ProfessionalYearLogbookTemplateAddEdit />}
      />
      <Route
        path="/logbook-workactivity-edit/:id"
        element={<ProfessionalYearLogbookTemplateAddEdit />}
      />
      <Route
        path="/log-training-edit/:id"
        element={<ProfessionalYearLogTrainingTemplateAddEdit />}
      />
      <Route
        path="/q1plan-supervisor-edit"
        element={<ProfessionalYearQ1PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q1plan-supervisor-view"
        element={<ProfessionalYearQ1PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q2plan-supervisor-edit"
        element={<ProfessionalYearQ2PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q2plan-supervisor-view"
        element={<ProfessionalYearQ2PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q3plan-supervisor-edit"
        element={<ProfessionalYearQ3PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q3plan-supervisor-view"
        element={<ProfessionalYearQ3PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q4plan-supervisor-edit"
        element={<ProfessionalYearQ4PlanSupervisorTemplateAddEdit />}
      />
      <Route
        path="/q4plan-supervisor-view"
        element={<ProfessionalYearQ4PlanSupervisorTemplateAddEdit />}
      />
      <Route path="/exam" element={<ProfessionalYearExamSupervisorTemplateAddEdit />} />
      <Route path="/exam-view" element={<ProfessionalYearExamSupervisorTemplateAddEdit />} />
      <Route path="/completion" element={<ProfessionalYearCompletionTemplateAddEdit />} />
      <Route path="/completion-view" element={<ProfessionalYearCompletionTemplateAddEdit />} />
    </Routes>
  );
};

export default ProfessionalYearRoutes;
