import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import OnboardingLeftMenu from './OnboardingLeftMenu';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  useAuthToken,
  useCurrentOnboardingUserInfo,
  useCurrentUserInfo,
  decryptData,
  encryptData
} from '../../../../utils/common/user';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { OnboardingTemplateState } from './state';
import { fetchOnboardingApplicationTemplate } from '../../../../api/onboarding/fetchOnboardingApplicationTemplate';
import { fetchOnboardingApplicationData } from '../../../../api/onboarding/fetchOnboardingApplicationData';
import { OnboardingTempplateReviewDataState } from '../../user/state';
import OnboardingTemplateTextMode from './OnboardingTemplateTextMode';
import { onboardingApplicationDataSubmit } from '../../../../api/onboarding/onboardingApplicationDataSubmit';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useModuleContext } from '../../settings/modules/ModulesContext';

const OnboardingApplicationReview = () => {
  const user = useCurrentUserInfo();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let onboardingId = queryParams.get('onboardingId');
  const encryptedOnboardingId = onboardingId; // Decrypt the ID
  onboardingId = decryptData(onboardingId, true);
  const onboarding_id = parseInt(onboardingId!, 10);
  let sourceId = queryParams.get('source');
  let encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = sourceId ? decryptData(sourceId, true) : null;
  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [onboardingAccountId, setOnboardingAccountId] = useState<string | undefined>('');
  const pageTitle = 'Review Application';
  const mainPath = `/onboarding/${encryptedSourceId}`;
  const type = localStorage.getItem('type');

  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;

  const { setNotificationSuccess } = useNotificationContext();
  const { moduleRecords } = useModuleContext();
  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  } else {
    // need to test on staging when live
    // const foundRegister =
    //   Array.isArray(moduleRecords) && moduleRecords.length > 0
    //     ? moduleRecords.find((module) => module.prefix === 'ONB')
    //     : '';

    // sourceId = foundRegister ? String(foundRegister.id) : null;
    // encryptedSourceId = foundRegister ? encryptData(String(foundRegister.id), true) : 0;
    sourceId = String(4);
    encryptedSourceId = encryptData(String(4), true);
  }
  let thirdTitle;
  if (localStorage.getItem('onboarding_unique_id')) {
    thirdTitle = localStorage.getItem('onboarding_unique_id');
  }
  const thirdPath = `/onboarding/details/${encryptedOnboardingId}?source=${encryptedSourceId}`;

  const onboardingUser = useCurrentOnboardingUserInfo();
  const [templateData, setTemplateData] = useState<string>('');
  const [formData, setFormData] = useState<OnboardingTempplateReviewDataState>({
    id: 0,
    onboarding_id: 0,
    form_data: '{}',
    submitted: 0,
    error: ''
  });

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: OnboardingTemplateState = (await fetchOnboardingApplicationTemplate(
          onboarding_id,
          onboardingUser?.email,
          authToken,
          type
        )) as OnboardingTemplateState;

        if (response.id) {
          const { form_data, account_id } = response;
          setOnboardingAccountId(account_id);
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
          } else {
            console.log(response);
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      if (authToken && id) {
        const onboardingApplicationId = parseInt(id, 10);
        const response: any = (await fetchOnboardingApplicationData(
          onboardingApplicationId,
          onboarding_id,
          authToken,
          onboardingUser ? true : false
        )) as any;
        if (response.id) {
          setFormData(response);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchTemplate();
    fetchData();
  }, []);

  useEffect(() => {
    if (type === t('onboarding.user') && formData.submitted == 1) {
      setNotificationSuccess('You have submitted this application.');
    }
  }, [formData]);

  let edit_btn_form = null;
  if (formData.submitted == 0 && type === t('onboarding.user')) {
    edit_btn_form = (
      <Button
        className="btn blue-fill-btn"
        component={RouterLink}
        to={`/onboarding-application?dataId=${encryptedId}&onboardingId=${encryptedOnboardingId}`}>
        <i className="left">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        Edit
      </Button>
    );
  }

  const handleSubmit = async () => {
    setFormLoading(true);

    try {
      if (authToken && id) {
        const onboardingApplicationId = parseInt(id, 10);
        const response: OnboardingTempplateReviewDataState = (await onboardingApplicationDataSubmit(
          onboardingApplicationId,
          authToken,
          encryptedOnboardingId,
          encryptedSourceId,
          onboardingAccountId
        )) as OnboardingTempplateReviewDataState;
        if (response.id) {
          setFormData(response);
          setNotificationSuccess(t('success.field.onboardingApplicationDataSubmited'));
          navigate(
            `/onboarding-application-submitted/${encryptData(String(response.id), true)}?onboardingId=${encryptedOnboardingId}`
          );
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={pageTitle}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            thirdTitle={thirdTitle ? thirdTitle : ''}
            thirdPath={thirdPath}
          />{' '}
          <div className="record-outer-row settings-outer-row">
            <OnboardingLeftMenu
              user={user}
              onboardingId={onboardingId ? onboardingId : ''}
              sourceId={sourceId ? sourceId : ''}
            />
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : templateData && formData.id ? (
                  <>
                    <div className="card-outline">
                      <OnboardingTemplateTextMode
                        surveyData={JSON.parse(templateData)}
                        formData={formData.form_data ? JSON.parse(formData.form_data) : {}}
                        edit_btn={edit_btn_form}
                        title="Application Form"
                      />
                    </div>

                    {type === t('onboarding.user') && formData.submitted == 0 && (
                      <div className="row-wrap center">
                        {formLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button className="btn primary-btn btn-sm" onClick={handleSubmit}>
                            <i className="left">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                  d="M16.6668 10.4141V5.66406C16.6668 4.26393 16.6668 3.56387 16.3943 3.02909C16.1547 2.55868 15.7722 2.17623 15.3018 1.93655C14.767 1.66406 14.067 1.66406 12.6668 1.66406H7.3335C5.93336 1.66406 5.2333 1.66406 4.69852 1.93655C4.22811 2.17623 3.84566 2.55868 3.60598 3.02909C3.3335 3.56387 3.3335 4.26393 3.3335 5.66406V14.3307C3.3335 15.7309 3.3335 16.4309 3.60598 16.9657C3.84566 17.4361 4.22811 17.8186 4.69852 18.0582C5.2333 18.3307 5.93336 18.3307 7.3335 18.3307H10.0002M11.6668 9.16406H6.66683M8.3335 12.4974H6.66683M13.3335 5.83073H6.66683M12.0835 15.8307L13.7502 17.4974L17.5002 13.7474"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                            Submit application
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="no-record">Review not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default OnboardingApplicationReview;
