import React, { useState, useEffect } from 'react';
import { useAuthToken } from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { Choices, TemplateTextModeProps, fetchDynamicChoicesData, renderFormElement } from '../../../../utils/common/surveyjs/survey-js';

const ReviewSurveyTextMode: React.FC<TemplateTextModeProps> = ({
  surveyData,
  formData,
  edit_btn
}) => {
  const [dynamicChoicesData, setDynamicChoicesData] = useState<{ [key: string]: Choices[] }>({});
  const authToken = useAuthToken();

  useEffect(() => {
    // Fetch dynamic choices data when the component mounts
    fetchDynamicChoicesData(authToken, surveyData, formData, setDynamicChoicesData);
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <div>
          <h3> {t('overview')}</h3>
        </div>
        <div className="inner-btn-wrap">{edit_btn}</div>
      </div>
      <div className="row-wrap">
        {surveyData?.title && (
          <div className="inner-wrap-title ">
            <h5 className="title-padding">{surveyData?.title}</h5>
          </div>
        )}
        <div className="form-group w-full">
          {surveyData &&
            surveyData?.pages?.map((page) => (
              <div key={page.name} className="row-wrap">
                {page.elements
                  .filter((element) => formData[element.name] !== undefined)
                  .map((element) => (
                    <div className={`form-group w-full`} key={element.name}>
                      <label className="form-label">
                        {element.title ? element.title : element.name}
                      </label>
                      {renderFormElement(element, formData[element.name], authToken, dynamicChoicesData)}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ReviewSurveyTextMode;
