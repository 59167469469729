import React, { useEffect, useState } from 'react';
import { Button, TextField, CircularProgress } from '@mui/material';
import {
  useAuthToken,
  getSelectedAccountInfo,
  decryptData,
  checkUserPermission,
  useCurrentUserInfo
} from '../../../utils/common/user';
import { useNavigate, useParams } from 'react-router-dom';
import { NotesFormState } from '../registers/state';
import { useTranslation } from 'react-i18next';
import { NotesCreateProps, NotesResponseError } from './state';
import { useNotificationContext } from '../layout/NotificationContext';
import { createNotes } from '../../../api/notes-tasks/createNotes';
import { fileChange } from '../common/fileChange';
import CloseIcon from '@mui/icons-material/Close';
import fileUploadIc from '../../../assets/images/file-upload.svg';
import { removeFile } from '../common/removeFile';
import { getModuleCategory, clearLocalStorageExcept } from '../../../utils/commonUtils';
import { NOTE_DELETE } from '../../../constants/constants';
import { fetchNotes } from '../../../api/notes-tasks/fetchNotes';
import { NotesState } from '../user/state';
import DeleteButton from '../common/DeleteButton';
import Popup from '../common/popup/Popup';
import { deleteNotes } from '../../../api/adviser/deleteNotes';

const AddNotes: React.FC<NotesCreateProps> = ({
  moduleType,
  sourceId,
  recordId,
  localKey,
  listUrl
}) => {
  const user = useCurrentUserInfo();
  const record_Id = parseInt(recordId!, 10);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { setNotificationSuccess } = useNotificationContext();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { t } = useTranslation();
  const source_id = parseInt(sourceId!, 10);
  const [files, setFiles] = useState<File[]>([]); // Store the selected files as an array
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [pageType, setPageType] = useState<string>('');
  const [deletePermission, setDeletePermission] = useState<boolean>(false);

  const review_id = parseInt(recordId!, 10);
  const noteId = parseInt(id!, 10);

  const [formData, setFormData] = useState<NotesFormState>({
    id: 0,
    subject: '',
    note: '',
    attachments: [],
    error: ''
  });

  useEffect(() => {
    setPageType(getModuleCategory(moduleType));
  }, [moduleType]);

  useEffect(() => {
    const sourceID = pageType === 'Settings' ? 7 : source_id;
    setDeletePermission(checkUserPermission(user, pageType, sourceID, NOTE_DELETE));
  }, [user, moduleType, sourceId]);

  const fetchData = async () => {
    try {
      if (authToken && recordId && noteId) {
        const response: NotesState = (await fetchNotes(
          review_id,
          source_id,
          moduleType,
          authToken,
          noteId
        )) as NotesState;

        if (response) {
          const { id, subject, note, attachments } = response;
          const newFormData = {
            id,
            subject,
            note,
            attachments,
            error: ''
          };

          setFormData(newFormData);
          setSelectedFiles(attachments as unknown as FileList);
          setFiles([]);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, authToken, t]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fileChange(event, setSelectedFiles, setFiles);
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.subject) {
      newErrors.subject = t('error.field.subjectRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const convertAttachmentsToFileObjects = (attachments: any) => {
    return attachments.map((attachment: any) => {
      const pathData = JSON.parse(attachment.path);
      const blob = new Blob([pathData.url], { type: attachment.file_type }); // Using url as content for Blob
      return new File([blob], pathData.original_name, { type: attachment.file_type });
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) {
      setLoading(false);
      return;
    }

    let attachments: File[] = [];
    if (files.length !== 0) {
      attachments = files;
    } else if (!selectedFiles || selectedFiles.length === 0) {
      attachments = [];
    } else {
      attachments = convertAttachmentsToFileObjects(formData.attachments);
    }

    const requestData = {
      subject: formData.subject,
      note: formData.note,
      attachments: attachments
    };

    try {
      const response: NotesResponseError = (await createNotes(
        requestData,
        record_Id,
        source_id,
        moduleType,
        authToken,
        selectedAccount,
        noteId
      )) as NotesResponseError;

      if (!response?.id) {
        const newErrors: Record<string, string> = {};
        if (response.subject) {
          newErrors.subject = response.subject[0];
        } else if (response.attachments) {
          newErrors.attachments = response.attachments[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      } else {
        const msg = noteId !== 0 ? t('success.field.noteUpdated') : t('success.field.noteAdded');
        setNotificationSuccess(msg);
        localStorage.removeItem(localKey);
        navigate(listUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    } finally {
      setLoading(false);
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };
  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteNotes(parseInt(id!, 10), authToken, pageType)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.noteDeleted'));
          clearLocalStorageExcept();
          navigate(listUrl);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancelClick = () => {
    navigate(listUrl);
  };

  const removeSelectedFile = (index: number) => {
    removeFile(index, selectedFiles, setSelectedFiles, setFiles);
  };

  // const formattedContent = (formData.note || '').replace(/<br\/>/g, '\n');

  return (
    <>
      <div className="right-outer">
        <div className="right-inner">
          <div className="form-wrap">
            <form onSubmit={handleSubmit}>
              <div className="card-outline">
                <div className="row-wrap">
                  <div className="form-group w-full">
                    <label className="form-label" htmlFor="subject">
                      Subject
                    </label>
                    {id ? (
                      <p className="form-control">{formData.subject}</p>
                    ) : (
                      <TextField
                        error={!!errors.subject}
                        fullWidth
                        name="subject"
                        className="form-control"
                        placeholder="Enter subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        helperText={errors.subject || ''}
                        inputProps={{
                          maxLength: 100 // Set the maximum character length
                        }}
                      />
                    )}
                  </div>
                  <div className="form-group w-full">
                    <label className="form-label" htmlFor="note">
                      Note
                    </label>
                    {id ? (
                      <TextField
                        error={!!errors.note}
                        fullWidth
                        name="note"
                        multiline
                        rows={8} // Double the height by increasing rows
                        value={formData.note}
                        className="form-control textarea"
                        placeholder="Enter detailed description"
                        onChange={handleInputChange}
                        helperText={errors.note || ''}
                      />
                    ) : (
                      <TextField
                        error={!!errors.note}
                        fullWidth
                        name="note"
                        multiline
                        rows={8} // Double the height by increasing rows
                        value={formData.note}
                        className="form-control textarea"
                        placeholder="Enter detailed description"
                        onChange={handleInputChange}
                        helperText={errors.note || ''}
                      />
                    )}
                  </div>
                  <div className="form-group w-full file-upload">
                    <label className="form-label large" htmlFor="imgaeUpdate">
                      Attachments
                    </label>
                    <div className="relative">
                      <TextField
                        error={!!errors.attachments}
                        id="imgaeUpdate"
                        variant="outlined"
                        name="imgaeUpdate"
                        className="form-control"
                        helperText={errors.attachments || ''}
                        InputProps={{
                          startAdornment: (
                            <input
                              type="file"
                              multiple
                              className="file-upload-input"
                              onChange={handleFileChange}
                              title="file"
                            />
                          )
                        }}
                      />
                      {selectedFiles && selectedFiles.length > 0 ? (
                        <div className="preview-uploaded mt-2">
                          <p className="file-name">
                            <span className="blue-text">Selected files:</span>
                          </p>
                          <ul>
                            {Array.from(selectedFiles).map((file, index) => (
                              <li key={index}>
                                {file.name}
                                <span onClick={() => removeSelectedFile(index)}>
                                  <CloseIcon />
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p className="placeholder-text">
                          <img src={fileUploadIc} alt="File upload" />
                          <span>
                            <span className="blue-text">Click to upload</span> or drag and drop
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`btn-wrap ${noteId !== 0 && deletePermission ? 'space-between' : ''}`}>
                {noteId !== 0 && deletePermission && (
                  <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                )}
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div>
                    <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                      Cancel
                    </Button>
                    <Button type="submit" className="btn primary-btn btn-sm">
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Popup
        type="confirmation"
        openPopup={openConfirmationPopup}
        text={`Are you sure you want to delete this record?`}
        togglePopup={togglePopup}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default AddNotes;
