import { UserAccessLevelPermissionState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchPermissions = async (
  formData: UserAccessLevelPermissionState,
  authToken?: string
): Promise<UserAccessLevelPermissionState | string | undefined> => {
  try {
    const { lu_user_access_level_id, page, sub_page } = formData;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/get-user-access-level-permission?userAccessLeveId=${dataEncrypt(lu_user_access_level_id?.toString())}&page=${dataEncrypt(page?.toString())}&subPage=${dataEncrypt(sub_page?.toString())}`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching user access level';
  }
};
