import React from 'react';
import { Button, Dialog } from '@mui/material';

export interface PopupProps {
  type: string,
  openPopup: boolean,
  text: string,
  togglePopup: (type?: string) => void,
  handleConfirmation?: () => void
}

const Popup = ({type, openPopup, text, togglePopup, handleConfirmation}: PopupProps) => {
  return (
    <Dialog
      open={openPopup}
      onClose={() => togglePopup(type)}
      className='custom-dialog-sm'
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="model-wrapper">
        <div className="modal-description">
          <div className="row-wrap center">
            <i className="left">
              {type == 'alert' && (
                <svg width="70" height="70" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#fda29b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )}

              {type == 'confirmation' && (
                <svg width="70" height="70" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                      stroke="#12b76a"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </i>

            <p>{ text }</p>
          </div>
        </div>
        <div className="btn-wrap">
          {type != 'alert' && (
            <Button className="btn border-btn btn-sm" onClick={() => togglePopup(type)}>
              Cancel
            </Button>
          )}

          {type == 'alert' && (
            <Button className="btn primary-btn btn-sm" onClick={() => togglePopup(type)}>
              Okay
            </Button>
          )}

          {type == 'confirmation' && (
            <Button className="btn primary-btn btn-sm" onClick={handleConfirmation}>
              Yes
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default Popup;
