import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { OnboardingTemplateState } from '../../views/components/modules/onboarding/state';
import { AccountState } from '../../views/components/user/state';

export const fetchOnboardingChecklistTemplate = async (
  onboardingId: number,
  sourceId: number,
  accountInfo: AccountState,
  authToken: string,
): Promise<OnboardingTemplateState | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-checklist-template/${dataEncrypt(onboardingId?.toString())}?accountId=${accountInfo.id}&sourceId=${sourceId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching template';
  }
};

