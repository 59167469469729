import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import { AccountState } from '../../user/state';
import { fetchAccountDetail } from '../../../../api/onboarding/fetchAccountDetail';
import { useAuthToken, useCurrentOnboardingUserInfo } from '../../../../utils/common/user';
import LazyImage from '../../common/LazyLoad';
import { S3PublicUrl } from '../../../../constants/constants';

const OnboardingApplicationSubmitted = () => {
  const authToken = useAuthToken();
  const pageTitle = 'Application Form Submitted';

  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [selectedAccountName, setSelectedAccountName] = useState<string>('');
  const onboardingUser = useCurrentOnboardingUserInfo();

  const fetchAccountDetails = async () => {
    const accountId = onboardingUser ? onboardingUser.account_id : 0;
    const account_id = accountId || 0;
    if (accountId) {
      try {
        const response: AccountState = await fetchAccountDetail(account_id, authToken, true);
        if (response) {
          setSelectedAccountName(response.name);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container">
          <div className="record-outer-row settings-outer-row">
            <div className="left-col">
              <div className="left-inner">
                <div className="title-wrap">
                  <h4 className="heading-onboarding-success">
                    Thank you for submitting your application.
                  </h4>
                  <p className="mt-2">{`It has been sent to ${onboardingUser?.owner?.first_name} ${onboardingUser?.owner?.last_name} from ${selectedAccountName}.`}</p>
                  <p className="mt-2">Contact the licensee if you have further queries.</p>
                </div>
              </div>
            </div>
            <div className="right-image-col application-submit-image">
              <div className="right-inner">
                <div className="img-block">
                  <LazyImage
                    src={`${S3PublicUrl}onboarding-success.png`}
                    alt=""
                    rootMargin="0px 0px 200px 0px"
                    className="onboarding-success-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default OnboardingApplicationSubmitted;
