import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import LeftNav from '../LeftNav';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { UserAccessLevelPermissionState, UserAccessLevelState } from '../../user/state';
import { fetchUserAccessLevels } from '../../../../api/settings/fetchUserAccessLevels';
import { fetchSubPages } from '../../../../api/settings/fetchSubPages';
import {
  ACCOUNT_DETAILS,
  CREATE,
  DELETE,
  EXPORT,
  FINALISE,
  MODULES,
  MY_TASKS,
  NOTE_CREATE,
  NOTE_DELETE,
  NOTE_READ,
  NOTE_UPDATE,
  POLICIES,
  PRINT,
  READ,
  REGISTERS,
  // REPORTING,
  REPORTS,
  ALL_TASKS,
  RESOURCES,
  SETTINGS,
  TASKS,
  TASK_CREATE,
  TASK_DELETE,
  TASK_READ,
  TASK_UPDATE,
  UPDATE,
  UPDATES,
  USERS,
  USER_ACCESS_PERMISSION,
  COMPLIANCE_QUERY,
  SUPPORT_REQUEST,
  BILLING,
  PLANS,
  ACCOUNTS,
  ADVISER_REVIEW_REPORT,
  PREVET_REPORT,
  INCIDENT_BREACH_REPORT,
  COMPLAINT_REPORT,
  EXPORT_DATA,
  CLIENT_FILE_REVIEW
} from '../../../../constants/constants';
import { fetchPermissions } from '../../../../api/settings/fetchPermissions';
import { assignRemovePermissions } from '../../../../api/settings/assignRemovePermissions';
import { useNotificationContext } from '../../layout/NotificationContext';
import { Pages, SubPages } from '../state';
import { t } from 'i18next';

const UserAccessPermission = () => {
  const title = 'User Access permission';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();

  const [userAccessLevels, setUserAccessLevels] = useState<UserAccessLevelState[]>([]);
  const [subPages, setSubPages] = useState<SubPages[]>([]);
  const permissions = [
    CREATE,
    READ,
    UPDATE,
    DELETE,
    NOTE_CREATE,
    NOTE_READ,
    NOTE_UPDATE,
    NOTE_DELETE,
    TASK_CREATE,
    TASK_READ,
    TASK_UPDATE,
    TASK_DELETE,
    PRINT,
    EXPORT,
    FINALISE
  ];
  const [error, setError] = useState<string>('');
  const [formData, setFormData] = useState<UserAccessLevelPermissionState>({
    lu_user_access_level_id: 0,
    page: '',
    sub_page: 0,
    permissions: []
  });

  const pages: Pages[] = [
    {
      id: 1,
      name: 'Modules'
    },
    {
      id: 2,
      name: 'Registers'
    },
    {
      id: 3,
      name: 'Tasks'
    },
    {
      id: 4,
      name: 'Resources'
    },
    {
      id: 5,
      name: 'Settings'
    },
    {
      id: 6,
      name: 'Reports'
    }
  ];

  const getUserAccessLevels = async () => {
    try {
      const response: any = await fetchUserAccessLevels(authToken);
      if (Array.isArray(response)) {
        setUserAccessLevels(response);
      } else {
        console.log('Error', response);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 1)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
    getUserAccessLevels();
  }, []);

  const getSubPages = async () => {
    if (formData.page === MODULES || formData.page === REGISTERS) {
      const response: any = await fetchSubPages(formData.page, authToken, selectedAccount.id);
      if (Array.isArray(response)) {
        setSubPages(response);
      } else {
        console.log('Error', response);
      }
    } else if (formData.page === TASKS) {
      const sub_pages: SubPages[] = [
        {
          id: 1,
          name: ALL_TASKS
        },
        {
          id: 2,
          name: MY_TASKS
        }
      ];
      setSubPages(sub_pages);
    } else if (formData.page === RESOURCES) {
      const sub_pages: SubPages[] = [
        {
          id: 1,
          name: UPDATES
        },
        {
          id: 2,
          name: POLICIES
        },
        {
          id: 3,
          name: SUPPORT_REQUEST
        },
        {
          id: 4,
          name: COMPLIANCE_QUERY
        }
      ];
      setSubPages(sub_pages);
    } else if (formData.page === SETTINGS) {
      const sub_pages: SubPages[] = [
        {
          id: 1,
          name: USER_ACCESS_PERMISSION
        },
        {
          id: 2,
          name: USERS
        },
        {
          id: 3,
          name: ACCOUNT_DETAILS
        },
        {
          id: 4,
          name: MODULES
        },
        {
          id: 5,
          name: REGISTERS
        },
        {
          id: 6,
          name: UPDATES
        },
        {
          id: 7,
          name: POLICIES
        },
        {
          id: 8,
          name: BILLING
        },
        {
          id: 9,
          name: ACCOUNTS
        },
        {
          id: 10,
          name: PLANS
        }
      ];
      setSubPages(sub_pages);
    } else if (formData.page === REPORTS) {
      const sub_pages: SubPages[] = [
        {
          id: 1,
          name: ADVISER_REVIEW_REPORT
        },
        {
          id: 2,
          name: PREVET_REPORT
        },
        {
          id: 3,
          name: INCIDENT_BREACH_REPORT
        },
        {
          id: 4,
          name: COMPLAINT_REPORT
        },
        {
          id: 5,
          name: EXPORT_DATA
        },
        {
          id: 6,
          name: CLIENT_FILE_REVIEW
        }
      ];
      setSubPages(sub_pages);
    }
  };

  useEffect(() => {
    getSubPages();
  }, [formData.page]);

  const getPermissions = async () => {
    try {
      const response: any = await fetchPermissions(formData, authToken);
      if (Object.prototype.hasOwnProperty.call(response, 'id')) {
        setFormData(response);
      } else {
        console.log('Error', response);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getPermissions();
  }, [formData.sub_page]);

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;

    if (
      name === 'lu_user_access_level_id' &&
      formData.lu_user_access_level_id != parseInt(value, 10)
    ) {
      setFormData({
        lu_user_access_level_id: parseInt(value, 10),
        page: '',
        sub_page: 0,
        permissions: []
      });
    } else if (name === 'page') {
      setFormData((prevData) => ({
        ...prevData,
        page: value,
        sub_page: 0,
        permissions: []
      }));
    } else if (name === 'sub_page') {
      setFormData((prevData) => ({
        ...prevData,
        sub_page: parseInt(value, 10),
        permissions: []
      }));
    }
  };

  const handleCheckBoxChange = (permissionName: string) => {
    const permission = formData.permissions;
    if (permission.includes(permissionName)) {
      formData.permissions = permission.filter((perName) => perName !== permissionName);
      handlePermissions('remove');
    } else {
      formData.permissions = [...permission, permissionName];
      handlePermissions('assign');
    }
  };

  const handlePermissions = async (type: string) => {
    try {
      const response: any = await assignRemovePermissions(formData, authToken);
      if (Object.prototype.hasOwnProperty.call(response, 'id')) {
        let msg;
        if (type === 'assign') {
          msg = 'Permission assigned successfuly';
        } else {
          msg = 'Permission removed successfuly';
        }
        setFormData(response);
        setNotificationSuccess(msg);
      } else if (response == t('permission.doesNotHaveAccessPermission')) {
        setError(t('permission.doesNotHaveAccessPermission'));
        const permissions = formData.permissions.slice(0, -1);
        setFormData({ ...formData, permissions });
      } else {
        console.log('Error', response);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              errorMsg={error}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <form>
                    <div className="card-outline">
                      <div className="card-title-wrap secondary-title-wrap">
                        <h3>User Access permission</h3>
                      </div>
                      <div className="row-wrap">
                        <div className="form-group">
                          <label className="form-label">User Access Level</label>
                          <FormControl variant="outlined">
                            <Select
                              labelId="lu_user_access_level_id-label"
                              id="lu_user_access_level_id"
                              name="lu_user_access_level_id"
                              value={formData.lu_user_access_level_id.toString()}
                              onChange={handleSelectChange}
                              IconComponent={KeyboardArrowDownRoundedIcon}
                              className="custom-select"
                              MenuProps={{ className: 'custom-dropdown-menu' }}
                              renderValue={(selected) => {
                                if (selected === '0') {
                                  return (
                                    <p className="dropdown-placeholder">Select User Access Level</p>
                                  ); // Render "Select User Access Level" as placeholder
                                }
                                const selectedOption =
                                  userAccessLevels &&
                                  userAccessLevels.find(
                                    (option) => option.id === parseInt(selected, 10)
                                  );
                                return selectedOption ? `${selectedOption.name}` : '';
                              }}>
                              {userAccessLevels &&
                                userAccessLevels.map((userAccessLevel) => (
                                  <MenuItem key={userAccessLevel.id} value={userAccessLevel.id}>
                                    {userAccessLevel.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Page</label>
                          <FormControl variant="outlined">
                            <Select
                              labelId="page-label"
                              id="page"
                              name="page"
                              value={formData.page}
                              onChange={handleSelectChange}
                              displayEmpty
                              IconComponent={KeyboardArrowDownRoundedIcon}
                              className="custom-select"
                              MenuProps={{ className: 'custom-dropdown-menu' }}
                              renderValue={(selected) => {
                                if (selected === '') {
                                  return <p className="dropdown-placeholder">Select Page</p>; // Render "Select Page" as placeholder
                                }
                                const selectedOption =
                                  pages && pages.find((option) => option.name === selected);
                                return selectedOption ? `${selectedOption.name}` : '';
                              }}>
                              {formData.lu_user_access_level_id &&
                                pages &&
                                pages.map((page) => (
                                  <MenuItem key={page.name} value={page.name}>
                                    {page.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Sub Page</label>
                          <FormControl variant="outlined">
                            <Select
                              labelId="sub_page-label"
                              id="sub_page"
                              name="sub_page"
                              value={formData.sub_page.toString()}
                              onChange={handleSelectChange}
                              IconComponent={KeyboardArrowDownRoundedIcon}
                              className="custom-select"
                              MenuProps={{ className: 'custom-dropdown-menu' }}
                              renderValue={(selected) => {
                                if (selected === '0') {
                                  return <p className="dropdown-placeholder">Select Subpage</p>; // Render "Select Subpage" as placeholder
                                }
                                const selectedOption =
                                  subPages &&
                                  subPages.find((option) => option.id === parseInt(selected, 10));
                                return selectedOption ? `${selectedOption.name}` : '';
                              }}>
                              {subPages &&
                                subPages.map((subPage: SubPages) => {
                                  if (
                                    formData.page == '2' &&
                                    subPage.account_register_access &&
                                    subPage.account_register_access[0]?.is_remove_permission != true
                                  ) {
                                    return (
                                      <MenuItem key={subPage.name} value={subPage.id}>
                                        {subPage.name}
                                      </MenuItem>
                                    );
                                  } else if (formData.page != '2') {
                                    return (
                                      <MenuItem key={subPage.name} value={subPage.id}>
                                        {subPage.name}
                                      </MenuItem>
                                    );
                                  }
                                })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="form-group"></div>
                        {formData.sub_page !== 0 &&
                          permissions.map((premission) => (
                            <div key={premission} className="form-group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.permissions.includes(premission)}
                                    onChange={() => handleCheckBoxChange(premission)}
                                  />
                                }
                                label={premission}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default UserAccessPermission;
