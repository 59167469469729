import { ApiResponse, ApiResponseType } from '../apiResponse';
import { AccountState, DropdownResponse } from '../../views/components/user/state';
import { dataEncrypt } from '../../utils/common/user';

export const fetchActiveUsers = async (authToken?: string, accountInfo?: AccountState): Promise<DropdownResponse[]> => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users-all-active?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    const responseData: ApiResponseType = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while Users listing';
  }
};
