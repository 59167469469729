import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrevetIndex from './PrevetIndex';
import PrevetCreateEdit from './PrevetCreateEdit';
import FileReviewAdd from './FileReviewAdd';
import FileReviewTemplateEdit from './FileReviewTemplateEdit';
import FileReviewCompleteView from './FileReviewCompleteView';
import PrevetNotesIndex from './PrevetNotesIndex';
import PrevetNotesAdd from './PrevetNotesAdd';
import PrevetTasksIndex from './PrevetTasksIndex';
import PrevetTasksAddEdit from './PrevetTasksAddEdit';

const PrevetRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PrevetIndex />} />
      <Route path="/:id" element={<PrevetCreateEdit />} />
      <Route path="/notes" element={<PrevetNotesIndex />} />
      <Route path="/add-note" element={<PrevetNotesAdd />} />
      <Route path="/edit-note/:id/" element={<PrevetNotesAdd />} />
      <Route path="/tasks" element={<PrevetTasksIndex />} />
      <Route path="/add-task" element={<PrevetTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<PrevetTasksAddEdit />} />
      <Route path="/add-file-review" element={<FileReviewAdd />} />
      <Route path="/edit-file-review/:id/" element={<FileReviewTemplateEdit />} />
      <Route path="/view-file-review/:id/" element={<FileReviewCompleteView />} />
    </Routes>
  );
};

export default PrevetRoutes;
