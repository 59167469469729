import { ApiResponse, ApiResponseType } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ModulesState } from '../../views/components/user/state';

export const fetchModuleHeaderListing = async (
  authToken?: string,
  accountInfo?: AccountState
): Promise<ModulesState[]> => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/modules-list?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    const responseData: ApiResponseType = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching modules';
  }
};
