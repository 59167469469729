import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck, encryptData, dataEncrypt } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { AttestationState } from '../../views/components/modules/attestations/state';

export const createEditAssestationData = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  sourceId?: number,
  recordId?: string | number,
): Promise<AttestationState | string | undefined | null> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { relevant_period, owner_id, instructions, user_ids, is_open, attestation_template_id, due_date } =
    formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('relevant_period', relevant_period);
    formData.append('due_date', due_date);
    formData.append('owner_id', owner_id);
    formData.append('instructions', instructions);
    formData.append('is_open', is_open);
    formData.append('attestation_template_id', attestation_template_id);
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('source_id', sourceId?.toString() || '');
    // formData.append('form_data', JSON.stringify(form_data));

    if (user_ids) {
      user_ids.map((id: number) => {
        formData.append('user_ids[]', id.toString())
      })
    }

    if (recordId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/attestations/${dataEncrypt(recordId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/attestations`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const sendEmail = async (
  recordIds: Array<number>,
  sourceId?: number,
  authToken?: string,
  accountInfo?: AccountState,
): Promise<AttestationState | string | undefined | null> => {
  let routeUrl;
  try {
    const selectedAccountId = accountInfo?.id;
    const encryptedAccountId = encryptData(String(selectedAccountId), true);
    const encryptedSourceId = encryptData(String(sourceId), true);

    // Array to store objects with recordId and redirectUrl
    const records: any = [];

    if (recordIds && Array.isArray(recordIds)) {
      recordIds.forEach((id: number) => {
        // Create a unique redirect URL for each recordId
        const redirectUrl = `attestation-form?account_id=${encryptedAccountId}&redirect=true&source=${encryptedSourceId}&attestationId=${encryptData(String(id), true)}`;

        // Add an object containing the recordId and its redirectUrl
        records.push({
          recordId: id,
          redirectUrl: redirectUrl,
        });
      });
    }
    const formData = new FormData(); // Create a FormData object
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('recordData', JSON.stringify(records));

    routeUrl = `${process.env.REACT_APP_API_URL}/send-attestation-email`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

