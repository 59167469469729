import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  decryptData,
  encryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import CommonLayout from '../../layout/CommonLayout';
import { useNotificationContext } from '../../layout/NotificationContext';
import { QuickVetTemplateFormState } from '../state';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import { Button } from '@mui/material';
import QuickVetTemplateCreateEditFormData from './QuickVetTemplateCreateEditFormData';
import { createQuickVetTemplate } from '../../../../api/quickvets/createQuickVetTemplate';
import { fetchQuickVetTemplates } from '../../../../api/quickvets/fetchQuickVetTemplates';

const QuickVetTemplateCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;

  const { setNotificationSuccess } = useNotificationContext();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const { search } = useLocation(); // Get encrypted ID from URL
  const queryParams = new URLSearchParams(search);
  const sourceId = queryParams.get('sourceId');
  const source_id = sourceId ? decryptData(sourceId, true) : 0;
  let copyId = queryParams.get('copyId');
  copyId = copyId ? decryptData(copyId, true) : 0;

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'QuickVet';
  const secondTitle = 'Modules';
  const secondpath = '/settings/modules';
  const pageTitle = id ? 'Edit Template' : 'Create Template';

  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const authToken = useAuthToken() || '';
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<QuickVetTemplateFormState>({
    template_name: '',
    description: '',
    checks: []
  });
  const [checks, setChecks] = useState<{ id: string; displayText: string; aiPrompt: string }[]>([
    { id: `${Date.now()}-1`, displayText: '', aiPrompt: '' } // Start from 1
  ]);

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  useEffect(() => {
    if ((id || copyId) && authToken) {
      fetchData();
    }
  }, [id, copyId]);

  const fetchData = async () => {
    try {
      if ((id || copyId) && authToken) {
        const templateId = copyId ? parseInt(copyId, 10) : parseInt(id!, 10);

        const response: QuickVetTemplateFormState = (await fetchQuickVetTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          templateId
        )) as QuickVetTemplateFormState;
        
        if (response) {
          const formattedChecks =
            (response.checks as any[]).map((check) => ({
              id: check.id.toString(),
              displayText: check.display_text,
              aiPrompt: check.ai_prompt
            })) || [];

          setFormData({
            template_name: response.name || '',
            description: response.description || ''
          });

          // Update the checks state separately
          setChecks(formattedChecks);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.template_name) {
      newErrors.template_name = t('error.field.templateNameRequired');
    }
    if (!formData.description) {
      newErrors.description = t('error.field.descriptionRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      const formDataWithChecks = {
        ...formData,
        checks // Add checks to the formData
      };
      try {
        const response: any = await createQuickVetTemplate(
          formDataWithChecks,
          authToken,
          selectedAccount,
          Number(id),
          Number(copyId)
        );
        if (response && response.id) {
          const msg = t('success.field.quickVetTemplateAdded');

          navigate(`/settings/modules/quickvets/${encryptData(String(source_id), true)}`);

          setNotificationSuccess(msg);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          const newErrors: Record<string, string> = {};
          console.log(response);
          if (response.template_name) {
            newErrors.template_name = response.template_name[0];
          } else if (response.description) {
            newErrors.description = response.description[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const handleCancelClick = () => {
    navigate(`/settings/modules/quickvets/${encryptData(String(source_id), true)}`);
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />
            <div className="record-outer-row settings-outer-row">
              <div className="settings-sidebar-wrap ">
                <nav className="settings-sidebar">
                  <div className="sidebar-inner">Enter the details for this template</div>
                </nav>
              </div>
              <div className="right-outer">
                <div className="right-inner">
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Template</h3>
                    </div>
                    <QuickVetTemplateCreateEditFormData
                      formData={formData}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      checks={checks}
                      setChecks={setChecks}
                    />

                    <div className="btn-wrap">
                      <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                      <Button className="btn primary-btn btn-sm" onClick={handleFormSubmit}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default QuickVetTemplateCreate;
