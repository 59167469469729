import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import axios from 'axios';

export const requestCall = async (
  authToken: string,
  accountInfo: AccountState,
  preferredPhone: string,
): Promise<string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const formData = new FormData(); // Create a FormData object
  formData.append('preferred_phone', preferredPhone);
  const routeUrl = `${process.env.REACT_APP_API_URL}/request-call?account_id=${selectedAccountId}`;

  try {
    const response = await axios.post<ApiResponseType>(routeUrl, 
      formData,
        {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.data.status);
      throw new ApiResponse(response.data);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while sending request';
  }
};

