import React from 'react';
import { FormControl, MenuItem, Typography, TextField } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { QuickVetFormsProps } from './state';
import Select from '@mui/material/Select';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { format, parseISO } from 'date-fns';
import { isFormatted } from '../../../../utils/commonUtils';

const QuickVetCreateEditFormData: React.FC<QuickVetFormsProps> = ({
  formData,
  errors,
  date_received,
  adviser,
  template,
  adviserArray,
  templateArray,
  closed_at,
  isDisabled,
  current_route,
  quickVetAddMode,
  edit_btn_snapshot,
  handleChangeAdviser,
  handleChangeTemplate,
  handleInputChange,
  handleFileChange,
  encryptedId,
  selectedFile,
  fetchDocument
}) => {
  const filteredTemplates =
    (templateArray as any[])?.filter(
      (template) => Array.isArray(template.account_template) && template.account_template.length > 0
    ) || [];

  return (
    <form action="" className="record-create-form">
      <div className="card-outline">
        <div className="card-title-wrap secondary-title-wrap">
          <h3>Snapshot</h3>
          <div className="inner-btn-wrap">
            {isDisabled && current_route && current_route === `/quickvet/${encryptedId}`
              ? edit_btn_snapshot
              : ''}
          </div>
        </div>
        {isDisabled && current_route && current_route === `/quickvet/${encryptedId}` && (
          <div className="row-wrap">
            <div className="form-group">
              <label className="form-label">Date created</label>
              <p className="form-control">
                {date_received
                  ? isFormatted(date_received)
                    ? date_received
                    : format(parseISO(date_received), 'd/MMM/yyyy')
                  : ''}
              </p>
            </div>
            <div className="form-group"></div>
            <div className="form-group">
              <label className="form-label">Adviser</label>
              <p className="form-control">
                {formData.adviser?.first_name + ' ' + formData.adviser?.last_name}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Client name(s)</label>
              <p className="form-control">{formData.subject}</p>
            </div>
            {/* <div className="form-group">
              <label className="form-label">Template</label>
              <p className="form-control">{formData.template?.name}</p>
            </div> */}
            <div className="form-group">
              <label className="form-label">Provided document</label>
              <p className="form-control file">
                {fetchDocument
                  ? (() => {
                      try {
                        return (
                          <a href={fetchDocument.path} target="_blank" rel="noopener noreferrer">
                            {fetchDocument.original_name}
                          </a>
                        );
                      } catch (error) {
                        console.error('Error parsing document JSON:', error);
                        return '';
                      }
                    })()
                  : ''}
              </p>
            </div>

            <div
              className={`form-group ${
                formData.is_open === 0 ? 'w-full-only-reopen' : 'w-full-reopen'
              }`}>
              <label className="form-label">Status</label>
              <p className="form-control">{formData.is_open === 1 ? 'Open' : 'Archived'}</p>
            </div>
            {formData.is_open === 0 && closed_at && (
              <div className="form-group">
                <label className="form-label">Date Closed</label>
                {closed_at
                  ? isFormatted(closed_at)
                    ? closed_at
                    : format(parseISO(closed_at), 'd/MMM/yyyy')
                  : ''}
              </div>
            )}
          </div>
        )}
        {!isDisabled &&
          current_route &&
          (current_route === `/quickvet-snapshot-edit/${encryptedId}` ||
            current_route === `/quickvet-add`) &&
          !quickVetAddMode && (
            <div className="row-wrap ">
              <div className="form-group w-full textarea">
                <label className="form-label">Client name(s)</label>
                <TextField
                  error={!!errors.subject}
                  id="Subject"
                  variant="outlined"
                  name="subject"
                  value={formData.subject ? formData.subject : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter client name(s)"
                  type="text"
                  helperText={errors.subject}
                  inputProps={{
                    maxLength: 120 // Set the maximum character length
                  }}
                />
              </div>
              {Array.isArray(adviserArray) && adviserArray.length > 0 && (
                <div className="form-group">
                  <label className="form-label">Adviser</label>
                  <FormControl variant="outlined" error={!!errors.adviser_id}>
                    <Select
                      error={!!errors.adviser_id}
                      onChange={handleChangeAdviser}
                      labelId="Complaint-owner-label"
                      id="Complaint-owner"
                      value={adviser}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <p className="dropdown-placeholder">Select Adviser</p>; // Render "Select Adviser" as placeholder
                        }
                        const selectedOption =
                          adviserArray &&
                          adviserArray.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {adviserArray.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || ''}>
                          {option?.name || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.adviser_id && (
                    <Typography variant="caption" color="error">
                      {errors.adviser_id}
                    </Typography>
                  )}
                </div>
              )}
              {Array.isArray(filteredTemplates) && (
                <div className="form-group">
                  <label className="form-label">Template</label>
                  <FormControl variant="outlined" error={!!errors.template_id}>
                    <Select
                      error={!!errors.template_id}
                      onChange={handleChangeTemplate}
                      labelId="Template-label"
                      id="Template"
                      value={template}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <p className="dropdown-placeholder">Select Template</p>; // Render "Select Reviewer" as placeholder
                        }
                        const selectedOption =
                          filteredTemplates &&
                          filteredTemplates.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {filteredTemplates.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || ''}>
                          {option?.name || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.template_id && (
                    <Typography variant="caption" color="error">
                      {errors.template_id}
                    </Typography>
                  )}
                </div>
              )}
              <div className="form-group w-full file-upload">
                <label className="form-label large" htmlFor="document">
                  Attach advice document to review
                </label>
                <div className="relative">
                  <TextField
                    error={!!errors.document}
                    id="document"
                    variant="outlined"
                    name="document"
                    onChange={handleFileChange}
                    className="form-control"
                    InputProps={{
                      startAdornment: (
                        <input
                          type="file"
                          className="file-upload-input"
                          onChange={handleFileChange}
                          title="file"
                        />
                      )
                    }}
                    helperText={errors.document}
                  />
                  {selectedFile ? (
                    <div className="preview-uploaded mt-2">
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span> {selectedFile.name}
                      </p>
                    </div>
                  ) : fetchDocument != null ? (
                    <div className="preview-uploaded mt-2">
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span>{' '}
                        {fetchDocument.original_name}
                      </p>
                    </div>
                  ) : (
                    <p className="placeholder-text">
                      <img src={fileUploadIc} alt="File upload" />
                      <span>
                        <span className="blue-text">Click to upload</span> or drag and drop
                      </span>
                    </p>
                  )}
                  {/* {formData.document ? (
                    <div className="preview-uploaded">
                      <img src={fileUploadIc} alt="File upload" />
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span> {formData.document.name}
                      </p>
                    </div>
                  ) : (
                    <p className="placeholder-text">
                      <img src={fileUploadIc} alt="File upload" />
                      <span>
                        <span className="blue-text">Click to upload</span> or drag and drop
                      </span>
                      <span className="small-text"></span>
                    </p>
                  )} */}
                </div>
              </div>

              {formData.is_open === 0 && (
                <div className="form-group disabled-col">
                  <label className="form-label">Date Closed</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      readOnly
                      format="DD/MMM/YYYY"
                      className="form-control date-picker"
                      value={closed_at ? dayjs(closed_at) : null}
                      slotProps={{
                        textField: {
                          error: !!errors.closed_at,
                          placeholder: 'Select Date',
                          helperText: errors.closed_at
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
      </div>
    </form>
  );
};

export default QuickVetCreateEditFormData;
