import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { TaskFormState } from '../../views/components/registers/state';
import { AccountState } from '../../views/components/user/state';

export const createTasks = async (
  formData: TaskFormState,
  moduleId: number,
  source_id: number,
  module_type: string,
  authToken?: string,
  selectedAccount?: AccountState,
  id?: number,
  questionId?: number,
  meetingId?: number,
  updateId?: number
): Promise<string | undefined | null> => {
  const selectedAccountId = selectedAccount?.id;
  const {
    task,
    module_id,
    details,
    owner,
    date_due,
    is_complete,
    date_complete,
    completed_notes,
    status_updates,
    is_assign
  } = formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('task', task);
    formData.append('source_id', source_id.toString());
    formData.append('module_type', module_type);
    formData.append('details', details ? details : '');
    formData.append('owner', owner ? owner.toString() : '');
    formData.append('date_due', date_due ? date_due : '');
    formData.append('is_complete', is_complete ? is_complete.toString() : '0');
    formData.append('is_assign', is_assign ? is_assign.toString() : '0');
    if (date_complete) {
      formData.append('date_complete', date_complete);
    }
    formData.append('completed_notes', completed_notes ? completed_notes : '');
    if (questionId) {
      formData.append('question_id', questionId.toString());
    }
    if (meetingId) {
      formData.append('meeting_id', meetingId.toString());
    }
    if (updateId) {
      formData.append('update_id', updateId.toString());
    }
    if (status_updates) {
      formData.append('status_updates', status_updates ? status_updates : '');
    }
    if (module_id) {
      formData.append('module_id', module_id!.toString());
    } else {
      formData.append('module_id', moduleId.toString());
    }
    let routeUrl;
    if (id) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/task/${dataEncrypt(id?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/task`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });
    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
