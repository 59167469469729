import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { CircularProgress } from '@mui/material';
import { Survey } from 'survey-react-ui';
import Dropdown from './Dropdown';
import { useLocation, useNavigate } from 'react-router';
import {
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { AttestationState, AttestationTemplatesState } from './state';
import { useNotificationContext } from '../../layout/NotificationContext';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import { fetchAttestationTemplate } from '../../../../api/attestations/fetchAttestationTemplate';
import AttestationLeftMenu from './AttestationLeftMenu';
import { fetchAttestations } from '../../../../api/attestations/fetchAttestations';
import { createAttestationData } from '../../../../api/attestations/createAttestationData';
import AttestationTemplateTextMode from './AttestationTemplateTextMode';
import { useModuleContext } from '../../settings/modules/ModulesContext';

const AttestationTemplateForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const selectedAccount = getSelectedAccountInfo();
  const accountId = selectedAccount?.id;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true) || ''; // Default to an empty string if undefined
  const source_Id = parseInt(sourceId!, 10);
  let attestationId = queryParams.get('attestationId');
  const encryptedAttestationId = attestationId; // Decrypt the ID
  attestationId = decryptData(attestationId, true) || ''; // Default to an empty string
  const attestation_Id = parseInt(attestationId!, 10);
  const { setNotificationSuccess } = useNotificationContext();
  const mainPath = `/attestations/${encryptedSourceId}`;
  const pageTitle = 'Attestation Form';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [loading, setLoading] = useState<boolean>(true);
  const { moduleRecords } = useModuleContext();
  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  }
  const thirdPath = `/attestation/${encryptedAttestationId}?source=${encryptedSourceId}`;

  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [survey, setSurvey] = useState<any>('');
  const userData = useCurrentUserInfo();

  const [formData, setFormData] = useState<AttestationState>({
    id: 0,
    owner_id: 0,
    user_id: 0,
    attestation_template_id: 0,
    relevant_period: '',
    instructions: '',
    is_open: 1
  });

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-attestation-template');
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      templateData != '""' &&
      formData?.attestation_data?.form_data &&
      Object.keys(formData?.attestation_data?.form_data).length !== 0
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.attestation_data?.form_data)
      );
      localStorage.setItem('survey-json-attestation-template', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.setItem('survey-json-attestation-template', templateData);
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();

      fetchTemplate();
      fetchData();
      setTimeout(() => {
        setHasEffectRun(true);
      }, 800);
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun, current_route]);

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });
      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const fetchTemplate = async () => {
    try {
      if (sourceId && authToken) {
        const response: AttestationTemplatesState = (await fetchAttestationTemplate(
          source_Id,
          accountId,
          authToken,
          attestation_Id
        )) as AttestationTemplatesState;

        if (response) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            localStorage.setItem('survey-json-attestation-template', form_data);
          } else {
            localStorage.removeItem('survey-json-attestation-template');
            setFormData((prevData) => ({ ...prevData, error: t('error.field.notemplateFound') }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (attestationId && authToken) {
      try {
        const editId = parseInt(attestationId!, 10);

        const response: AttestationState = (await fetchAttestations(
          authToken,
          selectedAccount,
          editId
        )) as AttestationState;

        if (response) {
          const {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            due_date,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user,
            created_at
          } = response;

          const newFormData = {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            due_date,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user,
            created_at
          };
          if (userData?.id !== user_id) {
            navigate('/dashboard');
          }
          setFormData(newFormData);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const setSurveyData = () => {
    const surveyJson = localStorage.getItem('survey-json-attestation-template') || ''; // Provide an empty string as the default

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.pageNextText = 'Save and next';
      surveyModel.completeText = 'Save';
      surveyModel.focusFirstQuestionAutomatic = true;
      surveyModel.addNavigationItem({
        id: 'survey_clear_current_page',
        title: 'Cancel',
        visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
        action: () => {
          handleCancelClick();
        }
      });

      setSurvey(surveyModel);
    }
  };

  const handleCancelClick = () => {
    navigate(`/attestation/${encryptedAttestationId}?source=${encryptedSourceId}`);
  };

  const filteredElements: any[] = [];
  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      let number: any;
      page.elements.forEach((element: any) => {
        const fieldName = element.name;

        if (fieldValues[fieldName] !== undefined || fieldName.trim().includes('guidance-')) {
          // Add the value to the element
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unusedVariable =
            fieldValues[fieldName] !== undefined
              ? (element.defaultValue = fieldValues[fieldName])
              : null;

          // Check if 'choices' array is present and not empty
          if (element.choices && element.choices.length > 0) {
            // Convert defaultValue to an array if it's not already
            const defaultValues = Array.isArray(element.defaultValue)
              ? element.defaultValue
              : [element.defaultValue];
            // Find the choices whose values are present in defaultValues
            const selectedChoices = element.choices.filter((choice: any) => {
              if (choice && choice.value) {
                return defaultValues.includes(choice.value);
              } else {
                return defaultValues.includes(choice);
              }
            });

            // Now you can use selectedChoices as needed
            selectedChoices.forEach((selectedChoice: any) => {
              // Your logic here
              const selectedText =
                selectedChoice && selectedChoice.text ? selectedChoice.text : selectedChoice;
              if (
                fieldName &&
                fieldName.trim().includes('check-') &&
                (selectedText.trim().toLowerCase() === 'yes with limitations' ||
                  selectedText.trim().toLowerCase() === 'no')
              ) {
                const match = fieldName.trim().match(/check-(\d+)/);
                number = match ? match[1] : null;
                const isDuplicate = filteredElements.some(
                  (existingElement) => existingElement.name === element.name
                );

                if (!isDuplicate) {
                  filteredElements.push(element);
                }
              }
            });
          }
          if (number) {
            const index = filteredElements.findIndex((element) =>
              element.name.includes(`check-${number}`)
            );
            if (element.type === 'html' && fieldName.trim().includes(`guidance-${number}`)) {
              if (index !== -1) {
                filteredElements[index].guidance = filteredElements[index].guidance || [];
                filteredElements[index].guidance.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`comment-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].comments = filteredElements[index].comments || [];
                filteredElements[index].comments.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`action-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].action = filteredElements[index].action || [];
                filteredElements[index].action.push({ element });
              }
            }
          }
        }
      });
    });

    return mainResponse;
  };

  const handleSurveySubmit = async (type = '') => {
    let validationResult = true;
    if (templateData && !type) {
      validationResult = survey.validate();
    }
    if (!validationResult) {
      return;
    }
    if (validationResult && survey) {
      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;

      const attestationDataId = formData.attestation_data?.id
        ? formData.attestation_data?.id
        : localStorage.getItem('attestation-data-id');
      mergeFieldValues(JSON.parse(templateData), surveyData);

      const response: any = (await createAttestationData(
        surveyData,
        attestation_Id,
        authToken,
        attestationDataId
      )) as any;
      if (response && response?.id) {
        localStorage.setItem('attestation-data-id', response?.id);
        if (!type) {
          localStorage.removeItem('survey-json-attestation-template');
          localStorage.removeItem('attestation-data-id');
          const msg = t('success.field.attestationDataUpdated');
          setNotificationSuccess(msg);
          setHasEffectRun(false);
          navigate(`/attestation/${encryptedAttestationId}?source=${encryptedSourceId}`);
        }
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={'Attestation'}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            thirdTitle={formData.unique_id ? formData.unique_id : pageTitle}
            thirdPath={thirdPath}
            errorMsg={formData.error}
            button={undefined}
          />{' '}
          <div className="record-outer-row settings-outer-row">
            <AttestationLeftMenu
              user={userData}
              attestationId={attestationId ? attestationId : '0'}
              sourceId={sourceId ? sourceId : ''}
              formDataRecord={formData}
            />
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : templateData &&
                  formData.attestation_data?.form_data &&
                  current_route === `/attestation-form-review` ? (
                  <div className="card-outline">
                    <AttestationTemplateTextMode
                      surveyData={JSON.parse(templateData)}
                      formData={JSON.parse(formData?.attestation_data?.form_data)}
                      // edit_btn={edit_btn_form}
                    />
                  </div>
                ) : survey ? (
                  <div className="card-outline">
                    <Survey model={survey} />

                    {survey.PageCount > 1 && (
                      <div className="inner-btn-wrap">
                        <Dropdown survey={survey} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="no-record">Attestation form not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default AttestationTemplateForm;
