import React, { useEffect, useState } from 'react';
import { RecordsState, TemplatesState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { format } from 'date-fns';
import { encryptData } from '../../../../utils/common/user';

export interface AdviserProfileConflictsOfInterestsProps {
  loading: boolean;
  template: TemplatesState;
  registerRecords: RecordsState[];
}

const AdviserProfileConflictsOfInterests: React.FC<AdviserProfileConflictsOfInterestsProps> = ({
  loading,
  template,
  registerRecords
}) => {
  const navigate = useNavigate();
  const [dense] = useState(false);
  const [orderBy, setOrderBy] = useState<keyof RecordsState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [records, setRecords] = useState<RecordsState[]>([]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof RecordsState;
    label: string;
    numeric: boolean;
  }
  const [headCells] = useState<HeadCell[]>([
    {
      id: 'unique_id',
      numeric: false,
      disablePadding: true,
      label: 'ID'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Subject'
    },
    {
      id: 'owner_id',
      numeric: false,
      disablePadding: false,
      label: 'Owner'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Status'
    }
  ]);

  useEffect(() => {
    const { form_data } = template;
    if (form_data && form_data !== '""') {
      const elements = JSON.parse(form_data).pages[0].elements;
      elements.map((element: any) => {
        if (element.name == 'Created') {
          headCells[1].label = element.title;
        } else if (element.name == 'Subject') {
          headCells[2].label = element.title;
        } else if (element.name == 'Owner') {
          headCells[3].label = element.title;
        } else if (element.name == 'Status') {
          headCells[4].label = element.title;
        }
      });
    }
  }, [template]);

  useEffect(() => {
    setRecords(registerRecords);
  }, [registerRecords]);

  const handleSort = (property: keyof RecordsState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    // Sort the registers array based on the selected criteria
    const sortedRegisters = [...records].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      // Handle sorting for numbers and strings separately
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });

    // Update the registers state with the sorted array
    setRecords(sortedRegisters);
  };

  const redirectEditPage = (rowid: number, registerId: number) => {
    navigate(
      `/register-record/${encryptData(String(rowid), true)}?source=${encryptData(String(registerId), true)}`
    );
  };

  return (
    <div className="card-outline">
      <div className="card-title-wrap full-width secondary-title-wrap">
        <div className="title-full-width">
          <h3>Conflicts of Interest</h3>
        </div>
      </div>

      <div className="row-wrap full-width">
        <TableContainer>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : records.length === 0 ? (
            <div className="no-record-adviser-incident">There are no conflicts of interests</div>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="table hover-table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`key-${headCell.id}`}
                      align={headCell.numeric ? 'left' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}>
                      {headCell.id === 'unique_id' ? (
                        headCell.label // Render the label without sorting for the "id" column
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleSort(headCell.id)}>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={{ display: 'none' }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((row: RecordsState) => (
                  <TableRow key={row.id} onClick={() => redirectEditPage(row.id, row.register_id)}>
                    <TableCell scope="row">{row.unique_id}</TableCell>
                    {/* <TableCell scope="row">{row.name}</TableCell> */}
                    {row.created_at ? (
                      <TableCell align="left">
                        {format(new Date(row.created_at), 'd/MMM/yyyy')}
                      </TableCell>
                    ) : (
                      <TableCell align="left">N/A</TableCell>
                    )}
                    <TableCell align="left">{row.subject}</TableCell>

                    <TableCell align="left">
                      {row.client?.first_name && row.client?.last_name
                        ? `${row.client.first_name} ${row.client.last_name}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left" className={row.is_open ? 'open' : 'closed'}>
                      <span>{row.is_open ? 'Open' : 'Archived'}</span>
                    </TableCell>
                    <TableCell align="left">
                      <div className="table-btn">
                        <img src={rightarrow} alt="Right Arrow" />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default AdviserProfileConflictsOfInterests;
