import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PaginationProps } from './state';
import { useNavigate } from 'react-router-dom';
import { FormControl, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const PaginationSettings: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
  isPagesShow = true,
  scroll = true,
  prevButtonText,
  nextButtonText,
  lastPageredirection,
  lastButtonTitle,
  handleClickEvent,
  additonalbutton,
  isShowPagesDropdown,
  mainCurrentPage,
  handleMainPageChange,
  dropdownOptions
}) => {
  const navigate = useNavigate();

  const handlePrevClick = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);

      if (scroll) {
        scrollToTop();
      }
    }

    if (mainCurrentPage && mainCurrentPage > 1 && currentPage == 1 && handleMainPageChange) {
      handleMainPageChange('prev');
    }
    if (handleClickEvent) {
      handleClickEvent();
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages || mainCurrentPage == 2) {
      handlePageChange(currentPage + 1);

      if (scroll) {
        scrollToTop();
      }
    }

    if (
      mainCurrentPage &&
      mainCurrentPage > 1 &&
      currentPage == totalPages &&
      handleMainPageChange
    ) {
      handleMainPageChange('next');
    }

    if (handleClickEvent) {
      handleClickEvent();
    }
  };

  const handleRedirection = () => {
    if (handleClickEvent) {
      handleClickEvent();
    }
    if (lastPageredirection) {
      navigate(lastPageredirection);
    }
  };

  const scrollToTop = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="pagination">
      {additonalbutton ? additonalbutton : ''}
      {isShowPagesDropdown && dropdownOptions && Object.keys(dropdownOptions).length == 0 && (
        <>
          <FormControl variant="outlined">
            <Select
              value={currentPage.toString()}
              displayEmpty
              onChange={(e) => {
                const selectedPage = e.target.value;
                if (selectedPage) {
                  handlePageChange(parseInt(selectedPage, 10));
                  if (scroll) {
                    scrollToTop();
                  }
                }
              }}
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="custom-select"
              MenuProps={{ className: 'custom-dropdown-menu' }}>
              <MenuItem key="0" value="">
                Select Page
              </MenuItem>
              {Array.from({ length: totalPages }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {isShowPagesDropdown && dropdownOptions && Object.keys(dropdownOptions).length > 0 && (
        <>
          <FormControl variant="outlined">
            <Select
              value={currentPage.toString()}
              displayEmpty
              onChange={(e) => {
                const selectedPage = e.target.value;
                if (selectedPage) {
                  handlePageChange(parseInt(selectedPage, 10));
                  if (scroll) {
                    scrollToTop();
                  }
                }
              }}
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="custom-select"
              MenuProps={{ className: 'custom-dropdown-menu' }}>
              <MenuItem key="0" value="">
                Select Page
              </MenuItem>
              {Object.entries(dropdownOptions).map(([, value], i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      <p
        className={`previous-btn ${
          additonalbutton || isShowPagesDropdown ? 'previous-align' : ''
        } ${!mainCurrentPage && currentPage === 1 ? 'disabled' : ''} ${
          currentPage === totalPages ? 'last-page' : ''
        }`}
        onClick={handlePrevClick}>
        <ArrowBackIcon /> {prevButtonText ? prevButtonText : 'Previous'}
      </p>
      {isPagesShow && (
        <div className="Number">
          {Array.from({ length: totalPages }, (_, i) => (
            <span
              key={i + 1}
              className={i + 1 === currentPage ? 'active' : ''}
              onClick={() => {
                handlePageChange(i + 1);
                if (scroll) {
                  scrollToTop();
                }
              }}>
              {i + 1}
            </span>
          ))}
        </div>
      )}
      <p
        className={`next-btn  ${
          !mainCurrentPage && currentPage === totalPages && !lastPageredirection ? 'disabled' : ''
        }`}
        onClick={
          !mainCurrentPage && lastPageredirection && currentPage === totalPages
            ? handleRedirection
            : handleNextClick
        }>
        {lastButtonTitle && currentPage === totalPages
          ? lastButtonTitle
          : nextButtonText
            ? nextButtonText
            : 'Next'}{' '}
        <ArrowForwardIcon />
      </p>
    </div>
  );
};

export default PaginationSettings;
