import React from 'react';
import { UploadQuestionsProps } from '../state';
import TextField from '@mui/material/TextField';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MeetingAgendasState } from '../../user/state';

const UploadQuestions: React.FC<UploadQuestionsProps> = ({
  agendas,
  questionFormData,
  handleFileChange,
  handleSelectChange,
  handleQuestionUpload,
  handleCancelClick,
  isQuestionsUploading,
  errors
}) => {
  return (
    <form onSubmit={handleQuestionUpload}>
      <div className="form-group">
        <label className="form-label">Agenda</label>
        <FormControl variant="outlined" error={!!errors.agenda_id}>
          <Select
            error={!!errors.agenda_id}
            labelId="agenda-label"
            id="agenda_id"
            name="agenda_id"
            value={questionFormData.agenda_id.toString()}
            onChange={handleSelectChange}
            IconComponent={KeyboardArrowDownRoundedIcon}
            className="custom-select"
            MenuProps={{ className: 'custom-dropdown-menu' }}
            renderValue={(selected) => {
              if (selected === '0') {
                return <p className="dropdown-placeholder">Select Agenda</p>; // Render "Select Agenda" as placeholder
              }
              const selectedOption =
                agendas && agendas.find((option) => option.id === parseInt(selected, 10));
              return selectedOption
                ? `${selectedOption.name}`
                : '';
            }}>
            {agendas && (
              agendas.map((agenda: MeetingAgendasState) => (
                <MenuItem key={agenda.id} value={agenda.id}>{agenda.name}</MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        {errors.agenda_id && (
          <Typography variant="caption" color="error">
            {errors.agenda_id}
          </Typography>
        )}
      </div>
      <div className="form-group w-full file-upload">
        <label className="form-label large" htmlFor="Logo">
          Upload Questions
        </label>
        <div className="relative">
          <TextField
            error={!!errors.questionFile}
            id="questionFile"
            variant="outlined"
            name="questionFile"
            onChange={(e) => handleFileChange(e, 'xlsx-file')}
            className="form-control"
            InputProps={{
              startAdornment: (
                <input
                  type="file"
                  accept=".xlsx"
                  className="file-upload-input"
                  onChange={(e) => handleFileChange(e, 'xlsx-file')}
                  title="file"
                />
              )
            }}
            helperText={errors.questionFile}
          />
          {questionFormData.questionFile ? (
            <div className="preview-uploaded">
              <img src={fileUploadIc} alt="File upload" />
              <p className="file-name">
                <span className="blue-text">Selected file:</span>{' '}
                {questionFormData.questionFile.name}
              </p>
            </div>
          ) : (
            <p className="placeholder-text">
              <img src={fileUploadIc} alt="File upload" />
              <span>
                <span className="blue-text">Click to upload</span> or drag and drop
              </span>
              <span className="small-text"></span>
            </p>
          )}
        </div>
      </div>
      <div className="btn-wrap">
        {isQuestionsUploading ? (
          <CircularProgress />
        ) : (
          <>
            <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button type="submit" className="btn primary-btn btn-sm">
              Save Upload
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
export default UploadQuestions;
