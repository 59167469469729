import React from 'react';
import { FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { UpdateFormsProps } from '../state';
import TinyMceEditor from '../../common/TinyMceEditor';
import { useCurrentUserInfo } from '../../../../utils/common/user';

const UpdateCreateEditFormData: React.FC<UpdateFormsProps> = ({
  formData,
  errors,
  handleInputChange,
  handleSelectChange,
  handleDateInputChange,
  handleEditorChange
}) => {
  const user = useCurrentUserInfo();

  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Update</h3>
          </div>

          <div className="row-wrap ">
            <div className="form-group">
              <label className="form-label">Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MMM/YYYY"
                  className="form-control date-picker"
                  value={formData.date ? dayjs(formData.date) : null}
                  onChange={handleDateInputChange}
                  slotProps={{
                    textField: {
                      error: !!errors.date,
                      placeholder: 'Select Date',
                      helperText: errors.date
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="form-group">
              <label className="form-label">Type</label>
              <FormControl variant="outlined" error={!!errors.type}>
                <Select
                  error={!!errors.type}
                  labelId="host-label"
                  id="type"
                  name="type"
                  value={formData.type.toString()}
                  onChange={handleSelectChange}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className="custom-select"
                  MenuProps={{ className: 'custom-dropdown-menu' }}>
                  {user?.lu_user_access_level_id != 2 && [
                    <MenuItem key={1} value="1">
                      Regulatory
                    </MenuItem>,
                    <MenuItem key={2} value="2">
                      System
                    </MenuItem>
                  ]}
                  <MenuItem value="3">Internal</MenuItem>
                </Select>
              </FormControl>
              {errors.type && (
                <Typography variant="caption" color="error">
                  {errors.type}
                </Typography>
              )}
            </div>
            <div className="form-group w-full">
              <label className="form-label">Title</label>
              <TextField
                error={!!errors.title}
                id="title"
                variant="outlined"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter the title..."
                type="text"
                helperText={errors.title}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full textarea">
              <label className="form-label">Summary</label>
              <TextField
                error={!!errors.summary}
                id="summary"
                variant="outlined"
                name="summary"
                value={formData.summary}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter a summary..."
                type="text"
                multiline
                rows={5}
                helperText={errors.summary}
              />
            </div>
            {/* <div className="form-group w-full textarea">
              <label className="form-label">Details</label>
              <TextField
                error={!!errors.details}
                id="details"
                variant="outlined"
                name="details"
                value={formData.details}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter a details..."
                type="text"
                multiline
                rows={5}
                helperText={errors.details}
              />
            </div> */}
            <div className="form-group w-full textarea">
              <label className="form-label">Details</label>
              <TinyMceEditor
                id="details"
                value={formData.details}
                handleEditorChange={handleEditorChange}
              />
            </div>
            <div className="form-group w-full">
              <label className="form-label">Additional information title</label>
              <TextField
                error={!!errors.additional_info_title}
                id="additional_info_title"
                variant="outlined"
                name="additional_info_title"
                value={formData.additional_info_title}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter the additional information title"
                type="text"
                helperText={errors.additional_info_title}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full">
              <label className="form-label">Additional information link</label>
              <TextField
                error={!!errors.additional_info_link}
                id="additional_info_link"
                variant="outlined"
                name="additional_info_link"
                value={formData.additional_info_link}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter the additional information link"
                type="text"
                helperText={errors.additional_info_link}
                inputProps={{
                  maxLength: 500 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Status</label>
              <FormControl variant="outlined">
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  value={formData.status.toString()}
                  onChange={handleSelectChange}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className="custom-select"
                  MenuProps={{ className: 'custom-dropdown-menu' }}>
                  <MenuItem value="1">Open</MenuItem>
                  <MenuItem value="0">Archived</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateCreateEditFormData;
