import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Switch, TableContainer, TextField } from '@mui/material';
import arrowUpRight from '../../../../assets/images/arrow-up-right.svg';
import { TasksState, UpdateState } from '../../user/state';
import { MeetingRegulatoryUpdateProps } from './state';
import QuestionNotesTable from './QuestionNotesTable';
import QuestionTasksTable from './QuestionTasksTable';

const MeetingRegulatoryUpdate = ({
  pathName,
  meetingRegulatoryUpdate,
  minutesFormData,
  isScribe,
  openRows,
  openTaskRows,
  handleNAChange,
  handleInputChange,
  handleMouseEnter,
  handleRowToggle,
  toggleNoteAddDialog,
  toggleTaskAddEditDialog
}: MeetingRegulatoryUpdateProps) => {
  return (
    <>
      {meetingRegulatoryUpdate.length > 0 && (
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Regulatory Updates</h3>
          </div>

          <div className="agenda-wrap">
            {meetingRegulatoryUpdate &&
              Array.isArray(meetingRegulatoryUpdate) &&
              meetingRegulatoryUpdate.map((update: UpdateState) => (
                <div key={update.id} className="agenda-question-wrap">
                  <div className="question">
                    <p>{update.title}</p>
                    <div className="right">
                      <Button
                        className="info"
                        component={RouterLink}
                        to={`/resources/update/${update.id}`}
                        target="_blank">
                        <i className="info">
                          <img src={arrowUpRight} alt="" />
                        </i>
                      </Button>

                      {isScribe === 'yes' && (
                        <>
                          <span className="na">N/A</span>
                          <div className="switch-wrap">
                            <Switch
                              className="custom-switch"
                              checked={minutesFormData[update.id!]?.na == 1}
                              onChange={(e) => handleNAChange(e, update.id!)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="name-date-wrap">
                    <span>{update.summary}</span>
                  </div>
                  {isScribe === 'yes' && minutesFormData[update.id!]?.na != 1 && (
                    <div className="form-group w-full">
                      <TextField
                        fullWidth
                        className="form-control textarea"
                        value={minutesFormData[update.id!]?.minutes}
                        onChange={(e) => handleInputChange(e, 'minute', update.id!)}
                        placeholder="Enter minutes"
                        multiline
                        rows={2}
                      />
                    </div>
                  )}

                  {pathName !== '/meeting/review-agenda' &&
                    isScribe !== 'yes' &&
                    update.minute &&
                    update.minute.minutes && (
                      <div className="form-group w-full">
                        <label className="form-label">Minutes</label>
                        <p className="form-control">{update.minute?.minutes}</p>
                      </div>
                    )}

                  {update.notes && update.notes.length > 0 && (
                    <div
                      key={`notes-${update.id}`}
                      className="table-outer table-sm-outer"
                      onMouseEnter={() => handleMouseEnter(update.id!)}>
                      <TableContainer>
                        <QuestionNotesTable
                          data={update.notes}
                          openRows={openRows}
                          handleRowToggle={handleRowToggle}
                        />
                      </TableContainer>
                    </div>
                  )}

                  {pathName !== '/meeting/review-agenda' &&
                    update.tasks &&
                    update.tasks.length > 0 && (
                      <div
                        key={`tasks-${update.id}`}
                        className="table-outer table-sm-outer"
                        onMouseEnter={() => handleMouseEnter(update.id!)}>
                        <TableContainer>
                          <QuestionTasksTable
                            data={update.tasks}
                            openRows={openTaskRows}
                            handleRowToggle={handleRowToggle}
                          />
                        </TableContainer>
                      </div>
                    )}

                  {isScribe === 'yes' && minutesFormData[update.id!]?.na != 1 && (
                    <div className="btn-wrap align-left">
                      {/* commented on 07 March 2024 Client dont need below button on run meeting page  */}
                      {/* <Button
                        className="btn blue-fill-btn btn-sm"
                        onClick={() => toggleNoteAddDialog(update.id!, update.title, 'update')}>
                        <i className="left">
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <g clipPath="url(#clip0_6545_879)">
                              <path
                                d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                                stroke="CurrentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6545_879">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </i>
                        Add Note
                      </Button> */}
                      <Button
                        className="btn blue-fill-btn btn-sm"
                        onClick={() =>
                          toggleTaskAddEditDialog(
                            update.id!,
                            update.title,
                            {} as TasksState,
                            'update'
                          )
                        }>
                        <i className="left">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path
                              d="M13.834 3.33464C14.609 3.33464 14.9964 3.33464 15.3144 3.41982C16.1771 3.65099 16.851 4.32486 17.0821 5.18759C17.1673 5.5055 17.1673 5.89299 17.1673 6.66797V14.3346C17.1673 15.7348 17.1673 16.4348 16.8948 16.9696C16.6552 17.44 16.2727 17.8225 15.8023 18.0622C15.2675 18.3346 14.5674 18.3346 13.1673 18.3346H7.83398C6.43385 18.3346 5.73379 18.3346 5.19901 18.0622C4.7286 17.8225 4.34615 17.44 4.10647 16.9696C3.83398 16.4348 3.83398 15.7348 3.83398 14.3346V6.66797C3.83398 5.89299 3.83398 5.5055 3.91917 5.18759C4.15034 4.32486 4.82421 3.65099 5.68694 3.41982C6.00485 3.33464 6.39234 3.33464 7.16732 3.33464M8.00065 12.5013L9.66732 14.168L13.4173 10.418M8.50065 5.0013H12.5007C12.9674 5.0013 13.2007 5.0013 13.379 4.91047C13.5358 4.83058 13.6633 4.7031 13.7432 4.54629C13.834 4.36803 13.834 4.13468 13.834 3.66797V3.0013C13.834 2.53459 13.834 2.30124 13.7432 2.12298C13.6633 1.96617 13.5358 1.83869 13.379 1.7588C13.2007 1.66797 12.9674 1.66797 12.5007 1.66797H8.50065C8.03394 1.66797 7.80059 1.66797 7.62233 1.7588C7.46552 1.83869 7.33804 1.96617 7.25815 2.12298C7.16732 2.30124 7.16732 2.53459 7.16732 3.0013V3.66797C7.16732 4.13468 7.16732 4.36803 7.25815 4.54629C7.33804 4.7031 7.46552 4.83058 7.62233 4.91047C7.80059 5.0013 8.03394 5.0013 8.50065 5.0013Z"
                              stroke="CurrentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </i>
                        Add Task
                      </Button>
                    </div>
                  )}

                  {!isScribe && pathName !== '/meeting/view-agenda' && (
                    <div className="btn-wrap align-left">
                      <Button
                        className="btn blue-fill-btn btn-sm"
                        onClick={() => toggleNoteAddDialog(update.id!, update.title, 'update')}>
                        <i className="left">
                          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <g clipPath="url(#clip0_6545_879)">
                              <path
                                d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                                stroke="CurrentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6545_879">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </i>
                        Add Note
                      </Button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MeetingRegulatoryUpdate;
