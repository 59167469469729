import React, { useState, useEffect } from 'react';
import { useAuthToken } from '../../../../utils/common/user';
import {
  Choices,
  TemplateTextModeProps,
  fetchDynamicChoicesData,
  renderFormElement
} from '../../../../utils/common/surveyjs/survey-js';
import { CircularProgress } from '@mui/material';

const AdviserProfileTemplateTextMode: React.FC<TemplateTextModeProps> = ({
  surveyData,
  formData,
  edit_btn,
  title,
  emptyText,
  loading
}) => {
  const [dynamicChoicesData, setDynamicChoicesData] = useState<{ [key: string]: Choices[] }>({});
  const authToken = useAuthToken();

  useEffect(() => {
    // Fetch dynamic choices data when the component mounts
    fetchDynamicChoicesData(authToken, surveyData, formData, setDynamicChoicesData);
  }, []);

  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <div>
          <h3>{title}</h3>
        </div>
        <div className="inner-btn-wrap">{edit_btn}</div>
      </div>
      {loading ? (
        <div className="no-record">
          <CircularProgress />
        </div>
      ) : (
        <div className="row-wrap">
          {surveyData?.title && (
            <div className="inner-wrap-title">
              <h5 className="title-padding">{surveyData?.title}</h5>
            </div>
          )}
          <div className="form-group w-full">
            {surveyData &&
              surveyData?.pages?.map((page) => (
                <div key={page.name} className="row-wrap">
                  {page.elements
                    .filter(
                      (element) => formData[element.name] !== undefined || element.type == 'html'
                    )
                    .map((element) => (
                      <div className={`form-group w-full`} key={element.name}>
                        {element.type !== 'html' && (
                          <label className="form-label">
                            {element.title ? element.title : element.name}
                          </label>
                        )}
                        {renderFormElement(
                          element,
                          formData[element.name],
                          authToken,
                          dynamicChoicesData
                        )}
                      </div>
                    ))}
                </div>
              ))}
            {Object.keys(formData).length === 0 && <div className="no-record">{emptyText}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default AdviserProfileTemplateTextMode;
