import { PaginationResponse, FileReviewTemplatesState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchFileReviewTemplatesFiltered = async (
  authToken?: string,
  accountInfo?: AccountState,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  reviewId?: number,
  templateId?: number,
  isDefault?: boolean,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | FileReviewTemplatesState | FileReviewTemplatesState[] | string | undefined> => {
  let routeUrl;

  const selectedAccountId = accountInfo?.id;
  routeUrl = `${process.env.REACT_APP_API_URL}/file-review-templates-filtered?account_id=${selectedAccountId}`

  if (templateId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/file-review-templates-filtered/${templateId}`;
  } else if (reviewId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-templates-filereview/${reviewId}`;
  } else if (!isDefault) {
    routeUrl = `${process.env.REACT_APP_API_URL}/file-review-templates-filtered?page=${currentPage}&perPage=${itemsPerPage}&account_id=${selectedAccountId}&orderBy=${orderBy}&order=${order}`;
  }

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData?.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};

