import React, { useEffect, useState } from 'react';
import { AutomatedReviewProps } from './state';
import { fetchAutomatedReview } from '../../../../api/quickvets/fetchAutomatedReview';
import { CircularProgress } from '@mui/material';
import { Done } from '@mui/icons-material';

interface AutomatedResponse {
  [key: string]: {
    question: string;
    answer: string;
  };
}

const AutomatedReview: React.FC<AutomatedReviewProps> = ({
  sourceId,
  authToken,
  selectedAccount,
  quickVetId,
  template
}) => {
  const [automatedResponse, setAutomatedResponse] = useState<AutomatedResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Add a loading state

  useEffect(() => {
    fetchAutomatedData();
  }, []);

  const fetchAutomatedData = async () => {
    try {
      if (authToken) {
        const response: any = await fetchAutomatedReview(
          sourceId,
          authToken,
          selectedAccount,
          quickVetId
        );

        if (response) {
          setAutomatedResponse(response.responses);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // Set loading to false once fetching is complete (either success or failure)
      setLoading(false);
    }
  };

  return (
    <form action="" className="record-create-form">
      <div className="card-outline">
        <div className="card-title-wrap secondary-title-wrap">
          <h3>Automated Review</h3>
        </div>
        <div className="secondary-title-wrap">
          <h5 style={{ color: '#344054' }}>[ {template?.name ?? ''} ]</h5>
        </div>
        <div style={{ marginTop: '20px' }}>
          {loading ? (
            <div className="form-group">
              <CircularProgress />
            </div>
          ) : (
            automatedResponse && (
              <div>
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Check</th>
                      <th>Probably Complaint</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(automatedResponse).map((qid) => (
                      <tr key={qid}>
                        <td>{automatedResponse[qid].question}</td>
                        <td>
                          {automatedResponse[qid].answer === 'Yes' ? (
                            <Done style={{ color: '#667085' }} />
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
    </form>
  );
};

export default AutomatedReview;
