import React from 'react';
import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

interface ImageContextType {
  selectedImage: File | null;
  selectedName: string | null;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedName: React.Dispatch<React.SetStateAction<string | null>>;
}
const ImageContext = createContext<ImageContextType | undefined>(undefined);

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};

export const ImageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  ImageProvider.propTypes = {
    children: PropTypes.node.isRequired
  };
  return (
    <ImageContext.Provider
      value={{ selectedImage, selectedName, setSelectedImage, setSelectedName }}>
      {children}
    </ImageContext.Provider>
  );
};
