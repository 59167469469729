import React, { useEffect, useState } from 'react';
import { PaginationResponse, UserState } from '../../user/state';
import { ProfessionalYearState } from './state';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken
} from '../../../../utils/common/user';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import { fetchProfessionalYears } from './../../../../api/professional-year/fetchProfessionalYears';
import { MODULES, NOTE_READ, TASK_READ } from '../../../../constants/constants';

interface LeftMenuProps {
  user: UserState | undefined;
  professionalYearId?: string;
  sourceId?: string;
  formData_record?: ProfessionalYearState;
  professionalYearIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const ProfessionalYearsLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  professionalYearId,
  sourceId,
  formData_record,
  professionalYearIdEncrypted,
  sourceIdEncrypted
}) => {
  const module_type = 'professional_years';
  const source_Id = parseInt(sourceId!, 10);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();

  const taskUrl = `/professional-year/tasks?source=${sourceIdEncrypted}&professionalYearId=${professionalYearIdEncrypted}`;
  const notesUrl = `/professional-year/notes?source=${sourceIdEncrypted}&professionalYearId=${professionalYearIdEncrypted}`;
  const detailsUrl = `/professional-year/details/${professionalYearIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/professional-year-edit/${professionalYearIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit1 = `/professional-year/logbook-add?source=${sourceIdEncrypted}&professionalYearId=${professionalYearIdEncrypted}`;
  const detailsUrlEdit2 = `/professional-year/plan-edit?source=${sourceIdEncrypted}&professionalYearId=${professionalYearIdEncrypted}`;
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('professionalYearNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('professionalYearTasksCount') || ''
  );

  const [formData, setFormData] = useState<ProfessionalYearState>({
    unique_id: '',
    new_entrant_id: 0,
    is_open: 0
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (professionalYearId && authToken) {
        const editId = parseInt(professionalYearId!, 10);

        const response: ProfessionalYearState = (await fetchProfessionalYears(
          authToken,
          selectedAccount,
          editId
        )) as ProfessionalYearState;

        if (response) {
          const { unique_id } = response;
          const uniqueId = unique_id || '';

          localStorage.removeItem('professional_year_unique_id');
          localStorage.setItem('professional_year_unique_id', uniqueId);

          setFormData(response);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('professionalYearNotesCount');
    try {
      if (authToken && professionalYearId) {
        const record_Id = parseInt(professionalYearId, 10);

        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('professionalYearNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('professionalYearTasksCount');
    try {
      if (authToken && professionalYearId) {
        const record_Id = parseInt(professionalYearId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('professionalYearTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    } else {
      formData_record ? setFormData(formData_record) : '';
    }

    if (!localStorage.getItem('professionalYearNotesCount')) {
      fetchNotesCount();
    }
    if (!localStorage.getItem('professionalYearTasksCount')) {
      fetchTasksCount();
    }
  }, [sourceId, formData_record]);

  // URLs exclude from the menu
  const excludedUrls = [
    '/professional-year-add',
    '/professional-year/add-note',
    `/professional-year/edit-note/${id}`,
    '/professional-year/add-task',
    `/professional-year/edit-task/${id}`,
    `/professional-year-edit/${id}`,
    `/professional-year/plan-supervisor-edit`,
    `/professional-year/plan-supervisor-view`,
    '/professional-year/logbook-workactivity-add',
    `/professional-year/logbook-workactivity-edit/${id}`,
    `/professional-year/log-training-edit/${id}`,
    '/professional-year/q1plan-supervisor-edit',
    '/professional-year/q1plan-supervisor-view',
    '/professional-year/q2plan-supervisor-edit',
    '/professional-year/q2plan-supervisor-view',
    '/professional-year/q3plan-supervisor-edit',
    '/professional-year/q3plan-supervisor-view',
    '/professional-year/q4plan-supervisor-edit',
    '/professional-year/q4plan-supervisor-view',
    '/professional-year/exam',
    '/professional-year/exam-view',
    '/professional-year/completion',
    '/new-entrant/q1plan-entrant-edit',
    '/new-entrant/q1plan-entrant-view',
    '/new-entrant/q2plan-entrant-edit',
    '/new-entrant/q2plan-entrant-view',
    '/new-entrant/q3plan-entrant-edit',
    '/new-entrant/q3plan-entrant-view',
    '/new-entrant/q4plan-entrant-edit',
    '/new-entrant/q4plan-entrant-view',
    '/new-entrant/exam',
    '/new-entrant/exam-view'
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit, detailsUrlEdit1, detailsUrlEdit2] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/professional-year/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/professional-year-add' && (
              <span className="inner-text-wrap">{t('professional-year.addMenuMsg')}</span>
            )}

            {location.pathname == '/professional-year/completion' && (
              <span className="inner-text-wrap">{t('professional-year.completion')}</span>
            )}

            {(location.pathname == '/new-entrant/q1plan-entrant-edit' ||
              location.pathname == '/new-entrant/q1plan-entrant-view') && (
              <span className="inner-text-wrap">{t('professional-year.q1Entrant')}</span>
            )}

            {(location.pathname == '/new-entrant/q2plan-entrant-edit' ||
              location.pathname == '/new-entrant/q2plan-entrant-view') && (
              <span className="inner-text-wrap">{t('professional-year.q2Entrant')}</span>
            )}

            {(location.pathname == '/new-entrant/q3plan-entrant-edit' ||
              location.pathname == '/new-entrant/q3plan-entrant-view') && (
              <span className="inner-text-wrap">{t('professional-year.q3Entrant')}</span>
            )}

            {(location.pathname == '/new-entrant/q4plan-entrant-edit' ||
              location.pathname == '/new-entrant/q4plan-entrant-view') && (
              <span className="inner-text-wrap">{t('professional-year.q4Entrant')}</span>
            )}

            {(location.pathname == '/new-entrant/exam' ||
              location.pathname == '/new-entrant/exam-view') && (
              <span className="inner-text-wrap">{t('professional-year.examEntrant')}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/professional-year-add' &&
                location.pathname !== '/professional-year/completion' &&
                location.pathname !== '/new-entrant/q1plan-entrant-edit' &&
                location.pathname !== '/new-entrant/q1plan-entrant-view' &&
                location.pathname !== '/new-entrant/q2plan-entrant-edit' &&
                location.pathname !== '/new-entrant/q2plan-entrant-view' &&
                location.pathname !== '/new-entrant/q3plan-entrant-edit' &&
                location.pathname !== '/new-entrant/q3plan-entrant-view' &&
                location.pathname !== '/new-entrant/q4plan-entrant-edit' &&
                location.pathname !== '/new-entrant/q4plan-entrant-view' &&
                location.pathname !== '/new-entrant/exam' &&
                location.pathname !== '/new-entrant/exam-view' && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      {formData && formData.unique_id ? (
                        <p>{formData.unique_id ? formData.unique_id : ''}</p>
                      ) : null}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>New Entrant</h6>
                      {formData && formData.new_entrant && (
                        <p>
                          {formData.new_entrant.first_name} {formData.new_entrant.last_name}
                        </p>
                      )}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Supervisor</h6>
                      {formData && formData.supervisor && (
                        <p>
                          {formData.supervisor.first_name} {formData.supervisor.last_name}
                        </p>
                      )}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      {formData && formData?.is_open ? (
                        <p>{formData?.is_open == 1 ? 'Open' : 'Archived'}</p>
                      ) : null}
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default ProfessionalYearsLeftMenu;
