import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const fetchPresignedUrl = async (filePath: string, authToken?: string): Promise<string> => {
  try {
    const type = localStorage.getItem('type');
    let url = `${process.env.REACT_APP_API_URL}/s3-presigned-file-url?file=${filePath}`;

    // Check if type is present and append it to the URL
    if (type) {
      url += `&type=${type}`;
    }

    const response = await fetch(
      url, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching data';
  }
};
