import React from 'react';
import { EditFormsPropsModule } from '../state';
import TextField from '@mui/material/TextField';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { Button, Switch } from '@mui/material';

const ModuleUpdateForm: React.FC<EditFormsPropsModule> = ({
  formData,
  handleInputChange,
  handleSubmit,
  handleFileChange,
  handleCancelClick,
  handleSwitchChange,
  // handleChangeTemplate,
  previewURL,
  fetchLogo,
  errors,
  selectedFile
  // templatesOptionsWithValueLabel,
  // template
}) => (
  <form onSubmit={handleSubmit}>
    <div className="card-outline">
      <div className="row-wrap">
        <div className="form-group">
          <label className="form-label" htmlFor="add_record_label">
            Add record label
          </label>
          <TextField
            error={!!errors.add_record_label}
            id="add_record_label"
            variant="outlined"
            name="add_record_label"
            className="form-control"
            type="text"
            placeholder="Add record label"
            value={formData.add_record_label ? formData.add_record_label : ''}
            onChange={handleInputChange}
            helperText={errors.add_record_label}
          />
        </div>
        <div className="form-group"></div>
        <div className="form-group">
          <label className="form-label">Default Module for Starter Plan</label>
          <div className="switch-wrap">
            {formData.default_for_starter ? (
              <>
                <p className="mute-text">No</p>
                <Switch
                  className="custom-switch"
                  checked={ formData.default_for_starter === 1 ? true : false}
                  onChange={handleSwitchChange('default_for_starter')}
                />
                <p>Yes</p>
              </>
            ) : (
              <>
                <p>No</p>
                <Switch
                  className="custom-switch"
                  checked={formData.default_for_starter === 1 ? true : false}
                  onChange={handleSwitchChange('default_for_starter')}
                />
                <p className="mute-text">Yes</p>
              </>
            )}
          </div>
        </div>
        <div className="form-group">
          <label className="form-label">Default Module for Premium Plan</label>
          <div className="switch-wrap">
            {formData.default_for_premium ? (
              <>
                <p className="mute-text">No</p>
                <Switch
                  className="custom-switch"
                  checked={formData.default_for_premium === 1 ? true : false}
                  onChange={handleSwitchChange('default_for_premium')}
                />
                <p>Yes</p>
              </>
            ) : (
              <>
                <p>No</p>
                <Switch
                  className="custom-switch"
                  checked={formData.default_for_premium === 1 ? true : false}
                  onChange={handleSwitchChange('default_for_premium')}
                />
                <p className="mute-text">Yes</p>
              </>
            )}
          </div>
        </div>
        {/* <div className="form-group">
          <label className="form-label" htmlFor="System Name">
            System Name
          </label>
          <TextField
            id="systemName"
            variant="outlined"
            name="systemName"
            className="form-control"
            type="text"
            placeholder="Enter System Name"
            value={formData.name}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="prefix">
            Prefix
          </label>
          <TextField
            error={!!errors.prefix}
            id="prefix"
            value={formData.prefix !== null ? formData.prefix : ''}
            variant="outlined"
            name="prefix"
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter Account Prefix"
            type="text"
            disabled
            helperText={errors.Prefix}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="displayName">
            Display Name
          </label>
          <TextField
            error={!!errors.display_name}
            id="displayName"
            variant="outlined"
            name="display_name"
            value={(formData as ModuleDataFormState).display_name}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter Display Name"
            type="text"
            helperText={errors.display_name}
          />
        </div> */}
        {/* {templatesOptionsWithValueLabel && templatesOptionsWithValueLabel.length > 0 && (
          <div className="form-group">
            <label className="form-label">Template</label>
            <FormControl variant="outlined" error={!!errors.template_id} sx={{ width: 360 }}>
              {template === '' ? ( // Check if template is empty
                <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
              ) : null}
              <Select
                error={!!errors.template_id}
                onChange={handleChangeTemplate}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={template}
                IconComponent={KeyboardArrowDownRoundedIcon}
                className="custom-select"
                MenuProps={{ className: 'custom-dropdown-menu' }}>
                {templatesOptionsWithValueLabel.map((option) => (
                  <MenuItem key={option?.id} value={option?.id || ''}>
                    {option?.name || ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.template_id && (
              <Typography variant="caption" color="error">
                {errors.template_id}
              </Typography>
            )}
          </div>
        )} */}
        {/* <div className="form-group w-full textarea">
          <label className="form-label">Description</label>
          <TextField
            error={!!errors.description}
            id="description"
            variant="outlined"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter a description..."
            type="text"
            helperText={errors.description}
          />
        </div> */}
        <div className="form-group w-full file-upload">
          <label className="form-label large" htmlFor="Logo">
            Logo upload
          </label>
          <div className="relative">
            <TextField
              id="logo"
              variant="outlined"
              name="logo"
              onChange={handleFileChange}
              className="form-control"
              InputProps={{
                startAdornment: (
                  <input
                    type="file"
                    accept="image/*"
                    className="file-upload-input"
                    onChange={handleFileChange}
                    title="file"
                  />
                )
              }}
              helperText={errors.logo}
            />
            {selectedFile ? (
              <div className="preview-uploaded">
                {previewURL && <img src={previewURL} alt="Preview" />}
                <p className="file-name">
                  <span className="blue-text">Selected file:</span> {selectedFile.name}
                </p>
              </div>
            ) : fetchLogo != null ? (
              <div className="preview-uploaded">
                <img src={`${fetchLogo.path}`} alt="Logo" />
                <p className="file-name">
                  <span className="blue-text">Logo :</span> {fetchLogo.original_name}
                </p>
              </div>
            ) : (
              <p className="placeholder-text">
                <img src={fileUploadIc} alt="File upload" />
                <span>
                  <span className="blue-text">Click to upload</span> or drag and drop
                </span>
                <span className="small-text">Logo has to be 40px by 40px PNG file.</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="btn-wrap mt-2">
        <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" className="btn primary-btn btn-sm">
          Save changes
        </Button>
      </div>
    </div>
  </form>
);

export default ModuleUpdateForm;
