import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import { Survey } from 'survey-react-ui';
import Dropdown from '../Dropdown';
import { t } from 'i18next';
import * as SurveyCore from 'survey-core';
import { Model } from 'survey-core';
import { ProfessionalYearState, ProfessionalYearTemplatesState } from '../state';
import { fetchProfessionalYearTemplates } from '../../../../../api/settings/fetchProfessionalYearTemplates';
import { getSelectedAccountInfo, useAuthToken } from '../../../../../utils/common/user';
import { uploadSurveyFile } from '../../../../../api/survey/uploadSurveyFile';
import { createProfessionalYearTemplateData } from '../../../../../api/professional-year/createProfessionalYearTemplateData';
import { useNotificationContext } from '../../../layout/NotificationContext';

export interface TaskAddEditDialogProps {
  templateType: string;
  formData: ProfessionalYearState;
  professionalYearId: number;
  dataId: number;
  openTemplateDialog: boolean;
  toggleTemplateDialog: () => void;
  setWorkActivityLogAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setTrainingLogAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfessionalYearTemplateDialog: React.FC<TaskAddEditDialogProps> = ({
  templateType,
  formData,
  professionalYearId,
  dataId,
  openTemplateDialog,
  toggleTemplateDialog,
  setWorkActivityLogAdded,
  setTrainingLogAdded
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();
  const [survey, setSurvey] = useState<any>('');
  const [template, setTemplate] = useState<string>('');

  SurveyCore.ChoicesRestfull.clearCache();
  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  const getProfessionalYearTemplates = async () => {
    try {
      if (authToken) {
        setLoading(true);
        const response: ProfessionalYearTemplatesState[] = (await fetchProfessionalYearTemplates(
          authToken,
          selectedAccount,
          8,
          professionalYearId
        )) as ProfessionalYearTemplatesState[];

        if (response.length > 0) {
          response.map((template: ProfessionalYearTemplatesState) => {
            if (template.template !== '""') {
              if (template.type === templateType) {
                setTemplate(template.template);
              }
            } else {
              console.log('Error: ', t('error.field.notemplateFound'));
            }
          });
        } else {
          console.log('Error: ', t('error.field.notemplateFound'));
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openTemplateDialog) {
      getProfessionalYearTemplates();
    }
  }, [openTemplateDialog]);

  useEffect(() => {
    if (templateType === t('templateType.logWorkactivity')) {
      localStorage.setItem(
        'survey-json-professionalyear-logbook-workactivity-temp-render',
        template
      );

      if (
        Object.keys(template).length !== 0 &&
        Object.keys(template).length > 1 &&
        formData?.logbook_workactivity_data &&
        formData.logbook_workactivity_data?.length > 0 &&
        dataId > 0
      ) {
        const logWorkActivityData = formData.logbook_workactivity_data.find(
          (workActivityData) => workActivityData.id === dataId
        );
        if (logWorkActivityData && logWorkActivityData.form_data) {
          const mergedResponse = mergeFieldValues(
            JSON.parse(template),
            JSON.parse(logWorkActivityData.form_data)
          );
          localStorage.setItem(
            'survey-json-professionalyear-logbook-workactivity-temp-render',
            JSON.stringify(mergedResponse)
          );
        }
      }
    } else if (templateType === t('templateType.logTraining')) {
      localStorage.setItem('survey-json-professionalyear-log-training-temp-render', template);

      if (
        Object.keys(template).length !== 0 &&
        Object.keys(template).length > 1 &&
        formData?.log_training_data &&
        formData.log_training_data?.length > 0 &&
        dataId > 0
      ) {
        const logTrainingData = formData.log_training_data.find(
          (workActivityData) => workActivityData.id === dataId
        );
        if (logTrainingData && logTrainingData.form_data) {
          const mergedResponse = mergeFieldValues(
            JSON.parse(template),
            JSON.parse(logTrainingData.form_data)
          );
          localStorage.setItem(
            'survey-json-professionalyear-log-training-temp-render',
            JSON.stringify(mergedResponse)
          );
        }
      }
    }

    setSurveyData();
  }, [template, dataId]);

  const setSurveyData = () => {
    let surveyJson;

    if (templateType === t('templateType.logWorkactivity')) {
      surveyJson =
        localStorage.getItem('survey-json-professionalyear-logbook-workactivity-temp-render') || ''; // Provide an empty string as the default
    } else if (templateType === t('templateType.logTraining')) {
      surveyJson =
        localStorage.getItem('survey-json-professionalyear-log-training-temp-render') || ''; // Provide an empty string as the default
    }

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.pageNextText = 'Save and next';
      surveyModel.completeText = 'Save';
      surveyModel.focusFirstQuestionAutomatic = true;
      surveyModel.addNavigationItem({
        id: 'survey_clear_current_page',
        title: 'Cancel',
        visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
        action: () => {
          toggleTemplateDialog();
        }
      });

      setSurvey(surveyModel);
    }
  };

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });

      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const filteredElements: any[] = [];
  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      let number: any;
      page.elements.forEach((element: any) => {
        const fieldName = element.name;

        if (fieldValues[fieldName] !== undefined || fieldName.trim().includes('guidance-')) {
          // Add the value to the element
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unusedVariable =
            fieldValues[fieldName] !== undefined
              ? (element.defaultValue = fieldValues[fieldName])
              : null;

          // Check if 'choices' array is present and not empty
          if (element.choices && element.choices.length > 0) {
            // Convert defaultValue to an array if it's not already
            const defaultValues = Array.isArray(element.defaultValue)
              ? element.defaultValue
              : [element.defaultValue];
            // Find the choices whose values are present in defaultValues
            const selectedChoices = element.choices.filter((choice: any) => {
              if (choice && choice.value) {
                return defaultValues.includes(choice.value);
              } else {
                return defaultValues.includes(choice);
              }
            });

            // Now you can use selectedChoices as needed
            selectedChoices.forEach((selectedChoice: any) => {
              // Your logic here
              const selectedText =
                selectedChoice && selectedChoice.text ? selectedChoice.text : selectedChoice;
              if (
                fieldName &&
                fieldName.trim().includes('check-') &&
                (selectedText.trim().toLowerCase() === 'yes with limitations' ||
                  selectedText.trim().toLowerCase() === 'no')
              ) {
                const match = fieldName.trim().match(/check-(\d+)/);
                number = match ? match[1] : null;
                const isDuplicate = filteredElements.some(
                  (existingElement) => existingElement.name === element.name
                );

                if (!isDuplicate) {
                  filteredElements.push(element);
                }
              }
            });
          }
          if (number) {
            const index = filteredElements.findIndex((element) =>
              element.name.includes(`check-${number}`)
            );
            if (element.type === 'html' && fieldName.trim().includes(`guidance-${number}`)) {
              if (index !== -1) {
                filteredElements[index].guidance = filteredElements[index].guidance || [];
                filteredElements[index].guidance.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`comment-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].comments = filteredElements[index].comments || [];
                filteredElements[index].comments.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`action-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].action = filteredElements[index].action || [];
                filteredElements[index].action.push({ element });
              }
            }
          }
        }
      });
    });

    return mainResponse;
  };

  const handleSurveySubmit = async (type = '') => {
    let validationResult = true;
    if (template && !type) {
      validationResult = survey.validate();
    }
    if (!validationResult) {
      return;
    }

    if (validationResult && survey) {
      const surveyData = survey.data;

      mergeFieldValues(JSON.parse(template), surveyData);

      const response: any = (await createProfessionalYearTemplateData(
        templateType,
        surveyData,
        formData.id || 0,
        authToken,
        dataId
      )) as any;
      if (response && response?.id) {
        if (!type) {
          let msg = '';
          if (templateType === t('templateType.logWorkactivity')) {
            localStorage.removeItem(
              'survey-json-professionalyear-logbook-workactivity-temp-render'
            );

            msg = t('success.field.professionalYearLogbookDataCreated');
            setWorkActivityLogAdded(true);
          } else if (templateType === t('templateType.logTraining')) {
            localStorage.removeItem('survey-json-professionalyear-log-training-temp-render');

            msg = t('success.field.professionalYearLogbookDataCreated');
            setTrainingLogAdded(true);
          }

          setTemplate('');
          setNotificationSuccess(msg);
          toggleTemplateDialog();
        }
      }
    }
  };

  return (
    <Dialog
      open={openTemplateDialog}
      onClose={toggleTemplateDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="log-modal">
      <div className="model-wrapper">
        {!survey.hasTitle && (
          <div className="modal-title">
            <h2>
              {templateType === t('templateType.logWorkactivity')
                ? 'Log Work Activity'
                : templateType === t('templateType.logTraining')
                  ? 'Log Training'
                  : ''}
            </h2>
          </div>
        )}
      </div>

      {loading ? (
        <div className="no-record">
          <CircularProgress />
        </div>
      ) : (
        <div className="card-outline">
          <Survey model={survey} />

          {survey.PageCount > 1 && (
            <div className="inner-btn-wrap">
              <Dropdown survey={survey} />
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default ProfessionalYearTemplateDialog;
