import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AdviserProfileTemplateState } from '../../views/components/modules/adviser-profiles/state';

export const fetchAdviserProfilesTemplates = async (
  templateId: number,
  type: string,
  authToken: string,
): Promise<AdviserProfileTemplateState | string | undefined> => {
  
  const routeUrl = `${process.env.REACT_APP_API_URL}/adviser-profiles-templates/${dataEncrypt(templateId?.toString())}?type=${type}`;
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
