import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonLayout from '../layout/CommonLayout';

const Logout: React.FC = () => {
  const title = 'Logout';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate('/login');
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <></>
    </CommonLayout>
  );
};

export default Logout;
