import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { NotesFormState } from '../../views/components/registers/state';
import { AccountState } from '../../views/components/user/state';

export const createNotes = async (
  formData: NotesFormState,
  module_id: number,
  source_id: number,
  module_type: string,
  authToken?: string,
  selectedAccount?: AccountState,
  id?: number,
  questionId?: number,
  meetingId?: number,
  taskId?: number,
  updateId?: number
): Promise<string | undefined | null> => {
  const selectedAccountId = selectedAccount?.id;

  const { note, subject, attachments } = formData;
  try {
    const form_Data = new FormData(); // Create a FormData object
    form_Data.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    form_Data.append('module_id', module_id.toString());
    form_Data.append('source_id', source_id.toString());
    form_Data.append('module_type', module_type);
    form_Data.append('subject', subject ? subject : '');
    form_Data.append('note', note ? note : '');
    if (attachments && attachments.length > 0) {
      for (const file of attachments) {
        form_Data.append('attachments[]', file);
      }
    }
    if (questionId) {
      form_Data.append('question_id', questionId.toString());
    }
    if (meetingId) {
      form_Data.append('meeting_id', meetingId.toString());
    }
    if (taskId) {
      form_Data.append('meeting_task_id', taskId.toString());
    }
    if (updateId) {
      form_Data.append('update_id', updateId.toString());
    }

    let routeUrl;

    if (id) {
      form_Data.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/notes/${id}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/notes`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, form_Data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });
    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
