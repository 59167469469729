import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import OnboardingLeftMenu from './OnboardingLeftMenu';
import { CircularProgress } from '@mui/material';
import { Survey } from 'survey-react-ui';
import Dropdown from './Dropdown';
import { useLocation, useNavigate } from 'react-router';
import {
  useAuthToken,
  useCurrentOnboardingUserInfo,
  decryptData,
  encryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
// import { useNotificationContext } from '../../layout/NotificationContext'
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { OnboardingTemplateState } from './state';
import { fetchOnboardingApplicationTemplate } from '../../../../api/onboarding/fetchOnboardingApplicationTemplate';
import { fetchOnboardingPersonalDetailsTemplate } from '../../../../api/onboarding/fetchOnboardingPersonalDetailsTemplate';
import { createOnboardingApplicationData } from '../../../../api/onboarding/createOnboardingApplicationData';
import { useNotificationContext } from '../../layout/NotificationContext';
import { fetchOnboardingApplicationData } from '../../../../api/onboarding/fetchOnboardingApplicationData';
import { fetchOnboardingPersonalDetailsData } from '../../../../api/onboarding/fetchOnboardingPersonalDetailsData';
import { OnboardingTempplateReviewDataState } from '../../user/state';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import { AdviserProfileTemplateState } from '../adviser-profiles/state';
import { createAdviserProfilePersonalDetailsData } from '../../../../api/adviser-profiles/createAdviserProfilePersonalDetailsData';

const OnboardingApplication = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let onboardingId = queryParams.get('onboardingId');
  const encryptedOnboardingId = onboardingId; // Decrypt the ID
  onboardingId = decryptData(onboardingId, true);
  const onboarding_Id = parseInt(onboardingId!, 10);
  let dataId = queryParams.get('dataId');
  dataId = dataId ? decryptData(dataId, true) : 0;
  const data_Id = parseInt(dataId!, 10);
  const { setNotificationSuccess } = useNotificationContext();
  const pageTitle = 'Application Form';
  const [loading, setLoading] = useState<boolean>(true);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [survey, setSurvey] = useState<any>('');
  const onboardingUser = useCurrentOnboardingUserInfo();
  const [formData, setFormData] = useState<OnboardingTempplateReviewDataState>({
    id: 0,
    onboarding_id: 0,
    form_data: '',
    submitted: 0,
    error: ''
  });

  const type = localStorage.getItem('type');

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-onboarding-personal-details-render');
    localStorage.removeItem('survey-json-onboarding-temp-render');
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      formData.form_data
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.form_data)
      );
      localStorage.setItem(
        'survey-json-onboarding-personal-details-render',
        JSON.stringify(mergedResponse)
      );
      localStorage.setItem('survey-json-onboarding-temp-render', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.setItem('survey-json-onboarding-temp-render', templateData);
      localStorage.setItem('survey-json-onboarding-personal-details-render', templateData);
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();
      
      if (current_route == '/onboarding-application') {
        fetchTemplate();
      } else {
        fetchPersonalDetailsTemplate();
        fetchPersonalData();
      }
      fetchData();

      setTimeout(() => {
        setHasEffectRun(true);
      }, 800);
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun, current_route]);

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken, true)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });
      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const fetchPersonalDetailsTemplate = async () => {
    try {
      if (authToken) {
        const response: AdviserProfileTemplateState = (await fetchOnboardingPersonalDetailsTemplate(
          onboarding_Id,
          onboardingUser?.email,
          authToken,
          type
        )) as AdviserProfileTemplateState;

        if (response.id) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            localStorage.setItem('survey-json-onboarding-personal-details-render', form_data);
          } else {
            localStorage.removeItem('survey-json-onboarding-personal-details-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFound')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: OnboardingTemplateState = (await fetchOnboardingApplicationTemplate(
          onboarding_Id,
          onboardingUser?.email,
          authToken,
          type
        )) as OnboardingTemplateState;

        if (response.id) {
          const { form_data, application_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            localStorage.setItem('survey-json-onboarding-temp-render', form_data);
          } else {
            localStorage.removeItem('survey-json-onboarding-temp-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFound')
            }));
          }

          if (
            !dataId &&
            application_data &&
            current_route !== '/onboarding-personal-details' &&
            application_data.form_status
          ) {
            navigate(
              `/onboarding-application-review/${encryptData(
                String(application_data.id),
                true
              )}?onboardingId=${encryptedOnboardingId}`
            );
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPersonalData = async () => {
    try {
      if (authToken) {
        const response: any = (await fetchOnboardingPersonalDetailsData(
          onboardingUser && onboardingUser.user_id ? onboardingUser?.user_id : 0,
          onboarding_Id,
          authToken,
          onboardingUser ? true : false
        )) as any;
        if (response.id) {
          navigate(
            `/onboarding-application?onboardingId=${encryptData(String(onboardingId), true)}`
          );
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      if (authToken && dataId) {
        const response: any = (await fetchOnboardingApplicationData(
          data_Id,
          onboarding_Id,
          authToken,
          onboardingUser ? true : false
        )) as any;
        if (response.id) {
          setFormData(response);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const setSurveyData = () => {
    if (current_route == '/onboarding-application') {
      const surveyJson = localStorage.getItem('survey-json-onboarding-temp-render') || ''; // Provide an empty string as the default

      if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
        const surveyModel = new Model(surveyJson);
        surveyModel.showCompletedPage = false; // prevent the thank-you page
        surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
        surveyModel.isSinglePage = false; // show all pages in single
        surveyModel.pageNextText = 'Save and next';
        surveyModel.completeText = 'Save';
        surveyModel.focusFirstQuestionAutomatic = true;

        setSurvey(surveyModel);
      }
    } else {
      const surveyJson =
        localStorage.getItem('survey-json-onboarding-personal-details-render') || ''; // Provide an empty string as the default

      if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
        const surveyModel = new Model(surveyJson);
        surveyModel.showCompletedPage = false; // prevent the thank-you page
        surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
        surveyModel.isSinglePage = true; // show all pages in single
        surveyModel.completeText = 'Save';
        surveyModel.focusFirstQuestionAutomatic = true;

        setSurvey(surveyModel);
      }
    }
  };

  const filteredElements: any[] = [];
  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      let number: any;
      page.elements.forEach((element: any) => {
        const fieldName = element.name;

        if (fieldValues[fieldName] !== undefined || fieldName.trim().includes('guidance-')) {
          // Add the value to the element
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unusedVariable =
            fieldValues[fieldName] !== undefined
              ? (element.defaultValue = fieldValues[fieldName])
              : null;

          // Check if 'choices' array is present and not empty
          if (element.choices && element.choices.length > 0) {
            // Convert defaultValue to an array if it's not already
            const defaultValues = Array.isArray(element.defaultValue)
              ? element.defaultValue
              : [element.defaultValue];
            // Find the choices whose values are present in defaultValues
            const selectedChoices = element.choices.filter((choice: any) => {
              if (choice && choice.value) {
                return defaultValues.includes(choice.value);
              } else {
                return defaultValues.includes(choice);
              }
            });

            // Now you can use selectedChoices as needed
            selectedChoices.forEach((selectedChoice: any) => {
              // Your logic here
              const selectedText =
                selectedChoice && selectedChoice.text ? selectedChoice.text : selectedChoice;
              if (
                fieldName &&
                fieldName.trim().includes('check-') &&
                (selectedText.trim().toLowerCase() === 'yes with limitations' ||
                  selectedText.trim().toLowerCase() === 'no')
              ) {
                const match = fieldName.trim().match(/check-(\d+)/);
                number = match ? match[1] : null;
                const isDuplicate = filteredElements.some(
                  (existingElement) => existingElement.name === element.name
                );

                if (!isDuplicate) {
                  filteredElements.push(element);
                }
              }
            });
          }
          if (number) {
            const index = filteredElements.findIndex((element) =>
              element.name.includes(`check-${number}`)
            );
            if (element.type === 'html' && fieldName.trim().includes(`guidance-${number}`)) {
              if (index !== -1) {
                filteredElements[index].guidance = filteredElements[index].guidance || [];
                filteredElements[index].guidance.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`comment-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].comments = filteredElements[index].comments || [];
                filteredElements[index].comments.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`action-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].action = filteredElements[index].action || [];
                filteredElements[index].action.push({ element });
              }
            }
          }
        }
      });
    });

    return mainResponse;
  };

  const handleSurveySubmit = async (type = '') => {
    let validationResult = true;
    if (templateData && !type) {
      validationResult = survey.validate();
    }
    const saved = type === 'nextPage' ? 0 : 1;

    if (!validationResult) {
      return;
    }
    if (validationResult && survey) {
      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;

      const onbDataId = localStorage.getItem('onboarding-application-data-id');
      mergeFieldValues(JSON.parse(templateData), surveyData);

      if (current_route == '/onboarding-application') {
        const response: any = (await createOnboardingApplicationData(
          surveyData,
          onboarding_Id,
          authToken,
          onbDataId ? onbDataId : data_Id,
          onboardingUser ? true : false,
          saved
        )) as any;
        if (response && response?.id) {
          localStorage.setItem('onboarding-application-data-id', response?.id);
          if (!type) {
            localStorage.removeItem('survey-json-onboarding-temp-render');
            localStorage.removeItem('onboarding-application-data-id');
            const msg = data_Id
              ? t('success.field.onboardingApplicationDataUpdated')
              : t('success.field.onboardingApplicationDataCreated');
            setNotificationSuccess(msg);
            setHasEffectRun(false);
            navigate(
              `/onboarding-application-review/${encryptData(
                String(response.id),
                true
              )}?onboardingId=${encryptedOnboardingId}`
            );
          }
        }
      } else {
        const response: any = (await createAdviserProfilePersonalDetailsData(
          surveyData,
          onboardingUser && onboardingUser.user_id ? onboardingUser.user_id : '',
          authToken,
          onboardingUser ? true : false
        )) as any;
        if (response && response?.id) {
          if (!type) {
            localStorage.removeItem('survey-json-onboarding-personal-details-render');
            const msg = t('success.field.adviserProfilePersonalDetailsDataCreated');
            setNotificationSuccess(msg);
            setHasEffectRun(false);
            navigate(
              `/onboarding-application?onboardingId=${encryptData(String(onboardingId), true)}`
            );
          }
        }
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs pageTitle={pageTitle} />
          <div className="record-outer-row settings-outer-row">
            <OnboardingLeftMenu />
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : survey ? (
                  <div className="card-outline">
                    <Survey model={survey} />

                    {survey.PageCount > 1 && (
                      <div className="inner-btn-wrap">
                        <Dropdown survey={survey} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="no-record">Application form not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default OnboardingApplication;
