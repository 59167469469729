import axios from 'axios';
import { AccountDetailsState } from '../../views/components/settings/state';
import { AccountState } from '../../views/components/user/state';
import { localStorageKeys } from '../../utils/local-storage';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck, encryptData, dataEncrypt } from '../../utils/common/user';

export const accountDetails = async (
  formData: AccountDetailsState,
  accountInfo?: AccountState,
  authToken?: string
): Promise<string | undefined | null> => {
  const selectedAccountId = accountInfo?.id;
  const { name, prefix, color, image, is_bespoke, compliance_query_email, plan_level } = formData;
  let routeUrl;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('name', name);
    formData.append('prefix', prefix || '');
    formData.append('color', color || '');
    
    if (is_bespoke) {
      formData.append('is_bespoke', is_bespoke ? '1' : '0');
    }
    if (compliance_query_email) {
      formData.append('compliance_query_email', compliance_query_email);
    }

    if(plan_level){
      formData.append('plan_level', plan_level);
    }

    if (image) {
      formData.append('logo', image); // Attach the logo as a Blob
    }
    if (selectedAccountId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/accounts/${dataEncrypt(selectedAccountId?.toString() ?? '0')}/update`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/accounts/create`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      localStorage.setItem(
        localStorageKeys.SELECTED_ACCOUNT,
        encryptData(JSON.stringify(response.data.data))
      );

      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
