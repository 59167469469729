import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { TemplatesState, AccountState } from '../../views/components/user/state';

export const fetchTemplateFromRecordType = async (
  authToken?: string,
  accountInfo?: AccountState,
  source_id?: number,
  recordId?: number,
): Promise<TemplatesState | string | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  routeUrl = `${process.env.REACT_APP_API_URL}/fetch-template-register/${dataEncrypt(source_id?.toString() ?? '0')}?accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  if (recordId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-template-record/${dataEncrypt(recordId?.toString() ?? '0')}?accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
