import {
  AccountState,
  AccountTemplateState,
  PaginationResponse
} from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchReviewTemplate = async (
  authToken: string,
  accountInfo?: AccountState,
  reviewId?: number,
  moduleId?: number
): Promise<PaginationResponse | AccountTemplateState[] | string | undefined> => {
  const selectedAccountId = accountInfo?.id;

  let routeUrl;
  routeUrl = `${process.env.REACT_APP_API_URL}/review-templates/${dataEncrypt(reviewId?.toString() ?? '0')}?accountId=${selectedAccountId}&sourceId=${moduleId}`;
  
  if (reviewId && !moduleId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-template-review/${reviewId}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();
    
    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
