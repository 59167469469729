import { PaginationResponse, FileReviewState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchFileReviews = async (
  authToken?: string,
  reviewId?: number,
  filereviewId?: number,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  fetchStatus: boolean = false,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | FileReviewState | string | undefined> => {
  let routeUrl;
  if (!filereviewId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/file-reviews-list?review_id=${reviewId}&page=${currentPage}&perPage=${itemsPerPage}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
    if (fetchStatus) {
      routeUrl = `${process.env.REACT_APP_API_URL}/file-reviews-list?review_id=${reviewId}&fetchStatus=1`; // Include pagination parameters
    }

  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/file-reviews-list/${dataEncrypt(filereviewId?.toString())}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;

    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};

