import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import PoliciesLeftMenu from './PoliciesLeftMenu';
import { SETTINGS, TASK_CREATE, TASK_UPDATE } from '../../../../constants/constants';
import AddTasks from '../../tasks/AddTasks';

const PoliciesTasksAddEdit = () => {
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let policyId = queryParams.get('policyId');
  const encryptedPolicyId = policyId; // Decrypt the ID
  policyId = decryptData(policyId, true) || ''; // Default to an empty string
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = id ? 'Edit Policy Task' : 'Add Policy Task';
  let secondTitle;
  const unique_id = localStorage.getItem('policy_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/settings/policies/details/${encryptedPolicyId}`;
  const thirdTitle = 'Tasks';
  const thirdPath = `/settings/policies/tasks?recordId=${encryptedPolicyId}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  useEffect(() => {
    const permission = id ? TASK_UPDATE : TASK_CREATE;
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 7, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <PoliciesLeftMenu
                  user={user}
                  policyId={policyId ? policyId : ''}
                  encryptedPolicyId={encryptedPolicyId}
                />
                <AddTasks
                  moduleType="policies"
                  sourceId={''}
                  recordId={policyId}
                  localKey="policyTasksCount"
                  listUrl={`/settings/policies/tasks?policyId=${encryptedPolicyId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default PoliciesTasksAddEdit;
