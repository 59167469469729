import React, { useEffect, useState } from 'react';
import { PrevetState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { format } from 'date-fns';
import { encryptData } from '../../../../utils/common/user';

export interface AdviserProfilePreVetsProps {
  loading: boolean;
  prevetRecords: PrevetState[];
}

const AdviserProfilePreVets: React.FC<AdviserProfilePreVetsProps> = ({
  loading,
  prevetRecords
}) => {
  const navigate = useNavigate();
  const [dense] = useState(false);
  const [orderBy, setOrderBy] = useState<keyof PrevetState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [prevets, setPrevets] = useState<PrevetState[]>([]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof PrevetState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'unique_id',
      numeric: false,
      disablePadding: true,
      label: 'ID'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'adviser_id',
      numeric: false,
      disablePadding: false,
      label: 'Adviser'
    },
    {
      id: 'reviewer_id',
      numeric: false,
      disablePadding: false,
      label: 'Reviewer'
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Subject'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Status'
    }
  ];

  useEffect(() => {
    setPrevets(prevetRecords);
  }, [prevetRecords]);

  const handleSort = (property: keyof PrevetState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    // Sort the registers array based on the selected criteria
    const sortedRegisters = [...prevets].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      // Handle sorting for numbers and strings separately
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });

    // Update the registers state with the sorted array
    setPrevets(sortedRegisters);
  };

  const redirectEditPage = (rowid: number, moduleId: number) => {
    navigate(
      `/prevet/${encryptData(String(rowid), true)}?source=${encryptData(String(moduleId), true)}`
    );
  };

  return (
    <div className="card-outline">
      <div className="card-title-wrap full-width secondary-title-wrap">
        <div className="title-full-width">
          <h3>Pre-Vets</h3>
        </div>
      </div>

      <div className="row-wrap full-width">
        <TableContainer>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : prevets.length === 0 ? (
            <div className="no-record-adviser-incident">There are no pre-vets</div>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="table hover-table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`key-${headCell.id}`}
                      align={headCell.numeric ? 'left' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}>
                      {headCell.id === 'unique_id' ? (
                        headCell.label // Render the label without sorting for the "id" column
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleSort(headCell.id)}>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={{ display: 'none' }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prevets.map((row: PrevetState) => (
                  <TableRow key={row.id} onClick={() => redirectEditPage(row.id, row.module_id)}>
                    <TableCell scope="row" className="unique-id-width">
                      {row.unique_id}
                    </TableCell>
                    {row.created_at ? (
                      <TableCell align="left">
                        {format(new Date(row.created_at), 'd/MMM/yyyy')}
                      </TableCell>
                    ) : (
                      <TableCell align="left">N/A</TableCell>
                    )}
                    <TableCell align="left">
                      <Grid container alignItems="center" flexWrap="nowrap">
                        <Grid item>
                          {row?.adviser?.profile_image ? (
                            <Avatar
                              src={`${JSON.parse(row.adviser.profile_image).path}`}
                              alt={row?.adviser ? row?.adviser.first_name : 'user'}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.style.display = 'none'; // Hide the image
                                const nextElement = target.nextElementSibling as HTMLElement | null;
                                if (nextElement) {
                                  nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                }
                              }}
                            />
                          ) : (
                            <Avatar alt={row?.adviser?.first_name} src="." />
                          )}
                        </Grid>
                        <Grid item className="content">
                          <p>{row?.adviser?.first_name + ' ' + row?.adviser?.last_name}</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="left">
                      <Grid container alignItems="center" flexWrap="nowrap">
                        <Grid item>
                          {row?.reviewer?.profile_image ? (
                            <Avatar
                              src={`${JSON.parse(row.reviewer.profile_image).path}`}
                              alt={row?.reviewer ? row?.reviewer.first_name : 'user'}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.style.display = 'none'; // Hide the image
                                const nextElement = target.nextElementSibling as HTMLElement | null;
                                if (nextElement) {
                                  nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                }
                              }}
                            />
                          ) : (
                            <Avatar alt={row?.reviewer?.first_name} src="." />
                          )}
                        </Grid>
                        <Grid item className="content">
                          <p>{row?.reviewer?.first_name + ' ' + row?.reviewer?.last_name}</p>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="left">{row.subject}</TableCell>
                    <TableCell align="left" className={row.is_open ? 'open' : 'closed'}>
                      <span>{row.is_open ? 'Open' : 'Archived'}</span>
                    </TableCell>
                    <TableCell align="left" className="right-arrow-width">
                      <div className="table-btn">
                        <img src={rightarrow} alt="Right Arrow" />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default AdviserProfilePreVets;
