import axios from 'axios';
import { ApiResponse } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { format } from 'date-fns';
import { dataEncrypt } from '../../utils/common/user';
import { ASIC_IDR_REPORT, COMPLAINT_IDR_REPORT } from '../../constants/constants';

export const downloadReportCsv = async (
  registerId: number,
  accountInfo: AccountState,
  authToken: string,
  title: string
) => {
  try {
    const selectedAccountId = accountInfo?.id;
    const routeUrl = `${process.env.REACT_APP_API_URL}/records-export?registerId=${dataEncrypt(registerId?.toString())}&accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
    const responseType = 'blob';
    const response = await axios.get(routeUrl, {
      headers: {
        'Content-Type': 'application/json',
        responseType,
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response) {
      const header = { type: 'text/csv' };
      const extension = 'csv';
      const blob = new Blob([response.data], header);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download =
        accountInfo.name.replace(/\s/g, '') +
        '-' +
        title.toLowerCase().replace(/\s/g, '') +
        '-' +
        format(new Date(), 'ddMMyyyy-hhmm') +
        `.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return response;
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const downloadReport = async (
  registerId: number,
  accountInfo: AccountState,
  authToken: string,
  title: string
) => {
  try {
    const selectedAccountId = accountInfo?.id;
    const routeUrl = `${process.env.REACT_APP_API_URL}/records-export-excel?registerId=${dataEncrypt(registerId?.toString())}&accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&title=${title}`;

    const response = await fetch(routeUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });
    const extension = 'xlsx';
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));

    let filename;
    const accountName = accountInfo.name.replace(/\s/g, '');

    if (title == COMPLAINT_IDR_REPORT) {
      filename = `${accountName}-${ASIC_IDR_REPORT}.${extension}`;
    } else {
      filename = `${accountName}-${title.toLowerCase().replace(/\s/g, '')}-${format(new Date(), 'ddMMyyyy-hhmm')}.${extension}`;
    }

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return response;
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
