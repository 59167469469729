import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { t } from 'i18next';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import OnboardingLeftMenu from './OnboardingLeftMenu';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import {
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
  // checkUserPermission
  // encryptData
} from '../../../../utils/common/user';
import { useNotificationContext } from '../../layout/NotificationContext';
import CreateEditFormData from './CreateEditFormData';
import {
  RolesResponse,
  TimezoneResponse,
  UserFormData,
  UserUpdateCreateResponseError
} from '../../settings/state';
// import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';
import { fetchTimezones } from '../../../../api/dashboard/fetchTimezones';
import { fetchUserData } from '../../../../api/settings/fetchSettingUsersListing';
import { fetchRoles } from '../../../../api/dashboard/fetchRoles';
import { saveUser } from '../../../../api/settings/saveUser';
import { SelectChangeEvent } from '@mui/material';

const OnboardingUser = () => {
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const authToken = useAuthToken();
  const { setNotificationSuccess } = useNotificationContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  let onboardingId = queryParams.get('onboardingId');
  const encryptedonboardingId = onboardingId; // Decrypt the ID
  onboardingId = decryptData(encryptedonboardingId, true);
  const onboarding_id = parseInt(onboardingId!, 10);
  let userId = queryParams.get('userId');
  const encrypteduserId = userId; // Decrypt the ID
  userId = decryptData(encrypteduserId, true);
  const user_id = parseInt(userId!, 10);
  const isEditing = !!user_id;
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  // const source_id = parseInt(sourceId!, 10);
  const [loading, setLoading] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>('');
  const [timezoneOptionsWithValueLabel, setTimezoneOptionsWithValueLabel] = useState<
    TimezoneResponse[]
  >([]);
  const [role, setRole] = useState<string>('');
  const [roleValueLabel, setRoleValueLabel] = useState<RolesResponse[]>([]);
  const [updateLogo, setUpdateLogo] = useState<Blob | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [imageChanged, setImageChanged] = useState(false);

  const [formData, setFormData] = useState<UserFormData>({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_image: null,
    timezone: '',
    is_open: true,
    isAdviser: true,
    lu_user_access_level_id: '',
    error: ''
  });

  const { moduleRecords } = useModuleContext();
  let mainTitle;
  const mainPath = `/onboarding/${encryptedSourceId}`;
  if (sourceId) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }
  let secondTitle;
  const unique_id = localStorage.getItem('onboarding_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/onboarding/details/${encryptedonboardingId}?source=${encryptedSourceId}`;
  const pageTitle = 'Create User';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setImageChanged(true);
    if (selectedFile) {
      setUpdateLogo(selectedFile);
      setFormData((prevData) => ({ ...prevData, profile_image: selectedFile }));
    } else {
      setUpdateLogo(null);
    }
  };

  const handleSwitchChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFormData((prevData) => ({ ...prevData, [fieldName]: checked }));
    };

  const CancelRequest = () => {
    navigate(secondpath);
  };

  const removeHandle = () => {
    setImageChanged(true);
    setFormData((prevData) => ({ ...prevData, profile_image: null }));
    setUpdateLogo(null);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.first_name) {
      newErrors.first_name = t('error.field.firstname');
    }
    if (!formData.last_name) {
      newErrors.last_name = t('error.field.lastname');
    }
    if (!formData.email) {
      newErrors.email = t('error.field.email');
    }

    if (!role) {
      newErrors.lu_user_access_level_id = t('error.field.userRole');
    }

    if (!timezone) {
      newErrors.timezone = t('error.field.timezone');
    }

    // const phoneRegex = /^[0-9]{10}$/;
    // if (formData.phone && !phoneRegex.test(formData.phone)) {
    //   newErrors.phone = t('error.field.phone');
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();

    const isFormValid = validateForm();
    if (!isFormValid) {
      setLoading(false);
      return;
    }

    // Prepare the data to send to the API
    const requestData = {
      id: formData.id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      profile_image: updateLogo,
      timezone,
      is_open: formData.is_open,
      isAdviser: formData.isAdviser,
      lu_user_access_level_id: role,
      error: ''
    };
    const response: UserUpdateCreateResponseError = (await saveUser(
      requestData,
      authToken,
      user,
      selectedAccount,
      imageChanged,
      true
    )) as UserUpdateCreateResponseError;
    if (response) {
      if (response == t('permission.doesNotHaveAccessPermission')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('permission.doesNotHaveAccessPermission')
        }));
      } else if (response == t('user.maxUserReached')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('user.maxUserReached')
        }));
      } else {
        setLoading(false);
        const newErrors: Record<string, string> = {};
        if (response.first_name) {
          newErrors.first_name = response.first_name[0];
        } else if (response.last_name) {
          newErrors.last_name = response.last_name[0];
        } else if (response.email) {
          newErrors.email = response.email[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      }
    } else {
      setLoading(false);
      const msg = formData.id ? t('success.field.userUpdated') : t('success.field.userCreated');
      setNotificationSuccess(msg);
      navigate(secondpath);
    }
  };

  const handleChangeTimezone = (event: SelectChangeEvent<string>) => {
    setTimezone(event.target.value);
  };

  const handleChangeRole = (event: SelectChangeEvent<string>) => {
    setRole(event.target.value);
  };

  // useEffect(() => {
  //   const permission = onboarding_id ? UPDATE : CREATE;
  //   // for check this page permission
  //   if (!checkUserPermission(user, SETTINGS, 2, permission)) {
  //     navigate('/dashboard');
  //   }
  //   setIsAccessable(true);
  // }, []);

  useEffect(() => {
    fetchTimezonesApi();
    fetchUserDataApi();
    fetchRolesApi();
  }, [isEditing, onboarding_id, authToken]);

  const fetchUserDataApi = async () => {
    try {
      if (isEditing && user_id) {
        const userData = await fetchUserData(1, '', '1', authToken, selectedAccount, user_id);
        setFormData({
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
          timezone: userData.timezone || '',
          is_open: userData.is_open === 1,
          isAdviser:
            userData.user_access && userData.user_access.length > 0
              ? !!userData.user_access[0].isAdviser
              : false,

          lu_user_access_level_id: userData.lu_user_access_level_id
            ? userData.lu_user_access_level_id
            : '',
          profile_image: userData.profile_image || null
        });

        if (userData.timezone) {
          setTimezone(userData.timezone);
        }
        if (userData.lu_user_access_level_id) {
          setRole(userData.lu_user_access_level_id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTimezonesApi = async () => {
    try {
      const response: TimezoneResponse[] = await fetchTimezones();
      if (response) {
        setTimezoneOptionsWithValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching timezones:', error);
    }
  };

  const fetchRolesApi = async () => {
    try {
      const response: RolesResponse[] = await fetchRoles();
      if (response) {
        setRoleValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            secondTitle={secondTitle}
            secondpath={secondpath}
            title={pageTitle}
            pageTitle={pageTitle}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <OnboardingLeftMenu
              user={user}
              onboardingId={onboardingId ? onboardingId : ''}
              sourceId={sourceId ? sourceId : ''}
              onboardingIdEncrypted={encryptedonboardingId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="form-wrap">
                  <CreateEditFormData
                    user={user!}
                    formData={formData}
                    imageInputRef={imageInputRef}
                    handleChangeTimezone={handleChangeTimezone}
                    handleChangeRole={handleChangeRole}
                    handleInputChange={handleInputChange}
                    handleFileChange={handleFileChange}
                    handleSwitchChange={handleSwitchChange}
                    CancelRequest={CancelRequest}
                    onSubmit={handleSubmit}
                    removeHandle={removeHandle}
                    timezoneOptionsWithValueLabel={timezoneOptionsWithValueLabel}
                    roleValueLabel={roleValueLabel}
                    timezone={timezone}
                    role={role}
                    updateLogo={updateLogo}
                    errors={errors}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default OnboardingUser;
