import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { CircularProgress } from '@mui/material';
import { Survey } from 'survey-react-ui';
import Dropdown from './Dropdown';
import { useLocation } from 'react-router';
import { decryptData, getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { AttestationTemplatesState } from './state';
import { fetchAttestationTemplate } from '../../../../api/attestations/fetchAttestationTemplate';

const AttestationTemplatePreview = () => {
  const authToken = useAuthToken();
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const selectedAccount = getSelectedAccountInfo();
  const accountId = selectedAccount?.id;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_id = parseInt(sourceId!, 10);
  const mainPath = `/attestations/${encryptedSourceId}`;
  const pageTitle = 'Preview Attestation';
  const secondTitle = 'Attestations';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [loading, setLoading] = useState<boolean>(true);

  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<string>('');
  const [survey, setSurvey] = useState<any>('');

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-attestation-template');
    if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.setItem('survey-json-attestation-template', templateData);
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();

      fetchTemplate();
      setTimeout(() => {
        setHasEffectRun(true);
      }, 800);
    }

    setSurveyData();
  }, [templateData, hasEffectRun, current_route]);

  const fetchTemplate = async () => {
    try {
      if (sourceId && authToken) {
        const response: AttestationTemplatesState = (await fetchAttestationTemplate(
          source_id,
          accountId,
          authToken
        )) as AttestationTemplatesState;

        if (response) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            localStorage.setItem('survey-json-attestation-template', form_data);
          } else {
            localStorage.removeItem('survey-json-attestation-template');
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const setSurveyData = () => {
    const surveyJson = localStorage.getItem('survey-json-attestation-template') || ''; // Provide an empty string as the default

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showNavigationButtons = false; // Hide the default navigation buttons
      surveyModel.mode = 'display';
      // console.log(surveyModel)
      setSurvey(surveyModel);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={pageTitle}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            button={undefined}
          />{' '}
          <div className="record-outer-row settings-outer-row">
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : survey ? (
                  <div className="card-outline">
                    <Survey model={survey} />

                    {survey.PageCount > 1 && (
                      <div className="inner-btn-wrap">
                        <Dropdown survey={survey} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="no-record">Attestation form not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default AttestationTemplatePreview;
