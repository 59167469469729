import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchAccounts = async (userId: number, authToken?: string): Promise<AccountState[]> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/${dataEncrypt(userId.toString())}/accounts`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    const responseData: ApiResponseType = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching accounts';
  }
};
