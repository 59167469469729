import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Grid
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FileReviewTemplatesState } from '../../user/state';
import { fetchFileReviewTemplatesFiltered } from '../../../../api/adviser/fetchFileReviewTemplatesFiltered';
import { exportClientFileData } from '../../../../api/reporting/exportClientFileData';

const ExportClientFileReview = () => {
  const title = 'Client File Review';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Reports';
  const thirdTitle = 'Export';
  const secondpath = '/dashboard';
  const pageTitle = `Export ${title}`;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [fromDate, setFromDate] = useState<string>('');
  // const [toDate, setToDate] = useState<string>(''); // Commented out the toDate state
  const [templateselect, settemplateselect] = useState<string>('');
  const [fileReviewTemplates, setFileReviewTemplates] = useState<FileReviewTemplatesState[]>([]);
  const [noDataMessage, setNoDataMessage] = useState<string>('');

  useEffect(() => {
    fetchFileReviewTemplatesdata();
  }, []);

  const handleFromDateChange = (date: dayjs.Dayjs | null): void => {
    const formattedDate = date ? date.format('YYYY-MM') : '';
    setFromDate(formattedDate);
  };

  // Commented out handleToDateChange function
  // const handleToDateChange = (date: dayjs.Dayjs | null): void => {
  //   const formattedDate = date ? date.format('YYYY-MM') : '';
  //   setToDate(formattedDate);
  // };

  const handleChangetemplate = (event: SelectChangeEvent<string>) => {
    settemplateselect(event.target.value);
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!templateselect) newErrors.templateselect = 'Please select a template';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchFileReviewTemplatesdata = async () => {
    try {
      if (authToken) {
        const response: FileReviewTemplatesState[] = (await fetchFileReviewTemplatesFiltered(
          authToken,
          selectedAccount,
          0,
          0,
          0,
          0,
          true
        )) as FileReviewTemplatesState[];

        if (response) {
          if (Array.isArray(response) && response.length > 0) {
            setFileReviewTemplates(response);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const fetchClientFileReview = true;
    const selectedMenu = 'MOD-1'; // adviser-review-id
    if (validateForm()) {
      setLoading(true);
      try {
        if (authToken) {
          // Pass null for toDate in the export ClientFile Data function
          const response = await exportClientFileData(
            authToken,
            selectedAccount,
            selectedMenu,
            fromDate,
            undefined, // Pass null instead of toDate
            templateselect,
            fetchClientFileReview
          );
          if (response.status === 204) {
            setNoDataMessage('No data available for the selected date range.');
          } else {
            setNoDataMessage('');
          }
        }
      } catch (error: any) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            thirdTitle={thirdTitle}
            secondpath={secondpath}
          />
          <div className="record-outer-row settings-outer-row">
            <div className="settings-sidebar-wrap ">
              <nav className="settings-sidebar">
                <div className="sidebar-inner">
                  Enter the export criteria and click &apos;Export&apos; to export data to Excel
                </div>
              </nav>
            </div>
            <div className="right-outer">
              <div className="right-inner">
                <form onSubmit={handleSubmit}>
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Criteria</h3>
                    </div>
                    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Select template</label>
                          <FormControl variant="outlined" error={!!errors.templateselect}>
                            <Select
                              error={!!errors.templateselect}
                              onChange={handleChangetemplate}
                              labelId="Complaint-owner-label"
                              id="Complaint-owner"
                              value={templateselect}
                              IconComponent={KeyboardArrowDownRoundedIcon}
                              className="custom-select"
                              MenuProps={{ className: 'custom-dropdown-menu' }}
                              displayEmpty
                              renderValue={(selected) => {
                                if (selected === '') {
                                  return <p className="dropdown-placeholder">Select template</p>;
                                }
                                const selectedOption =
                                  fileReviewTemplates &&
                                  fileReviewTemplates.find(
                                    (option) => option.id === parseInt(selected, 10)
                                  );
                                return selectedOption ? `${selectedOption.name}` : '';
                              }}>
                              {fileReviewTemplates.map((row: FileReviewTemplatesState) => (
                                <MenuItem key={row?.id} value={row?.id || ''}>
                                  {row?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {errors.templateselect && (
                            <Typography variant="caption" color="error">
                              {errors.templateselect}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Date review finalised</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['year', 'month']}
                               className="form-control date-picker"
                              value={fromDate ? dayjs(fromDate) : null}
                              onChange={(date) => handleFromDateChange(date)}
                              minDate={dayjs('2000-01')}
                              maxDate={dayjs()}
                              format="YYYY-MM"
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                      {/* Commented out toDate */}
                      {/* <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Month and Year to</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['year', 'month']}
                              value={toDate ? dayjs(toDate) : null}
                              onChange={(date) => handleToDateChange(date)}
                              minDate={dayjs('2000-01')}
                              maxDate={dayjs()}
                              format="YYYY-MM"
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid> */}
                    </Grid>
                    {noDataMessage && (
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: '1rem', color: '#f04438' }}>
                        {noDataMessage}
                      </Typography>
                    )}
                    <div className="btn-wrap" style={{ textAlign: 'center', marginTop: '20px' }}>
                      {loading && (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      )}
                      <Button className="btn primary-btn btn-sm" type="submit" disabled={loading}>
                        Export
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default ExportClientFileReview;
