import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { PaginationResponse, UserState } from '../../user/state';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission
} from '../../../../utils/common/user';
import { CalendarFormData } from './state';
import { getCalendarEventId } from '../../../../api/calendar/getCalendarEvents';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import { format } from 'date-fns';
import { CalenderEventState } from '../../user/state';
import { MODULES, NOTE_READ, TASK_READ } from '../../../../constants/constants';

interface LeftMenuProps {
  user: UserState | undefined;
  moduleId?: string;
  sourceId?: string;
  formData_record?: CalendarFormData;
  calendarIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const CalenderLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  moduleId,
  sourceId,
  formData_record,
  calendarIdEncrypted,
  sourceIdEncrypted
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();
  const module_type = 'calendar_events';
  const { moduleRecords } = useModuleContext();
  const taskUrl = `/calendar/tasks?source=${sourceIdEncrypted}&moduleId=${calendarIdEncrypted}`;
  const notesUrl = `/calendar/notes?source=${sourceIdEncrypted}&moduleId=${calendarIdEncrypted}`;
  const detailsUrl = `/calendar/${calendarIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/calendar-event-edit/${calendarIdEncrypted}?source=${sourceIdEncrypted}`;
  const currentModuleId = sourceId ? parseInt(sourceId, 10) : 0;

  const foundModule =
    Array.isArray(moduleRecords) && moduleRecords.length > 0
      ? moduleRecords.find((module) => module.id === currentModuleId)
      : '';
  const instructions =
    foundModule && foundModule.instructions != null && foundModule.instructions != ''
      ? foundModule?.instructions
      : t('calendar.addMenuMsg');

  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('calendarNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('calendarTasksCount') || ''
  );

  const [formData, setFormData] = useState<CalendarFormData>({
    id: 0,
    module_id: parseInt(sourceId!, 10),
    subject: '',
    event_date: '',
    is_open: 1,
    owner_id: ''
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (
        Array.isArray(moduleRecords) &&
        moduleRecords.length > 0 &&
        ((sourceId && !foundModule) || !sourceId)
      ) {
        navigate('/404');
      }
      if (moduleId && authToken) {
        const editId = parseInt(moduleId!, 10);

        const response: CalenderEventState = (await getCalendarEventId(
          0,
          authToken,
          selectedAccount,
          editId
        )) as CalenderEventState;

        if (response) {
          const { id, subject, event_date, owner_id, module_id, is_open, unique_id } = response;

          const newFormData = {
            id,
            subject,
            event_date: event_date ?? '',
            owner_id,
            is_open,
            module_id,
            unique_id
          };
          setFormData(newFormData);
        }
      } // <- Missing closing brace for the if statement
    } catch (error) {
      console.error('Something Wrong');
    }
  };
  const fetchNotesCount = async () => {
    localStorage.removeItem('calendarNotesCount');
    try {
      if (authToken && moduleId) {
        const record_Id = parseInt(moduleId, 10);
        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          currentModuleId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('calendarNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('calendarTasksCount');
    try {
      if (authToken && moduleId) {
        const record_Id = parseInt(moduleId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          currentModuleId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;

        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('calendarTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    }

    if (!localStorage.getItem('calendarNotesCount')) {
      fetchNotesCount();
    }

    if (!localStorage.getItem('calendarTasksCount')) {
      fetchTasksCount();
    }
  }, [sourceId, formData_record]);

  // URLs exclude from the menu
  const excludedUrls = [
    `/calendar-event-add`,
    `/calendar/add-note`,
    `/calendar/edit-note/${id}`,
    `/calendar/add-task`,
    `/calendar/edit-task/${id}`,
    `/calendar-edit/${id}`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/compliance-calendar/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/calendar-event-add' && (
              <span className="inner-text-wrap">{instructions}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/calendar-event-add' && (
                <div className="comp-uder-detail">
                  {formData_record || formData ? (
                    <>
                      <div className="comp-uder-detail-list">
                        <h6>ID</h6>
                        <p>{formData_record?.unique_id || formData?.unique_id}</p>
                      </div>
                      <div className="comp-uder-detail-list">
                        <h6>Start date</h6>
                        <p>
                          {(formData_record?.event_date &&
                            format(new Date(formData_record?.event_date), 'd/MMM/yyyy')) ||
                            (formData?.event_date &&
                              format(new Date(formData?.event_date), 'd/MMM/yyyy'))}
                        </p>
                      </div>
                      <div className="comp-uder-detail-list">
                        <h6>Subject</h6>
                        <p>{formData_record?.subject || formData?.subject}</p>
                      </div>
                      <div className="comp-uder-detail-list">
                        <h6>Status</h6>
                        <p>
                          {formData && formData.id && formData.id > 0
                            ? formData.is_open === 1
                              ? 'Open'
                              : 'Archived'
                            : formData_record && formData_record.is_open === 1
                              ? 'Open'
                              : 'Archived'}
                        </p>
                      </div>
                    </>
                  ) : (
                    <p></p>
                  )}
                </div>
              )}

              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};
export default CalenderLeftMenu;
