import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/authSlice';
import accountReducer from './account/accountSlice';
import moduleReducer from './modules/moduleSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer,
    module: moduleReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
