import { OnboardingTempplateReviewDataState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchOnboardingApplicationData = async (
  onboardingApplicationId: number,
  onbaordingId: number,
  authToken: string,
  typeOnboarding?: boolean
): Promise<OnboardingTempplateReviewDataState | string | undefined> => {
  let routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-application-template-data/${dataEncrypt(onboardingApplicationId?.toString())}?onboardingId=${dataEncrypt(onbaordingId?.toString())}`; // Include pagination parameters
  if (typeOnboarding) {
    routeUrl += `&type=onboardingUser`;

  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
};

