import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Avatar,
  Grid,
  Switch
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { ProviderReviewState } from '../../user/state';
import { format } from 'date-fns';
import PaginationSettings from '../../settings/Pagination';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import trashGrey from '../../../../assets/images/trash-grey.svg';
import { ProvidersDataFormState } from './state';
import { checkUserPermission, decryptData, encryptData } from '../../../../utils/common/user';
import { UserState } from './../../user/state.d';
import { DELETE, REGISTERS } from '../../../../constants/constants';

interface ReviewProps {
  user: UserState | undefined;
  formData?: ProvidersDataFormState;
  handleReviewStatusSubmit: (id: number, checked: boolean) => void;
  setOpenConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setProviderReviewId: React.Dispatch<React.SetStateAction<number>>;
}

const ProviderReviewListing: React.FC<ReviewProps> = ({
  user,
  formData,
  handleReviewStatusSubmit,
  setOpenConfirmationPopup,
  setProviderReviewId
}) => {
  const providerReviews = formData?.provider_reviews;
  const navigate = useNavigate();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const title = 'Contract Reviews';
  const [orderBy, setOrderBy] = useState<keyof ProviderReviewState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [providerReview, setProviderReview] = useState<ProviderReviewState[]>([]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof ProviderReviewState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Date'
    },
    {
      id: 'reviewer_id',
      numeric: false,
      disablePadding: true,
      label: 'Reviewer'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Completed'
    }
  ];

  const handleSort = (property: keyof ProviderReviewState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    // Sort the registers array based on the selected criteria
    const sortedRegisters = [...providerReview].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      // Handle sorting for numbers and strings separately
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });
    setProviderReview(sortedRegisters);
  };

  const redirectEditPage = (rowid: number) => {
    navigate(
      `/providers/review-edit/${encryptData(String(rowid), true)}?source=${encryptedSourceId}&provider=${encryptedId}`
    );
  };

  const redirectDetailPage = (rowid: number) => {
    navigate(
      `/providers/review/${encryptData(String(rowid), true)}?source=${encryptedSourceId}&provider=${encryptedId}`
    );
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSwitchChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    handleReviewStatusSubmit(id, checked);
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-adv-review-template');
    if (providerReviews && providerReviews.data) {
      if (Array.isArray(providerReviews.data)) {
        setProviderReview(providerReviews.data);
      }
      setTotalPages(providerReviews.last_page);
      setLoading(false);
    }
    setLoading(false);
  }, [providerReviews, id, currentPage, setProviderReview]);

  let addButton = null;
  if (formData && formData.is_open === 1) {
    addButton = (
      <div className="inner-btn-wrap">
        <Button
          className="btn primary-btn"
          component={RouterLink}
          to={`/providers/review-add?source=${encryptedSourceId}&provider=${encryptedId}`}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_6545_879)">
                <path
                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6545_879">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Add Review
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="title-wrapper card-title-wrap secondary-title-wrap">
        <div>
          <h4>{title}</h4>
        </div>
        <div className="inner-btn-wrap">{addButton}</div>
      </div>
      <TableContainer>
        {loading ? (
          <div className="no-record">
            <CircularProgress />
          </div>
        ) : providerReview.length === 0 ? (
          <div className="no-record">No Review is found.</div>
        ) : (
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            className="table hover-table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={`key-${headCell.id}`}
                    align={headCell.numeric ? 'left' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}>
                    {headCell.id === 'created_at' ? (
                      headCell.label // Render the label without sorting for the "id" column
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSort(headCell.id)}>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={{ display: 'none' }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerReview.map((data: ProviderReviewState) => (
                <TableRow key={data.id}>
                  {data.created_at ? (
                    <TableCell align="left">
                      {format(new Date(data.created_at), 'd/MMM/yyyy')}
                    </TableCell>
                  ) : (
                    <TableCell align="left">N/A</TableCell>
                  )}
                  <TableCell align="left">
                    <Grid container alignItems="center" flexWrap="nowrap">
                      <Grid item>
                        {data?.reviewer && data?.reviewer?.profile_image ? (
                          <Avatar
                            src={`${JSON.parse(data.reviewer.profile_image).path}`}
                            alt={data?.reviewer ? data?.reviewer?.first_name : 'reviewer'}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.style.display = 'none';
                              const nextElement = target.nextElementSibling as HTMLElement | null;
                              if (nextElement) {
                                nextElement.style.display = 'block';
                              }
                            }}
                          />
                        ) : (
                          <Avatar alt={data?.reviewer?.first_name} src="." />
                        )}
                      </Grid>
                      <Grid item className="content">
                        <p>{data?.reviewer?.first_name + ' ' + data?.reviewer?.last_name}</p>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="left">
                    <div className="switch-wrap">
                      {data.is_open ? (
                        <>
                          <Switch
                            className={`custom-switch custom-switch-inside ${
                              data.is_open ? 'custom-switch-open' : 'custom-switch-complete'
                            } `}
                            checked={data.is_open ? false : true}
                            onChange={handleSwitchChange(data.id)}
                          />
                        </>
                      ) : (
                        <>
                          <Switch
                            className={`custom-switch custom-switch-inside ${
                              data.is_open ? 'custom-switch-open' : 'custom-switch-complete'
                            } `}
                            checked={data.is_open ? false : true}
                            onChange={handleSwitchChange(data.id)}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="left" className="right-arrow-width">
                    <div className="table-btn">
                      {checkUserPermission(user, REGISTERS, source_Id, DELETE) && (
                        <Button
                          className="edit"
                          onClick={() => {
                            setProviderReviewId(data.id);
                            setOpenConfirmationPopup(true);
                          }}>
                          <img src={trashGrey} alt="trashGrey" />
                        </Button>
                      )}
                      <Button
                        className="edit"
                        onClick={() =>
                          data.is_open == 1
                            ? redirectEditPage(data.id)
                            : redirectDetailPage(data.id)
                        }>
                        <img src={rightarrow} alt="Right Arrow" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {!loading && providerReview.length > 0 && (
          <PaginationSettings
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </TableContainer>
    </>
  );
};

export default ProviderReviewListing;
