import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CommonLayout from '../layout/CommonLayout';
import { t } from 'i18next';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const ForgotPasswordSuccess: React.FC = () => {
  const title = 'Forgot Password Success';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type') || '';

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              {type ? (
                <Link to="/onboarding-login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              ) : (
                <Link to="/login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              )}
              <div className="title-wrap">
                <h1>Password reset</h1>
                <p>Your password has been successfully reset, Click below to login.</p>
              </div>
              {type ? (
                <Button
                  variant="contained"
                  className="btn primary-btn"
                  to="/onboarding-login"
                  component={Link}>
                  Continue
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="btn primary-btn"
                  to="/login"
                  component={Link}>
                  Continue
                </Button>
              )}
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-reset-password.png`}
                  alt=""
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default ForgotPasswordSuccess;
