import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { PolicyState } from '../../user/state';
import { fetchPolicies } from '../../../../api/policies/fetchPolicies';
import PoliciesLeftMenu from './PoliciesLeftMenu';
import { SETTINGS, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import AddButton from '../../tasks/AddButton';
import TasksIndex from '../../tasks/Tasks';

const PoliciesTasks = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let policyId = queryParams.get('policyId');
  const encryptedPolicyId = policyId; // Decrypt the ID
  policyId = decryptData(policyId, true) || ''; // Default to an empty string
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const [currentStatus, setCurrentStatus] = useState<number>(1);

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Policy Tasks';
  let secondTitle;
  const unique_id = localStorage.getItem('policy_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/settings/policies/details/${encryptedPolicyId}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 7, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchPolicyData = async () => {
    try {
      if (policyId && authToken) {
        const editId = parseInt(policyId!, 10);

        const response: PolicyState = (await fetchPolicies(
          authToken,
          selectedAccount,
          editId
        )) as PolicyState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('policy_unique_id');
          localStorage.setItem('policy_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 7, TASK_CREATE) && (
        <AddButton addPath={`/settings/policies/add-task?policyId=${encryptedPolicyId}`} />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <PoliciesLeftMenu
                  user={user}
                  policyId={policyId ? policyId : ''}
                  encryptedPolicyId={encryptedPolicyId}
                />
                <TasksIndex
                  moduleType="policies"
                  sourceId={''}
                  recordId={policyId}
                  localKey="policy_unique_id"
                  fetchModuleData={fetchPolicyData}
                  editUrl={`/settings/policies/edit-task/rowId?policyId=${encryptedPolicyId}`}
                  currentStatus={currentStatus}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default PoliciesTasks;
