import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Button, SelectChangeEvent, CircularProgress } from '@mui/material';
import MeetingLeftMenu from './MeetingLeftMenu';
import MeetingCreateEditFormData from './MeetingCreateEditFormData';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '../../layout/NotificationContext';
import { MeetingState, MeetingsDataFormState } from './state';
import { createMeetingData, sendEmailAttendes } from '../../../../api/meeting/createMeetingData';
import { fetchMeetings } from '../../../../api/meeting/fetchMeetings';
import { localStorageKeys } from '../../../../utils/local-storage';
import {
  CREATE,
  DELETE,
  MODULES,
  NOTE_CREATE,
  READ,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
import { DropdownResponse, MeetingAgendasState } from '../../user/state';
import { fetchMeetingsAgendas } from '../../../../api/meeting/fetchMeetingsAgendas';
import { fetchMeetingTemplate } from '../../../../api/meeting/fetchMeetingTemplate';
import FinaliseMeetings from './FinaliseMeeting';
import { format } from 'date-fns';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';
import DeleteButton from '../../common/DeleteButton';
import Popup from '../../common/popup/Popup';
import { deleteMeeting } from '../../../../api/meeting/deleteMeeting';
import { finaliseMeeting } from '../../../../api/meeting/finaliseMeeting';

const MeetingCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const currentUserInfo = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [closed_at, setClosed_at] = useState<string | null>(null);
  const { moduleRecords } = useModuleContext();
  const { setNotificationSuccess } = useNotificationContext();
  const [isDisabled, setIsDisabled] = useState<boolean>(false); // Initially disabled if id is not available
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [meetingAddMode, setMeetingAddMode] = useState<boolean>(false); // Initially disabled if id is not available
  const [users, setUsers] = useState<DropdownResponse[]>([]);
  const date = format(new Date(), 'yyyy-MM-dd');
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  }

  const mainPath = `/meetings/${encryptedSourceId}`;
  const pageTitle = id ? `Meeting Details` : `Add Meeting`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [template, setTemplate] = useState<number>(0);
  const [agendas, setAgendas] = useState<MeetingAgendasState[]>([]);
  const [formData, setFormData] = useState<MeetingsDataFormState>({
    id: 0,
    module_id: source_Id,
    host_id: 0,
    scribe_id: 0,
    agenda_id: 0,
    meeting_date_time: '',
    meeting_location: '',
    webinar_link: '',
    description: '',
    attendee_ids: [],
    meeting_started: 0,
    finalised: false,
    finalised_by: {
      first_name: '',
      last_name: ''
    },
    meeting_report: '',
    is_open: 1,
    finalised_at: '',
    error: ''
  });

  useEffect(() => {
    const permission = current_route === `/meeting/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (
      subscriptonExpired ||
      (!checkUserPermission(currentUserInfo, MODULES, source_Id, permission) && currentUserInfo)
    ) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const HandlesnapshotIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      navigate(`/meeting-edit/${encryptedId}?source=${encryptedSourceId}`);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMeetingDateTimeInputChange = (datetime: any): void => {
    setFormData((prevData) => ({
      ...prevData,
      meeting_date_time: datetime.format('YYYY-MM-DD hh:mm A')
    }));
  };

  const handleComplete = async (status: number) => {
    const requestData = {
      ...formData,
      is_open: status
    };

    const edit_id = id ? id : formData.id;
    try {
      const response: any = await createMeetingData(
        requestData,
        template,
        authToken,
        selectedAccount,
        edit_id
      );

      if (response && response.id) {
        const msg = id ? t('success.field.meetingUpdated') : t('success.field.meetingCreated');

        if (response.id && current_route && current_route === `/meeting-add` && !meetingAddMode) {
          setErrors({});
          navigate(
            `/meeting/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
          );
        } else if (id || meetingAddMode) {
          setHasEffectRun(false);
          setNotificationSuccess(msg);
          setMeetingAddMode(false);
          navigate(
            `/meeting/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
          );
        } else {
          setNotificationSuccess(msg);
          navigate(`/meetings/${encryptedSourceId}`);
        }
      } else {
        const newErrors: Record<string, string> = {};

        if (response.host_id) {
          newErrors.host_id = response.host_id[0];
        } else if (response.scribe_id) {
          newErrors.scribe_id = response.scribe_id[0];
        } else if (response.agenda_id) {
          newErrors.agenda_id = response.agenda_id[0];
        } else if (response.meeting_date_time) {
          newErrors.meeting_date_time = response.meeting_date_time[0];
        } else if (response.meeting_location) {
          newErrors.meeting_location = response.meeting_location[0];
        } else if (response.webinar_link) {
          newErrors.webinar_link = response.webinar_link[0];
        } else if (response.attendees) {
          newErrors.attendees = response.attendees[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }

        setErrors(newErrors);
      }
    } catch (error) {
      // Handle other error cases
      console.error(error); // Log the error
      // You can handle other error cases as needed
    }
  };

  const fetchData = async () => {
    localStorage.removeItem(localStorageKeys.IS_SCRIBE);

    if (id && authToken) {
      try {
        const meetingId = parseInt(id!, 10);

        const response: MeetingState = (await fetchMeetings(
          source_Id,
          authToken,
          selectedAccount,
          meetingId
        )) as MeetingState;

        if (response) {
          const {
            id,
            module_id,
            account_id,
            unique_id,
            host_id,
            scribe_id,
            agenda_id,
            meeting_date_time,
            meeting_location,
            webinar_link,
            description,
            attendee_ids,
            meeting_started,
            is_open,
            finalised,
            finalised_by,
            finalised_at,
            meeting_report,
            closed_at,
            created_at,
            created_by,
            agenda,
            host,
            scribe,
            attendees
          } = response;

          if (closed_at) {
            setClosed_at(closed_at);
          }

          const newFormData = {
            id,
            module_id,
            account_id,
            unique_id,
            host_id,
            scribe_id,
            agenda_id,
            meeting_date_time,
            meeting_location,
            webinar_link,
            description,
            attendee_ids,
            meeting_started,
            is_open,
            finalised: finalised ? true : false,
            finalised_at,
            finalised_by: {
              id: finalised_by ? finalised_by.id : 0,
              email: finalised_by ? finalised_by.email : '',
              first_name: finalised_by ? finalised_by.first_name : '',
              last_name: finalised_by ? finalised_by.last_name : ''
            },
            meeting_report,
            closed_at,
            created_at,
            created_by,
            agenda,
            host,
            scribe,
            attendees
          };
          setFormData(newFormData);

          if (currentUserInfo?.id == scribe_id) {
            localStorage.setItem(localStorageKeys.IS_SCRIBE, 'yes');
          }
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
      if (typeof response !== 'string') {
        setUsers(response);
      } else {
        console.log('Error :', response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching users:', error);
    }
  };

  // Get meeting Agendas
  const getMeetingsAgendas = async () => {
    try {
      const response: MeetingAgendasState[] = await fetchMeetingsAgendas(authToken);

      if (response) {
        setAgendas(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching users:', error);
    }
  };

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: MeetingAgendasState = (await fetchMeetingTemplate(
          formData.agenda_id,
          authToken
        )) as MeetingAgendasState;

        if (response) {
          const { template } = response;
          if (template && template !== 0) {
            setTemplate(template);
            setFormData((prevData) => ({
              ...prevData,
              error: ''
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFound')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (current_route == `/meeting-add` && formData.agenda_id > 0) {
      fetchTemplate();
    }
  }, [current_route, formData.agenda_id]);

  useEffect(() => {
    if (current_route == `/meeting-add` && formData.agenda_id > 0) {
      fetchTemplate();
    }

    if (current_route == `/meeting-edit/${encryptedId}` || current_route == `/meeting-add`) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (!hasEffectRun) {
      setHasEffectRun(true);
      fetchData(); // Call the async function
      fetchUsers();
      getMeetingsAgendas();
    }
  }, [current_route, hasEffectRun]);

  const handleCancelClick = () => {
    if (id) {
      navigate(`/meeting/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/meetings/${encryptedSourceId}`);
    }
  };

  const handleMeetingStart = async () => {
    const requestData = {
      ...formData,
      meeting_started: 1
    };

    const edit_id = id ? id : formData.id;
    try {
      const response: any = await createMeetingData(
        requestData,
        template,
        authToken,
        selectedAccount,
        edit_id
      );

      if (response && response.id) {
        const msg = t('success.field.meetingStarted');

        setNotificationSuccess(msg);
        navigate(
          `/meeting/run-meeting?source=${encryptedSourceId}&meetingId=${encryptedId}&agendaId=${encryptData(String(formData.agenda_id), true)}`
        );
      } else {
        const newErrors: Record<string, string> = {};

        if (response.host_id) {
          newErrors.host_id = response.host_id[0];
        } else if (response.scribe_id) {
          newErrors.scribe_id = response.scribe_id[0];
        } else if (response.agenda_id) {
          newErrors.agenda_id = response.agenda_id[0];
        } else if (response.meeting_date_time) {
          newErrors.meeting_date_time = response.meeting_date_time[0];
        } else if (response.meeting_location) {
          newErrors.meeting_location = response.meeting_location[0];
        } else if (response.webinar_link) {
          newErrors.webinar_link = response.webinar_link[0];
        } else if (response.attendees) {
          newErrors.attendees = response.attendees[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }

        setErrors(newErrors);
      }
    } catch (error) {
      // Handle other error cases
      console.error(error); // Log the error
      // You can handle other error cases as needed
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.host_id) {
      newErrors.host_id = t('error.field.hostRequired');
    }
    if (!formData.scribe_id) {
      newErrors.scribe_id = t('error.field.scribeRequired');
    }
    if (!formData.agenda_id) {
      newErrors.agenda_id = t('error.field.agendaRequired');
    }
    if (!formData.meeting_date_time || formData.meeting_date_time === 'Invalid Date') {
      newErrors.meeting_date_time = t('error.field.meetingDateTimeRequired');
    }
    if (!formData.attendee_ids?.length) {
      newErrors.attendee_ids = t('error.field.attendeesRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createMeetingData(
          formData,
          template,
          authToken,
          selectedAccount,
          edit_id
        );

        if (response && response.id) {
          if (!id) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const responseData: any = await sendEmailAttendes(
              response.id,
              authToken,
              selectedAccount,
              encryptedSourceId
            );
          }

          const msg = id ? t('success.field.meetingUpdated') : t('success.field.meetingCreated');
          setLoading(false);
          if (response.id && current_route && current_route === `/meeting-add` && !meetingAddMode) {
            setErrors({});
            navigate(
              `/meeting/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
            );
          } else if (id || meetingAddMode) {
            setHasEffectRun(false);
            setNotificationSuccess(msg);
            setMeetingAddMode(false);
            navigate(
              `/meeting/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
            );
          } else {
            setNotificationSuccess(msg);
            navigate(`/meetings/${encryptedSourceId}`);
          }
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          const newErrors: Record<string, string> = {};

          if (response.host_id) {
            newErrors.host_id = response.host_id[0];
          } else if (response.scribe_id) {
            newErrors.scribe_id = response.scribe_id[0];
          } else if (response.agenda_id) {
            newErrors.agenda_id = response.agenda_id[0];
          } else if (response.meeting_date_time) {
            newErrors.meeting_date_time = response.meeting_date_time[0];
          } else if (response.meeting_location) {
            newErrors.meeting_location = response.meeting_location[0];
          } else if (response.webinar_link) {
            newErrors.webinar_link = response.webinar_link[0];
          } else if (response.attendees) {
            newErrors.attendees = response.attendees[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
    setLoading(false);
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteMeeting(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.meetingDeleted'));
          navigate(`/meetings/${encryptedSourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const handleFinalise = async (type: string = '') => {
    try {
      setLoading(true);

      const response = await finaliseMeeting(
        authToken,
        selectedAccount,
        formData.id,
        source_Id,
        type
      );
      if (!response) {
        setLoading(false);
        fetchData();
        const msg =
          type == 'un-finalised'
            ? t('success.field.meetingUnFinalised')
            : t('success.field.meetingFinalised');
        setNotificationSuccess(msg);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      /* empty */
      setLoading(false);
      console.log(error);
    }
  };

  let edit_btn_snapshot;
  if (formData && formData.is_open == 1) {
    edit_btn_snapshot = (
      <>
        {checkUserPermission(currentUserInfo, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  let addButton;
  if (formData && formData.is_open == 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {isDisabled && current_route === `/meeting/${encryptedId}` && (
          <>
            {checkUserPermission(currentUserInfo, MODULES, source_Id, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/meeting/add-note?source=${encryptedSourceId}&meetingId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}
            {checkUserPermission(currentUserInfo, MODULES, source_Id, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/meeting/add-task?source=${encryptedSourceId}&meetingId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id ? formData.unique_id : pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                errorMsg={formData.error}
                button={id ? addButton : undefined}
              />

              <div className="record-outer-row settings-outer-row">
                <MeetingLeftMenu
                  user={currentUserInfo}
                  meetingId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formData_record={formData}
                  meetingIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {current_route &&
                      (current_route === `/meeting/${encryptedId}` ||
                        current_route === `/meeting-edit/${encryptedId}` ||
                        current_route === `/meeting-add`) &&
                      !meetingAddMode && (
                        <MeetingCreateEditFormData
                          date={date}
                          formData={formData}
                          users={users}
                          agendas={agendas}
                          sourceId={sourceId ? sourceId : ''}
                          errors={errors}
                          closed_at={closed_at}
                          isDisabled={isDisabled}
                          current_route={current_route}
                          meetingAddMode={meetingAddMode}
                          edit_btn_snapshot={edit_btn_snapshot}
                          currentUserInfo={currentUserInfo}
                          handleComplete={handleComplete}
                          handleInputChange={handleInputChange}
                          handleSelectChange={handleSelectChange}
                          handleMeetingDateTimeInputChange={handleMeetingDateTimeInputChange}
                          handleMeetingStart={handleMeetingStart}
                          meetingIdEncrypted={encryptedId}
                          sourceIdEncrypted={encryptedSourceId}
                        />
                      )}

                    {!isDisabled &&
                      current_route &&
                      (current_route === `/meeting-edit/${encryptedId}` ||
                        current_route === `/meeting-add`) &&
                      !meetingAddMode && (
                        <div className="btn-wrap">
                          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </Button>
                          {(template !== 0 || current_route === `/meeting-edit/${encryptedId}`) && (
                            <>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                <Button
                                  className="btn primary-btn btn-sm"
                                  type="submit"
                                  onClick={handleFormSubmit}>
                                  Save
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    {isDisabled &&
                      current_route === `/meeting/${encryptedId}` &&
                      (formData.finalised || currentUserInfo?.id == formData.scribe_id) && (
                        <FinaliseMeetings
                          date={date}
                          formData={formData}
                          loading={loading}
                          handleFinalise={handleFinalise}
                        />
                      )}

                    {(currentUserInfo?.lu_user_access_level_id == 1 ||
                      currentUserInfo?.lu_user_access_level_id == 2) &&
                      checkUserPermission(currentUserInfo, MODULES, source_Id, UPDATE) &&
                      current_route === `/meeting/${encryptedId}` &&
                      formData.finalised && (
                        <div className="form-group">
                          <div className="inner-btn-wrap inner-btn-wrap-complete">
                            <Button
                              className="btn white-btn"
                              onClick={() => handleFinalise('un-finalised')}>
                              <i className="left">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path
                                    d="M9.99984 17.5L9.91646 17.3749C9.33759 16.5066 9.04816 16.0725 8.66576 15.7582C8.32722 15.4799 7.93714 15.2712 7.51784 15.1438C7.04421 15 6.52243 15 5.47886 15H4.33317C3.39975 15 2.93304 15 2.57652 14.8183C2.26292 14.6586 2.00795 14.4036 1.84816 14.09C1.6665 13.7335 1.6665 13.2668 1.6665 12.3333V5.16667C1.6665 4.23325 1.6665 3.76654 1.84816 3.41002C2.00795 3.09641 2.26292 2.84144 2.57652 2.68166C2.93304 2.5 3.39975 2.5 4.33317 2.5H4.6665C6.53335 2.5 7.46677 2.5 8.17981 2.86331C8.80701 3.18289 9.31695 3.69282 9.63653 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333M9.99984 17.5V7.83333M9.99984 17.5L10.0832 17.3749C10.6621 16.5066 10.9515 16.0725 11.3339 15.7582C11.6725 15.4799 12.0625 15.2712 12.4818 15.1438C12.9555 15 13.4772 15 14.5208 15H15.6665C16.5999 15 17.0666 15 17.4232 14.8183C17.7368 14.6586 17.9917 14.4036 18.1515 14.09C18.3332 13.7335 18.3332 13.2668 18.3332 12.3333V5.16667C18.3332 4.23325 18.3332 3.76654 18.1515 3.41002C17.9917 3.09641 17.7368 2.84144 17.4232 2.68166C17.0666 2.5 16.5999 2.5 15.6665 2.5H15.3332C13.4663 2.5 12.5329 2.5 11.8199 2.86331C11.1927 3.18289 10.6827 3.69282 10.3631 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333"
                                    stroke="#667085"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </i>
                              Un-Finalise
                            </Button>
                          </div>
                        </div>
                      )}
                    {checkUserPermission(currentUserInfo, MODULES, source_Id, DELETE) &&
                      current_route === `/meeting/${encryptedId}` && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default MeetingCreateEdit;
