import React from 'react';
import {
  TextField,
} from '@mui/material';
import { ComplianceQueryState } from '../../user/state';

export interface SupportRequestFormProps {
  formData: ComplianceQueryState;
  errors: Record<string, string>;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ComplianceQueryCreateEditFormData: React.FC<SupportRequestFormProps> = ({
  formData,
  errors,
  handleInputChange
}) => {
  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Compliance Query</h3>
          </div>

          <div className="row-wrap ">
            <div className="form-group">
              <label className="form-label">Subject</label>
              <TextField
                error={!!errors.subject}
                id="subject"
                variant="outlined"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter subject"
                type="text"
                helperText={errors.subject}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full textarea">
              <label className="form-label">Enquiry</label>
              <TextField
                error={!!errors.enquiry}
                id="enquiry"
                variant="outlined"
                name="enquiry"
                value={formData.enquiry}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter the full details of your enquiry"
                type="text"
                multiline
                rows={5}
                helperText={errors.enquiry}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ComplianceQueryCreateEditFormData;
