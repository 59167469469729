import React from 'react';
import { Bar } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);


interface BarChartProps {
  data: number[];
  labels: string[];
} 

const BarChart: React.FC<BarChartProps> = ({ data, labels }) => {
  const chartData = {
    labels,
    datasets: [
      {
        data,  
        borderWidth: 0,
        backgroundColor: '#D2ECFC',
        barThickness: 20,
        borderRadius: 30,
        hoverBackgroundColor: '#1DA2EF'
      },
    ],
  };

//   const options = {
//     scales: {
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Percentage (%)',
//         },
//         ticks: {
//           callback: (value: number) => `${value}%`,
//         },
//       },
//     },
//   };

  return (
    <div className="bar-chart">
      <Bar data={chartData}  />
    </div>
  );
};

export default BarChart;