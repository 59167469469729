import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AttestationsPeriodsState } from '../../views/components/modules/attestations/state';
import { RelevantPeriodElement } from '../../views/components/settings/attestations/AttestationRelevantPeriods';

export const addEditAttestationPeriods = async (
  elements: RelevantPeriodElement[],
  accountId: number,
  authToken: string,
): Promise<AttestationsPeriodsState | string | undefined | null> => {
  try {
    const formData = new FormData(); // Create a FormData object

    elements.map((element) => {
      formData.append('period_label[]', element.relevant_period);
    })

    const routeUrl = `${process.env.REACT_APP_API_URL}/attestation-periods?accountId=${dataEncrypt(accountId?.toString())}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
