import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ReviewTemplatesState } from '../../views/components/user/state';

export const EditReviewTemplate = async (
  jsonResponse: any,
  templateId: number,
  sourceId: number,
  accountId: number,
  type: string,
  authToken: string,
): Promise<ReviewTemplatesState | string | undefined | null> => {
  let routeUrl;
 
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('form_data', JSON.stringify(jsonResponse));

    routeUrl = `${process.env.REACT_APP_API_URL}/review-templates?type=${type}&sourceId=${dataEncrypt(sourceId?.toString())}&accountId=${dataEncrypt(accountId?.toString())}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
