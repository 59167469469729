import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { StripeSessionUrlState } from '../../views/components/settings/state';

export const fetchBillingPortalSessionURL = async (
  authToken?: string,
  accountInfo?: AccountState,
): Promise<StripeSessionUrlState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/billing-portal-session?account_id=${selectedAccountId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching updates';
  }
};

