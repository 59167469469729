import React, { useEffect, useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { Button, CircularProgress } from '@mui/material';
import { PlanState } from '../user/state';
import {
  getSelectedAccountInfo,
  // isSubscriptionExpired,
  useAuthToken
} from '../../../utils/common/user';
import { getPlans } from '../../../api/plans/getPlans';
import { StripeSessionUrlState } from '../settings/state';
import { fetchSubscriptionSessionURL } from '../../../api/stripe/fetchSubscriptionSessionURL';
import { useNavigate } from 'react-router';
// import Popup from '../common/popup/Popup';

const SelectPlan = () => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();

  const title = 'Select Plan';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionUrlLoading, setSessionUrlLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<PlanState[]>([]);
  const [planId, setPlanId] = useState<number>(0);
  // const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  // const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      if (authToken) {
        const response: any = (await getPlans(authToken)) as any;

        if (Array.isArray(response)) {
          setPlans(response);
        } else {
          console.log('Error : ', response);
        }
      }
    } catch (error: any) {
      console.log('Error : ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNextStep = async () => {
    setSessionUrlLoading(true);

    try {
      const response: StripeSessionUrlState = (await fetchSubscriptionSessionURL(
        authToken,
        selectedAccount,
        planId
      )) as StripeSessionUrlState;

      if (response.url) {
        const a = document.createElement('a');
        a.href = response.url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPlanId(0);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper selected-plan-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="content-wrapper">
                <h2>Select your plan</h2>
                <p>3Lines is intentionally designed to support everyone at your firm. </p>
                <p>
                  This includes compliance professionals, administrators, paraplanners, Authorised
                  Representatives, Responsible Managers, and anyone else involved in maintaining
                  your firm&apos;s ongoing compliance.
                </p>
              </div>
              {loading ? (
                <div className="no-record">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="plan-list">
                    <ul>
                      {plans.map((plan: PlanState) => (
                        <li key={`plan-${plan.id}`} onClick={() => setPlanId(plan.id!)}>
                          <p className={plan.id == planId ? `selected` : ''}>
                            <span className="plan-name">{plan.plan_name}</span>
                            <span className="max-users">{plan.max_users} Users</span>
                            <span className="img-wrapper">
                              <img src={plan.image} alt="user" />
                            </span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {sessionUrlLoading ? (
                    <div className="right-outer">
                      <div className="right-inner">
                        <div className="form-wrap">
                          We are now redirecting you to the secure payment portal...
                          <div className="mt-2">
                            <CircularProgress />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="btn-wrap">
                      <Button
                        className="btn primary-btn"
                        onClick={() => handleNextStep()}
                        disabled={!planId}>
                        Next
                      </Button>
                      <Button className="btn border-btn" onClick={() => navigate('/more-than-20')}>
                        More than 20 Users?
                        <span>Or need custom functionality?</span>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-success-1.png`}
                  alt={'Login'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default SelectPlan;
