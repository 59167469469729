import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface TinyMceEditorProps {
  id: string;
  value: string;
  handleEditorChange: ((content: string) => void) | undefined;
}

const TinyMceEditor: React.FC<TinyMceEditorProps> = ({ id, value, handleEditorChange }) => {
  // const handleEditorChange = (content: any, editor: any) => {
  //   console.log('Content was updated:', content, editor);
  // };

  return (
    <Editor
      apiKey="9rfcpn5uxe0rhs66bux1gqw0eye4q3ebwm0dchtum2gdngrc"
      init={{
        height: 250,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'bold italic | alignleft aligncenter ' + 'alignright alignjustify | bullist | ',
        statusbar: false
      }}
      value={value}
      textareaName="details"
      id={id}
      onEditorChange={handleEditorChange}
    />
  );
};

export default TinyMceEditor;
