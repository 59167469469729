import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import CommonLayout from '../../layout/CommonLayout';
import { fetchFileReviewTemplates } from '../../../../api/adviser/fetchFileReviewTemplates';
import { FileReviewTemplatesState, FileReviewState } from '../../user/state';
import { fetchFileReviews } from '../../../../api/adviser/fetchFileReviews';
import { FileReviewFormState } from './state';
import { SurveyData, renderFormElement } from '../../../../utils/common/surveyjs/survey-js';

const FileReviewCompleteView: React.FC = () => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  id = decryptData(id, true);
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let reviewId = queryParams.get('reviewId');
  const encryptedReviewId = reviewId; // Decrypt the ID
  reviewId = decryptData(reviewId, true);
  const review_id = parseInt(reviewId!, 10);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const title = 'File Review Details';
  const edit_id = parseInt(id!, 10);
  const { t } = useTranslation();
  const mainPath = `/reviews/${encryptedSourceId}`;
  const pageTitle = 'Client File Review';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [templateData, setTemplateData] = useState<SurveyData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FileReviewFormState>({
    id: 0,
    review_id: review_id,
    file_review_template_id: 0,
    client: '',
    is_complete: false,
    error: ''
  });

  let thirdTitle;
  if (formData && formData.review_data?.unique_id) {
    thirdTitle = formData.review_data?.unique_id;
  }
  const thirdPath = `/review/${encryptedReviewId}?source=${encryptedSourceId}`;
  const secondTitle = 'Adviser Reviews';

  useEffect(() => {
    if (!hasEffectRun) {
      fetchTemplate();
      fetchData();
      setTimeout(() => {
        setHasEffectRun(true);
        setLoading(false);
      }, 1000);
    }
  }, [templateData, formData, hasEffectRun, current_route]);

  const fetchTemplate = async () => {
    try {
      if (id && authToken) {
        const response: FileReviewTemplatesState = (await fetchFileReviewTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          edit_id
        )) as FileReviewTemplatesState;

        if (response) {
          const { form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(JSON.parse(form_data));
          } else {
            localStorage.removeItem('survey-json-file-review-temp-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFoundReview')
            }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      if (authToken && id) {
        const reviewId = parseInt(id, 10);
        const response: FileReviewState = (await fetchFileReviews(
          authToken,
          reviewId,
          edit_id,
          0,
          0
        )) as FileReviewState;
        if (response) {
          const {
            id,
            review_id,
            client,
            file_review_template_id,
            is_complete,
            filereview_data,
            review_data
          } = response;

          const newFormData = {
            id,
            review_id,
            client,
            file_review_template_id,
            is_complete: is_complete ? true : false,
            filereview_data: {
              id: filereview_data ? filereview_data.id : 0,
              file_review_id: filereview_data ? filereview_data.file_review_id : 0,
              form_data: filereview_data ? JSON.parse(filereview_data.form_data) : ''
            },
            review_data: {
              id: review_data ? review_data.id : 0,
              unique_id: review_data ? review_data.unique_id : '',
              adviser: {
                id: review_data?.adviser ? review_data?.adviser.id : 0,
                email: review_data?.adviser ? review_data?.adviser.email : '',
                first_name: review_data?.adviser ? review_data?.adviser.first_name : '',
                last_name: review_data?.adviser ? review_data?.adviser.last_name : ''
              }
            }
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const handleExit = () => {
    if (reviewId) {
      navigate(`/review/${encryptedReviewId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/reviews/${encryptedReviewId}`);
    }
  };

  const editButton = (
    <div className="inner-btn-wrap">
      <React.Fragment>
        <Button className="btn white-btn" onClick={handleExit}>
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          Exit
        </Button>
      </React.Fragment>
    </div>
  );

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            thirdTitle={thirdTitle}
            thirdPath={thirdPath}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <ReviewLeftMenu
              user={user}
              fileformData_record={formData}
              filereviewId={id}
              reviewIdEncrypted={encryptedReviewId}
              sourceIdEncrypted={encryptedSourceId}
            />
            {loading ? (
              <div className="no-record">
                <CircularProgress />
              </div>
            ) : (
              <div className="right-outer">
                <div className="right-inner">
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <div>
                        <h3> {t('snapshot')}</h3>
                      </div>
                      <div className="inner-btn-wrap">{editButton}</div>
                    </div>
                    <div className="row-wrap">
                      {templateData?.title && (
                        <div className="inner-wrap-title ">
                          <h4 className="title-padding">{templateData?.title}:</h4>
                          <p className={` ${templateData?.title ? 'template-description' : ''}`}>
                            {templateData?.description}
                          </p>
                        </div>
                      )}
                      <div className="form-group w-full">
                        {templateData &&
                          templateData?.pages?.map((page) => (
                            <div key={page.name} className="row-wrap">
                              {page.elements
                                .filter(
                                  (element: any) =>
                                    formData.filereview_data?.form_data[element.name] !== undefined
                                )
                                .map((element: any) => (
                                  <div className={`form-group w-full`} key={element.name}>
                                    <label className="form-label">
                                      {element.title ? element.title : element.name}
                                    </label>
                                    {formData.filereview_data?.form_data[element.name] &&
                                      renderFormElement(
                                        element,
                                        formData.filereview_data?.form_data[element.name],
                                        authToken
                                      )}
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default FileReviewCompleteView;
