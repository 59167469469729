import axios from 'axios';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';
import { OnboardingsDataFormState, OnboardingsResponseError } from '../../views/components/modules/onboarding/state';

export const createOnboardingData = async (
  formData: OnboardingsDataFormState,
  authToken?: string,
  accountInfo?: AccountState,
  onboardingId?: string | number,
): Promise<OnboardingsDataFormState | OnboardingsResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    module_id,
    owner_id,
    car,
    first_name,
    surname,
    email,
    state,
    is_open,
  } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('owner_id', owner_id?.toString() || '');
    formData.append('car', car);
    formData.append('first_name', first_name);
    formData.append('surname', surname);
    formData.append('email', email);
    formData.append('state', state);
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('module_id', module_id?.toString() || '');
    formData.append('is_open', is_open.toString());

    if (onboardingId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/onboardings/${dataEncrypt(onboardingId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/onboardings`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const sendEmailOnboardingCreate = async (
  onboardingId: number,
  authToken?: string,
  accountInfo?: AccountState,
): Promise<OnboardingsDataFormState | string | undefined | null> => {
  let routeUrl;
  try {
    const selectedAccountId = accountInfo?.id;
    const redirectUrl = `onboarding-personal-details?account_id=${encryptData(String(selectedAccountId), true)}&redirect=true&onboardingId=${encryptData(String(onboardingId), true)}`;
    const formData = new FormData(); // Create a FormData object
    formData.append('accountId', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('redirectUrl', redirectUrl);
    formData.append('onboardingId', String(onboardingId));

    routeUrl = `${process.env.REACT_APP_API_URL}/send-onboarding-email`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
