import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';

interface ForgotPasswordFormState {
  password: string;
  token?: string;
  email?: string;
}

export const forgotPassword = async (
  formData: ForgotPasswordFormState,
  type?: string
): Promise<string | undefined> => {
  const { token, password, email } = formData;
  if (token && email) {
    try {
      const response = await axios.post<ApiResponseType>(
        `${process.env.REACT_APP_API_URL}/password/reset?type=${type}`,
        {
          token,
          email,
          password,
          password_confirmation: password
        }
      );

      if (response.data.success) {
        return;
      } else {
        throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
      }
    } catch (error: any) {
      const errorMessage =
        error instanceof ApiResponse ? error.message : error?.response?.data?.message;

      return errorMessage;
    }
  } else {
    return 'Oops! Please try again with the new forgot password email.';
  }
};
