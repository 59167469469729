import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { FileReviewTemplatesState, AccountState } from '../../views/components/user/state';

export const createFileReviewTemplate = async (
  jsonResponse: any,
  authToken?: string,
  accountInfo?: AccountState,
  copyId?: number,
  fileReviewMainTempId?: number,
): Promise<FileReviewTemplatesState | string | undefined | null | number> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('form_data', JSON.stringify(jsonResponse));
    if(fileReviewMainTempId){
      formData.append('file_review_temp_id', JSON.stringify(fileReviewMainTempId));
    }
    if (selectedAccountId) {
      formData.append('account_id', selectedAccountId.toString());
    }

    if (copyId) {
      formData.append('copyId', copyId.toString());
    }

    routeUrl = `${process.env.REACT_APP_API_URL}/file-review-templates`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
