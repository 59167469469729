const allowedExtensions = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'csv',
  'txt',
  'tx',
  'eml',
  'msg',
  'odt'
];

export const fileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>,
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
) => {
  const inputFiles = event.target.files;
  const newFiles = Array.from(inputFiles || []);

  for (const file of newFiles) {
    if (file instanceof File) {
      // Check if 'file' is an instance of File
      const fileName = file.name || ''; // Ensure fileName is defined
      const extension = (fileName.split('.').pop() || '').toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        alert('Invalid file extension. Allowed extensions: ' + allowedExtensions.join(', '));
        // Clear the file input if necessary
        event.target.value = '';
        return;
      }
    }
  }
  // Create a new array for the selected files
  setSelectedFiles(inputFiles);
  setFiles(newFiles);
};
