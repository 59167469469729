import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { ModulesState } from '../../user/state';
import { ModuleDataFormState, ModuleResponseError } from '../state';
import { useTranslation } from 'react-i18next';
import { modulesDetails } from '../../../../api/settings/updateModulesDetails';
import { getSelectedAccountInfo, useAuthToken, decryptData } from '../../../../utils/common/user';
import { useDispatch } from 'react-redux'; // Import useDispatch
import ModuleUpdateForm from './ModuleUpdateForm';
import { useNotificationContext } from '../../layout/NotificationContext';
import { TemplatesDropdownResponse } from '../state';
import { fetchTemplatesDropdown } from '../../../../api/dashboard/fetchTemplatesDropdown';
import { SelectChangeEvent } from '@mui/material';

const ModulesUpdate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function
  const selectedAccount = getSelectedAccountInfo();
  const authToken = useAuthToken() || '';
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [updateLogo, setUpdateLogo] = useState<Blob | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const modules = useSelector((state: RootState) => state?.module?.modules) || [];
  const { setNotificationSuccess } = useNotificationContext();
  const [template, setTemplate] = useState<string>('');
  const [templatesOptionsWithValueLabel, setTemplatesOptionsWithValueLabel] = useState<
    TemplatesDropdownResponse[]
  >([]);

  const [fetchLogo, setFetchLogo] = useState<{
    path: string;
    original_name: string;
  } | null>(null);

  const [formData, setFormData] = useState<ModuleDataFormState>({
    id: 0,
    add_record_label: '',
    name: '',
    display_name: '',
    prefix: '',
    description: '',
    icon_path: null,
    default_for_starter: 0,
    default_for_premium: 0,
    error: ''
  });

  useEffect(() => {
    if (!id) {
      navigate('/error');
    } else {
      fetchTemplatesApi();
      const moduleIdInt = parseInt(id, 10);
      if (modules) {
        const module = modules && modules.find((module: ModulesState) => module.id === moduleIdInt);
        if (module) {
          try {
            const {
              id,
              add_record_label,
              name,
              display_name,
              prefix,
              description,
              template_id,
              icon_path,
              default_for_starter,
              default_for_premium
            } = module;
            const imageObject = icon_path ? JSON.parse(icon_path) : null;
            const typedImageObject = imageObject as { path: string; original_name: string };

            if (icon_path) {
              setFetchLogo({
                path: typedImageObject.path,
                original_name: typedImageObject.original_name
              });
            } else {
              setFetchLogo(null);
            }
            if (template_id) {
              setTemplate(template_id.toString());
            }

            setFormData({
              id: id,
              add_record_label: add_record_label,
              name: name,
              display_name: display_name,
              prefix: prefix,
              description: description,
              icon_path: null,
              default_for_starter: parseInt(default_for_starter?.toString() || "", 10),
              default_for_premium: parseInt(default_for_premium?.toString() || "", 10),
              error: ''
            });
          } catch (error) {
            console.error('Error fetching module details:', error);
          }
        } else {
          navigate('/settings/modules');
        }
      } else {
        navigate('/settings/modules');
        console.error('Error fetching module details:');
      }
    }
    return () => {};
  }, [id, navigate, modules]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.name) {
      newErrors.name = t('error.field.moduleDetailsName');
    }
    if (!formData.add_record_label) {
      newErrors.add_record_label = t('error.field.registerDetailsAddRecordLabel');
    }
    if (!formData.description) {
      newErrors.description = t('error.field.moduleDetailsDescription');
    }
    if (!formData.prefix) {
      newErrors.prefix = t('error.field.accountDetailsPrefix');
    } else if (formData.prefix.length < 2 || formData.prefix.length > 4) {
      newErrors.prefix = t('error.field.accountDetailsPrefixLength');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    // Prepare the data to send to the API
    const requestData = {
      id: formData.id,
      add_record_label: formData.add_record_label,
      name: formData.name,
      display_name: formData.display_name,
      prefix: formData.prefix,
      description: formData.description,
      template_id: template,
      icon_path: updateLogo,
      default_for_starter: formData.default_for_starter,
      default_for_premium: formData.default_for_premium,
      error: ''
    };
    const response: ModuleResponseError = (await modulesDetails(
      dispatch,
      requestData,
      selectedAccount,
      authToken
    )) as ModuleResponseError;
    if (response) {
      if (response == t('permission.doesNotHaveAccessPermission')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('permission.doesNotHaveAccessPermission')
        }));
      } else {
        const newErrors: Record<string, string> = {};
        if (response.display_name) {
          newErrors.display_name = response.display_name[0];
        } else if (response.prefix) {
          newErrors.accountPrefix = response.prefix[0]; // Take the first error message for 'prefix'
        } else if (response.icon_path) {
          setSelectedFile(null);
          newErrors.logo = response.icon_path[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      }
    } else {
      setNotificationSuccess(t('success.field.moduleDetailsUpdates'));
      navigate('/settings/modules');
    }
  };

  const handleCancelClick = () => {
    // Reset form fields
    setErrors({});
    setSelectedFile(null);
    setFormData((prevData) => ({ ...prevData, error: '' }));
    navigate('/settings/modules');
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);
      setUpdateLogo(file);
    } else {
      setPreviewURL(null);
      setUpdateLogo(null);
    }
  };

  const fetchTemplatesApi = async () => {
    try {
      const response: TemplatesDropdownResponse[] = await fetchTemplatesDropdown(authToken);
      if (response) {
        setTemplatesOptionsWithValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleChangeTemplate = (event: SelectChangeEvent<string>) => {
    setTemplate(event.target.value);
  };

  const handleSwitchChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFormData((prevData) => ({ ...prevData, [fieldName]: checked ? 1 : 0 }));
    };

  return (
    <div className="form-wrap mt-2">
      <ModuleUpdateForm
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleFileChange={handleFileChange}
        handleCancelClick={handleCancelClick}
        handleChangeTemplate={handleChangeTemplate}
        handleSwitchChange={handleSwitchChange}
        previewURL={previewURL}
        fetchLogo={fetchLogo}
        errors={errors}
        selectedFile={selectedFile}
        templatesOptionsWithValueLabel={templatesOptionsWithValueLabel}
        template={template}
      />
    </div>
  );
};

export default ModulesUpdate;
