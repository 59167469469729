import React, { useEffect, useRef } from 'react';

export interface LazyLoadProps {
  src: string;
  alt: string;
  rootMargin?: string;
  className?: string;
}

const LazyImage: React.FC<LazyLoadProps> = ({ src, alt, rootMargin = '0px', className }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const imgElement = entry.target as HTMLImageElement;
            if (imgElement.dataset.src) {
              imgElement.src = imgElement.dataset.src; // Set the `src` from `data-src`
              observer.unobserve(imgElement); // Unobserve after setting `src`
            }
          }
        });
      },
      { rootMargin }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [rootMargin]);

  return <img ref={imgRef} data-src={src} alt={alt} className={className ? className : ''} />;
};

export default LazyImage;
