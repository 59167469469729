import axios from 'axios';
import {
  OnboardingApplicationState,
} from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const createAttestationData = async (
  form_data: any,
  attestationId: string | number,
  authToken: string,
  dataId?: number | string | null
): Promise<OnboardingApplicationState | string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('attestation_id', attestationId.toString());

    let routeUrl;
    if (dataId) {
      formData.append('_method', 'put');
      routeUrl = `${process.env.REACT_APP_API_URL}/attestation-template-data/${dataEncrypt(dataId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/attestation-template-data`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
