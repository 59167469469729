import axios from 'axios';
import {
  OnboardingAssessmentState,
} from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const createOnboardingAssessmentData = async (
  form_data: any,
  onboardingId: string | number,
  templateId: string | number,
  authToken: string,
  dataId?: number | string | null
): Promise<OnboardingAssessmentState | string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object
    const { proceed_to_authorisations, reason } = form_data

    if (!proceed_to_authorisations && !reason) {
      formData.append('form_data', JSON.stringify(form_data));
      formData.append('onboarding_assessment_template_id', templateId.toString());
    } else {
      formData.append('proceed_to_authorisations', proceed_to_authorisations.toString());
      formData.append('reason', reason);
    }
    formData.append('onboarding_id', onboardingId.toString());

    let routeUrl;
    if (dataId) {
      formData.append('_method', 'put');
      routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-assessment-template/${dataEncrypt(dataId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-assessment-template`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
