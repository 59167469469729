import React from 'react';
import { format } from 'date-fns';
import { PresignedUrl } from './surveyFilePresignedUrl';
import { FunctionFactory, QuestionDropdownModel, SurveyModel } from 'survey-core';

// Define TypeScript types or interfaces for surveyData and formData
export interface Choices {
  id?: number;
  value: string;
  name?: string;
  type?: string;
  text?: string;
  imageLink?: string;
}

export type ChoicesByUrl = {
  titleName: string;
  url: string;
  valueName?: string;
};

export interface Items {
  name: string;
  title?: string;
}

export interface Element {
  type: any;
  name: string;
  title: string;
  inputType?: string;
  choices?: Choices[];
  choicesByUrl?: ChoicesByUrl;
  startWithNewLine?: boolean;
  items?: Items[];
  html?: string;
}

export interface SurveyData {
  title: string;
  description: string;
  pages: {
    name: string;
    elements: Element[];
    title: string;
    description: string;
  }[];
}

export interface FormData {
  [key: string]: string | string[];
}

export interface TemplateTextModeProps {
  surveyData: SurveyData;
  formData: FormData;
  edit_btn?: JSX.Element | null;
  title?: string;
  emptyText?: string;
  loading?: boolean;
}

export const fetchDynamicChoicesData = async (
  authToken: string,
  surveyData: SurveyData,
  formData: FormData,
  setDynamicChoicesData: React.Dispatch<React.SetStateAction<{ [key: string]: Choices[] }>>
) => {
  const choicesByUrlElements = surveyData?.pages
    ?.flatMap((page) => page.elements)
    .filter(
      (element) =>
        (element.type === 'dropdown' || element.type === 'tagbox') && element.choicesByUrl
    );

  if (choicesByUrlElements) {
    try {
      const fetchPromises = choicesByUrlElements.map(async (element) => {
        const choicesByUrlArray = element.choicesByUrl;
        if (choicesByUrlArray) {
          let url = choicesByUrlArray.url;

          // Replace placeholders like {complaint_category} with actual values from formData
          Object.keys(formData).forEach((key) => {
            const placeholder = `{${key}}`;
            if (url.includes(placeholder)) {
              url = url.replace(placeholder, formData[key] as string);
            }
          });

          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          });
          const data = await response.json();
          setDynamicChoicesData((prevData) => ({
            ...prevData,
            [element.name]: data
          }));
        }
      });

      await Promise.all(fetchPromises);
    } catch (error) {
      console.error('Error fetching dynamic choices data:', error);
    }
  }
};

export const registerSurveyFunctions = (survey: SurveyModel) => {
  FunctionFactory.Instance.register('getDisplayName', function (params: any[]) {
    const questionName = params[0]; // Access the first parameter as questionName
    const dropdownQuestion = survey.getQuestionByName(questionName) as QuestionDropdownModel;

    if (!dropdownQuestion) return '';

    // Handle multi-select dropdowns
    if (Array.isArray(dropdownQuestion.value)) {
      const selectedItems = dropdownQuestion.visibleChoices.filter((choice) =>
        dropdownQuestion.value.includes(choice.value)
      );
      return selectedItems.length > 0 ? selectedItems.map((item) => item.text).join(', ') : '';
    } else {
      // Handle single-select dropdowns
      return dropdownQuestion.selectedItem ? dropdownQuestion.selectedItem.text : '';
    }
  });
};

export const renderFormElement = (
  element: Element,
  value: string | string[] | boolean | any[], // Adjust the value type to include arrays
  authToken: string,
  dynamicChoicesData: { [key: string]: Choices[] } = {}
) => {
  let content;

  switch (element.type) {
    case 'dropdown':
      {
        const choices = dynamicChoicesData[element.name] || element.choices || [];
        let selectedChoice: any = '';
        if (dynamicChoicesData[element.name]) {
          selectedChoice = choices.find((choice) => {
            return choice.id && choice.id.toString() == value;
          });
          content = (
            <div className="dropdown-container">
              <p className="selected-choice-text">{selectedChoice ? selectedChoice.name : value}</p>
            </div>
          );
        } else {
          selectedChoice = choices.find((choice) => choice.value === value);

          content = (
            <div className="dropdown-container">
              <p className="selected-choice-text">{selectedChoice ? selectedChoice.text : value}</p>
            </div>
          );
        }
      }
      break;

    case 'tagbox':
      {
        const choices = dynamicChoicesData[element.name] || element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        const selectedTexts = selectedValues.map((selectedValue) => {
          const selectedChoice = choices.find((choice) => {
            return choice.id?.toString() === selectedValue.toString();
          });
          return selectedChoice ? selectedChoice.text || selectedChoice.name : selectedValue;
        });

        content =
          selectedTexts.length > 0 ? (
            <div className="tagbox-container">
              <p className="selected-tagbox-item">{selectedTexts.join(', ')}</p>
            </div>
          ) : (
            <p className="form-control">{Array.isArray(value) ? value.join(', ') : value}</p>
          );
      }
      break;

    case 'checkbox':
      {
        const choices = element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        const selectedTexts = choices
          .filter((choice) => selectedValues.includes(choice.value))
          .map((choice) => choice.text);

        content = (
          <div className="tagbox-container">
            {selectedTexts.map((text, index) => (
              <p key={index} className="selected-tagbox-item">
                {text}
              </p>
            ))}
          </div>
        );
      }
      break;

    case 'matrixdynamic': // Handle matrixdynamic type to render table
      {
        if (
          'columns' in element &&
          Array.isArray((element as any).columns) &&
          Array.isArray(value)
        ) {
          content = (
            <table className="matrix-table" style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  {(element as any).columns.map((col: any) => (
                    <th
                      key={col.name}
                      style={{
                        border: '1px solid #ccc',
                        padding: '8px',
                        backgroundColor: '#f5f5f5',
                        textAlign: 'left'
                      }}>
                      {col.title || col.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {value.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {(element as any).columns.map((col: any) => (
                      <td
                        key={col.name}
                        style={{
                          border: '1px solid #ccc',
                          padding: '8px',
                          textAlign: 'left'
                        }}>
                        <p className="form-control" style={{ margin: 0 }}>
                          {isValidDate(row[col.name])
                            ? formatDate(row[col.name])
                            : row[col.name] || ''}
                        </p>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          );
        } else {
          content = <p className="form-control">No data available</p>;
        }
      }
      break;

    case 'ranking':
      {
        const choices = element.choices || [];
        const selectedValues = Array.isArray(value) ? value : [value];

        content = (
          <div className="ranking-container">
            {selectedValues.map((selectedValue, index) => {
              const selectedChoice = choices.find((choice) => choice.value === selectedValue);
              const text = selectedChoice ? selectedChoice.text : '';

              return (
                <div key={index} className="ranking-item">
                  <p className="selected-ranking-text">{text}</p>
                </div>
              );
            })}
          </div>
        );
      }
      break;

    case 'boolean':
      {
        if (element.name === 'ComplaintStatus') {
          content = <p className="form-control">{value == '2' ? 'Yes' : 'No'}</p>;
        } else {
          content = (
            <p className="form-control">{value == true || value === 'Open' ? 'Yes' : 'No'}</p>
          );
        }
      }
      break;

    case 'file':
      {
        content =
          Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' ? (
            <>
              {value.map((file: any, index: number) => (
                <p
                  onClick={() => PresignedUrl(file.content, authToken)}
                  className="form-control edit-task-arrow-home file"
                  key={index}>
                  {file.name}
                </p>
              ))}
            </>
          ) : (
            <p className="form-control">{value}</p>
          );
      }
      break;

    case 'signaturepad':
      {
        content = <img src={value as string} alt="Signature" />;
      }
      break;

    case 'imagepicker':
      {
        const choices = element.choices || [];
        const selectedImages = Array.isArray(value) ? value : [value];
        content = (
          <div className="image-picker-container">
            {choices.map((choice, index) => (
              <div
                key={index}
                className={`image-picker-item ${
                  selectedImages.includes(choice.value) ? 'selected' : ''
                }`}>
                {selectedImages.includes(choice.value) && (
                  <>
                    <img src={choice?.imageLink} alt={choice.value} />
                    <p>{choice.value}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        );
      }
      break;

    case 'multipletext':
      {
        const items = element.items || [];

        content = (
          <div className="multiple-text-container">
            {items.map((item, index) => {
              const itemName: string = item.name;
              const itemTitle = item.title;

              const itemValue =
                itemName && typeof value === 'object' && value !== null
                  ? String(value[itemName as keyof typeof value])
                  : String(value);

              return (
                <div key={index} className="">
                  <p className="item-title">{itemTitle}</p>
                  <p className="item-value">{itemValue}</p>
                </div>
              );
            })}
          </div>
        );
      }
      break;

    case 'matrix':
      {
        content = <div className="matrix"></div>;
      }
      break;

    case 'html':
      {
        const htmlContent = element.html || '';
        content = (
          <div className="form-control" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
      }
      break;

    default:
      {
        if (element.inputType && element.inputType === 'date') {
          let dateValue: Date;

          if (typeof value === 'string' || typeof value === 'number') {
            dateValue = new Date(value);
          } else if (value instanceof Date) {
            dateValue = value;
          } else {
            throw new Error('Invalid date format');
          }

          content = <p className="form-control">{format(dateValue, 'd/MMM/yyyy')}</p>;
        } else {
          let filteredValue = value;
          if (typeof filteredValue === 'string') {
            filteredValue = filteredValue.replace(/(?:\r\n|\r|\n)/g, '<br>');
          }

          if (typeof filteredValue === 'string') {
            content = (
              <p className="form-control" dangerouslySetInnerHTML={{ __html: filteredValue }} />
            );
          } else {
            content = <p className="form-control">{filteredValue}</p>;
          }
        }
      }
      break;
  }

  return content;
};

// Helper function to check if a value is a valid date
function isValidDate(dateStr: string): boolean {
  const date = new Date(dateStr);
  return !isNaN(date.getTime());
}

// Helper function to format date to DD/MM/YYYY
function formatDate(dateStr: string): string {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-GB'); // 'en-GB' formats the date to DD/MM/YYYY
}
