import React from 'react';
import { CircularProgress } from '@mui/material';
import { ReviewsDataFormState } from './state';
import { FileReviewState, FinalizationReviewState } from '../../user/state';
import { useTranslation } from 'react-i18next';
import PdfGenerator from './PdfGenerator';
import { fetchReportPath } from '../../../../utils/commonUtils';
import DraftPDFGenerator from './DraftPDFGenerator';

interface ReportProps {
  loading: boolean;
  filereviews: FinalizationReviewState[];
  formData?: ReviewsDataFormState;
  filereviewData?: FileReviewState[];
}

const Report: React.FC<ReportProps> = ({ loading, filereviews, formData, filereviewData = [] }) => {
  const { t } = useTranslation();

  const allReviewsComplete =
    filereviews.length > 0 && filereviews.every((review) => review.is_complete === 1);

  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <h3>Report</h3>
      </div>
      {loading ? ( // Check loading state
        <div className="no-record">
          <CircularProgress />
        </div>
      ) : allReviewsComplete && formData?.finalised && filereviewData.length > 0 ? (
        <>
          <div className="no-record-finalise">{t('review.completedFinalised')}</div>
          <div className="inner-btn-wrap">
            <div style={{ marginTop: '20px' }}>
              <PdfGenerator
                report={fetchReportPath(formData.adviser_review_report)}
                from={'adviser-review'}
                status={'finalised'}
              />
            </div>
          </div>
        </>
      ) : !formData?.finalised && filereviewData.length > 0 ? (
        <>
          <div className="no-record-finalise">{t('review.addedFileReviews')}</div>
          <div className="inner-btn-wrap">
            <div style={{ marginTop: '20px' }}>
              {/* <PdfGenerator
                report={fetchReportPath(formData?.adviser_review_report)}
                from={'adviser-review'}
                status={'draft'}
              /> */}
              <DraftPDFGenerator formData={formData} from={'adviser-review'} />
            </div>
          </div>
        </>
      ) : (
        <div className="no-record-finalise">{t('review.incompleteFileReviews')}</div>
      )}
    </>
  );
};

export default Report;
