import React from 'react';
import { FormControl, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ProviderReviewFormProps } from './state';
import Select from '@mui/material/Select';
import { format } from 'date-fns';

const ProviderReviewCreateEditFormData: React.FC<ProviderReviewFormProps> = ({
  formData,
  errors,
  date_received,
  reviewerArray,
  closed_at,
  isDisabled,
  current_route,
  handleChangeReviewer,
  handleReviewDateInputChange,
  providerReviewAddMode,
  providerReviewIdEncrypted
}) => (
  <form action="" className="record-create-form">
    <div className="card-outline">
      <div className="card-title-wrap secondary-title-wrap">
        <h3>Contract Review Snapshot</h3>
      </div>
      {isDisabled &&
        current_route &&
        current_route === `/providers/review/${providerReviewIdEncrypted}` && (
          <div className="row-wrap">
            <div className="form-group">
              <label className="form-label">Created</label>
              <p className="form-control">
                {date_received ? format(new Date(date_received), 'd/MMM/yyyy') : ''}
              </p>
            </div>
            {formData.is_open === 0 && closed_at && (
              <div className="form-group">
                <label className="form-label">Completed</label>
                {closed_at ? format(new Date(closed_at), 'd/MMM/yyyy') : ''}
              </div>
            )}
            <div className="form-group">
              <label className="form-label">Reviewer</label>
              <p className="form-control">
                {formData.reviewer?.first_name + ' ' + formData.reviewer?.last_name}
              </p>
            </div>
          </div>
        )}
      {!isDisabled &&
        current_route &&
        (current_route === `/providers/review-edit/${providerReviewIdEncrypted}` ||
          current_route === `/providers/review-add`) &&
        !providerReviewAddMode && (
          <div className="row-wrap ">
            <div className="form-group">
              <label className="form-label">Date of review</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MMM/YYYY"
                  className="form-control date-picker"
                  value={formData.review_date ? dayjs(formData.review_date) : null}
                  onChange={handleReviewDateInputChange}
                  slotProps={{
                    textField: {
                      error: !!errors.review_date,
                      placeholder: 'Select Date',
                      helperText: errors.review_date
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
            {Array.isArray(reviewerArray) && reviewerArray.length > 0 && (
              <div className="form-group">
                <label className="form-label">Reviewer</label>
                <FormControl variant="outlined" error={!!errors.reviewer_id}>
                  <Select
                    error={!!errors.reviewer_id}
                    onChange={handleChangeReviewer}
                    labelId="Complaint-owner-label"
                    id="reviewer"
                    name="reviewer_id"
                    value={formData.reviewer_id?.toString()}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    className="custom-select"
                    MenuProps={{ className: 'custom-dropdown-menu' }}
                    renderValue={(selected) => {
                      if (selected === '0') {
                        return <p className="dropdown-placeholder">Select Reviewer</p>; // Render "Select Reviewer" as placeholder
                      }
                      const selectedOption =
                        reviewerArray &&
                        reviewerArray.find((option) => option.id === parseInt(selected, 10));
                      return selectedOption ? `${selectedOption.name}` : '';
                    }}>
                    {reviewerArray.map((option) => (
                      <MenuItem key={option?.id} value={option?.id || ''}>
                        {option?.name || ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.reviewer_id && (
                  <Typography variant="caption" color="error">
                    {errors.reviewer_id}
                  </Typography>
                )}
              </div>
            )}

            {formData.is_open === 0 && (
              <div className="form-group disabled-col">
                <label className="form-label">Completed</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    readOnly
                    format="DD/MMM/YYYY"
                    className="form-control date-picker"
                    value={closed_at ? dayjs(closed_at) : null}
                    slotProps={{
                      textField: {
                        error: !!errors.closed_at,
                        placeholder: 'Select Date',
                        helperText: errors.closed_at
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>
        )}
    </div>
  </form>
);

export default ProviderReviewCreateEditFormData;
