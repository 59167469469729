import axios from 'axios';
import {
  MeetingMinutesFormStates,
} from './../../views/components/modules/meetings/state.d';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const createMeetingMinute = async (
  formData: MeetingMinutesFormStates[],
  authToken?: string,
): Promise<MeetingMinutesFormStates[] | string | number | undefined> => {
  try {
    const routeUrl = `${process.env.REACT_APP_API_URL}/meeting-minute`;
    const response = await axios.post<ApiResponseType>(routeUrl, {'minutesData': formData}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
