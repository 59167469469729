import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const updateloginInfoAccount = async (
  accountId: number,
  authToken?: string
): Promise<string | undefined | null> => {
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('account_id', accountId.toString());
    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/update-user-login-info`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
