import { dataEncrypt, tokenCheck } from "../../utils/common/user";
import { ApiResponse, ApiResponseType } from "../apiResponse";

export const fetchAttestationTemplate = async (
  source_id: number,
  accountId: number,
  authToken: string,
  attestationId?: number,
) => {
  let routeUrl = `${process.env.REACT_APP_API_URL}/attestation-template?sourceId=${source_id}&accountId=${dataEncrypt(accountId?.toString())}`;
  if (attestationId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/attestation-template/${dataEncrypt(attestationId?.toString())}?sourceId=${source_id}&accountId=${dataEncrypt(accountId?.toString())}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
}