import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { ReviewsDataFormState } from './state';
import { FileReviewState, FinalizationReviewState } from '../../user/state';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface FinaliseReviewsProps {
  loading: boolean;
  reviewId?: string;
  filereviews: FinalizationReviewState[];
  formData?: ReviewsDataFormState;
  sourceId?: string | null;
  reviewIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
  filereviewData?: FileReviewState[];
}

const FinaliseReviews: React.FC<FinaliseReviewsProps> = ({
  loading,
  filereviews,
  formData,
  reviewIdEncrypted,
  sourceIdEncrypted,
  filereviewData = []
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleComplete = () => {
    navigate(`/review-finalise/${reviewIdEncrypted}?source=${sourceIdEncrypted}`);
  };

  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <h3>Finalise Reviews</h3>
      </div>
      {loading ? ( // Check loading state
        <div className="no-record">
          <CircularProgress />
        </div>
      ) : formData &&
        formData.file_reviews_with_status?.file_review_status == '0' &&
        !formData.finalised &&
        filereviews.length > 0 &&
        filereviewData.length > 0 ? (
        <div className="inner-btn-wrap wrap-center">
          <React.Fragment>
            <Button className="btn primary-btn" onClick={() => handleComplete()}>
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Finalise Review
            </Button>
          </React.Fragment>
        </div>
      ) : (
        <div className="no-record-finalise">
          {filereviews.length === 0
            ? t('review.noFinalisePermission')
            : formData && formData.finalised
              ? `The Adviser Review was marked as FINALISED by ${
                  formData?.finalised_by?.first_name
                } ${formData?.finalised_by?.last_name} on ${
                  formData?.finalised_at
                    ? format(new Date(formData?.finalised_at), 'd/MMM/yyyy')
                    : ''
                }.`
              : formData && formData.file_reviews_with_status?.file_review_status == '1'
                ? t('review.noFinalisePermissionWithoutComplete')
                : null}
        </div>
      )}
    </>
  );
};

export default FinaliseReviews;
