import React from 'react';
import { Pie } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);

interface PieChartProps {
  piedata: {
    labels: string[];
    data: number[];
  };
}

const PieChart: React.FC<PieChartProps> = ({ piedata }) => {
  const chartData = {
    labels: piedata.labels,
    datasets: [
      {
        data: piedata.data,
        backgroundColor: ['#F04438', '#EAECF0'],
        hoverBackgroundColor: ['#F04438', '#EAECF0'],
        borderWidth: 0,
      },
    ],
  };

//   const options = {
//     plugins: {
//       legend: {
//         display: true,
//         position: 'right',
//       },
//       tooltip: {
//         callbacks: {
//           label: (tooltipItem: any, data: any) => {
//             const dataset = data.datasets[tooltipItem.datasetIndex];
//             const total = dataset.data.reduce(
//               (previousValue: number, currentValue: number) =>
//                 previousValue + currentValue
//             );
//             const currentValue = dataset.data[tooltipItem.index];
//             const percentage = (
//               (currentValue / total) *
//               100
//             ).toFixed(2);
//             return `${data.labels[tooltipItem.index]}: ${percentage}%`;
//           },
//         },
//       },
//     },
//   };

  return (
    <div className="pie-chart">
      <Pie data={chartData}   />
    </div>
  );
};

export default PieChart;
