import { dataEncrypt, tokenCheck } from "../../utils/common/user";
import { ApiResponse, ApiResponseType } from "../apiResponse";

export const fetchUserOnboardingTemplate = async (
  source_id: number,
  accountId: number,
  authToken: string,
) => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/user-onboarding-templates?sourceId=${dataEncrypt(source_id?.toString())}&accountId=${dataEncrypt(accountId?.toString())}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
}