import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import LeftNav from '../LeftNav';
import { UserFormData, UserUpdateCreateResponseError } from '../state';
import { saveUser } from '../../../../api/settings/saveUser';
import {
  useAuthToken,
  useCurrentUserInfo,
  getSelectedAccountInfo,
  checkUserPermission,
  decryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { fetchUserData } from '../../../../api/settings/fetchSettingUsersListing';
import CreateEditFormData from './CreateEditFormData';
import { fetchTimezones } from '../../../../api/dashboard/fetchTimezones';
import { TimezoneResponse } from '../state';
import { fetchRoles } from '../../../../api/dashboard/fetchRoles';
import { RolesResponse } from '../state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useNotificationContext } from '../../layout/NotificationContext';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';

const UserCreateEdit: React.FC = () => {
  let { id } = useParams();
  id = id ? decryptData(id, true) : 0;
  const isEditing = !!id; // Check if we are in edit mode
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const title = 'Users';
  const pageTitle = isEditing ? 'Edit User' : 'Add User';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const authToken = useAuthToken();
  const user = useCurrentUserInfo();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const selectedAccount = getSelectedAccountInfo();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [timezone, setTimezone] = useState<string>('');
  const [imageChanged, setImageChanged] = useState(false);
  const [updateLogo, setUpdateLogo] = useState<Blob | null>(null);
  const { setNotificationSuccess } = useNotificationContext();
  const [timezoneOptionsWithValueLabel, setTimezoneOptionsWithValueLabel] = useState<
    TimezoneResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [roleValueLabel, setRoleValueLabel] = useState<RolesResponse[]>([]);
  const [formData, setFormData] = useState<UserFormData>({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    profile_image: null,
    timezone: '',
    is_open: true,
    isAdviser: true,
    is_consultant: false,
    lu_user_access_level_id: '',
    error: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setImageChanged(true);
    if (selectedFile) {
      setUpdateLogo(selectedFile);
      setFormData((prevData) => ({ ...prevData, profile_image: selectedFile }));
    } else {
      setUpdateLogo(null);
    }
  };

  const handleSwitchChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFormData((prevData) => ({ ...prevData, [fieldName]: checked }));
    };

  const CancelRequest = () => {
    navigate(`/settings/user`);
  };

  const removeHandle = () => {
    setImageChanged(true);
    setFormData((prevData) => ({ ...prevData, profile_image: null }));
    setUpdateLogo(null);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.first_name) {
      newErrors.first_name = t('error.field.firstname');
    }
    if (!formData.last_name) {
      newErrors.last_name = t('error.field.lastname');
    }
    if (!formData.email) {
      newErrors.email = t('error.field.email');
    }

    if (!role) {
      newErrors.lu_user_access_level_id = t('error.field.userRole');
    }

    if (!timezone) {
      newErrors.timezone = t('error.field.timezone');
    }

    // const phoneRegex = /^[0-9]{10}$/;
    // if (formData.phone && !phoneRegex.test(formData.phone)) {
    //   newErrors.phone = t('error.field.phone');
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    setLoading(true);

    // Prepare the data to send to the API
    const requestData = {
      id: formData.id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      profile_image: updateLogo,
      timezone,
      is_open: formData.is_open,
      isAdviser: formData.isAdviser,
      is_consultant: formData.is_consultant,
      lu_user_access_level_id: role,
      error: ''
    };
    const response: UserUpdateCreateResponseError = (await saveUser(
      requestData,
      authToken,
      user,
      selectedAccount,
      imageChanged
    )) as UserUpdateCreateResponseError;
    if (response) {
      if (response == t('permission.doesNotHaveAccessPermission')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('permission.doesNotHaveAccessPermission')
        }));
      } else {
        const newErrors: Record<string, string> = {};
        if (response.first_name) {
          newErrors.first_name = response.first_name[0];
        } else if (response.last_name) {
          newErrors.last_name = response.last_name[0];
        } else if (response.email) {
          newErrors.email = response.email[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
      }
      setLoading(false);
    } else {
      setLoading(false);
      const msg = formData.id ? t('success.field.userUpdated') : t('success.field.userCreated');
      setNotificationSuccess(msg);
      navigate('/settings/user');
    }
  };

  const handleChangeTimezone = (event: SelectChangeEvent<string>) => {
    setTimezone(event.target.value);
  };

  const handleChangeRole = (event: SelectChangeEvent<string>) => {
    setRole(event.target.value);
  };

  useEffect(() => {
    const permission = id ? UPDATE : CREATE;
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 2, permission)) {
      navigate('/dashboard');
    }
    setIsAccessable(true);
  }, []);

  useEffect(() => {
    fetchTimezonesApi();
    fetchUserDataApi();
    fetchRolesApi();
  }, [isEditing, id, authToken]);

  const fetchUserDataApi = async () => {
    try {
      if (isEditing && id) {
        const userId = parseInt(id, 10);
        const userData = await fetchUserData(1, '', '1', authToken, selectedAccount, userId);
        setFormData({
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
          timezone: userData.timezone || '',
          is_open: userData.is_open === 1,
          is_consultant: userData.is_consultant === 1,
          isAdviser:
            userData.user_access && userData.user_access.length > 0
              ? userData.user_access[0].isAdviser
              : 1,
          lu_user_access_level_id: userData.lu_user_access_level_id
            ? userData.lu_user_access_level_id
            : '',
          profile_image: userData.profile_image || null
        });

        if (userData.timezone) {
          setTimezone(userData.timezone);
        }
        if (userData.lu_user_access_level_id) {
          setRole(userData.lu_user_access_level_id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTimezonesApi = async () => {
    try {
      const response: TimezoneResponse[] = await fetchTimezones();
      if (response) {
        setTimezoneOptionsWithValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching timezones:', error);
    }
  };

  const fetchRolesApi = async () => {
    try {
      const response: RolesResponse[] = await fetchRoles();
      if (response) {
        setRoleValueLabel(response);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    <CreateEditFormData
                      user={user!}
                      formData={formData}
                      imageInputRef={imageInputRef}
                      handleChangeTimezone={handleChangeTimezone}
                      handleChangeRole={handleChangeRole}
                      handleInputChange={handleInputChange}
                      handleFileChange={handleFileChange}
                      handleSwitchChange={handleSwitchChange}
                      CancelRequest={CancelRequest}
                      onSubmit={handleSubmit}
                      removeHandle={removeHandle}
                      timezoneOptionsWithValueLabel={timezoneOptionsWithValueLabel}
                      roleValueLabel={roleValueLabel}
                      timezone={timezone}
                      role={role}
                      updateLogo={updateLogo}
                      errors={errors}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default UserCreateEdit;
