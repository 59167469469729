import React from 'react';
import LeftNav from '../LeftNav';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Button, MenuItem, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@mui/material/Select';
import { InputAdornment } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import HomeIc from '../../../../assets/images/home-outline.svg';
import rightArrowIc from '../../../../assets/images/right-arrow.svg';
import filter from '../../../../assets/images/filter.svg';
import search from '../../../../assets/images/search.svg';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconButton } from '@mui/material';

const RegisterUpdateIndex: React.FC = () => {
  return (
    <div className="settings-outer-wrap">
      <div className="breadcrumbs-title-wrap">
        <div className="breadcrumbs-wrap">
          <Breadcrumbs aria-label="breadcrumb" separator={<img src={rightArrowIc} alt="arrow" />}>
            <Link href="/dashboard">
              <img src={HomeIc} alt="Home" />
            </Link>
            <Link href="/settings">Settings</Link>
            <span>Regulatory Updates</span>
          </Breadcrumbs>
        </div>
        <div className="title-wrap">
          <h2>Regulatory Updates</h2>
          <Button className="btn primary-btn" component={RouterLink} to="/settings/add-reg-update">
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Add Regulatory Update
          </Button>
          <IconButton aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
        </div>
      </div>
      <div className="settings-outer-row">
        <LeftNav />
        <div className="right-outer">
          <div className="right-inner">
            <form>
              <div className="filter-outer">
                <div className="filter-list">
                  <Button className="btn border-btn">
                    <i className="left">
                      <img src={filter} alt="Filter" />
                    </i>
                    Filters
                  </Button>
                </div>
                <div className="filter-list">
                  <div className="form-group">
                    <TextField
                      id="input-with-icon-textfield"
                      className="form-control"
                      placeholder="First name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="search" />
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="form-group">
                    <TextField
                      id="input-with-icon-textfield"
                      className="form-control"
                      placeholder="Last name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={search} alt="search" />
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="form-group">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={10}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value={10} selected>
                        Status
                      </MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterUpdateIndex;
