import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ProfessionalYearsLeftMenu from './ProfessionalYearsLeftMenu';
import { Button, SelectChangeEvent } from '@mui/material';
import {
  checkUserPermission,
  decryptData,
  encryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import {
  CREATE,
  DELETE,
  MODULES,
  NOTE_CREATE,
  READ,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
import DeleteButton from '../../common/DeleteButton';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { useNotificationContext } from '../../layout/NotificationContext';
import { DropdownResponse } from '../../user/state';
import { ProfessionalYearState, ProfessionalYearTemplatesState } from './state';
import Popup from '../../common/popup/Popup';
import { fetchProfessionalYears } from '../../../../api/professional-year/fetchProfessionalYears';
import ProfessionalYearCreateEditFormData from './ProfessionalYearCreateEditFormData';
import { createProfessionalYearData } from '../../../../api/professional-year/createProfessionalYearData';
import { deleteProfessionalYear } from '../../../../api/professional-year/deleteProfessionalYear';
import ProfessionalYearLogbookWorkactivityTemplateTableMode from './ProfessionalYearLogbookWorkactivityTemplateTableMode';
import { fetchNewEntrantUsersWithNoRecords } from '../../../../api/common/fetchNewEntrantUsersWithNoRecords';
import { fetchProfessionalYearTemplates } from '../../../../api/settings/fetchProfessionalYearTemplates';
import ProfessionalYearLogTrainingTemplateTableMode from './ProfessionalYearLogTrainingTemplateTableMode';
import { format } from 'date-fns';
import { fetchRepresentativeUsers } from '../../../../api/common/fetchRepresentativeUsers';

const ProfessionalYearCreateEdit: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const user = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const { pathname, search } = useLocation();
  const current_route = pathname;
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const { moduleRecords } = useModuleContext();
  const { setNotificationSuccess } = useNotificationContext();
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [advisers, setAdvisers] = useState<DropdownResponse[]>([]);
  const [owners, setOwners] = useState<DropdownResponse[]>([]);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  }

  const mainPath = `/professional-year/${encryptedSourceId}`;
  const pageTitle = id ? `Professional Year Details` : `Add Professional Year`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [planSupervisorTemplate, setPlanSupervisorTemplate] = useState<string>('');
  const [logbookWorkactivityTemplate, setLogbookWorkactivityTemplate] = useState<string>('');
  const [q1SupervisorTemplate, setQ1SupervisorTemplate] = useState<string>('');
  const [q1EntrantTemplate, setQ1EntrantTemplate] = useState<string>('');
  const [q2SupervisorTemplate, setQ2SupervisorTemplate] = useState<string>('');
  const [q2EntrantTemplate, setQ2EntrantTemplate] = useState<string>('');
  const [q3SupervisorTemplate, setQ3SupervisorTemplate] = useState<string>('');
  const [q3EntrantTemplate, setQ3EntrantTemplate] = useState<string>('');
  const [q4SupervisorTemplate, setQ4SupervisorTemplate] = useState<string>('');
  const [q4EntrantTemplate, setQ4EntrantTemplate] = useState<string>('');
  const [completionSupervisorTemplate, setCompletionSupervisorTemplate] = useState<string>('');
  const [completionLicenseeTemplate, setCompletionLicenseeTemplate] = useState<string>('');
  const [examSupervisorTemplate, setExamSupervisorTemplate] = useState<string>('');
  const [examEntrantTemplate, setExamEntrantTemplate] = useState<string>('');
  const [logTrainingTemplate, setLogTrainingTemplate] = useState<string>('');
  const [isSwitchChange, setIsSwitchChange] = useState<boolean>(false);
  const [formData, setFormData] = useState<ProfessionalYearState>({
    id: 0,
    module_id: source_Id,
    new_entrant_id: 0,
    supervisor_id: 0,
    other_supervisor_id: 0,
    plan_supervisor_template_id: 0,
    logbook_workactivity_template_id: 0,
    plan_complete: 0,
    q1_complete: 0,
    q2_complete: 0,
    q3_complete: 0,
    q4_complete: 0,
    exam_passed: 0,
    exam_passed_confirmation: 0,
    py_complete_supervisor: 0,
    py_complete_licensee: 0,
    is_open: 1,
    error: ''
  });

  useEffect(() => {
    const permission =
      current_route === `/professional-year/details/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const professionalYearId = parseInt(id!, 10);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const response: ProfessionalYearState = (await fetchProfessionalYears(
          authToken,
          selectedAccount,
          professionalYearId
        )) as ProfessionalYearState;

        if (response) {
          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const fetchNewEntrantUsersWithNoRecord = async () => {
    if (
      current_route == `/professional-year-add` ||
      current_route == `/professional-year-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchNewEntrantUsersWithNoRecords(
          authToken,
          selectedAccount,
          parseInt(id!, 10)
        );
        if (response) {
          setAdvisers(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  const fetchOwnersUsers = async () => {
    if (
      current_route == `/professional-year-add` ||
      current_route == `/professional-year-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchRepresentativeUsers(
          authToken,
          selectedAccount
        );
        if (response) {
          setOwners(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  const getProfessionalYearTemplates = async () => {
    try {
      if (authToken) {
        const response: ProfessionalYearTemplatesState[] = (await fetchProfessionalYearTemplates(
          authToken,
          selectedAccount,
          source_Id,
          professionalYearId || 0
        )) as ProfessionalYearTemplatesState[];

        if (response.length > 0) {
          response.map((template: ProfessionalYearTemplatesState) => {
            if (template.template !== '""') {
              if (template.type === t('templateType.planSupervisor')) {
                setPlanSupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  plan_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.logWorkactivity')) {
                setLogbookWorkactivityTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  logbook_workactivity_template_id: template.id
                }));
              } else if (template.type === t('templateType.q1Supervisor')) {
                setQ1SupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  q1_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.q1Entrant')) {
                setQ1EntrantTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, q1_entrant_template_id: template.id }));
              } else if (template.type === t('templateType.q2Supervisor')) {
                setQ2SupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  q2_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.q2Entrant')) {
                setQ2EntrantTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, q2_entrant_template_id: template.id }));
              } else if (template.type === t('templateType.q3Supervisor')) {
                setQ3SupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  q3_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.q3Entrant')) {
                setQ3EntrantTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, q3_entrant_template_id: template.id }));
              } else if (template.type === t('templateType.q4Supervisor')) {
                setQ4SupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  q4_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.q4Entrant')) {
                setQ4EntrantTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, q4_entrant_template_id: template.id }));
              } else if (template.type === t('templateType.completionSupervisor')) {
                setCompletionSupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  completion_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.completionLicensee')) {
                setCompletionLicenseeTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  completion_licensee_template_id: template.id
                }));
              } else if (template.type === t('templateType.examSupervisor')) {
                setExamSupervisorTemplate(template.template);
                setFormData((prevData) => ({
                  ...prevData,
                  exam_supervisor_template_id: template.id
                }));
              } else if (template.type === t('templateType.examEntrant')) {
                setExamEntrantTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, exam_entrant_template_id: template.id }));
              } else if (template.type === t('templateType.logTraining')) {
                setLogTrainingTemplate(template.template);
                setFormData((prevData) => ({ ...prevData, log_training_template_id: template.id }));
              }
            } else {
              localStorage.removeItem('survey-json-professional-year-plan-template');
              setFormData((prevData) => ({
                ...prevData,
                error: t('error.field.notemplateFound')
              }));
            }
          });
        } else {
          setFormData((prevData) => ({
            ...prevData,
            error: t('error.field.notemplateFound')
          }));
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (
      current_route == `/professional-year-add` ||
      current_route == `/professional-year/details/${encryptedId}`
    ) {
      getProfessionalYearTemplates();
    }

    if (!hasEffectRun) {
      setHasEffectRun(true);
      fetchData(); // Call the async function
      fetchNewEntrantUsersWithNoRecord();
      fetchOwnersUsers();
    }
  }, [current_route, hasEffectRun]);

  const handleCancelClick = () => {
    if (id) {
      navigate(`/professional-year/details/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/professional-year/${encryptedSourceId}`);
    }
  };

  useEffect(() => {
    if (isSwitchChange) {
      handleFormSubmit();
    }
  }, [isSwitchChange]);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.new_entrant_id) {
      newErrors.new_entrant_id = t('error.field.newEntrantRequired');
    }
    if (!formData.supervisor_id) {
      newErrors.supervisor_id = t('error.field.supervisorRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createProfessionalYearData(
          formData,
          authToken,
          selectedAccount,
          edit_id
        );

        if (response && response.id) {
          if (!isSwitchChange) {
            const msg = id
              ? t('success.field.professionalYearUpdated')
              : t('success.field.professionalYearCreated');

            setHasEffectRun(false);
            setNotificationSuccess(msg);
            navigate(
              `/professional-year/details/${encryptData(
                String(response.id),
                true
              )}?source=${encryptedSourceId}`
            );
          } else {
            setIsSwitchChange(!isSwitchChange);
          }
        } else {
          const newErrors: Record<string, string> = {};

          if (response.adviser_id) {
            newErrors.adviser_id = response.adviser_id[0];
          } else if (response.owner_id) {
            newErrors.owner_id = response.owner_id[0];
          } else if (response === 'Record already exists for the selected entrant user') {
            setFormData((prevData) => ({ ...prevData, error: response }));
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const handleComplete = async (status: number) => {
    const requestData = {
      ...formData,
      is_open: status
    };

    const edit_id = id ? id : formData.id;
    try {
      const response: any = await createProfessionalYearData(
        requestData,
        authToken,
        selectedAccount,
        edit_id
      );

      if (response && response.id) {
        setNotificationSuccess(t('success.field.professionalYearUpdated'));
        fetchData();
      } else {
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    } catch (error) {
      // Handle other error cases
      console.error(error); // Log the error
      // You can handle other error cases as needed
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: ProfessionalYearState = (await deleteProfessionalYear(
          parseInt(id!, 10),
          authToken
        )) as ProfessionalYearState;

        if (response.id) {
          setNotificationSuccess(t('success.field.professionalYearDeleted'));
          navigate(`/professional-year/${encryptedSourceId}`);
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const fetchReportPath = (report: string | undefined) => {
    const reportObject = report ? JSON.parse(report) : null;
    const typedReportObject = reportObject as { path: string; original_name: string };

    if (report) {
      return typedReportObject.path;
    } else {
      return null;
    }
  };

  const handleViewCertificate = (certificate: string | undefined) => {
    const report = fetchReportPath(certificate);
    const componentBUrl = report ? `${report}` : '';
    window.open(componentBUrl, '_blank');
  };

  const HandlesnapshotIsDisabled = () => {
    navigate(`/professional-year-edit/${encryptedId}?source=${encryptedSourceId}`);
  };

  let edit_btn_snapshot;
  let addButton;
  if (formData && formData.is_open == 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {current_route === `/professional-year/details/${encryptedId}` && (
          <>
            {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/professional-year/add-note?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}
            {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/professional-year/add-task?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </>
        )}
      </div>
    );

    edit_btn_snapshot = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id ? formData.unique_id : pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                errorMsg={formData.error}
                button={id ? addButton : undefined}
              />

              <div className="record-outer-row settings-outer-row">
                <ProfessionalYearsLeftMenu
                  user={user}
                  professionalYearId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formData_record={formData}
                  professionalYearIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {(current_route == `/professional-year-add` ||
                      current_route == `/professional-year-edit/${encryptedId}` ||
                      current_route == `/professional-year/details/${encryptedId}`) && (
                      <ProfessionalYearCreateEditFormData
                        formData={formData}
                        errors={errors}
                        adviserArray={advisers}
                        ownerArray={owners}
                        current_route={current_route}
                        handleSelectChange={handleSelectChange}
                        handleComplete={handleComplete}
                        professionalYearIdEncrypted={encryptedId}
                        sourceIdEncrypted={encryptedSourceId}
                        edit_btn_snapshot={edit_btn_snapshot}
                      />
                    )}

                    {formData &&
                      formData.id !== 0 &&
                      current_route === `/professional-year/details/${encryptedId}` && (
                        <>
                          {/* Progress Card */}
                          <div className="card-outline">
                            <div className="card-title-wrap secondary-title-wrap">
                              <div>
                                <h3>Progress</h3>
                              </div>
                            </div>
                            <div className="progress-outer">
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.plan_complete == 0
                                      ? 'red'
                                      : formData.plan_complete == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Annual Plan</h3>
                                    {/* <p>Start date: 01/Jan/2024</p> */}
                                  </div>
                                  <div className="progress-btn-wrap">
                                    <Button
                                      className="btn btn-sm blue-fill-btn"
                                      component={RouterLink}
                                      to={`/professional-year/plan-supervisor-edit?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                      <i className="left">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                                          <g clipPath="url(#clip0_6545_879)">
                                            <path
                                              d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                              stroke="CurrentColor"
                                              strokeWidth="1.66667"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_6545_879">
                                              <rect width="20" height="20" fill="white"></rect>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </i>
                                      Update
                                    </Button>
                                    {formData.plan_complete == 1 ? (
                                      <Button
                                        className="btn btn-sm blue-fill-btn"
                                        component={RouterLink}
                                        to={`/professional-year/plan-supervisor-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                        <i className="left">
                                          <svg
                                            width="19"
                                            height="19"
                                            viewBox="0 0 19 19"
                                            fill="none">
                                            <g clipPath="url(#clip0_6545_879)">
                                              <path
                                                d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                stroke="CurrentColor"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_6545_879">
                                                <rect width="20" height="20" fill="white"></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </i>
                                        View
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.plan_complete == 1 && formData.q1_complete == 0
                                      ? 'orange'
                                      : formData.plan_complete == 1 && formData.q1_complete == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Q1 Plan</h3>
                                    <p>
                                      {formData.q1_start_date
                                        ? `Start date: ${format(
                                            new Date(formData.q1_start_date || ''),
                                            'd/MMM/yyyy'
                                          )}`
                                        : 'Not yet started'}
                                    </p>
                                  </div>

                                  {formData.plan_complete == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.q1_complete == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/q1plan-supervisor-edit?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.q1_complete == 1 ? (
                                        <>
                                          <Button
                                            className="btn btn-sm white-btn"
                                            onClick={() =>
                                              handleViewCertificate(
                                                formData.q1_completion_certificate
                                              )
                                            }>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    strokeWidth="1.2"
                                                    stroke="#667085"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            Completion Certificate
                                          </Button>
                                          <Button
                                            className="btn btn-sm blue-fill-btn"
                                            component={RouterLink}
                                            to={`/professional-year/q1plan-supervisor-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    stroke="CurrentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.q1_complete == 1 && formData.q2_complete == 0
                                      ? 'orange'
                                      : formData.q1_complete == 1 && formData.q2_complete == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Q2 Plan</h3>
                                    <p>
                                      {formData.q2_start_date
                                        ? `Start date: ${format(
                                            new Date(formData.q2_start_date || ''),
                                            'd/MMM/yyyy'
                                          )}`
                                        : 'Not yet started'}
                                    </p>
                                  </div>
                                  {formData.q1_complete == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.q2_complete == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/q2plan-supervisor-edit?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.q2_complete == 1 ? (
                                        <>
                                          <Button
                                            className="btn btn-sm white-btn"
                                            onClick={() =>
                                              handleViewCertificate(
                                                formData.q2_completion_certificate
                                              )
                                            }>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    strokeWidth="1.2"
                                                    stroke="#667085"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            Completion Certificate
                                          </Button>
                                          <Button
                                            className="btn btn-sm blue-fill-btn"
                                            component={RouterLink}
                                            to={`/professional-year/q2plan-supervisor-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    stroke="CurrentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.exam_passed == 1 &&
                                    formData.exam_passed_confirmation == 0
                                      ? 'orange'
                                      : formData.exam_passed == 1 &&
                                          formData.exam_passed_confirmation == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Financial Adviser Exam</h3>
                                    {/* <p>Required</p> */}
                                  </div>
                                  {formData.exam_passed == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.exam_passed_confirmation == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/exam?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.exam_passed_confirmation == 1 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/exam-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          View
                                        </Button>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.q2_complete == 1 && formData.q3_complete == 0
                                      ? 'orange'
                                      : formData.q2_complete == 1 && formData.q3_complete == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Q3 Plan</h3>
                                    <p>
                                      {formData.q3_start_date
                                        ? `Start date: ${format(
                                            new Date(formData.q3_start_date || ''),
                                            'd/MMM/yyyy'
                                          )}`
                                        : 'Not yet started'}
                                    </p>
                                  </div>
                                  {formData.q2_complete == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.q3_complete == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/q3plan-supervisor-edit?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.q3_complete == 1 ? (
                                        <>
                                          <Button
                                            className="btn btn-sm white-btn"
                                            onClick={() =>
                                              handleViewCertificate(
                                                formData.q3_completion_certificate
                                              )
                                            }>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    strokeWidth="1.2"
                                                    stroke="#667085"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            Completion Certificate
                                          </Button>
                                          <Button
                                            className="btn btn-sm blue-fill-btn"
                                            component={RouterLink}
                                            to={`/professional-year/q3plan-supervisor-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    stroke="CurrentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.q3_complete == 1 && formData.q4_complete == 0
                                      ? 'orange'
                                      : formData.q3_complete == 1 && formData.q4_complete == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Q4 Plan</h3>
                                    <p>
                                      {formData.q4_start_date
                                        ? `Start date: ${format(
                                            new Date(formData.q4_start_date || ''),
                                            'd/MMM/yyyy'
                                          )}`
                                        : 'Not yet started'}
                                    </p>
                                  </div>
                                  {formData.q3_complete == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.q4_complete == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/q4plan-supervisor-edit?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.q4_complete == 1 ? (
                                        <>
                                          <Button
                                            className="btn btn-sm white-btn"
                                            onClick={() =>
                                              handleViewCertificate(
                                                formData.q4_completion_certificate
                                              )
                                            }>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    strokeWidth="1.2"
                                                    stroke="#667085"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            Completion Certificate
                                          </Button>
                                          <Button
                                            className="btn btn-sm blue-fill-btn"
                                            component={RouterLink}
                                            to={`/professional-year/q4plan-supervisor-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    stroke="CurrentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="progress-list">
                                <div
                                  className={`progress-inner ${
                                    formData.py_complete_supervisor == 1 &&
                                    formData.py_complete_licensee == 0
                                      ? 'orange'
                                      : formData.py_complete_supervisor == 1 &&
                                          formData.py_complete_licensee == 1
                                        ? 'green'
                                        : ''
                                  }`}>
                                  <div className="content-wrapper">
                                    <h3>Completion</h3>
                                  </div>
                                  {formData.q4_complete == 1 && (
                                    <div className="progress-btn-wrap">
                                      {formData.py_complete_supervisor == 0 ||
                                      formData.py_complete_licensee == 0 ? (
                                        <Button
                                          className="btn btn-sm blue-fill-btn"
                                          component={RouterLink}
                                          to={`/professional-year/completion?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                          <i className="left">
                                            <svg
                                              width="19"
                                              height="19"
                                              viewBox="0 0 19 19"
                                              fill="none">
                                              <g clipPath="url(#clip0_6545_879)">
                                                <path
                                                  d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                  stroke="CurrentColor"
                                                  strokeWidth="1.66667"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_6545_879">
                                                  <rect width="20" height="20" fill="white"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </i>
                                          Update
                                        </Button>
                                      ) : formData.py_complete_supervisor == 1 &&
                                        formData.py_complete_licensee == 1 ? (
                                        <>
                                          <Button
                                            className="btn btn-sm white-btn"
                                            onClick={() =>
                                              handleViewCertificate(formData.completion_certificate)
                                            }>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    strokeWidth="1.2"
                                                    stroke="#667085"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            Completion Certificate
                                          </Button>
                                          <Button
                                            className="btn btn-sm blue-fill-btn"
                                            component={RouterLink}
                                            to={`/professional-year/completion-view?source=${encryptedSourceId}&professionalYearId=${encryptedId}`}>
                                            <i className="left">
                                              <svg
                                                width="19"
                                                height="19"
                                                viewBox="0 0 19 19"
                                                fill="none">
                                                <g clipPath="url(#clip0_6545_879)">
                                                  <path
                                                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                                                    stroke="CurrentColor"
                                                    strokeWidth="1.66667"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_6545_879">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"></rect>
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </i>
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Workactiviry Log Card */}
                          <div className="card-outline">
                            <ProfessionalYearLogbookWorkactivityTemplateTableMode
                              user={user}
                              sourceId={source_Id}
                              formData={formData}
                              surveyData={
                                logbookWorkactivityTemplate
                                  ? JSON.parse(logbookWorkactivityTemplate)
                                  : {}
                              }
                              professionalYearId={parseInt(id!, 10)}
                              authToken={authToken}
                              professionalYearIdEncrypted={encryptedId}
                              sourceIdEncrypted={encryptedSourceId}
                            />
                          </div>

                          {/* Training Log Card */}
                          <div className="card-outline">
                            <ProfessionalYearLogTrainingTemplateTableMode
                              user={user}
                              sourceId={source_Id}
                              formData={formData}
                              surveyData={
                                logTrainingTemplate ? JSON.parse(logTrainingTemplate) : {}
                              }
                              professionalYearId={parseInt(id!, 10)}
                              // add_btn={logbook_add_btn}
                              authToken={authToken}
                              professionalYearIdEncrypted={encryptedId}
                              sourceIdEncrypted={encryptedSourceId}
                            />
                          </div>
                        </>
                      )}

                    {(current_route == `/professional-year-add` ||
                      current_route == `/professional-year-edit/${encryptedId}`) && (
                      <div className="btn-wrap">
                        <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                          Cancel
                        </Button>
                        {((planSupervisorTemplate &&
                          logbookWorkactivityTemplate &&
                          q1SupervisorTemplate &&
                          q1EntrantTemplate &&
                          q2SupervisorTemplate &&
                          q2EntrantTemplate &&
                          q3SupervisorTemplate &&
                          q3EntrantTemplate &&
                          q4SupervisorTemplate &&
                          q4EntrantTemplate &&
                          completionSupervisorTemplate &&
                          completionLicenseeTemplate &&
                          examSupervisorTemplate &&
                          examEntrantTemplate &&
                          logTrainingTemplate) ||
                          current_route == `/professional-year-edit/${encryptedId}`) && (
                          <Button
                            className="btn primary-btn btn-sm"
                            type="submit"
                            onClick={() => handleFormSubmit()}>
                            Save
                          </Button>
                        )}
                      </div>
                    )}

                    {checkUserPermission(user, MODULES, source_Id, DELETE) &&
                      current_route === `/professional-year/details/${encryptedId}` && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default ProfessionalYearCreateEdit;
