import { ApiResponse } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { format } from 'date-fns';
import { dataEncrypt } from '../../utils/common/user';

export const downloadTasksReport = async (
  authToken: string,
  accountInfo: AccountState,
  isMytasks: number,
  title: string
) => {
  try {
    const selectedAccountId = accountInfo?.id;
    const routeUrl = `${process.env.REACT_APP_API_URL}/export-tasks?accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&isMytasks=${dataEncrypt(String(isMytasks))}&title=${title}`;

    const response = await fetch(routeUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const extension = 'xlsx';
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = accountInfo.name.replace(/\s/g, '') + '-' + title.toLowerCase().replace(/\s/g, '') + '-' + format(new Date(), 'ddMMyyyy-hhmm') + `.${extension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return response;
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
