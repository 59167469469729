import React, { useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Grid
} from '@mui/material';
import { useRegistersContext } from '../../registers/RegisterContext';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { exportData } from '../../../../api/reporting/exportData';

const ExportDataIndex = () => {
  const title = 'Export Data';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Reports';
  const thirdTitle = 'Export';
  const secondpath = '/dashboard';
  const pageTitle = `Export Data`;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { registersRecords } = useRegistersContext();
  const { moduleRecords } = useModuleContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [noDataMessage, setNoDataMessage] = useState<string>('');

  // Create new arrays for newFilteredModuleRecords and newFilteredRegisterRecords
  let newFilteredModuleRecords = Array.isArray(moduleRecords) ? [...moduleRecords] : [];
  let newFilteredRegisterRecords = Array.isArray(registersRecords) ? [...registersRecords] : [];

  // Filter the new arrays and prepend "MOD" with the ID for modules
  if (newFilteredModuleRecords.length > 0) {
    newFilteredModuleRecords = newFilteredModuleRecords
      .filter(
        (module) =>
          Array.isArray(module.account_module_access) && module.account_module_access.length > 0
      )
      .map((module) => ({
        ...module,
        newId: `MOD-${module.id}`
      }));
  }

  // Filter the new arrays and prepend "REG" with the ID for registers
  if (newFilteredRegisterRecords.length > 0) {
    newFilteredRegisterRecords = newFilteredRegisterRecords
      .filter(
        (register) =>
          Array.isArray(register.account_register_access) &&
          register.account_register_access.length > 0
      )
      .map((register) => ({
        ...register,
        newId: `REG-${register.id}`
      }));
  }

  // Merge the two arrays into a single array
  const mergedRecords = [...newFilteredModuleRecords, ...newFilteredRegisterRecords];

  const handleChangeMenu = (event: SelectChangeEvent<string>) => {
    setSelectedMenu(event.target.value);
  };

  const handleFromDateChange = (date: dayjs.Dayjs | null): void => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date: dayjs.Dayjs | null): void => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    setToDate(formattedDate);
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!selectedMenu) newErrors.selectedMenu = 'Please select a module or register';
    if (!fromDate) newErrors.fromDate = 'Please select a from date';
    if (!toDate) newErrors.toDate = 'Please select a to date';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        if (authToken) {
          const response = await exportData(
            authToken,
            selectedAccount,
            selectedMenu,
            fromDate,
            toDate
          );
          if (response.status === 204) {
            setNoDataMessage('No data available for the selected date range.');
          } else {
            setNoDataMessage('');
          }
        }
      } catch (error: any) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const filteredRecords = mergedRecords.filter((record) => record.name === 'Adviser Reviews');

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            thirdTitle={thirdTitle}
            secondpath={secondpath}
          />
          <div className="record-outer-row settings-outer-row">
            <div className="settings-sidebar-wrap ">
              <nav className="settings-sidebar">
                <div className="sidebar-inner">
                  Enter the export criteria and click &apos;Export&apos; to export data to Excel
                </div>
              </nav>
            </div>
            <div className="right-outer">
              <div className="right-inner">
                <form onSubmit={handleSubmit} className="record-create-form">
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Export Data</h3>
                    </div>
                    <Typography variant="body1" gutterBottom>
                      Select a register or module below to export your data
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Module / Register</label>
                          {Array.isArray(filteredRecords) && filteredRecords.length > 0 && (
                            <FormControl fullWidth variant="outlined" error={!!errors.selectedMenu}>
                              <Select
                                error={!!errors.selectedMenu}
                                onChange={handleChangeMenu}
                                labelId="module-register-select-label"
                                id="module-register-select"
                                value={selectedMenu}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                className="custom-select"
                                MenuProps={{ className: 'custom-dropdown-menu' }}
                                displayEmpty
                                renderValue={(selected) => {
                                  if (selected === '') {
                                    return (
                                      <Typography color="textSecondary">Select one</Typography>
                                    );
                                  }
                                  const selectedOption =
                                    filteredRecords &&
                                    filteredRecords.find((option) => option.newId === selected);
                                  return selectedOption ? `${selectedOption.name}` : '';
                                }}>
                                {filteredRecords.map((option) => (
                                  <MenuItem key={option?.newId} value={option?.newId || ''}>
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.selectedMenu && (
                                <Typography variant="caption" color="error">
                                  {errors.selectedMenu}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Date created from</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MMM/YYYY"
                              className="form-control date-picker"
                              name="from_date"
                              value={fromDate ? dayjs(fromDate) : null}
                              onChange={(date) => handleFromDateChange(date)}
                              slotProps={{
                                textField: {
                                  error: !!errors.fromDate,
                                  placeholder: 'Select date',
                                  helperText: errors.fromDate
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <label className="form-label">Date created to</label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MMM/YYYY"
                              className="form-control date-picker"
                              name="to_date"
                              value={toDate ? dayjs(toDate) : null}
                              onChange={(date) => handleToDateChange(date)}
                              slotProps={{
                                textField: {
                                  error: !!errors.toDate,
                                  placeholder: 'Select date',
                                  helperText: errors.toDate
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    {noDataMessage && (
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: '1rem', color: '#f04438' }}>
                        {noDataMessage}
                      </Typography>
                    )}
                    <div className="btn-wrap" style={{ textAlign: 'center', marginTop: '20px' }}>
                      {loading && (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      )}
                      <Button className="btn primary-btn btn-sm" type="submit" disabled={loading}>
                        Export
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default ExportDataIndex;
