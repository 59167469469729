import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { FileReviewState } from '../../views/components/user/state';

export const deleteFileReview = async (
  recordId: number,
  authToken?: string
): Promise<FileReviewState | string | undefined | null> => {
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('_method', 'DELETE');

    const routeUrl = `${process.env.REACT_APP_API_URL}/filereview/${dataEncrypt(recordId?.toString())}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
