import axios, { AxiosResponse } from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { UserState } from '../../views/components/user/state';
import { localStorageKeys } from '../../utils/local-storage';
import { encryptData } from '../../utils/common/user';
import { UserFormData } from '../../views/components/settings/state';
import { AccountState } from '../../views/components/user/state';

export const saveUser = async (
  formData: UserFormData,
  authToken?: string,
  user?: UserState,
  accountInfo?: AccountState,
  imageChanged?: boolean,
  isCreatedFromOnboarding?: boolean
): Promise<string | undefined | null> => {
  const {
    id,
    first_name,
    last_name,
    email,
    phone,
    profile_image,
    timezone,
    is_open,
    isAdviser,
    is_consultant,
    lu_user_access_level_id
  } = formData;
  const selectedAccountId = accountInfo?.id;
  const form = new FormData();
  if (selectedAccountId) {
    form.append('account_id', selectedAccountId.toString());
  }
  form.append('first_name', first_name);
  form.append('last_name', last_name);
  form.append('email', email);
  form.append('phone', phone ? phone : '');
  form.append('timezone', timezone ? timezone : '');
  form.append('is_open', is_open ? '1' : '0');
  form.append('isAdviser', isAdviser ? '1' : '0');
  form.append('is_consultant', is_consultant ? '1' : '0');
  form.append('lu_user_access_level_id', lu_user_access_level_id ? lu_user_access_level_id : '');

  if (profile_image) {
    form.append('profile_image', profile_image); // Attach the logo as a Blob
  } else if (!profile_image && imageChanged) {
    form.append('profile_image', '');
  }

  if(isCreatedFromOnboarding) {
    form.append('is_created_from_onboarding', '1');
  }

  // Define the route URL based on whether it's an update or create
  const routeUrl = id
    ? `${process.env.REACT_APP_API_URL}/users/${dataEncrypt(id?.toString())}`
    : `${process.env.REACT_APP_API_URL}/users`;

  try {
    if (id) {
      form.append('_method', 'PUT');

      if (user?.id === id) {
        form.append('current_logged_in_user', 'true');
      }
    }
    const response: AxiosResponse<ApiResponseType> = await axios.post(routeUrl, form, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      if (user?.id === id) {
        localStorage.setItem(
          localStorageKeys.USER_DATA,
          encryptData(JSON.stringify(response.data.data))
        );
      }
      return; // Return true to indicate success
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage;
  }
};


export const sendEmail = async (emailData: any) => {

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/newpassword/email`, emailData);
  if (response.data.success) {
    return true; // Return true to indicate success
  } else {
    throw new Error('Email sending failed');
  }

}
