import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { format, parseISO } from 'date-fns';
import { PaginationResponse, PrevetState, UserState } from '../../user/state';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission
} from '../../../../utils/common/user';
import { FileReviewFormState, PrevetsDataFormState } from './state';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { fetchPrevets } from '../../../../api/pre-vet/fetchPrevets';
import { MODULES, NOTE_READ, TASK_READ } from '../../../../constants/constants';
import { isFormatted } from '../../../../utils/commonUtils';

interface LeftMenuProps {
  user: UserState | undefined;
  prevetId?: string;
  sourceId?: string;
  formData_record?: PrevetsDataFormState;
  fileformData_record?: FileReviewFormState;
  filereviewId?: string;
  prevetIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const PrevetLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  prevetId,
  sourceId,
  formData_record,
  fileformData_record,
  filereviewId,
  prevetIdEncrypted,
  sourceIdEncrypted
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();
  const { moduleRecords } = useModuleContext();
  const module_type = 'prevets';
  const taskUrl = `/prevet/tasks?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`;
  const notesUrl = `/prevet/notes?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`;
  const detailsUrl = `/prevet/${prevetIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/prevet-snapshot-edit/${prevetIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEditSecond = `/prevet-form-edit/${prevetIdEncrypted}?source=${sourceIdEncrypted}`;
  const moduleId = sourceId ? parseInt(sourceId, 10) : 0;

  const foundModule =
    Array.isArray(moduleRecords) && moduleRecords.length > 0
      ? moduleRecords.find((module) => module.id === moduleId)
      : '';
  const instructions =
    foundModule && foundModule.instructions != null && foundModule.instructions != ''
      ? foundModule?.instructions
      : t('prevet.addMenuMsg');

  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('prevetNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('prevetTasksCount') || ''
  );

  const [formData, setFormData] = useState<PrevetsDataFormState>({
    unique_id: '',
    adviser_id: 0,
    created_at: '',
    is_open: 0
  });

  const [fileformData, setFileFormData] = useState<FileReviewFormState>({
    client: '',
    is_complete: false,
    prevet_data: {
      unique_id: '',
      adviser: {
        first_name: '',
        last_name: ''
      }
    }
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (
        Array.isArray(moduleRecords) &&
        moduleRecords.length > 0 &&
        ((sourceId && !foundModule) || (!sourceId && location.pathname !== '/prevets/request'))
      ) {
        navigate('/404');
      }
      if (prevetId && authToken) {
        const editId = parseInt(prevetId!, 10);

        const response: PrevetState = (await fetchPrevets(
          0,
          authToken,
          selectedAccount,
          editId
        )) as PrevetState;

        if (response) {
          const { unique_id, adviser, is_open, prevet_template_id } = response;

          localStorage.removeItem('prevet_unique_id');
          localStorage.setItem('prevet_unique_id', unique_id);

          let created_at;
          if (response.created_at) {
            const date = new Date(response.created_at);
            created_at = date.toISOString().split('T')[0];
          }
          let closed_at;
          if (response.closed_at) {
            const date = new Date(response.closed_at);
            closed_at = date.toISOString().split('T')[0];
          }

          const newFormData = {
            unique_id,
            prevet_template_id,
            adviser,
            is_open,
            created_at,
            closed_at
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('prevetNotesCount');
    try {
      if (authToken && prevetId) {
        const record_Id = parseInt(prevetId, 10);
        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          moduleId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('prevetNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('prevetTasksCount');
    try {
      if (authToken && prevetId) {
        const record_Id = parseInt(prevetId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          moduleId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('prevetTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (
      currentUrl !==
      `/prevet/add-file-review?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`
    ) {
      if (!formData_record && !filereviewId) {
        fetchData(); // Call the async function
      } else if (filereviewId && fileformData_record) {
        setFileFormData(fileformData_record);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unusedVariable = formData_record ? setFormData(formData_record) : null;
      }

      if (!localStorage.getItem('prevetNotesCount')) {
        fetchNotesCount();
      }

      if (!localStorage.getItem('prevetTasksCount')) {
        fetchTasksCount();
      }
    }
  }, [sourceId, formData_record, fileformData_record]);

  // URLs exclude from the menu
  const excludedUrls = [
    '/prevet-add',
    '/prevet/add-note',
    `/prevet/edit-note/${id}`,
    '/prevet/add-task',
    `/prevet/edit-task/${id}`,
    `/prevet-edit/${id}`,
    `/prevet/add-file-review`,
    `/prevet/edit-file-review/${id}`,
    `/prevet/view-file-review/${id}`,
    `/prevet-finalise/${id}`,
    `/prevet-finalise-list/${id}`,
    `/prevets/request`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit, detailsUrlEditSecond] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/prevets/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/prevet-add' && (
              <span className="inner-text-wrap">{instructions}</span>
            )}
            {location.pathname == '/prevet/add-file-review' && (
              <span className="inner-text-wrap">{t('review.addFileReviewMenuMsg')}</span>
            )}
            {location.pathname == '/prevets/request' && (
              <span className="inner-text-wrap">{t('prevet.requestPrevetAddMenuMsg')}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/prevet-add' &&
                location.pathname !== '/prevet/add-file-review' &&
                location.pathname !== '/prevets/request' && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      {formData_record || formData.unique_id ? (
                        <p>{formData.unique_id ? formData.unique_id : ''}</p>
                      ) : fileformData_record || fileformData?.prevet_data?.unique_id ? (
                        <p>{fileformData.prevet_data ? fileformData.prevet_data.unique_id : ''}</p>
                      ) : null}
                    </div>
                    {(formData_record || formData.created_at) && (
                      <div className="comp-uder-detail-list">
                        <h6>Created</h6>
                        <p>
                          {formData && formData.created_at
                            ? isFormatted(formData.created_at)
                              ? formData.created_at
                              : format(parseISO(formData.created_at), 'd/MMM/yyyy')
                            : 'N/A'}
                        </p>
                      </div>
                    )}

                    {(fileformData_record || fileformData?.client) && (
                      <div className="comp-uder-detail-list">
                        <h6>Client</h6>
                        <p>{fileformData && fileformData?.client ? fileformData?.client : ''}</p>
                      </div>
                    )}

                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      {formData_record || formData?.is_open ? (
                        <p>{formData && formData?.is_open ? 'Open' : 'Archived'}</p>
                      ) : fileformData_record || fileformData?.is_complete ? (
                        <p>{fileformData && fileformData?.is_complete ? 'Completed' : 'Open'}</p>
                      ) : null}
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};
export default PrevetLeftMenu;
