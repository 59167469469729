import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

interface DropdownProps {
  survey: any; // Add the appropriate SurveyJS types
}

const Dropdown: React.FC<DropdownProps> = ({ survey }) => {
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [selectedPageName, setSelectedPageName] = useState<string>('page1'); // Track the selected page name

  useEffect(() => {
    const updateDropdownOptions = () => {
      const visiblePagesWithNames = survey.pages.filter(
        (page: any) => page.title && isPageVisible(page)
      );

      const options =
        visiblePagesWithNames.length > 0
          ? visiblePagesWithNames.map((page: any) => ({ value: page.name, label: page.title }))
          : survey.pages.map((page: any, index: number) => ({
              value: page.name || index + 1,
              label: page.title || `${index + 1}`
            }));

      setDropdownOptions(options);
    };

    const isPageVisible = (page: any) => {
      const firstQuestionOnPage =
        page.isVisible && page.questions && page.questions.length > 0 ? page.questions[0] : null;
      return firstQuestionOnPage ? firstQuestionOnPage.visible : false;
    };

    updateDropdownOptions();

    // Listen for changes to the survey.pages and update the dropdown options accordingly
    survey.onValueChanged.add(updateDropdownOptions);

    // Cleanup the event listener when the component unmounts
    return () => {
      survey.onValueChanged.remove(updateDropdownOptions);
    };
  }, [survey]);

  return (
    <div className="form-group">
      <label className="form-label">Select Page</label>
      <FormControl variant="outlined">
        <Select
          onChange={(e) => {
            const selectedValue = e.target.value;
            if (selectedValue) {
              survey.currentPage = survey.pages.find((page: any) => page.name === selectedValue);
              setSelectedPageName(survey.currentPage?.name || '');
            }
          }}
          labelId="selectPage"
          id="selectPage"
          value={selectedPageName} // Use the local state to control the value
          IconComponent={KeyboardArrowDownRoundedIcon}
          className="custom-select"
          MenuProps={{ className: 'custom-dropdown-menu' }}>
          {dropdownOptions.length > 1 &&
            dropdownOptions.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
