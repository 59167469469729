import React, { useState, useEffect } from 'react';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import CommonLayout from '../../layout/CommonLayout';
import { Switch } from '@mui/material';
import { useNotificationContext } from '../../layout/NotificationContext';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import ModulesUpdate from '../modules/ModuleUpdate';
import QuickVetTemplateIndex from './QuickVetTemplateIndex';
import { updateQuickVetTemplateStatus } from '../../../../api/settings/updateQuickVetTemplateStatus';
import { fetchQuickVetTemplates } from '../../../../api/quickvets/fetchQuickVetTemplates';
import { QuickVetTemplateFormState } from '../state';

const QuickVetsDefault: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationSuccess } = useNotificationContext();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'QuickVets';
  const secondTitle = 'Modules';
  const secondpath = '/settings/modules';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const authToken = useAuthToken() || '';
  const selectedAccount = getSelectedAccountInfo();
  const [quickVetTemplates, setQuickVetTemplates] = useState<QuickVetTemplateFormState[]>([]);
  const [switchValues, setSwitchValues] = useState<Record<number, boolean>>({});

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchQuickVetTemplatesData = async () => {
    try {
      if (authToken) {
        const response: QuickVetTemplateFormState[] = (await fetchQuickVetTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          undefined,
          undefined,
          '',
          ''
        )) as QuickVetTemplateFormState[];

        if (response) {
          // Update the state with the fetched templates
          setQuickVetTemplates(response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchQuickVetTemplatesData();
  }, []);

  useEffect(() => {
    if (quickVetTemplates && Array.isArray(quickVetTemplates)) {
      const initialSwitchValues: Record<number, boolean> = {};
      quickVetTemplates.forEach((template) => {
        if (
          template?.id !== undefined &&
          template?.account_template &&
          template?.account_template.length > 0
        ) {
          initialSwitchValues[template.id] = true;
        } else if (template?.id !== undefined) {
          // Handle the case where there is no account_template for this template, but id exists
          initialSwitchValues[template.id] = false;
        }
      });
      setSwitchValues(initialSwitchValues);
    }
  }, [quickVetTemplates]);

  const handleSwitchChange = (templateId: number) => {
    setSwitchValues((prevSwitchValues) => {
      const updatedSwitchValues = {
        ...prevSwitchValues,
        [templateId]: !prevSwitchValues[templateId]
      };
      const activeTemplateIds = Object.keys(updatedSwitchValues).filter(
        (id) => updatedSwitchValues[parseInt(id)]
      ); // Parse id to number

      // Make API call with updated switch values
      updateQuickVetTemplateStatus(activeTemplateIds, selectedAccount, authToken)
        .then(() => {
          setNotificationSuccess(t('success.field.filereviewtemplatestatusupdate'));
          localStorage.setItem('quickvet-template-listing-updates', '1');
          fetchQuickVetTemplatesData();
        })
        .catch((error) => {
          console.error('Error updating switch state:', error);
        });

      return updatedSwitchValues;
    });
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    <form>
                      <div className="card-outline">
                        {Array.isArray(quickVetTemplates) && (
                          <>
                            <h5>Available QuickVet Templates</h5>
                            {Array.isArray(quickVetTemplates) && quickVetTemplates.length > 0 ? (
                              quickVetTemplates.map((template) => (
                                <div key={`temp-${template.id}`}>
                                  {template.is_remove_permission === 0 &&
                                    template.account_template && (
                                      <div className="row-wrap review-row" key={template.id}>
                                        <div className="form-group">
                                          <div className="switch-wrap">
                                            <Switch
                                              className="custom-switch"
                                              checked={
                                                template.id !== undefined
                                                  ? switchValues[template.id] || false
                                                  : false
                                              }
                                              onChange={() =>
                                                template.id !== undefined &&
                                                handleSwitchChange(template.id)
                                              }
                                            />

                                            <span className="template-namefile-review">
                                              {template.name}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              ))
                            ) : (
                              <div>No template available</div>
                            )}
                          </>
                        )}
                      </div>
                      <QuickVetTemplateIndex />
                    </form>
                  </div>
                  <div className="form-wrap mt-2">
                    <ModulesUpdate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default QuickVetsDefault;
