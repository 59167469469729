import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { t } from 'i18next';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { useAuthToken, useCurrentUserInfo, decryptData } from '../../../../utils/common/user';
import { CircularProgress } from '@mui/material';
import { Survey } from 'survey-react-ui';
import { useNotificationContext } from '../../layout/NotificationContext';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import Dropdown from './Dropdown';
import { ProfessionalYearTemplateDataState, ProfessionalYearTemplateState } from './state';
import ProfessionalYearsLeftMenu from './ProfessionalYearsLeftMenu';
import { createProfessionalYearTemplateData } from '../../../../api/professional-year/createProfessionalYearTemplateData';
import { fetchProfessionalYearLogTrainingTemplate } from '../../../../api/professional-year/fetchProfessionalYearLogTrainingTemplate';

const ProfessionalYearLogTrainingTemplateAddEdit = () => {
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const { setNotificationSuccess } = useNotificationContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const queryParams = new URLSearchParams(search);
  let professionalYearId = queryParams.get('professionalYearId');
  const encryptedProfessionalYearId = professionalYearId; // Decrypt the ID
  professionalYearId = decryptData(professionalYearId, true);
  const professional_year_id = parseInt(professionalYearId!, 10);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [templateData, setTemplateData] = useState<string>('');
  const [survey, setSurvey] = useState<any>('');
  const [formData, setFormData] = useState<ProfessionalYearTemplateDataState>({
    id: 0,
    professional_year_id: 0,
    form_data: '',
    error: ''
  });

  const { moduleRecords } = useModuleContext();
  let mainTitle;
  const mainPath = `/professional-year/${encryptedSourceId}`;
  if (sourceId) {
    const moduleId = parseInt(sourceId, 10);
    const foundModule =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundModule ? foundModule?.name : '';
  }
  let secondTitle;
  const unique_id = localStorage.getItem('professional_year_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/professional-year/details/${encryptedProfessionalYearId}?source=${encryptedSourceId}`;
  const pageTitle = 'Log Training Form';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-professionalyear-logbook-training-temp-render');
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      formData.form_data
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.form_data)
      );
      localStorage.setItem(
        'survey-json-professionalyear-logbook-training-temp-render',
        JSON.stringify(mergedResponse)
      );
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.setItem(
        'survey-json-professionalyear-logbook-training-temp-render',
        templateData
      );
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();

      fetchTemplate();
      setTimeout(() => {
        setHasEffectRun(true);
      }, 800);
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun]);

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });

      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: ProfessionalYearTemplateState =
          (await fetchProfessionalYearLogTrainingTemplate(
            professional_year_id,
            authToken,
            parseInt(id!, 10)
          )) as ProfessionalYearTemplateState;

        if (response.id) {
          const { form_data, log_training_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            localStorage.setItem(
              'survey-json-professionalyear-logbook-training-temp-render',
              form_data
            );
          } else {
            localStorage.removeItem('survey-json-professionalyear-logbook-training-temp-render');
            setFormData((prevData) => ({
              ...prevData,
              error: t('error.field.notemplateFound')
            }));
          }

          if (log_training_data) {
            setFormData(log_training_data);
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const setSurveyData = () => {
    const surveyJson =
      localStorage.getItem('survey-json-professionalyear-logbook-training-temp-render') || ''; // Provide an empty string as the default

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.pageNextText = 'Save and next';
      surveyModel.completeText = 'Save';
      surveyModel.focusFirstQuestionAutomatic = true;
      surveyModel.addNavigationItem({
        id: 'survey_clear_current_page',
        title: 'Cancel',
        visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
        action: () => {
          handleCancelClick();
        }
      });

      setSurvey(surveyModel);
    }
  };

  const handleCancelClick = () => {
    const redirectback = localStorage.getItem('redirect_back');

    if (redirectback) {
      navigate(redirectback);
    } else {
      navigate(
        `/professional-year/details/${encryptedProfessionalYearId}?source=${encryptedSourceId}`
      );
    }
  };

  const filteredElements: any[] = [];
  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      let number: any;
      page.elements.forEach((element: any) => {
        const fieldName = element.name;

        if (fieldValues[fieldName] !== undefined || fieldName.trim().includes('guidance-')) {
          // Add the value to the element
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const unusedVariable =
            fieldValues[fieldName] !== undefined
              ? (element.defaultValue = fieldValues[fieldName])
              : null;

          // Check if 'choices' array is present and not empty
          if (element.choices && element.choices.length > 0) {
            // Convert defaultValue to an array if it's not already
            const defaultValues = Array.isArray(element.defaultValue)
              ? element.defaultValue
              : [element.defaultValue];
            // Find the choices whose values are present in defaultValues
            const selectedChoices = element.choices.filter((choice: any) => {
              if (choice && choice.value) {
                return defaultValues.includes(choice.value);
              } else {
                return defaultValues.includes(choice);
              }
            });

            // Now you can use selectedChoices as needed
            selectedChoices.forEach((selectedChoice: any) => {
              // Your logic here
              const selectedText =
                selectedChoice && selectedChoice.text ? selectedChoice.text : selectedChoice;
              if (
                fieldName &&
                fieldName.trim().includes('check-') &&
                (selectedText.trim().toLowerCase() === 'yes with limitations' ||
                  selectedText.trim().toLowerCase() === 'no')
              ) {
                const match = fieldName.trim().match(/check-(\d+)/);
                number = match ? match[1] : null;
                const isDuplicate = filteredElements.some(
                  (existingElement) => existingElement.name === element.name
                );

                if (!isDuplicate) {
                  filteredElements.push(element);
                }
              }
            });
          }
          if (number) {
            const index = filteredElements.findIndex((element) =>
              element.name.includes(`check-${number}`)
            );
            if (element.type === 'html' && fieldName.trim().includes(`guidance-${number}`)) {
              if (index !== -1) {
                filteredElements[index].guidance = filteredElements[index].guidance || [];
                filteredElements[index].guidance.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`comment-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].comments = filteredElements[index].comments || [];
                filteredElements[index].comments.push({ element });
              }
            } else if (
              element.type === 'comment' &&
              fieldName.trim().includes(`action-${number}`)
            ) {
              if (index !== -1) {
                filteredElements[index].action = filteredElements[index].action || [];
                filteredElements[index].action.push({ element });
              }
            }
          }
        }
      });
    });

    return mainResponse;
  };

  const handleSurveySubmit = async (type = '') => {
    let validationResult = true;
    if (templateData && !type) {
      validationResult = survey.validate();
    }
    if (!validationResult) {
      return;
    }
    if (validationResult && survey) {
      const surveyData = survey.data;

      mergeFieldValues(JSON.parse(templateData), surveyData);

      const response: any = (await createProfessionalYearTemplateData(
        t('templateType.logTraining'),
        surveyData,
        professional_year_id,
        authToken,
        parseInt(id!, 10)
      )) as any;
      if (response && response?.id) {
        if (!type) {
          localStorage.removeItem('survey-json-professionalyear-logbook-workactivity-temp-render');
          const msg = id
            ? t('success.field.professionalYearLogTrainingDataUpdated')
            : t('success.field.professionalYearLogTrainingDataCreated');
          setNotificationSuccess(msg);
          setHasEffectRun(false);
          handleCancelClick();
        }
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            secondTitle={secondTitle}
            secondpath={secondpath}
            title={pageTitle}
            pageTitle={pageTitle}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <ProfessionalYearsLeftMenu
              user={user}
              professionalYearId={professionalYearId ? professionalYearId : ''}
              sourceId={sourceId ? sourceId : ''}
              professionalYearIdEncrypted={encryptedId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : survey ? (
                  <div className="card-outline">
                    <Survey model={survey} />

                    {survey.PageCount > 1 && (
                      <div className="inner-btn-wrap">
                        <Dropdown survey={survey} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="no-record">Logbook form not found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default ProfessionalYearLogTrainingTemplateAddEdit;
