import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { PaginationResponse, UserState } from '../../user/state';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission
} from '../../../../utils/common/user';
import { ProvidersDataFormState } from './state';
import { ProviderState } from '../../user/state';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import { fetchProviders } from '../../../../api/outsourced-provider/fetchProviders';
import { useRegistersContext } from '../../registers/RegisterContext';
import { NOTE_READ, REGISTERS, TASK_READ } from '../../../../constants/constants';

interface LeftMenuProps {
  user: UserState | undefined;
  providerId?: string | null;
  sourceId?: string;
  formData_record?: ProvidersDataFormState;
  providerIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const ProviderLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  providerId,
  sourceId,
  formData_record,
  providerIdEncrypted,
  sourceIdEncrypted
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();
  const { registersRecords } = useRegistersContext();
  const module_type = 'providers';
  const taskUrl = `/providers/tasks?source=${sourceIdEncrypted}&providerId=${providerIdEncrypted}`;
  const notesUrl = `/providers/notes?source=${sourceIdEncrypted}&providerId=${providerIdEncrypted}`;
  const detailsUrl = `/providers/details/${providerIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEdit = `/provider-snapshot-edit/${providerIdEncrypted}?source=${sourceIdEncrypted}`;
  const detailsUrlEditSecond = `/providers/edit/initial-engagement/${providerIdEncrypted}?source=${sourceIdEncrypted}`;
  const registerId = sourceId ? parseInt(sourceId, 10) : 0;

  let addMenuMsg: string = t('provider.addMenuMsg');

  const foundRegister =
    Array.isArray(registersRecords) && registersRecords.length > 0
      ? registersRecords.find((register) => register.id === registerId)
      : '';

  if (
    location.pathname == `/providers/review-add` ||
    location.pathname == `/providers/review-edit/${providerIdEncrypted}`
  ) {
    addMenuMsg =
      foundRegister && foundRegister.instructions != null && foundRegister.instructions != ''
        ? foundRegister?.instructions
        : t('provider.reviewAddMenuMsg');
  } else if (
    location.pathname == `/providers/review` ||
    location.pathname == `/providers/review/${providerIdEncrypted}`
  ) {
    addMenuMsg = t('provider.reviewDetailAddMenuMsg');
  } else if (location.pathname == `/providers/add/initial-engagement`) {
    addMenuMsg = t('provider.contractAddMenuMsg');
  }

  const instructions = addMenuMsg;

  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('providerNotesCount') || ''
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('providerTasksCount') || ''
  );

  const [formData, setFormData] = useState<ProvidersDataFormState>({
    unique_id: '',
    provider: '',
    owner_id: 0,
    created_at: '',
    is_open: 0
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (
        Array.isArray(registersRecords) &&
        registersRecords.length > 0 &&
        ((sourceId && !foundRegister) || !sourceId)
      ) {
        navigate('/404');
      }
      if (providerId && authToken) {
        const editId = parseInt(providerId!, 10);

        const response: ProviderState = (await fetchProviders(
          0,
          authToken,
          selectedAccount,
          editId
        )) as ProviderState;

        if (response) {
          const { unique_id, owner, provider, is_open } = response;

          localStorage.removeItem('provider_unique_id');
          localStorage.setItem('provider_unique_id', unique_id);

          let created_at;
          if (response.created_at) {
            const date = new Date(response.created_at);
            created_at = date.toISOString().split('T')[0];
          }
          let closed_at;
          if (response.closed_at) {
            const date = new Date(response.closed_at);
            closed_at = date.toISOString().split('T')[0];
          }

          const newFormData = {
            unique_id,
            provider,
            owner,
            is_open,
            created_at,
            closed_at
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('providerNotesCount');
    try {
      if (authToken && providerId) {
        const record_Id = parseInt(providerId, 10);
        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          registerId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('providerNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('providerTasksCount');
    try {
      if (authToken && providerId) {
        const record_Id = parseInt(providerId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          registerId,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('providerTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formData_record) {
      fetchData(); // Call the async function
    } else {
      formData_record ? setFormData(formData_record) : '';
    }

    if (!localStorage.getItem('providerNotesCount')) {
      fetchNotesCount();
    }

    if (!localStorage.getItem('providerTasksCount')) {
      fetchTasksCount();
    }
  }, [sourceId, formData_record]);

  // URLs show only instructions
  const instructionUrls = [
    '/providers/add',
    `/providers/add/initial-engagement`,
    `/providers/review`,
    `/providers/review/${id}`,
    `/providers/review-add`,
    `/providers/review-edit/${id}`,
    `/providers/review-data`
  ];

  // URLs exclude from the menu
  const excludedUrls = [
    '/providers/add',
    '/providers/add-note',
    `/providers/edit-note/${id}`,
    '/providers/add-task',
    `/providers/edit-task/${id}`,
    `/providers/add/initial-engagement`,
    `/providers/review`,
    `/providers/review/${id}`,
    `/providers/review-add`,
    `/providers/review-edit/${id}`,
    `/providers/review-data`
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl, detailsUrlEdit, detailsUrlEditSecond] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, REGISTERS, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, REGISTERS, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/providers/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {instructionUrls.includes(location.pathname) && (
              <span className="inner-text-wrap">{instructions}</span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/providers/add' &&
                location.pathname !== '/providers/add/initial-engagement' &&
                location.pathname !== '/providers/review' &&
                location.pathname !== `/providers/review/${providerIdEncrypted}` &&
                location.pathname !== `/providers/review-add` &&
                location.pathname !== `/providers/review-edit/${providerIdEncrypted}` &&
                location.pathname !== `/providers/review-data` && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      {formData_record || formData.unique_id ? (
                        <p>
                          {formData.unique_id ? formData.unique_id : formData_record?.unique_id}
                        </p>
                      ) : null}
                    </div>

                    <div className="comp-uder-detail-list">
                      <h6>Provider</h6>
                      {formData_record || formData.provider ? (
                        <p>{formData.provider ? formData.provider : ''}</p>
                      ) : null}
                    </div>

                    <div className="comp-uder-detail-list">
                      <h6>Owner</h6>
                      {formData.owner ? (
                        <p>
                          {formData.owner
                            ? formData.owner.first_name + ' ' + (formData.owner.last_name || '')
                            : ''}
                        </p>
                      ) : null}
                    </div>

                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      {formData_record || formData?.is_open ? (
                        <p>{formData && formData?.is_open ? 'Open' : 'Archived'}</p>
                      ) : null}
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};
export default ProviderLeftMenu;
