import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ProfessionalYearTemplateState } from '../../views/components/modules/professional-years/state';

export const fetchProfessionalYearCompletionLicenseeTemplate = async (
  professionalYearId: number,
  authToken: string,
): Promise<ProfessionalYearTemplateState | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/professional-year-completion-licensee-template/${dataEncrypt(professionalYearId?.toString())}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching template';
  }
};

