import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Button, SelectChangeEvent } from '@mui/material';
import UpdateCreateEditFormData from './UpdateCreateEditFormData';
import { UpdateState } from '../../user/state';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { t } from 'i18next';
import { createUpdateData } from '../../../../api/updates/createUpdateData'; //sendNewUpdatesEmail
import { useNotificationContext } from '../../layout/NotificationContext';
import { fetchUpdates } from '../../../../api/updates/fetchUpdates';
import UpdateLeftMenu from './UpdateLeftMenu';
import { CREATE, SETTINGS, UPDATE } from '../../../../constants/constants';

const UpdateCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = id ? 'Edit Update' : 'Add Update';
  const secondpath = '/settings/updates';
  const secondTitle = 'Updates';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { setNotificationSuccess } = useNotificationContext();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<UpdateState>({
    id: 0,
    date: '',
    type: 3,
    title: '',
    summary: '',
    details: '',
    additional_info_title: '',
    additional_info_link: '',
    status: 1,
    error: ''
  });

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const updateId = parseInt(id!, 10);

        const response: UpdateState = (await fetchUpdates(
          authToken,
          selectedAccount,
          updateId
        )) as UpdateState;

        if (response) {
          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    const permission = id ? UPDATE : CREATE;
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 6, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);

    fetchData(); // Call the async function
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateInputChange = (date: any): void => {
    setFormData((prevData) => ({
      ...prevData,
      date: date.format('YYYY-MM-DD')
    }));
  };

  const handleEditorChange = (newContent: string) => {
    setFormData((prevData) => ({
      ...prevData,
      details: newContent
    }));
  };

  const handleCancelClick = () => {
    navigate(`/settings/updates`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.date) {
      newErrors.date = t('error.field.dateRequired');
    }
    if (!formData.title) {
      newErrors.title = t('error.field.titleRequired');
    }
    if (!formData.summary) {
      newErrors.summary = t('error.field.summaryRequired');
    }
    if (!formData.details) {
      newErrors.details = t('error.field.detailsRequired');
    }
    if (!formData.additional_info_title && formData.additional_info_link) {
      newErrors.additional_info_title = t('error.field.additionalInfoTitleRequired');
    }
    if (!formData.additional_info_link && formData.additional_info_title) {
      newErrors.additional_info_link = t('error.field.additionalInfoLinkRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createUpdateData(formData, authToken, selectedAccount, edit_id);

        if (response && response.id) {
          // if(!id){
          //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
          //   const responseData: any = await sendNewUpdatesEmail(
          //     response.id,
          //     authToken,
          //     selectedAccount,
          //   );
          // }
          const msg = id ? t('success.field.updateUpdated') : t('success.field.updateCreated');

          setNotificationSuccess(msg);
          navigate(`/settings/updates`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          const newErrors: Record<string, string> = {};

          if (response.date) {
            newErrors.date = response.date[0];
          } else if (response.title) {
            newErrors.title = response.title[0];
          } else if (response.summary) {
            newErrors.summary = response.summary[0];
          } else if (response.details) {
            newErrors.details = response.details[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
              />

              <div className="record-outer-row settings-outer-row">
                <UpdateLeftMenu />

                <div className="right-outer">
                  <div className="right-inner">
                    <UpdateCreateEditFormData
                      formData={formData}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleSelectChange={handleSelectChange}
                      handleDateInputChange={handleDateInputChange}
                      handleEditorChange={handleEditorChange}
                    />

                    <div className="btn-wrap">
                      <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn btn-sm"
                        type="submit"
                        onClick={handleFormSubmit}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default UpdateCreateEdit;
