import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import AdviserProfileLeftMenu from './AdviserProfileLeftMenu';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MODULES, NOTE_CREATE, READ, TASK_CREATE, UPDATE } from '../../../../constants/constants';
import { fetchAdviserProfiles } from '../../../../api/adviser-profiles/fetchAdviserProfiles';
import { Button } from '@mui/material';
import { AdviserProfileState } from './state';
import AdviserProfileTemplateTextMode from './AdviserProfileTemplateTextMode';
import { Link } from 'react-router-dom';
import AdviserProfileIncidentsBreaches from './AdviserProfileIncidentsBreaches';
import { TemplatesState } from '../../user/state';
import AdviserProfileAdviserReviews from './AdviserProfileAdviserReviews';
import AdviserProfilePreVets from './AdviserProfilePrevets';
import AdviserProfileComplaints from './AdviserProfileComplaints';
import AdviserProfileConflictsOfInterests from './AdviserProfileConflictsOfInterests';
import AdviserProfileAttestation from './AdviserProfileAttestation';

const AdviserProfilesDetails = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const [loading, setLoading] = useState<boolean>(true);
  const [adviserProfile, setAdviserProfile] = useState<AdviserProfileState | null>(null);

  const mainPath = `/adviser-profiles/${encryptedSourceId}`;
  const secondTitle = 'Adviser Profiles';
  const pageTitle = 'Adviser Profile';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      if (authToken && id) {
        const response: AdviserProfileState = (await fetchAdviserProfiles(
          authToken,
          selectedAccount,
          source_Id,
          parseInt(id!, 10)
        )) as AdviserProfileState;

        if (response) {
          setAdviserProfile(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [encryptedId]);

  let addButton;
  if (adviserProfile && adviserProfile.is_open == 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {pathname === `/adviser-profile/${encryptedId}` && (
          <>
            {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/adviser-profile/add-note?source=${encryptedSourceId}&adviserProfileId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}

            {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/adviser-profile/add-task?source=${encryptedSourceId}&adviserProfileId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  let edit_btn_persional_details;
  let edit_btn_authorisations;
  if (adviserProfile) {
    edit_btn_persional_details = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button
            className="btn blue-fill-btn"
            component={RouterLink}
            to={`/adviser-profile/personal-details?source=${encryptedSourceId}&adviserProfileId=${encryptedId}`}>
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );

    edit_btn_authorisations = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button
            className="btn blue-fill-btn"
            component={RouterLink}
            to={`/adviser-profile/authorisations?source=${encryptedSourceId}&adviserProfileId=${encryptedId}`}>
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={
                  adviserProfile
                    ? adviserProfile?.first_name + ' ' + adviserProfile?.last_name
                    : 'Adviser Profile'
                }
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                button={addButton}
              />

              <div className="record-outer-row settings-outer-row">
                <AdviserProfileLeftMenu
                  user={user}
                  adviserProfileId={id}
                  sourceId={sourceId ? sourceId : ''}
                  record={adviserProfile}
                  adviserProfileIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    <div className="title-wrap">
                      <h3>
                        {adviserProfile
                          ? adviserProfile?.first_name + ' ' + adviserProfile?.last_name
                          : ''}
                      </h3>
                      <p className="link">
                        <Link to={`mailto:${adviserProfile?.email}`}>
                          {adviserProfile ? adviserProfile?.email : ''}
                        </Link>
                      </p>
                    </div>
                    <div className="card-outline">
                      <AdviserProfileTemplateTextMode
                        surveyData={
                          adviserProfile?.personalDetailsTemplate?.form_data
                            ? JSON.parse(adviserProfile?.personalDetailsTemplate?.form_data)
                            : {}
                        }
                        formData={
                          adviserProfile?.personal_details_data?.form_data
                            ? JSON.parse(adviserProfile?.personal_details_data?.form_data)
                            : {}
                        }
                        edit_btn={edit_btn_persional_details}
                        title="Personal Details"
                        emptyText="Personal details not found."
                        loading={loading}
                      />
                    </div>

                    <div className="card-outline">
                      <AdviserProfileTemplateTextMode
                        surveyData={
                          adviserProfile?.authorisationsTemplate?.form_data
                            ? JSON.parse(adviserProfile?.authorisationsTemplate?.form_data)
                            : {}
                        }
                        formData={
                          adviserProfile?.authorisations_data?.form_data
                            ? JSON.parse(adviserProfile?.authorisations_data?.form_data)
                            : {}
                        }
                        edit_btn={edit_btn_authorisations}
                        title="Authorisations"
                        emptyText="Authorisations not found."
                        loading={loading}
                      />
                    </div>

                    <AdviserProfileAdviserReviews
                      loading={loading}
                      adviserReviews={
                        adviserProfile && adviserProfile.adviserReviews
                          ? adviserProfile.adviserReviews
                          : []
                      }
                    />

                    <AdviserProfilePreVets
                      loading={loading}
                      prevetRecords={
                        adviserProfile && adviserProfile.prevetRecords
                          ? adviserProfile.prevetRecords
                          : []
                      }
                    />

                    <AdviserProfileComplaints
                      loading={loading}
                      template={
                        adviserProfile && adviserProfile.complaintRegisterTemplate
                          ? adviserProfile.complaintRegisterTemplate
                          : ({} as TemplatesState)
                      }
                      registerRecords={
                        adviserProfile && adviserProfile.complaintRegisterRecords
                          ? adviserProfile.complaintRegisterRecords
                          : []
                      }
                    />

                    <AdviserProfileIncidentsBreaches
                      loading={loading}
                      template={
                        adviserProfile && adviserProfile.incidentBreachRegisterTemplate
                          ? adviserProfile.incidentBreachRegisterTemplate
                          : ({} as TemplatesState)
                      }
                      registerRecords={
                        adviserProfile && adviserProfile.incidentBreachRegisterRecords
                          ? adviserProfile.incidentBreachRegisterRecords
                          : []
                      }
                    />

                    <AdviserProfileConflictsOfInterests
                      loading={loading}
                      template={
                        adviserProfile && adviserProfile.conflictsRegisterTemplate
                          ? adviserProfile.conflictsRegisterTemplate
                          : ({} as TemplatesState)
                      }
                      registerRecords={
                        adviserProfile && adviserProfile.conflictsRegisterRecords
                          ? adviserProfile.conflictsRegisterRecords
                          : []
                      }
                    />

                    <AdviserProfileAttestation
                      loading={loading}
                      attestationRecords={
                        adviserProfile && adviserProfile.attestationRecords
                          ? adviserProfile.attestationRecords
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AdviserProfilesDetails;
