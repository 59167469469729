import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const generatePDFDirect = async (
  authToken?: string,
  accountInfo?: AccountState,
  review_id?: number,
  emailSwitch?: 0 | 1,
  taskContent?: string | null,
  addWatermark?: boolean,
  generateDraft?: boolean
): Promise<string | undefined | null> => {
  const selectedAccountId = accountInfo?.id;
  try {
    // Convert the parameters into query parameters for GET request
    const params = new URLSearchParams();

    if (selectedAccountId !== undefined) {
      params.append('accountId', selectedAccountId.toString());
    }
    if (emailSwitch !== undefined) {
      params.append('emailSwitch', emailSwitch.toString());
    }
    if (taskContent !== undefined && taskContent !== null) {
      params.append('taskContent', taskContent);
    }
    if (addWatermark !== undefined) {
      params.append('addWatermark', addWatermark.toString());
    }
    if (generateDraft !== undefined) {
      params.append('generateDraft', generateDraft.toString());
    }

    const routeUrl = `${process.env.REACT_APP_API_URL}/generate-pdf-direct/${review_id}?${params.toString()}`;

    const response = await axios.get<ApiResponseType>(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.url;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
