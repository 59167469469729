import React from 'react';

interface RenderHTMLProps {
  htmlContent: string;
}

const RenderHTML: React.FC<RenderHTMLProps> = ({ htmlContent }) => {
  // Modify the HTML content to include inline styles for lists
  const modifiedHtmlContent = htmlContent.replace(
    /<ul([^>]*)>/g,
    '<ul class="update-tiny-html" $1>'
  );
  // You can do a similar modification for <ol>

  return <span dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }} />;
};

export default RenderHTML;
