import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { AccountState } from '../../user/state';

interface LeftMenuProps {
  selectedAccount: AccountState;
}

const ComplianceQueryLeftMenu: React.FC<LeftMenuProps> = ({ selectedAccount }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {location.pathname == `/resources/compliance-query` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            <span className="inner-text-wrap">
              {selectedAccount.compliance_query_email
                ? t('complianceQuery.addMenuMsg')
                : t('complianceQuery.addMenuMsgNotComplianceQueryEmail')}
            </span>
          </nav>
        </div>
      )}
    </>
  );
};

export default ComplianceQueryLeftMenu;
