import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import trashGrey400 from '../../../../assets/images/trash-grey-400.svg';
import { addEditAttestationPeriods } from '../../../../api/settings/addEditAttestationPeriods';
import { AttestationsPeriodsState } from '../../modules/attestations/state';
import { fetchAttestationPeriods } from '../../../../api/settings/fetchAttestationPeriods';

export interface RelevantPeriodElement {
  id: number;
  relevant_period: string;
}

export interface AttestationRelevantPeriodsProps {
  accountId: number;
  authToken: string;
}

const AttestationRelevantPeriods: React.FC<AttestationRelevantPeriodsProps> = ({
  accountId,
  authToken
}) => {
  const [elements, setElements] = useState<RelevantPeriodElement[]>([]);
  const [elemId, setElemId] = useState<number>(0);
  const [isElementRemove, setIsElementRemove] = useState<boolean>(false);
  const [copiedElements, setCopiedElements] = useState<number[]>([]);

  const getAttestationPeriods = async () => {
    try {
      const response: AttestationsPeriodsState = (await fetchAttestationPeriods(
        accountId,
        authToken
      )) as AttestationsPeriodsState;

      if (response.id) {
        const periodsLabel = JSON.parse(response.period_label);
        if (periodsLabel) {
          const newElements: RelevantPeriodElement[] = [];
          periodsLabel.map((label: string, index: number) => {
            newElements[index] = {
              id: index,
              relevant_period: label
            };
          });

          setElements(newElements);
          setElemId(periodsLabel.length);
        } else {
          handleAddRelevantPeriod();
        }
      } else {
        handleAddRelevantPeriod();
        console.log('Error: ', response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    getAttestationPeriods();
  }, []);

  const handleAddRelevantPeriod = () => {
    setElements([...elements, { id: elemId, relevant_period: '' }]);
    setElemId(elemId + 1);
    setCopiedElements([...copiedElements, elemId]);
  };

  const handleInputChange = (id: number, field: string, value: string) => {
    setElements(
      elements.map((element) => {
        if (element.id === id) {
          return { ...element, [field]: value };
        }
        return element;
      })
    );
  };

  const handleRemoveElement = (id: number) => {
    setElements(
      elements.filter((element) => {
        if (element.id !== id) {
          return element;
        }
      })
    );
    setIsElementRemove(true);
  };

  useEffect(() => {
    if (isElementRemove) {
      handleSubmit();
      setIsElementRemove(false);
    }
  }, [isElementRemove]);

  const handleSubmit = async () => {
    try {
      const response: AttestationsPeriodsState = (await addEditAttestationPeriods(
        elements,
        accountId,
        authToken
      )) as AttestationsPeriodsState;

      if (!response.id) {
        console.log('Error: ', response);
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };
  return (
    <div className="form-wrap mt-2">
      <form>
        <div className="card-outline">
          <div className="card-title-wrap">
            <h3>Relevant Periods</h3>
          </div>
          {elements.length > 0 &&
            elements.map((element, index) => (
              <React.Fragment key={element.id}>
                <div
                  className={`row-wrap ${(copiedElements.includes(element.id) && element.id > 0) || index > 0 ? ' attestation-margin' : ''}`}>
                  <div
                    className={`form-group attestation-width ${(copiedElements.includes(element.id) && element.id > 0) || index > 0 ? ' mt-2' : ''}`}>
                    {element.id == 0 && <label className="form-label">Relevant Period</label>}
                    <TextField
                      variant="outlined"
                      className="form-control"
                      type="text"
                      placeholder="Enter label"
                      value={element.relevant_period}
                      onChange={(e) =>
                        handleInputChange(element.id, 'relevant_period', e.target.value)
                      }
                      onBlur={handleSubmit}
                    />
                  </div>
                  <div
                    className={`form-group attestation-width-button${(copiedElements.includes(element.id) && element.id > 0) || index > 0 ? ' no-margin' : ''}`}>
                    <Button className="edit" onClick={() => handleRemoveElement(element.id)}>
                      <img src={trashGrey400} alt="trashGrey400" />
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            ))}
          <div className="btn-wrap mt-2 btn-attestation-margin">
            <Button className="btn primary-btn btn-sm" onClick={handleAddRelevantPeriod}>
              <i className="left">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Add Relevant Period
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AttestationRelevantPeriods;
