import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { MeetingPaginationResponse } from '../../views/components/user/state';

export const fetchMeetingAgendaQuestions = async (
  agendaId: number,
  meetingId: number,
  manageMeetingId: number,
  authToken: string,
  currentPage: number = 1,
  questionId: number = 0,
  itemsPerPage: number = 1,
): Promise<MeetingPaginationResponse | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/meeting-agenda-questions?agendaId=${agendaId}&meetingId=${dataEncrypt(meetingId?.toString())}&manageMeetingId=${manageMeetingId}&page=${currentPage}&perPage=${itemsPerPage}&questionId=${questionId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
};
