import React from 'react';
import { FormControl, MenuItem, Typography, TextField } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { RequestPrevetFormsProps } from './state';
import Select from '@mui/material/Select';
import fileUploadIc from '../../../../assets/images/file-upload.svg';

const PrevetRequestCreateFormData: React.FC<RequestPrevetFormsProps> = ({
  formData,
  errors,
  reviewerArray,
  handleInputChange,
  handleChangeReviewer,
  handleDateInputChange,
  handleFileChange
}) => (
  <form action="" className="record-create-form">
    <div className="card-outline">
      <div className="card-title-wrap secondary-title-wrap">
        <h3>Snapshot</h3>
      </div>

      <div className="row-wrap ">
        <div className="form-group w-full textarea">
          <label className="form-label">Client name(s)</label>
          <TextField
            error={!!errors.client_names}
            id="client_names"
            variant="outlined"
            name="client_names"
            value={formData.client_names}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter client name(s)"
            type="text"
            helperText={errors.client_names}
            inputProps={{
              maxLength: 120 // Set the maximum character length
            }}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Reviewer</label>
          <FormControl variant="outlined" error={!!errors.reviewer_id}>
            <Select
              error={!!errors.reviewer_id}
              onChange={handleChangeReviewer}
              labelId="reviewer-label"
              name="reviewer_id"
              id="reviewer_id"
              value={formData.reviewer_id?.toString()}
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="custom-select"
              MenuProps={{ className: 'custom-dropdown-menu' }}
              renderValue={(selected) => {
                if (selected === '0') {
                  return <p className="dropdown-placeholder">Select Reviewer</p>; // Render "Select Reviewer" as placeholder
                }
                const selectedOption =
                  reviewerArray && reviewerArray.find((option) => option.id === parseInt(selected, 10));
                return selectedOption
                  ? `${selectedOption.name}`
                  : '';
              }}>
              {reviewerArray.map((option) => (
                <MenuItem key={option?.id} value={option?.id || ''}>
                  {option?.name || ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.reviewer_id && (
            <Typography variant="caption" color="error">
              {errors.reviewer_id}
            </Typography>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Preferred completion date (not guaranteed)</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MMM/YYYY"
              className="form-control date-picker"
              value={formData.completion_date ? dayjs(formData.completion_date) : null}
              onChange={handleDateInputChange}
              slotProps={{
                textField: {
                  error: !!errors.completion_date,
                  placeholder: 'Select Date',
                  helperText: errors.completion_date
                }
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="form-group w-full textarea">
          <label className="form-label">Comments</label>
          <TextField
            id="comment"
            variant="outlined"
            name="comment"
            value={formData.comment ? formData.comment : ''}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter any additional comments"
            type="text"
            multiline
            rows={5}
          />
        </div>
        <div className="form-group w-full file-upload">
          <label className="form-label large" htmlFor="document">
            Attach advice document to review
          </label>
          <div className="relative">
            <TextField
              error={!!errors.document}
              id="document"
              variant="outlined"
              name="document"
              onChange={handleFileChange}
              className="form-control"
              InputProps={{
                startAdornment: (
                  <input
                    type="file"
                    className="file-upload-input"
                    onChange={handleFileChange}
                    title="file"
                  />
                )
              }}
              helperText={errors.document}
            />
            {formData.document ? (
              <div className="preview-uploaded">
                <img src={fileUploadIc} alt="File upload" />
                <p className="file-name">
                  <span className="blue-text">Selected file:</span> {formData.document.name}
                </p>
              </div>
            ) : (
              <p className="placeholder-text">
                <img src={fileUploadIc} alt="File upload" />
                <span>
                  <span className="blue-text">Click to upload</span> or drag and drop
                </span>
                <span className="small-text"></span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  </form>
);

export default PrevetRequestCreateFormData;
