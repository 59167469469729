import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Button, CircularProgress, SelectChangeEvent } from '@mui/material';
import { TechnicalSupportRequestState } from '../../user/state';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { t } from 'i18next';
import { CREATE, RESOURCES } from '../../../../constants/constants';
import SupportRequestLeftMenu from './SupportRequestLeftMenu';
import SupportRequestCreateEditFormData from './SupportRequestCreateEditFormData';
import { sendTechnicalSupportRequestMail } from '../../../../api/technical-support-request/sendTechnicalSupportRequestMail';

const SupportRequestCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const title = 'Technical Support Request';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Resources';
  const secondpath = '/dashboard';
  const pageTitle = title;

  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<TechnicalSupportRequestState>({
    request_type: '',
    enquiry: '',
    error: ''
  });
  const [loading, setLoading] = useState<boolean>(false);

  const request_type = [ 
    { 
      id: 1, 
      name: 'Something is not working', 
    }, 
    { 
      id: 2,
      name: 'Plans, pricing and billing'
    },
    { 
      id: 3, 
      name: 'Demos and training request'
    }
  ];

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, RESOURCES, 3, CREATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancelClick = () => {
    navigate(`/dashboard`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.request_type) {
      newErrors.request_type = t('error.field.requestType');
    }
    if (!formData.enquiry) {
      newErrors.enquiry = t('error.field.enquiry');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      setLoading(true);

      try {
        const response: any = await sendTechnicalSupportRequestMail(
          formData,
          authToken,
          selectedAccount
        );

        if (response) {
          const newErrors: Record<string, string> = {};

          if (response.request_type) {
            newErrors.request_type = response.request_type[0];
          } else if (response.enquiry) {
            newErrors.enquiry = response.enquiry[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        } else {
          navigate(`/resources/support-request-sent`);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={secondpath}
              />

              <div className="record-outer-row settings-outer-row">
                {currentUrl === '/resources/support-request-sent' ? (
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Thanks!</h3>
                    </div>

                    <div className="row-wrap ">
                      <div className="form-group">
                        <label className="form-label">
                          You’re request has been sent to the support team.
                          <br />
                          <br />
                          We’ll aim to respond by the end of the next business day.
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <SupportRequestLeftMenu />

                    <div className="right-outer">
                      <div className="right-inner">
                        <SupportRequestCreateEditFormData
                          formData={formData}
                          requestType={request_type}
                          errors={errors}
                          handleInputChange={handleInputChange}
                          handleSelectChange={handleSelectChange}
                        />

                        <div className="btn-wrap">
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                                Cancel
                              </Button>
                              <Button
                                className="btn primary-btn btn-sm"
                                type="submit"
                                onClick={handleFormSubmit}>
                                Save
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {currentUrl === '/resources/support-request-sent' && (
                <div className="support-request-button">
                  <Button
                    fullWidth
                    className="btn primary-btn btn-sm"
                    component={RouterLink}
                    to={`/dashboard`}>
                    Home
                  </Button>
                </div>
              )}
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default SupportRequestCreateEdit;
