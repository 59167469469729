import React, { useEffect, useState } from 'react';
import { PolicyState, PolicyVersionState } from '../../user/state';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export interface PolicyVersionsTableProps {
  formData: PolicyState;
}

const PolicyVersionsTable: React.FC<PolicyVersionsTableProps> = ({ formData }) => {
  const [dense] = useState(false);
  const [orderBy, setOrderBy] = useState('version');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [versionsData, setVersionsData] = useState<PolicyVersionState[]>([]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof PolicyVersionState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'version',
      numeric: false,
      disablePadding: true,
      label: 'Version'
    },
    {
      id: 'file',
      numeric: true,
      disablePadding: true,
      label: 'File'
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Uploaded'
    }
  ];

  useEffect(() => {
    if (formData.versions && Array.isArray(formData.versions)) {
      setVersionsData(formData.versions);
    }
  }, [formData]);

  const handleSort = (property: keyof PolicyVersionState) => {
    if (formData.versions && Array.isArray(formData.versions)) {
      const isAsc = orderBy === property && order === 'asc';
      setOrderBy(property);
      setOrder(isAsc ? 'desc' : 'asc');

      // Sort the registers array based on the selected criteria
      const sortedRegisters = [...formData.versions].sort((a, b) => {
        const aValue = a[property];
        const bValue = b[property];

        // Handle sorting for numbers and strings separately
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return isAsc ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else {
          return 0;
        }
      });
      setVersionsData(sortedRegisters);
    }
  };

  return (
    <div className="table-outer">
      <TableContainer>
        {formData.versions && Array.isArray(formData.versions) && formData.versions.length === 0 ? (
          <div className="no-record">No policies versions found.</div>
        ) : (
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            className="table hover-table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={`key-${headCell.id}`}
                    align={headCell.numeric ? 'left' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleSort(headCell.id)}>
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={{ display: 'none' }}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {versionsData.map((data: PolicyVersionState) => (
                <TableRow key={data.id}>
                  <TableCell scope="row">{data.version}</TableCell>
                  <TableCell scope="row">
                    <Link
                      className="file"
                      to={`${JSON.parse(data.file_link || '').path}`}
                      target="_blanck">
                      {data.file}
                    </Link>
                  </TableCell>
                  <TableCell scope="row">
                    {data.updated_at ? format(new Date(data.updated_at), 'd/MMM/yyyy') : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default PolicyVersionsTable;
