import axios from 'axios';
import { Dispatch } from 'redux';
import { ModuleActiveStatus } from '../../views/components/settings/state';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { updateModule } from '../../store/modules/moduleSlice';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const updateModulesActiveStatus = async (
  dispatch: Dispatch,
  requestData: ModuleActiveStatus,
  accountInfo?: AccountState,
  authToken?: string
): Promise<string | number | undefined> => {
  const selectedAccountId = accountInfo?.id;
  try {
    const formDataObj = new FormData();
    formDataObj.append('id',requestData.id.toString());
    formDataObj.append('is_open', requestData.is_open.toString());
    formDataObj.append('locked', requestData.locked.toString());
    if (selectedAccountId) {
      formDataObj.append('accountId', selectedAccountId.toString());
    }
    formDataObj.append('_method', 'PUT');

    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/modules/${dataEncrypt(requestData.id?.toString())}/update-module-status`,
      formDataObj,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    if (response.data.success) {
      dispatch(updateModule(response.data.data));
      return; // Return the 'active' status as a number
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
