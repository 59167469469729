import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotesIndex from './RegisterNotesIndex';
import NotesAdd from './NotesAdd';
import TasksIndex from './RegisterTasksIndex';
import TaskAddEdit from './TasksAddEdit';
import RecordIndex from './RecordIndex';

const RecordRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/:id" element={<RecordIndex />} />
      <Route path="/notes" element={<NotesIndex />} />
      <Route path="/add-note" element={<NotesAdd />} />
      <Route path="/edit-note/:id/" element={<NotesAdd />} />
      <Route path="/tasks" element={<TasksIndex />} />
      <Route path="/add-task" element={<TaskAddEdit />} />
      <Route path="/edit-task/:id/" element={<TaskAddEdit />} />
    </Routes>
  );
};

export default RecordRoutes;
