import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';

const PlansLeftMenu = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
      <div className="overlay" onClick={toggleSidebar}></div>
      <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
        <MenuRoundedIcon />
      </IconButton>
      <nav className="settings-sidebar">
        <div className="close-btn-wrap">
          <IconButton className="close-btn" onClick={toggleSidebar}>
            <CloseIcon />
          </IconButton>
        </div>
      </nav>
    </div>
  );
};

export default PlansLeftMenu;
