import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { FileReviewFormState } from '../../views/components/modules/pre-vet/state';

export const createFileReview = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  id?: number,
  filteredElements?: any,
): Promise<FileReviewFormState | string | undefined | null> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { prevet_file_review_template_id, client, prevet_id, form_data, is_complete } =
    formData;
  try {
    const formData = new FormData(); // Create a FormData object
    if (!id) {
      formData.append('prevet_file_review_template_id', prevet_file_review_template_id);
      formData.append('client', client);
      if (prevet_id) {
        formData.append('prevet_id', prevet_id?.toString())
      }
    }
    else {
      formData.append('_method', 'PUT');
      if (client) {
        formData.append('client', client);
      }
      if (prevet_id) {
        formData.append('prevet_id', prevet_id);
      }
      if (form_data) {
        formData.append('form_data', JSON.stringify(form_data));
      }
      formData.append('is_complete', is_complete ? '1' : '0');
      if (filteredElements) {
        formData.append('filteredElements', JSON.stringify(filteredElements)); // Include filteredElements in the payload
      }
    }

    routeUrl = `${process.env.REACT_APP_API_URL}/prevet-filereview?account_id=${selectedAccountId}`;
    if (id) {
      routeUrl = `${process.env.REACT_APP_API_URL}/prevet-filereview/${dataEncrypt(id?.toString())}?account_id=${selectedAccountId}`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
