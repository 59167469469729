import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import {
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import ProfessionalYearsLeftMenu from './ProfessionalYearsLeftMenu';
import ProfessionalYearCompletionSupervisorTemplate from './ProfessionalYearCompletionSupervisorTemplate';
import ProfessionalYearCompletionLicenseeTemplate from './ProfessionalYearCompletionLicenseeTemplate';
import { ProfessionalYearState } from './state';
import { fetchProfessionalYears } from '../../../../api/professional-year/fetchProfessionalYears';
import { t } from 'i18next';

const ProfessionalYearCompletionTemplateAddEdit = () => {
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let professionalYearId = queryParams.get('professionalYearId');
  const encryptedProfessionalYearId = professionalYearId; // Decrypt the ID
  professionalYearId = decryptData(professionalYearId, true);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);

  const [formData, setFormData] = useState<ProfessionalYearState>({
    id: 0,
    py_complete_supervisor: 0,
    error: ''
  });

  const { moduleRecords } = useModuleContext();
  let mainTitle;
  const mainPath = `/professional-year/${encryptedProfessionalYearId}`;
  const moduleId = parseInt(sourceId!, 10);
  if (sourceId) {
    const foundModule =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundModule ? foundModule?.name : '';
  }
  let secondTitle;
  const unique_id = localStorage.getItem('professional_year_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/professional-year/details/${encryptedProfessionalYearId}?source=${encryptedSourceId}`;
  const pageTitle = 'Completion';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const fetchData = async () => {
    const professional_year_id = parseInt(professionalYearId!, 10);
    if (professional_year_id && authToken) {
      try {
        const response: ProfessionalYearState = (await fetchProfessionalYears(
          authToken,
          selectedAccount,
          professional_year_id
        )) as ProfessionalYearState;

        if (response) {
          setFormData(response);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            secondTitle={secondTitle}
            secondpath={secondpath}
            title={pageTitle}
            pageTitle={pageTitle}
          />
          <div className="record-outer-row settings-outer-row">
            <ProfessionalYearsLeftMenu
              user={user}
              professionalYearId={professionalYearId ? professionalYearId : ''}
              sourceId={sourceId ? sourceId : ''}
              professionalYearIdEncrypted={encryptedProfessionalYearId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <ProfessionalYearCompletionSupervisorTemplate professionalYear={formData} />

                <ProfessionalYearCompletionLicenseeTemplate professionalYear={formData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default ProfessionalYearCompletionTemplateAddEdit;
