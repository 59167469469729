import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import { ReviewsDataFormState } from './state';
import 'survey-core/defaultV2.min.css';
import CommonLayout from '../../layout/CommonLayout';
import { Button } from '@mui/material';
import { useAuthToken } from '../../../../utils/common/user';
import { fetchAdviserReviews } from '../../../../api/adviser/fetchAdviserReviews';
import { ReviewState } from '../../user/state';
import { useTranslation } from 'react-i18next';

const FinaliseReviewTextPage: React.FC = () => {
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const mainPath = `/reviews/${encryptedSourceId}`;
  const pageTitle = 'Finalise Adviser Review';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const authToken = useAuthToken();
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.toLocaleString('default', {
    month: 'short'
  })}/${today.getFullYear()}`;
  const { t } = useTranslation();

  const [formData] = useState<ReviewsDataFormState>({
    id: 0,
    subject: '',
    adviser_id: 0,
    reviewer_id: 0,
    review_template_id: selectedAccount?.review_template_id,
    unique_id: '',
    is_open: 1,
    created_at: formattedDate,
    created_by: 0,
    error: '',
    adviser: {
      first_name: '',
      last_name: ''
    },
    reviewer: {
      first_name: '',
      last_name: ''
    },
    user: {
      first_name: '',
      last_name: ''
    },
    file_reviews_with_status: {
      file_review_status: '0'
    }
  });

  const secondTitle = 'Adviser Reviews';

  const handleCancelClick = () => {
    navigate(`/review/${encryptedId}?source=${encryptedSourceId}`);
  };

  const handleComplete = () => {
    navigate(`/review-finalise-list/${encryptedId}?source=${encryptedSourceId}`);
  };

  const fetchReviewData = async () => {
    try {
      if (id && authToken) {
        const editId = parseInt(id!, 10);

        const response: ReviewState = (await fetchAdviserReviews(
          0,
          authToken,
          selectedAccount,
          editId
        )) as ReviewState;

        if (response) {
          const { unique_id } = response;
          localStorage.removeItem('review_unique_id');
          localStorage.setItem('review_unique_id', unique_id);
        }
      }
    } catch (error) {
      /* empty */
    }
  };

  useEffect(() => {
    const review_unique_id = localStorage.getItem('review_unique_id');
    if (review_unique_id) {
      fetchReviewData();
    }
  }, [id]);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={pageTitle}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
            errorMsg={formData.error}
          />
          <div className="record-outer-row settings-outer-row">
            <ReviewLeftMenu
              user={user}
              reviewId={id ? id : ''}
              sourceId={sourceId ? sourceId : ''}
              reviewIdEncrypted={encryptedId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="card-outline">
                  <div className="card-title-wrap secondary-title-wrap">
                    <h3>Are you sure?</h3>
                  </div>
                  <div className="inner-wrap-title inner-wrap-title-center">
                    <p>{t('review.finaliseConfirmation')}</p>
                    <p className="margin-top">{t('review.finaliseConfirmationSure')}</p>
                  </div>
                  <div className="inner-btn-wrap wrap-center-two">
                    <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                      Cancel
                    </Button>
                    <Button className="btn primary-btn" onClick={() => handleComplete()}>
                      <i className="left">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_6545_879)">
                            <path
                              d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                              stroke="CurrentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6545_879">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      Finalise Review
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default FinaliseReviewTextPage;
