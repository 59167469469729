import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordFormState } from './state';
import { sendResetPasswordEmail } from '../../../api/user/resetPassword';
import { Alert, IconButton } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ResetPassword from './ResetPassword';
import CloseIcon from '@mui/icons-material/Close';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const [formData, setFormData] = useState<ForgotPasswordFormState>({
    email: '',
    emailError: '',
    error: '',
    loading: false
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const validateEmail = (): boolean => {
    const { email } = formData;

    let isValid = true;

    if (!email) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: t('user.emailRequired')
      }));
      isValid = false;
    } else if (email) {
      // Email pattern validation
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(email)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: t('user.enterValidEmail')
        }));

        isValid = false;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: ''
        }));

        isValid = true;
      }
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!validateEmail()) {
      return;
    }
    setFormData((prevData) => ({ ...prevData, loading: true }));

    const errorMessage = await sendResetPasswordEmail(formData, type || '');

    setFormData((prevData) => ({ ...prevData, loading: false }));

    if (errorMessage) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: t('user.enterValidEmailNew'),
        error: errorMessage
      }));
      // setShowAlert(true);
    } else {
      navigate(`/reset-password-success?type=${type ?? ''}`, {
        state: { email: formData.email.toString() }
      });
    }
  };

  const { error } = formData;

  return (
    <div className="pre-login-wrapper">
      <div className="pre-login-inner">
        <div className="left-col">
          <div className="left-inner">
            {type ? (
              <Link to="/onboarding-login" className="back-link">
                <ArrowBackRoundedIcon />
                {t('back')}
              </Link>
            ) : (
              <Link to="/login" className="back-link">
                <ArrowBackRoundedIcon />
                {t('back')}
              </Link>
            )}
            <div className="title-wrap">
              <h1>{t('user.forgotPassword')}?</h1>
              <p>{t('user.forgotPasswordInstruction')}</p>
            </div>
            {error && showAlert && (
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleCloseAlert}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {error}
              </Alert>
            )}
            <ResetPassword
              formData={formData}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              t={t}
            />
          </div>
        </div>
        <div className="right-image-col">
          <div className="right-inner">
            <div className="img-block">
              <LazyImage
                src={`${S3PublicUrl}3Lines-reset-password.png`}
                alt=""
                rootMargin="0px 0px 200px 0px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
