import { PaginationResponse, PolicyState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchPolicies = async (
  authToken?: string,
  accountInfo?: AccountState,
  policyId?: number,
  currentPage: number = 1,
  type: string = '',
  search: string = '',
  selectedStatus: string = '1',
  itemsPerPage: number = 10,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | PolicyState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  if (!policyId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/policies?page=${currentPage}&perPage=${itemsPerPage}&search=${search}&status=${selectedStatus}&account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&orderBy=${orderBy}&order=${order}&type=${type}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/policies/${dataEncrypt(policyId?.toString())}?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching updates';
  }
};


export const fetchPolicy = async (
  authToken?: string,
  accountInfo?: AccountState,
  policyId?: number,
  currentPage: number = 1,
  type: string = '',
): Promise<PolicyState[] | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/policies?page=${currentPage}&account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&type=${type}`; // Include pagination parameters

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching updates';
  }
};


