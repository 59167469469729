import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { PrevetsDataFormState, PrevetsResponseError } from '../../views/components/modules/pre-vet/state';

export const createPrevetData = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  prevetId?: string | number,
  isStatusUpdate?: boolean
): Promise<PrevetsDataFormState | PrevetsResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { subject, reviewer_id, adviser_id, is_open, form_data, prevet_template_id, module_id, comment, completion_date, document } =
    formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('subject', subject);
    formData.append('adviser_id', adviser_id);
    formData.append('reviewer_id', reviewer_id);
    formData.append('module_id', module_id);
    if (comment) {
      formData.append('comment', comment);
    }
    if(completion_date){
      formData.append('completion_date', completion_date ? completion_date : '');
    }
    if (document) {
      formData.append('document', document);
    }

    if (isStatusUpdate) {
      formData.append('is_open', is_open);
    } else {
      formData.append('prevet_template_id', prevet_template_id);
      formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
      formData.append('form_data', JSON.stringify(form_data));
    }

    if(prevetId){
      const encryptedAccountId = encryptData(String(selectedAccountId), true);
      const encryptedSourceId = encryptData(String(module_id), true);
      const encryptedPrevetId = encryptData(String(prevetId), true);
      const returnUrl = `prevet/${encryptedPrevetId}?source=${encryptedSourceId}&account_id=${encryptedAccountId}&redirect=true`;
      formData.append('returnUrl', returnUrl);
    }

    if (prevetId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/prevets/${dataEncrypt(prevetId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/prevets`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
