import axios from 'axios';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const updateprevetfilereviewtemplatestatus = async (
  switchValues: any,
  accountInfo?: AccountState,
  authToken?: string
): Promise<any> => {
  const selectedAccountId = accountInfo?.id;
  try {
    const formDataObj = new FormData();
    if (switchValues && switchValues.length > 0) {
      for (const status of switchValues) {
        formDataObj.append('switchValues[]', status);
      }
    }
    if (selectedAccountId) {
      formDataObj.append('account_id', selectedAccountId.toString());
    }
    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/prevet-file-review-templates-status`,
      formDataObj,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    if (response.data.success) {
      return; // Return the 'active' status as a number
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
