import { fetchPresignedUrl } from "../../../api/s3/fetchPresignedUrl";

export const PresignedUrl = async(path:string, authToken:string) => {
  const urlObj = new URL(path);
  const fileName = urlObj.pathname.substring(urlObj.pathname.indexOf('/') + 1);
  try {
    fetchPresignedUrl(fileName, authToken)
        .then((response) => {
          const data: any = response;
          window.open(data, '_blank');
        })
        .catch((error) => {
          // Handle other error cases
          console.error(error); // Log the error
          return;
        });
  } catch (error) {
    console.error('Error fetching templates:', error);
    return;
  }
}