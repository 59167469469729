import axios from 'axios';
import { Dispatch } from 'redux'; // Import Dispatch type
import { ModuleDataFormState } from '../../views/components/settings/state';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { updateModule } from '../../store/modules/moduleSlice';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const modulesDetails = async (
  dispatch: Dispatch,
  formData: ModuleDataFormState,
  accountInfo?: AccountState,
  authToken?: string
): Promise<string | number | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const {
    id,
    add_record_label,
    name,
    display_name,
    prefix,
    description,
    template_id,
    icon_path,
    default_for_starter,
    default_for_premium
  } = formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('name', name);
    formData.append('add_record_label', add_record_label ? add_record_label : '');
    formData.append('display_name', display_name || '');
    formData.append('prefix', prefix);
    formData.append('description', description || '');
    formData.append('default_for_starter', default_for_starter?.toString() || '0');
    formData.append('default_for_premium', default_for_premium?.toString() || '0');
    if (template_id) {
      formData.append('template_id', template_id.toString());
    }
    if (icon_path) {
      formData.append('icon_path', icon_path); // Attach the icon as a Blob
    }
    if (selectedAccountId) {
      formData.append('accountId', selectedAccountId.toString());
    }
    formData.append('_method', 'PUT');
    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/modules/${dataEncrypt(id?.toString())}/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.success) {
      dispatch(updateModule(response.data.data));

      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
