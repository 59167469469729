import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UpdateState } from '../../user/state';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { fetchUpdates } from '../../../../api/updates/fetchUpdates';
import { t } from 'i18next';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import RenderHTML from '../../common/RenderHtml';
import Regulatory from '../../../../assets/updates-images/regulatory-updates.png';
import System from '../../../../assets/updates-images/system-updates.png';
import Internal from '../../../../assets/updates-images/internal-updates.png';
import { READ, RESOURCES } from '../../../../constants/constants';

const Update = () => {
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const title = `Update Details`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Resources';
  const secondpath = '/dashboard';
  const thirdTitle = 'Updates';
  const thirdPath = '/resources/updates';
  const pageTitle = `Update`;

  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [updateData, setUpdateData] = useState<UpdateState>({
    date: '',
    type: 0,
    title: '',
    summary: '',
    details: '',
    additional_info_title: '',
    additional_info_link: '',
    status: 0,
    error: ''
  });

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, RESOURCES, 1, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchBackground = (type: number) => {
    if (type == 1) {
      return '#1da2ef';
    } else if (type == 2) {
      return '#13c4a3';
    } else if (type == 3) {
      return '#835fa6';
    }
  };

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const updateId = parseInt(id!, 10);

        const response: UpdateState = (await fetchUpdates(
          authToken,
          selectedAccount,
          updateId
        )) as UpdateState;

        if (response.id) {
          setUpdateData(response);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        // Handle any errors here
        setUpdateData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  useEffect(() => {
    fetchData(); // Call the async function
  }, []);

  const handleBack = () => {
    navigate('/resources/updates');
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />

              <div className="update">
                {loading ? (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                ) : !updateData.id ? (
                  <div className="no-record">No update found.</div>
                ) : (
                  <div
                    className="notification"
                    style={{ border: `2px solid ${fetchBackground(updateData.type)}` }}>
                    <div
                      className="type-wrap"
                      style={{ background: fetchBackground(updateData.type) }}>
                      <div className="type">
                        {updateData.type == 1
                          ? 'Regulatory Update'
                          : updateData.type == 2
                            ? 'System Update'
                            : updateData.type == 3
                              ? 'Internal Update'
                              : ''}
                      </div>
                      <div className="date">
                        <span>
                          {updateData.date ? format(new Date(updateData.date), 'd/MMM/yyyy') : ''}
                        </span>
                      </div>
                    </div>
                    <div className="title">
                      <h2>{updateData.title}</h2>
                    </div>
                    <div className="row-wrap mb-2">
                      <div className="img-updates">
                        {updateData.type == 1 ? (
                          <img className="update-image" src={Regulatory} alt={updateData.title} />
                        ) : updateData.type == 2 ? (
                          <img className="update-image" src={System} alt={updateData.title} />
                        ) : updateData.type == 3 ? (
                          <img className="update-image" src={Internal} alt={updateData.title} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <p className="summary">{updateData.summary}</p>
                        <p className="details">
                          <RenderHTML htmlContent={updateData.details} />
                        </p>
                      </div>
                    </div>
                    <div className="link" style={{ color: selectedAccount.color }}>
                      {updateData.additional_info_title && updateData.additional_info_link && (
                        <Link to={updateData.additional_info_link} target="_blank">
                          {updateData.additional_info_title}
                          <i className="left">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <g clipPath="url(#clip0_9307_11593)">
                                <path
                                  d="M1.94957 12.1593C1.20713 9.37912 1.92645 6.28982 4.10753 4.10875C7.36189 0.854376 12.6383 0.854376 15.8926 4.10875C19.147 7.36311 19.147 12.6395 15.8926 15.8939C13.7116 18.0749 10.6223 18.7943 7.84212 18.0518M12.5002 12.5014V7.50139M12.5002 7.50139H7.5002M12.5002 7.50139L4.16671 15.8347"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_9307_11593">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </i>
                        </Link>
                      )}
                      <div className="inner-btn-wrap">
                        <p className="previous-btn" onClick={handleBack}>
                          <ArrowBackIcon /> Back
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default Update;
