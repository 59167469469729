import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress
} from '@mui/material';
import Link from '@mui/material/Link';
import edit from '../../../../assets/images/edit.svg';
import copy from '../../../../assets/images/copy.svg';
import { format } from 'date-fns';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  decryptData,
  encryptData
} from '../../../../utils/common/user';
import { PaginationResponse } from '../../user/state';
// import PaginationSettings from '../Pagination';
import { fetchQuickVetTemplates } from '../../../../api/quickvets/fetchQuickVetTemplates';
import { QuickVetTemplateFormState } from '../state';

const QuickVetTemplateIndex: React.FC = () => {  
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [orderBy, setOrderBy] = useState<keyof QuickVetTemplateFormState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  // const [totalPages, setTotalPages] = useState<number>(1);
  // const [currentPage, setCurrentPage] = useState<number>(1);

  const [templates, setTemplates] = useState<QuickVetTemplateFormState[]>([]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof QuickVetTemplateFormState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description'
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated'
    }
  ];

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchQuickVetTemplates(
          authToken,
          selectedAccount,
          1,
          10,
          undefined,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTemplates(response.data);
          }
          // setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(orderBy, order);
    localStorage.removeItem('quickvet-template-listing-updates');
  }, [localStorage.getItem('quickvet-template-listing-updates')]);

  const handleSort = (property: keyof QuickVetTemplateFormState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage);
  // };

  const button = (
    <Button
      className="btn primary-btn"
      component={RouterLink}
      to={`/settings/add-quickvet-template?sourceId=${encryptedId}`}>
      <i className="left">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_6545_879)">
            <path
              d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
              stroke="CurrentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_6545_879">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </i>
      Add Template
    </Button>
  );
  return (
    <div className="card-outline">
      <div
        className={`card-title-wrap secondary-title-wrap ${
          templates.length > 0 ? 'no-border-title' : ''
        }`}>
        <h3>All QuickVet Templates</h3>
        <div className="inner-btn-wrap">{button}</div>
      </div>

      <div className="row-wrap">
        <TableContainer>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : templates.length === 0 ? (
            <div className="no-record">No templates found.</div>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'left' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSort(headCell.id)}>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={{ display: 'none' }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map(
                  (row: QuickVetTemplateFormState) =>
                    row.is_remove_permission === 0 &&
                    row.account_template && (
                      <TableRow key={row.id}>
                        <TableCell scope="row">{row.name}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        {row.updated_at ? (
                          <TableCell align="left">
                            {format(new Date(row.updated_at), 'd/MMM/yyyy')}
                          </TableCell>
                        ) : (
                          <TableCell align="left">N/A</TableCell>
                        )}
                        <TableCell align="left">
                          <div className="table-btn">
                            <Button className="copy">
                              <Link
                                component={RouterLink}
                                to={`/settings/add-quickvet-template?copyId=${encryptData(
                                  String(row.id),
                                  true
                                )}&sourceId=${encryptedId}`}>
                                <img src={copy} alt="copy" />
                              </Link>
                            </Button>
                            <Link
                              component={RouterLink}
                              to={`/settings/add-quickvet-template/${encryptData(
                                String(row.id),
                                true
                              )}?sourceId=${encryptedId}`}
                              className="edit">
                              <img src={edit} alt="Right Arrow" />
                            </Link>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          )}
          {/* {!loading && templates.length > 0 && (
            <PaginationSettings
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          )} */}
        </TableContainer>
      </div>
    </div>
  );
};

export default QuickVetTemplateIndex;
