import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import PrevetLeftMenu from './PrevetLeftMenu';
import { RequestPrevetsDataFormState } from './state';
import 'survey-core/defaultV2.min.css';
import { SelectChangeEvent } from '@mui/material/Select';
import CommonLayout from '../../layout/CommonLayout';
import PrevetRequestCreateFormData from './PrevetRequestCreateFormData';
import { Button, CircularProgress } from '@mui/material';
import { requestPrevet } from '../../../../api/pre-vet/requestPrevet';
import { DropdownResponse, UserAccessState } from '../../user/state';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';
import { sendEmail } from '../../../../api/pre-vet/sendPrevetEmail';

const PrevetRequestCreate: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = sourceId ? decryptData(sourceId, true) : 0;
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [reviewerArray, setReviewerArray] = useState<DropdownResponse[]>([]);
  const mainPath = `/prevets/${encryptedSourceId}`;

  const secondTitle = 'Pre-Vets';
  const pageTitle = `Request Pre-Vet`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [formData, setFormData] = useState<RequestPrevetsDataFormState>({
    client_names: '',
    reviewer_id: 0,
    completion_date: '',
    comment: '',
    document: null
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const userAccess: UserAccessState = user?.user_access
      ? user?.user_access?.filter((item) => item.account_id === selectedAccount.id)[0]
      : ({} as UserAccessState);

    // for check this page permission
    if (userAccess && userAccess.isAdviser !== 1) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
      if (response) {
        setReviewerArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeReviewer = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateInputChange = (date: any): void => {
    setFormData((prevData) => ({
      ...prevData,
      completion_date: date.format('YYYY-MM-DD')
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files = {} as FileList } = event.target as { files?: FileList };

    const file = files?.[0];
    if (file) {
      setFormData((prevData) => ({ ...prevData, document: file }));
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.client_names) {
      newErrors.client_names = t('error.field.clientNames');
    }
    if (!formData.reviewer_id) {
      newErrors.reviewer_id = t('error.field.reviewer');
    }
    // if (!formData.completion_date) {
    //   newErrors.completion_date = t('error.field.completionDate');
    // }
    // if (!formData.document) {
    //   newErrors.document = t('error.field.document');
    // }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      setLoading(true);

      try {
        const response: any = await requestPrevet(formData, authToken, selectedAccount);

        if (response && !response.id) {
          // Error handling for validation errors
          const newErrors: Record<string, string> = {};

          if (response.client_names) {
            newErrors.client_names = response.client_names[0];
          } else if (response.reviewer_id) {
            newErrors.reviewer_id = response.reviewer_id[0];
          } else if (response.completion_date) {
            newErrors.completion_date = response.completion_date[0];
          } else if (response.document) {
            newErrors.document = response.document[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const responseData: any = await sendEmail(
            response.id,
            source_Id,
            authToken,
            selectedAccount
          );
          navigate(`/prevets/request-sent`);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelClick = () => {
    navigate(`/dashboard`);
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                title={pageTitle}
                errorMsg={formData.error}
              />

              <div className="record-outer-row settings-outer-row">
                {current_route === `/prevets/request-sent` ? (
                  <div className="card-outline">
                    <div className="card-title-wrap secondary-title-wrap">
                      <h3>Thanks!</h3>
                    </div>

                    <div className="row-wrap ">
                      <div className="form-group">
                        <label className="form-label">
                          Your Pre-Vet has been sent to the Reviewer.
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <PrevetLeftMenu
                      user={user}
                      prevetId={id}
                      sourceId={sourceId ? sourceId : ''}
                      prevetIdEncrypted={encryptedId}
                      sourceIdEncrypted={encryptedSourceId}
                    />

                    <div className="right-outer">
                      <div className="right-inner">
                        <PrevetRequestCreateFormData
                          formData={formData}
                          errors={errors}
                          reviewerArray={reviewerArray}
                          handleInputChange={handleInputChange}
                          handleChangeReviewer={handleChangeReviewer}
                          handleDateInputChange={handleDateInputChange}
                          handleFileChange={handleFileChange}
                        />

                        <div className="btn-wrap">
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                                Cancel
                              </Button>
                              <Button
                                className="btn primary-btn btn-sm"
                                type="submit"
                                onClick={handleSubmit}>
                                Request
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {current_route === '/prevets/request-sent' && (
                <div className="support-request-button">
                  <Button
                    fullWidth
                    className="btn primary-btn btn-sm"
                    component={RouterLink}
                    to={`/dashboard`}>
                    Home
                  </Button>
                </div>
              )}
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default PrevetRequestCreate;
