import React, { useEffect, useState } from 'react';
import LeftNav from '../LeftNav';
import { ModulesState } from '../../user/state';
import { fetchModules } from '../../../../api/settings/fetchModules';
import {
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useDispatch } from 'react-redux';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { updateModulesActiveStatus } from '../../../../api/settings/updateModuleActiveStatus';
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import CommonLayout from '../../layout/CommonLayout';
import PaginationSettings from '../Pagination';
import { format } from 'date-fns';
import { useModuleContext } from './ModulesContext';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useRegistersContext } from '../../registers/RegisterContext';
import { READ, SETTINGS, UPDATE } from '../../../../constants/constants';
import { t } from 'i18next';

const ModulesIndex: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const selectedAccountId = selectedAccount?.id;
  const title = 'Modules';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const [loading, setLoading] = useState<boolean>(false);
  const [dense] = useState(false);
  const [modules, setModules] = useState<ModulesState[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [error, setError] = useState<string>('');
  const [orderBy, setOrderBy] = useState<keyof ModulesState>('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const { fetchModuleRecords } = useModuleContext();
  const { fetchRegisterRecords } = useRegistersContext();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const handleSwitchToggle = async (moduleId: number, name: string, newValue: number) => {
    const requestData = {
      id: moduleId,
      is_open: 0,
      locked: 0
    };

    if (name === 'is_open') {
      requestData.is_open = newValue;
    } else if (name === 'locked') {
      requestData.locked = newValue;
    }

    try {
      const response = await updateModulesActiveStatus(
        dispatch,
        requestData,
        selectedAccount,
        authToken
      );

      if (response == t('permission.doesNotHaveAccessPermission')) {
        setError(t('permission.doesNotHaveAccessPermission'));
        return;
      }

      fetchData(1, orderBy, order);
      fetchModuleRecords();
      fetchRegisterRecords();
    } catch (error) {
      console.error('Error updating switch state:', error);
    }
  };

  useEffect(() => {
    fetchData(0, orderBy, order);
  }, [dispatch, selectedAccountId]);

  const fetchData = async (type: number, orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (type !== 1) {
        setLoading(true);
      }
      if (authToken) {
        const response: ModulesState = (await fetchModules(
          dispatch,
          selectedAccount,
          authToken,
          orderBy,
          order
        )) as ModulesState;
        if (response) {
          if (Array.isArray(response)) {
            setModules(response);
          }
          setTotalPages(totalPages);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };
  interface HeadCell {
    disablePadding: boolean;
    id: keyof ModulesState;
    label: string;
    numeric: boolean;
  }
  let headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name'
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated'
    },
    {
      id: 'account_module_access',
      numeric: false,
      disablePadding: false,
      label: 'Active'
    },
    {
      id: 'account_module_access',
      numeric: false,
      disablePadding: false,
      label: 'Locked'
    }
  ];

  headCells = headCells.filter((cell) => {
    if (cell.label === 'Visible') {
      return checkUserPermission(user, SETTINGS, 4, UPDATE);
    }
    return true;
  });

  const handleSort = (property: keyof ModulesState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(0, property, isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const redirectEditPage = (rowid: number) => {
    if (rowid == 1) {
      navigate(`/settings/modules/adviser-review/${encryptData(String(rowid), true)}`);
    } else if (rowid == 2) {
      navigate(`/settings/modules/compliance-calender/${encryptData(String(rowid), true)}`);
    } else if (rowid == 3) {
      navigate(`/settings/modules/meeting/${encryptData(String(rowid), true)}`);
    } else if (rowid == 4) {
      navigate(`/settings/modules/onboarding/${encryptData(String(rowid), true)}`);
    } else if (rowid == 5) {
      navigate(`/settings/modules/pre-vet/${encryptData(String(rowid), true)}`);
    } else if (rowid == 6) {
      navigate(`/settings/modules/adviser-profile/${encryptData(String(rowid), true)}`);
    } else if (rowid == 7) {
      navigate(`/settings/modules/attestations/${encryptData(String(rowid), true)}`);
    } else if (rowid == 8) {
      navigate(`/settings/modules/professional-year/${encryptData(String(rowid), true)}`);
    } else if (rowid == 9) {
      navigate(`/settings/modules/quickvets/${encryptData(String(rowid), true)}`);
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              errorMsg={error}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  {loading ? (
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : modules.length === 0 ? (
                    <div className="no-record">No modules found.</div>
                  ) : (
                    <div className="table-outer">
                      <TableContainer>
                        {loading ? ( // Check loading state
                          <div className="no-record">
                            <CircularProgress />
                          </div>
                        ) : modules.length === 0 ? (
                          <div className="no-record">No records found.</div>
                        ) : (
                          <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            className="table ">
                            <TableHead>
                              <TableRow>
                                {headCells.map((headCell, index) => (
                                  <TableCell
                                    key={`key-${headCell.id}-${index}`}
                                    align={headCell.numeric ? 'left' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.id === 'account_module_access' ? (
                                      headCell.label
                                    ) : (
                                      <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={() => handleSort(headCell.id)}>
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                          <Box component="span" sx={{ display: 'none' }}>
                                            {order === 'desc'
                                              ? 'sorted descending'
                                              : 'sorted ascending'}
                                          </Box>
                                        ) : null}
                                      </TableSortLabel>
                                    )}
                                  </TableCell>
                                ))}
                                {checkUserPermission(user, SETTINGS, 4, UPDATE) && (
                                  <TableCell></TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {modules.map((row: ModulesState) => (
                                <TableRow key={row.id}>
                                  <TableCell scope="row">{row.name}</TableCell>
                                  <TableCell align="left">{row.description}</TableCell>
                                  {row.updated_at ? (
                                    <TableCell align="left">
                                      {format(new Date(row.updated_at), 'd/MMM/yyyy')}
                                    </TableCell>
                                  ) : (
                                    <TableCell align="left">N/A</TableCell>
                                  )}

                                  {checkUserPermission(user, SETTINGS, 4, UPDATE) && (
                                    <>
                                      <TableCell align="left" className="">
                                        <Checkbox
                                          onChange={(e) =>
                                            handleSwitchToggle(
                                              row.id,
                                              'is_open',
                                              e.target.checked ? 1 : 0
                                            )
                                          }
                                          checked={
                                            row.account_module_access &&
                                            row.account_module_access.length > 0 &&
                                            parseInt(
                                              row.account_module_access[0]?.locked?.toString() ??
                                                '0',
                                              10
                                            ) === 0
                                          }
                                          className="custom-switch"
                                        />
                                      </TableCell>
                                      <TableCell align="left" className="">
                                        <Checkbox
                                          onChange={(e) =>
                                            handleSwitchToggle(
                                              row.id,
                                              'locked',
                                              e.target.checked ? 1 : 0
                                            )
                                          }
                                          checked={
                                            row.account_module_access &&
                                            row.account_module_access.length > 0 &&
                                            parseInt(
                                              row.account_module_access[0].locked?.toString() ??
                                                '0',
                                              10
                                            ) === 1
                                          }
                                          className="custom-switch"
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="right-arrow-width"
                                        onClick={() =>
                                          row.account_module_access.length != 0
                                            ? redirectEditPage(row.id)
                                            : ''
                                        }>
                                        <div className="table-btn">
                                          <img src={rightarrow} alt="Right Arrow" />
                                        </div>
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                        {!loading && modules.length > 0 && (
                          <PaginationSettings
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                          />
                        )}
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default ModulesIndex;
