import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { TemplatesState } from '../../views/components/user/state';
import { PaginationResponse } from '../../views/components/user/state';

export const fetchTemplates = async (
  page: number = 1, // Specify the page number, default to 1
  itemsPerPage: number = 10, // Specify the items per page, default to 10
  authToken?: string,
  nameSearch?: string,
  descriptionSearch?: string,
  selectedStatus?: string,
  templateId?: number
): Promise<PaginationResponse | TemplatesState | string | undefined> => {
  let routeUrl;
  if (!templateId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/templates?per_page=${itemsPerPage}&page=${page}&search2=${descriptionSearch}&search=${nameSearch}&status=${selectedStatus}`; // Include pagination parameters

    // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/templates/${dataEncrypt(templateId?.toString())}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
