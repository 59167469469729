import React, { useState, useEffect } from 'react';
import LeftNav from '../LeftNav';
import { accountDetails } from '../../../../api/settings/updateAccountDetails';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import AccountDetailsForm from './AccountDetailForm';
import { AccountDataFormState, AccountDetailsResponseError, AccountDetailsState } from '../state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useImage } from '../../layout/ImageContext';
import CommonLayout from '../../layout/CommonLayout';
import { useRegistersContext } from '../../registers/RegisterContext';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useModuleContext } from '../modules/ModulesContext';

const AccountIndex: React.FC = () => {
  const { t } = useTranslation();
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Account Settings';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [updateLogo, setUpdateLogo] = useState<File | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [prefixDisabled, setPrefixDisabled] = useState<boolean>(false);
  const authToken = useAuthToken() || '';
  const [selectedAccount, setSelectedAccount] = useState(getSelectedAccountInfo());
  const { setNotificationSuccess } = useNotificationContext();
  const { setSelectedImage, setSelectedName } = useImage();
  const { fetchModuleRecords } = useModuleContext();
  const { fetchRegisterRecords } = useRegistersContext();
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const [formData, setFormData] = useState<AccountDataFormState>({
    accountName: '',
    accountPrefix: '',
    primaryColor: '',
    logo: null,
    is_bespoke: false,
    compliance_query_email: '',
    plan_level: '0',
    error: ''
  });

  const [fetchLogo, setFetchLogo] = useState<{
    path: string;
    original_name: string;
  } | null>(null);

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 3, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchSettingAccountDetails = async () => {
    try {
      const { name, prefix, color, is_bespoke, image, compliance_query_email, plan_level } = selectedAccount;
      const imageObject = image ? JSON.parse(image) : null; // Add a null check
      const typedImageObject = imageObject
        ? (imageObject as { path: string; original_name: string })
        : null;
      if (typedImageObject && typedImageObject.path) {
        setFetchLogo({
          path: typedImageObject ? typedImageObject.path : '',
          original_name: typedImageObject ? typedImageObject.original_name : ''
        });
      } else {
        setFetchLogo(null);
      }
      if (prefix) {
        setPrefixDisabled(true);
      }
      setFormData({
        accountName: name,
        accountPrefix: prefix,
        primaryColor: color,
        logo: null,
        is_bespoke: is_bespoke == 1 ? true : false,
        compliance_query_email: compliance_query_email,
        plan_level: plan_level,
        error: ''
      });
      setSuccessMessage(false);
    } catch (error) {
      console.error('Error fetching user account details:', error);
    }
  };

  useEffect(() => {
    if(successMessage){
      setSelectedAccount(getSelectedAccountInfo())
    }
  }, [successMessage]);

  useEffect(() => {
    fetchSettingAccountDetails();
  }, [selectedAccount]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.accountName) {
      newErrors.accountName = t('error.field.accountDetailsName');
    }
    if (!formData.accountPrefix) {
      newErrors.accountPrefix = t('error.field.accountDetailsPrefix');
    } else if (formData.accountPrefix.length < 2 || formData.accountPrefix.length > 4) {
      newErrors.accountPrefix = t('error.field.accountDetailsPrefixLength');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }
    
    // Prepare the data to send to the API
    const requestData: AccountDetailsState = {
      name: formData.accountName,
      prefix: formData.accountPrefix,
      color: formData.primaryColor,
      image: updateLogo,// Use the updateLogo variable here
      compliance_query_email: formData.compliance_query_email,
      plan_level:  formData.plan_level,
    };

    if (formData.is_bespoke !== selectedAccount.is_bespoke) {
      requestData.is_bespoke = formData.is_bespoke;
    }

    const response: AccountDetailsResponseError = (await accountDetails(
      requestData,
      selectedAccount,
      authToken
    )) as AccountDetailsResponseError;
    if (!response) {
      fetchModuleRecords();
      fetchRegisterRecords();
      setErrors({});
      setSelectedName(formData.accountName);
      setSelectedImage(updateLogo);
      setSelectedFile(null);
      setFormData((prevData) => ({ ...prevData, error: '' }));
      setSuccessMessage(true);
      setNotificationSuccess(t('success.field.accountDetailsUpdates'));
      return;
    } else if (response == t('permission.doesNotHaveAccessPermission')) {
      setFormData((prevData) => ({ ...prevData, error: t('permission.doesNotHaveAccessPermission') }));
      return;
    } 

    const newErrors: Record<string, string> = {};
    if (response.name) {
      newErrors.accountName = response.name[0];
    } else if (response.prefix) {
      newErrors.accountPrefix = response.prefix[0];
    } else if (response.logo) {
      setSelectedFile(null);
      newErrors.logo = response.logo[0];
    } else {
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
    setErrors(newErrors);
  };

  const handleCancelClick = () => {
    // Reset form fields
    fetchSettingAccountDetails();
    setErrors({});
    setSelectedFile(null);
    setFormData((prevData) => ({ ...prevData, error: '' }));
    setSelectedImage(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(file);
      setUpdateLogo(file);
    } else {
      setPreviewURL(null);
      setUpdateLogo(null);
    }
  };

  const handleCloseClick = () => {
    setFormData((prevData) => ({ ...prevData, error: '' }));
  };

  const handleSwitchChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setFormData((prevData) => ({ ...prevData, [fieldName]: checked }));
    };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              errorMsg={formData.error}
              handleCloseClick={handleCloseClick}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    <AccountDetailsForm
                      user={user}
                      formData={formData}
                      handleInputChange={handleInputChange}
                      handleSelectChange={handleSelectChange}
                      handleSubmit={handleSubmit}
                      handleFileChange={handleFileChange}
                      handleCancelClick={handleCancelClick}
                      previewURL={previewURL}
                      fetchLogo={fetchLogo}
                      errors={errors}
                      selectedFile={selectedFile}
                      prefixDisabled={prefixDisabled}
                      handleSwitchChange={handleSwitchChange}
                      selectedAccount={selectedAccount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default AccountIndex;
