import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import { ComplaintsReportDataState } from '../../user/state';
import { CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import { fetchComplaints } from '../../../../api/reporting/fetchReportingComplaints';

const ComplaintsReportIndex = () => {
  const title = 'Complaints';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Reports';
  const secondpath = '/dashboard';
  const pageTitle = title;
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ComplaintsReportDataState>();

  const fetchData = async () => {
    setLoading(true);

    try {
      if (authToken) {
        const response: ComplaintsReportDataState = (await fetchComplaints(
          authToken,
          selectedAccount
        )) as ComplaintsReportDataState;
        if (response) {
          setChartData(response);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />
          {!loading &&
          !chartData?.openComplaints &&
          !chartData?.newComplaints &&
          !chartData?.compensationPaid &&
          !chartData?.resolvedComplaintDays ? (
            <div className="no-record">No Data found.</div>
          ) : chartData && chartData ? (
            <>
              <div className="chart-complaints">
                <ul className="chart-complaints-list">
                  <li>
                    <div className="card-outline">
                      <div className="complaints-desc">Current open complaints</div>
                      <div className="complaints-count">{chartData.openComplaints}</div>
                    </div>
                  </li>
                  <li>
                    <div className="card-outline">
                      <div className="complaints-desc">
                        Number of new complaints received in last 12 months
                      </div>
                      <div className="complaints-count">{chartData.newComplaints}</div>
                    </div>
                  </li>
                </ul>
                <div className="card-outline total-complaints">
                  <div className="complaints-desc">
                    Compensation provided to complainants this financial year
                  </div>
                  <div className="complaints-count">{chartData.compensationPaid}</div>
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={chartData.resolvedComplaintDays}
                    options={{
                      title: 'Days taken to resolve complaint (last 12 months)',
                      tooltip: { isHtml: true }, // CSS styling affects only HTML tooltips.
                      legend: { position: 'none' },
                      colors: ['#1DA2EF'],
                      chartArea: {
                        top: 40, // Increase top margin to provide more space for y-axis labels
                        bottom: 60, // Increase bottom margin to provide more space for x-axis labels
                        left: 50,
                        right: 50
                      }
                    }}
                  />
                </div>
                <div className="report-chart">
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={chartData.complaintsReportedTOAFCA}
                    options={{
                      title: 'Complaints referred to AFCA (last 12 months)',
                      // is3D: true,
                      pieSliceText: 'value',
                      slices: {
                        0: { offset: 0.1 }
                      }
                    }}
                  />
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  {chartData.complaintRelatedProducts.length > 1 ? (
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={chartData.complaintRelatedProducts}
                      options={{
                        title: 'Top 5 complaint related product / service types (last 12 months)',
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: 'Count',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'Product/Service Name'
                        },
                        bars: 'horizontal'
                      }}
                    />
                  ) : (
                    <>
                      <h3>Top 5 complaint related product / service types (last 12 months)</h3>
                      <div className="no-record">No Data found.</div>
                    </>
                  )}
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  {chartData.complaintIssueTypes.length > 1 ? (
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={chartData.complaintIssueTypes}
                      options={{
                        title: 'Top 5 issue types raised by complainants (last 12 months)',
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: 'Count',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'Issue Type'
                        },
                        bars: 'horizontal'
                      }}
                    />
                  ) : (
                    <>
                      <h3>Top 5 issue types raised by complainants (last 12 months)</h3>
                      <div className="no-record">No Data found.</div>
                    </>
                  )}
                </div>
              </div>
              <div className="google-chart">
                <div className="report-chart margin-chart margin-google-chart">
                  {chartData.representativeInvolvedInDisputes.length > 1 ? (
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      height="400px"
                      data={chartData.representativeInvolvedInDisputes}
                      options={{
                        title:
                          'Top 10 Representatives involved in disputes - advice related (last 12 months)',
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: 'Count',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'Representative Name'
                        },
                        bars: 'horizontal'
                      }}
                    />
                  ) : (
                    <>
                      <h3>
                        Top 10 Representatives involved in disputes - advice related (last 12
                        months)
                      </h3>
                      <div className="no-record">No Data found.</div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : null}

          {loading && (
            <div className="no-record">
              <CircularProgress />
            </div>
          )}
        </div>
      </section>
    </CommonLayout>
  );
};

export default ComplaintsReportIndex;
