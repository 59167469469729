import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import SuccessMsg from '../../../assets/images/success-msg.svg';
import CommonLayout from '../layout/CommonLayout';

const AccountResetPasswordSuccess: React.FC = () => {
  const metapageTitle = `${process.env.REACT_APP_NAME}: Account Reset Sucess`;

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-password">
        <div className="password-success">
          <div className="left-col">
            <div className="left-inner">
              <div>
                {' '}
                <img src={SuccessMsg} alt=""></img>
              </div>
              <div className="title-wrap">
                <h1>Password reset Successfull!</h1>
                <p>Your password has been successfully reset, Click below to back to home.</p>
              </div>
              <Button
                variant="contained"
                className="btn primary-btn"
                to="/dashboard"
                component={Link}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default AccountResetPasswordSuccess;
