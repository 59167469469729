import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { CalenderEventState } from '../../views/components/user/state';

export const getCalendarEvents = async (
  source_id?: number,
  authToken?: string,
  accountInfo?: AccountState,
  schedule?: boolean,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<CalenderEventState[]> => {
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  if (schedule) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-calender-events?source_id=${source_id}&account_id=${selectedAccountId}&schedule=1&orderBy=${orderBy}&order=${order}`;
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-calender-events?source_id=${source_id}&account_id=${selectedAccountId}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;

    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};


export const getCalendarEventId = async (
  source_id?: number,
  authToken?: string,
  accountInfo?: AccountState,
  event_id?: number,
): Promise<CalenderEventState> => {
  const selectedAccountId = accountInfo?.id;

  const routeUrl = `${process.env.REACT_APP_API_URL}/fetch-calender-events/${dataEncrypt(event_id?.toString() ?? '0')}?account_id=${selectedAccountId}&source_id=${source_id}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;

    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};

