import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { fetchAdviserReviews } from '../../../../api/adviser/fetchAdviserReviews';
import { ReviewState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import { MODULES, NOTE_READ, NOTE_CREATE } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import ReviewLeftMenu from './ReviewLeftMenu';
import AddButton from '../../notes/AddButton';
import Notes from '../../notes/Notes';

const ReviewTasksIndex = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let reviewId = queryParams.get('reviewId');
  const encryptedReviewId = reviewId; // Decrypt the ID
  reviewId = decryptData(reviewId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/reviews/${encryptedSourceId}`;
  const title = 'Adviser Review Notes';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('review_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/review/${encryptedReviewId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, NOTE_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchReviewData = async () => {
    try {
      if (reviewId && authToken) {
        const editId = parseInt(reviewId!, 10);

        const response: ReviewState = (await fetchAdviserReviews(
          0,
          authToken,
          selectedAccount,
          editId
        )) as ReviewState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);

          localStorage.removeItem('review_unique_id');
          localStorage.setItem('review_unique_id', unique_id);
        }
      }
    } catch (error) {
      /* empty */
    }
  };
  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
        <AddButton
          addPath={`/review/add-note?source=${encryptedSourceId}&reviewId=${encryptedReviewId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <ReviewLeftMenu
                  user={user}
                  reviewId={reviewId ? reviewId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  reviewIdEncrypted={encryptedReviewId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <Notes
                  moduleType="reviews"
                  sourceId={sourceId}
                  recordId={reviewId}
                  localKey="review_unique_id"
                  fetchModuleData={fetchReviewData}
                  editUrl={`/review/edit-note/rowId?source=${encryptedSourceId}&reviewId=${encryptedReviewId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default ReviewTasksIndex;
