import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, Typography, IconButton, Link } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface AppBarElementProps {
  onVisibilityChange?: (isVisible: boolean) => void;
}

export default function AppBarElement4BelowHeader({ onVisibilityChange }: AppBarElementProps) {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(() => {
    const storedVisibility = localStorage.getItem('appBarVisible');
    return storedVisibility !== 'false'; // Default to true if not set
  });

  useEffect(() => {
    onVisibilityChange?.(isVisible);
  }, [isVisible, onVisibilityChange]);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('appBarVisible', 'false'); // Persist visibility state
  };

  // Check if the current path is `/dashboard`
  if (location.pathname !== '/dashboard' || !isVisible) {
    return null; // Render nothing if not on `/dashboard` or not visible
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: '10px' }}>
      <AppBar
        position="static"
        elevation={0} // Removes Material-UI default shadow
        sx={{
          boxShadow: 'none',
          backgroundColor: '#16D299', // Professional green background
          color: 'white', // White text for better contrast
          padding: '10px 20px' // Adds padding for a clean layout
        }}>
        <Toolbar
          variant="dense"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px'
          }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center align vertically
              justifyContent: 'center', // Center align horizontally
              textAlign: 'center'
            }}>
            {/* First Line */}
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: '1.8rem',
                fontWeight: 'bold'
              }}>
              ACCESS PREMIUM FREE FOR 30 DAYS!
            </Typography>
            {/* Second Line */}
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                marginTop: '4px' // Add slight spacing between lines
              }}>
              OFFER ENDS 31 <sup>st</sup> DECEMBER USE CODE &#39;PREMIUM30&#39; AT CHECKOUT
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              fontSize: '1.6rem',
              fontWeight: 'bold'
            }}>
            <Link
              component={RouterLink} // Use RouterLink for navigation
              to="/upgrade" // Navigate to the same path as the Button
              underline="hover"
              sx={{
                color: 'white',
                textDecoration: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#FFD700' }
              }}>
              CLAIM NOW
            </Link>
          </Box>

          {/* Close Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={handleClose}
            sx={{
              padding: '5px',
              transition: 'transform 0.3s ease',
              '&:hover': { transform: 'scale(1.2)' }
            }}>
            <HighlightOffIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
