import { createSlice } from '@reduxjs/toolkit';
import { ModulesState } from '../../views/components/user/state';

const moduleSlice = createSlice({
  name: 'module',
  initialState: {
    modules: [] as ModulesState[]
  },
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    updateModule: (state, action) => {
      const updatedModule = action.payload;
      const moduleIndex = state.modules.findIndex((module) => module.id === updatedModule.id);
      if (moduleIndex !== -1) {
        // Replace the module at the specified index with the updated module
        state.modules[moduleIndex] = updatedModule;
      }
    }
  }
});

export const { setModules, updateModule } = moduleSlice.actions;
export default moduleSlice.reducer;
