import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Avatar,
  Grid
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { format } from 'date-fns';
import PaginationSettings from '../../settings/Pagination';
import { PaginationResponse, QuickVetState } from '../../user/state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import CommonLayout from '../../layout/CommonLayout';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { CREATE, MODULES, READ } from '../../../../constants/constants';
import QuickVetFilters from './FilterBtn';
import { fetchQuickVets } from '../../../../api/quickvets/fetchQuickVets';

const QuickVetIndex: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  const title = 'QuickVets';
  const [orderBy, setOrderBy] = useState<keyof QuickVetState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [quickVet, setQuickVet] = useState<QuickVetState[]>([]);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('1');
  const { moduleRecords } = useModuleContext();

  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const moduleId = parseInt(id!, 10);
  const foundModule =
    Array.isArray(moduleRecords) && moduleRecords.length > 0
      ? moduleRecords.find((module) => module.id === moduleId)
      : '';

  interface HeadCell {
    disablePadding: boolean;
    id: keyof QuickVetState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'unique_id',
      numeric: false,
      disablePadding: true,
      label: 'ID'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'adviser_id',
      numeric: false,
      disablePadding: false,
      label: 'Adviser'
    },
    {
      id: 'client_name',
      numeric: false,
      disablePadding: false,
      label: 'Client'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Status'
    }
  ];

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, parseInt(id!, 10), READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (
        Array.isArray(moduleRecords) &&
        moduleRecords.length > 0 &&
        ((id && !foundModule) || !id)
      ) {
        navigate('/404');
      }
      if (authToken && id) {
        const source_id = parseInt(id, 10);
        const response: PaginationResponse = (await fetchQuickVets(
          source_id,
          authToken,
          selectedAccount,
          0,
          currentPage,
          search,
          selectedStatus,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setQuickVet(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(orderBy, order);
  }, [id, currentPage, search, selectedStatus]);

  const handleSort = (property: keyof QuickVetState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc'); // Cast to string
  };

  const redirectEditPage = (rowid: number) => {
    navigate(`/quickvet/${encryptData(String(rowid), true)}?source=${encryptedId}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, parseInt(id!, 10), CREATE) && (
        <div className="inner-btn-wrap">
          <Button
            className="btn primary-btn"
            component={RouterLink}
            to={`/quickvet-add?source=${encryptedId}`}>
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Add QuickVet
          </Button>
        </div>
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs title={title} pageTitle={title} button={addButton} />
              <QuickVetFilters
                search={search}
                setSearch={setSearch}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
              <div className="table-outer">
                <TableContainer>
                  {loading ? (
                    <div className="no-record">
                      <CircularProgress />
                    </div>
                  ) : quickVet.length === 0 ? (
                    <div className="no-record">No QuickVets found.</div>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="table hover-table">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={`key-${headCell.id}`}
                              align={headCell.numeric ? 'left' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}>
                              {headCell.id === 'unique_id' ? (
                                headCell.label // Render the label without sorting for the "id" column
                              ) : (
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={orderBy === headCell.id ? order : 'asc'}
                                  onClick={() => handleSort(headCell.id)}>
                                  {headCell.label}
                                  {orderBy === headCell.id ? (
                                    <Box component="span" sx={{ display: 'none' }}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quickVet.map((data: QuickVetState) => (
                          <TableRow key={data.id} onClick={() => redirectEditPage(data.id)}>
                            <TableCell scope="row" className="unique-id-width">
                              {data.unique_id}
                            </TableCell>
                            {data.created_at ? (
                              <TableCell align="left">
                                {format(new Date(data.created_at), 'd/MMM/yyyy')}
                              </TableCell>
                            ) : (
                              <TableCell align="left">N/A</TableCell>
                            )}
                            <TableCell align="left">
                              <Grid container alignItems="center" flexWrap="nowrap">
                                <Grid item>
                                  {data?.adviser?.profile_image ? (
                                    <Avatar
                                      src={`${JSON.parse(data.adviser.profile_image).path}`}
                                      alt={data?.adviser ? data?.adviser.first_name : 'user'}
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.style.display = 'none'; // Hide the image
                                        const nextElement =
                                          target.nextElementSibling as HTMLElement | null;
                                        if (nextElement) {
                                          nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                        }
                                      }}
                                    />
                                  ) : (
                                    <Avatar alt={data?.adviser?.first_name} src="." />
                                  )}
                                </Grid>
                                <Grid item className="content">
                                  <p>
                                    {data?.adviser?.first_name + ' ' + data?.adviser?.last_name}
                                  </p>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="left">
                              <Grid container alignItems="center" flexWrap="nowrap">
                                <Grid item className="content">
                                  <p>{data?.subject}</p>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="left" className={data.is_open ? 'open' : 'closed'}>
                              <span>{data.is_open ? 'Open' : 'Archived'}</span>
                            </TableCell>
                            <TableCell align="left" className="right-arrow-width">
                              <div className="table-btn">
                                <img src={rightarrow} alt="Right Arrow" />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {!loading && quickVet.length > 0 && (
                    <PaginationSettings
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </TableContainer>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default QuickVetIndex;
