import React, { useEffect, useState } from 'react';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { READ, SETTINGS } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import LeftNav from '../LeftNav';
import { StripeSessionUrlState } from '../state';
import { fetchBillingPortalSessionURL } from '../../../../api/settings/fetchBillingPortalSessionURL';
import { CircularProgress } from '@mui/material';

const BillingPortal = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const title = 'Billing';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';

  const handleBiiling = async () => {
    try {
      const response: StripeSessionUrlState = (await fetchBillingPortalSessionURL(
        authToken,
        selectedAccount
      )) as StripeSessionUrlState;

      if (response.url) {
        const a = document.createElement('a');
        a.href = response.url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!checkUserPermission(user, SETTINGS, 8, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
    handleBiiling();
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    Wait a minute we will redirect you on secure billing portal shortly.

                    <div className="mt-2">
                      <CircularProgress />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default BillingPortal;
