import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  decryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, NOTE_CREATE } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import MeetingLeftMenu from './MeetingLeftMenu';
import AddNotes from '../../notes/AddNotes';

const MeetingNotesAdd = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let meetingId = queryParams.get('meetingId');
  const encryptedMeetingId = meetingId; // Decrypt the ID
  meetingId = decryptData(meetingId, true);
  const mainPath = `/meetings/${encryptedSourceId}`;
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const title = id ? 'Edit Meeting Note' : 'Add Meeting Note';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const thirdTitle = 'Notes';
  const thirdPath = `/meeting/notes?source=${encryptedSourceId}&recordId=${encryptedMeetingId}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const [isAccessable, setIsAccessable] = useState<boolean>(false);

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const meetingId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === meetingId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id = localStorage.getItem('meeting_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/meeting/${encryptedMeetingId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, NOTE_CREATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <MeetingLeftMenu
                  user={user}
                  meetingId={meetingId ? meetingId : ''}
                  sourceId={sourceId || ''}
                  meetingIdEncrypted={encryptedMeetingId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <AddNotes
                  moduleType="meetings"
                  sourceId={sourceId}
                  recordId={meetingId}
                  localKey="meetingNotesCount"
                  listUrl={`/meeting/notes?source=${encryptedSourceId}&meetingId=${encryptedMeetingId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default MeetingNotesAdd;
