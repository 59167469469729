import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { sendResetPasswordEmail } from '../../../api/user/resetPassword';
import { Alert, IconButton } from '@mui/material';
import CommonLayout from '../layout/CommonLayout';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const ResetPasswordSuccess: React.FC = () => {
  const title = 'Reset Password Success';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const location = useLocation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type') || '';

  const { email } = location.state || {};

  const [resendLoading, setResendLoading] = useState(false);
  const [resendError, setResendError] = useState<string | null>(null);

  const [resendSuccessMsg, setResendSuccessMsg] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleResendEmail = async () => {
    setResendLoading(true);
    setResendError(null);

    if (email) {
      const errorMessage = await sendResetPasswordEmail({ email }, type);
      if (errorMessage) {
        setResendError(errorMessage);
        setShowAlert(true);
      } else {
        setResendSuccessMsg(
          `We have resent a reset password link to ${typeof email === 'string' ? email : ''}`
        );
        setShowAlert(true);
      }
    }

    setResendLoading(false);
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              {type ? (
                <Link to="/onboarding-login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              ) : (
                <Link to="/login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              )}
              <div className="title-wrap">
                <h1>Check your email</h1>
                <p>We sent a password reset link to {typeof email === 'string' ? email : ''}</p>
              </div>
              {/* resend success handling */}
              {resendSuccessMsg && showAlert && (
                <Alert
                  variant="outlined"
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseAlert}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  <div>{resendSuccessMsg}</div>
                </Alert>
              )}
              {/* Error handling */}
              {resendError && showAlert && (
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseAlert}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  <div>{resendError}</div>
                </Alert>
              )}
              <div className="text-link-wrap">
                <p>
                  Didn’t receive the email?{' '}
                  <a
                    className="primary-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!resendLoading) {
                        handleResendEmail();
                      }
                    }}>
                    {resendLoading ? 'Resending...' : 'Click to resend'}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-reset-password.png`}
                  alt=""
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default ResetPasswordSuccess;
