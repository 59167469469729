import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReviewNotesIndex from './ReviewNotesIndex';
import ReviewTasksIndex from './ReviewTasksIndex';
import ReviewTasksAddEdit from './ReviewTasksAddEdit';
import ReviewNotesAdd from './ReviewNotesAdd';
import FileReviewAdd from './FileReviewAdd';
import FileReviewTemplateEdit from './FileReviewTemplateEdit';
import ReviewIndex from './ReviewIndex';
import ReviewCreateEdit from './ReviewCreateEdit';
import FileReviewCompleteView from './FileReviewCompleteView';

const AdviserRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ReviewIndex />} />
      <Route path="/:id" element={<ReviewCreateEdit />} />
      <Route path="/notes" element={<ReviewNotesIndex />} />
      <Route path="/add-note" element={<ReviewNotesAdd />} />
      <Route path="/edit-note/:id/" element={<ReviewNotesAdd />} />
      <Route path="/tasks" element={<ReviewTasksIndex />} />
      <Route path="/add-task" element={<ReviewTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<ReviewTasksAddEdit />} />
      <Route path="/add-file-review" element={<FileReviewAdd />} />
      <Route path="/edit-file-review/:id/" element={<FileReviewTemplateEdit />} />
      <Route path="/view-file-review/:id/" element={<FileReviewCompleteView />} />
    </Routes>
  );
};

export default AdviserRoutes;
