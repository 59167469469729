import { PaginationResponse, PlanState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchPlans = async (
  authToken?: string,
  planId?: number,
  currentPage: number = 1,
  search: string = '',
  selectedStatus: string = '1',
  itemsPerPage: number = 10,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | PlanState | string | undefined> => {
  let routeUrl;
  if (!planId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/plans?page=${currentPage}&perPage=${itemsPerPage}&search=${search}&status=${selectedStatus}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/plans/${dataEncrypt(planId?.toString())}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching updates';
  }
};

