import { PaginationResponse } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchProfessionalYearsLogTrainingData = async (
  authToken: string,
  professionYearId: number,
  type: string = '',
  currentPage: number = 1,
  itemsPerPage: number = 10
): Promise<PaginationResponse | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/professional-years-logtraining-data?page=${currentPage}&perPage=${itemsPerPage}&professionYearId=${dataEncrypt(professionYearId?.toString())}&type=${type}`; // Include pagination parameters
  
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
};

