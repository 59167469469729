import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { setSurveyJsLicenceKey } from '../../../../utils/common/app';
import { Button } from '@mui/material';
import { useAuthToken, getSelectedAccountInfo, decryptData } from '../../../../utils/common/user';
import { useNotificationContext } from '../../layout/NotificationContext';
import { FileReviewTemplatesState } from '../../user/state';
import { fetchFileReviewTemplates } from '../../../../api/adviser/fetchFileReviewTemplates';
import { useTranslation } from 'react-i18next';
import CommonLayout from '../../layout/CommonLayout';
import { createFileReviewTemplate } from '../../../../api/settings/createFileReviewTemplate';
import LazyImage from '../../common/LazyLoad';
import { S3PublicUrl } from '../../../../constants/constants';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showThemeTab: false
};

interface DefaultAnswers {
  [key: string]: any;
}

const FileReviewTemplateCreate: React.FC = () => {
  const { t } = useTranslation();
  let { id } = useParams(); // Get encrypted ID from URL
  id = decryptData(id, true);
  const { search } = useLocation();
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const pageTitle = id ? 'Edit Template' : 'Create Template';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const queryParams = new URLSearchParams(search);
  let copyId = queryParams.get('copyId');
  copyId = copyId ? decryptData(copyId, true) : 0;

  let sourceId = queryParams.get('sourceId') || '';
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);

  const [creator, setCreator] = useState<SurveyCreator | null>(null);
  const [answers, setAnswers] = useState<any | null>({});
  const { setNotificationSuccess } = useNotificationContext();
  const [dataFetched, setDataFetched] = useState(false);
  const selectedAccount = getSelectedAccountInfo();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setSurveyJsLicenceKey();
    let json = localStorage.getItem('survey-json-file-review-temp') || '';
    const creator = new SurveyCreator(creatorOptions);

    if (creator && dataFetched && json !== '') {
      const surveyStructure = JSON.parse(json);
      json = JSON.stringify(surveyStructure);
    }
    creator.isAutoSave = true;
    creator.text = json;
    setCreator(creator);

    if ((id || copyId) && authToken) {
      fetchData();
    }
  }, [id, copyId, dataFetched]);

  const fetchData = async () => {
    try {
      if ((id || copyId) && authToken) {
        const templateId = copyId ? parseInt(copyId, 10) : parseInt(id!, 10);

        const response: FileReviewTemplatesState = (await fetchFileReviewTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          0,
          templateId
        )) as FileReviewTemplatesState;

        if (response) {
          setDataFetched(true);
          const { form_data } = response;
          localStorage.removeItem('survey-json-file-review-temp');
          if (form_data && form_data.trim() !== '""') {
            localStorage.setItem('survey-json-file-review-temp', form_data);
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined) {
          // Add the value to the element
          element.defaultValue = fieldValues[fieldName];
        }
      });
    });
    return mainResponse;
  };

  const handleSaveSurvey = async () => {
    if (creator) {
      const surveyStructure = JSON.parse(creator.text);
      let mergedResponse = surveyStructure;
      if (Object.keys(surveyStructure).length !== 0 && Object.keys(surveyStructure).length > 1) {
        // Merge the survey structure and answers
        setAnswers({});
        mergedResponse = mergeFieldValues(surveyStructure, answers);
      }

      const titleField = surveyStructure.title;
      if (!titleField) {
        setErrorMessage('Title field is required.');
        return;
      }

      const fileReviewMainTempId = parseInt(id!, 10);
      try {
        const response: FileReviewTemplatesState = (await createFileReviewTemplate(
          mergedResponse,
          authToken,
          selectedAccount,
          0,
          fileReviewMainTempId
        )) as FileReviewTemplatesState;
        if (!response) {
          const msg = id ? t('success.field.templateUpdated') : t('success.field.templateCreated');
          setNotificationSuccess(msg);
          navigate(`/settings/modules/adviser-review/${encryptedSourceId}`);
        } else {
          setErrorMessage(response.message || 'Template Name Already Exists.');
        }
      } catch (error) {
        console.error('Error saving survey:', error);
        setErrorMessage('An error occurred while saving.');
      }
    }
  };

  const defaultAnswers: DefaultAnswers = {};
  if (creator) {
    creator.onSurveyInstanceCreated.add((sender, options) => {
      options.survey.onValueChanged.add((sender: any, options: any) => {
        defaultAnswers[options.name] = options.value;
        setAnswers(defaultAnswers);
      });
    });
  }

  return (
    <CommonLayout title={metapageTitle}>
      <div className="settings-outer-wrap">
        <div className="inner-btn-wrap secondary-button-wrap">
          <div className="btn-wrap">
            <Button onClick={handleSaveSurvey} className="btn primary-btn btn-sm">
              Save
            </Button>
            <Button
              className="btn border-btn btn-sm"
              component={RouterLink}
              to={`/settings/modules/adviser-review/${encryptedSourceId}`}>
              Cancel
            </Button>
          </div>
          <a href="" className="template-logo">
            <LazyImage src={`${S3PublicUrl}3lines-logo-small.png`} alt="logo" />
          </a>
        </div>
        <div className="no-record ">
          {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
        <div className="settings-outer-row">
          <div className="right-outer full-width">
            <div className="right-inner">
              {creator && <SurveyCreatorComponent creator={creator} />}
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default FileReviewTemplateCreate;
