import React from 'react';
import { FormControl, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import search from '../../../../assets/images/search.svg';
import { UpdateFilterProps } from '../../user/state';

const SearchIcon = () => (
  <InputAdornment position="start">
    <img src={search} alt="search" />
  </InputAdornment>
);

const RecordFilters: React.FC<UpdateFilterProps> = ({
  search,
  setSearch,
  selectedStatus,
  setSelectedStatus
}) => {
  return (
    <div className="filter-outer">
      <div className="filter-list search-box">
        <div className="form-group">
          <TextField
            id="first-name-input"
            type="search"
            className="form-control"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: <SearchIcon />
            }}
            variant="outlined"
            aria-label="Search"
          />
        </div>
      </div>
      <div className="filter-list">
        <div className="form-group">
          <FormControl variant="outlined" className="form-control">
            {/* <InputLabel id="status-label">Status</InputLabel> */}
            <Select
              labelId="status-label"
              id="status-select"
              label="Status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value as string)}
              name="status"
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="custom-select"
              MenuProps={{ className: 'custom-dropdown-menu' }}
              aria-label="Select status">
              <MenuItem value="1" defaultChecked>Open</MenuItem>
              <MenuItem value="0">Archived</MenuItem>
              <MenuItem value="All">All</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default RecordFilters;
