import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NewEntrantDashboard from '../../../dashboard/NewEntrantDashboard';
import PlanSupervisorTemplateView from './PlanSupervisorTemplateView';
import ProfessionalYearQ1PlanEntrantTemplateAddEdit from './ProfessionalYearQ1PlanEntrantTemplateAddEdit';
import ProfessionalYearQ2PlanEntrantTemplateAddEdit from './ProfessionalYearQ2PlanEntrantTemplateAddEdit';
import ProfessionalYearQ3PlanEntrantTemplateAddEdit from './ProfessionalYearQ3PlanEntrantTemplateAddEdit';
import ProfessionalYearQ4PlanEntrantTemplateAddEdit from './ProfessionalYearQ4PlanEntrantTemplateAddEdit';
import ProfessionalYearExamEntrantTemplateAddEdit from './ProfessionalYearExamEntrantTemplateAddEdit';

const NewEntrantRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<NewEntrantDashboard />} />
      <Route path="/plan" element={<PlanSupervisorTemplateView />} />
      <Route path="/q1plan-entrant-edit" element={<ProfessionalYearQ1PlanEntrantTemplateAddEdit />} />
      <Route path="/q1plan-entrant-view" element={<ProfessionalYearQ1PlanEntrantTemplateAddEdit />} />
      <Route path="/q2plan-entrant-edit" element={<ProfessionalYearQ2PlanEntrantTemplateAddEdit />} />
      <Route path="/q2plan-entrant-view" element={<ProfessionalYearQ2PlanEntrantTemplateAddEdit />} />
      <Route path="/q3plan-entrant-edit" element={<ProfessionalYearQ3PlanEntrantTemplateAddEdit />} />
      <Route path="/q3plan-entrant-view" element={<ProfessionalYearQ3PlanEntrantTemplateAddEdit />} />
      <Route path="/q4plan-entrant-edit" element={<ProfessionalYearQ4PlanEntrantTemplateAddEdit />} />
      <Route path="/q4plan-entrant-view" element={<ProfessionalYearQ4PlanEntrantTemplateAddEdit />} />
      <Route path="/exam" element={<ProfessionalYearExamEntrantTemplateAddEdit />} />
      <Route path="/exam-view" element={<ProfessionalYearExamEntrantTemplateAddEdit />} />
    </Routes>
  );
};

export default NewEntrantRoutes;
