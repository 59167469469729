import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { TaskAddEditDialogProps } from './state';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const TaskAddEditDialog = ({
  question,
  formData,
  ownerArray,
  errors,
  openTaskAddEditDialog,
  toggleTaskAddEditDialog,
  handleInputChange,
  handleChangeUser,
  handleDateInputChange,
  handleIsCompleteChange,
  handleSubmit,
  loading
}: TaskAddEditDialogProps) => {
  const minDate = dayjs(); // This sets the minimum date to today
  const maxAllowedDate = new Date();
  const minAllowedDate = maxAllowedDate;

  return (
    <Dialog
      open={openTaskAddEditDialog}
      onClose={toggleTaskAddEditDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="model-wrapper">
        <div className="modal-title">
          <h2>{formData.id ? 'Edit Task' : 'Add Task'}</h2>
        </div>
        {question && (
          <div className="modal-description">
            <p>{question}</p>
          </div>
        )}
        <form onSubmit={(e) => handleSubmit(e, 'task')}>
          <div className="row-wrap">
            <div className="form-group w-full">
              <label className="form-label" htmlFor="subject">
                Task
              </label>
              <TextField
                error={!!errors.task}
                fullWidth
                name="task"
                className="form-control"
                placeholder="Enter the task"
                value={formData.task}
                onChange={(e) => handleInputChange(e, 'task')}
                helperText={errors.task || ''}
                inputProps={{
                  maxLength: 100 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full">
              <label className="form-label" htmlFor="details">
                Details
              </label>
              <TextField
                error={!!errors.details}
                fullWidth
                name="details"
                className="form-control textarea"
                placeholder="Enter detailed description"
                multiline
                rows={4}
                value={(formData.details || '').replace(/<br\/>/g, '\n')}
                onChange={(e) => handleInputChange(e, 'task')}
                helperText={errors.details || ''}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Owner</label>
              <FormControl variant="outlined">
                <Select
                  error={!!errors.owner}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className="custom-select"
                  value={formData.owner?.toString()}
                  onChange={handleChangeUser}
                  MenuProps={{ className: 'custom-dropdown-menu' }}
                  renderValue={(selected) => {
                    if (selected === '0') {
                      return <p className="dropdown-placeholder">Select Owner</p>; // Render "Select Owner" as placeholder
                    }
                    const selectedOption =
                      ownerArray &&
                      ownerArray.find((option) => option.id === parseInt(selected, 10));
                    return selectedOption ? `${selectedOption.name}` : '';
                  }}>
                  {ownerArray.map((option) => (
                    <MenuItem key={option?.id} value={option?.id || ''}>
                      {option?.name || ''}
                    </MenuItem>
                  ))}
                </Select>
                {errors.owner && (
                  <Typography variant="caption" color="error">
                    {errors.owner}
                  </Typography>
                )}
              </FormControl>
            </div>
            <div className="form-group">
              <label className="form-label">Date Due</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MMM/YYYY"
                  className="form-control date-picker"
                  value={formData.date_due ? dayjs(formData.date_due) : null}
                  minDate={minDate}
                  onChange={(date) => handleDateInputChange(date, 'date_due')}
                  slotProps={{
                    textField: {
                      error: !!errors.date_due,
                      placeholder: 'Select due date',
                      helperText: errors.date_due
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="form-group check-outer">
              <label className="form-label">Completed</label>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_complete"
                    checked={formData.is_complete === 1}
                    onChange={handleIsCompleteChange}
                    className="custom-check custom-check-other"
                  />
                }
                label=""
              />
            </div>
            {formData.is_complete === 1 && (
              <>
                <div className="form-group">
                  <label className="form-label">Date Completed</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MMM/YYYY"
                      className="form-control date-picker"
                      value={formData.date_complete ? dayjs(formData.date_complete) : null}
                      minDate={dayjs(minAllowedDate)} // Set minDate based on the calculated difference
                      maxDate={dayjs(maxAllowedDate)} // Set maxDate to today
                      onChange={(date) => handleDateInputChange(date, 'date_complete')}
                      slotProps={{
                        textField: {
                          error: !!errors.date_complete,
                          placeholder: 'Select date completed',
                          helperText: errors.date_complete
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="form-group w-full">
                  <label className="form-label" htmlFor="note">
                    Completed Notes
                  </label>
                  <TextField
                    error={!!errors.completed_notes}
                    fullWidth
                    multiline
                    rows={4}
                    name="completed_notes"
                    className="form-control textarea"
                    placeholder="Add notes about the completion of the task"
                    value={formData.completed_notes ? formData.completed_notes : ''}
                    onChange={(e) => handleInputChange(e, 'task')}
                    helperText={errors.completed_notes || ''}
                  />
                </div>
              </>
            )}
          </div>
          <div className="btn-wrap">
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button className="btn border-btn btn-sm" onClick={toggleTaskAddEditDialog}>
                  Cancel
                </Button>
                <Button className="btn primary-btn btn-sm" type="submit">
                  Save
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default TaskAddEditDialog;
