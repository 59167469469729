import { dataEncrypt, tokenCheck } from "../../utils/common/user";
import { AttestationState } from "../../views/components/modules/attestations/state";
import { AccountState, PaginationResponse } from "../../views/components/user/state";
import { ApiResponse, ApiResponseType } from "../apiResponse";

export const fetchAttestations = async (
  authToken: string,
  accountInfo: AccountState,
  attestationId?: number,
  currentPage: number = 1,
  search: string = '',
  selectedStatus: string = '',
  itemsPerPage: number = 10,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | AttestationState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  if (!attestationId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/attestations?page=${currentPage}&perPage=${itemsPerPage}&search=${search}&status=${selectedStatus}&account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/attestations/${dataEncrypt(attestationId?.toString())}?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
}