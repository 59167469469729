import axios from 'axios';
import { AccountState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ProfessionalYearResponseError, ProfessionalYearState } from '../../views/components/modules/professional-years/state';

export const createProfessionalYearData = async (
  formData: ProfessionalYearState,
  authToken?: string,
  accountInfo?: AccountState,
  professionalYearId?: string | number,
): Promise<ProfessionalYearState | ProfessionalYearResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    module_id,
    new_entrant_id,
    supervisor_id,
    other_supervisor_id,
    plan_supervisor_template_id,
    logbook_workactivity_template_id,
    q1_supervisor_template_id,
    q1_entrant_template_id,
    q2_supervisor_template_id,
    q2_entrant_template_id,
    q3_supervisor_template_id,
    q3_entrant_template_id,
    q4_supervisor_template_id,
    q4_entrant_template_id,
    completion_supervisor_template_id,
    completion_licensee_template_id,
    exam_supervisor_template_id,
    exam_entrant_template_id,
    log_training_template_id,
    plan_complete,
    q1_complete,
    q2_complete,
    q3_complete,
    q4_complete,
    exam_passed,
    exam_passed_confirmation,
    is_open,
  } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('new_entrant_id', new_entrant_id?.toString() || '');
    formData.append('supervisor_id', supervisor_id?.toString() || '');
    formData.append('other_supervisor_id', other_supervisor_id?.toString() || '');
    formData.append('plan_supervisor_template_id', plan_supervisor_template_id?.toString() || '');
    formData.append('logbook_workactivity_template_id', logbook_workactivity_template_id?.toString() || '');
    formData.append('q1_supervisor_template_id', q1_supervisor_template_id?.toString() || '');
    formData.append('q1_entrant_template_id', q1_entrant_template_id?.toString() || '');
    formData.append('q2_supervisor_template_id', q2_supervisor_template_id?.toString() || '');
    formData.append('q2_entrant_template_id', q2_entrant_template_id?.toString() || '');
    formData.append('q3_supervisor_template_id', q3_supervisor_template_id?.toString() || '');
    formData.append('q3_entrant_template_id', q3_entrant_template_id?.toString() || '');
    formData.append('q4_supervisor_template_id', q4_supervisor_template_id?.toString() || '');
    formData.append('q4_entrant_template_id', q4_entrant_template_id?.toString() || '');
    formData.append('completion_supervisor_template_id', completion_supervisor_template_id?.toString() || '');
    formData.append('completion_licensee_template_id', completion_licensee_template_id?.toString() || '');
    formData.append('exam_supervisor_template_id', exam_supervisor_template_id?.toString() || '');
    formData.append('exam_entrant_template_id', exam_entrant_template_id?.toString() || '');
    formData.append('log_training_template_id', log_training_template_id?.toString() || '');
    formData.append('plan_complete', plan_complete?.toString() || '0');
    formData.append('q1_complete', q1_complete?.toString() || '0');
    formData.append('q2_complete', q2_complete?.toString() || '0');
    formData.append('q3_complete', q3_complete?.toString() || '0');
    formData.append('q4_complete', q4_complete?.toString() || '0');
    formData.append('exam_passed', exam_passed?.toString() || '0');
    formData.append('exam_passed_confirmation', exam_passed_confirmation?.toString() || '0');
    formData.append('is_open', is_open?.toString() || '1');
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('source_id', module_id?.toString() || '');
    
    if (professionalYearId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/professional-years/${dataEncrypt(professionalYearId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/professional-years`;
    }

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
