import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import LeftNav from '../LeftNav';
import {
  checkUserPermission,
  encryptData,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CREATE, DELETE, READ, SETTINGS, UPDATE } from '../../../../constants/constants';
import PlansFilters from './FilterBtn';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { PaginationResponse, PlanState } from '../../user/state';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import trashGrey from '../../../../assets/images/trash-grey.svg';
import PaginationSettings from '../Pagination';
import Popup from '../../common/popup/Popup';
import { useNotificationContext } from '../../layout/NotificationContext';
import { t } from 'i18next';
import { fetchPlans } from '../../../../api/plans/fetchPlans';
import { deletePlan } from '../../../../api/plans/deletePlan';

const Plans = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const { setNotificationSuccess } = useNotificationContext();
  const title = 'Plans';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';

  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [plans, setPlans] = useState<PlanState[]>([]);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [planId, setPlanId] = useState<number>(0);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!checkUserPermission(user, SETTINGS, 10, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof PlanState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'plan_name',
      numeric: true,
      disablePadding: true,
      label: 'Plan Name'
    },
    {
      id: 'max_users',
      numeric: true,
      disablePadding: true,
      label: 'Max Users'
    },
    {
      id: 'price_id',
      numeric: true,
      disablePadding: true,
      label: 'Stripe Price Id'
    },
    {
      id: 'product_id',
      numeric: true,
      disablePadding: true,
      label: 'Stripe Product Id'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Status'
    }
  ];

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchPlans(
          authToken,
          0,
          currentPage,
          search,
          selectedStatus,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setPlans(response.data);
          }
          setTotalPages(response.last_page);
        }
      }
    } catch (error: any) {
      console.log('Error : ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(orderBy, order);
  }, [currentPage, search, selectedStatus]);

  const handleSort = (property: keyof PlanState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  const redirectEditPage = (rowid: number) => {
    navigate(`/settings/plans/update/${encryptData(String(rowid), true)}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (planId && authToken) {
        const response: any = (await deletePlan(planId, authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.planDeleted'));
          fetchData(orderBy, order);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setError(t('permission.doesNotHaveAccessPermission'));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 10, CREATE) && (
        <div className="inner-btn-wrap">
          <Button className="btn primary-btn" component={RouterLink} to={`/settings/plans/add`}>
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Add Plan
          </Button>
        </div>
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              button={addButton}
              errorMsg={error}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <PlansFilters
                    search={search}
                    setSearch={setSearch}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                  />

                  <div className="table-outer">
                    <TableContainer>
                      {loading ? (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      ) : plans.length === 0 ? (
                        <div className="no-record">No plans found.</div>
                      ) : (
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          className="table hover-table">
                          <TableHead>
                            <TableRow>
                              {headCells.map((headCell) => (
                                <TableCell
                                  key={`key-${headCell.id}`}
                                  align={headCell.numeric ? 'left' : 'left'}
                                  padding={headCell.disablePadding ? 'none' : 'normal'}>
                                  {headCell.id == 'id' ? (
                                    headCell.label // Render the label without sorting for the "id" column
                                  ) : (
                                    <TableSortLabel
                                      active={orderBy === headCell.id}
                                      direction={orderBy === headCell.id ? order : 'asc'}
                                      onClick={() => handleSort(headCell.id)}>
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={{ display: 'none' }}>
                                          {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                    </TableSortLabel>
                                  )}
                                </TableCell>
                              ))}
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {plans.map((data: PlanState) => (
                              <TableRow key={data.id}>
                                <TableCell scope="row">{data.plan_name}</TableCell>
                                <TableCell scope="row">{data.max_users}</TableCell>
                                <TableCell scope="row">{data.price_id}</TableCell>
                                <TableCell scope="row">{data.product_id}</TableCell>
                                <TableCell
                                  align="left"
                                  className={data.is_open == 1 ? 'open' : 'closed'}>
                                  <span>{data.is_open == 1 ? 'Open' : 'Archived'}</span>
                                </TableCell>
                                <TableCell align="left" className="right-arrow-width">
                                  <div className="table-btn">
                                    {checkUserPermission(user, SETTINGS, 10, DELETE) && (
                                      <Button
                                        className="edit"
                                        onClick={() => {
                                          setPlanId(data?.id || 0);
                                          setOpenConfirmationPopup(true);
                                        }}>
                                        <img src={trashGrey} alt="trashGrey" />
                                      </Button>
                                    )}
                                    {checkUserPermission(user, SETTINGS, 10, UPDATE) && (
                                      <Button
                                        className="edit"
                                        onClick={() => redirectEditPage(data?.id || 0)}>
                                        <img src={rightarrow} alt="Right Arrow" />
                                      </Button>
                                    )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {!loading && plans.length > 0 && (
                        <PaginationSettings
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default Plans;
