import React from 'react';
import { Avatar, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format, isAfter } from 'date-fns';
import { TasksState } from '../../user/state';

export interface questionTasksTableProps {
  data: TasksState[];
  openRows: { [key: number]: boolean };
  handleRowToggle: (rowId: number, type: string) => void;
}

const QuestionTasksTable = ({ data, openRows, handleRowToggle }: questionTasksTableProps) => {
  return (
    <Table sx={{ minWidth: 750 }} aria-label="simple table" className="table">
      <TableHead>
        <TableRow key="1_field-second-row">
          <TableCell key="1_field">Due Date</TableCell>
          <TableCell key="2_field" align="left">
            Task
          </TableCell>
          <TableCell key="3_field" align="left">
            Owner
          </TableCell>
          <TableCell key="4_field" align="left">
            Status
          </TableCell>
          <TableCell key="5_field" align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: TasksState) => (
          <React.Fragment key={`task-${row.id}`}>
            <TableRow
              key={`task-${row.id}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}>
              {row.date_due && !row.is_complete ? (
                <TableCell
                  align="left"
                  className={
                    isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                      ? 'overdue-status'
                      : 'other-status'
                  }>
                  {row.date_due ? format(new Date(row.date_due), 'd/MMM/yyyy') : 'N/A'}
                </TableCell>
              ) : row.is_complete ? (
                <TableCell align="left">-</TableCell>
              ) : (
                <TableCell align="left">N/A</TableCell>
              )}
              <TableCell align="left">{row.task}</TableCell>
              <TableCell align="left">
                <div className="user-img-wrap">
                  {row.user && row.user.profile_image ? (
                    <Avatar
                      src={`${JSON.parse(row.user.profile_image).path}`}
                      alt={row.user?.first_name ? row.user?.first_name : 'user'}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none'; // Hide the image
                        const nextElement = target.nextElementSibling as HTMLElement | null;
                        if (nextElement) {
                          nextElement.style.display = 'block'; // Show the <p> element with the user's name
                        }
                      }}
                    />
                  ) : (
                    <Avatar alt={row.user ? row.user.first_name : ''} src="." />
                  )}
                  {row.user ? (
                    <p className="user-name">{row.user.first_name + ' ' + row.user.last_name}</p>
                  ) : (
                    ''
                  )}
                </div>
              </TableCell>
              {row.date_due ? (
                <TableCell
                  align="left"
                  className={
                    isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                      ? 'overdue'
                      : row.is_complete > 0
                        ? 'completed'
                        : 'opened'
                  }>
                  <span>
                    {isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                      ? 'Overdue'
                      : row.is_complete > 0
                        ? 'Completed'
                        : 'Open'}
                  </span>
                </TableCell>
              ) : (
                <TableCell align="left">Open</TableCell>
              )}
              <TableCell align="left">
                <div className="collapse-btn-wrapper">
                  {row.details && row.details.trim() !== '' ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleRowToggle(row.id, 'task')}>
                      {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  ) : (
                    <IconButton></IconButton>
                  )}
                </div>
              </TableCell>
            </TableRow>

            <TableRow className="collapse-open" key={`collapse-${row.id}`}>
              <TableCell colSpan={5}>
                <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                  <div className="mb-2 mt-2">
                    Details: {(row.details || '').replace(/<br\/>/g, '\n')}
                  </div>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default QuestionTasksTable;
