import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';

interface ForgotPasswordFormState {
  password: string;
  email?: string;
}

export const newUserPassword = async (
  formData: ForgotPasswordFormState
): Promise<string | undefined> => {
  const { password, email } = formData;
  if (email) {
    try {
      const response = await axios.post<ApiResponseType>(
        `${process.env.REACT_APP_API_URL}/new/password`,
        {
          email,
          password,
          password_confirmation: password
        }
      );

      if (response.data.success) {
        return;
      } else {
        throw new ApiResponse(response.data.data);
      }
    } catch (error: any) {
      const errorMessage =
        error instanceof ApiResponse ? error.message : error?.response?.data?.message;

      return errorMessage;
    }
  } else {
    return 'Oops! Please try again with the new password email.';
  }
};
