import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AdviserProileTempplateDataState } from '../../views/components/modules/adviser-profiles/state';

export const createAdviserProfileAuthorisationsData = async (
  form_data: any,
  adviserProfileId: string | number,
  authToken: string,
): Promise<AdviserProileTempplateDataState | string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object
    
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('adviser_profile_id', adviserProfileId.toString());

    const routeUrl = `${process.env.REACT_APP_API_URL}/adviser-profile-authorisations-template`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
