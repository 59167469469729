import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import {
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { PaginationResponse, UpdateState } from '../../user/state';
import { fetchUpdatesPagination } from '../../../../api/updates/fetchUpdates';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import Regulatory from '../../../../assets/updates-images/regulatory-updates.png';
import System from '../../../../assets/updates-images/system-updates.png';
import Internal from '../../../../assets/updates-images/internal-updates.png';
import { READ, RESOURCES } from '../../../../constants/constants';
import UpdatesFilters from './FilterBtn';

const Notifications = () => {
  const title = 'Updates';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Resources';
  const secondpath = '/dashboard';
  const pageTitle = title;
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<UpdateState[]>([]);
  const [search, setSearch] = useState('');
  const [selectedType, setSelectedType] = useState('All');
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // for checking permission
    if (!checkUserPermission(user, RESOURCES, 1, READ)) {
      navigate('/dashboard');
    }
    setIsAccessable(true);
  }, []);

  const fetchBackground = (type: number) => {
    if (type == 1) {
      return '#1da2ef';
    } else if (type == 2) {
      return '#13c4a3';
    } else if (type == 3) {
      return '#835fa6';
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (authToken) {
        const pageToFetch = page;
        if ((search !== '' || selectedType !== 'All') && !isInitialLoad) {
          setIsInitialLoad(true); // Trigger initial load when filters or search change
        }
        const response: PaginationResponse = await fetchUpdatesPagination(
          authToken,
          selectedAccount,
          0,
          'resources',
          pageToFetch,
          search,
          '1',
          20,
          false,
          selectedType
        );
        if (response && response.data) {
          if (isInitialLoad) {
            setUpdateData(response.data); // Set new search results or initial results
          } else {
            setUpdateData((prevData) => [...prevData, ...response.data]); // Append to existing results for pagination
          }
          setCurrentPage(response.current_page);
          setLastPage(response.last_page);
        }
      }
    } catch (error: any) {
      // Handle error
    } finally {
      setLoading(false); // Ensure loading state is always updated
    }
  };

  useEffect(() => {
    fetchData();
    setIsInitialLoad(false); // Update flag after the initial load
  }, [page, search, selectedType]);

  // Ensure correct pagination logic
  const handleScroll = () => {
    if (
      currentPage < lastPage &&
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
        document.documentElement.offsetHeight - 100
    ) {
      setPage((prevPage) => prevPage + 1); // Increment page when scrolling to the bottom
    }
  };

  const debouncedScrollHandler = debounce(handleScroll, 200);
  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollHandler);

    return () => {
      window.removeEventListener('scroll', debouncedScrollHandler);
    };
  }, [currentPage, lastPage]); // Update dependency array

  const handleFilterChange = () => {
    // Reset page to 1 when filters are applied
    setPage(1);
    setIsInitialLoad(true); // Trigger initial load when filters change
  };

  const handleSearchChange = (newValue: string) => {
    // Clear updateData when new search query is entered
    setUpdateData([]);
    setSearch(newValue);
    handleFilterChange(); // Reset page and trigger initial load
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={secondpath}
              />
              <UpdatesFilters
                search={search}
                setSearch={handleSearchChange}
                selectedType={selectedType}
                setSelectedType={(value) => {
                  setSelectedType(value);
                  handleFilterChange();
                }}
                setPage={setPage}
                setCurrentPage={setCurrentPage}
                setLastPage={setLastPage}
                handleSearchChange={handleSearchChange} // Add this line
              />

              {!loading && updateData.length == 0 ? (
                <div className="no-record">No updates found.</div>
              ) : updateData.length > 0 ? (
                updateData.map((update) => (
                  <div
                    key={update.id}
                    className="notification"
                    style={{ border: `2px solid ${fetchBackground(update.type)}` }}>
                    <div className="type-wrap" style={{ background: fetchBackground(update.type) }}>
                      <div className="type">
                        {update.type == 1
                          ? 'Regulatory Update'
                          : update.type == 2
                            ? 'System Update'
                            : update.type == 3
                              ? 'Internal Update'
                              : ''}
                      </div>
                      <div className="date">
                        <span>
                          {update.date ? format(new Date(update.date), 'd/MMM/yyyy') : ''}
                        </span>
                      </div>
                    </div>
                    <div className="title">
                      <h2>{update.title}</h2>
                    </div>
                    <div className="row-wrap mb-2 notification-grid">
                      <div className="img-updates">
                        {update.type == 1 ? (
                          <img className="notification-image" src={Regulatory} alt={update.title} />
                        ) : update.type == 2 ? (
                          <img className="notification-image" src={System} alt={update.title} />
                        ) : update.type == 3 ? (
                          <img className="notification-image" src={Internal} alt={update.title} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <p className="summary">{update.summary}</p>
                        <div className="link link-one">
                          <Link
                            to={`/resources/update/${encryptData(String(update.id), true)}`}
                            style={{ color: fetchBackground(update.type) }}>
                            Read more
                            <i className="left">
                              <svg width="10" height="10" viewBox="0 0 12 12" fill="none">
                                <path
                                  d="M1 11L11 1M11 1H4.33333M11 1V7.66667"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : null}

              {loading && (
                <div className="no-record">
                  <CircularProgress />
                </div>
              )}
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default Notifications;
