import React from 'react';
import CommonBreadcrumbs from '../layout/Breadcrumb/CommonBreadcrumbs';
import RecordLeftMenu from './RecordLeftMenu';
import { Button, MenuItem } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import filter from '../../../assets/images/filter.svg';
import Dialog from '@mui/material/Dialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import BarChart from './BarChart';
import PieChart from './PieChart';
import { useRegistersContext } from './RegisterContext';
import CommonLayout from '../layout/CommonLayout';
import { useCurrentUserInfo } from '../../../utils/common/user';

const RecordChart: React.FC = () => {
  const user = useCurrentUserInfo();
  const { id } = useParams();
  const mainTitle = 'Registers';
  const mainPath = `/register/${id}`;
  let title = 'Complaint Register';

  const { registersRecords } = useRegistersContext();
  let secondTitle;
  if (id) {
    const registerId = parseInt(id, 10);
    const foundRegister =
      Array.isArray(registersRecords) && registersRecords.length > 0
        ? registersRecords.find((register) => register.id === registerId)
        : '';
    title = foundRegister ? foundRegister?.name : '';
  }
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  // Modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // select
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  // Chart
  const data = [40, 60, 75, 100, 30];
  const labels = ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'];

  const piedata = {
    labels: ['Label 1', 'Label 2'],
    data: [70, 30]
  };

  const addButton = (
    <div className="inner-btn-wrap">
      <Button className="btn border-btn" aria-label="Filter" onClick={handleClickOpen}>
        <i className="left">
          <img src={filter} alt="Filter" />
        </i>
        Filters
      </Button>
      <Button className="btn blue-fill-btn" component={RouterLink} to={`/register-chart/${id}`}>
        <i className="left">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M16.6666 10.416V5.66602C16.6666 4.26588 16.6666 3.56582 16.3941 3.03104C16.1544 2.56063 15.772 2.17818 15.3016 1.9385C14.7668 1.66602 14.0667 1.66602 12.6666 1.66602H7.33325C5.93312 1.66602 5.23306 1.66602 4.69828 1.9385C4.22787 2.17818 3.84542 2.56063 3.60574 3.03104C3.33325 3.56582 3.33325 4.26588 3.33325 5.66602V14.3327C3.33325 15.7328 3.33325 16.4329 3.60574 16.9677C3.84542 17.4381 4.22787 17.8205 4.69828 18.0602C5.23306 18.3327 5.93308 18.3327 7.33313 18.3327H10.4166M11.6666 9.16602H6.66659M8.33325 12.4993H6.66659M13.3333 5.83268H6.66659M12.4999 15.8327L14.9999 18.3327M14.9999 18.3327L17.4999 15.8327M14.9999 18.3327V13.3327"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        Export File
      </Button>

      <Button
        className="btn primary-btn"
        component={RouterLink}
        to={`/register-record-add?source=${id}`}>
        <i className="left">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        Add Complaint
      </Button>
      <IconButton aria-label="hamburger" className="toggle-button">
        <MenuRoundedIcon />
      </IconButton>
    </div>
  );

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={title}
            secondTitle={secondTitle}
            button={addButton}
          />
          <div className="record-outer-row settings-outer-row">
            <RecordLeftMenu user={user} sourceId={id ? id : ''} />
            <div className="right-outer">
              <div className="chart-outer">
                <div className="chart-title-wrapper">
                  <div className="chart-left-title">
                    <h4>Complaints</h4>
                    <p>By month for the last 12 months</p>
                  </div>
                  <div className="chart-right-title">
                    <h4>Complaints</h4>
                    <p>Open and closed for the last 12 months</p>
                  </div>
                </div>
                <div className="chart-wrapper">
                  <div className="chart-left">
                    <BarChart data={data} labels={labels} />
                  </div>
                  <div className="chart-right">
                    <PieChart piedata={piedata} />
                  </div>
                </div>
                <div className="chart-title-wrapper">
                  <div className="chart-full-title">
                    <h4>Complaints</h4>
                    <p>Representative for the last 12 months</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="modal-outer">
          <div className="modal-wrapper">
            <div className="modal-title">
              <h2>Filters</h2>
            </div>
            <div className="modal-body">
              <div className="card-outline">
                <div className="row-wrap">
                  <div className="form-group">
                    <label className="form-label">Received From</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        // value={date_received ? dayjs(date_received) : null}
                        // onChange={(newValue) =>
                        //   setDate_received(newValue ? newValue.format('YYYY-MM-DD') : null)
                        // }
                        // slotProps={{
                        //   textField: {
                        //     error: !!errors.date_received,
                        //     placeholder: 'Select Date',
                        //     helperText: errors.date_received
                        //   }
                        // }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Received To</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        // value={date_received ? dayjs(date_received) : null}
                        // onChange={(newValue) =>
                        //   setDate_received(newValue ? newValue.format('YYYY-MM-DD') : null)
                        // }
                        // slotProps={{
                        //   textField: {
                        //     error: !!errors.date_received,
                        //     placeholder: 'Select Date',
                        //     helperText: errors.date_received
                        //   }
                        // }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Closed From</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        // value={date_received ? dayjs(date_received) : null}
                        // onChange={(newValue) =>
                        //   setDate_received(newValue ? newValue.format('YYYY-MM-DD') : null)
                        // }
                        // slotProps={{
                        //   textField: {
                        //     error: !!errors.date_received,
                        //     placeholder: 'Select Date',
                        //     helperText: errors.date_received
                        //   }
                        // }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Closed To</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MMM/YYYY"
                        className="form-control date-picker"
                        // value={date_received ? dayjs(date_received) : null}
                        // onChange={(newValue) =>
                        //   setDate_received(newValue ? newValue.format('YYYY-MM-DD') : null)
                        // }
                        // slotProps={{
                        //   textField: {
                        //     error: !!errors.date_received,
                        //     placeholder: 'Select Date',
                        //     helperText: errors.date_received
                        //   }
                        // }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Representative</label>
                    <Select
                      onChange={handleChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value={10}> Option 1</MenuItem>
                      <MenuItem value={20}> Option 2</MenuItem>
                    </Select>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Owner</label>
                    <Select
                      onChange={handleChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value={10}> Option 1</MenuItem>
                      <MenuItem value={20}> Option 2</MenuItem>
                    </Select>
                  </div>
                  <div className="form-group w-full">
                    <label className="form-label">Status</label>
                    <Select
                      onChange={handleChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value={10}> Option 1</MenuItem>
                      <MenuItem value={20}> Option 2</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="btn-wrap">
                <Button type="submit" className="btn primary-btn btn-sm">
                  <i className="left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_6545_879)">
                        <path
                          d="M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 7.33333L9 11.5M9 11.5L4.83333 7.33333M9 11.5V1.5"
                          stroke="CurrentColor"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6545_879">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  Download
                </Button>
              </div>
            </div>
          </div>
          <Button onClick={handleClose} autoFocus className="modal-close">
            <CloseIcon />
          </Button>
        </Dialog>
      </section>
    </CommonLayout>
  );
};

export default RecordChart;
