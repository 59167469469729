import React, { useState, useEffect } from 'react';
import TasksIndex from '../../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { CalenderEventState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import CalenderLeftMenu from './CalenderLeftMenu';
import AddButton from '../../tasks/AddButton';
import { getCalendarEventId } from '../../../../api/calendar/getCalendarEvents';

const CalendarTaskIndex = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let moduleId = queryParams.get('moduleId');
  const encryptedModuleId = moduleId; // Decrypt the ID
  moduleId = decryptData(moduleId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/compliance-calendar/${encryptedSourceId}`;
  const title = 'Calendar Event Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id_local = localStorage.getItem('calendar_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/calendar/${encryptedModuleId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchCalendarData = async () => {
    try {
      if (moduleId && authToken) {
        const editId = parseInt(moduleId!, 10);

        const response: CalenderEventState = (await getCalendarEventId(
          source_Id,
          authToken,
          selectedAccount,
          editId
        )) as CalenderEventState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);

          // Check if unique_id is defined before setting it in localStorage
          if (unique_id) {
            localStorage.removeItem('calendar_unique_id');
            localStorage.setItem('calendar_unique_id', unique_id);
          }
        }
      }
    } catch (error) {
      /* empty */
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/calendar/add-task?source=${encryptedSourceId}&moduleId=${encryptedModuleId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <CalenderLeftMenu
                  user={user}
                  moduleId={moduleId ? moduleId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  calendarIdEncrypted={encryptedModuleId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <TasksIndex
                  moduleType="calendar_events"
                  sourceId={sourceId}
                  recordId={moduleId}
                  localKey="calendar_unique_id"
                  fetchModuleData={fetchCalendarData}
                  editUrl={`/calendar/edit-task/rowId?source=${encryptedSourceId}&moduleId=${encryptedModuleId}`}
                  currentStatus={currentStatus}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default CalendarTaskIndex;
