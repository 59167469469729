import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import {
  getSelectedAccountInfo,
  useCurrentUserInfo,
  Logout,
  useAuthToken,
  useCurrentOnboardingUserInfo,
  checkUserPermission,
  checkUserIsNewEntrant,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import default_user from '../../../../assets/images/default_user.png';
import { updateLogoutInfo } from '../../../../api/dashboard/updateLogoutInfo';
import { useImage } from '../ImageContext';
import { useRegistersContext } from '../../registers/RegisterContext';
import { fetchAccounts } from '../../../../api/dashboard/fetchAccounts';
import { AccountState } from '../../user/state';
import { localStorageKeys } from '../../../../utils/local-storage';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { t } from 'i18next';
import { fetchAccountDetail } from './../../../../api/onboarding/fetchAccountDetail';
import {
  MODULES,
  REGISTERS,
  TASKS,
  RESOURCES,
  SETTINGS,
  READ,
  CREATE,
  S3PublicUrl,
  REPORTS
} from '../../../../constants/constants';
import { encryptData } from '../../../../utils/common/user';
import LazyImage from '../../common/LazyLoad';
import lockLightGrey from '../../../../assets/images/lock-light-grey.svg';
// import AppBarElement from './AppBarElement';
import AppBarElement4BelowHeader from './AppBarElement4BelowHeader';

const Header: React.FC = () => {
  const { selectedImage, selectedName } = useImage();
  const [anchorEl] = useState<null | HTMLElement>(null);
  const [profileMenu, setProfileMenu] = useState<null | HTMLElement>(null);
  const [registerMenu, setRegisterMenu] = useState<null | HTMLElement>(null);
  const [taskMenu, setTaskMenu] = useState<null | HTMLElement>(null);
  const [reportMenu, setReportsMenu] = useState<null | HTMLElement>(null);
  // const [reportingMenu, setReportingMenu] = useState<null | HTMLElement>(null);
  const [resourcesMenu, setResourcesMenu] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const profileOpen = Boolean(profileMenu);
  const registerMenuOpen = Boolean(registerMenu);
  const taskMenuOpen = Boolean(taskMenu);
  const reportsMenuOpen = Boolean(reportMenu);
  // const reportingMenuOpen = Boolean(reportingMenu);
  const resourcesMenuOpen = Boolean(resourcesMenu);
  const [selectedAccount, setSelectedAccount] = useState(getSelectedAccountInfo());
  const [subscriptonExpired, setSubscriptonExpired] = useState<boolean>(
    isSubscriptionExpired(selectedAccount)
  );
  const authToken = useAuthToken();
  const user = useCurrentUserInfo();
  const onboardingUser = useCurrentOnboardingUserInfo();
  const { registersRecords } = useRegistersContext();
  const { moduleRecords } = useModuleContext();
  const [ModulesMenu, setModulesMenu] = useState<null | HTMLElement>(null);
  const modulesMenuOpen = Boolean(ModulesMenu);
  const [accountData, setAccountData] = useState<AccountState[]>([]);
  const [sessionTimeout, setSessionTimeout] = useState<NodeJS.Timeout | null>(null);
  const sessionTimeoutDuration = 8 * 60 * 60 * 1000;
  const [hasLoggedOut] = useState(false);
  const location = useLocation();
  const type = localStorage.getItem('type');
  const [selectedAccountName, setSelectedAccountName] = useState<string>('');
  const [imageLoaded, setImageLoaded] = useState(true);
  const [isAppBarVisible, setIsAppBarVisible] = React.useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    setSelectedAccount(getSelectedAccountInfo());
  }, [navigate]);

  useEffect(() => {
    setSubscriptonExpired(isSubscriptionExpired(selectedAccount));
  }, [selectedAccount]);

  useEffect(() => {
    if (!hasLoggedOut) {
      const currentURL = window.location.pathname + window.location.search;
      if (currentURL !== '/session-login' && selectedAccount) {
        localStorage.setItem('lastPageURL', currentURL);
      }
      const timer = setTimeout(() => {
        navigate('/session-login');
        window.location.reload();
      }, sessionTimeoutDuration);
      setSessionTimeout(timer);
    } else {
      if (sessionTimeout) {
        clearTimeout(sessionTimeout);
      }
    }
  }, [hasLoggedOut, navigate, sessionTimeoutDuration]);

  const fetchAccountDetails = async () => {
    const accountId = onboardingUser ? onboardingUser.account_id : 0;
    const account_id = accountId || 0;
    if (accountId) {
      try {
        const response: AccountState = await fetchAccountDetail(
          account_id,
          authToken,
          onboardingUser ? true : false
        );
        if (response) {
          setSelectedAccountName(response.name);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    }
  };

  useEffect(() => {
    fetchAccountsListing();

    if (type === t('onboarding.user')) {
      fetchAccountDetails();
    }

    // Listen for the custom 'logout' event
    const logoutListener = () => {
      handleLogout();
    };
    // Add event listener
    window.addEventListener('logout', logoutListener);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('logout', logoutListener);
    };
  }, []);

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenu(event.currentTarget);
  };

  const handleRegisterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRegisterMenu(event.currentTarget);
  };

  const handleTaskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTaskMenu(event.currentTarget);
  };

  const handleReportsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReportsMenu(event.currentTarget);
  };

  const handleResourcesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResourcesMenu(event.currentTarget);
  };

  const handleRegisterClose = () => {
    setRegisterMenu(null);
  };

  const handleTaskClose = () => {
    setTaskMenu(null);
  };

  const handleReportsClose = () => {
    setReportsMenu(null);
  };

  const handleResourcesClose = () => {
    setResourcesMenu(null);
  };

  const handleProfileClose = () => {
    setProfileMenu(null);
  };

  const handleProfile = () => {
    navigate('/user-profile');
    setProfileMenu(null);
  };

  const handleAccountSelection = () => {
    localStorage.removeItem(localStorageKeys.SELECTED_ACCOUNT);
    navigate('/account-selection');
  };

  const handleSetting = () => {
    navigate('/settings/user');
  };

  const handleModuleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setModulesMenu(event.currentTarget);
  };
  const handleModuleClose = () => {
    setModulesMenu(null);
  };
  const handleLogout = async (type: string = '') => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await updateLogoutInfo(authToken);
    } catch (error) {
      console.error('Error updating:', error);
    }
    Logout();
    if (!type) {
      navigate('/login');
    } else {
      navigate('/onboarding-login');
    }
  };

  const [isMenuActive, setIsMenuActive] = useState(false);
  const handleHamburgerClick = () => {
    setIsMenuActive((current) => !current);
  };
  const handleHamburgerClose = () => {
    setIsMenuActive(false);
  };

  const fetchAccountsListing = async () => {
    if (user) {
      const userId = user.id;
      try {
        const response: AccountState[] = await fetchAccounts(userId, authToken);
        if (response) {
          setAccountData(response);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    }
  };

  const shouldExcludePath = (path: string) => {
    const excludePatterns = [
      /^\/settings\/register-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/adviser-review-summary-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/edit-file-review-template\/[A-Za-z0-9-_]+/,
      /^\/settings\/provider-initial-engagement-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/provider-contract-review-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/onboarding-application-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/onboarding-assessment-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/onboarding-checklist-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/edit-prevet-file-review-template\/[A-Za-z0-9-_]+/,
      /^\/settings\/prevet-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/add-prevet-file-review-template(?:\/[A-Za-z0-9-_]+)?(?:\?.*)?$/,
      /^\/settings\/add-file-review-template(?:\/[A-Za-z0-9-_]+)?(?:\?.*)?$/,
      /^\/settings\/adviser-profile-personal-details-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/adviser-profile-authorisations-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/attestation-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/professionalyear-plan-supervisor-template-edit\/[A-Za-z0-9-_]+/,
      /^\/settings\/professionalyear-logbook-workactivity-template-edit\/[A-Za-z0-9-_]+/,
      /^\/print-report\/[A-Za-z0-9-_]+/
    ];

    return excludePatterns.some((pattern) => pattern.test(path));
  };

  let filteredModuleRecords = moduleRecords;
  if (Array.isArray(filteredModuleRecords) && filteredModuleRecords.length > 0) {
    // check for registers permission
    filteredModuleRecords = filteredModuleRecords.filter(
      (module) =>
        Array.isArray(module.account_module_access) && module.account_module_access.length > 0
    );
  }

  let filteredRegisterRecords = registersRecords;
  if (Array.isArray(filteredRegisterRecords) && filteredRegisterRecords.length > 0) {
    // check for registers permission
    filteredRegisterRecords = filteredRegisterRecords.filter(
      (register) =>
        Array.isArray(register.account_register_access) &&
        register.account_register_access.length > 0
    );
  }

  return (
    <>
      {!shouldExcludePath(location.pathname) && (
        // <div
        //   className={`header ${
        //     isAppBarVisible && location.pathname == '/dashboard' ? 'with-appbar' : ''
        //   }`}>
        <div className="header">
          {/* <AppBarElement onVisibilityChange={setIsAppBarVisible} /> */}
          <div className="container-full">
            <div className="inner-header">
              <div className="logo-wrap">
                {selectedAccountName ? (
                  selectedAccountName
                ) : (
                  <Link to="/dashboard">
                    {selectedImage ? (
                      <img src={URL.createObjectURL(selectedImage)} alt="Selected Image" />
                    ) : selectedAccount && selectedAccount.image ? (
                      <div className="image-container">
                        <img
                          src={`${JSON.parse(selectedAccount.image).path}`}
                          alt={selectedAccount?.name}
                          style={{ display: imageLoaded ? 'block' : 'none' }}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.style.display = 'none';
                            const container = target.closest(
                              '.image-container'
                            ) as HTMLElement | null;
                            if (container) {
                              const nameElement = container.querySelector(
                                '.name'
                              ) as HTMLElement | null;
                              if (nameElement) {
                                nameElement.style.display = 'block';
                              }
                            }
                            handleImageError();
                          }}
                          onLoad={() => handleImageLoad()}
                        />
                        {(!selectedAccount.image || !imageLoaded) && selectedAccount?.name}
                      </div>
                    ) : selectedName ? (
                      selectedName
                    ) : (
                      selectedAccount?.name
                    )}
                  </Link>
                )}
              </div>
              <div className={`right-block ${type === t('onboarding.user') && 'onboarding-user'}`}>
                {type !== t('onboarding.user') && (
                  <>
                    <div
                      className={isMenuActive ? 'show overlay' : 'overlay'}
                      onClick={handleHamburgerClose}></div>
                    <div className={isMenuActive ? 'open-menu menu-wrap' : 'menu-wrap'}>
                      <ul className="menu-list">
                        <li className="menu-item">
                          <Button
                            className="btn transparent-btn"
                            onClick={() => {
                              !checkUserIsNewEntrant(user)
                                ? navigate(`/dashboard`)
                                : navigate(`/new-entrant`);
                            }}>
                            Home
                          </Button>
                        </li>

                        {!checkUserIsNewEntrant(user) && Array.isArray(filteredModuleRecords) && (
                          <li className="menu-item">
                            <Button
                              id="register-button"
                              aria-controls={open ? 'module-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleModuleClick}
                              className="btn transparent-btn">
                              Modules
                              <i className="right">
                                <KeyboardArrowDownRoundedIcon />
                              </i>
                            </Button>
                            <Menu
                              anchorEl={ModulesMenu}
                              open={modulesMenuOpen}
                              onClose={handleModuleClose}
                              className="custom-dropdown position-mobile"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}>
                              {filteredModuleRecords.length > 0 ? (
                                filteredModuleRecords.map((module) => (
                                  <MenuItem
                                    key={module.id}
                                    className={`${
                                      !checkUserPermission(user, MODULES, module.id, READ) ||
                                      (module.account_module_access &&
                                        (module.account_module_access.length === 0 ||
                                          parseInt(
                                            module.account_module_access[0]?.locked?.toString() ??
                                              '0',
                                            10
                                          ) === 1)) ||
                                      subscriptonExpired
                                        ? 'in-active'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      if (
                                        checkUserPermission(user, MODULES, module.id, READ) &&
                                        module.account_module_access &&
                                        module.account_module_access.length > 0 &&
                                        parseInt(
                                          module.account_module_access[0]?.locked?.toString() ??
                                            '0',
                                          10
                                        ) !== 1 &&
                                        !subscriptonExpired
                                      ) {
                                        setModulesMenu(null);
                                        navigate(
                                          `/${module.route}/${encryptData(String(module.id), true)}`
                                        );
                                      }
                                    }}>
                                    {(!checkUserPermission(user, MODULES, module.id, READ) ||
                                      (module.account_module_access &&
                                        (module.account_module_access.length === 0 ||
                                          parseInt(
                                            module.account_module_access[0]?.locked?.toString() ??
                                              '0',
                                            10
                                          ) === 1)) ||
                                      subscriptonExpired) && (
                                      <i className="left">
                                        <img src={lockLightGrey} alt="" />
                                      </i>
                                    )}
                                    {module.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <p className="no-register-available">
                                    There are no modules available
                                  </p>
                                </MenuItem>
                              )}
                            </Menu>
                          </li>
                        )}

                        {!checkUserIsNewEntrant(user) && (
                          <li className="menu-item">
                            <Button
                              id="register-button"
                              aria-controls={open ? 'module-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleRegisterClick}
                              className="btn transparent-btn">
                              Registers
                              <i className="right">
                                <KeyboardArrowDownRoundedIcon />
                              </i>
                            </Button>
                            <Menu
                              anchorEl={registerMenu}
                              open={registerMenuOpen}
                              onClose={handleRegisterClose}
                              className="custom-dropdown position-mobile"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}>
                              {Array.isArray(filteredRegisterRecords) &&
                              filteredRegisterRecords.length > 0 ? (
                                filteredRegisterRecords.map((register) => (
                                  <MenuItem
                                    key={register.id}
                                    className={`${
                                      !checkUserPermission(user, REGISTERS, register.id, READ) ||
                                      (register.account_register_access &&
                                        (register.account_register_access.length === 0 ||
                                          parseInt(
                                            register.account_register_access[0]?.locked?.toString() ??
                                              '0',
                                            10
                                          ) === 1)) ||
                                      subscriptonExpired
                                        ? 'in-active'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      if (
                                        checkUserPermission(user, REGISTERS, register.id, READ) &&
                                        register.account_register_access &&
                                        register.account_register_access.length > 0 &&
                                        parseInt(
                                          register.account_register_access[0]?.locked?.toString() ??
                                            '0',
                                          10
                                        ) !== 1 &&
                                        !subscriptonExpired
                                      ) {
                                        setRegisterMenu(null);
                                        register.prefix === 'PRV'
                                          ? navigate(
                                              `/providers/${encryptData(String(register.id), true)}`
                                            )
                                          : navigate(
                                              `/register/${encryptData(String(register.id), true)}`
                                            );
                                      }
                                    }}>
                                    {(!checkUserPermission(user, REGISTERS, register.id, READ) ||
                                      (register.account_register_access &&
                                        (register.account_register_access.length === 0 ||
                                          parseInt(
                                            register.account_register_access[0]?.locked?.toString() ??
                                              '0',
                                            10
                                          ) === 1)) ||
                                      subscriptonExpired) && (
                                      <i className="left">
                                        <img src={lockLightGrey} alt="" />
                                      </i>
                                    )}
                                    {register.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <p className="no-register-available">
                                    There are no registers available
                                  </p>
                                </MenuItem>
                              )}
                            </Menu>
                          </li>
                        )}

                        {!checkUserIsNewEntrant(user) && (
                          <li className="menu-item">
                            <Button
                              id="tasks-button"
                              aria-controls={open ? 'module-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleTaskClick}
                              className="btn transparent-btn">
                              Tasks
                              <i className="right">
                                <KeyboardArrowDownRoundedIcon />
                              </i>
                            </Button>
                            <Menu
                              anchorEl={taskMenu}
                              open={taskMenuOpen}
                              onClose={handleTaskClose}
                              className="custom-dropdown position-mobile"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}>
                              {checkUserPermission(user, TASKS, 1, READ) && (
                                <MenuItem
                                  onClick={() => {
                                    setTaskMenu(null);
                                    navigate(`/tasks`);
                                  }}>
                                  All Tasks
                                </MenuItem>
                              )}
                              {checkUserPermission(user, TASKS, 2, READ) && (
                                <MenuItem
                                  onClick={() => {
                                    setTaskMenu(null);
                                    navigate(`/my-tasks`);
                                  }}>
                                  My Tasks
                                </MenuItem>
                              )}

                              {!checkUserPermission(user, TASKS, 1, READ) &&
                                !checkUserPermission(user, TASKS, 2, READ) && (
                                  <MenuItem>
                                    <p className="no-register-available">
                                      There are no tasks available
                                    </p>
                                  </MenuItem>
                                )}
                            </Menu>
                          </li>
                        )}

                        {!checkUserIsNewEntrant(user) && (
                          <li className="menu-item">
                            <Button
                              id="reports-button"
                              aria-controls={open ? 'module-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleReportsClick}
                              className="btn transparent-btn">
                              Reports
                              <i className="right">
                                <KeyboardArrowDownRoundedIcon />
                              </i>
                            </Button>
                            <Menu
                              anchorEl={reportMenu}
                              open={reportsMenuOpen}
                              onClose={handleReportsClose}
                              className="custom-dropdown position-mobile"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}>
                              {checkUserPermission(user, REPORTS, 1, READ) && (
                                <MenuItem
                                  key="adviser-reviews"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/adviser-reviews`);
                                  }}>
                                  Adviser Reviews
                                </MenuItem>
                              )}
                              {checkUserPermission(user, REPORTS, 2, READ) && (
                                <MenuItem
                                  key="prevets"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/prevets`);
                                  }}>
                                  Prevets
                                </MenuItem>
                              )}
                              {checkUserPermission(user, REPORTS, 3, READ) && (
                                <MenuItem
                                  key="incidents-breaches"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/incidents-breaches`);
                                  }}>
                                  Incidents & Breaches
                                </MenuItem>
                              )}
                              {checkUserPermission(user, REPORTS, 4, READ) && (
                                <MenuItem
                                  key="complaints"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/complaints`);
                                  }}>
                                  Complaints
                                </MenuItem>
                              )}
                              {checkUserPermission(user, REPORTS, 5, READ) && (
                                <MenuItem
                                  key="export-data"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/export-data`);
                                  }}>
                                  Export Data
                                </MenuItem>
                              )}
                              {checkUserPermission(user, REPORTS, 6, READ) && (
                                <MenuItem
                                  key="client-file-review"
                                  onClick={() => {
                                    setReportsMenu(null);
                                    navigate(`/reports/client-file-review`);
                                  }}>
                                  Client File Review
                                </MenuItem>
                              )}
                              {!checkUserPermission(user, REPORTS, 1, READ) && (
                                <MenuItem>
                                  <p className="no-register-available">
                                    There are no reports available
                                  </p>
                                </MenuItem>
                              )}
                            </Menu>
                          </li>
                        )}

                        {!checkUserIsNewEntrant(user) && (
                          <li className="menu-item">
                            <Button
                              id="resources-button"
                              aria-controls={open ? 'module-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleResourcesClick}
                              className="btn transparent-btn">
                              Resources
                              <i className="right">
                                <KeyboardArrowDownRoundedIcon />
                              </i>
                            </Button>
                            <Menu
                              anchorEl={resourcesMenu}
                              open={resourcesMenuOpen}
                              onClose={handleResourcesClose}
                              className="custom-dropdown position-mobile"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}>
                              {checkUserPermission(user, RESOURCES, 1, READ) && (
                                <MenuItem
                                  onClick={() => {
                                    setResourcesMenu(null);
                                    navigate(`/resources/updates`);
                                  }}>
                                  Updates
                                </MenuItem>
                              )}
                              {checkUserPermission(user, RESOURCES, 2, READ) && (
                                <MenuItem
                                  onClick={() => {
                                    setResourcesMenu(null);
                                    navigate(`/resources/policies`);
                                  }}>
                                  Policies
                                </MenuItem>
                              )}
                              {checkUserPermission(user, RESOURCES, 4, CREATE) && (
                                <MenuItem
                                  onClick={() => {
                                    setResourcesMenu(null);
                                    navigate(`/resources/compliance-query`);
                                  }}>
                                  Compliance Query
                                </MenuItem>
                              )}
                              {checkUserPermission(user, RESOURCES, 3, CREATE) && (
                                <MenuItem
                                  onClick={() => {
                                    setResourcesMenu(null);
                                    navigate(`/resources/support-request`);
                                  }}>
                                  Technical support request
                                </MenuItem>
                              )}

                              {!checkUserPermission(user, RESOURCES, 1, READ) &&
                                !checkUserPermission(user, RESOURCES, 2, READ) &&
                                !checkUserPermission(user, RESOURCES, 4, CREATE) &&
                                !checkUserPermission(user, RESOURCES, 3, CREATE) && (
                                  <MenuItem>
                                    <p className="no-register-available">
                                      There are no resources available
                                    </p>
                                  </MenuItem>
                                )}
                            </Menu>
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                )}
                <div className="profile-inner-wrapper">
                  <div className="setting-profile-wrap">
                    {type !== t('onboarding.user') ? (
                      <ul className="setting-profile-list">
                        {checkUserPermission(user, SETTINGS) && (
                          <li className="setting-profile-item">
                            <IconButton
                              className="icon-btn"
                              aria-label="setting"
                              onClick={handleSetting}>
                              <SettingsOutlinedIcon />
                            </IconButton>
                          </li>
                        )}
                        <li className="setting-profile-item">
                          <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleProfileClick}
                            className="icon-btn"
                            disableRipple
                            aria-label="setting">
                            {user?.profile_image ? (
                              <Avatar
                                src={`${JSON.parse(user.profile_image).path}`}
                                alt={user ? user.first_name : 'user'}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.style.display = 'none'; // Hide the image
                                  const nextElement =
                                    target.nextElementSibling as HTMLElement | null;
                                  if (nextElement) {
                                    nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                  }
                                }}
                              />
                            ) : (
                              <Avatar alt="User" src={default_user} />
                            )}
                          </IconButton>

                          <Menu
                            anchorEl={profileMenu}
                            open={profileOpen}
                            onClose={handleProfileClose}
                            className="custom-dropdown"
                            MenuListProps={{
                              'aria-labelledby': 'basic-button'
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}>
                            <MenuItem className="user-detail-item" disableRipple>
                              <div className="user-detail-wrap">
                                <div className="image-block">
                                  {user?.profile_image ? (
                                    <Avatar
                                      src={`${JSON.parse(user.profile_image).path}`}
                                      alt={user.first_name ? user.first_name : 'user'}
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.style.display = 'none';
                                        const nextElement =
                                          target.nextElementSibling as HTMLElement | null;
                                        if (nextElement) {
                                          nextElement.style.display = 'block';
                                        }
                                      }}
                                    />
                                  ) : (
                                    <img src={default_user} alt=""></img>
                                  )}

                                  <span className="status"></span>
                                </div>
                                <div className="content-block">
                                  <p className="name">
                                    {user?.first_name && user?.last_name
                                      ? `${user.first_name} ${user.last_name}`
                                      : user?.first_name || user?.last_name}
                                  </p>
                                  <p>{user?.email}</p>
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem onClick={handleProfile}>
                              <i className="left">
                                <PersonOutlineOutlinedIcon />
                              </i>
                              View profile
                            </MenuItem>
                            {accountData && accountData.length > 1 && (
                              <MenuItem onClick={handleAccountSelection}>
                                <i className="left">
                                  <RotateLeftIcon />
                                </i>
                                Switch Account
                              </MenuItem>
                            )}
                            <MenuItem onClick={() => handleLogout()}>
                              <i className="left">
                                <LogoutIcon />
                              </i>
                              Log out
                            </MenuItem>
                          </Menu>
                        </li>
                      </ul>
                    ) : (
                      <Link onClick={() => handleLogout(type)} className="logout" to="">
                        Logout
                      </Link>
                    )}
                  </div>
                  <div className="flag-wrap">
                    <i>
                      <LazyImage src={`${S3PublicUrl}3lines-logo-small.png`} alt="logo" />
                    </i>
                  </div>
                  {type !== t('onboarding.user') && (
                    <div className="hamburget-ic">
                      <IconButton
                        aria-label="hamburger"
                        className={isMenuActive ? 'open hamburger-btn' : 'hamburger-btn'}
                        onClick={handleHamburgerClick}>
                        {isMenuActive ? <CloseIcon /> : <MenuRoundedIcon />}
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      )}
      {selectedAccount && selectedAccount.plan_level === '0' && isAppBarVisible && (
        <AppBarElement4BelowHeader onVisibilityChange={setIsAppBarVisible} />
      )}
    </>
  );
};

export default Header;
