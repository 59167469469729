import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { FinalizationReviewState } from '../../views/components/user/state';
import { AccountState } from '../../views/components/user/state';

export const skipFinalisation = async (
  authToken?: string,
  accountInfo?: AccountState,
  prevetId?: string | number,
  sourceId?: string | number,
  emailSwitch?: boolean,
  taskArray?: FinalizationReviewState[]
): Promise<string | number | undefined> => {

  try {
    const selectedAccountId = accountInfo?.id;
    const formData = new FormData(); // Create a FormData object
    formData.append('finalised', '1');
    formData.append('_method', 'PUT');
    formData.append('emailSwitch', emailSwitch ? '1' : '0');
    formData.append('source_id', sourceId ? sourceId.toString() : '0');
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    if (taskArray) {
      formData.append('taskArray', JSON.stringify(taskArray));
    }
    const routeUrl = `${process.env.REACT_APP_API_URL}/prevet-skip-and-finalisation/${dataEncrypt(prevetId?.toString() ?? '0')}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
