import { AccountState, PaginationResponse, ReviewTemplatesState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { t } from 'i18next';

export const fetchProviderTemplate = async (
  authToken?: string,
  type?: string,
  accountInfo?: AccountState,
  source_id?: number,
  providerId?: number,
  reviewId?: number,
): Promise<PaginationResponse | ReviewTemplatesState | string | undefined> => {

  const selectedAccountId = accountInfo?.id;

  let routeUrl
  routeUrl = `${process.env.REACT_APP_API_URL}/provider-templates?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&type=${type}&register_id=${dataEncrypt(source_id?.toString() ?? '0')}`;
  if (type == t('templateType.initialEngagement') && providerId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-provider-template?provider_id=${dataEncrypt(providerId?.toString())}&type=${type}`
  } else if (type == t('templateType.contractReview') && providerId && reviewId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/fetch-provider-template?provider_id=${dataEncrypt(providerId?.toString())}&review_id=${dataEncrypt(reviewId?.toString())}&type=${type}`
  }

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};

