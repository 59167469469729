import React from 'react';
import {
  Avatar,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import attestment from '../../../../assets/images/attestment.svg';
import pdficon from '../../../../assets/images/file-pdf.png';
import othericon from '../../../../assets/images/file-other.png';
import { NotesState } from '../../user/state';

export interface questionNotesTableProps {
  data: NotesState[];
  openRows: { [key: number]: boolean };
  handleRowToggle: (rowId: number, type?: string) => void;
}

const QuestionNotesTable = ({ data, openRows, handleRowToggle }: questionNotesTableProps) => {
  return (
    <Table sx={{ minWidth: 750 }} aria-label="simple table" className="table">
      <TableHead>
        <TableRow key="1_field-row">
          <TableCell key="1_field">By</TableCell>
          <TableCell key="2_field" align="left">
            Note
          </TableCell>
          <TableCell key="3_field" align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: NotesState) => (
          <React.Fragment key={`note-${row.id}`}>
            <TableRow
              key={`note-${row.id}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}>
              <TableCell align="left">
                <div className="user-img-wrap">
                  {row.user && row.user.profile_image ? (
                    <Avatar
                      src={`${JSON.parse(row.user.profile_image).path}`}
                      alt={row.user ? row.user.first_name : 'profile_image'}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.style.display = 'none'; // Hide the image
                        const nextElement = target.nextElementSibling as HTMLElement;
                        if (nextElement) {
                          nextElement.style.display = 'block'; // Show the <p> element with the user's name
                        }
                      }}
                    />
                  ) : (
                    <Avatar alt={row.user ? row.user.first_name : ''} src="." />
                  )}
                  {row.user ? (
                    <p className="user-name">{row.user.first_name + ' ' + row.user.last_name}</p>
                  ) : (
                    ''
                  )}
                </div>
              </TableCell>
              <TableCell align="left">{row.subject}</TableCell>
              <TableCell>
                <div className="collapse-btn-wrapper">
                  {row.attachments && row.attachments.length > 0 && (
                    <Button className="table-inner-btn">
                      <img src={attestment} alt="attestment" />
                    </Button>
                  )}
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleRowToggle(row.id)}>
                    {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>

            <TableRow className="collapse-open" key={`collapse-${row.id}`}>
              <TableCell colSpan={4}>
                <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                  <div className="collapse-description">{row.note}</div>
                  {row.attachments && row.attachments.length > 0 && (
                    <ul className="file-wrapper">
                      {row.attachments.map((attachment: any) => (
                        <li key={attachment.id}>
                          <a
                            href={`${JSON.parse(attachment.path).path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pdf-flex">
                            <img
                              src={attachment.file_type == 'pdf' ? pdficon : othericon}
                              alt="pdficon"
                            />
                            {attachment.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default QuestionNotesTable;
