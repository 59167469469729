import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MeetingFormsProps } from './state';
import { format } from 'date-fns';
import { Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { MeetingAgendasState } from '../../user/state';
import { encryptData } from '../../../../utils/common/user';

const MeetingCreateEditFormData: React.FC<MeetingFormsProps> = ({
  date,
  agendas,
  formData,
  users,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceId,
  errors,
  closed_at,
  isDisabled,
  current_route,
  meetingAddMode,
  edit_btn_snapshot,
  currentUserInfo,
  handleComplete,
  handleInputChange,
  handleSelectChange,
  handleMeetingDateTimeInputChange,
  handleMeetingStart,
  meetingIdEncrypted,
  sourceIdEncrypted
}) => {
  const meetingDate =
    formData.meeting_date_time &&
    formData.meeting_date_time !== 'Invalid date format' &&
    formData.meeting_date_time !== 'Invalid Date' &&
    formData.meeting_date_time.split(' ')[0];
  const agendaUrl =
    date < meetingDate
      ? `/meeting/review-agenda?source=${sourceIdEncrypted}&meetingId=${meetingIdEncrypted}&agendaId=${encryptData(String(formData.agenda_id), true)}`
      : `/meeting/view-agenda?source=${sourceIdEncrypted}&meetingId=${meetingIdEncrypted}&agendaId=${encryptData(String(formData.agenda_id), true)}`;

  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Snapshot</h3>
            <div className="inner-btn-wrap">
              {isDisabled && current_route && current_route === `/meeting/${meetingIdEncrypted}`
                ? edit_btn_snapshot
                : ''}
            </div>
          </div>
          {isDisabled && current_route && current_route === `/meeting/${meetingIdEncrypted}` && (
            <div className="row-wrap">
              <div className="form-group">
                <label className="form-label">Created</label>
                <p className="form-control">
                  {formData?.created_at
                    ? format(new Date(formData?.created_at || ''), 'd/MMM/yyyy')
                    : ''}
                </p>
              </div>
              <div className="form-group">
                <label className="form-label">Host</label>
                <p className="form-control">
                  {formData.host?.first_name} {formData.host?.last_name}
                </p>
              </div>
              <div className="form-group">
                <label className="form-label">Agenda</label>
                <p className="form-control">{formData.agenda?.name}</p>
              </div>
              <div className="form-group">
                <label className="form-label">Meeting date/time</label>
                <p className="form-control">
                  {formData.meeting_date_time &&
                  formData.meeting_date_time !== 'Invalid date format' &&
                  formData.meeting_date_time !== 'Invalid Date' ? (
                    <>
                      {format(new Date(formData.meeting_date_time.split(' ')[0]), 'd/MMM/yyyy')}{' '}
                      {formData.meeting_date_time.split(' ')[1]}{' '}
                      {formData.meeting_date_time.split(' ')[2]}
                    </>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <div className="form-group w-full">
                <label className="form-label">Location</label>
                <p className="form-control">{formData.meeting_location || '-'}</p>
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Webinar link</label>
                <p className="form-control">
                  {formData.webinar_link ? (
                    <a href={formData.webinar_link} target="_blank" rel="noopener noreferrer">
                      {formData.webinar_link}
                    </a>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Additional information</label>
                <p className="form-control">{formData.description || '-'}</p>
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Attendees</label>
                <p className="form-control">{formData.attendees}</p>
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Scribe</label>
                <p className="form-control">
                  {formData.scribe?.first_name} {formData.scribe?.last_name}
                </p>
              </div>
              <div className={`form-group`}>
                <label className="form-label">Status</label>
                <p className="form-control">{formData.is_open == 1 ? 'Open' : 'Archived'}</p>
              </div>

              {(currentUserInfo?.id == formData.host_id ||
                currentUserInfo?.id == formData.scribe_id) &&
                formData.is_open == 1 && (
                  <div className="form-group">
                    <div className="inner-btn-wrap inner-btn-wrap-complete">
                      <Button className="btn white-btn" onClick={() => handleComplete(0)}>
                        <i className="left">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_10218_12413)">
                              <path
                                d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                                fill="#667085"
                              />
                              <path
                                d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                                fill="#667085"
                              />
                              <path
                                d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                                fill="#667085"
                              />
                              <path
                                d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                                fill="#667085"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_10218_12413">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </i>
                        Archive
                      </Button>
                    </div>
                  </div>
                )}

              {formData.is_open == 0 && closed_at && (
                <div className="form-group">
                  <label className="form-label">Date Closed</label>
                  {closed_at ? format(new Date(closed_at), 'd/MMM/yyyy') : ''}
                </div>
              )}
            </div>
          )}

          {!isDisabled &&
            current_route &&
            (current_route === `/meeting-edit/${meetingIdEncrypted}` ||
              current_route === `/meeting-add`) &&
            !meetingAddMode && (
              <div className="row-wrap ">
                <div className="form-group">
                  <label className="form-label">Host</label>
                  <FormControl variant="outlined" error={!!errors.host_id}>
                    <Select
                      error={!!errors.host_id}
                      labelId="host-label"
                      id="host"
                      name="host_id"
                      value={formData.host_id.toString()}
                      onChange={handleSelectChange}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      renderValue={(selected) => {
                        if (selected === '0') {
                          return <p className="dropdown-placeholder">Select the host</p>; // Render "Select the host" as placeholder
                        }
                        const selectedOption =
                          users && users.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {users &&
                        users?.length > 0 &&
                        users.map((option) => (
                          <MenuItem key={option?.id} value={option?.id || ''}>
                            {option?.name || ''}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.host_id && (
                    <Typography variant="caption" color="error">
                      {errors.host_id}
                    </Typography>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Scribe</label>
                  <FormControl variant="outlined" error={!!errors.scribe_id}>
                    <Select
                      error={!!errors.scribe_id}
                      labelId="scribe-label"
                      id="scribe"
                      name="scribe_id"
                      value={formData.scribe_id.toString()}
                      onChange={handleSelectChange}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      renderValue={(selected) => {
                        if (selected === '0') {
                          return <p className="dropdown-placeholder">Select the scribe</p>; // Render "Select the host" as placeholder
                        }
                        const selectedOption =
                          users && users.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {users &&
                        users?.length > 0 &&
                        users.map((option) => (
                          <MenuItem key={option?.id} value={option?.id || ''}>
                            {option?.name || ''}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.scribe_id && (
                    <Typography variant="caption" color="error">
                      {errors.scribe_id}
                    </Typography>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Agenda</label>
                  <FormControl variant="outlined" error={!!errors.agenda_id}>
                    <Select
                      error={!!errors.agenda_id}
                      labelId="agenda-label"
                      id="agenda"
                      name="agenda_id"
                      value={formData.agenda_id.toString()}
                      onChange={handleSelectChange}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      renderValue={(selected) => {
                        if (selected === '0') {
                          return <p className="dropdown-placeholder">Select the agenda</p>; // Render "Select the host" as placeholder
                        }
                        const selectedOption =
                          agendas && agendas.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {agendas &&
                        agendas.map((agenda: MeetingAgendasState) => (
                          <MenuItem key={agenda.id} value={agenda.id}>
                            {agenda.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.agenda_id && (
                    <Typography variant="caption" color="error">
                      {errors.agenda_id}
                    </Typography>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Meeting Date/Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      format="DD/MMM/YYYY hh:mm A"
                      className="form-control date-picker"
                      value={
                        formData.meeting_date_time &&
                        formData.meeting_date_time !== 'Invalid date format' &&
                        formData.meeting_date_time !== 'Invalid Date'
                          ? dayjs(formData.meeting_date_time)
                          : null
                      }
                      onChange={handleMeetingDateTimeInputChange}
                      slotProps={{
                        textField: {
                          error: !!errors.meeting_date_time,
                          placeholder: 'Select the date and time',
                          helperText: errors.meeting_date_time
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="form-group w-full textarea">
                  <label className="form-label">Meeting Location</label>
                  <TextField
                    error={!!errors.meeting_location}
                    id="meeting-location"
                    variant="outlined"
                    name="meeting_location"
                    value={formData.meeting_location}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter meeting location"
                    type="text"
                    helperText={errors.meeting_location}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group w-full textarea">
                  <label className="form-label">Webinar Link</label>
                  <TextField
                    error={!!errors.webinar_link}
                    id="webinar-link"
                    variant="outlined"
                    name="webinar_link"
                    value={formData.webinar_link}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter webinar link"
                    type="text"
                    helperText={errors.webinar_link}
                    inputProps={{
                      maxLength: 250 // Set the maximum character length
                    }}
                  />
                </div>
                <div className="form-group w-full textarea">
                  <label className="form-label">Additional Information</label>
                  <TextField
                    id="description"
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter additional information"
                    type="text"
                    multiline
                    rows={5}
                  />
                </div>
                <div className="form-group w-full">
                  <label className="form-label">Attendees</label>
                  <FormControl variant="outlined" error={!!errors.attendee_ids}>
                    <Select
                      error={!!errors.attendee_ids}
                      labelId="Complaint-owner-label"
                      id="attendee-ids"
                      name="attendee_ids"
                      displayEmpty
                      onChange={handleSelectChange}
                      value={formData.attendee_ids}
                      multiple
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      renderValue={(selected) => {
                        if (!selected.length) {
                          return <p className="dropdown-placeholder">Select the attendees</p>; // Render placeholder if no attendees selected
                        }
                        const selectedOptions = selected.map(
                          (selectedId) =>
                            users && users.find((option) => option.id === parseInt(selectedId, 10))
                        );
                        return (
                          selectedOptions &&
                          selectedOptions.map((selectedOption, index) => (
                            <span key={selectedOption && selectedOption.id}>
                              {selectedOption && selectedOption.name}
                              {index < selectedOptions.length - 1 ? ', ' : ''}{' '}
                              {/* Add comma separator if not last item */}
                            </span>
                          ))
                        );
                      }}>
                      {users &&
                        users.length > 0 &&
                        users.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.attendee_ids && (
                    <Typography variant="caption" color="error">
                      {errors.attendee_ids}
                    </Typography>
                  )}
                </div>
              </div>
            )}
        </div>

        {isDisabled && current_route && current_route === `/meeting/${meetingIdEncrypted}` && (
          <>
            <div className="card-outline manage-meeting">
              <div className="card-title-wrap secondary-title-wrap">
                <h3>
                  {currentUserInfo?.id != formData.scribe_id
                    ? `View Meeting Agenda`
                    : `Review Meeting Agenda`}
                </h3>
              </div>
              <div className="form-group">
                <p className="form-control">
                  {formData.finalised && currentUserInfo?.id == formData.scribe_id
                    ? `The meeting has been finalised but you can view the agenda.`
                    : date < meetingDate
                      ? `Before the meeting commences, you can review the agenda and add Notes for discussion during the meeting.`
                      : date >= meetingDate && currentUserInfo?.id != formData.scribe_id
                        ? `The meeting has commenced so you can view the agenda.`
                        : date >= meetingDate && currentUserInfo?.id == formData.scribe_id
                          ? `The meeting has commenced so you can view the agenda.`
                          : null}
                </p>
                <div className="inner-btn-wrap">
                  <Button className="btn primary-btn" component={RouterLink} to={agendaUrl}>
                    <i className="left">
                      <svg viewBox="0 0 21 20" fill="none">
                        <path
                          d="M17.1667 10.418V5.66797C17.1667 4.26784 17.1667 3.56777 16.8942 3.03299C16.6545 2.56259 16.2721 2.18014 15.8017 1.94045C15.2669 1.66797 14.5668 1.66797 13.1667 1.66797H7.83337C6.43324 1.66797 5.73318 1.66797 5.1984 1.94045C4.72799 2.18014 4.34554 2.56259 4.10586 3.03299C3.83337 3.56777 3.83337 4.26784 3.83337 5.66797V14.3346C3.83337 15.7348 3.83337 16.4348 4.10586 16.9696C4.34554 17.44 4.72799 17.8225 5.1984 18.0622C5.73318 18.3346 6.43324 18.3346 7.83337 18.3346H10.5M12.1667 9.16797H7.16671M8.83337 12.5013H7.16671M13.8334 5.83464H7.16671M12.5834 15.8346L14.25 17.5013L18 13.7513"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                    {date < meetingDate
                      ? `Review agenda`
                      : date >= meetingDate
                        ? `View agenda`
                        : null}
                  </Button>
                </div>
              </div>
            </div>

            {date >= meetingDate && currentUserInfo?.id == formData.scribe_id && !formData.finalised && (
              <div className="card-outline manage-meeting">
                <div className="card-title-wrap secondary-title-wrap">
                  <h3>Run Meeting</h3>
                </div>
                <div className="form-group">
                  <p className="form-control">When the meeting commences, you can take minutes.</p>
                  <div className="inner-btn-wrap">
                    <Button className="btn primary-btn" onClick={handleMeetingStart}>
                      <i className="left">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path
                            d="M17.9999 17.499H11.3333M2.58325 17.9156L7.20764 16.137C7.50343 16.0232 7.65132 15.9664 7.78968 15.8921C7.91259 15.8261 8.02975 15.75 8.13995 15.6645C8.26402 15.5682 8.37606 15.4561 8.60015 15.2321L17.9999 5.83228C18.9204 4.91181 18.9204 3.41943 17.9999 2.49895C17.0795 1.57848 15.5871 1.57847 14.6666 2.49895L5.26682 11.8987C5.04273 12.1228 4.93069 12.2348 4.83441 12.3589C4.7489 12.4691 4.67275 12.5863 4.60678 12.7092C4.53251 12.8476 4.47563 12.9954 4.36186 13.2912L2.58325 17.9156ZM2.58325 17.9156L4.29835 13.4564C4.42108 13.1373 4.48245 12.9778 4.5877 12.9047C4.67969 12.8408 4.7935 12.8167 4.9035 12.8377C5.02936 12.8617 5.15024 12.9826 5.39199 13.2243L7.27456 15.1069C7.51631 15.3487 7.63719 15.4695 7.66122 15.5954C7.68223 15.7054 7.65807 15.8192 7.59421 15.9112C7.52112 16.0164 7.36158 16.0778 7.04248 16.2005L2.58325 17.9156Z"
                            stroke="CurrentColor"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </i>
                      Take minutes
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default MeetingCreateEditFormData;
