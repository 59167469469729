import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { OnboardingState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import { MODULES, NOTE_CREATE, NOTE_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import OnboardingLeftMenu from './OnboardingLeftMenu';
import AddButton from '../../notes/AddButton';
import { fetchOnboardings } from '../../../../api/onboarding/fetchOnboardings';
import Notes from '../../notes/Notes';

const OnboardingNotes = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let onboardingId = queryParams.get('onboardingId');
  const encryptedOnboardingId = onboardingId; // Decrypt the ID
  onboardingId = decryptData(onboardingId, true);

  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/onboarding/${encryptedSourceId}`;
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  let title;
  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
    title = foundRegister ? foundRegister?.singular + ' Notes' : 'Onboarding Notes';
  }
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let secondTitle;
  const unique_id_local = localStorage.getItem('onboarding_unique_id');
  if (unique_id_local) {
    secondTitle = unique_id_local;
  }
  const secondpath = `/onboarding/details/${encryptedOnboardingId}?source=${encryptedSourceId}`;


  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, NOTE_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchOnboardingData = async () => {
    try {
      if (onboardingId && authToken) {
        const editId = parseInt(onboardingId!, 10);

        const response: OnboardingState = (await fetchOnboardings(
          0,
          authToken,
          selectedAccount,
          editId
        )) as OnboardingState;

        if (response) {
          const { unique_id, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('onboarding_unique_id');
          localStorage.setItem('onboarding_unique_id', unique_id || '');
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
        <AddButton
          addPath={`/onboarding/add-note?source=${encryptedSourceId}&onboardingId=${encryptedOnboardingId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <OnboardingLeftMenu
                  user={user}
                  onboardingId={onboardingId ? onboardingId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  onboardingIdEncrypted={encryptedOnboardingId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <Notes
                  moduleType="onboardings"
                  sourceId={sourceId}
                  recordId={onboardingId}
                  localKey="onboarding_unique_id"
                  fetchModuleData={fetchOnboardingData}
                  editUrl={`/onboarding/edit-note/rowId?source=${encryptedSourceId}&onboardingId=${encryptedOnboardingId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default OnboardingNotes;
