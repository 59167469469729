import { ComplaintsReportDataState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { tokenCheck } from '../../utils/common/user';

export const fetchComplaints = async (
  authToken?: string,
  accountInfo?: AccountState
): Promise<ComplaintsReportDataState | string | undefined> => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/complaints-data?account_id=${selectedAccountId}`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching data';
  }
};
