import React, { useState, useEffect } from 'react';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { MeetingAgendasState } from '../../user/state';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import CommonLayout from '../../layout/CommonLayout';
import { useNotificationContext } from '../../layout/NotificationContext';
import { QuestionsFormState } from '../state';
import UploadQuestions from './UploadQuestions';
import { uploadMeetingAgendaQuestions } from '../../../../api/settings/uploadMeetingAgendaQuestions';
import { SelectChangeEvent } from '@mui/material';
import { SETTINGS, UPDATE } from '../../../../constants/constants';
import { fetchMeetingsAgendas } from '../../../../api/meeting/fetchMeetingsAgendas';
import ModulesUpdate from '../modules/ModuleUpdate';

const MeetingEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationSuccess } = useNotificationContext();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Meeting';
  const secondTitle = 'Modules';
  const secondpath = '/settings/modules';
  const pageTitle = title;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const authToken = useAuthToken() || '';
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [agendas, setAgendas] = useState<MeetingAgendasState[]>([]);
  const [isQuestionsUploading, setiIsQuestionsUploading] = useState<boolean>(false);
  const [questionFormData, setQuestionFormData] = useState<QuestionsFormState>({
    agenda_id: 0,
    questionFile: null
  });

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);

    getMeetingsAgendas();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { files = {} as FileList } = event.target as { files?: FileList };

    const file = files?.[0];
    if (file) {
      setQuestionFormData((prevData) => ({ ...prevData, questionFile: file }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target;
    setQuestionFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancelClick = () => {
    // Reset form fields
    navigate('/settings/modules');
  };

  // Get meeting Agendas
  const getMeetingsAgendas = async () => {
    try {
      const response: MeetingAgendasState[] = await fetchMeetingsAgendas(authToken);

      if (response) {
        setAgendas(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching users:', error);
    }
  };

  const validateQuestionForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!questionFormData.agenda_id) {
      newErrors.agenda_id = 'Please select agenda for questions.';
    }
    if (questionFormData.questionFile === null) {
      newErrors.questionFile = 'Please select questions file to upload.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleQuestionUpload = async (event: React.FormEvent) => {
    event.preventDefault();

    const isFormValid = validateQuestionForm();
    if (isFormValid) {
      try {
        setiIsQuestionsUploading(true);

        const response: any = (await uploadMeetingAgendaQuestions(
          questionFormData,
          authToken
        )) as any;

        if (response) {
          const newErrors: Record<string, string> = {};
          if (response.agenda_id) {
            newErrors.agenda_id = response.agenda_id[0];
          } else if (response.question_file) {
            newErrors.questionFile = response.question_file[0];
          }
          
          setErrors(newErrors);
        } else {
          setNotificationSuccess(t('success.field.meetingAgendaQuestionsUploaded'));
          setQuestionFormData({
            agenda_id: 0,
            questionFile: null
          });
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setiIsQuestionsUploading(false);
      }
    }
  };

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={pageTitle}
              secondTitle={secondTitle}
              secondpath={secondpath}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap mt-2">
                    <div className="card-outline">
                      <UploadQuestions
                        agendas={agendas}
                        questionFormData={questionFormData}
                        handleFileChange={handleFileChange}
                        handleSelectChange={handleSelectChange}
                        handleQuestionUpload={handleQuestionUpload}
                        handleCancelClick={handleCancelClick}
                        isQuestionsUploading={isQuestionsUploading}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <ModulesUpdate />
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default MeetingEdit;
