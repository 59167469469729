import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountPasswordResetState } from '../../views/components/dashboard/state';

export const accountPasswordReset = async (
  formData: AccountPasswordResetState,
  authToken?: string
): Promise<string | undefined | null> => {
  try {
    const formDataObj = new FormData();
    formDataObj.append('currentPassword', formData.currentPassword);
    formDataObj.append('newPassword', formData.newPassword);
    formDataObj.append('confirmPassword', formData.confirmPassword);
    formDataObj.append('_method', 'PUT');

    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/account-password-reset`,
      formDataObj,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
