import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { DropdownResponse } from '../../views/components/user/state';

export const fetchUsersAssociatedListAPI = async (accountId?: number | string): Promise<DropdownResponse[] | string> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/add-accounts/fetch-user-associated?account_id=${dataEncrypt(accountId?.toString() ?? '0')}`);
    const responseData: ApiResponseType = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching roles';
  }
};
