import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const uploadSurveyFile = async (
  formData: any,
  authToken?: string,
  typeOnboarding?: boolean
): Promise<string | undefined> => {
  let routeUrl;
  try {
    const form_Data = new FormData(); // Create a FormData object
    if (formData && formData.length > 0) {
      for (const file of formData) {
        form_Data.append('attachments[]', file);
      }
    }

    routeUrl = `${process.env.REACT_APP_API_URL}/survey/upload-files`;
    if (typeOnboarding) {
      routeUrl += `?type=onboardingUser`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, form_Data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};