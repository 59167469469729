import React from 'react';
import CommonLayout from '../layout/CommonLayout';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const MoreThanTwentySent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = 'More Than 20 Sent';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper selected-plan-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="content-wrapper">
                <h2>{t('user.enterPrisePlan')}</h2>
                <p>
                  {t('user.moreThanTwentySent')}
                </p>
              </div>
            </div>
            <div className="btn-wrap">
              <Button className="btn primary-btn" onClick={() => navigate('/dashboard')}>
                {t('user.returnToDashboard')}
              </Button>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-success-1.png`}
                  alt={'Login'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default MoreThanTwentySent;