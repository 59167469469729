import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { TechnicalSupportRequestState } from '../../user/state';

export interface SupportRequestFormProps {
  formData: TechnicalSupportRequestState;
  errors: Record<string, string>;
  requestType: any;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange?: (event: SelectChangeEvent<string>) => void;
}

const SupportRequestCreateEditFormData: React.FC<SupportRequestFormProps> = ({
  formData,
  requestType,
  errors,
  handleInputChange,
  handleSelectChange
}) => {
  return (
    <>
      <form action="" className="record-create-form">
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Support Request</h3>
          </div>

          <div className="row-wrap ">
            <div className="form-group">
              <label className="form-label">Request type</label>
              <FormControl variant="outlined" error={!!errors.request_type}>
                <Select
                  error={!!errors.request_type}
                  labelId="host-label"
                  id="request_type"
                  name="request_type"
                  value={formData.request_type}
                  onChange={handleSelectChange}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className="custom-select"
                  MenuProps={{ className: 'custom-dropdown-menu' }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <p className="dropdown-placeholder">Select Request Type</p>; // Render "Select Request Type" as placeholder
                    }
                    const selectedOption =
                      requestType && requestType.find((option: any) => option.name === selected);
                    return selectedOption
                      ? `${selectedOption.name}`
                      : '';
                  }}>
                    {requestType.map((option: any) => (
                      <MenuItem key={option?.id} value={option?.name || ''}>
                        {option?.name || ''}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {errors.request_type && (
                <Typography variant="caption" color="error">
                  {errors.request_type}
                </Typography>
              )}
            </div>
            <div className="form-group w-full textarea">
              <label className="form-label">Enquiry</label>
              <TextField
                error={!!errors.enquiry}
                id="enquiry"
                variant="outlined"
                name="enquiry"
                value={formData.enquiry}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter the full details of your enquiry"
                type="text"
                multiline
                rows={5}
                helperText={errors.enquiry}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SupportRequestCreateEditFormData;
