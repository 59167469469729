import React from 'react';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { InputAdornment } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ForgotPasswordFormState } from './state';
import CommonLayout from '../layout/CommonLayout';

interface ForgotPasswordFormProps {
  formData: ForgotPasswordFormState;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  t: (key: string) => string;
}

const ResetPassword: React.FC<ForgotPasswordFormProps> = ({
  formData,
  handleInputChange,
  handleSubmit,
  t
}) => {
  const title = 'Reset Password';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  return (
    <CommonLayout title={metapageTitle}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label" htmlFor="email">
            {t('user.email')}
          </label>
          <TextField
            error={!!formData.emailError}
            id="email"
            variant="outlined"
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter your Email"
            helperText={formData.emailError}
            name="email"
            InputProps={{
              endAdornment: (
                <InputAdornment className="message-ic" position="end">
                  <InfoOutlinedIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <Button
          variant="contained"
          type="submit"
          className="btn reset-password"
          disabled={formData.loading}>
          {formData.loading ? (
            <CircularProgress size={20} style={{ color: '#ffffff' }} />
          ) : (
            t('user.resetPassword')
          )}
        </Button>
      </form>
    </CommonLayout>
  );
};

export default ResetPassword;
