import axios from 'axios';
import { AccountState, UpdateState, UpdatesResponseError } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';

export const createUpdateData = async (
  formData: UpdateState,
  authToken?: string,
  accountInfo?: AccountState,
  updateId?: string | number,
): Promise<UpdateState | UpdatesResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    date,
    type,
    title,
    summary,
    details,
    additional_info_title,
    additional_info_link,
    status,
  } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('date', date);
    formData.append('type', type.toString());
    formData.append('title', title);
    formData.append('summary', summary);
    formData.append('details', details);
    formData.append('additional_info_title', additional_info_title);
    formData.append('additional_info_link', additional_info_link);
    formData.append('status', status.toString());
    
    if (type == 3) {
      formData.append('account_id', selectedAccountId?.toString() || '');
    }
    
    if (updateId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/updates/${dataEncrypt(updateId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/updates`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};

export const sendNewUpdatesEmail = async (
  updateId: number,
  authToken?: string,
  accountInfo?: AccountState
): Promise<UpdateState | string | undefined | null> => {
  let routeUrl;
  try {
    const selectedAccountId = accountInfo?.id;
    const redirectUrl = `resources/update/${encryptData(String(updateId), true)}`;
    const formData = new FormData(); // Create a FormData object
    formData.append('accountId', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('redirectUrl', redirectUrl);
    formData.append('updateId', String(updateId));

    routeUrl = `${process.env.REACT_APP_API_URL}/send-new-updates-email`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};