import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { DropdownResponse, QuickVetState } from '../../user/state';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useNotificationContext } from '../../layout/NotificationContext';
import { QuickVetDataFormState } from './state';
import { SelectChangeEvent } from '@mui/material/Select';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, DELETE, MODULES, READ, UPDATE } from '../../../../constants/constants';
import { fetchRepresentativeUsers } from '../../../../api/common/fetchRepresentativeUsers';
import DeleteButton from '../../common/DeleteButton';
import Popup from '../../common/popup/Popup';
import { format } from 'date-fns';
import QuickVetCreateEditFormData from './QuickVetCreateEditFormData';
import { fetchQuickVetTemplates } from '../../../../api/quickvets/fetchQuickVetTemplates';
import { createQuickVetData } from '../../../../api/quickvets/createQuickVetData';
import { fetchQuickVets } from '../../../../api/quickvets/fetchQuickVets';
import { deleteQuickVet } from '../../../../api/quickvets/deleteQuickVet';
import QuickVetLeftMenu from './QuickVetLeftMenu';
import AutomatedReview from './AutomatedReview';

const QuickVetCreateEdit: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [closed_at, setClosed_at] = useState<string | null>(null);
  const { setNotificationSuccess } = useNotificationContext();
  const [isDisabled, setIsDisabled] = useState(Boolean); // Initially disabled if id is not available
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [adviser, setAdviser] = useState<string>('');
  const [template, setTemplate] = useState<string>('');
  const [adviserArray, setAdviserArray] = useState<DropdownResponse[]>([]);
  const [templateArray, setTemplateArray] = useState<DropdownResponse[]>([]);
  const mainPath = `/quickvets/${encryptedSourceId}`;
  const [quickVetAddMode, setQuickVetAddMode] = useState(false); // Initially disabled if id is not available
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Specify the type as 'File | null'
  const [fetchDocument, setFetchDocument] = useState<{
    path: string;
    original_name: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const secondTitle = 'QuickVets';
  const pageTitle = id ? `QuickVet Details` : `Add ${secondTitle}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const today = new Date();
  const formattedDate = format(today, 'd/MMM/yyyy');

  const [date_received, setDate_received] = useState<string | null>(formattedDate);

  const [formData, setFormData] = useState<QuickVetDataFormState>({
    id: 0,
    module_id: source_Id,
    subject: '',
    adviser_id: 0,
    template_id: 0,
    unique_id: '',
    is_open: 1,
    created_at: formattedDate,
    created_by: 0,
    error: '',
    adviser: {
      first_name: '',
      last_name: ''
    },
    template: {
      name: ''
    },
    user: {
      first_name: '',
      last_name: ''
    },
    document: ''
  });

  useEffect(() => {
    const permission =
      current_page.pathname === `/quickvet/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (
      subscriptonExpired ||
      (!checkUserPermission(user, MODULES, source_Id, permission) && user)
    ) {
      navigate('/dashboard');
    }
    setIsAccessable(true);
  }, []);

  const HandlesnapshotIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      navigate(`/quickvet-snapshot-edit/${encryptedId}?source=${encryptedSourceId}`);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchAdviserUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchRepresentativeUsers(
        authToken,
        selectedAccount
      );
      if (response) {
        setAdviserArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  const fetchTemplate = async () => {
    try {
      if (authToken) {
        const response: DropdownResponse[] = (await fetchQuickVetTemplates(
          authToken,
          selectedAccount,
          0,
          0,
          undefined,
          undefined,
          '',
          ''
        )) as DropdownResponse[];

        if (response) {
          console.log(response);
          setTemplateArray(response);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (
      current_route == `/quickvet-snapshot-edit/${encryptedId}` ||
      current_route == `/quickvet-form-edit/${encryptedId}` ||
      current_route == `/quickvet-add`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (!hasEffectRun) {
      setHasEffectRun(true);
      fetchTemplate();
      fetchData(); // Call the async function
      fetchAdviserUsers();
    }
  }, [formData, hasEffectRun, current_route]);

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const quickVetId = parseInt(id!, 10);

        const response: QuickVetState = (await fetchQuickVets(
          source_Id,
          authToken,
          selectedAccount,
          quickVetId
        )) as QuickVetState;

        if (response) {
          const {
            id,
            subject,
            unique_id,
            template_id,
            module_id,
            adviser_id,
            created_at,
            closed_at,
            is_open,
            adviser,
            template,
            user,
            document
          } = response;
          if (template_id) {
            setTemplate(template_id.toString());
          }
          if (adviser_id) {
            setAdviser(adviser_id.toString());
          }

          if (created_at) {
            setDate_received(created_at);
          }

          if (closed_at) {
            setClosed_at(closed_at);
          }

          const imageObject = document ? JSON.parse(document) : null; // Add a null check
          const typedImageObject = imageObject
            ? (imageObject as { path: string; original_name: string })
            : null;
          if (typedImageObject && typedImageObject.path) {
            setFetchDocument({
              path: typedImageObject ? typedImageObject.path : '',
              original_name: typedImageObject ? typedImageObject.original_name : ''
            });
          } else {
            setFetchDocument(null);
          }
          const newFormData = {
            id,
            subject,
            adviser_id,
            template_id,
            module_id,
            unique_id,
            is_open,
            created_at,
            closed_at,
            error: '',
            adviser: {
              id: adviser ? adviser.id : 0,
              email: adviser ? adviser.email : '',
              first_name: adviser ? adviser.first_name : '',
              last_name: adviser ? adviser.last_name : ''
            },
            template: {
              id: template ? template.id : 0,
              name: template ? template.name : ''
            },
            user: {
              id: user ? user.id : 0,
              email: user ? user.email : '',
              first_name: user ? user.first_name : '',
              last_name: user ? user.last_name : ''
            }
          };
          setFormData(newFormData);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const handleChangeAdviser = (event: SelectChangeEvent<string>) => {
    setAdviser(event.target.value);
  };

  const handleChangeTemplate = (event: SelectChangeEvent<string>) => {
    setTemplate(event.target.value);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.subject) {
      newErrors.subject = t('error.field.subjectRequired');
    }
    if (!adviser) {
      newErrors.adviser_id = t('error.field.adviser');
    }
    if (!template) {
      newErrors.template_id = t('error.field.template');
    }
    if (!template) {
      newErrors.template_id = t('error.field.template');
    }
    if (!selectedFile) {
      newErrors.document = t('error.field.documentRequired'); // Add an error message for document
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSurveySubmit = async (type = '') => {
    let isFormValid = true;
    if (current_route === `/quickvet-snapshot-edit/${encryptedId}`) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    } else {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    }

    if (isFormValid) {
      setLoading(true);

      const requestData = {
        module_id: formData.module_id,
        subject: formData.subject,
        template_id: template,
        adviser_id: adviser,
        is_open: formData.is_open,
        document: selectedFile
      };
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createQuickVetData(
          requestData,
          authToken,
          selectedAccount,
          edit_id
        );

        if (response && response?.id) {
          setSelectedFile(null);

          const msg = id ? t('success.field.quickvetUpdated') : t('success.field.quickvetSaved');

          if (
            response.id &&
            current_route &&
            current_route === `/quickvet-add` &&
            !quickVetAddMode
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setErrors({});
            setNewResponse(response);
            navigate(
              `/quickvet/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
            );
          } else if (id || quickVetAddMode) {
            if (!type) {
              setHasEffectRun(false);
              setNotificationSuccess(msg);
              setQuickVetAddMode(false);
              navigate(
                `/quickvet/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
              );
            }
          } else {
            navigate(`/quickvets/${encryptedSourceId}`);
            setNotificationSuccess(msg);
          }
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else if (current_route && current_route === `/quickvet-form-edit/${encryptedId}`) {
          if (
            response.template_id ||
            response.adviser_id ||
            response.subject ||
            response.completion_date ||
            response.document
          ) {
            setFormData((prevData) => ({
              ...prevData,
              error: 'Please complete snapshot form first'
            }));
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }
        } else {
          // Error handling for validation errors
          const newErrors: Record<string, string> = {};

          if (response.template_id) {
            newErrors.template_id = response.template_id[0];
          } else if (response.adviser_id) {
            newErrors.adviser_id = response.adviser_id[0];
          } else if (response.subject) {
            newErrors.subject = response.subject[0];
          } else if (response.completion_date) {
            newErrors.completion_date = response.completion_date[0];
          } else if (response.document) {
            setSelectedFile(null);
            newErrors.document = response.document[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  const setNewResponse = (response: QuickVetState) => {
    if (response) {
      const { id, subject, unique_id, template_id, adviser_id, created_at, document } = response;

      if (closed_at) {
        setClosed_at(closed_at);
      }
      const newFormData = {
        id,
        subject,
        adviser_id,
        template_id,
        unique_id,
        created_at,
        document
      };
      setFormData(newFormData);
      setTimeout(() => {
        setQuickVetAddMode(true);
      }, 200);
    }
  };

  const handleCancelClick = () => {
    setSelectedFile(null);
    if (id) {
      navigate(`/quickvet/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/quickvets/${encryptedSourceId}`);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check if the file is a PDF
      if (
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        setSelectedFile(file);
        setErrors((prevErrors) => ({ ...prevErrors, document: '' })); // Clear any file errors
      } else {
        // Set error message if file is not a PDF
        setErrors((prevErrors) => ({
          ...prevErrors,
          document: 'Please upload a valid PDF or DOCX document.' // Add appropriate error message
        }));
        setSelectedFile(null); // Clear selected file
      }
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteQuickVet(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.quickvetDeleted'));
          navigate(`/quickvets/${encryptedSourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  let edit_btn_snapshot = null;
  if (formData.is_open === 1) {
    edit_btn_snapshot = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }
  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id ? formData.unique_id : pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                errorMsg={formData.error}
              />

              <div className="record-outer-row settings-outer-row">
                <QuickVetLeftMenu
                  user={user}
                  quickVetId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formData_record={formData}
                  quickVetIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {current_route &&
                      (current_route === `/quickvet-snapshot-edit/${encryptedId}` ||
                        current_route === `/quickvet/${encryptedId}` ||
                        current_route === `/quickvet-add`) &&
                      !quickVetAddMode && (
                        <QuickVetCreateEditFormData
                          formData={formData}
                          errors={errors}
                          date_received={date_received}
                          adviser={adviser}
                          template={template}
                          adviserArray={adviserArray}
                          templateArray={templateArray}
                          closed_at={closed_at}
                          isDisabled={isDisabled}
                          current_route={current_route}
                          quickVetAddMode={quickVetAddMode}
                          edit_btn_snapshot={edit_btn_snapshot}
                          handleChangeAdviser={handleChangeAdviser}
                          handleChangeTemplate={handleChangeTemplate}
                          handleInputChange={handleInputChange}
                          handleFileChange={handleFileChange}
                          encryptedId={encryptedId ? encryptedId : null}
                          selectedFile={selectedFile}
                          fetchDocument={fetchDocument}
                        />
                      )}
                    {current_route === `/quickvet/${encryptedId}` && (
                      <AutomatedReview
                        sourceId={source_Id}
                        authToken={authToken}
                        selectedAccount={selectedAccount}
                        quickVetId={Number(id)}
                        template={formData.template}
                      />
                    )}

                    {!isDisabled &&
                      !quickVetAddMode &&
                      (current_route == `/quickvet-add` ||
                        current_route == `/quickvet-snapshot-edit/${encryptedId}`) && (
                        <div className="btn-wrap">
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                                Cancel
                              </Button>
                              {
                                <Button
                                  className="btn primary-btn btn-sm"
                                  type="submit"
                                  onClick={() => handleSurveySubmit()}>
                                  {current_route == `/quickvet-snapshot-edit/${encryptedId}`
                                    ? 'Save'
                                    : 'Save and continue'}
                                </Button>
                              }
                            </>
                          )}
                        </div>
                      )}

                    {checkUserPermission(user, MODULES, source_Id, DELETE) &&
                      current_route === `/quickvet/${encryptedId}` && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default QuickVetCreateEdit;
