import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ForgotPasswordContextType {
  forgotPasswordSuccess: boolean;
  setForgotPasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordContext = createContext<ForgotPasswordContextType | null>(null);

interface ForgotPasswordProviderProps {
  children: ReactNode; // Use ReactNode to type the children prop
}

export const ForgotPasswordProvider: React.FC<ForgotPasswordProviderProps> = ({ children }) => {
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);

  return (
    <ForgotPasswordContext.Provider value={{ forgotPasswordSuccess, setForgotPasswordSuccess }}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};

export const useForgotPasswordContext = () => {
  const context = useContext(ForgotPasswordContext);
  if (!context) {
    throw new Error('useForgotPasswordContext must be used within a ForgotPasswordProvider');
  }
  return context;
};
