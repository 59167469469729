import { NotesState, TasksState } from "../views/components/user/state";
// Possible order values
type SortOrder = 'asc' | 'desc';

// State update functions
type SetState<T> = (value: T) => void;

// Type definition for sorting utility parameters
interface HandleSortPropsTasks {
  property: keyof TasksState;
  orderBy: keyof TasksState;
  order: SortOrder;
  setOrderBy: SetState<keyof TasksState>;
  setOrder: SetState<SortOrder>;
  fetchData: (orderBy: keyof TasksState, order: SortOrder) => void;
}

interface HandleSortPropsNotes {
  property: keyof NotesState;
  orderBy: keyof NotesState;
  order: SortOrder;
  setOrderBy: SetState<keyof NotesState>;
  setOrder: SetState<SortOrder>;
  fetchData: (orderBy: keyof NotesState, order: SortOrder) => void;
}


// Sorting utility function with defined types
export const handleSortTasks = ({
  property,
  orderBy,
  order,
  setOrderBy,
  setOrder,
  fetchData,
}: HandleSortPropsTasks) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');

  // Call fetchData with updated sorting parameters
  fetchData(property, isAsc ? 'desc' : 'asc');
};

export const handleSortNotes = ({
  property,
  orderBy,
  order,
  setOrderBy,
  setOrder,
  fetchData,
}: HandleSortPropsNotes) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');

  // Call fetchData with updated sorting parameters
  fetchData(property, isAsc ? 'desc' : 'asc');
};
