import axios from 'axios';
import {
  AccountState,
  TechnicalSupportRequestResponseError,
  TechnicalSupportRequestState
} from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const sendTechnicalSupportRequestMail = async (
  formData: TechnicalSupportRequestState,
  authToken?: string,
  accountInfo?: AccountState
): Promise<TechnicalSupportRequestResponseError | string | number | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const { request_type, enquiry } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('request_type', request_type);
    formData.append('enquiry', enquiry);

    const routeUrl = `${process.env.REACT_APP_API_URL}/technical-support-request?accountId=${selectedAccountId}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return ;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
