import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import editIcon from '../../../../assets/images/edit.svg';
import { updateClientFileName } from '../../../../api/adviser/updateClientFileName';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';

interface ClientFileReviewNameEditProps {
  reviewId?: number;
  filereviewId?: string;
  initialClientName?: string;
}

const ClientFileReviewNameEdit: React.FC<ClientFileReviewNameEditProps> = ({
  reviewId,
  filereviewId,
  initialClientName
}) => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();

  const [isEditing, setIsEditing] = useState(false);
  const [clientName, setClientName] = useState(initialClientName);
  const [clientNameError, setClientNameError] = useState<string | null>(null);

  useEffect(() => {
    setClientName(initialClientName);
  }, [initialClientName]);

  const handleNameSaveClick = async () => {
    if (!clientName || !clientName.trim()) {
      setClientNameError('Client name is required');
      return;
    }
    setClientNameError(null);

    try {
      const review_id = Number(reviewId);
      const file_review_id = Number(filereviewId);
      const response = await updateClientFileName(
        authToken,
        selectedAccount,
        review_id,
        file_review_id,
        clientName
      );
      if (response) {
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating client name:', error);
    }
  };

  const handleNameCancelClick = () => {
    setClientName(initialClientName); // Reset the input value to the original client name
    setIsEditing(false);
  };

  const handleNameEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div className="client-file-review">
      <div className="card-outline-inner">
        {isEditing ? (
          <div className="form-group edit-container">
            <TextField
              error={!!clientNameError}
              id="Subject"
              variant="outlined"
              name="subject"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              className="form-control"
              placeholder="Enter a client name"
              type="text"
              inputProps={{
                maxLength: 120 // Set the maximum character length
              }}
            />
            <Button className="btn primary-btn btn-sm" onClick={handleNameSaveClick}>
              Save
            </Button>
            <Button className="btn border-btn btn-sm" onClick={handleNameCancelClick}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className="client-file-review-template client-name">
            <h3>{clientName}</h3>
            <Button onClick={handleNameEditClick} className="edit btn template-inner-edit-btn">
              <img src={editIcon} alt="Edit" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientFileReviewNameEdit;
