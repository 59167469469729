import axios from 'axios';
import { Dispatch } from 'redux'; // Import Dispatch type
import { localStorageKeys } from '../../utils/local-storage';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck, encryptData } from '../../utils/common/user';
import { PersonalDataFormState } from '../../views/components/dashboard/state';
import { loginSuccess } from '../../store/user/authSlice';

export const updateCurrentUserInfo = async (
  dispatch: Dispatch,
  formData: PersonalDataFormState,
  imageChanged: boolean,
  authToken?: string
): Promise<string | undefined | null> => {
  const { id, first_name, last_name, email, phone, profile_image, timezone } = formData;
  let routeUrl;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('phone', phone ? phone.toString() : '');
    if (timezone) {
      formData.append('timezone', timezone);
    }
    if (profile_image) {
      formData.append('profile_image', profile_image); // Attach the logo as a Blob
    } else if (!profile_image && imageChanged) {
      formData.append('profile_image', '');
    }
    if (id) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/user/current-user`;
      const response = await axios.post<ApiResponseType>(routeUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.success) {
        dispatch(loginSuccess(response.data.data));
        localStorage.setItem(
          localStorageKeys.USER_DATA,
          encryptData(JSON.stringify(response.data.data))
        );

        return;
      } else {
        tokenCheck(response.status);
        throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
      }
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
