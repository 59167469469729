import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { format, parseISO } from 'date-fns';
import { QuickVetState, UserState } from '../../user/state';
import { useAuthToken, getSelectedAccountInfo } from '../../../../utils/common/user';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { isFormatted } from '../../../../utils/commonUtils';
import { fetchQuickVets } from '../../../../api/quickvets/fetchQuickVets';
import { QuickVetDataFormState } from './state';

interface LeftMenuProps {
  user: UserState | undefined;
  quickVetId?: string;
  sourceId?: string;
  formData_record?: QuickVetDataFormState;
  quickVetIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const QuickVetLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  quickVetId,
  sourceId,
  formData_record,
  quickVetIdEncrypted,
  sourceIdEncrypted
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { moduleRecords } = useModuleContext();
  const moduleId = sourceId ? parseInt(sourceId, 10) : 0;
  console.log(user);

  const foundModule =
    Array.isArray(moduleRecords) && moduleRecords.length > 0
      ? moduleRecords.find((module) => module.id === moduleId)
      : '';
  const instructions =
    foundModule && foundModule.instructions != null && foundModule.instructions != ''
      ? foundModule?.instructions
      : t('quickVet.addMenuMsg');

  const [formData, setFormData] = useState<QuickVetDataFormState>({
    unique_id: '',
    adviser_id: 0,
    created_at: '',
    is_open: 0
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const fetchData = async () => {
    try {
      if (quickVetId && authToken) {
        const editId = parseInt(quickVetId!, 10);

        const response: QuickVetState = (await fetchQuickVets(
          0,
          authToken,
          selectedAccount,
          editId
        )) as QuickVetState;

        if (response) {
          const { unique_id, adviser, is_open, quickvet_template_id } = response;

          localStorage.removeItem('quickvet_unique_id');
          localStorage.setItem('quickvet_unique_id', unique_id);

          let created_at;
          if (response.created_at) {
            const date = new Date(response.created_at);
            created_at = date.toISOString().split('T')[0];
          }
          let closed_at;
          if (response.closed_at) {
            const date = new Date(response.closed_at);
            closed_at = date.toISOString().split('T')[0];
          }

          const newFormData = {
            unique_id,
            quickvet_template_id,
            adviser,
            is_open,
            created_at,
            closed_at
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData: any) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  useEffect(() => {
    if (
      currentUrl !==
      `/quickvet/add-file-review?source=${sourceIdEncrypted}&quickVetId=${quickVetIdEncrypted}`
    ) {
      if (!formData_record) {
        fetchData(); // Call the async function
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unusedVariable = formData_record ? setFormData(formData_record) : null;
      }
    }
  }, [sourceId, formData_record]);

  // URLs exclude from the menu

  return (
    <>
      {location.pathname !== `/quickvets/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/quickvet-add' && (
              <span
                className="inner-text-wrap"
                dangerouslySetInnerHTML={{ __html: instructions }}
              />
            )}
            <div className="sidebar-inner">
              {location.pathname !== '/quickvet-add' && (
                <div className="comp-uder-detail">
                  <div className="comp-uder-detail-list">
                    <h6>ID</h6>
                    {formData_record || formData.unique_id ? (
                      <p>{formData.unique_id ? formData.unique_id : ''}</p>
                    ) : null}
                  </div>
                  {(formData_record || formData.created_at) && (
                    <div className="comp-uder-detail-list">
                      <h6>Created</h6>
                      <p>
                        {formData && formData.created_at
                          ? isFormatted(formData.created_at)
                            ? formData.created_at
                            : format(parseISO(formData.created_at), 'd/MMM/yyyy')
                          : 'N/A'}
                      </p>
                    </div>
                  )}

                  <div className="comp-uder-detail-list">
                    <h6>Status</h6>
                    {formData_record || formData?.is_open ? (
                      <p>{formData && formData?.is_open ? 'Open' : 'Archived'}</p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};
export default QuickVetLeftMenu;
