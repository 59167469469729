import React from 'react';
import { TextField } from '@mui/material';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { PlanFormsProps } from '../state';

const PlansCreateEditFormData: React.FC<PlanFormsProps> = ({
  formData,
  selectedFile,
  isDisabled,
  errors,
  handleInputChange,
  handleFileChange
}) => {
  return (
    <form action="" className="record-create-form">
      <div className="card-outline">
        <div className="card-title-wrap secondary-title-wrap">
          <h3>Plan</h3>
        </div>

        {!isDisabled && (
          <div className="row-wrap ">
            <div className="form-group">
              <label className="form-label">Plan Name</label>
              <TextField
                error={!!errors.plan_name}
                id="plan_name"
                variant="outlined"
                name="plan_name"
                value={formData.plan_name}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter plan name"
                type="text"
                helperText={errors.plan_name}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Max Users</label>
              <TextField
                error={!!errors.max_users}
                id="maX_users"
                variant="outlined"
                name="max_users"
                value={formData.max_users}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter max users"
                type="number"
                helperText={errors.max_users}
                inputProps={{
                  min: 0 // Set the minimum number
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Stripe Price Id</label>
              <TextField
                error={!!errors.price_id}
                id="price_id"
                variant="outlined"
                name="price_id"
                value={formData.price_id}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter stripe price id"
                type="text"
                helperText={errors.price_id}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Stripe Product Id</label>
              <TextField
                error={!!errors.product_id}
                id="product_id"
                variant="outlined"
                name="product_id"
                value={formData.product_id}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter stripe product id"
                type="text"
                helperText={errors.product_id}
                inputProps={{
                  maxLength: 120 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full file-upload">
              <label className="form-label large" htmlFor="file">
                Image
              </label>
              <div className="relative">
                <TextField
                  error={!!errors.image}
                  id="image"
                  variant="outlined"
                  name="image"
                  onChange={handleFileChange}
                  className="form-control"
                  InputProps={{
                    startAdornment: (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .webp"
                        className="file-upload-input"
                        onChange={handleFileChange}
                        title="image"
                      />
                    )
                  }}
                  helperText={errors.image}
                />
                {selectedFile ? (
                  <div className="preview-uploaded">
                    <img src={fileUploadIc} alt="File upload" />
                    <p className="file-name">
                      <span className="blue-text">Selected file:</span> {selectedFile.name}
                    </p>
                  </div>
                ) : (
                  <p className="placeholder-text">
                    <img src={fileUploadIc} alt="File upload" />
                    <span>
                      <span className="blue-text">Click to upload</span> or drag and drop
                    </span>
                    <span className="small-text">
                      Policy file has to be Word, Excel or PDF file.
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default PlansCreateEditFormData;
