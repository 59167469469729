import React, { useEffect } from 'react';
import CommonLayout from '../layout/CommonLayout';
import { Button } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { Link } from 'react-router-dom';

const SignupSuccess = () => {
  const title = 'Signup Success';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const navigate = useNavigate();

  useEffect(() => {
    const isRegister = localStorage.getItem('isRegister');

    if (isRegister !== 'yes') {
      navigate('/signup');
    }

    setTimeout(() => {
      localStorage.removeItem('isRegister');
    }, 1000);
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="logo-wrap">
                <Link to="/">
                  <LazyImage
                    src={`${S3PublicUrl}3lines-logo.svg`}
                    alt={'logo'}
                    rootMargin="0px 0px 200px 0px"
                  />
                </Link>
              </div>

              <div className="signup-success-content">
                <h3>Congratulations!</h3>
                <div className="info-text">
                  <p>Your account has been created.</p>
                  <p>
                    Check your email for your login details and start simplifying compliance today.
                  </p>
                  <p>We’ll also send you a Quick Start Guide which explains how to get started.</p>
                </div>

                <div className="inner-btn-wrap">
                  <Button className="btn primary-btn" component={RouterLink} to="/login">
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-success-1.png`}
                  alt={'Signup'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default SignupSuccess;
