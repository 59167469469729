import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  CircularProgress,
  Button,
  Avatar,
  Box
} from '@mui/material';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import {
  checkUserPermission,
  encryptData,
  useAuthToken,
  useCurrentUserInfo
} from '../../../utils/common/user';
import PaginationSettings from '../settings/Pagination';
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NotesState, PaginationResponse } from '../user/state';
import { handleSortNotes } from '../../../utils/sortingUtils';
import { fetchNotes } from '../../../api/notes-tasks/fetchNotes';
import attachment from '../../../assets/images/attestment.svg';
import pdficon from '../../../assets/images/file-pdf.png';
import othericon from '../../../assets/images/file-other.png';
import { NotesIndexProps } from './state';
import { MODULES, NOTE_UPDATE, REGISTERS } from '../../../constants/constants';
import edit from '../../../assets/images/edit.svg';

const Notes: React.FC<NotesIndexProps> = ({
  moduleType,
  sourceId,
  recordId,
  localKey,
  fetchModuleData,
  editUrl
}) => {
  const user = useCurrentUserInfo();
  const authToken = useAuthToken();
  const [notes, setNotes] = useState<NotesState[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState<keyof NotesState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [dense] = useState(false);
  const review_id = parseInt(recordId!, 10);
  const source_id = parseInt(sourceId!, 10);

  const handleRowToggle = (rowId: number) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
    // Set the currently open row
    if (openRow === rowId) {
      setOpenRow(null); // If the same row was clicked again, close it
    } else {
      setOpenRow(rowId);
    }
  };
  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken && recordId) {
        const response: PaginationResponse = (await fetchNotes(
          review_id,
          source_id,
          moduleType,
          authToken,
          0,
          currentPage,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotes(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const localNotExists = localStorage.getItem(localKey);
    fetchData(orderBy, order); // Call the async function

    if (localNotExists === null) {
      fetchModuleData();
    }
  }, [recordId, currentPage, localKey]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof NotesState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: true,
      label: 'Subject'
    },
    {
      id: 'created_by',
      numeric: false,
      disablePadding: true,
      label: 'Created By'
    }
  ];

  const handleEdit = (rowId: number): string => {
    return editUrl.replace('rowId', encryptData(String(rowId), true)); // Ensure it returns a string URL
  };

  return (
    <>
      <div className="right-outer">
        <div className="table-outer">
          <TableContainer>
            {loading ? ( // Check loading state
              <div className="no-record">
                <CircularProgress />
              </div>
            ) : notes.length === 0 ? (
              <div className="no-record">No notes found.</div>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                className="table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() =>
                            handleSortNotes({
                              property: 'id', // Using correct type
                              orderBy,
                              order,
                              setOrderBy,
                              setOrder,
                              fetchData
                            })
                          }>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={{ display: 'none' }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes.map((row: NotesState) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        key={row.id}
                        id={`main-col-${row.id}`}
                        className={openRow === row.id ? 'collapse' : ''}>
                        {row.created_at ? (
                          <TableCell align="left">
                            {format(new Date(row.created_at), 'd/MMM/yyyy')}
                          </TableCell>
                        ) : (
                          <TableCell align="left">N/A</TableCell>
                        )}
                        <TableCell align="left">{row.subject}</TableCell>
                        <TableCell align="left">
                          <div className="user-img-wrap">
                            {row.user && row.user.profile_image ? (
                              <Avatar
                                src={`${JSON.parse(row.user.profile_image).path}`}
                                alt={row.user.first_name ? row.user.first_name : 'user'}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.style.display = 'none';
                                  const nextElement =
                                    target.nextElementSibling as HTMLElement | null;
                                  if (nextElement) {
                                    nextElement.style.display = 'block';
                                  }
                                }}
                              />
                            ) : (
                              <Avatar alt={row.user ? row.user.first_name : ''} src="." />
                            )}
                            {row.user ? (
                              <p className="user-name">
                                {row.user.first_name + ' ' + row.user.last_name}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="collapse-btn-wrapper">
                            {row.attachments && row.attachments.length > 0 && (
                              <Button className="table-inner-btn">
                                <img src={attachment} alt="attachment" />
                              </Button>
                            )}
                            {(moduleType != 'providers' && moduleType != 'register_records'
                              ? checkUserPermission(user, MODULES, source_id, NOTE_UPDATE)
                              : checkUserPermission(user, REGISTERS, source_id, NOTE_UPDATE)) && (
                              <Button
                                component={RouterLink}
                                to={handleEdit(row.id)} // Return a string
                                className="edit table-inner-btn">
                                <img src={edit} alt="Right Arrow" />
                              </Button>
                            )}
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleRowToggle(row.id)}>
                              {openRows[row.id] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow className="collapse-open" key={`collapse-${row.id}`}>
                        <TableCell colSpan={4}>
                          <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                            <div className="collapse-description">{row.note}</div>
                            {row.attachments && row.attachments.length > 0 && (
                              <ul className="file-wrapper">
                                {row.attachments.map((attachment) => (
                                  <li key={attachment.id}>
                                    <a
                                      href={`${JSON.parse(attachment.path).path}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="pdf-flex">
                                      <img
                                        src={attachment.file_type == 'pdf' ? pdficon : othericon}
                                        alt="pdficon"
                                      />
                                      {attachment.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
            {!loading && notes.length > 0 && (
              <PaginationSettings
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Notes;
