import React, { useEffect } from 'react';
import CommonLayout from '../layout/CommonLayout';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { CircularProgress } from '@mui/material';
import { AccountState } from '../user/state';
import { encryptData, getSelectedAccountInfo, useAuthToken } from '../../../utils/common/user';
import { fetchAccountDetail } from '../../../api/onboarding/fetchAccountDetail';
import { localStorageKeys } from '../../../utils/local-storage';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const navigate = useNavigate();

  const title = 'Success';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const fetchAccount = async () => {
    try {
      if (authToken) {
        const response: AccountState = (await fetchAccountDetail(selectedAccount.id, authToken)) as AccountState;
        
        if (response) {
          localStorage.setItem(
            localStorageKeys.SELECTED_ACCOUNT,
            encryptData(JSON.stringify(response))
          );

          navigate('/dashboard')
        } else {
          console.log('Error : ', response);
        }
      }
    } catch (error: any) {
      console.log('Error : ', error);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper selected-plan-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="right-outer">
                <div className="right-inner">
                  <div className="form-wrap">
                    Wait a minute we will redirect you on your dashboard shortly.
                    <div className="mt-2">
                      <CircularProgress />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-success-1.png`}
                  alt={'Login'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Success;
