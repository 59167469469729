import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import * as SurveyCore from 'survey-core';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { setSurveyJsLicenceKey } from '../../../../utils/common/app';
import { Button } from '@mui/material';
import {
  checkUserPermission,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useTranslation } from 'react-i18next';
import CommonLayout from '../../layout/CommonLayout';
import { localStorageKeys } from '../../../../utils/local-storage';
import { OnboardingTemplateState } from '../../modules/onboarding/state';
import { fetchOnboardingTemplates } from '../../../../api/settings/fetchOnboardingTemplates';
import { editOnboardingTemplate } from '../../../../api/settings/editOnboardingTemplate';
import { S3PublicUrl, SETTINGS, UPDATE } from '../../../../constants/constants';
import LazyImage from '../../common/LazyLoad';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showThemeTab: false
};

interface DefaultAnswers {
  [key: string]: any;
}

const OnboardingTemplateEdit: React.FC = () => {
  const { t } = useTranslation();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const templateId = parseInt(id!, 10);
  const { search } = useLocation();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const queryParams = new URLSearchParams(search);
  let accountId = queryParams.get('accountId') || '';
  accountId = decryptData(accountId, true);
  let sourceId = queryParams.get('sourceId') || '';
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const location = useLocation();
  const currentUrl = location.pathname;
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const pageTitle = id ? 'Edit Onboarding Template' : 'Create Onboarding Template';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [creator, setCreator] = useState<SurveyCreator | null>(null);
  const [answers, setAnswers] = useState<any | null>({});
  const { setNotificationSuccess } = useNotificationContext();
  const [dataFetched, setDataFetched] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 4, UPDATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  useEffect(() => {
    setSurveyJsLicenceKey();
    let json: string = '';
    if (currentUrl == `/settings/onboarding-application-template-edit/${encryptedId}`) {
      json = localStorage.getItem(localStorageKeys.SURVEY_JSON_ONBOARDING_APPLICATION) || '';
      setType(t('templateType.application'));
    } else if (currentUrl == `/settings/onboarding-assessment-template-edit/${encryptedId}`) {
      json = localStorage.getItem(localStorageKeys.SURVEY_JSON_ONBOARDING_ASSESSMENT) || '';
      setType(t('templateType.assessment'));
    } else if (currentUrl == `/settings/onboarding-checklist-template-edit/${encryptedId}`) {
      json = localStorage.getItem(localStorageKeys.SURVEY_JSON_ONBOARDING_CHECKLIST) || '';
      setType(t('templateType.checklist'));
    }

    const creator = new SurveyCreator(creatorOptions);

    if (creator && dataFetched && json !== '') {
      const surveyStructure = JSON.parse(json);
      json = JSON.stringify(surveyStructure);
    }
    creator.isAutoSave = true;
    creator.text = json;
    setCreator(creator);
  }, [id, dataFetched]);

  useEffect(() => {
    fetchData();
  }, [type]);

  SurveyCore.ChoicesRestfull.clearCache();
  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  const fetchData = async () => {
    try {
      if (id && authToken && type) {
        const response: OnboardingTemplateState = (await fetchOnboardingTemplates(
          templateId,
          type,
          authToken
        )) as OnboardingTemplateState;
        if (response) {
          setDataFetched(true);
          const { form_data } = response;

          if (type == t('templateType.application')) {
            localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_APPLICATION);
          } else if (type == t('templateType.assessment')) {
            localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_ASSESSMENT);
          } else if (type == t('templateType.checklist')) {
            localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_CHECKLIST);
          }

          if (form_data && form_data.trim() !== '""') {
            if (type == t('templateType.application')) {
              localStorage.setItem(localStorageKeys.SURVEY_JSON_ONBOARDING_APPLICATION, form_data);
            } else if (type == t('templateType.assessment')) {
              localStorage.setItem(localStorageKeys.SURVEY_JSON_ONBOARDING_ASSESSMENT, form_data);
            } else if (type == t('templateType.checklist')) {
              localStorage.setItem(localStorageKeys.SURVEY_JSON_ONBOARDING_CHECKLIST, form_data);
            }
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined) {
          // Add the value to the element
          element.defaultValue = fieldValues[fieldName];
        }
      });
    });
    return mainResponse;
  };

  const handleSaveSurvey = async () => {
    if (creator) {
      const surveyStructure = JSON.parse(creator.text);
      let mergedResponse = surveyStructure;
      if (Object.keys(surveyStructure).length !== 0 && Object.keys(surveyStructure).length > 1) {
        // Merge the survey structure and answers
        setAnswers({});
        mergedResponse = mergeFieldValues(surveyStructure, answers);
      }

      const source_id = parseInt(sourceId, 10);
      const account_id = parseInt(accountId, 10);
      const response: OnboardingTemplateState = (await editOnboardingTemplate(
        mergedResponse,
        templateId,
        source_id,
        account_id,
        type,
        authToken
      )) as OnboardingTemplateState;
      if (!response) {
        if (type == t('templateType.application')) {
          localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_APPLICATION);
        } else if (type == t('templateType.assessment')) {
          localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_ASSESSMENT);
        } else if (type == t('templateType.checklist')) {
          localStorage.removeItem(localStorageKeys.SURVEY_JSON_ONBOARDING_CHECKLIST);
        }

        const msg = t('success.field.templateUpdated');

        setNotificationSuccess(msg);
        navigate(`/settings/modules/onboarding/${encryptedSourceId}`);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  creator?.onPreviewSurveyCreated.add(function (_, options) {
    setIsPreviewMode(true);
  });

  const defaultAnswers: DefaultAnswers = {};
  if (creator) {
    creator.onSurveyInstanceCreated.add((sender, options) => {
      options.survey.onValueChanged.add((sender: any, options: any) => {
        defaultAnswers[options.name] = options.value;
        setAnswers(defaultAnswers);
      });
    });
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <div className="inner-btn-wrap secondary-button-wrap">
              <div className="btn-wrap">
                {(id || isPreviewMode) && (
                  <Button onClick={handleSaveSurvey} className="btn primary-btn btn-sm">
                    Save
                  </Button>
                )}
                <Button
                  className="btn border-btn btn-sm"
                  component={RouterLink}
                  to={`/settings/modules/onboarding/${encryptedSourceId}`}>
                  Cancel
                </Button>
              </div>
              <a href="" className="template-logo">
                <LazyImage src={`${S3PublicUrl}3lines-logo-small.png`} alt="logo" />
              </a>
            </div>
            <div className="settings-outer-row">
              <div className="right-outer full-width">
                <div className="right-inner">
                  {creator && <SurveyCreatorComponent creator={creator} />}
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
};

export default OnboardingTemplateEdit;
