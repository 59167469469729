import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NotificationContextType {
  notificationSuccess: string;
  setNotificationSuccess: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
  children: ReactNode; // Use ReactNode to type the children prop
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notificationSuccess, setNotificationSuccess] = useState('');

  return (
    <NotificationContext.Provider value={{ notificationSuccess, setNotificationSuccess }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
};
