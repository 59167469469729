import React, { useEffect, useState } from 'react';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { PolicyState } from '../../user/state';
import { fetchPolicy } from '../../../../api/policies/fetchPolicies';
// import { debounce } from 'lodash';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { READ, RESOURCES } from '../../../../constants/constants';

const Policies = () => {
  const title = 'Policies';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainPath = '/dashboard';
  const secondTitle = 'Resources';
  const secondpath = '/dashboard';
  const pageTitle = title;
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(true);
  const [policies, setPolicies] = useState<PolicyState[]>([]);

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, RESOURCES, 2, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      if (authToken) {
        const response = await fetchPolicy(authToken, selectedAccount, 0, 0, 'resource');

        if (response) {
          setPolicies(response as PolicyState[]);
        }
      }
    } catch (error: any) {
      console.log('Error : ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleScroll = () => {
  //   if (
  //     currentPage <= lastPage &&
  //     Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
  //       document.documentElement.offsetHeight
  //   ) {
  //     currentPage += 1;
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  // const debouncedScrollHandler = debounce(handleScroll, 200);

  // useEffect(() => {
  //   window.addEventListener('scroll', debouncedScrollHandler);

  //   return () => {
  //     window.removeEventListener('scroll', debouncedScrollHandler);
  //   };
  // }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={title}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={secondpath}
              />
              <div className="policy-list">
                {!loading && policies.length == 0 ? (
                  <div className="no-record">No policies found.</div>
                ) : policies.length > 0 ? (
                  <ul>
                    {policies.map((policy: PolicyState) => (
                      <li key={policy.id}>
                        <Link
                          to={
                            policy.versions &&
                            Array.isArray(policy.versions) &&
                            policy.versions[0].file_link
                              ? `${JSON.parse(policy.versions[0].file_link).path}`
                              : ''
                          }
                          target="_blank"
                          style={{ background: selectedAccount?.color }}>
                          <span className="icon">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                              <g clipPath="url(#clip0_6545_879)">
                                <path
                                  d="M23.3334 3.78125V10.6655C23.3334 11.5989 23.3334 12.0656 23.5151 12.4221C23.6749 12.7357 23.9298 12.9907 24.2434 13.1505C24.5999 13.3322 25.0667 13.3322 26.0001 13.3322H32.8843M26.6667 21.6654H13.3334M26.6667 28.332H13.3334M16.6667 14.9987H13.3334M23.3334 3.33203H14.6667C11.8665 3.33203 10.4664 3.33203 9.3968 3.877C8.45599 4.35637 7.69108 5.12127 7.21172 6.06208C6.66675 7.13164 6.66675 8.53177 6.66675 11.332V28.6654C6.66675 31.4656 6.66675 32.8658 7.21172 33.9353C7.69108 34.8761 8.45599 35.641 9.3968 36.1204C10.4664 36.6654 11.8665 36.6654 14.6667 36.6654H25.3334C28.1337 36.6654 29.5338 36.6654 30.6034 36.1204C31.5442 35.641 32.3091 34.8761 32.7884 33.9353C33.3334 32.8658 33.3334 31.4656 33.3334 28.6654V13.332L23.3334 3.33203Z"
                                  stroke="CurrentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_6545_879">
                                  <rect width="40" height="40" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <h5>{policy.policy_name}</h5>
                          <p>
                            Version:{' '}
                            {policy.versions &&
                            Array.isArray(policy.versions) &&
                            policy.versions[0].version
                              ? policy.versions[0].version
                              : ''}
                          </p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}

                {loading && (
                  <div className="no-record">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default Policies;
