import { PaginationResponse, ProviderReviewState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchProviderReviews = async (
  authToken?: string,
  accountInfo?: AccountState,
  providerId?: number|string|undefined,
  providerReviewId?: number,
  currentPage: number = 1,
  selectedStatus: string = '',
  itemsPerPage: number = 10
): Promise<PaginationResponse | ProviderReviewState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  
  let routeUrl;
  if (!providerReviewId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/provider/reviews?provider_id=${providerId}&page=${currentPage}&perPage=${itemsPerPage}&status=${selectedStatus}&account_id=${selectedAccountId}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/provider/reviews/${dataEncrypt(providerReviewId?.toString())}?account_id=${selectedAccountId}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;

    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};

