import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { FinalizationPrevetState } from '../../views/components/user/state';

export const fetchFinalisePrevetListing = async (
  authToken?: string,
  prevetId?: number,
): Promise<FinalizationPrevetState | FinalizationPrevetState[] | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/finalise-prevets-list/${dataEncrypt(prevetId?.toString() ?? '0')}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData?.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching finalised Pre-Vets';
  }
};
