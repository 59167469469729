import { PaginationResponse, PrevetState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchPrevets = async (
  source_id?: number,
  authToken?: string,
  accountInfo?: AccountState,
  prevetId?: number,
  currentPage: number = 1,
  search: string = '',
  selectedStatus: string = '',
  itemsPerPage: number = 10,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | PrevetState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  let routeUrl;
  if (!prevetId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/prevets-list?source_id=${source_id}&page=${currentPage}&perPage=${itemsPerPage}&search=${search}&status=${selectedStatus}&account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/prevets-list/${dataEncrypt(prevetId?.toString())}?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;

    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching prevets';
  }
};

