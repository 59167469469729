import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import PoliciesLeftMenu from './PoliciesLeftMenu';
import { NOTE_CREATE, SETTINGS } from '../../../../constants/constants';
import AddNotes from '../../notes/AddNotes';

const PoliciesNotesAdd = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let policyId = queryParams.get('policyId');
  const encryptedPolicyId = policyId; // Decrypt the ID
  policyId = decryptData(policyId, true) || ''; // Default to an empty string
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = id ? 'Edit Policy Note' : 'Add Policy Note';
  let secondTitle;
  const unique_id = localStorage.getItem('policy_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/settings/policies/details/${encryptedPolicyId}`;
  const thirdTitle = 'Notes';
  const thirdPath = `/settings/policies/notes?policyId=${encryptedPolicyId}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 7, NOTE_CREATE)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <PoliciesLeftMenu
                  user={user}
                  policyId={policyId ? policyId : ''}
                  encryptedPolicyId={encryptedPolicyId}
                />
                <AddNotes
                  moduleType="policies"
                  sourceId={''}
                  recordId={policyId}
                  localKey="policyNotesCount"
                  listUrl={`/settings/policies/notes?policyId=${encryptedPolicyId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default PoliciesNotesAdd;
