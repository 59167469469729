import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import LeftNav from '../LeftNav';
import { decryptData } from '../../../../utils/common/user';

const UpdateLeftMenu = () => {
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id;
  id = id ? decryptData(id, true) : 0;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {location.pathname == `/settings/update-add` ||
      location.pathname == `/settings/update-edit/${encryptedId}` ? (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            <span className="inner-text-wrap">{t('update.addMenuMsg')}</span>
          </nav>
        </div>
      ) : (
        <LeftNav />
      )}
    </>
  );
};

export default UpdateLeftMenu;
