import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState, PrevetTemplatesState } from '../../views/components/user/state';

export const fetchPrevetTemplate = async (
  authToken?: string,
  templateId?: number,
  type?: string,
  accountInfo?: AccountState,
  moduleId?: number,
): Promise<PrevetTemplatesState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/prevet-template/${dataEncrypt(templateId?.toString() ?? '0')}?type=${type}&accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&sourceId=${dataEncrypt(moduleId?.toString() ?? '0')}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
