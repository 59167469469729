import { Button, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { accountPasswordReset } from '../../../api/dashboard/accountResetPassword';
import { useTranslation } from 'react-i18next';
import { useAuthToken } from '../../../utils/common/user';
import { AccountPasswordResetState, AccountPasswordResetError } from './state';
import { useNavigate } from 'react-router-dom';
import CommonLayout from '../layout/CommonLayout';

const AccountResetPassword: React.FC = () => {
  const authToken = useAuthToken();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: Account Reset`;
  const [formData, setFormData] = useState<AccountPasswordResetState>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    error: ''
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    const commonPhrases = ['password', '123456', 'qwerty', 'letmein', 'welcome', 'admin', 'test'];
    const sequentialPattern =
      /^(?:0123|1234|2345|3456|4567|5678|6789|7890|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)$/i;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$])[A-Za-z\d!@#$]{12,}$/;

    if (!formData.currentPassword) {
      newErrors.currentPassword = t('error.field.currentPasswordRequired');
    }

    if (!formData.newPassword) {
      newErrors.newPassword = t('error.field.newPasswordRequired');
    } else if (!passwordPattern.test(formData.newPassword)) {
      newErrors.newPassword = t('error.field.newPasswordPattern');
    } else if (formData.currentPassword === formData.newPassword) {
      newErrors.newPassword = t('error.field.useDifferentPassword');
    } else if (
      commonPhrases.some((phrase) => formData.newPassword.toLowerCase().includes(phrase))
    ) {
      newErrors.newPassword = t('error.field.commonPasswordPhrase');
    } else if (sequentialPattern.test(formData.newPassword)) {
      newErrors.newPassword = t('error.field.sequentialCharacters');
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = t('error.field.confirmPasswordRequired');
    } else if (formData.confirmPassword !== formData.newPassword) {
      newErrors.confirmPassword = t('error.field.confirmPasswordNotMatch');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveClick = async () => {
    if (validateForm()) {
      try {
        const requestData = {
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPassword,
          error: ''
        };

        const response: AccountPasswordResetError = (await accountPasswordReset(
          requestData,
          authToken
        )) as AccountPasswordResetError;

        if (response) {
          const newErrors: Record<string, string> = {};

          if (response.currentPassword) {
            newErrors.currentPassword = response.currentPassword[0];
          } else if (response.newPassword) {
            newErrors.newPassword = response.newPassword[0]; // Take the first error message for 'prefix'
          } else if (response.confirmPassword) {
            newErrors.confirmPassword = response.confirmPassword[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        } else {
          navigate('/account-password-success');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleCancelClick = () => {
    navigate('/user-profile');
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseClick = () => {
    setFormData((prevData) => ({ ...prevData, error: '' }));
  };
  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword((prevShow) => !prevShow);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword((prevShow) => !prevShow);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div>
        <section className="user-profile-section">
          <div className="container-full">
            <div className="section-title">
              <h2>Reset Password</h2>
              <p>Please enter your current password to change your password.</p>
            </div>
            {/* Error Messsage */}
            {formData.error && (
              <Alert variant="outlined" severity="error" onClose={handleCloseClick}>
                <div>{formData.error}</div>
              </Alert>
            )}
            <form autoComplete="off">
              <div className="form-block">
                <div className="form-inner-wrap width-sm">
                  <div className="custom-row">
                    <div className="custom-col">
                      <div className="form-group">
                        <label className="form-label">Current password</label>
                        <TextField
                          error={!!errors.currentPassword}
                          variant="outlined"
                          className="form-control"
                          placeholder="Current password"
                          helperText={errors.currentPassword}
                          type={showCurrentPassword ? 'text' : 'password'}
                          onChange={handleInputChange}
                          name="currentPassword"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoOutlinedIcon className="message-ic"></InfoOutlinedIcon>
                                <IconButton
                                  className="password-visiblity-btn"
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowCurrentPassword} // Use the toggle function
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="custom-col">
                      <div className="form-group">
                        <label className="form-label">New password</label>
                        <TextField
                          error={!!errors.newPassword}
                          variant="outlined"
                          className="form-control"
                          placeholder="New password"
                          type={showNewPassword ? 'text' : 'password'}
                          helperText={errors.newPassword}
                          onChange={handleInputChange}
                          name="newPassword"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoOutlinedIcon className="message-ic"></InfoOutlinedIcon>
                                <IconButton
                                  className="password-visiblity-btn"
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="custom-col">
                      <div className="form-group">
                        <label className="form-label">Confirm password</label>
                        <TextField
                          error={!!errors.confirmPassword}
                          variant="outlined"
                          className="form-control"
                          placeholder="Confirm password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          helperText={errors.confirmPassword}
                          onChange={handleInputChange}
                          name="confirmPassword"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoOutlinedIcon className="message-ic"></InfoOutlinedIcon>
                                <IconButton
                                  className="password-visiblity-btn"
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-wrap">
                <Button
                  onClick={handleCancelClick}
                  className="btn border-btn btn-sm"
                  variant="outlined">
                  Cancel
                </Button>
                <Button onClick={handleSaveClick} className="btn primary-btn btn-sm">
                  Update password
                </Button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </CommonLayout>
  );
};

export default AccountResetPassword;
