import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { MeetingState } from '../../views/components/modules/meetings/state';

export const fetchPreviousMeeting = async (
  meetingId: number,
  accountInfo: AccountState,
  agendaId: number,
  authToken: string,
): Promise<MeetingState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/previous-meeting?accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&meetingId=${dataEncrypt(meetingId?.toString())}&agendaId=${agendaId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
};

