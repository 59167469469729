import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { OnboardingTemplateState } from '../../views/components/modules/onboarding/state';
import { AccountState } from '../../views/components/user/state';

export const fetchOnboardingPersonalDetailsTemplate = async (
  onboardingId?: number,
  email?:string | undefined,
  authToken?: string,
  type?: string | null,
  accountInfo?: AccountState,
  sourceId?: number,
): Promise<OnboardingTemplateState | string | undefined> => {
  let routeUrl;

  if (onboardingId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-personal-details-template/${dataEncrypt(onboardingId?.toString())}?email=${email}&type=${type}&sourceId=${sourceId}`;
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-personal-details-template?accountId=${accountInfo?.id}&sourceId=${sourceId}`;
  }

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }

  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching template';
  }
};