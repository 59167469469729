import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, encryptData, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const finaliseMeeting = async (
  authToken?: string,
  accountInfo?: AccountState,
  meetingId?: string | number,
  sourceId?: string | number,
  type?: string,
): Promise<string | number | undefined> => {

  try {
    const selectedAccountId = accountInfo?.id;
    const encryptedSourceId = encryptData(String(sourceId), true);
    const redirectUrl = `meeting/${encryptData(String(meetingId), true)}?account_id=${encryptData(String(selectedAccountId), true)}&redirect=true&source=${encryptedSourceId}`;
    const formData = new FormData(); // Create a FormData object
    formData.append('finalised', '1');
    formData.append('_method', 'PUT');
    formData.append('source_id', sourceId ? sourceId.toString() : '0');
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('type', type!);
    formData.append('redirectUrl', redirectUrl);
    
    const routeUrl = `${process.env.REACT_APP_API_URL}/finalise-meeting/${dataEncrypt(meetingId?.toString() ?? '0')}`;

    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};