import React from 'react';
import { FormControl, MenuItem, Typography, TextField, Button, Radio, Input } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { CalendarFormsProps } from './state';
import { recurrence_naming, recurringPatternContent } from '../../../../utils/commonUtils';

const CalendarCreateEditFormData: React.FC<CalendarFormsProps> = ({
  formData,
  errors,
  userList,
  isDisabled,
  current_route,
  eventAddMode,
  edit_btn_snapshot,
  showRecurringFields,
  handleChangeData,
  handleInputChange,
  handleComplete,
  handleEventDropdown,
  setFormData,
  handleDropdown,
  firstRadioChecked,
  secondRadioChecked,
  createTask,
  inputsDisabled,
  handleRadioChange,
  event,
  handleInputChangeEvent,
  colorOptions,
  calendarIdEncrypted
}) => {
  return (
    <form action="" className="record-create-form">
      <div className="card-outline">
        <div className="card-title-wrap secondary-title-wrap">
          <h3>Snapshot</h3>
          <div className="inner-btn-wrap">
            {isDisabled && current_route && current_route === `/calendar/${calendarIdEncrypted}`
              ? edit_btn_snapshot
              : ''}
          </div>
        </div>
        {isDisabled && current_route && current_route === `/calendar/${calendarIdEncrypted}` && (
          <div className="row-wrap">
            <div className="form-group">
              <label className="form-label">Created</label>
              <p className="form-control">
                {formData.created_at ? format(new Date(formData.created_at), 'd/MMM/yyyy') : ''}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Owner</label>
              <p className="form-control">
                {formData.owner?.first_name + ' ' + formData.owner?.last_name}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Subject</label>
              <p className="form-control">{formData.subject}</p>
            </div>
            <div className="form-group w-full textarea">
              <label className="form-label">Required action</label>
              <p className="form-control">{formData.required_action}</p>
            </div>
            {formData.regulatory_reference && (
              <div className="form-group w-full textarea">
                <label className="form-label">Regulatory reference</label>
                <p className="form-control">{formData.regulatory_reference}</p>
              </div>
            )}
            <div className="form-group ">
              <label className="form-label">First event</label>
              <p className="form-control">
                {formData.event_date ? format(new Date(formData.event_date), 'd/MMM/yyyy') : ''}
              </p>
            </div>
            <div className="form-group textarea">
              <label className="form-label">Colour</label>
              <div className=" form-control">
                <div
                  className="calendar-box-color"
                  style={{ backgroundColor: formData.color }}></div>
              </div>
            </div>
            <div className={`form-group ${formData.is_recurring === 'no' ? 'w-full' : ''}`}>
              <label className="form-label">Recurring</label>
              <p className="form-control">{formData.is_recurring === 'yes' ? 'Yes' : 'No'}</p>
            </div>
            {formData.is_recurring == 'yes' && (
              <div className="form-group ">
                <label className="form-label">Recurring pattern</label>
                <p className="form-control">{recurringPatternContent(formData, event)}</p>
              </div>
            )}

            <div className={`form-group ${formData.create_task == 0 ? 'w-full' : ''}`}>
              <label className="form-label">Create task for owner before event</label>
              <p className="form-control">{formData.create_task == 1 ? 'Yes' : 'No'}</p>
            </div>
            {formData.create_task == 1 && formData.task_days ? (
              <div className="form-group">
                <label className="form-label">Number of days before event</label>
                <p className="form-control">{formData.task_days}</p>
              </div>
            ) : (
              ''
            )}

            <div
              className={`form-group ${
                formData.is_open === 1 ? 'w-full-reopen-calendar' : 'w-full-reopen-calendar'
              }`}>
              <label className="form-label">Status</label>
              <p className="form-control">{formData.is_open === 1 ? 'Open' : 'Archived'}</p>
            </div>
            {formData.is_open === 1 && (
              <div className="inner-btn-wrap calendar-wrap-btn">
                <Button className="btn white-btn" onClick={() => handleComplete(1)}>
                  <i className="left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_10218_12413)">
                        <path
                          d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                          fill="#667085"
                        />
                        <path
                          d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                          fill="#667085"
                        />
                        <path
                          d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                          fill="#667085"
                        />
                        <path
                          d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                          fill="#667085"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10218_12413">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  Archive
                </Button>
              </div>
            )}
          </div>
        )}
        {!isDisabled &&
          current_route &&
          (current_route === `/calendar-event-edit/${calendarIdEncrypted}` ||
            current_route === `/calendar-event-add`) &&
          !eventAddMode && (
            <div className="row-wrap ">
              {Array.isArray(userList) && userList.length > 0 && (
                <div className="form-group">
                  <label className="form-label">Owner</label>
                  <FormControl variant="outlined" error={!!errors.owner_id}>
                    <Select
                      error={!!errors.owner_id}
                      labelId="Complaint-owner-label"
                      id="Complaint-owner"
                      name="owner_id"
                      value={formData.owner_id} // Ensure formData.owner_id is defined
                      onChange={handleDropdown}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <p className="dropdown-placeholder">Select Owner</p>; // Render "Select Owner" as placeholder
                        }
                        const selectedOption =
                          userList &&
                          userList.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {userList.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.owner_id && (
                    <Typography variant="caption" color="error">
                      {errors.owner_id}
                    </Typography>
                  )}
                </div>
              )}
              <div className="form-group w-full textarea">
                <label className="form-label">Subject</label>
                <TextField
                  error={!!errors.subject}
                  id="Subject"
                  variant="outlined"
                  name="subject"
                  value={formData.subject ? formData.subject : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter subject or title for this event"
                  type="text"
                  helperText={errors.subject}
                  inputProps={{
                    maxLength: 120 // Set the maximum character length
                  }}
                />
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Required action</label>
                <TextField
                  error={!!errors.required_action}
                  id="required_action"
                  variant="outlined"
                  name="required_action"
                  value={formData.required_action ? formData.required_action : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  type="text"
                  multiline
                  rows={5}
                  placeholder="Enter a description"
                  helperText={errors.required_action}
                />
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Regulatory reference</label>
                <TextField
                  error={!!errors.regulatory_reference}
                  id="regulatory_reference"
                  variant="outlined"
                  name="regulatory_reference"
                  value={formData.regulatory_reference ? formData.regulatory_reference : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the regulatory reference if applicable"
                  type="text"
                  helperText={errors.regulatory_reference}
                  inputProps={{
                    maxLength: 120 // Set the maximum character length
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label">First event</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MMM/YYYY"
                    className="form-control date-picker"
                    name="event_date"
                    value={formData.event_date ? dayjs(formData.event_date) : null}
                    minDate={dayjs()}
                    onChange={(newValue) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        event_date: newValue ? newValue.format('YYYY-MM-DD') : ''
                      }));
                    }}
                    slotProps={{
                      textField: {
                        error: !!errors.event_date,
                        placeholder: 'Select event start date',
                        helperText: errors.event_date
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="Primary Color">
                  Colour
                </label>
                <FormControl variant="outlined" error={!!errors.color}>
                  <Select
                    error={!!errors.color}
                    labelId="color"
                    id="color"
                    name="color"
                    value={formData.color} // Ensure formData.owner_id is defined
                    onChange={handleDropdown}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    className="custom-select"
                    MenuProps={{
                      className: 'custom-dropdown-menu'
                    }}>
                    {colorOptions.map((color) => (
                      <MenuItem
                        key={color.label}
                        value={color.value}
                        style={{
                          backgroundColor: color.value,
                          padding: '15px',
                          margin: '5px 15px 5px 15px'
                        }}>
                        <span
                          className="custom-color-dropdown"
                          style={{
                            backgroundColor: formData.color
                          }}></span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="form-group">
                <label className="form-label">Is this recurring</label>
                <FormControl variant="outlined" error={!!errors.is_recurring}>
                  <Select
                    error={!!errors.is_recurring}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_recurring"
                    value={formData.is_recurring}
                    onChange={handleChangeData}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    className="custom-select"
                    MenuProps={{ className: 'custom-dropdown-menu' }}>
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
                {errors.is_recurring && (
                  <Typography variant="caption" color="error">
                    {errors.is_recurring}
                  </Typography>
                )}
              </div>
              {showRecurringFields && (
                <div className="form-group">
                  <label className="form-label">Recurring pattern</label>
                  <FormControl variant="outlined" error={!!errors.recurrence_pattern}>
                    <Select
                      error={!!errors.recurrence_pattern}
                      labelId="demo-simple-pattern-label"
                      id="demo-simple-pattern"
                      name="recurrence_pattern"
                      value={event.recurrence_pattern}
                      onChange={handleEventDropdown}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}>
                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                      <MenuItem value="YEARLY">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.recurrence_pattern && (
                    <Typography variant="caption" color="error">
                      {errors.recurrence_pattern}
                    </Typography>
                  )}
                </div>
              )}
              {showRecurringFields && (
                <div className="container-boxs">
                  <div className="each-row">
                    <Radio checked={firstRadioChecked} onChange={handleRadioChange('1')} />
                    <span> Day </span>
                    <Input
                      error={!!errors.day}
                      className={`box ${errors.day ? 'box-red-border' : ''}`}
                      name="day"
                      value={event.day}
                      onChange={handleInputChangeEvent}
                      disabled={inputsDisabled.firstContainer}
                      inputProps={{
                        maxLength: 2
                      }}
                    />
                    {event.recurrence_pattern === 'YEARLY' && (
                      <>
                        <span> of </span>
                        <FormControl error={!!errors.monthNumber} variant="outlined">
                          <Select
                            error={!!errors.monthNumber}
                            // id="demo-simple-days"
                            name="monthNumber"
                            value={event.monthNumber}
                            onChange={handleEventDropdown}
                            disabled={inputsDisabled.firstContainer}
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            className="custom-select dropdown-2 dropdown-month"
                            MenuProps={{ className: 'custom-dropdown-menu' }}>
                            <MenuItem value="1">January</MenuItem>
                            <MenuItem value="2">February</MenuItem>
                            <MenuItem value="3">March</MenuItem>
                            <MenuItem value="4">April</MenuItem>
                            <MenuItem value="5">May</MenuItem>
                            <MenuItem value="6">June</MenuItem>
                            <MenuItem value="7">July</MenuItem>
                            <MenuItem value="8">August</MenuItem>
                            <MenuItem value="9">September</MenuItem>
                            <MenuItem value="10">October</MenuItem>
                            <MenuItem value="11">November</MenuItem>
                            <MenuItem value="12">December</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                    <span> of every </span>
                    <Input
                      className={`box ${errors.month ? 'box-red-border' : ''}`}
                      name="month"
                      value={event.month}
                      onChange={handleInputChangeEvent}
                      disabled={inputsDisabled.firstContainer}
                    />
                    <span> {recurrence_naming(event.recurrence_pattern.toLowerCase())}(s) </span>
                  </div>
                  <div className="each-row">
                    <Radio checked={secondRadioChecked} onChange={handleRadioChange('2')} />
                    <span> The </span>
                    <FormControl error={!!errors.pattern_dayNumber} variant="outlined">
                      <Select
                        error={!!errors.pattern_dayNumber}
                        // id="demo-simple-days"
                        name="pattern_dayNumber"
                        value={event.pattern_dayNumber}
                        onChange={handleEventDropdown}
                        disabled={inputsDisabled.secondContainer}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        className="custom-select dropdown-2"
                        MenuProps={{ className: 'custom-dropdown-menu' }}>
                        <MenuItem value="1">First</MenuItem>
                        <MenuItem value="2">Second</MenuItem>
                        <MenuItem value="3">Third</MenuItem>
                        <MenuItem value="4">Fourth</MenuItem>
                        <MenuItem value="5">Fifth</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl error={!!errors.pattern_dayName} variant="outlined">
                      <Select
                        id="demo-simple-Perticularday"
                        name="pattern_dayName"
                        value={event.pattern_dayName}
                        onChange={handleEventDropdown}
                        disabled={inputsDisabled.secondContainer}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        className="custom-select dropdown-1"
                        MenuProps={{ className: 'custom-dropdown-menu' }}>
                        <MenuItem value="MO">Monday</MenuItem>
                        <MenuItem value="TU">Tuesday</MenuItem>
                        <MenuItem value="WE">Wednesday</MenuItem>
                        <MenuItem value="TH">Thursday</MenuItem>
                        <MenuItem value="FR">Friday</MenuItem>
                        <MenuItem value="ST">Saturday</MenuItem>
                        <MenuItem value="SU">Sunday</MenuItem>
                      </Select>
                    </FormControl>
                    {event.recurrence_pattern === 'YEARLY' && (
                      <>
                        <span> of </span>
                        <FormControl error={!!errors.pattern_monthNumber} variant="outlined">
                          <Select
                            error={!!errors.pattern_monthNumber}
                            // id="demo-simple-days"
                            name="pattern_monthNumber"
                            value={event.pattern_monthNumber}
                            onChange={handleEventDropdown}
                            disabled={inputsDisabled.secondContainer}
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            className="custom-select dropdown-2 dropdown-month"
                            MenuProps={{ className: 'custom-dropdown-menu' }}>
                            <MenuItem value="1">January</MenuItem>
                            <MenuItem value="2">February</MenuItem>
                            <MenuItem value="3">March</MenuItem>
                            <MenuItem value="4">April</MenuItem>
                            <MenuItem value="5">May</MenuItem>
                            <MenuItem value="6">June</MenuItem>
                            <MenuItem value="7">July</MenuItem>
                            <MenuItem value="8">August</MenuItem>
                            <MenuItem value="9">September</MenuItem>
                            <MenuItem value="10">October</MenuItem>
                            <MenuItem value="11">November</MenuItem>
                            <MenuItem value="12">December</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                    <span> of every </span>
                    <Input
                      error={!!errors.pattern_month}
                      className={`box ${errors.pattern_month ? 'box-red-border' : ''}`}
                      name="pattern_month"
                      value={event.pattern_month}
                      onChange={handleInputChangeEvent}
                      disabled={inputsDisabled.secondContainer}
                    />
                    <span> {recurrence_naming(event.recurrence_pattern.toLowerCase())}(s) </span>
                  </div>
                </div>
              )}
              {!showRecurringFields && (
                <div className="form-group">
                  <label className="form-label"></label>
                </div>
              )}
              <div className="form-group">
                <label className="form-label">Create task for owner before event</label>
                <FormControl variant="outlined" error={!!errors.create_task}>
                  <Select
                    error={!!errors.create_task}
                    labelId="demo-simple-task-label"
                    id="demo-simple-task"
                    name="create_task"
                    value={formData.create_task}
                    onChange={handleDropdown}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    className="custom-select"
                    MenuProps={{ className: 'custom-dropdown-menu' }}>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
                {errors.create_task && (
                  <Typography variant="caption" color="error">
                    {errors.create_task}
                  </Typography>
                )}
              </div>
              {createTask && (
                <div className="form-group">
                  <label className="form-label">Number of days before event</label>
                  <TextField
                    error={!!errors.task_days}
                    id="task_days"
                    variant="outlined"
                    name="task_days"
                    value={formData.task_days}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter number of days"
                    type="text"
                    helperText={errors.task_days}
                    inputProps={{
                      maxLength: 120 // Set the maximum character length
                    }}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </form>
  );
};

export default CalendarCreateEditFormData;
