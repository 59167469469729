import { UserAccessLevelState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';

export const fetchUserAccessLevels = async (
  authToken?: string
): Promise<UserAccessLevelState[] | string | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/get-user-access-levels`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching user access level';
  }
};
