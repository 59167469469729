import React, { useState, useEffect } from 'react';
import TasksIndex from '../../tasks/Tasks';
import { useLocation } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { fetchAdviserProfiles } from '../../../../api/adviser-profiles/fetchAdviserProfiles';
import { UserState } from '../../user/state';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_CREATE, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import AdviserProfileLeftMenu from './AdviserProfileLeftMenu';
import AddButton from '../../tasks/AddButton';

const AdviserProfileTasksPage = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  let adviserProfileId = queryParams.get('adviserProfileId');
  const encryptedAdviserProfileId = adviserProfileId; // Decrypt the ID
  adviserProfileId = decryptData(adviserProfileId, true);
  const authToken = useAuthToken();
  const [currentStatus, setCurrentStatus] = useState<number>(1);
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const mainPath = `/adviser-profiles/${encryptedSourceId}`;
  const title = 'Adviser Profile Tasks';
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const adviserName = localStorage.getItem('adviser_name');
  if (adviserName) {
    secondTitle = adviserName;
  }
  const secondpath = `/adviser-profile/${encryptedAdviserProfileId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const fetchAdviserProfileData = async () => {
    try {
      if (adviserProfileId && authToken) {
        const editId = parseInt(adviserProfileId!, 10);

        const response: UserState = (await fetchAdviserProfiles(
          authToken,
          selectedAccount,
          source_Id,
          editId
        )) as UserState;

        if (response) {
          const { first_name, last_name, is_open } = response;
          setCurrentStatus(is_open ? is_open : 0);
          localStorage.removeItem('adviser_name');
          localStorage.setItem('adviser_name', first_name + ' ' + last_name);
        }
      }
    } catch (error) {
      /* empty */
      console.log(error);
    }
  };

  const addButton = (
    <>
      {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
        <AddButton
          addPath={`/adviser-profile/add-task?source=${encryptedSourceId}&adviserProfileId=${encryptedAdviserProfileId}`}
        />
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                button={currentStatus ? addButton : null}
              />
              <div className="record-outer-row settings-outer-row">
                <AdviserProfileLeftMenu
                  user={user}
                  adviserProfileId={adviserProfileId ? adviserProfileId : ''}
                  sourceId={sourceId ? sourceId : ''}
                  adviserProfileIdEncrypted={encryptedAdviserProfileId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <TasksIndex
                  moduleType="adviser-profile"
                  sourceId={sourceId}
                  recordId={adviserProfileId}
                  localKey="adviser_name"
                  fetchModuleData={fetchAdviserProfileData}
                  editUrl={`/adviser-profile/edit-task/rowId?source=${encryptedSourceId}&adviserProfileId=${encryptedAdviserProfileId}`}
                  currentStatus={currentStatus}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AdviserProfileTasksPage;
