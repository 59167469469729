import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Button,
  CircularProgress,
  Switch
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginationResponse, PrevetFileReviewState, AccountState } from '../../user/state';
import { fetchFileReviews } from '../../../../api/pre-vet/fetchFileReviews';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import trashGrey from '../../../../assets/images/trash-grey.svg';
import { FileReviewResponseError, PrevetsDataFormState } from './state';
import { useNotificationContext } from '../../layout/NotificationContext';
import { useTranslation } from 'react-i18next';
import { createFileReview } from '../../../../api/pre-vet/createFileReview';
import {
  checkUserPermission,
  encryptData,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { DELETE, MODULES } from '../../../../constants/constants';

interface SetFileReviewSwitch {
  (value: React.SetStateAction<boolean>): void;
}
interface clientFileProps {
  setFileReviewSwitch: SetFileReviewSwitch;
  setOpenConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setClientFileReviewId: React.Dispatch<React.SetStateAction<number>>;
  prevetId?: string;
  authToken?: string;
  formData?: PrevetsDataFormState;
  sourceId?: string | null;
  accountInfo?: AccountState;
  prevetIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
  setFilereviewsData: React.Dispatch<React.SetStateAction<PrevetFileReviewState[]>>;
}

const ClientFileReviews: React.FC<clientFileProps> = ({
  prevetId,
  authToken,
  formData,
  sourceId,
  accountInfo,
  setFileReviewSwitch,
  setOpenConfirmationPopup,
  setClientFileReviewId,
  prevetIdEncrypted,
  sourceIdEncrypted,
  setFilereviewsData
}) => {
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const { notificationSuccess, setNotificationSuccess } = useNotificationContext();
  const [orderBy, setOrderBy] = useState<keyof PrevetFileReviewState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [filereviews, setFilereviews] = useState<PrevetFileReviewState[]>([]);
  const { t } = useTranslation();

  interface HeadCell {
    disablePadding: boolean;
    id: keyof PrevetFileReviewState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created'
    },
    {
      id: 'client',
      numeric: false,
      disablePadding: true,
      label: 'Client'
    },
    {
      id: 'is_complete',
      numeric: false,
      disablePadding: true,
      label: 'Completed'
    }
  ];

  const handleSort = (property: keyof PrevetFileReviewState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    // Sort the registers array based on the selected criteria
    const sortedRegisters = [...filereviews].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      // Handle sorting for numbers and strings separately
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });

    // Update the registers state with the sorted array
    setFilereviews(sortedRegisters);
    setFilereviewsData(sortedRegisters);
  };

  const redirectEditPage = (rowid: number, is_complete: number) => {
    rowid = encryptData(String(rowid), true);
    if (is_complete == 0 && formData && formData.is_open === 1) {
      navigate(
        `/prevet/edit-file-review/${rowid}?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`
      );
    } else if (formData && is_complete == 1) {
      navigate(
        `/prevet/view-file-review/${rowid}?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`
      );
    }
  };

  const handleSwitchChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    handleSubmit(id, checked);
  };

  const handleSubmit = async (id: number, checked: boolean) => {
    const requestData = {
      is_complete: checked ? checked : false
    };

    try {
      const response: FileReviewResponseError = (await createFileReview(
        requestData,
        authToken,
        accountInfo,
        id
      )) as FileReviewResponseError;

      if (response) {
        const msg = t('success.field.prevetUpdated');
        setNotificationSuccess(msg);
        setFileReviewSwitch((prevState) => !prevState);
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  useEffect(() => {
    localStorage.removeItem('survey-json-prevet-file-review-temp-render');
    const fetchData = async () => {
      try {
        if (authToken && prevetId) {
          const prevet_Id = parseInt(prevetId, 10);
          const response: PaginationResponse = (await fetchFileReviews(
            authToken,
            prevet_Id,
            0
          )) as PaginationResponse;
          if (response && response.data) {
            if (Array.isArray(response.data)) {
              setFilereviews(response.data);
              setFilereviewsData(response.data);
            }
            setLoading(false); // Set loading to false when data is fetched
          }
        }
      } catch (error) {
        // Handle any errors here
        setLoading(false); // Set loading to false on error too
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [prevetId, notificationSuccess]);

  let addButton = null;
  if (formData && formData.is_open === 1 && !formData.finalised && filereviews.length < 1) {
    addButton = (
      <Button
        className="btn primary-btn"
        component={RouterLink}
        to={`/prevet/add-file-review?source=${sourceIdEncrypted}&prevetId=${prevetIdEncrypted}`}>
        <i className="left">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        Add File Review
      </Button>
    );
  }

  return (
    <>
      <div
        className={`card-title-wrap secondary-title-wrap ${
          filereviews.length > 0 ? 'no-border-title' : ''
        }`}>
        <h3>Client File Review</h3>
        <div className="inner-btn-wrap">{addButton}</div>
      </div>
      <div className="row-wrap client-review-table">
        <TableContainer>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : filereviews.length === 0 ? (
            <div className="no-record no-record-finalise">No file reviews have been created…</div>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="table hover-table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'left' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleSort(headCell.id)}>
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={{ display: 'none' }}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filereviews.map((row: PrevetFileReviewState) => (
                  <React.Fragment key={row.id}>
                    <TableRow key={row.id} id={`main-col-${row.id}`}>
                      {row.created_at ? (
                        <TableCell align="left">
                          {format(new Date(row.created_at), 'd/MMM/yyyy')}
                        </TableCell>
                      ) : (
                        <TableCell align="left">N/A</TableCell>
                      )}
                      <TableCell align="left">{row.client}</TableCell>
                      <TableCell align="left">
                        <div className="switch-wrap">
                          {formData?.finalised ? (
                            <span>{row.is_complete ? 'Yes' : 'No'}</span>
                          ) : (
                            <>
                              <Switch
                                className={`custom-switch custom-switch-inside ${
                                  row.is_complete ? 'custom-switch-complete' : 'custom-switch-open'
                                } `}
                                checked={row.is_complete ? true : false}
                                onChange={handleSwitchChange(row.id)}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left" className="right-arrow-width">
                        <div className="table-btn">
                          {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), DELETE) && !formData?.finalised &&  (
                            <Button
                              className="edit"
                              onClick={() => {
                                setClientFileReviewId(row.id);
                                setOpenConfirmationPopup(true);
                              }}>
                              <img src={trashGrey} alt="trashGrey" />
                            </Button>
                          )}
                          {row.is_complete == 0 && formData && formData.is_open === 1 && (
                            <Button
                              className="edit"
                              onClick={() => redirectEditPage(row.id, row.is_complete)}>
                              <img src={rightarrow} alt="Right Arrow" />
                            </Button>
                          )}
                          {row.is_complete == 1 && formData && (
                            <Button
                              className="edit"
                              onClick={() => redirectEditPage(row.id, row.is_complete)}>
                              <img src={rightarrow} alt="Right Arrow" />
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </>
  );
};

export default ClientFileReviews;
