import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { ProviderReviewDataFormState, ProviderReviewResponseError } from '../../views/components/modules/outsource-provider/state';

export const createProviderReview = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  reviewId?: string | number,
  isStatusUpdate?: boolean
): Promise<ProviderReviewDataFormState | ProviderReviewResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const { is_open, provider_id, review_date, reviewer_id, provider_review_template_id, form_data } =
    formData;
  try {
    const formData = new FormData(); // Create a FormData object

    if (isStatusUpdate) {
      formData.append('is_open', is_open?.toString() || '');
      formData.append('provider_id', provider_id?.toString() || '');
    } else {
      formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
      formData.append('provider_id', provider_id?.toString() || '');
      formData.append('review_date', review_date?.toString() || '');
      formData.append('reviewer_id', reviewer_id?.toString() || '');
      formData.append('provider_review_template_id', provider_review_template_id?.toString() || '');
      formData.append('form_data', JSON.stringify(form_data));
    }
    
    if (reviewId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/provider/reviews/${dataEncrypt(reviewId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/provider/reviews`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
