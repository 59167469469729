import { PaginationResponse } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { QuickVetTemplateFormState } from '../../views/components/settings/state';

export const fetchAutomatedReview = async (
  sourceId: number,
  authToken: string,
  accountInfo: AccountState,
  quickVetId: number,
): Promise<
  PaginationResponse | QuickVetTemplateFormState | QuickVetTemplateFormState[] | string | undefined
> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/quickvet-automated-data?account_id=${dataEncrypt(
    selectedAccountId?.toString() ?? '0'
  )}&source_id=${sourceId}&quickvet_id=${quickVetId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData?.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};