import { ApiResponse } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { format } from 'date-fns';

export const exportData = async (
  authToken?: string,
  accountInfo?: AccountState,
  menuId?: string | number,
  fromDate?: string,
  toDate?: string,
  templateselect?: string,
  fetchClientFileReview?: boolean
) => {
  try {
    const selectedAccountId = accountInfo?.id;

    let URL = `${process.env.REACT_APP_API_URL}/export-data?account_id=${selectedAccountId}&menu_id=${menuId}&from_date=${fromDate}&to_date=${toDate}`;

    // Conditionally append fetch_client_file_review if true
    if (fetchClientFileReview) {
      URL += `&fetch_client_file_review=${fetchClientFileReview}`;
      if (templateselect) {
        URL += `&file_review_template_id=${templateselect}`;
      }
    }

    const response = await fetch(
      URL, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );
    if (response.status === 204) {
      return { status: 204, message: 'No data available for the selected date range.' };
    }

    const extension = 'xlsx';
    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download =
      accountInfo?.name.replace(/\s/g, '') +
      '-' +
      format(new Date(), 'ddMMyyyy-hhmm') +
      `.${extension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return response;
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching data';
  }
};
