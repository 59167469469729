import React from 'react';
import { FormControl, MenuItem, Typography, TextField, Button } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { PrevetFormsProps } from './state';
import Select from '@mui/material/Select';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import { format, parseISO } from 'date-fns';
import { isFormatted } from '../../../../utils/commonUtils';

const PrevetCreateEditFormData: React.FC<PrevetFormsProps> = ({
  formData,
  errors,
  date_received,
  adviser,
  reviewer,
  adviserArray,
  reviewerArray,
  closed_at,
  isDisabled,
  current_route,
  prevetAddMode,
  edit_btn_snapshot,
  handleChangeAdviser,
  handleChangeReviewer,
  handleInputChange,
  handleFinalizebutton,
  handleDateInputChange,
  handleFileChange,
  encryptedId,
  selectedFile,
  fetchDocument
}) => {
  return (
    <form action="" className="record-create-form">
      <div className="card-outline">
        <div className="card-title-wrap secondary-title-wrap">
          <h3>Snapshot</h3>
          <div className="inner-btn-wrap">
            {isDisabled && current_route && current_route === `/prevet/${encryptedId}`
              ? edit_btn_snapshot
              : ''}
          </div>
        </div>
        {isDisabled && current_route && current_route === `/prevet/${encryptedId}` && (
          <div className="row-wrap">
            <div className="form-group">
              <label className="form-label">Date created</label>
              <p className="form-control">
                {date_received
                  ? isFormatted(date_received)
                    ? date_received
                    : format(parseISO(date_received), 'd/MMM/yyyy')
                  : ''}
              </p>
            </div>
            <div className="form-group"></div>
            <div className="form-group w-full textarea">
              <label className="form-label">Client name(s)</label>
              <p className="form-control">{formData.subject}</p>
            </div>
            <div className="form-group">
              <label className="form-label">Adviser</label>
              <p className="form-control">
                {formData.adviser?.first_name + ' ' + formData.adviser?.last_name}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Reviewer</label>
              <p className="form-control">
                {formData.reviewer?.first_name + ' ' + formData.reviewer?.last_name}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Preferred completion date</label>
              <p className="form-control">
                {' '}
                {formData.completion_date
                  ? format(new Date(formData.completion_date), 'd/MMM/yyyy')
                  : '-'}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Comments</label>
              <p className="form-control">{formData.comment}</p>
            </div>
            <div className="form-group">
              <label className="form-label">Advice document to review</label>
              <p className="form-control file">
                {fetchDocument
                  ? (() => {
                      try {
                        return (
                          <a href={fetchDocument.path} target="_blank" rel="noopener noreferrer">
                            {fetchDocument.original_name}
                          </a>
                        );
                      } catch (error) {
                        console.error('Error parsing document JSON:', error);
                        return '';
                      }
                    })()
                  : ''}
              </p>
            </div>

            <div
              className={`form-group ${
                formData.is_open === 0 ? 'w-full-only-reopen' : 'w-full-reopen'
              }`}>
              <label className="form-label">Status</label>
              <p className="form-control">{formData.is_open === 1 ? 'Open' : 'Archived'}</p>
            </div>

            {formData.is_open === 0 ? (
              <div className="inner-btn-wrap">
                <Button className="btn white-btn" onClick={() => handleFinalizebutton(1)}>
                  <i className="left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M17.0441 10.7449C16.8126 12.9198 15.5802 14.9579 13.5411 16.1352C10.1532 18.0912 5.82108 16.9304 3.86507 13.5425L3.65674 13.1817M2.95503 9.25651C3.18653 7.0816 4.41885 5.04349 6.45801 3.86618C9.84591 1.91018 14.178 3.07096 16.134 6.45886L16.3424 6.81971M2.91089 15.0557L3.52093 12.779L5.79764 13.3891M14.2019 6.61234L16.4786 7.22239L17.0887 4.94568"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </i>
                  Re-open
                </Button>
              </div>
            ) : (
              <div className="inner-btn-wrap">
                <Button className="btn white-btn" onClick={() => handleFinalizebutton(0)}>
                  <i className="left">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_10218_12413)">
                        <path
                          d="M19.784 6.58183C19.6055 6.37094 19.3433 6.25 19.0649 6.25H0.935092C0.656852 6.25 0.39494 6.37094 0.216426 6.5816C0.0498255 6.77828 -0.0254473 7.03413 0.00763839 7.28816L1.07794 18.4578C1.07888 18.4664 1.07982 18.475 1.08099 18.4834C1.17513 19.1881 1.77356 19.7194 2.47313 19.7194H17.5269C18.2624 19.7194 18.8757 19.1561 18.9221 18.4578L19.9924 7.28839C20.0254 7.03441 19.9504 6.77851 19.784 6.58183ZM17.5306 18.3226C17.5299 18.3215 17.5264 18.3208 17.5269 18.3212L2.48547 18.3233C2.47942 18.3205 2.47055 18.3101 2.46778 18.3019L1.44657 7.64843H18.5534L17.5306 18.3226Z"
                          fill="#667085"
                        />
                        <path
                          d="M18.2334 3.56308C18.0563 3.35918 17.7988 3.24219 17.5266 3.24219H2.4962C2.22425 3.24219 1.96558 3.36359 1.7864 3.57519C1.60628 3.7882 1.52843 4.0655 1.57226 4.32718L1.99171 7.05364L3.37381 6.8411L3.03522 4.64038H16.9879L16.6496 6.8411L18.0314 7.05364L18.4518 4.31925C18.4909 4.04386 18.4115 3.76816 18.2334 3.56308Z"
                          fill="#667085"
                        />
                        <path
                          d="M16.1604 0.602615C15.9833 0.398476 15.7256 0.28125 15.4529 0.28125H4.57043C4.29848 0.28125 4.03981 0.402655 3.86063 0.614255C3.68028 0.827261 3.60266 1.10456 3.64673 1.36812L4.06618 4.04798L5.44757 3.83173L5.11062 1.67944H14.9079L14.5535 3.82587L15.933 4.05376L16.3781 1.35944C16.4177 1.08359 16.3382 0.807925 16.1604 0.602615Z"
                          fill="#667085"
                        />
                        <path
                          d="M12.9362 10.6523H7.06384C6.67771 10.6523 6.36475 10.9653 6.36475 11.3514C6.36475 11.7376 6.67771 12.0505 7.06384 12.0505H12.9362C13.3223 12.0505 13.6353 11.7376 13.6353 11.3514C13.6353 10.9653 13.3223 10.6523 12.9362 10.6523Z"
                          fill="#667085"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10218_12413">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  Archive
                </Button>
              </div>
            )}
            {formData.is_open === 0 && closed_at && (
              <div className="form-group">
                <label className="form-label">Date Closed</label>
                {closed_at
                  ? isFormatted(closed_at)
                    ? closed_at
                    : format(parseISO(closed_at), 'd/MMM/yyyy')
                  : ''}
              </div>
            )}
          </div>
        )}
        {!isDisabled &&
          current_route &&
          (current_route === `/prevet-snapshot-edit/${encryptedId}` ||
            current_route === `/prevet-add`) &&
          !prevetAddMode && (
            <div className="row-wrap ">
              <div className="form-group w-full textarea">
                <label className="form-label">Client name(s)</label>
                <TextField
                  error={!!errors.subject}
                  id="Subject"
                  variant="outlined"
                  name="subject"
                  value={formData.subject ? formData.subject : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter client name(s)"
                  type="text"
                  helperText={errors.subject}
                  inputProps={{
                    maxLength: 120 // Set the maximum character length
                  }}
                />
              </div>
              {Array.isArray(adviserArray) && adviserArray.length > 0 && (
                <div className="form-group w-full textarea">
                  <label className="form-label">Adviser</label>
                  <FormControl variant="outlined" error={!!errors.adviser_id}>
                    <Select
                      error={!!errors.adviser_id}
                      onChange={handleChangeAdviser}
                      labelId="Complaint-owner-label"
                      id="Complaint-owner"
                      value={adviser}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <p className="dropdown-placeholder">Select Adviser</p>; // Render "Select Adviser" as placeholder
                        }
                        const selectedOption =
                          adviserArray &&
                          adviserArray.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {adviserArray.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || ''}>
                          {option?.name || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.adviser_id && (
                    <Typography variant="caption" color="error">
                      {errors.adviser_id}
                    </Typography>
                  )}
                </div>
              )}
              {Array.isArray(reviewerArray) && reviewerArray.length > 0 && (
                <div className="form-group">
                  <label className="form-label">Reviewer</label>
                  <FormControl variant="outlined" error={!!errors.reviewer_id}>
                    <Select
                      error={!!errors.reviewer_id}
                      onChange={handleChangeReviewer}
                      labelId="Complaint-owner-label"
                      id="Complaint-owner"
                      value={reviewer}
                      IconComponent={KeyboardArrowDownRoundedIcon}
                      className="custom-select"
                      MenuProps={{ className: 'custom-dropdown-menu' }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === '') {
                          return <p className="dropdown-placeholder">Select Reviewer</p>; // Render "Select Reviewer" as placeholder
                        }
                        const selectedOption =
                          reviewerArray &&
                          reviewerArray.find((option) => option.id === parseInt(selected, 10));
                        return selectedOption ? `${selectedOption.name}` : '';
                      }}>
                      {reviewerArray.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || ''}>
                          {option?.name || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.reviewer_id && (
                    <Typography variant="caption" color="error">
                      {errors.reviewer_id}
                    </Typography>
                  )}
                </div>
              )}
              <div className="form-group">
                <label className="form-label">Preferred completion date (not guaranteed)</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MMM/YYYY"
                    className="form-control date-picker"
                    value={formData.completion_date ? dayjs(formData.completion_date) : null}
                    onChange={handleDateInputChange}
                    slotProps={{
                      textField: {
                        error: !!errors.completion_date,
                        placeholder: 'Select Date',
                        helperText: errors.completion_date
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="form-group w-full textarea">
                <label className="form-label">Comments</label>
                <TextField
                  id="comment"
                  variant="outlined"
                  name="comment"
                  value={formData.comment ? formData.comment : ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter any additional comments"
                  type="text"
                  multiline
                  rows={5}
                />
              </div>
              <div className="form-group w-full file-upload">
                <label className="form-label large" htmlFor="document">
                  Attach advice document to review
                </label>
                <div className="relative">
                  <TextField
                    error={!!errors.document}
                    id="document"
                    variant="outlined"
                    name="document"
                    onChange={handleFileChange}
                    className="form-control"
                    InputProps={{
                      startAdornment: (
                        <input
                          type="file"
                          className="file-upload-input"
                          onChange={handleFileChange}
                          title="file"
                        />
                      )
                    }}
                    helperText={errors.document}
                  />
                  {selectedFile ? (
                    <div className="preview-uploaded mt-2">
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span> {selectedFile.name}
                      </p>
                    </div>
                  ) : fetchDocument != null ? (
                    <div className="preview-uploaded mt-2">
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span>{' '}
                        {fetchDocument.original_name}
                      </p>
                    </div>
                  ) : (
                    <p className="placeholder-text">
                      <img src={fileUploadIc} alt="File upload" />
                      <span>
                        <span className="blue-text">Click to upload</span> or drag and drop
                      </span>
                    </p>
                  )}
                  {/* {formData.document ? (
                    <div className="preview-uploaded">
                      <img src={fileUploadIc} alt="File upload" />
                      <p className="file-name">
                        <span className="blue-text">Selected file:</span> {formData.document.name}
                      </p>
                    </div>
                  ) : (
                    <p className="placeholder-text">
                      <img src={fileUploadIc} alt="File upload" />
                      <span>
                        <span className="blue-text">Click to upload</span> or drag and drop
                      </span>
                      <span className="small-text"></span>
                    </p>
                  )} */}
                </div>
              </div>

              {formData.is_open === 0 && (
                <div className="form-group disabled-col">
                  <label className="form-label">Date Closed</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      readOnly
                      format="DD/MMM/YYYY"
                      className="form-control date-picker"
                      value={closed_at ? dayjs(closed_at) : null}
                      slotProps={{
                        textField: {
                          error: !!errors.closed_at,
                          placeholder: 'Select Date',
                          helperText: errors.closed_at
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
      </div>
    </form>
  );
};

export default PrevetCreateEditFormData;
