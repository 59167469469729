import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { QuickVetTemplateFormState } from '../../views/components/settings/state';
import { QuickVetsResponseError } from '../../views/components/modules/quickvets/state';

export const createQuickVetTemplate = async (
  formData: any,
  authToken: string,
  accountInfo: AccountState,
  templateId: number | string,
  copyId: number | string
): Promise<QuickVetTemplateFormState | QuickVetsResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  try {
    const form = new FormData(); // Create a FormData object
    form.append('account_id', selectedAccountId.toString());
    // form.append('source_id', sourceId.toString());
    if (templateId) {
      form.append('quickvet_template_id', templateId.toString());
    }
    if (copyId) {
      form.append('copyId', copyId.toString());
    }

    form.append('form_data', JSON.stringify(formData));

    routeUrl = `${process.env.REACT_APP_API_URL}/quickvet-template`;

    const response = await axios.post<ApiResponseType>(routeUrl, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });
    console.log(response);
    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
