import React from 'react';
import { S3PublicUrl } from '../../../constants/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import CommonLayout from '../layout/CommonLayout';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LazyImage from '../common/LazyLoad';

const productsSettings = {
  modules: [Pagination, Navigation, Autoplay],
  navigation: false,
  slidesPerView: 1,
  spaceBetween: 16,
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 2500, // Delay between slides in milliseconds
    disableOnInteraction: false // Autoplay will not be disabled after user interactions
  }
};

const Upgrade = () => {
  const title = 'Upgrade';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  return (
    <CommonLayout title={metapageTitle}>
      <div className="main-wrap">
        <section className="banner-section">
          <div className="container">
            <div className="title-wrap">
              <p className="feature-img-wrap">
                <LazyImage src={`${S3PublicUrl}feature-image.webp`} alt="feature-image" />
              </p>
              <h1 className="title">Go Premium.</h1>
              <Button className="btn secondary-btn" component={RouterLink} to={`/select-plan`}>
                Upgrade NOW!
              </Button>
            </div>
          </div>
        </section>
        <section className="needs-section">
          <div className="container">
            <div className="title-wrap">
              <h2 className="title">everything you need, right at your fingertips.</h2>
            </div>
            <div className="main-content custom-row">
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}laptop.webp`} alt="laptop" />
                </div>
                <div className="content-wrap">
                  <h3>efficiency</h3>
                  <p>Everything you need is in one place, ready from the moment you subscribe.</p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}user_empowerment.webp`} alt="user_empowerment" />
                </div>
                <div className="content-wrap">
                  <h3>empowerment</h3>
                  <p>3Lines Platform is designed to give you control and clarity.</p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}user_mind.webp`} alt="user_mind" />
                </div>
                <div className="content-wrap">
                  <h3>peace of mind</h3>
                  <p>
                    With 3Lines, you&apos;re not just compliant. You&apos;re confidently ahead of
                    the curve and in safe hands.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="features-section">
          <div className="container">
            <div className="title-wrap">
              <h2 className="title">
                Here&apos;s why forward thinking firms go <span>premium</span>
              </h2>
            </div>
            <div className="main-content custom-row">
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}meeting_icon.webp`} alt="meeting_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Facilitate compliance meetings</h3>
                  <p>
                    Streamline your CCMs with ease. Our platform enables you to review, attest, and
                    record adherence to your General Obligations seamlessly, ensuring every meeting
                    is productive and actionable.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}report_icon.webp`} alt="report_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Manage compliance registers and ASIC reporting</h3>
                  <p>
                    Oversee key compliance data and deadlines through our intuitive dashboard. Our
                    Complaints Register, aligned with ASIC&apos;s reporting standards, allows for
                    straightforward CSV exports, simplifying your bi-annual ASIC submissions.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}review_icon.webp`} alt="review_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Conduct client file reviews and pre-vets</h3>
                  <p>
                    Enhance oversight with our tailored audit question set, designed for
                    comprehensive client file reviews and pre-vets. Assign remediation tasks easily
                    and generate detailed reports to fulfil your monitoring and supervision duties.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}blocks_icon.webp`} alt="blocks_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Facilitate Professional Year</h3>
                  <p>
                    Eliminate uncertainty in handling PY training and completion . Our PY solution
                    empowers individuals, supervisors, and licensees to effortlessly generate,
                    oversee, and monitor annual plans, program status, logged hours, and
                    certification for advisers during their professional year.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}management_icon.webp`} alt="management_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Track Representative Attestations</h3>
                  <p>
                    Simplify the complex management of Representative Attestations with our
                    centralised hub. Issue, track, monitor, and review attestations within 3Lines,
                    and embrace efficiency and ease in compliance management with our innovative
                    solution.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}filter_icon.webp`} alt="filter_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Stay up to date with regulatory and industry changes</h3>
                  <p>
                    Never miss a beat on regulatory updates. Our platform keeps you informed on
                    essential industry changes, providing clear guidance on implementation timelines
                    and regulatory requirements to stay compliant.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}user_policy.webp`} alt="user_policy" />
                </div>
                <div className="content-wrap">
                  <h3>Host your policy suite for firm-wide access</h3>
                  <p>
                    Simplify policy management with a centralised repository for a comprehensive
                    view of all your documents. Easily upload, manage, and archive policies,
                    ensuring timely reviews and firm-wide accessibility to critical information.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}analysis_icon.webp`} alt="analysis_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Analyse compliance trends</h3>
                  <p>
                    Gain valuable insights into compliance trends within your firm to guide training
                    programs, identify systemic issues, and track improvements. Benchmark your
                    performance against our global network for a comprehensive understanding of
                    where you stand.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}user_tasks.webp`} alt="user_tasks" />
                </div>
                <div className="content-wrap">
                  <h3>Manage remediation and assign tasks</h3>
                  <p>
                    Assign and track remediation and CCM action items with full traceability,
                    ensuring accountability and progress across your firm. Our platform guarantees a
                    seamless workflow for task management and status reporting.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}adviser_path.png`} alt="adviser_path" />
                </div>
                <div className="content-wrap">
                  <h3>Onboard advisers with ease</h3>
                  <p>
                    Simplify the appointment of representatives, manage forms and documentation,
                    streamline authorisation approvals and experience effortless onboarding. Say
                    goodbye to guesswork with our process-driven approach and robust record-keeping
                    features.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}user_profiles.webp`} alt="user_profiles" />
                </div>
                <div className="content-wrap">
                  <h3>Access adviser profiles</h3>
                  <p>
                    Streamline the management of authorisations, complaints and breach data, and
                    client file review outcomes. Our user-friendly interface and intuitive design
                    make navigating adviser profiles effortless and effective.
                  </p>
                </div>
              </div>
              <div className="custom-col">
                <div className="img-wrap">
                  <LazyImage src={`${S3PublicUrl}chat_icon.webp`} alt="chat_icon" />
                </div>
                <div className="content-wrap">
                  <h3>Chat with an expert</h3>
                  <p>
                    Need to speak to a human? Access ongoing support with just a click. Whether you
                    have a compliance question for our experts or require technical support, our
                    platform facilitates seamless communication.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="trial-section">
          <div className="container">
            <div className="title-wrap">
              <h2 className="title">Upgrade to Premium Today!</h2>
              <p>Monthly billing, no hidden fees, no commitments, just clarity and control.</p>
              <div className="btn-wrap">
                <Button className="btn primary-btn" component={RouterLink} to={`/select-plan`}>
                  Upgrade NOW!
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing-section">
          <div className="container">
            <div className="title-wrap">
              <h2 className="title">
                Clear-cut pricing, <span>no surprises.</span>
              </h2>
            </div>
            <div className="main-content">
              <p>
                Because navigating compliance is already complex enough, we’ve made our pricing as
                painless as possible.
              </p>
              <p>
                3Lines is a whole of firm tool. Your team size will be the number of members in your
                team, from Authorised Representatives, through to Responsible Managers, and anyone
                in between, who has a role to play in ensuring your firm&apos;s ongoing compliance.
              </p>
              <h4>Straightforward monthly billing. No lock-in contracts.</h4>
              <div className="btn-wrap">
                <Button className="btn primary-btn" component={RouterLink} to={`/select-plan`}>
                  Upgrade NOW!
                </Button>
              </div>
              <div className="img-wrap">
                <LazyImage src={`${S3PublicUrl}pricing_img.webp`} alt="pricing_img" />
              </div>
            </div>
            <div className="btn-wrap">
              <Button className="btn secondary-btn" component={RouterLink} to={`/select-plan`}>
                Upgrade NOW!
              </Button>
            </div>
          </div>
        </section>
        <section className="slider-section">
          <div className="container">
            <div className="title-wrap">
              <h2 className="title">
                AFSL Compliance simplified, <span>community amplified.</span>
              </h2>
              <p>
                Discover some of our growing network of financial service businesses powering their
                compliance with 3Lines.
              </p>
            </div>
          </div>
          <Swiper {...productsSettings} className="custom-swiper">
            <SwiperSlide>
              <div className="img-wrap">
                <LazyImage src={`${S3PublicUrl}slider_img_01.webp`} alt="slide_img_01" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-wrap">
                <LazyImage src={`${S3PublicUrl}slider_img_02.webp`} alt="slide_img_02" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="img-wrap">
                <LazyImage src={`${S3PublicUrl}slider_img_03.webp`} alt="slide_img_03" />
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
      </div>
    </CommonLayout>
  );
};

export default Upgrade;
