import axios from 'axios';
import { AccountState, PolicyResponseError, PolicyState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const createPolicyData = async (
  formData: PolicyState,
  selectedFile: File | null,
  authToken?: string,
  accountInfo?: AccountState,
  policyId?: string | number,
  type?: string
): Promise<PolicyState | PolicyResponseError | string | number | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    owner,
    policy_name,
    versions,
    date_next_review,
    is_open
  } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('owner', owner.toString());
    formData.append('policy_name', policy_name);
    formData.append('version', versions && !Array.isArray(versions) ? versions.version : '');
    formData.append('date_next_review', date_next_review);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    formData.append('is_open', is_open.toString());
    formData.append('account_id', selectedAccountId?.toString() || '');
    formData.append('type', type || '');

    if (type) {
      formData.append('policyId', policyId?.toString() || '');
    }

    if (policyId && !type) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/policies/${dataEncrypt(policyId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/policies`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
