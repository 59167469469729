import React from 'react';
import {
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Switch,
  Avatar,
  Link,
  InputAdornment,
  Typography,
  CircularProgress
} from '@mui/material';
import default_user from '../../../../assets/images/default_user.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { EditUserFormProps } from '../state';

const CreateEditFormData: React.FC<EditUserFormProps> = ({
  user,
  formData,
  errors,
  handleChangeTimezone,
  handleChangeRole,
  handleInputChange,
  handleFileChange,
  handleSwitchChange,
  onSubmit,
  removeHandle,
  CancelRequest,
  timezoneOptionsWithValueLabel,
  roleValueLabel,
  timezone,
  role,
  updateLogo,
  loading
}) => {
  return (
    <form className="adduser-outer" onSubmit={onSubmit}>
      <div className="card-outline">
        <div className="card-title-wrap">
          <h3>Personal Information</h3>
        </div>
        <div className="profile-image-block">
          <div className="image-block">
            {formData.profile_image ? (
              formData.profile_image instanceof File ? (
                <img
                  src={URL.createObjectURL(formData.profile_image)}
                  className="profile-image"
                  alt="profile-Image"
                />
              ) : (
                <Avatar
                  src={
                    formData.profile_image instanceof Blob
                      ? URL.createObjectURL(formData.profile_image)
                      : JSON.parse(formData.profile_image).path
                  }
                  alt="profile_image"
                />
              )
            ) : (
              <img src={default_user || ''} className="profile-image" alt="profile-Image" />
            )}
          </div>

          <div className="content-block">
            <Button
              className="btn primary-btn btn-sm"
              onClick={() => document.getElementById('fileInput')?.click()} // Trigger the file input click event
            >
              Change
            </Button>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              className="file-upload-input"
              title="imageInput"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            {(formData.profile_image || updateLogo) && (
              <Link onClick={removeHandle} className="secondary-link" title="Remove">
                Remove
              </Link>
            )}
          </div>
        </div>
        <div className="row-wrap">
          <div className="form-group">
            <label className="form-label">First Name</label>
            <TextField
              variant="outlined"
              className="form-control"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder="First Name"
              error={!!errors.first_name}
              helperText={errors.first_name}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Last Name</label>
            <TextField
              variant="outlined"
              className="form-control"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Email Address</label>
            <TextField
              variant="outlined"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InfoOutlinedIcon className="message-ic"></InfoOutlinedIcon>
                  </InputAdornment>
                )
              }}
              error={!!errors.email}
              helperText={errors.email}
            />
          </div>
          {/* <div className="form-group">
            <label className="form-label">Phone</label>
            <TextField
              variant="outlined"
              className="form-control"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="0000000000"
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </div> */}
        </div>
      </div>
      <div className="card-outline">
        <div className="card-title-wrap">
          <h3>System Information</h3>
        </div>
        <div className="row-wrap">
          <div className="form-group">
            <label className="form-label">Role</label>
            <FormControl variant="outlined">
              <Select
                error={!!errors.lu_user_access_level_id}
                labelId="demo-simple-select-label"
                id="demo-simple-select1"
                label="Role"
                value={role}
                onChange={handleChangeRole}
                IconComponent={KeyboardArrowDownRoundedIcon}
                className="custom-select"
                MenuProps={{ className: 'custom-dropdown-menu' }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return <p className="dropdown-placeholder">Select Role</p>; // Render "Select Role" as placeholder
                  }
                  const selectedOption =
                    roleValueLabel &&
                    roleValueLabel.find((option) => option.id === parseInt(selected, 10));
                  return selectedOption ? `${selectedOption.name}` : '';
                }}>
                {roleValueLabel &&
                  roleValueLabel?.map((option) =>
                    option.id !== 1 ? (
                      <MenuItem
                        key={option.id}
                        value={option.id} // Use optional chaining
                      >
                        {option.name}
                      </MenuItem>
                    ) : null
                  )}
              </Select>
            </FormControl>
            {errors.lu_user_access_level_id && (
              <Typography variant="caption" color="error">
                {errors.lu_user_access_level_id}
              </Typography>
            )}
          </div>

          <div className="form-group">
            <label className="form-label">Timezone</label>
            <FormControl variant="outlined">
              <Select
                error={!!errors.timezone}
                onChange={handleChangeTimezone}
                placeholder="Select Timezone"
                labelId="demo-simple-select-label"
                label="Timezone"
                id="demo-simple-select"
                value={timezone} // Use an empty string as the default value
                IconComponent={KeyboardArrowDownRoundedIcon}
                className="custom-select"
                MenuProps={{ className: 'custom-dropdown-menu' }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return <p className="dropdown-placeholder">Select Timezone</p>; // Render "Select Timezone" as placeholder
                  }
                  const selectedOption =
                    timezoneOptionsWithValueLabel &&
                    timezoneOptionsWithValueLabel.find((option) => option.offset === selected);
                  return selectedOption ? `${selectedOption.name}` : '';
                }}>
                {/* Default option */}
                {timezoneOptionsWithValueLabel &&
                  timezoneOptionsWithValueLabel?.map((option) => (
                    <MenuItem
                      key={option?.offset}
                      value={option?.offset || ''} // Use optional chaining
                    >
                      <InputAdornment position="start">
                        <AccessTimeRoundedIcon />
                      </InputAdornment>
                      {option?.name || ''}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {errors.timezone && (
              <Typography variant="caption" color="error">
                {errors.timezone}
              </Typography>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">Is this user an authorised Adviser</label>
            <div className="switch-wrap">
              {formData.isAdviser ? (
                <>
                  <p className="mute-text">No</p>
                  <Switch
                    className="custom-switch"
                    checked={formData.isAdviser}
                    onChange={handleSwitchChange('isAdviser')}
                  />
                  <p>Yes</p>
                </>
              ) : (
                <>
                  <p>No</p>
                  <Switch
                    className="custom-switch"
                    checked={formData.isAdviser}
                    onChange={handleSwitchChange('isAdviser')}
                  />
                  <p className="mute-text">Yes</p>
                </>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">Is the user account active</label>
            <div className="switch-wrap">
              {formData.is_open ? (
                <>
                  <p className="mute-text">No</p>
                  <Switch
                    className="custom-switch"
                    checked={formData.is_open}
                    onChange={handleSwitchChange('is_open')}
                  />
                  <p>Yes</p>
                </>
              ) : (
                <>
                  <p>No</p>
                  <Switch
                    className="custom-switch"
                    checked={formData.is_open}
                    onChange={handleSwitchChange('is_open')}
                  />
                  <p className="mute-text">Yes</p>
                </>
              )}
            </div>
          </div>
          {user.lu_user_access_level_id === 1 && (
            <div className="form-group">
              <label className="form-label">Is this user a Consultant</label>
              <div className="switch-wrap">
                {formData.is_consultant ? (
                  <>
                    <p className="mute-text">No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.is_consultant}
                      onChange={handleSwitchChange('is_consultant')}
                    />
                    <p>Yes</p>
                  </>
                ) : (
                  <>
                    <p>No</p>
                    <Switch
                      className="custom-switch"
                      checked={formData.is_consultant}
                      onChange={handleSwitchChange('is_consultant')}
                    />
                    <p className="mute-text">Yes</p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="btn-wrap">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button className="btn border-btn btn-sm" onClick={CancelRequest}>
              Cancel
            </Button>

            <Button type="submit" className="btn primary-btn btn-sm">
              {formData.id ? 'Save' : 'Add User'}
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

export default CreateEditFormData;
