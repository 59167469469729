import { t } from 'i18next';
import React, { ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface AuthCheckProps {
  children: ReactNode;
}

const AuthCheck: React.FC<AuthCheckProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('userData');
  const isOnboardingAuthenticated = !!localStorage.getItem(t('onboarding.user'));
  const isaccountexists = !!localStorage.getItem('selected_account');
  const checkredirectexists = !!localStorage.getItem('redirectAfterLogin');

  const navigate = useNavigate();
  const location = useLocation(); // Access location using useLocation
  const currentUrl = `${location.pathname}${location.search}`;

  useEffect(() => {
    if (
      location.pathname === '/onboarding-application' ||
      location.pathname.includes('/onboarding-personal-details') ||
      location.pathname.includes('/onboarding-application-review') ||
      location.pathname.includes('/onboarding-application-submitted')
    ) {
      if (isAuthenticated) {
        navigate('/dashboard');
      } else if (!isOnboardingAuthenticated) {
        // If not authenticated, navigate to the login page
        // const searchParams = new URLSearchParams(location.search);

        localStorage.setItem('redirectAfterLogin', currentUrl);
        navigate('/onboarding-login');
      }
    } else {
      if (isOnboardingAuthenticated) {
        navigate('/login');
      } else if (!isAuthenticated) {
        const hasRedirect = localStorage.getItem('hasRedirect');
        // If not authenticated, navigate to the login page
        const searchParams = new URLSearchParams(location.search);
        const isRedirect = searchParams.get('redirect');
        if (isRedirect) {
          const redirectUrl = window.location.href;
          if (redirectUrl && !hasRedirect) {
            localStorage.setItem('hasRedirect', 'true');
            localStorage.setItem('redirectAfterLogin', redirectUrl);
          }
          navigate('/login');
        } else if (!checkredirectexists) {
          navigate('/session-login');
        }
      } else if (!isaccountexists) {
        navigate('/account-selection');
      }
    }
  }, [isAuthenticated, navigate]);

  return <>{children}</>;
};

export default AuthCheck;
