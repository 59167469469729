import React, { useEffect, useState } from 'react';
import {
  Choices,
  TemplateTextModeProps,
  fetchDynamicChoicesData,
  renderFormElement
} from '../../../../utils/common/surveyjs/survey-js';
import { useAuthToken } from '../../../../utils/common/user';
import { t } from 'i18next';

interface ProfessionalYearTemplateTextModeProps extends TemplateTextModeProps {
  type: string;
}

const ProfessionalYearTemplateTextMode: React.FC<ProfessionalYearTemplateTextModeProps> = ({
  surveyData,
  formData,
  edit_btn,
  type
}) => {
  const [dynamicChoicesData, setDynamicChoicesData] = useState<{ [key: string]: Choices[] }>({});
  const authToken = useAuthToken();

  useEffect(() => {
    // Fetch dynamic choices data when the component mounts
    fetchDynamicChoicesData(authToken, surveyData, formData, setDynamicChoicesData);
  }, []);

  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <div>
          <h3>
            {surveyData?.title
              ? surveyData?.title
              : type === t('templateType.planSupervisor')
                ? 'Plan'
                : type === t('templateType.q1Supervisor')
                  ? 'Q1 Plan'
                  : type === t('templateType.q1Entrant')
                    ? 'Q1 Entrant Plan'
                    : type === t('templateType.q2Supervisor')
                      ? 'Q2 Plan'
                      : type === t('templateType.q2Entrant')
                        ? 'Q2 Entrant Plan'
                        : type === t('templateType.q3Supervisor')
                          ? 'Q3 Plan'
                          : type === t('templateType.q3Entrant')
                            ? 'Q3 Entrant Plan'
                            : type === t('templateType.q4Supervisor')
                              ? 'Q4 Plan'
                              : type === t('templateType.q4Entrant')
                                ? 'Q4 Entrant Plan'
                                : type === t('templateType.examEntrant')
                                  ? 'Exam'
                                  : type === t('templateType.examEntrant')
                                    ? 'Exam'
                                    : ''}
          </h3>
        </div>
        <div className="inner-btn-wrap">{edit_btn}</div>
      </div>
      <div className="row-wrap">
        <div className="form-group w-full">
          {surveyData &&
            surveyData?.pages?.map((page) => (
              <div key={page.name} className="row-wrap">
                {page.elements
                  .filter((element) => formData[element.name] !== undefined)
                  .map((element) => (
                    <div className={`form-group w-full`} key={element.name}>
                      <label className="form-label">
                        {element.title ? element.title : element.name}
                      </label>
                      {renderFormElement(
                        element,
                        formData[element.name],
                        authToken,
                        dynamicChoicesData
                      )}
                    </div>
                  ))}
              </div>
            ))}

          {Object.keys(formData).length === 0 && (
            <>
              {type === t('templateType.planSupervisor') ? (
                <div className="no-record">Plan data not found.</div>
              ) : type === t('templateType.q1Supervisor') ? (
                <div className="no-record">Q1 Supervisor Plan data not found.</div>
              ) : type === t('templateType.q1Entrant') ? (
                <div className="no-record">Q1 Entrant Plan data not found.</div>
              ) : type === t('templateType.q2Supervisor') ? (
                <div className="no-record">Q2 Supervisor Plan data not found.</div>
              ) : type === t('templateType.q2Entrant') ? (
                <div className="no-record">Q2 Entrant Plan data not found.</div>
              ) : type === t('templateType.q3Supervisor') ? (
                <div className="no-record">Q3 Supervisor Plan data not found.</div>
              ) : type === t('templateType.q3Entrant') ? (
                <div className="no-record">Q3 Entrant Plan data not found.</div>
              ) : type === t('templateType.q4Supervisor') ? (
                <div className="no-record">Q4 Supervisor Plan data not found.</div>
              ) : type === t('templateType.q4Entrant') ? (
                <div className="no-record">Q4 Entrant Plan data not found.</div>
              ) : type === t('templateType.examSupervisor') ? (
                <div className="no-record">Exam Supervisor Plan data not found.</div>
              ) : type === t('templateType.examEntrant') ? (
                <div className="no-record">Exam Entrant Plan data not found.</div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfessionalYearTemplateTextMode;
