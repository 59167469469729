import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import PaginationSettings from '../Pagination';
import PoliciesFilters from './FilterBtn';
import {
  checkUserPermission,
  encryptData,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginationResponse, PolicyState } from '../../user/state';
import LeftNav from '../LeftNav';
import rightarrow from '../../../../assets/images/right-arrow.svg';
import { fetchPolicies } from '../../../../api/policies/fetchPolicies';
import { CREATE, READ, SETTINGS } from '../../../../constants/constants';

const Policies = () => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const navigate = useNavigate();
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState<boolean>(true);
  const [dense] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [policies, setPolicies] = useState<PolicyState[]>([]);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('1');

  const mainTitle = 'Settings';
  const mainPath = '/settings/user';
  const title = 'Policies';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 7, READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof PolicyState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID'
    },
    {
      id: 'policy_name',
      numeric: true,
      disablePadding: true,
      label: 'Policy'
    },
    {
      id: 'owner',
      numeric: false,
      disablePadding: false,
      label: 'Owner'
    },
    {
      id: 'is_open',
      numeric: true,
      disablePadding: false,
      label: 'Status'
    },
    {
      id: 'date_next_review',
      numeric: true,
      disablePadding: false,
      label: 'Next Review'
    }
  ];

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchPolicies(
          authToken,
          selectedAccount,
          0,
          currentPage,
          '',
          search,
          selectedStatus,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setPolicies(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(orderBy, order);
  }, [currentPage, search, selectedStatus]);

  const handleSort = (property: keyof PolicyState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  const redirectEditPage = (rowid: number) => {
    navigate(`/settings/policies/details/${encryptData(String(rowid), true)}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 7, CREATE) && (
        <div className="inner-btn-wrap">
          <Button className="btn primary-btn" component={RouterLink} to={`/settings/policies/add`}>
            <i className="left">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Add Policy
          </Button>
        </div>
      )}
    </>
  );

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              button={addButton}
            />

            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <PoliciesFilters
                    search={search}
                    setSearch={setSearch}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                  />
                  <div className="table-outer">
                    <TableContainer>
                      {loading ? (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      ) : policies.length === 0 ? (
                        <div className="no-record">No policies found.</div>
                      ) : (
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                          className="table hover-table">
                          <TableHead>
                            <TableRow>
                              {headCells.map((headCell) => (
                                <TableCell
                                  key={`key-${headCell.id}`}
                                  align={headCell.numeric ? 'left' : 'left'}
                                  padding={headCell.disablePadding ? 'none' : 'normal'}>
                                  {headCell.id == 'id' ? (
                                    headCell.label // Render the label without sorting for the "id" column
                                  ) : (
                                    <TableSortLabel
                                      active={orderBy === headCell.id}
                                      direction={orderBy === headCell.id ? order : 'asc'}
                                      onClick={() => handleSort(headCell.id)}>
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={{ display: 'none' }}>
                                          {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                    </TableSortLabel>
                                  )}
                                </TableCell>
                              ))}
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {policies.map((data: PolicyState) => (
                              <TableRow
                                key={data.id}
                                onClick={() => redirectEditPage(data?.id || 0)}>
                                <TableCell scope="row">{data.unique_id}</TableCell>
                                <TableCell scope="row">{data.policy_name}</TableCell>
                                <TableCell align="left">
                                  <Grid container alignItems="center" flexWrap="nowrap">
                                    <Grid item>
                                      {data.user?.profile_image ? (
                                        <Avatar
                                          src={`${JSON.parse(data.user.profile_image).path}`}
                                          alt={
                                            data.user?.first_name ? data.user?.first_name : 'user'
                                          }
                                          onError={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'none'; // Hide the image
                                            const nextElement =
                                              target.nextElementSibling as HTMLElement | null;
                                            if (nextElement) {
                                              nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Avatar alt={data.user?.first_name} src="." />
                                      )}
                                    </Grid>
                                    <Grid item className="content">
                                      <p>
                                        {data.user?.first_name} {data.user?.last_name}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={data.is_open == 1 ? 'open' : 'closed'}>
                                  <span>{data.is_open == 1 ? 'Open' : 'Archived'}</span>
                                </TableCell>
                                <TableCell scope="row">
                                  {data.date_next_review
                                    ? format(new Date(data.date_next_review), 'd/MMM/yyyy')
                                    : '-'}
                                </TableCell>
                                <TableCell>
                                  <img src={rightarrow} alt="Right Arrow" />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      {!loading && policies.length > 0 && (
                        <PaginationSettings
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default Policies;
