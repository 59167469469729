import { PaginationResponse, TasksState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchTasks = async (
  authToken?: string,
  accountInfo?: AccountState,
  currentPage: number = 1,
  itemsPerPage: number = 10,
  type: number = 1,
  search: string = '',
  selectedStatus: string = '',
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | TasksState | string | undefined> => {
  const selectedAccountId = accountInfo?.id;

  const routeUrl = `${process.env.REACT_APP_API_URL}/all-task?account_id=${selectedAccountId}&page=${currentPage}&perPage=${itemsPerPage}&search=${search}&status=${selectedStatus}&type=${type}&orderBy=${orderBy}&order=${order}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching tasks';
  }
};
