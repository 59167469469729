import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import MeetingLeftMenu from './MeetingLeftMenu';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import {
  MeetingMinutesFormStates,
  MeetingNotesFormState,
  MeetingNotesResponseError,
  MeetingQuestionStates,
  MeetingTaskResponseError,
  QuestionState
} from './state';
import {
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { fetchMeetingAgendaQuestions } from '../../../../api/meeting/fetchMeetingAgendaQuestions';
import { fileChange } from '../../common/fileChange';
import { removeFile } from '../../common/removeFile';
import { useNotificationContext } from '../../layout/NotificationContext';
import NoteAddDialog from './NoteAddDialog';
import { t } from 'i18next';
import TaskAddEditDialog from './TaskAddEditDialog';
import PaginationSettings from '../../settings/Pagination';
import { localStorageKeys } from '../../../../utils/local-storage';
import { createMeetingMinute } from '../../../../api/meeting/createMeetingMinute';
import { createNotes } from '../../../../api/notes-tasks/createNotes';
import { createTasks } from '../../../../api/notes-tasks/createTasks';
import {
  DropdownResponse,
  MeetingPaginationResponse,
  TasksState,
  UpdateState
} from '../../user/state';
import { fetchPreviousMeeting } from './../../../../api/meeting/fetchPreviousMeeting';
import MeetingQuestions from './MeetingQuestions';
import PreviousMeeting from './PreviousMeeting';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TaskFormState } from '../../registers/state';
import MeetingRegulatoryUpdate from './MeetingRegulatoryUpdate';
import { fetchMeetingRegulatoryUpdates } from '../../../../api/meeting/fetchMeetingRegulatoryUpdates';
import MeetingOtherBusiness from './MeetingOtherBusiness';
import { fetchPreviousMeetingOpenTasks } from '../../../../api/meeting/fetchPreviousMeetingOpenTasks';
import { fetchMinuteQuestion } from '../../../../api/meeting/fetchMinuteQuestion';
import { fetchActiveUsers } from '../../../../api/common/fetchActiveUsers';

const MeetingRunMeeting = () => {
  const user = useCurrentUserInfo();
  const module_type = 'meetings';
  const isScribe = localStorage.getItem(localStorageKeys.IS_SCRIBE);
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_id = parseInt(sourceId!, 10);
  let meetingId = queryParams.get('meetingId');
  const encryptedMeetingId = meetingId; // Decrypt the ID
  meetingId = decryptData(meetingId, true);
  const record_id = parseInt(meetingId!, 10);
  let agendaId = queryParams.get('agendaId');
  agendaId = decryptData(agendaId, true);
  const agenda_id = parseInt(agendaId!, 10);
  const { moduleRecords } = useModuleContext();
  const { setNotificationSuccess } = useNotificationContext();
  const selectedAccount = getSelectedAccountInfo();
  const [mainCurrentPage, setMainCurrentPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const mainPath = `/meetings/${encryptedSourceId}`;
  const [dropdownOptions, setDropdownOptions] = useState<{ [key: string]: string }>({});

  let mainTitle;
  let title;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
    title = foundRegister ? foundRegister?.singular + ' in Progress' : 'Meeting in Progress';
  }
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let secondTitle;
  const unique_id = localStorage.getItem('meeting_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/meeting/${encryptedMeetingId}?source=${encryptedSourceId}`;

  const [staticQuestion, setStaticQuestion] = useState<QuestionState | null>(null);
  const [openTasks, setOpenTasks] = useState<TasksState[]>([]);
  const [questionData, setQuestionData] = useState<MeetingQuestionStates>(
    {} as MeetingQuestionStates
  );
  const [questionId, setQuestionId] = useState<number>(0);
  const [taskId, setTaskId] = useState<number>(0);
  const [updateId, setUpdateId] = useState<number>(0);
  const [question, setQuestion] = useState<string>('');
  const [openNoteAddDialog, setOpenNoteAddDialog] = useState(false);
  const [openTaskAddEditDialog, setOpenTaskAddEditDialog] = useState(false);
  const [files, setFiles] = useState<File[]>([]); // Store the selected files as an array
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [openInfo, setOpenInfo] = useState<{ [key: number]: boolean }>({});
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [openTaskRows, setOpenTaskRows] = useState<{ [key: number]: boolean }>({});
  const [ownerArray, setOwnerArray] = useState<DropdownResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<MeetingNotesFormState>({
    subject: '',
    note: '',
    attachments: [],
    error: ''
  });
  const [taskFormData, setTaskFormData] = useState<TaskFormState>({
    id: 0,
    task: '',
    details: '',
    owner: 0,
    date_due: '',
    is_complete: 0,
    date_complete: '',
    completed_notes: '',
    error: '',
    is_assign: 0
  });
  const [minutesFormData, setMinutesFormData] = useState<MeetingMinutesFormStates[]>([]);
  const [previousMeetingReportUrl, setPreviousMeetingReportUrl] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [meetingRegulatoryUpdate, setMeetingRegulatoryUpdate] = useState<UpdateState[]>([]);

  const toggleNoteAddDialog = (
    id: number = 0,
    question: string = '',
    type: string = 'question'
  ) => {
    setFormData({
      subject: '',
      note: '',
      attachments: [],
      error: ''
    });
    if (type == 'task') {
      setTaskId(id);
      setUpdateId(0);
      setQuestionId(0);
    } else if (type == 'update') {
      setUpdateId(id);
      setTaskId(0);
      setQuestionId(0);
    } else {
      setQuestionId(id);
      setTaskId(0);
      setUpdateId(0);
    }
    setQuestion(question);
    addMeetingMinutes();
    setOpenNoteAddDialog(!openNoteAddDialog);
    setErrors({});
    setSelectedFiles(null);
  };

  const toggleTaskAddEditDialog = (
    id: number = 0,
    question: string = '',
    task: TasksState = {} as TasksState,
    type: string = 'question'
  ) => {
    if (task.id) {
      setTaskFormData(task);
    } else {
      setTaskFormData({
        id: 0,
        task: '',
        details: '',
        owner: 0,
        date_due: '',
        is_complete: 0,
        date_complete: '',
        completed_notes: '',
        is_assign: 0,
        error: ''
      });
    }
    if (type == 'update') {
      setUpdateId(id);
      setQuestionId(0);
    } else {
      setQuestionId(id);
      setUpdateId(0);
    }
    setQuestion(question);
    addMeetingMinutes();
    setOpenTaskAddEditDialog(!openTaskAddEditDialog);
    setErrors({});
  };

  const handleInfoToggle = (questionId: number) => {
    setOpenInfo((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId]
    }));
  };

  const handleRowToggle = (rowId: number, type: string = 'note') => {
    if (type === 'note') {
      setOpenRows((prevState) => ({
        ...prevState,
        [rowId]: !prevState[rowId]
      }));
    } else {
      setOpenTaskRows((prevState) => ({
        ...prevState,
        [rowId]: !prevState[rowId]
      }));
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string = 'note',
    questionId: number = 0
  ) => {
    const { name, value } = event.target;

    if (type === 'note') {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else if (type === 'task') {
      setTaskFormData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      const updatedMeetingData = [...minutesFormData];
      updatedMeetingData[questionId].minutes = value;

      setMinutesFormData(updatedMeetingData);
    }
  };

  const handleNAChange = (event: React.ChangeEvent<HTMLInputElement>, id: number = 0) => {
    const updatedMeetingData = [...minutesFormData];

    updatedMeetingData[id].na = event.target.checked ? 1 : 0;
    if (event.target.checked) {
      updatedMeetingData[id].minutes = '';
    }
    setMinutesFormData(updatedMeetingData);
  };

  const handleChangeUser = (event: SelectChangeEvent<string>) => {
    setTaskFormData((prevData) => ({ ...prevData, owner: parseInt(event.target.value) }));
  };

  const handleDateInputChange = (datetime: any, inputName: string) => {
    setTaskFormData((prevData) => ({
      ...prevData,
      [inputName]: datetime.format('YYYY-MM-DD')
    }));
  };

  const handleIsCompleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaskFormData((prevData) => ({
      ...prevData,
      is_complete: event.target.checked ? 1 : 0,
      date_complete: '',
      completed_notes: ''
    }));
    if (!event.target.checked) {
      setErrors({});
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fileChange(event, setSelectedFiles, setFiles);
  };

  const removeSelectedFile = (index: number) => {
    removeFile(index, selectedFiles, setSelectedFiles, setFiles);
  };

  const fetchQuestions = async () => {
    if (agendaId && authToken) {
      try {
        const response: MeetingPaginationResponse = (await fetchMeetingAgendaQuestions(
          agenda_id,
          record_id,
          2,
          authToken,
          currentPage,
          questionId
        )) as MeetingPaginationResponse;

        if (response) {
          if (response.questions.data.length > 0) {
            setQuestionData(response.questions.data[0]);

            const meetingMinutesData: MeetingMinutesFormStates[] = [];

            // Set minutes data for each question if exists
            response.questions.data[0].questions.map((question: QuestionState) => {
              if (question.minute) {
                meetingMinutesData[question.id] = {
                  id: question.minute.id,
                  meeting_id: question.minute.meeting_id,
                  meeting_question_id: question.minute.meeting_question_id,
                  na: question.minute.na,
                  minutes: question.minute.minutes
                };
              } else {
                meetingMinutesData[question.id] = {
                  meeting_id: record_id,
                  meeting_question_id: question.id,
                  na: 0,
                  minutes: ''
                };
              }
            });
            setMinutesFormData(meetingMinutesData);
          }
          if (response.pageDropdownOptions) {
            // Parse the JSON string into a JavaScript object
            const parsedOptions = JSON.parse(response.pageDropdownOptions);
            // Check if the parsed options object is not empty
            setDropdownOptions(parsedOptions);
          }
          setTotalPages(response.questions.last_page);
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    if (mainCurrentPage == 1) {
      getPreviousMeetingOpenTasks();
    }

    if (mainCurrentPage == 1 || mainCurrentPage == 4) {
      getStaticQuestion();
    } else if (mainCurrentPage == 2) {
      fetchQuestions();
    } else if (mainCurrentPage == 3) {
      fetchMeetingRegulatoryUpdate();
    }
  }, [mainCurrentPage, currentPage]);

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchActiveUsers(authToken, selectedAccount);
      if (response) {
        setOwnerArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (openTaskAddEditDialog) {
      fetchUsers();
    } else {
      setOwnerArray([]);
    }
  }, [openTaskAddEditDialog]);

  const getPreviousMeeting = async () => {
    try {
      const response: any = await fetchPreviousMeeting(
        record_id,
        selectedAccount,
        agenda_id,
        authToken
      );

      if (response.id) {
        if (response.meeting_report) {
          const meetingReport = JSON.parse(response.meeting_report);
          setPreviousMeetingReportUrl(meetingReport.path);
        }
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    getPreviousMeeting();
  }, []);

  const getStaticQuestion = async () => {
    try {
      const response: any = (await fetchMinuteQuestion(
        authToken,
        mainCurrentPage,
        record_id,
        2
      )) as any;

      if (response.id) {
        setStaticQuestion(response);

        const meetingMinutesData: MeetingMinutesFormStates[] = [];

        if (response.minute) {
          meetingMinutesData[response.id!] = {
            id: response.minute.id,
            meeting_id: response.minute.meeting_id,
            meeting_question_id: response.minute.meeting_question_id,
            na: response.minute.na,
            minutes: response.minute.minutes
          };
        } else {
          meetingMinutesData[response.id!] = {
            meeting_id: record_id,
            meeting_question_id: response.id,
            na: 0,
            minutes: ''
          };
        }
        setMinutesFormData(meetingMinutesData);
      } else {
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const getPreviousMeetingOpenTasks = async () => {
    try {
      const response: any = (await fetchPreviousMeetingOpenTasks(
        record_id,
        selectedAccount,
        agenda_id,
        authToken
      )) as any;

      if (Array.isArray(response)) {
        setOpenTasks(response);
      } else {
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const fetchMeetingRegulatoryUpdate = async () => {
    try {
      const response: any = (await fetchMeetingRegulatoryUpdates(
        record_id,
        selectedAccount,
        agenda_id,
        2,
        authToken
      )) as any;

      if (Array.isArray(response)) {
        setMeetingRegulatoryUpdate(response);

        const meetingMinutesData: MeetingMinutesFormStates[] = [];

        // Set minutes data for each update if exists
        response.map((update: UpdateState) => {
          if (update.minute) {
            meetingMinutesData[update.id!] = {
              id: update.minute.id,
              meeting_id: update.minute.meeting_id,
              update_id: update.minute.update_id,
              na: update.minute.na,
              minutes: update.minute.minutes
            };
          } else {
            meetingMinutesData[update.id!] = {
              meeting_id: record_id,
              update_id: update.id,
              na: 0,
              minutes: ''
            };
          }
        });
        setMinutesFormData(meetingMinutesData);
      } else {
        setMeetingRegulatoryUpdate([]);
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (mainCurrentPage == 3 && meetingRegulatoryUpdate.length == 0) {
      handleMainPageChange();
    }
  }, [meetingRegulatoryUpdate]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleMainPageChange = (type: string = 'next') => {
    if (type == 'prev') {
      if (mainCurrentPage == 4 && meetingRegulatoryUpdate.length == 0) {
        setMainCurrentPage((prev) => prev - 2);
      } else {
        setMainCurrentPage((prev) => prev - 1);
      }

      if (mainCurrentPage == 3 || (mainCurrentPage == 4 && meetingRegulatoryUpdate.length == 0)) {
        setCurrentPage((prev) => prev - 1);
      }
    } else if (mainCurrentPage !== 4) {
      if (mainCurrentPage == 2 && agenda_id == 4) {
        setMainCurrentPage((prev) => prev + 2);
      } else {
        setMainCurrentPage((prev) => prev + 1);
      }
    }

    if (
      mainCurrentPage == 1 ||
      (mainCurrentPage == 3 && meetingRegulatoryUpdate.length !== 0 && agenda_id !== 4) ||
      mainCurrentPage == 4
    ) {
      addMeetingMinutes();
    }

    if (type === 'next' && mainCurrentPage === 4) {
      navigate(`/meeting/${encryptedMeetingId}?source=${encryptedSourceId}`);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMouseEnter = (questionId: number) => {
    setQuestionId(questionId);
  };

  const validateForm = (type: string): boolean => {
    const newErrors: Record<string, string> = {};
    if (type === 'note') {
      if (!formData.subject) {
        newErrors.subject = t('error.field.subjectRequired');
      }
    } else {
      if (!taskFormData.task) {
        newErrors.task = t('error.field.task');
      }
      if (!taskFormData.owner) {
        newErrors.owner = t('error.field.ownerRequired');
      }

      if (!taskFormData.date_due) {
        newErrors.date_due = t('error.field.dueDate');
      }

      if (taskFormData.is_complete === 1) {
        if (!taskFormData.date_complete) {
          newErrors.date_complete = t('error.field.dateComplete');
        }
        if (!taskFormData.completed_notes) {
          newErrors.completed_notes = t('error.field.completeNotes');
        }
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent, type: string = 'note') => {
    setLoading(true);
    event.preventDefault();
    const isFormValid = validateForm(type);
    if (!isFormValid) {
      setLoading(false);
      return;
    }

    if (type === 'note') {
      const requestData = {
        subject: formData.subject,
        note: formData.note,
        attachments: files
      };
      const response: MeetingNotesResponseError = (await createNotes(
        requestData,
        record_id,
        source_id,
        module_type,
        authToken,
        selectedAccount,
        questionId,
        2,
        taskId,
        updateId
      )) as MeetingNotesResponseError;
      if (!response?.id) {
        const newErrors: Record<string, string> = {};
        if (response.subject) {
          newErrors.subject = response.subject[0];
        } else if (response.attachments) {
          newErrors.attachments = response.attachments[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
        setLoading(false);
      } else {
        const msg = t('success.field.noteAdded');
        toggleNoteAddDialog();
        localStorage.removeItem('meetingTasksCount');
        setNotificationSuccess(msg);
        setLoading(false);
      }
    } else {
      const requestData = {
        id: taskFormData.id,
        task: taskFormData.task,
        module_id: taskFormData.module_id,
        details: taskFormData.details,
        owner: taskFormData.owner,
        date_due: taskFormData.date_due,
        is_complete: taskFormData.is_complete,
        date_complete: taskFormData.date_complete,
        completed_notes: taskFormData.completed_notes,
        is_assign: 0
      };

      const record_id = parseInt(meetingId!, 10);
      const task_id = taskFormData.id || 0;
      const response: MeetingTaskResponseError = (await createTasks(
        requestData,
        record_id,
        source_id,
        module_type,
        authToken,
        selectedAccount,
        task_id,
        questionId,
        2,
        updateId
      )) as MeetingTaskResponseError;
      if (!response?.id) {
        const newErrors: Record<string, string> = {};
        if (response.task) {
          newErrors.task = response.task[0];
        } else if (response.details) {
          newErrors.details = response.details[0];
        } else if (response.owner) {
          newErrors.owner = response.owner[0];
        } else if (response.date_due) {
          newErrors.date_due = response.date_due[0];
        } else if (response.date_complete) {
          newErrors.date_complete = response.date_complete[0];
        } else if (response.completed_notes) {
          newErrors.completed_notes = response.completed_notes[0];
        } else {
          setTaskFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
        setLoading(false);
      } else {
        const msg = t('success.field.taskAdded');
        setNotificationSuccess(msg);
        toggleTaskAddEditDialog();
        setLoading(false);
      }
    }

    if (mainCurrentPage == 1) {
      getStaticQuestion();
      getPreviousMeetingOpenTasks();
    } else if (mainCurrentPage == 2) {
      fetchQuestions();
    } else if (mainCurrentPage == 3) {
      fetchMeetingRegulatoryUpdate();
    } else if (mainCurrentPage == 4) {
      getStaticQuestion();
    }
  };

  const addMeetingMinutes = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: any = (await createMeetingMinute(minutesFormData, authToken)) as any;
    } catch (error) {
      console.error('Error Adding Minutes:', error);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={title}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />

          <div className="record-outer-row settings-outer-row">
            <MeetingLeftMenu
              user={user}
              meetingId={meetingId || ''}
              sourceId={sourceId ? sourceId : ''}
              previousMeetingReportUrl={previousMeetingReportUrl}
              meetingIdEncrypted={encryptedMeetingId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                {mainCurrentPage == 1 ? (
                  <PreviousMeeting
                    type="run-meeting"
                    pathName={''}
                    question={staticQuestion}
                    openTasks={openTasks}
                    minutesFormData={minutesFormData}
                    isScribe={isScribe}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleNAChange={handleNAChange}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 2 ? (
                  <MeetingQuestions
                    type="run-meeting"
                    pathName={''}
                    questionData={questionData}
                    minutesFormData={minutesFormData}
                    isScribe={isScribe}
                    openInfo={openInfo}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleInfoToggle={handleInfoToggle}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleNAChange={handleNAChange}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 3 ? (
                  <MeetingRegulatoryUpdate
                    type="review-agenda"
                    pathName={''}
                    meetingRegulatoryUpdate={meetingRegulatoryUpdate}
                    minutesFormData={minutesFormData}
                    isScribe={isScribe}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleNAChange={handleNAChange}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 4 ? (
                  <MeetingOtherBusiness
                    type="run-meeting"
                    pathName={''}
                    question={staticQuestion}
                    minutesFormData={minutesFormData}
                    isScribe={isScribe}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleNAChange={handleNAChange}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : null}

                {mainCurrentPage !== 2 ? (
                  <div className="pagination">
                    <p
                      className={`previous-btn ${mainCurrentPage == 1 ? 'disabled' : ''}`}
                      onClick={() => {
                        mainCurrentPage > 1 ? handleMainPageChange('prev') : '';
                      }}>
                      <ArrowBackIcon /> Back
                    </p>
                    <p className={`next-btn`} onClick={() => handleMainPageChange()}>
                      {mainCurrentPage !== 4 ? 'Next Page' : 'Save'}
                      <ArrowForwardIcon />
                    </p>
                  </div>
                ) : (
                  <>
                    {questionData && (
                      <PaginationSettings
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        isPagesShow={false}
                        prevButtonText="Back"
                        nextButtonText="Next Page"
                        handleClickEvent={addMeetingMinutes}
                        isShowPagesDropdown={true}
                        mainCurrentPage={mainCurrentPage}
                        handleMainPageChange={handleMainPageChange}
                        dropdownOptions={dropdownOptions}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <NoteAddDialog
        question={question}
        formData={formData}
        errors={errors}
        selectedFiles={selectedFiles}
        openNoteAddDialog={openNoteAddDialog}
        toggleNoteAddDialog={toggleNoteAddDialog}
        handleInputChange={handleInputChange}
        handleFileChange={handleFileChange}
        removeSelectedFile={removeSelectedFile}
        handleSubmit={handleSubmit}
        loading={loading}
      />

      <TaskAddEditDialog
        question={question}
        formData={taskFormData}
        ownerArray={ownerArray}
        errors={errors}
        openTaskAddEditDialog={openTaskAddEditDialog}
        toggleTaskAddEditDialog={toggleTaskAddEditDialog}
        handleInputChange={handleInputChange}
        handleChangeUser={handleChangeUser}
        handleDateInputChange={handleDateInputChange}
        handleIsCompleteChange={handleIsCompleteChange}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </CommonLayout>
  );
};

export default MeetingRunMeeting;
