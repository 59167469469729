import React from 'react';
import { Button, Dialog, TextField, CircularProgress } from '@mui/material';
import fileUploadIc from '../../../../assets/images/file-upload.svg';
import CloseIcon from '@mui/icons-material/Close';
import { NoteAddDialogProps } from './state';

const NoteAddDialog = ({
  question,
  formData,
  errors,
  selectedFiles,
  openNoteAddDialog,
  toggleNoteAddDialog,
  handleInputChange,
  handleFileChange,
  removeSelectedFile,
  handleSubmit,
  loading
}: NoteAddDialogProps) => {
  return (
    <Dialog
      open={openNoteAddDialog}
      onClose={toggleNoteAddDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="model-wrapper">
        <div className="modal-title">
          <h2>Add Note</h2>
        </div>
        <div className="modal-description">
          <p>{question}</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row-wrap">
            <div className="form-group w-full">
              <label className="form-label" htmlFor="subject">
                Subject
              </label>
              <TextField
                error={!!errors.subject}
                fullWidth
                name="subject"
                className="form-control"
                placeholder="Enter subject"
                value={formData.subject}
                onChange={handleInputChange}
                helperText={errors.subject || ''}
                inputProps={{
                  maxLength: 100 // Set the maximum character length
                }}
              />
            </div>
            <div className="form-group w-full">
              <label className="form-label" htmlFor="note">
                Note
              </label>
              <TextField
                error={!!errors.note}
                fullWidth
                name="note"
                multiline
                rows={4}
                value={formData.note}
                className="form-control textarea"
                placeholder="Enter detailed description"
                onChange={handleInputChange}
                helperText={errors.note || ''}
              />
            </div>
            <div className="form-group w-full file-upload">
              <label className="form-label large" htmlFor="imgaeUpdate">
                Attachments
              </label>
              <div className="relative">
                <TextField
                  error={!!errors.attachments}
                  id="imgaeUpdate"
                  variant="outlined"
                  name="imgaeUpdate"
                  className="form-control"
                  helperText={errors.attachments || ''}
                  InputProps={{
                    startAdornment: (
                      <input
                        type="file"
                        multiple
                        className="file-upload-input"
                        onChange={handleFileChange}
                        title="file"
                      />
                    )
                  }}
                />
                {selectedFiles ? (
                  <div className="preview-uploaded">
                    <p className="file-name">
                      <span className="blue-text">Selected file:</span>{' '}
                    </p>
                    <ul>
                      {Array.from(selectedFiles).map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span onClick={() => removeSelectedFile(index)}>
                            <CloseIcon />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="placeholder-text">
                    <img src={fileUploadIc} alt="File upload" />
                    <span>
                      <span className="blue-text">Click to upload</span>or drag and drop
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button className="btn border-btn btn-sm" onClick={toggleNoteAddDialog}>
                  Cancel
                </Button>
                <Button className="btn primary-btn btn-sm" type="submit">
                  Save
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default NoteAddDialog;
