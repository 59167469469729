import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Meetings from './Meetings';
import MeetingCreateEdit from './MeetingCreateEdit';
import MeetingNotes from './MeetingNotes';
import MeetingNotesAdd from './MeetingNotesAdd';
import MeetingTasks from './MeetingTasks';
import MeetingTasksAddEdit from './MeetingTasksAddEdit';
import MeetingReviewAgenda from './MeetingReviewAgenda';
import MeetingRunMeeting from './MeetingRunMeeting';

const MeetingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Meetings />} />
      <Route path="/:id" element={<MeetingCreateEdit />} />
      <Route path="/notes" element={<MeetingNotes />} />
      <Route path="/add-note" element={<MeetingNotesAdd />} />
      <Route path="/edit-note/:id/" element={<MeetingNotesAdd />} />
      <Route path="/tasks" element={<MeetingTasks />} />
      <Route path="/add-task" element={<MeetingTasksAddEdit />} />
      <Route path="/edit-task/:id/" element={<MeetingTasksAddEdit />} />
      <Route path="/review-agenda" element={<MeetingReviewAgenda />} />
      <Route path="/view-agenda" element={<MeetingReviewAgenda />} />
      <Route path="/run-meeting" element={<MeetingRunMeeting />} />
    </Routes>
  );
};

export default MeetingRoutes;
