import React from 'react';
import { FormControl, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import search from '../../../../assets/images/search.svg';

const SearchIcon = () => (
  <InputAdornment position="start">
    <img src={search} alt="search" />
  </InputAdornment>
);

export interface UpdateFilterProps {
  search: string;
  setSearch: (newValue: string) => void;
  selectedType: string;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  setPage: (page: number) => void;
  setCurrentPage: (page: number) => void;
  setLastPage: (page: number) => void;
  handleSearchChange: (newValue: string) => void; // Add this line
}

const UpdatesFilters: React.FC<UpdateFilterProps> = ({
  search,
  // setSearch,
  selectedType,
  setSelectedType,
  // setPage,
  // setCurrentPage,
  handleSearchChange
}) => {
  return (
    <div className="filter-outer filter-outer-update notification-update-filter">
      <div className="filter-list search-box">
        <div className="form-group">
          <TextField
            id="first-name-input"
            type="search"
            className="form-control"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearchChange(e.target.value); // Call handleSearchChange
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: <SearchIcon />
            }}
            variant="outlined"
            aria-label="Search"
          />
        </div>
      </div>
      <div className="filter-list">
        <div className="form-group">
          <FormControl variant="outlined" className="form-control">
            {/* <InputLabel id="status-label">Status</InputLabel> */}
            <Select
              labelId="status-label"
              id="status-select"
              label="Status"
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value as string);
              }}
              name="status"
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="custom-select"
              MenuProps={{ className: 'custom-dropdown-menu' }}
              aria-label="Select status">
              <MenuItem value="All" defaultChecked>
                All
              </MenuItem>
              <MenuItem value="1">Regulatory</MenuItem>
              <MenuItem value="2">System</MenuItem>
              <MenuItem value="3">Internal</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default UpdatesFilters;
