import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { ProfessionalYearTemplatesState } from '../../views/components/modules/professional-years/state';
import { AccountState } from '../../views/components/user/state';

export const fetchProfessionalYearTemplates = async (
  authToken: string,
  accountInfo?: AccountState,
  moduleId?: number,
  professionalYearId?: number
): Promise<ProfessionalYearTemplatesState[] | string | undefined> => {
  const selectedAccountId = accountInfo?.id;
  const routeUrl = `${process.env.REACT_APP_API_URL}/professionalyear-templates?professionalYearId=${dataEncrypt(professionalYearId?.toString() ?? '0')}&accountId=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&sourceId=${moduleId}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching templates';
  }
};
