import React, { useEffect } from 'react';
import CommonLayout from '../layout/CommonLayout';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { dataDecrypt, encryptData } from './../../../utils/common/user';

const Redirecting = () => {
  const metapageTitle = `${process.env.REACT_APP_NAME}: Redirecting`;
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let url = dataDecrypt(queryParams.get('url') || '');
  const data = JSON.parse(dataDecrypt(queryParams.get('data') || ''));

  useEffect(() => {
    data.map((value: string, index: string) => {
      const regex = new RegExp(`\\b${index}\\b`, 'g');
      const descryptedValue = encryptData(String(dataDecrypt(value)), true);
      url = url.replace(regex, descryptedValue);
    });
    
    navigate(url);
  }, []);

  return (
    <CommonLayout title={metapageTitle}>
      <div className="container-full redirecting">
        <div className="form-wrap">
          Verifying URL, Redirect you shortly...
          <div className="mt-2">
            <CircularProgress />
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Redirecting;
