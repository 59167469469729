import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, Alert } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { newUserPassword } from '../../../api/user/newUserPassword';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const NewUserPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('userData');

  const url = new URL(window.location.href);
  const email = url.searchParams.get('email') || null;

  useEffect(() => {
    if (!email) {
      navigate('/error');
    } else if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [email, navigate]);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    error: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validateForm = (): boolean => {
    const { password, confirmPassword } = formData;
    let isValid = true;

    if (!password) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: 'Password is required.'
      }));
      isValid = false;
    } else if (password.length < 8) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: 'Must be at least 8 characters.'
      }));
      isValid = false;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: ''
      }));
    }

    if (!confirmPassword) {
      setFormData((prevData) => ({
        ...prevData,
        confirmPasswordError: 'Confirm password is required.'
      }));
      isValid = false;
    } else if (password !== confirmPassword) {
      setFormData((prevData) => ({
        ...prevData,
        confirmPasswordError: 'Password does not match.'
      }));
      isValid = false;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        confirmPasswordError: ''
      }));
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const errorMessage = await newUserPassword({
      password: formData.password,
      email: email ? atob(email) : undefined
    });

    if (errorMessage) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: '',
        confirmPasswordError: '',
        error: errorMessage
      }));
    } else {
      navigate('/forgot-password-success');
    }
  };

  return (
    <div className="pre-login-wrapper">
      <div className="pre-login-inner">
        <div className="left-col">
          <div className="left-inner">
            <Link to="/login" className="back-link">
              <ArrowBackRoundedIcon />
              Back
            </Link>
            <div className="title-wrap">
              <h1>Create new password</h1>
              <p>Enter your new password below.</p>
            </div>
            {formData.error && (
              <Alert variant="outlined" severity="error" onClose={() => {}}>
                <div>{formData.error}</div>
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="New Password">
                  New Password
                </label>
                <TextField
                  error={!!formData.passwordError || !!formData.confirmPasswordError}
                  id="password"
                  variant="outlined"
                  name="password"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  helperText={formData.passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="Confirm Password">
                  Confirm Password
                </label>
                <TextField
                  error={!!formData.passwordError || !!formData.confirmPasswordError}
                  id="confirmPassword"
                  variant="outlined"
                  name="confirmPassword"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your password"
                  helperText={formData.confirmPasswordError}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <Button variant="contained" type="submit" className="btn primary-btn">
                Create password
              </Button>
            </form>
          </div>
        </div>
        <div className="right-image-col">
          <div className="right-inner">
            <div className="img-block">
              <LazyImage
                src={`${S3PublicUrl}3Lines-reset-password.png`}
                alt=""
                rootMargin="0px 0px 200px 0px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUserPasswordForm;
