// 2 => Compliance Calendar
export const RunMeetingLetMenuModuleButtons = ['2'];

/* 
2 => Complaints Register, 
3 => Conflicts of Interest Register, 
4 => Incident and Breach Register, 
5 => Outsourced Service Provider Register, 
6 => Risk Register */
export const RunMeetingLetMenuRegisterButtons = ['15', '14', '11', '18', '16'];

/* 
1 => Active
2 => Paused, 
3 => Canceled */
export const SubscriptionActiveStatus = ['1', '2', '3']

/* 
0 => Starter
5 => Enterprise, 
6 => Old Plans */
export const PlanLevel = ['0', '5', '6'];
