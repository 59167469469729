import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TasksDialogIndex from './TasksDialogIndex';
import NotesDialogIndex from './NotesDialogIndex';

interface TaskShowAddEditDialogProps {
  handleClose: () => void;
  dialogOpen: boolean;
  currentLabel: string;
  currentAction: string;
  recordId?: string | null;
  sourceId?: string | null;
  setStateUpdates: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentAction: React.Dispatch<React.SetStateAction<string>>;
  addTaskUrl: string;
  editTaskUrl: string;
  addNoteUrl: string;
  editNoteUrl: string;
}

const TasksNotesShowAddEditDialog: React.FC<TaskShowAddEditDialogProps> = ({
  handleClose,
  dialogOpen,
  currentLabel,
  currentAction,
  recordId,
  sourceId,
  setStateUpdates,
  setCurrentAction,
  addTaskUrl,
  editTaskUrl,
  addNoteUrl,
  editNoteUrl
}) => {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dialogOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        PaperProps={{
          style: {
            minHeight: '80vh',
            maxHeight: '80vh',
            minWidth: '50vw',
            maxWidth: '50vw',
            border: 'none'
          }
        }}
        disableEscapeKeyDown>
        <DialogTitle>{''}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {currentLabel == 'Tasks' ? (
            <TasksDialogIndex
              moduleType="register_records"
              sourceId={sourceId ?? null}
              recordId={recordId ?? null}
              currentLabel={currentLabel}
              currentAction={currentAction}
              addUrl={addTaskUrl}
              editUrl={editTaskUrl}
              setStateUpdates={setStateUpdates}
              setCurrentAction={setCurrentAction}
            />
          ) : (
            <NotesDialogIndex
              moduleType="register_records"
              sourceId={sourceId ?? null}
              recordId={recordId ?? null}
              currentLabel={currentLabel}
              currentAction={currentAction}
              addUrl={addNoteUrl}
              editUrl={editNoteUrl}
              setStateUpdates={setStateUpdates}
              setCurrentAction={setCurrentAction}
              // editUrl={`/register/edit-note/rowId?source=${sourceIdEncrypt}&recordId=${registerIdEncrypted}`}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TasksNotesShowAddEditDialog;
