import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import rightArrowIc from '../../../../assets/images/right-arrow.svg';
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Alert from '@mui/material/Alert';
import { useNotificationContext } from '../NotificationContext';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { getSelectedAccountInfo } from '../../../../utils/common/user';
import { t } from 'i18next';

interface CommonBreadcrumbsProps {
  dashboardPath?: string;
  mainTitle?: string;
  mainPath?: string;
  title?: string;
  pageTitle?: string;
  secondTitle?: string;
  secondpath?: string;
  thirdTitle?: string;
  thirdPath?: string;
  errorMsg?: string | null;
  handleCloseClick?: () => void; // No event parameter needed
  button?: JSX.Element | null; // Add a button prop
}

const CommonBreadcrumbs: React.FC<CommonBreadcrumbsProps> = ({
  dashboardPath,
  mainTitle,
  mainPath,
  title,
  pageTitle,
  secondTitle,
  secondpath,
  thirdTitle,
  thirdPath,
  errorMsg,
  handleCloseClick,
  button
}) => {
  const selectedAccount = getSelectedAccountInfo();
  let successAlertTimer: ReturnType<typeof setTimeout>;
  const location = useLocation();
  const isSettingPage = location.pathname.startsWith('/setting/');
  const type = localStorage.getItem('type');

  const { notificationSuccess, setNotificationSuccess } = useNotificationContext();
  useEffect(() => {
    if (notificationSuccess) {
      successAlertTimer = setTimeout(() => {
        setNotificationSuccess('');
      }, 3000);
    }
    return () => {
      clearTimeout(successAlertTimer);
    };
  }, [notificationSuccess, setNotificationSuccess]);

  const svgStyle = {
    stroke: selectedAccount ? selectedAccount.color : '' // or fill: selectedAccount.color for fillable SVGs
  };

  return (
    <div className="breadcrumbs-title-wrap">
      {type !== t('onboarding.user') && (
        <div className="breadcrumbs-wrap">
          <Breadcrumbs aria-label="breadcrumb" separator={<img src={rightArrowIc} alt="arrow" />}>
            <Link component={RouterLink} to={dashboardPath ? dashboardPath : "/dashboard"} style={{ color: selectedAccount?.color }}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className="svg-css">
                <path
                  d="M6.66667 14.1663H13.3333M9.18141 2.30297L3.52949 6.6989C3.15168 6.99276 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99276 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={svgStyle}
                />
              </svg>
            </Link>
            {mainTitle && (
              <Link
                component={RouterLink}
                to={mainPath ? mainPath : ''}
                style={{ color: selectedAccount?.color }}>
                {mainTitle}
              </Link>
            )}
            {secondTitle && (
              <Link
                component={RouterLink}
                to={secondpath ? secondpath : ''}
                style={{ color: selectedAccount?.color }}>
                {secondTitle}
              </Link>
            )}
            {thirdTitle && (
              <Link
                component={RouterLink}
                to={thirdPath ? thirdPath : ''}
                style={{ color: selectedAccount?.color }}>
                {thirdTitle}
              </Link>
            )}
            {title && <span>{title}</span>}
          </Breadcrumbs>
        </div>
      )}
      <div className="title-wrap">
        <h2>{pageTitle}</h2>
        {/* Success Message */}
        {notificationSuccess && (
          <Alert variant="outlined" severity="success" onClose={handleCloseClick}>
            {notificationSuccess}
          </Alert>
        )}

        {/* Error Messsage */}
        {errorMsg && (
          <Alert variant="outlined" severity="error" onClose={handleCloseClick}>
            <div>{errorMsg}</div>
          </Alert>
        )}
        {button}
        {isSettingPage && (
          <IconButton aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default CommonBreadcrumbs;
