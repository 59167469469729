import React, { useEffect, useState } from 'react';
import { PaginationResponse, UserState } from '../../user/state';
import { AttestationLeftMenuState, AttestationState } from './state';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken
} from '../../../../utils/common/user';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import { MODULES, NOTE_READ, TASK_READ } from '../../../../constants/constants';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAttestations } from '../../../../api/attestations/fetchAttestations';

interface LeftMenuProps {
  user?: UserState | undefined;
  attestationId?: string;
  sourceId?: string;
  formDataRecord?: AttestationState;
  attestationIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const AttestationLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  attestationId,
  sourceId,
  formDataRecord,
  attestationIdEncrypted,
  sourceIdEncrypted
}) => {
  const module_type = 'attestations';
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { id } = useParams();

  const taskUrl = `/attestation/tasks?source=${sourceIdEncrypted}&attestationId=${attestationIdEncrypted}`;
  const notesUrl = `/attestation/notes?source=${sourceIdEncrypted}&attestationId=${attestationIdEncrypted}`;
  const detailsUrl = `/attestation/${attestationIdEncrypted}?source=${sourceIdEncrypted}`;
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('attestationNotesCount') || ''
  );
  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('attestationTasksCount') || ''
  );

  const [formData, setFormData] = useState<AttestationLeftMenuState | AttestationState>({
    unique_id: '',
    owner: {} as UserState,
    is_open: 0,
    relevant_period: ''
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (attestationId && authToken) {
        const editId = parseInt(attestationId!, 10);

        const response: AttestationState = (await fetchAttestations(
          authToken,
          selectedAccount,
          editId
        )) as AttestationState;

        if (response) {
          const {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user
          } = response;

          localStorage.removeItem('attestation_unique_id');
          localStorage.setItem('attestation_unique_id', unique_id || '');

          const newFormData = {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user
          };
          setFormData(newFormData);
        }
      }
    } catch (error) {
      // Handle any errors here
      setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('attestationNotesCount');
    try {
      if (authToken && attestationId) {
        const record_Id = parseInt(attestationId, 10);

        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('attestationNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('attestationTasksCount');
    try {
      if (authToken && attestationId) {
        const record_Id = parseInt(attestationId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('attestationTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!formDataRecord) {
      fetchData(); // Call the async function
    } else {
      formDataRecord ? setFormData(formDataRecord) : '';
    }

    if (!localStorage.getItem('attestationNotesCount')) {
      fetchNotesCount();
    }
    if (!localStorage.getItem('attestationTasksCount')) {
      fetchTasksCount();
    }
  }, [sourceId, formDataRecord]);

  // URLs exclude from the menu
  const excludedUrls = [
    '/attestation-add',
    '/attestation/add-note',
    `/attestation/edit-note/${id}`,
    '/attestation/add-task',
    `/attestation/edit-task/${id}`,
    `/attestation-edit/${id}`,
    '/attestation-form',
    '/attestation-form-review'
  ];

  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });

  return (
    <>
      {location.pathname !== `/attestations/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>
            {location.pathname == '/attestation-add' && (
              <span className="inner-text-wrap">{t('attestation.addMenuMsg')}</span>
            )}

            {location.pathname == '/attestation-form' && (
              <span className="inner-text-wrap">
                {t('attestation.leftMenuUserMsg').replace(
                  'requested_period',
                  formData?.relevant_period ? formData?.relevant_period : ''
                )}
              </span>
            )}

            <div className="sidebar-inner">
              {location.pathname !== '/attestation-add' &&
                location.pathname !== '/attestation-form' &&
                location.pathname !== '/attestation-form-review' && (
                  <div className="comp-uder-detail">
                    <div className="comp-uder-detail-list">
                      <h6>ID</h6>
                      {formData && formData.unique_id ? (
                        <p>{formData.unique_id ? formData.unique_id : ''}</p>
                      ) : null}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Owner</h6>
                      {formData && formData.owner ? (
                        <p>
                          {formData.owner.first_name} {formData.owner.last_name}
                        </p>
                      ) : null}
                    </div>
                    <div className="comp-uder-detail-list">
                      <h6>Status</h6>
                      {formData && formData?.is_open ? (
                        <p>
                          {formData?.is_open == 1
                            ? 'Sent'
                            : formData?.is_open == 2
                              ? 'For Review'
                              : 'Archived'}
                        </p>
                      ) : null}
                    </div>
                  </div>
                )}
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default AttestationLeftMenu;
