import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  checkUserPermission,
  decryptData,
  getSelectedAccountInfo,
  isSubscriptionExpired,
  useCurrentUserInfo
} from '../../../../utils/common/user';
import { useNavigate } from 'react-router-dom';
import { MODULES, TASK_READ } from '../../../../constants/constants';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import AttestationLeftMenu from './AttestationLeftMenu';
import AddTasks from '../../tasks/AddTasks';
import { useParams } from 'react-router-dom';

const AttestationTasksAddEdit = () => {
  const navigate = useNavigate();
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true) || ''; // Default to an empty string if undefined
  const source_Id = parseInt(sourceId!, 10);
  let attestationId = queryParams.get('attestationId');
  const encryptedAttestationId = attestationId; // Decrypt the ID
  attestationId = decryptData(attestationId, true) || ''; // Default to an empty string
  let { id } = useParams(); // Get encrypted ID from URL
  id = id ? decryptData(id, true) : 0;
  const mainPath = `/attestations/${encryptedSourceId}`;
  const title = id ? 'Edit Attestation Task' : 'Add Attestation Task';

  const thirdTitle = 'Tasks';
  const thirdPath = `/attestation/tasks?source=${encryptedSourceId}&attestationId=${encryptedAttestationId}`;

  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));

  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let mainTitle;
  const { moduleRecords } = useModuleContext();

  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }

  let secondTitle;
  const unique_id = localStorage.getItem('attestation_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/attestation/${encryptedAttestationId}?source=${encryptedSourceId}`;

  useEffect(() => {
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, TASK_READ)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainTitle={mainTitle}
                mainPath={mainPath}
                title={title}
                pageTitle={title}
                secondTitle={secondTitle}
                secondpath={secondpath}
                thirdTitle={thirdTitle}
                thirdPath={thirdPath}
              />
              <div className="record-outer-row settings-outer-row">
                <AttestationLeftMenu
                  user={user}
                  attestationId={attestationId ? attestationId : ''}
                  sourceId={sourceId || ''}
                  attestationIdEncrypted={encryptedAttestationId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <AddTasks
                  moduleType="attestations"
                  sourceId={sourceId}
                  recordId={attestationId}
                  localKey="attestationTasksCount"
                  listUrl={`/attestation/tasks?source=${encryptedSourceId}&attestationId=${encryptedAttestationId}`}
                />
              </div>
            </div>
          </section>
        </CommonLayout>
      )}
    </>
  );
};

export default AttestationTasksAddEdit;
