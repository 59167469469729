import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import detail from '../../../../assets/images/detail.svg';
import detaillActive from '../../../../assets/images/detaill-black.svg';
import note from '../../../../assets/images/note.svg';
import noteActive from '../../../../assets/images/note-black.svg';
import templatesIc from '../../../../assets/images/templates-ic.svg';
import templatesActive from '../../../../assets/images/templates-black-ic.svg';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken
} from '../../../../utils/common/user';
import { PaginationResponse, UserState } from '../../user/state';
import { fetchNotes } from '../../../../api/notes-tasks/fetchNotes';
import { fetchTasks } from '../../../../api/notes-tasks/fetchTasks';
import { MODULES, NOTE_READ, TASK_READ } from '../../../../constants/constants';
import { fetchAdviserProfiles } from '../../../../api/adviser-profiles/fetchAdviserProfiles';
import { AdviserProfileState } from './state';

interface LeftMenuProps {
  user?: UserState | undefined;
  adviserProfileId?: string;
  sourceId?: string;
  record?: AdviserProfileState | null;
  adviserProfileIdEncrypted?: string | null;
  sourceIdEncrypted?: string | null;
}

const AdviserProfileLeftMenu: React.FC<LeftMenuProps> = ({
  user,
  adviserProfileId,
  sourceId,
  record,
  adviserProfileIdEncrypted,
  sourceIdEncrypted
}) => {
  const module_type = 'adviser-profile';
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const { id } = useParams(); // Get encrypted ID from URL
  // id = decryptData(id, true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();

  const taskUrl = `/adviser-profile/tasks?source=${sourceIdEncrypted}&adviserProfileId=${adviserProfileIdEncrypted}`;
  const notesUrl = `/adviser-profile/notes?source=${sourceIdEncrypted}&adviserProfileId=${adviserProfileIdEncrypted}`;
  const detailsUrl = `/adviser-profile/${adviserProfileIdEncrypted}?source=${sourceIdEncrypted}`;
  const [notesCount, setNotesCount] = useState<string>(
    localStorage.getItem('adviserProfileNotesCount') || '0'
  );

  const [tasksCount, setTaskCount] = useState<string>(
    localStorage.getItem('adviserProfileTasksCount') || '0'
  );

  const [adviserProfile, setAdviserProfile] = useState<UserState | null>(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      if (adviserProfileId && authToken) {
        const editId = parseInt(adviserProfileId!, 10);

        const response: UserState = (await fetchAdviserProfiles(
          authToken,
          selectedAccount,
          source_Id,
          editId
        )) as UserState;

        if (response) {
          localStorage.removeItem('adviser_name');
          localStorage.setItem('adviser_name', response.first_name + ' ' + response.last_name);

          setAdviserProfile(response);
        }
      }
    } catch (error) {
      // Handle any errors here
      console.log('Error: ', t('somethingWentWrong'));
    }
  };

  const fetchNotesCount = async () => {
    localStorage.removeItem('adviserProfileNotesCount');
    try {
      if (authToken && adviserProfileId) {
        const record_Id = parseInt(adviserProfileId, 10);

        const response: PaginationResponse = (await fetchNotes(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setNotesCount(response.total.toString());
            localStorage.setItem('adviserProfileNotesCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchTasksCount = async () => {
    localStorage.removeItem('adviserProfileTasksCount');
    try {
      if (authToken && adviserProfileId) {
        const record_Id = parseInt(adviserProfileId, 10);
        const response: PaginationResponse = (await fetchTasks(
          record_Id,
          source_Id,
          module_type,
          authToken,
          0,
          0
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTaskCount(response.total.toString());
            localStorage.setItem('adviserProfileTasksCount', response.total.toString());
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!record) {
      fetchData(); // Call the async function
    } else {
      setAdviserProfile(record);
    }
  }, [sourceId, record]);

  useEffect(() => {
    if (!localStorage.getItem('adviserProfileNotesCount')) {
      fetchNotesCount();
    }

    if (!localStorage.getItem('adviserProfileTasksCount')) {
      fetchTasksCount();
    }
  }, []);

  // URLs exclude from the menu
  const excludedUrls = [
    `/adviser-profile/${sourceIdEncrypted}`,
    `/adviser-profile/add-note`,
    `/adviser-profile/edit-note/${id}`,
    `/adviser-profile/add-task`,
    `/adviser-profile/authorisations`,
    `/adviser-profile/personal-details`,
    `/adviser-profile/edit-task/${id}`
  ];
  // Check if the current URL is in the excluded URLs list
  const isExcluded = excludedUrls.includes(location.pathname);

  let menuItems = [
    {
      icon: detail,
      iconActive: detaillActive,
      label: 'Details',
      path: [detailsUrl] // Define an array of paths
    },
    {
      icon: note,
      iconActive: noteActive,
      label: 'Notes',
      path: [notesUrl]
    },
    {
      icon: templatesIc,
      iconActive: templatesActive,
      label: 'Tasks',
      path: [taskUrl]
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Details') {
      return item;
    } else if (
      item.label === 'Notes' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), NOTE_READ)
    ) {
      return item;
    } else if (
      item.label === 'Tasks' &&
      checkUserPermission(user, MODULES, parseInt(sourceId!, 10), TASK_READ)
    ) {
      return item;
    }
  });
  return (
    <>
      {location.pathname !== `/adviser-profiles/${sourceIdEncrypted}` && (
        <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
          <div className="overlay" onClick={toggleSidebar}></div>
          <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
            <MenuRoundedIcon />
          </IconButton>
          <nav className="settings-sidebar">
            <div className="close-btn-wrap">
              <IconButton className="close-btn" onClick={toggleSidebar}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="sidebar-inner">
              <div className="comp-uder-detail">
                <div className="comp-uder-detail-list">
                  <h6>Adviser</h6>
                  {adviserProfile ? (
                    <p>
                      {adviserProfile.first_name} {adviserProfile.last_name}
                    </p>
                  ) : null}
                </div>
                <div className="comp-uder-detail-list">
                  <h6>Status</h6>
                  {adviserProfile && adviserProfile?.is_open ? (
                    <p>{adviserProfile?.is_open ? 'Active' : 'Inactive'}</p>
                  ) : null}
                </div>
              </div>
              {isExcluded ? null : (
                <ul className="menu-item-wrap">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item ${
                        Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (Array.isArray(item.path)) {
                          handleNavigation(item.path[0]);
                        }
                      }}>
                      <i className="icon">
                        <img
                          src={`${
                            Array.isArray(item.path) && item.path.includes(location.pathname)
                              ? item.iconActive
                              : item.icon
                          }`}
                          alt="Icon"
                        />
                      </i>
                      <span>{item.label}</span>
                      {(item.label == 'Notes' || item.label == 'Tasks') && (
                        <i className="icon-right">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="CurrentColor">
                            <rect width="24" height="24" rx="4" fill="#EAECF0" />
                            <text
                              className="text-count"
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fill="CurrentColor">
                              {item.label == 'Notes' ? notesCount : tasksCount}
                            </text>
                          </svg>
                        </i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default AdviserProfileLeftMenu;
