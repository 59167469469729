import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import LeftNav from '../LeftNav';
import { useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData
} from '../../../../utils/common/user';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import PaginationSettings from '../Pagination';
import CommonLayout from '../../layout/CommonLayout';
import { CREATE, READ, SETTINGS, UPDATE } from '../../../../constants/constants';
import edit from '../../../../assets/images/edit.svg';
import { fetchAddAccountsData } from '../../../../api/settings/fetchAddAccountsData';
import { AccountState } from '../../user/state';
import AccountFilters from './FilterBtn';

export default function AddAccountsIndex() {
  const title = 'Account Management';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const mainTitle = 'Settings';
  const mainPath = '/settings/add-accounts';
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [accounts, setAccounts] = useState<AccountState[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const [dense] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (property: keyof AccountState) => {
    console.log(property);
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    fetchData(property, isAsc ? 'desc' : 'asc');
  };

  useEffect(() => {
    // for check this page permission
    if (!checkUserPermission(user, SETTINGS, 9, READ)) {
      navigate('/dashboard');
    }
    setIsAccessable(true);
  }, []);

  useEffect(() => {
    fetchData(orderBy, order);
  }, [currentPage, search]);

  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      const response = await fetchAddAccountsData(
        currentPage,
        search,
        authToken,
        selectedAccount,
        undefined,
        orderBy,
        order
      );
      if (response.data && Array.isArray(response.data)) {
        setAccounts(response.data);
        const totalPages = response.last_page || 1;
        setTotalPages(totalPages);
      }
      setLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  const EditAccountData = (account: AccountState) => {
    navigate(`/settings/edit-account/${encryptData(String(account.id), true)}`);
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof AccountState;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'prefix',
      numeric: false,
      disablePadding: true,
      label: 'Prefix'
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: true,
      label: 'Color'
    }
  ];

  const addButton = (
    <>
      {checkUserPermission(user, SETTINGS, 2, CREATE) && (
        <Button className="btn primary-btn" component={RouterLink} to="/settings/add-account">
          <i className="left">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_6545_879)">
                <path
                  d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6545_879">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          Add Account
        </Button>
      )}
    </>
  );

  const isEditPermission = checkUserPermission(user, SETTINGS, 2, UPDATE);

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <div className="settings-outer-wrap">
            <CommonBreadcrumbs
              mainTitle={mainTitle}
              mainPath={mainPath}
              title={title}
              pageTitle={title}
              button={addButton}
            />
            <div className="settings-outer-row">
              <LeftNav />
              <div className="right-outer">
                <div className="right-inner">
                  <form>
                    <AccountFilters search={search} setSearch={setSearch} />
                    <div className="table-outer">
                      <TableContainer>
                        {loading ? (
                          <div className="no-record">
                            <CircularProgress />
                          </div>
                        ) : accounts.length === 0 ? (
                          <div className="no-record">No accounts found.</div>
                        ) : (
                          <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            className="table hover-table">
                            <TableHead>
                              <TableRow>
                                {headCells.map((headCell) => (
                                  <TableCell
                                    key={`key-${headCell.id}`}
                                    align={headCell.numeric ? 'left' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    <TableSortLabel
                                      active={orderBy === headCell.id}
                                      direction={orderBy === headCell.id ? order : 'asc'}
                                      onClick={() => handleSort(headCell.id)}>
                                      {headCell.label}
                                      {orderBy === headCell.id ? (
                                        <Box component="span" sx={{ display: 'none' }}>
                                          {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                        </Box>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(accounts) && accounts.length > 0
                                ? accounts.map((account) => (
                                    <TableRow key={account.id} hover>
                                      <TableCell align="left">
                                        <Grid container alignItems="center" flexWrap="nowrap">
                                          <Grid item sm={3}>
                                            {account.image ? (
                                              <Avatar
                                                src={`${JSON.parse(account.image).path}`}
                                                alt={account.name ? account.name : 'account'}
                                                onError={(e) => {
                                                  const target = e.target as HTMLImageElement;
                                                  target.style.display = 'none'; // Hide the image
                                                  const nextElement =
                                                    target.nextElementSibling as HTMLElement | null;
                                                  if (nextElement) {
                                                    nextElement.style.display = 'block'; // Show the <p> element with the user's name
                                                  }
                                                }}
                                              />
                                            ) : (
                                              <Avatar alt={account.name} src="." />
                                            )}
                                          </Grid>
                                          <Grid item sm={9} className="content">
                                            <p className="name">{account.name}</p>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell align="left">{account.prefix}</TableCell>
                                      <TableCell align="left">
                                        {' '}
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: account.color,
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            marginRight: '8px'
                                          }}></div>
                                        {account.color ? account.color : '-'}
                                      </TableCell>
                                      {isEditPermission && (
                                        <TableCell align="left">
                                          <div className="table-btn">
                                            <Button
                                              className="edit"
                                              onClick={() => EditAccountData(account)}>
                                              <img src={edit} alt="edit" />
                                            </Button>
                                          </div>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))
                                : null}
                            </TableBody>
                          </Table>
                        )}
                        {!loading && accounts.length > 0 && (
                          <PaginationSettings
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                          />
                        )}
                      </TableContainer>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </CommonLayout>
      )}
    </>
  );
}
