import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyToken } from '../../../api/user/verifyToken';
import { useDispatch } from 'react-redux';
import {
  checkUserIsNewEntrant,
  decryptData,
  setSelectedAccountInfo
} from '../../../utils/common/user';
import { setAccounts } from '../../../store/account/accountSlice';
import { updateloginInfoAccount } from '../../../api/user/updateloginInfoAccount';

const LoginCallback: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');

    if (accessToken) {
      console.log(urlParams.get('access_token'));
      handleTokenVerification(accessToken);
    } else {
      console.error('Access token not found in the URL');
      navigate('/login');
    }
  }, [navigate]);

  const handleTokenVerification = async (token: string) => {
    try {
      const errorMessage = await verifyToken(dispatch, token);

      if (!errorMessage) {
        const userData = decryptData(localStorage.getItem('userData'))?.userInfo;
        const authToken = decryptData(localStorage.getItem('userData'))?.token;

        if (authToken && (window as any)?.Outseta) {
          (window as any).Outseta.setAccessToken(authToken);
        }

        if (userData?.userAccounts?.length == 1) {
          // if user has only single account access redirect to dashboard
          const userAccountData = userData?.user_access[0]?.account;

          dispatch(setAccounts(userAccountData));
          setSelectedAccountInfo(userAccountData.id, [userAccountData]);
          updateloginInfoAccount(userAccountData.id, authToken);

          if (!checkUserIsNewEntrant(userData)) {
            navigate('/dashboard');
          } else {
            navigate('/new-entrant');
          }
        } else {
          // if user has only single account access redirect to dashboard
          const userAccountData = userData?.selectedAccountFromOutseta;

          dispatch(setAccounts(userAccountData));
          setSelectedAccountInfo(userAccountData.id, [userAccountData]);
          updateloginInfoAccount(userAccountData.id, authToken);

          navigate('/dashboard');

          // navigate to the AccountSelection component
          // navigate('/account-selection');
        }
      } else {
        console.error('Token verification failed:', errorMessage);
        navigate('/login'); // Redirect to login if verification fails
      }
    } catch (error) {
      // Log any unexpected errors during token verification
      console.error('Error verifying token:', error);
      navigate('/login'); // Redirect to login if verification fails
    }
  };

  return null; // Invisible component, renders nothing
};

export default LoginCallback;
