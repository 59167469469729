import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';

export const updateLogoutInfo = async (authToken?: string): Promise<string | undefined | null> => {
  let routeUrl;
  try {
    const formData = new FormData(); // Create a FormData object
    routeUrl = `${process.env.REACT_APP_API_URL}/logout`;
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return;
    } else {
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
