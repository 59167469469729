import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  CircularProgress,
  Button,
  Avatar,
  Box
} from '@mui/material';
import { format, isAfter } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import {
  useAuthToken,
  useCurrentUserInfo,
  checkUserPermission,
  encryptData
} from '../../../utils/common/user';
import PaginationSettings from '../settings/Pagination';
import { fetchTasks } from '../../../api/notes-tasks/fetchTasks';
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PaginationResponse, TasksState } from '../user/state';
import { MODULES, REGISTERS, TASK_UPDATE } from '../../../constants/constants';
import { handleSortTasks } from '../../../utils/sortingUtils';
import edit from '../../../assets/images/edit.svg';
import { TasksIndexProps } from './state';

const Tasks: React.FC<TasksIndexProps> = ({
  moduleType,
  sourceId,
  recordId,
  localKey,
  fetchModuleData,
  editUrl
}) => {
  const authToken = useAuthToken();
  const user = useCurrentUserInfo();
  const [tasks, setTasks] = useState<TasksState[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState<keyof TasksState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [dense] = useState(false);
  const review_id = parseInt(recordId!, 10);
  const source_id = parseInt(sourceId!, 10);

  const handleRowToggle = (rowId: number) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
    // Set the currently open row
    if (openRow === rowId) {
      setOpenRow(null); // If the same row was clicked again, close it
    } else {
      setOpenRow(rowId);
    }
  };
  const fetchData = async (orderBy: string, order: 'asc' | 'desc') => {
    try {
      if (authToken && recordId) {
        const response: PaginationResponse = (await fetchTasks(
          review_id,
          source_id,
          moduleType,
          authToken,
          0,
          currentPage,
          undefined,
          orderBy,
          order
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTasks(response.data);
          }
          setTotalPages(response.last_page);
          setLoading(false); // Set loading to false when data is fetched
        }
      }
    } catch (error) {
      // Handle any errors here
      setLoading(false); // Set loading to false on error too
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const localNotExists = localStorage.getItem(localKey);
    fetchData(orderBy, order); // Call the async function

    if (localNotExists === null) {
      fetchModuleData();
    }
  }, [recordId, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof TasksState;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'date_due',
      numeric: false,
      disablePadding: true,
      label: 'Due Date'
    },
    {
      id: 'task',
      numeric: false,
      disablePadding: true,
      label: 'Task'
    },
    {
      id: 'owner',
      numeric: false,
      disablePadding: true,
      label: 'Owner'
    },
    {
      id: 'is_complete',
      numeric: false,
      disablePadding: true,
      label: 'Status'
    }
  ];

  const handleEdit = (rowId: number): string => {
    return editUrl.replace('rowId', encryptData(String(rowId), true)); // Ensure it returns a string URL
  };

  return (
    <>
      <div className="right-outer">
        <div className="table-outer">
          <TableContainer>
            {loading ? ( // Check loading state
              <div className="no-record">
                <CircularProgress />
              </div>
            ) : tasks.length === 0 ? (
              <div className="no-record">No tasks found.</div>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                className="table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() =>
                            handleSortTasks({
                              property: 'id', // Using correct type
                              orderBy,
                              order,
                              setOrderBy,
                              setOrder,
                              fetchData
                            })
                          }>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={{ display: 'none' }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((row: TasksState) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        key={row.id}
                        id={`main-col-${row.id}`}
                        className={openRow === row.id ? 'collapse' : ''}>
                        {row.date_due && !row.is_complete ? (
                          <TableCell
                            align="left"
                            className={
                              isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                                ? 'overdue-status'
                                : 'other-status'
                            }>
                            {row.date_due ? format(new Date(row.date_due), 'd/MMM/yyyy') : 'N/A'}
                          </TableCell>
                        ) : row.is_complete ? (
                          <TableCell align="left">-</TableCell>
                        ) : (
                          <TableCell align="left">N/A</TableCell>
                        )}
                        <TableCell align="left">{row.task}</TableCell>
                        <TableCell align="left">
                          <div className="user-img-wrap">
                            {row.user && row.user.profile_image ? (
                              <Avatar
                                src={`${JSON.parse(row.user.profile_image).path}`}
                                alt={row.user.first_name ? row.user.first_name : 'user'}
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.style.display = 'none';
                                  const nextElement =
                                    target.nextElementSibling as HTMLElement | null;
                                  if (nextElement) {
                                    nextElement.style.display = 'block';
                                  }
                                }}
                              />
                            ) : (
                              <Avatar alt={row.user ? row.user.first_name : ''} src="." />
                            )}
                            {row.user ? (
                              <p className="user-name">
                                {row.user.first_name + ' ' + row.user.last_name}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </TableCell>
                        {row.date_due ? (
                          <TableCell
                            align="left"
                            className={
                              isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                                ? 'overdue'
                                : row.is_complete > 0
                                  ? 'completed'
                                  : 'opened'
                            }>
                            <span>
                              {isAfter(new Date(), new Date(row.date_due)) && !row.is_complete
                                ? 'Overdue'
                                : row.is_complete > 0
                                  ? 'Completed'
                                  : 'Open'}
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell align="left">Open</TableCell>
                        )}
                        <TableCell align="left">
                          <div className="collapse-btn-wrapper">
                            {(moduleType != 'providers' && moduleType != 'register_records'
                              ? checkUserPermission(user, MODULES, source_id, TASK_UPDATE)
                              : checkUserPermission(user, REGISTERS, source_id, TASK_UPDATE)) && (
                              <Button
                                component={RouterLink}
                                to={handleEdit(row.id)} // Return a string
                                className="edit table-inner-btn">
                                <img src={edit} alt="Right Arrow" />
                              </Button>
                            )}
                            {row.details && row.details.trim() !== '' ? (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleRowToggle(row.id)}>
                                {openRows[row.id] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            ) : (
                              <IconButton></IconButton>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                      {row.details && row.details.trim() !== '' && (
                        <TableRow className="collapse-open" key={`collapse-${row.id}`}>
                          <TableCell colSpan={5}>
                            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                              <div className="mb-2 mt-2">
                                Details: {(row.details || '').replace(/<br\/>/g, '\n')}
                              </div>
                              {row.status_updates && row.status_updates.trim() !== '' && (
                                <div>Status Updates: {row.status_updates}</div>
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
            {!loading && tasks.length > 0 && (
              <PaginationSettings
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Tasks;
