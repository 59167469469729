import { ModulesState } from '../../views/components/user/state';
import { Dispatch } from 'redux';
import { setModules } from '../../store/modules/moduleSlice';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { AccountState } from '../../views/components/user/state';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const fetchModules = async (
  dispatch: Dispatch,
  accountInfo?: AccountState,
  authToken?: string,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<ModulesState | string | undefined> => {
  try {
    const selectedAccountId = accountInfo?.id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/modules?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&orderBy=${orderBy}&order=${order}`, // Update the API endpoint as needed
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    );

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      dispatch(setModules(responseData.data));
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching modules';
  }
};
