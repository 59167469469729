import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ReviewsDataFormState } from './state';
import { getSelectedAccountInfo, useAuthToken } from '../../../../utils/common/user';
import { generatePDFDirect } from '../../../../api/adviser/generatePDFDirect';

interface DraftPDFGeneratorProps {
  formData?: ReviewsDataFormState;
  from?: string;
}

const DraftPDFGenerator: React.FC<DraftPDFGeneratorProps> = ({ formData, from }) => {
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(false);

  const handleGeneratePDF = async () => {
    const reviewId = formData?.id; // Assuming review_id is part of formData
    const emailSwitch = 0; // or 1, depending on your needs
    const taskContent = null;
    const addWatermark = true;
    const generateDraft = true;
    setLoading(true);

    if (authToken && selectedAccount && reviewId) {
      const response = await generatePDFDirect(
        authToken,
        selectedAccount,
        reviewId,
        emailSwitch,
        taskContent,
        addWatermark,
        generateDraft
      );

      if (response) {
        window.open(response, '_blank');
      } else {
        console.error('Failed to generate PDF');
      }
      setLoading(false);
    } else {
      setLoading(false);
      console.error('Missing required parameters');
    }
  };

  if (loading) {
    return (
      <div className="no-record">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Button
        onClick={() => handleGeneratePDF()}
        className={from === 'adviser-review' ? 'btn primary-btn' : 'btn blue-fill-btn'}>
        <i className="left">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_6545_879)">
              <path
                d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6545_879">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </i>
        View Draft
      </Button>
    </>
  );
};
export default DraftPDFGenerator;
