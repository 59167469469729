import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, Alert, CircularProgress } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { forgotPassword } from '../../../api/user/forgotPassword';
import CommonLayout from '../layout/CommonLayout';
import { t } from 'i18next';
import { S3PublicUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const title = 'Forgot Password';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  const url = new URL(window.location.href);
  const token = url.searchParams.get('token') || null;
  const email = url.searchParams.get('email') || null;
  const type = url.searchParams.get('type') || '';

  useEffect(() => {
    if (!token || !email) {
      navigate('/error');
    }
  }, [token, email, navigate]);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    error: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validateForm = (): boolean => {
    const { password, confirmPassword } = formData;
    let isValid = true;

    const commonPhrases = ['password', '123456', 'qwerty', 'letmein', 'welcome', 'admin', 'test'];
    const sequentialPattern =
      /^(?:0123|1234|2345|3456|4567|5678|6789|7890|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)$/i;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$])[A-Za-z\d!@#$]{12,}$/;

    const newErrors: Record<string, string> = {};

    if (!password) {
      newErrors.passwordError = 'The new password is required.';
      isValid = false;
    } else if (!passwordPattern.test(password)) {
      newErrors.passwordError =
        'The new password must be at least 12 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters (!@#$).';
      isValid = false;
    } else if (commonPhrases.some((phrase) => password.toLowerCase().includes(phrase))) {
      newErrors.passwordError = 'The new password should not contain common phrases.';
      isValid = false;
    } else if (sequentialPattern.test(password)) {
      newErrors.passwordError = 'The new password should not contain sequential characters.';
      isValid = false;
    } else {
      newErrors.passwordError = '';
    }

    if (!confirmPassword) {
      newErrors.confirmPasswordError = 'Confirm password is required.';
      isValid = false;
    } else if (password !== confirmPassword) {
      newErrors.confirmPasswordError = 'The confirm password must match the new password.';
      isValid = false;
    } else {
      newErrors.confirmPasswordError = '';
    }

    setFormData((prevData) => ({
      ...prevData,
      ...newErrors
    }));

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    const isFormValid = validateForm();

    if (!isFormValid) {
      setLoading(false);
      return;
    }

    const errorMessage = await forgotPassword(
      {
        password: formData.password,
        token: token || undefined,
        email: email ? atob(email) : undefined
      },
      type
    );

    if (errorMessage) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: '',
        confirmPasswordError: '',
        error: errorMessage
      }));
      setLoading(false);
    } else {
      if (type) {
        navigate(`/forgot-password-success?type=${type}`);
      } else {
        navigate('/forgot-password-success');
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              {type ? (
                <Link to="/onboarding-login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              ) : (
                <Link to="/login" className="back-link">
                  <ArrowBackRoundedIcon />
                  {t('back')}
                </Link>
              )}
              <div className="title-wrap">
                <h1>Set new password</h1>
                <p>Your new password must be different to previously used passwords.</p>
              </div>
              {/* Error handling */}
              {formData.error && (
                <Alert variant="outlined" severity="error" onClose={() => {}}>
                  <div>{formData.error}</div>
                </Alert>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="New Password">
                    New Password
                  </label>
                  <TextField
                    error={!!formData.passwordError || !!formData.confirmPasswordError}
                    id="password"
                    variant="outlined"
                    name="password"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your password"
                    type={showPassword ? 'text' : 'password'}
                    helperText={formData.passwordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="Confirm Password">
                    Confirm Password
                  </label>
                  <TextField
                    error={!!formData.passwordError || !!formData.confirmPasswordError}
                    id="confirmPassword"
                    variant="outlined"
                    name="confirmPassword"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your password"
                    helperText={formData.confirmPasswordError}
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  className="btn primary-btn"
                  disabled={loading}>
                  {loading ? (
                    <CircularProgress size={20} style={{ color: '#ffffff' }} />
                  ) : (
                    ' Reset password'
                  )}
                </Button>
              </form>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-reset-password.png`}
                  alt=""
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default ForgotPasswordForm;
