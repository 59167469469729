import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import './i18n/config';
import { ForgotPasswordProvider } from './views/components/user/ForgotPasswordContext';
import store from './store/store';
import { Provider } from 'react-redux';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ForgotPasswordProvider>
        <App />
      </ForgotPasswordProvider>
    </Provider>
  </React.StrictMode>
);
