import axios from 'axios';
import { OnboardingAuthorisationsState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';

export const createOnboardingAuthorisationsData = async (
  form_data: any,
  onboardingId: string | number,
  templateId: string | number,
  authToken: string,
  dataId?: number | string | null,
  userId?: number | string
): Promise<OnboardingAuthorisationsState | string | number | undefined> => {
  try {
    const formData = new FormData(); // Create a FormData object
    const { proceed_to_checklist, reason } = form_data;

    if (!proceed_to_checklist && !reason) {
      formData.append('form_data', JSON.stringify(form_data));
      formData.append('onboarding_authorisations_template_id', templateId.toString());
    } else {
      formData.append('proceed_to_checklist', proceed_to_checklist.toString());
      formData.append('reason', reason);
    }
    formData.append('onboarding_id', onboardingId.toString());
    if (userId) {
      formData.append('user_id', userId.toString());
    }

    let routeUrl;
    if (dataId) {
      formData.append('_method', 'put');
      routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-authorisations-template/${dataEncrypt(dataId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/onboarding-authorisations-template`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data?.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
