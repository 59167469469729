export const localStorageKeys = {
  USER_DATA: 'userData',
  USER_TOKEN: 'user_token',
  USER_ACCOUNTS: 'user_accounts',
  SELECTED_ACCOUNT: 'selected_account',
  USER_INFO: 'userInfo',
  IS_SCRIBE: 'isScribe',
  SURVEY_JSON_ONBOARDING_APPLICATION: 'survey-json-onboarding-application',
  SURVEY_JSON_ONBOARDING_ASSESSMENT: 'survey-json-onboarding-assessment',
  SURVEY_JSON_ONBOARDING_CHECKLIST: 'survey-json-onboarding-checklist',
  SURVEY_JSON_ADVISERPROILE_PERSONALDETAILS: 'survey-json-adviser-profile-personal-details',
  SURVEY_JSON_ADVISERPROILE_AUTHORISATIONS: 'survey-json-adviser-profile-authorisations',
  SURVEY_JSON_ATTESTATION: 'survey-json-attestation',
  SURVEY_JSON_PROFESSIONALYEAR_PLAN_SUPERVISOR: 'survey-json-professionalyear-plan-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_Q1_SUPERVISOR: 'survey-json-professionalyear-q1-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_Q1_ENTRANT: 'survey-json-professionalyear-q1-entrant',
  SURVEY_JSON_PROFESSIONALYEAR_Q2_SUPERVISOR: 'survey-json-professionalyear-q2-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_Q2_ENTRANT: 'survey-json-professionalyear-q2-entrant',
  SURVEY_JSON_PROFESSIONALYEAR_EXAM_SUPERVISOR: 'survey-json-professionalyear-exam-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_EXAM_ENTRANT: 'survey-json-professionalyear-exam-entrant',
  SURVEY_JSON_PROFESSIONALYEAR_Q3_SUPERVISOR: 'survey-json-professionalyear-q3-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_Q3_ENTRANT: 'survey-json-professionalyear-q3-entrant',
  SURVEY_JSON_PROFESSIONALYEAR_Q4_SUPERVISOR: 'survey-json-professionalyear-q4-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_Q4_ENTRANT: 'survey-json-professionalyear-q4-entrant',
  SURVEY_JSON_PROFESSIONALYEAR_COMPLETION_SUPERVISOR: 'survey-json-professionalyear-completion-supervisor',
  SURVEY_JSON_PROFESSIONALYEAR_COMPLETION_LICENSEE: 'survey-json-professionalyear-completion-licensee',
  SURVEY_JSON_PROFESSIONALYEAR_LOGBOOK_WORKACTIVITY: 'survey-json-professionalyear-logbook-workactivity',
  SURVEY_JSON_PROFESSIONALYEAR_LOG_TRAINING: 'survey-json-professionalyear-log-training'
} as const;

export const clearLocalStorage = () => {
  Object.values(localStorageKeys).forEach((k: string) => {
    localStorage.removeItem(k);
  });
};
