import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { FinalizationReviewState } from '../../views/components/user/state';

export const fetchFinaliseReviewListing = async (
  authToken?: string,
  reviewId?: number,
): Promise<FinalizationReviewState | FinalizationReviewState[] | string | undefined> => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/finalise-reviews-list/${dataEncrypt(reviewId?.toString() ?? '0')}`;

  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData?.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching adviser reviews';
  }
};
