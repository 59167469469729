import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getSelectedAccountInfo, Logout } from '../utils/common/user';
import { useNavigate } from 'react-router-dom';

interface LoginCheckProps {
  children: ReactNode;
}

const LoginCheck: React.FC<LoginCheckProps> = ({ children }) => {
  const navigate = useNavigate();
  const selectedAccount = getSelectedAccountInfo();

  const isAuthenticated = localStorage.getItem('userData');

  // const navigate = useNavigate();
  const location = useLocation(); // Access location using useLocation

  useEffect(() => {
    if (location.pathname === '/onboarding-login') {
      if (isAuthenticated) {
        Logout();
      }
    } else if (isAuthenticated) {
      if (selectedAccount) {
        navigate('/dashboard');
      } else {
        navigate('/account-selection');
      }
    }
  }, []);

  return <>{children}</>;
};

export default LoginCheck;
