import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export async function fetchUserData(
  currentPage: number,
  search: string,
  selectedStatus: string,
  authToken?: string,
  accountInfo?: AccountState,
  userId?: number,
  orderBy: string = 'id',
  order: string = 'desc'
) {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  if (!userId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/users?per_page=10&page=${currentPage}&search=${search}&status=${selectedStatus}&account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/users/${dataEncrypt(userId?.toString())}?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    const data: ApiResponseType = await response.json();

    if (data.success) {
      return data.data;
    } else {
      tokenCheck(data.status);
      throw new ApiResponse(data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage;
  }
}
