import axios from 'axios';
import { AccountDetailsState } from '../../views/components/settings/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { tokenCheck, dataEncrypt } from '../../utils/common/user';

export const createAddAccount = async (
  formData: AccountDetailsState,
  id?: number | string,
  authToken?: string
): Promise<string | undefined | null> => {
  const currentId = id;
  const {
    name,
    prefix,
    color,
    image,
    is_bespoke,
    compliance_query_email,
    users_associated
    /*users_adviser*/
  } = formData;
  let routeUrl;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('name', name);
    formData.append('prefix', prefix || '');
    formData.append('color', color || '');
    formData.append('users_associated', JSON.stringify(users_associated) || '[]');
    // formData.append('users_adviser', JSON.stringify(users_adviser) || '[]');

    if (is_bespoke) {
      formData.append('is_bespoke', is_bespoke ? '1' : '0');
    }
    if (compliance_query_email) {
      formData.append('compliance_query_email', compliance_query_email);
    }

    if (image) {
      formData.append('logo', image); // Attach the logo as a Blob
    }
    if (currentId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/add-accounts/${dataEncrypt(currentId?.toString())}/update`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/add-accounts/create`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      return;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
