import axios from "axios";
import { dataEncrypt, tokenCheck } from "../../utils/common/user";
import { ApiResponse, ApiResponseType } from "../apiResponse";

export const convertToEnterpriseAdviserProfile = async (
  source_id: number,
  accountId: number,
  authToken: string,
  type: string,
  templateId: number,
) => {
  const routeUrl = `${process.env.REACT_APP_API_URL}/adviser-profile-template/convert-to-enterprise?sourceId=${dataEncrypt(source_id?.toString())}&accountId=${dataEncrypt(accountId?.toString())}&type=${type}&templateId=${dataEncrypt(templateId?.toString())}`;

  const formData = new FormData();
  formData.append('_method', 'PUT');
  try {
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      tokenCheck(response.data.status);
      throw new ApiResponse(response.data);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching meetings';
  }
}