import React from 'react';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';

const PageNotFound = () => {
  return (
    <div className="error-container">
      <div className="error-container-inner">
        <div className="img-wrap">
          <LazyImage src={`${S3PublicUrl}error-page-img.jpg`} alt="Error" />
        </div>
        <div className="content-wrap">
          <h1>404 - Page Not Found</h1>
          <p>The page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
