import { PaginationResponse, RegistersState } from '../../views/components/user/state';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';

export const fetchRegisters = async (
  authToken?: string,
  accountInfo?: AccountState,
  page: number = 1, // Specify the page number, default to 1
  itemsPerPage: number = 10, // Specify the items per page, default to 10
  registerId?: number,
  orderBy: string = 'id',
  order: string = 'desc'
): Promise<PaginationResponse | RegistersState | string | undefined> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  if (!registerId) {
    routeUrl = `${process.env.REACT_APP_API_URL}/registers?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}&page=${page}&perPage=${itemsPerPage}&orderBy=${orderBy}&order=${order}`; // Include pagination parameters
  } else {
    routeUrl = `${process.env.REACT_APP_API_URL}/registers/${dataEncrypt(registerId?.toString())}?account_id=${dataEncrypt(selectedAccountId?.toString() ?? '0')}`;
  }
  try {
    const response = await fetch(routeUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const responseData: ApiResponseType = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      tokenCheck(responseData.status);
      throw new ApiResponse(responseData);
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;
    return errorMessage || 'An error occurred while fetching registers';
  }
};
