import React, { useState, useEffect } from 'react';
import { useAuthToken } from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { PresignedUrl } from '../../../../utils/common/surveyjs/surveyFilePresignedUrl';

// Define TypeScript types or interfaces for surveyData and formData
type SurveyData = {
  title: string;
  description: string;
  pages: {
    name: string;
    elements: Element[];
    title: string;
    description: string;
  }[];
};

type Element = {
  type: any;
  name: string;
  title: string;
  inputType?: string;
  choices?: Choices[];
  choicesByUrl?: ChoicesByUrl;
  startWithNewLine?: boolean;
  items?: Items[];
};

type Items = {
  name: string;
  title?: string;
};

type Choices = {
  id?: number;
  value: string;
  name?: string;
  type?: string;
  text?: string;
  imageLink?: string;
};

type ChoicesByUrl = {
  titleName: string;
  url: string;
  valueName?: string;
};

type FormData = {
  [key: string]: string | string[];
};

interface PrevetSurveyTextModeProps {
  surveyData: SurveyData;
  formData: FormData;
  edit_btn: JSX.Element | null;
}

const PrevetSurveyTextMode: React.FC<PrevetSurveyTextModeProps> = ({
  surveyData,
  formData,
  edit_btn
}) => {
  const [dynamicChoicesData, setDynamicChoicesData] = useState<{ [key: string]: Choices[] }>({});
  const authToken = useAuthToken();

  const fetchDynamicChoicesData = async () => {
    const choicesByUrlElements = surveyData?.pages
      ?.flatMap((page) => page.elements)
      .filter((element) => element.type === 'dropdown' && element.choicesByUrl);

    if (choicesByUrlElements) {
      try {
        const fetchPromises = choicesByUrlElements.map(async (element) => {
          const choicesByUrlArray = element.choicesByUrl;
          if (choicesByUrlArray) {
            let url = choicesByUrlArray.url;

            // Replace placeholders like {complaint_category} with actual values from formData
            Object.keys(formData).forEach((key) => {
              const placeholder = `{${key}}`;
              if (url.includes(placeholder)) {
                url = url.replace(placeholder, formData[key] as string);
              }
            });

            const response = await fetch(url, {
              headers: {
                Authorization: `Bearer ${authToken}`
              }
            });
            const data = await response.json();
            setDynamicChoicesData((prevData) => ({
              ...prevData,
              [element.name]: data
            }));
          }
        });

        await Promise.all(fetchPromises);
      } catch (error) {
        console.error('Error fetching dynamic choices data:', error);
      }
    }
  };

  const renderFormElement = (element: Element, value: string | string[] | boolean) => {
    let content; // Declare variable outside the switch block
    switch (element.type) {
      case 'dropdown':
        {
          const choices = dynamicChoicesData[element.name] || element.choices || [];
          let selectedChoice: any = '';
          if (dynamicChoicesData[element.name]) {
            selectedChoice = choices.find((choice) => {
              return choice.id && choice.id.toString() == value;
            });
            content = (
              <div className="dropdown-container">
                <p className="selected-choice-text">
                  {selectedChoice ? selectedChoice.name : value}
                </p>
              </div>
            );
          } else {
            selectedChoice = choices.find((choice) => choice.value === value);

            content = (
              <div className="dropdown-container">
                <p className="selected-choice-text">
                  {selectedChoice ? selectedChoice.text : value}
                </p>
              </div>
            );
          }
        }
        break;
      case 'tagbox':
      case 'checkbox':
        {
          const choices = element.choices || [];
          const selectedValues = Array.isArray(value) ? value : [value];

          const selectedTexts = choices
            .filter((choice) => selectedValues.includes(choice.value))
            .map((choice) => choice.text);

          content = (
            <div className="tagbox-container">
              {selectedTexts.map((text, index) => (
                <p key={index} className="selected-tagbox-item">
                  {text}
                </p>
              ))}
            </div>
          );
        }
        break;
      case 'ranking':
        {
          const choices = element.choices || [];
          const selectedValues = Array.isArray(value) ? value : [value];

          content = (
            <div className="ranking-container">
              {selectedValues.map((selectedValue, index) => {
                const selectedChoice = choices.find((choice) => choice.value === selectedValue);
                const text = selectedChoice ? selectedChoice.text : '';

                return (
                  <div key={index} className="ranking-item">
                    <p className="selected-ranking-text">{text}</p>
                    {/* You can add additional styling or elements for each ranking item */}
                  </div>
                );
              })}
            </div>
          );
        }
        break;
      case 'boolean':
        {
          content = <p className="form-control">{value ? 'Yes' : 'No'}</p>;
        }
        break;
      case 'file':
        {
          content =
            Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' ? (
              <>
                {value.map((file: any, index: number) => (
                <p onClick={() => PresignedUrl(file.content, authToken)} 
                  className="form-control edit-task-arrow-home"
                  key={index} >
                  {file.name}
                </p>
              ))}
              </>
            ) : (
              <p className="form-control">{value}</p>
            );
        }
        break;
      case 'signaturepad':
        {
          content = <img src={value as string} alt="Signature" />;
        }
        break;
      case 'imagepicker':
        {
          const choices = element.choices || [];
          const selectedImages = Array.isArray(value) ? value : [value];
          content = (
            <div className="image-picker-container">
              {choices.map((choice, index) => (
                <div
                  key={index}
                  className={`image-picker-item ${
                    selectedImages.includes(choice.value) ? 'selected' : ''
                  }`}>
                  {selectedImages.includes(choice.value) && (
                    <>
                      <img src={choice?.imageLink} alt={choice.value} />
                      <p>{choice.value}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          );
        }

        break;
      case 'multipletext':
        {
          const items = element.items || [];

          content = (
            <div className="multiple-text-container">
              {items.map((item, index) => {
                const itemName: string = item.name;
                const itemTitle = item.title;

                const itemValue =
                  itemName && typeof value === 'object' && value !== null
                    ? String(value[itemName as keyof typeof value])
                    : String(value);

                return (
                  <div key={index} className="">
                    <p className="item-title">{itemTitle}</p>
                    <p className="item-value">{itemValue}</p>
                  </div>
                );
              })}
            </div>
          );
        }
        break;

      case 'matrix':
        {
          content = <div className="matrix"></div>;
        }
        break;
      default:
        {
          if (element.inputType && element.inputType === 'date') {
            let dateValue: Date;

            // Convert 'string' or 'number' to 'Date' if possible
            if (typeof value === 'string' || typeof value === 'number') {
              dateValue = new Date(value);
            } else if (value instanceof Date) {
              dateValue = value;
            } else {
              // Handle other cases or throw an error based on your requirements
              throw new Error('Invalid date format');
            }

            content = <p className="form-control">{format(dateValue, 'd/MMM/yyyy')}</p>;
          } else {
            // If it's not a Date, string, or number, render 'value' as is
            content = <p className="form-control">{value}</p>;
          }
        }
        break;
    }

    return content;
  };

  useEffect(() => {
    // Fetch dynamic choices data when the component mounts
    fetchDynamicChoicesData();
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <div className="card-title-wrap secondary-title-wrap">
        <div>
          <h3> {t('generalObservation')}</h3>
        </div>
        <div className="inner-btn-wrap">{edit_btn}</div>
      </div>
      <div className="row-wrap">
        {surveyData?.title && (
          <div className="inner-wrap-title ">
            <h5 className="title-padding">{surveyData?.title}</h5>
          </div>
        )}
        <div className="form-group w-full">
          {surveyData &&
            surveyData?.pages?.map((page) => (
              <div key={page.name} className="row-wrap">
                {page.elements
                  .filter((element) => formData[element.name] !== undefined)
                  .map((element) => (
                    <div className={`form-group w-full`} key={element.name}>
                      <label className="form-label">
                        {element.title ? element.title : element.name}
                      </label>
                      {renderFormElement(element, formData[element.name])}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default PrevetSurveyTextMode;
