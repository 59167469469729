import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  Table,
  TableBody,
  Switch,
  Checkbox,
  Button,
  CircularProgress
} from '@mui/material';
import { fetchFinaliseReviewListing } from '../../../../api/adviser/fetchFinaliseReviewListing';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { FinalizationReviewState } from '../../user/state';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import ReviewLeftMenu from './ReviewLeftMenu';
import 'survey-core/defaultV2.min.css';
import CommonLayout from '../../layout/CommonLayout';
import { useTranslation } from 'react-i18next';
import { ReviewState } from '../../user/state';
import { fetchAdviserReviews } from '../../../../api/adviser/fetchAdviserReviews';
import { skipFinalisation } from '../../../../api/adviser/skipFinalisation';
import { useNotificationContext } from '../../layout/NotificationContext';
import { formattedContent } from '../../../../utils/commonUtils';

const FinaliseReviewIndex: React.FC = () => {
  const user = useCurrentUserInfo();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const [reviews, setReviews] = useState<FinalizationReviewState[]>([]);
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const { t } = useTranslation();
  const [dense] = useState(false);
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = decryptData(id, true);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const mainPath = `/reviews/${encryptedSourceId}`;
  const pageTitle = 'Finalise Adviser Review';
  const secondTitle = 'Adviser Reviews';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;
  const [orderBy, setOrderBy] = useState<keyof FinalizationReviewState>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const { setNotificationSuccess } = useNotificationContext();
  const [finalizeBtn, setFinalizeBtn] = useState('Create');
  const [emailSwitch, setEmailSwitch] = useState(false);
  const [breach, setBreach] = useState(false);
  const [taskArray, setTaskArray] = useState<FinalizationReviewState[]>([]);
  const [breachArray, setBreachArray] = useState<FinalizationReviewState[]>([]);

  const [loading, setLoading] = useState(false);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof FinalizationReviewState;
    label: string;
    numeric: boolean;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'client',
      numeric: false,
      disablePadding: true,
      label: 'Client'
    },
    {
      id: 'limitation',
      numeric: false,
      disablePadding: true,
      label: 'Limitation'
    },
    {
      id: 'create_task',
      numeric: false,
      disablePadding: false,
      label: 'Create Task '
    },
    {
      id: 'create_breach',
      numeric: false,
      disablePadding: false,
      label: 'Create Breach'
    }
  ];

  const handleEmailSwitch = () => {
    setEmailSwitch((prevSwitch) => {
      const newSwitch = !prevSwitch;

      if (newSwitch) {
        setFinalizeBtn('Create and send');
      } else {
        setFinalizeBtn('Create');
      }

      return newSwitch;
    });
  };

  const handleSort = (property: keyof FinalizationReviewState) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');

    // Sort the registers array based on the selected criteria
    const sortedRegisters = [...reviews].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      // Handle sorting for numbers and strings separately
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return isAsc ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0;
      }
    });
    setReviews(sortedRegisters);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const editId = parseInt(id!, 10);
        const response = await fetchFinaliseReviewListing(authToken, editId);
        if (response && Array.isArray(response)) {
          const transformedReviews: FinalizationReviewState[] = response.map((item) => ({
            id: item.id,
            client: item.client,
            limitation: item.limitation,
            limitation_answer: item.limitation_answer,
            action: item.action,
            comment: item.comment,
            guidance: item.guidance
          }));
          setReviews(transformedReviews);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchData();
    const review_unique_id = localStorage.getItem('review_unique_id');
    if (review_unique_id) {
      fetchReviewData();
    }
  }, []);

  const fetchReviewData = async () => {
    try {
      if (id && authToken) {
        const editId = parseInt(id!, 10);
        const response: ReviewState = (await fetchAdviserReviews(
          0,
          authToken,
          selectedAccount,
          editId
        )) as ReviewState;

        if (response) {
          const { unique_id, finalised, breach } = response;
          setBreach(breach ? breach : false);
          if (finalised) {
            navigate(`/review/${encryptedId}?source=${encryptedSourceId}`);
          }
          localStorage.removeItem('review_unique_id');
          localStorage.setItem('review_unique_id', unique_id);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      /* empty */
    }
  };

  const handleSkipbutton = async () => {
    try {
      setLoading(true);
      const source_id = parseInt(sourceId!, 10);

      const response = await skipFinalisation(
        authToken,
        selectedAccount,
        id,
        source_id,
        emailSwitch,
        taskArray,
        breachArray
      );
      if (!response) {
        setLoading(false);
        navigate(`/review/${encryptedId}?source=${encryptedSourceId}`);
        const msg = t('success.field.reviewFinalised');
        localStorage.removeItem('reviewTasksCount');
        setNotificationSuccess(msg);
      }
    } catch (error) {
      /* empty */
      setLoading(false);
    }
  };

  const handleCreateTask = (data: FinalizationReviewState) => {
    const isAlreadyChecked = taskArray.includes(data);

    if (isAlreadyChecked) {
      // If the task is already in the array, remove it
      setTaskArray((prevArray) => prevArray.filter((item) => item !== data));
    } else {
      // If the task is not in the array, add it
      setTaskArray((prevArray) => [...prevArray, data]);
    }
  };

  const handleBreachTask = (data: FinalizationReviewState) => {
    const isAlreadyChecked = breachArray.includes(data);

    if (isAlreadyChecked) {
      // If the task is already in the array, remove it
      setBreachArray((prevArray) => prevArray.filter((item) => item !== data));
    } else {
      // If the task is not in the array, add it
      setBreachArray((prevArray) => [...prevArray, data]);
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={pageTitle}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={mainPath}
          />
          <div className="record-outer-row settings-outer-row">
            <ReviewLeftMenu
              user={user}
              reviewId={id ? id : ''}
              sourceId={sourceId ? sourceId : ''}
              reviewIdEncrypted={encryptedId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                <div className="card-outline">
                  <div className="card-title-wrap secondary-title-wrap">
                    <h3>Next Steps</h3>
                  </div>

                  <div className="inner-wrap-title inner-no-margin">
                    {t('review.emailAdviserTxt')}
                  </div>
                  <div className="switch-wrap switch-margin">
                    <p className="mute-text">No</p>
                    <Switch className="custom-switch" onChange={handleEmailSwitch} />
                    <p>Yes</p>
                  </div>
                  <div className="inner-wrap-title inner-no-margin">
                    {t('review.finiliseReviewMsg')}
                  </div>
                  <div className="row-wrap">
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        className="table hover-table">
                        <TableHead>
                          <TableRow>
                            {headCells.map((headCell) => (
                              <TableCell
                                key={`key-${headCell.id}`}
                                align={headCell.numeric ? 'left' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}>
                                {headCell.id === 'create_task' ||
                                headCell.id === 'create_breach' ? (
                                  <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={() => handleSort(headCell.id)}>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                      <Box component="span" sx={{ display: 'none' }}>
                                        {order === 'desc'
                                          ? 'sorted descending'
                                          : 'sorted ascending'}
                                      </Box>
                                    ) : null}
                                  </TableSortLabel>
                                ) : (
                                  headCell.label
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reviews.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={5}>
                                <div className="no-record">No limitations.</div>
                              </TableCell>
                            </TableRow>
                          ) : (
                            reviews.map((data: FinalizationReviewState) => (
                              <TableRow key={data.id}>
                                <TableCell scope="row" className="unique-id-width">
                                  {data.client}
                                </TableCell>
                                <TableCell align="left">
                                  <span className="limitation-heading">{data.limitation}</span>

                                  <p className="overdue-status">{data.limitation_answer}</p>

                                  <p className="limitation-other-data">
                                    Comment: {data.comment && formattedContent(data.comment)}
                                  </p>
                                  <p className="limitation-other-data">
                                    Action: {data.action && formattedContent(data.action)}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    onChange={() => handleCreateTask(data)}
                                    checked={taskArray.includes(data)}
                                  />
                                </TableCell>
                                {breach && (
                                  <TableCell>
                                    <Checkbox
                                      onChange={() => handleBreachTask(data)}
                                      checked={breachArray.includes(data)}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>

                      <div className="inner-btn-wrap wrap-center wrap-padding wrap-center-two ">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <Button className="btn border-btn btn-sm" onClick={handleSkipbutton}>
                              Skip
                            </Button>
                            <Button className="btn primary-btn" onClick={handleSkipbutton}>
                              <i className="left">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <g clipPath="url(#clip0_6545_879)">
                                    <path
                                      d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                                      stroke="CurrentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_6545_879">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </i>
                              {finalizeBtn}
                            </Button>
                          </>
                        )}
                      </div>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default FinaliseReviewIndex;
