import React from 'react'; // Explicit import for React
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';

interface AddButtonProps {
  addPath: string; // Path to navigate when clicking the button
  dialogButton?: boolean;
  handleClick?: () => void;
}

// Create a functional component for the button
const AddButton: FC<AddButtonProps> = ({ addPath, dialogButton, handleClick }) =>
  dialogButton ? (
    <Button className="btn primary-btn" onClick={handleClick}>
      <i className="left">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_6545_879)">
            <path
              d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
              stroke="CurrentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_6545_879">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </i>
      Add Task
    </Button>
  ) : (
    <Button className="btn primary-btn" component={RouterLink} to={addPath}>
      <i className="left">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_6545_879)">
            <path
              d="M10.0001 6.66602V13.3327M6.66675 9.99935H13.3334M18.3334 9.99935C18.3334 14.6017 14.6025 18.3327 10.0001 18.3327C5.39771 18.3327 1.66675 14.6017 1.66675 9.99935C1.66675 5.39698 5.39771 1.66602 10.0001 1.66602C14.6025 1.66602 18.3334 5.39698 18.3334 9.99935Z"
              stroke="CurrentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_6545_879">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </i>
      Add Task
    </Button>
  );

export default AddButton; // Correct export
