import axios from 'axios';
import { loginSuccess, loginFailure } from '../../store/user/authSlice';
import { Dispatch } from 'redux'; // Import Dispatch type
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { localStorageKeys } from '../../utils/local-storage';
import { encryptData } from '../../utils/common/user';
import { t } from 'i18next';

interface LoginFormState {
  email: string;
  password: string;
  type?: string;
}

export const loginUser = async (
  dispatch: Dispatch,
  formData: LoginFormState
): Promise<string | undefined> => {
  const { email, password, type } = formData;

  try {
    const response = await axios.post<ApiResponseType>(`${process.env.REACT_APP_API_URL}/login`, {
      email,
      password,
      type
    });

    if (response.data.success) {
      dispatch(loginSuccess(response.data.data));

      if (type == t('onboarding.user')) {
        localStorage.setItem(
          t('onboarding.user'),
          encryptData(JSON.stringify(response.data.data))
        );
        localStorage.setItem('redirectAfterLogin', `/onboarding-personal-details?onboardingId=${encryptData(String(response.data.data.userInfo.id), true)}`)
        localStorage.setItem('type', t('onboarding.user'));
      } else {
        localStorage.setItem(
          localStorageKeys.USER_DATA,
          encryptData(JSON.stringify(response.data.data))
        );
      }

      return;
    } else {
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse
        ? error.message
        : error?.response?.data?.message || 'Something went wrong';

    dispatch(loginFailure(errorMessage)); // Dispatch the action with the error message

    return errorMessage;
  }
};
