import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { dataEncrypt, tokenCheck } from '../../utils/common/user';
import { AccountState } from '../../views/components/user/state';
import { RecordsDataFormState } from '../../views/components/registers/state';

export const createRecord = async (
  formData: any,
  authToken?: string,
  accountInfo?: AccountState,
  recordId?: string | number,
  typeData?: string | null
): Promise<RecordsDataFormState | string | undefined | null> => {
  let routeUrl;
  const selectedAccountId = accountInfo?.id;
  const {
    subject,
    owner_id,
    is_open,
    form_data,
    register_id,
    register_template_id,
    complaint_added
  } = formData;
  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('subject', subject);
    formData.append('owner_id', owner_id);
    formData.append('register_id', register_id);
    formData.append('is_open', is_open);
    formData.append('register_template_id', register_template_id);
    formData.append('account_id', selectedAccountId ? selectedAccountId.toString() : '');
    formData.append('form_data', JSON.stringify(form_data));
    formData.append('complaint_added', complaint_added);

    if (typeData) {
      formData.append('type_data', typeData);
    }

    if (recordId) {
      formData.append('_method', 'PUT');
      routeUrl = `${process.env.REACT_APP_API_URL}/records/${dataEncrypt(recordId?.toString())}`;
    } else {
      routeUrl = `${process.env.REACT_APP_API_URL}/records`;
    }
    const response = await axios.post<ApiResponseType>(routeUrl, formData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    });

    if (response.data.success) {
      return response?.data.data;
    } else {
      tokenCheck(response.status);
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse ? error.message : error?.response?.data?.message;

    return errorMessage;
  }
};
