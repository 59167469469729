import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { MeetingsDataFormState } from './state';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { localStorageKeys } from '../../../../utils/local-storage';

interface FinaliseMeetingssProps {
  date: string;
  formData: MeetingsDataFormState;
  loading: boolean;
  handleFinalise: () => void;
}

const FinaliseMeetings: React.FC<FinaliseMeetingssProps> = ({
  date,
  formData,
  loading,
  handleFinalise
}) => {
  const meetingDate = formData ? formData.meeting_date_time.split(' ')[0] : '';
  const { t } = useTranslation();
  const isScribe = localStorage.getItem(localStorageKeys.IS_SCRIBE);

  const fetchReportPath = (report: string | undefined) => {
    const reportObject = report ? JSON.parse(report) : null;
    const typedReportObject = reportObject as { path: string; original_name: string };

    if (report) {
      return typedReportObject.path;
    } else {
      return null;
    }
  };

  const handleViewMinutes = (meetingReport: string | undefined) => {
    const report = fetchReportPath(meetingReport);
    const componentBUrl = report ? `${report}` : '';
    window.open(componentBUrl, '_blank');
  };

  return (
    <>
      {formData && date >= meetingDate && (
        <div className="card-outline">
          <div className="card-title-wrap secondary-title-wrap">
            <h3>Finalise Meeting</h3>
          </div>
          {loading ? ( // Check loading state
            <div className="no-record">
              <CircularProgress />
            </div>
          ) : isScribe && !formData.finalised ? (
            <div className="manage-meeting">
              <div className="form-group">
                <p className="form-control">{t('meeting.finaliseMsg')}</p>
                <div className="inner-btn-wrap">
                  <Button className="btn primary-btn" onClick={() => handleFinalise()}>
                    <i className="left">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_6545_879)">
                          <path
                            d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                            stroke="CurrentColor"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6545_879">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                    Finalise Meeting
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-record-finalise">
              {formData.finalised && formData?.finalised_at ? (
                <div className="manage-meeting">
                  <div className="form-group">
                    <p className="form-control">
                      The meeting was finalised by {formData?.finalised_by?.first_name}{' '}
                      {formData?.finalised_by?.last_name} on{' '}
                      {format(new Date(formData?.finalised_at), 'd/MMM/yyyy')} at{' '}
                      {format(new Date(formData?.finalised_at), 'h:mm aa')}.
                    </p>
                    <div className="inner-btn-wrap">
                      <Button
                        className="btn primary-btn"
                        onClick={() => handleViewMinutes(formData.meeting_report)}>
                        <i className="left">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clipPath="url(#clip0_6545_879)">
                              <path
                                d="M6.25 10L8.75 12.5L13.75 7.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                                stroke="CurrentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_6545_879">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </i>
                        View Minutes
                      </Button>
                    </div>
                  </div>
                </div>
              ) : formData.meeting_started == 0 ? (
                t('meeting.noFinalisePermissionWithoutComplete')
              ) : null}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FinaliseMeetings;
